<template>
  <div class="chart-parent">
    <apexchart
      width="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "BarChart3",
  props: {
    series: {
      type: Array,
      default: () => [],
    },

    isStacked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "apexchart",
          stacked: this.isStacked,
        },
        xaxis: {
          categories: [],
        },
      },

      seriesData: this.series,
      categories: [],
    };
  },

  methods: {
    updateXAxisCategories(data) {
      this.chartOptions = {
        xaxis: {
          categories: data,
        },
      };
    },
  },

  watch: {
    series(newValue) {
      this.categories = [...newValue[0]?.xAxis];
      this.updateXAxisCategories(this.categories);
    },
  },
};
</script>
<style scoped>
.chart-parent {
  height: 100%;
}
</style>
