<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Edit Dispatch</h5>

            <p>Step 2 of 2</p>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <NumberInput
          placeholder="Loader Cost"
          @onInputChange="handleInputChange"
          fieldName="loaderCost"
          :inputValue="dispatchForm?.loaderCost"
        />

        <NumberInput
          placeholder="Fuel Cost"
          @onInputChange="handleInputChange"
          fieldName="fuelCost"
          :inputValue="dispatchForm?.fuelCost"
        />

        <NumberInput
          placeholder="Imprest Cost"
          @onInputChange="handleInputChange"
          fieldName="imprestCost"
          :inputValue="dispatchForm?.imprestCost"
        />

        <NumberInput
          placeholder="Community Cost"
          @onInputChange="handleInputChange"
          fieldName="communityCost"
          :inputValue="dispatchForm?.communityCost"
        />

        <NumberInput
          placeholder="Offloading Cost"
          @onInputChange="handleInputChange"
          fieldName="offloadingCost"
          :inputValue="dispatchForm?.offloadingCost"
        />

        <NumberInput
          placeholder="Checkpoint Cost"
          @onInputChange="handleInputChange"
          fieldName="checkPointCost"
          :inputValue="dispatchForm?.checkPointCost"
        />
      </form>
    </section>

    <div class="button-container">
      <InvertedBlackButton
        label="Back"
        width="8rem"
        class="back-btn"
        @click="routeToPreviousPage"
      />
      <GreenButton label="Update Dispatch" @click="updateDispatch" />
    </div>
  </div>
</template>

<script>
import NumberInput from "../../../components/form-inputs/number-input/NumberInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";

export default {
  name: "StartDispatchModal2",

  components: {
    GreenButton,
    InvertedBlackButton,
    NumberInput,
    Loading,
  },

  data() {
    return {
      dispatchForm: this.$store.getters["dispatch/getDispatchToBeUpdated"] || {
        loaderCost: 0,
        fuelCost: 0,
        imprestCost: 0,
        communityCost: 0,
        offloadingCost: 0,
        checkPointCost: 0,
        totalDispatchCost: "",
      },
      globalInputtedDispatch: {},
      loading: false,
      dispatchId: this.$store.getters["dispatch/getDispatchToBeUpdated"]["_id"],
    };
  },

  computed: {
    isRequiredFieldsEmpty() {
      const isEmpty =
        !this.farmerForm["first_name"].length ||
        !this.farmerForm["last_name"].length;

      // console.log(isEmpty);

      return isEmpty;
    },

    errors() {
      return this.$store.getters["dispatch/getError"];
    },
  },

  mounted() {
    console.log(this.$store.getters["dispatch/getDispatchToBeUpdated"]);
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.dispatchForm = {
        ...this.dispatchForm,
        ...obj,
      };
    },

    clearForm() {
      this.dispatchForm = {
        loaderCost: "",
        fuelCost: 0,
        imprestCost: 0,
        communityCost: 0,
        offloadingCost: 0,
        checkPointCost: 0,
        totalDispatchCost: 0,
      };
    },

    async updateDispatch() {
      const payload = {
        id: this.dispatchId,
        apId: this.dispatchForm["apId"],
        driver: this.dispatchForm["driver"],
        vehicle: this.dispatchForm["vehicle"],
        factory: this.dispatchForm["factory"],
        qtyMoved: this.dispatchForm["qtyMoved"],
        dispatch_starts_at: this.dispatchForm["dispatch_starts_at"],
        fuelCost: this.dispatchForm["fuelCost"],
        imprestCost: this.dispatchForm["imprestCost"],
        loaderCost: this.dispatchForm["loaderCost"],
        offloadingCost: this.dispatchForm["offloadingCost"],
        communityCost: this.dispatchForm["communityCost"],
        checkPointCost: this.dispatchForm["checkPointCost"],
        totalDispatchCost:
          this.dispatchForm["fuelCost"] +
          this.dispatchForm["imprestCost"] +
          this.dispatchForm["loaderCost"] +
          this.dispatchForm["offloadingCost"] +
          this.dispatchForm["communityCost"] +
          this.dispatchForm["checkPointCost"],
      };

      this.loading = true;

      const data = await this.$store.dispatch(
        "dispatch/updateDispatch",
        payload
      );

      if (data.id) {
        this.loading = false;
        this.clearForm();
        window.location.reload();
        createToast("Dispatch updated successfully!", {
          type: "success",
          timeout: 5000,
          position: "top-right",
        });
      }
    },

    routeToPreviousPage() {
      this.$emit("onSetAddModalPage", 1);

      this.globalInputtedDispatch =
        this.$store.getters["dispatch/getDispatchToBeUpdated"];
      this.globalInputtedDispatch = {
        ...this.globalInputtedDispatch,
        ...this.dispatchForm,
      };

      this.$store.dispatch(
        "dispatch/setValuesForDispatchUpdate",
        this.globalInputtedDispatch
      );
    },
  },

  watch: {
    "dispatchForm.loaderCost"(newValue, oldValue) {
      this.dispatchForm.totalDispatchCost +=
        Number(newValue) - Number(oldValue);
    },

    "dispatchForm.fuelCost"(newValue, oldValue) {
      this.dispatchForm.totalDispatchCost +=
        Number(newValue) - Number(oldValue);
    },

    "dispatchForm.imprestCost"(newValue, oldValue) {
      this.dispatchForm.totalDispatchCost +=
        Number(newValue) - Number(oldValue);
    },

    "dispatchForm.communityCost"(newValue, oldValue) {
      this.dispatchForm.totalDispatchCost +=
        Number(newValue) - Number(oldValue);
    },

    "dispatchForm.offloadingCost"(newValue, oldValue) {
      this.dispatchForm.totalDispatchCost +=
        Number(newValue) - Number(oldValue);
    },
    "dispatchForm.checkPointCost"(newValue, oldValue) {
      this.dispatchForm.totalDispatchCost +=
        Number(newValue) - Number(oldValue);
    },

    errors(newError) {
      this.loading = false;
      createToast(newError.message || newError.error, {
        type: "danger",
        timeout: 5000,
        position: "top-right",
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: auto;
}

.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 25%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 3% auto 2% auto;
  gap: 20px;
}
</style>
