<template>
  <div class="discovery-parent">
    <div class="discovery-wrapper">
      <section class="discovery-search-container">
        <div class="search-form">
          <input
            type="text"
            class="search-input"
            placeholder="Search by farmer name, mill name, and discovery id"
            v-model="searchTerm"
            @keyup.enter="handleSearchText"
          />
          <button><img src="../../assets/search-icon.png" alt="" /></button>
        </div>

        <GreenButton
          v-if="getUserType !== 'FactoryCustomer'"
          label="Add New Discovery"
          width="13.9rem"
          height="2.9rem"
          iconName="add-icon"
          @click="openAddDiscoveryModal"
        />
      </section>

      <section class="date-filter-container">
        <div class="wrapper">
          <p class="">Search by Period</p>

          <div class="controls-container">
            <div class="inputs-container">
              <div class="input">
                <input
                  type="text"
                  name=""
                  id="date"
                  placeholder="Select Start Date"
                  onfocus="(this.type = 'date')"
                  v-model="startDate"
                />
              </div>

              <div class="input">
                <input
                  type="text"
                  name=""
                  id="date"
                  placeholder="Select End Date"
                  onfocus="(this.type = 'date')"
                  v-model="endDate"
                />
              </div>
              <GreenButton
                label="Fetch"
                width="5rem"
                height="2rem"
                @click="fetchByDatePeriod(page)"
              />
            </div>

            <div v-if="itemsSelected.length > 0" class="multi-actions">
              <i
                class="fa fa-trash"
                style="font-size: 24px; color: red; cursor: pointer"
                @click="openDeleteModal"
              ></i>
              <GreenButton
                label="Approve"
                width="5rem"
                height="2rem"
                @click="openApprovalModal"
              />
            </div>

            <div class="buttons-container">
              <div class="">
                <download-excel :data="discoveryJSON">
                  <InvertedBlackButton
                    label="Export"
                    width="8rem"
                    height="2.6rem"
                    iconName="excel-icon"
                  >
                  </InvertedBlackButton>
                </download-excel>
              </div>
              <div class="">
                <BlackButton
                  label="Open Filter"
                  width="10rem"
                  height="2.6rem"
                  iconName="filter-icon"
                  @click="openFilterModal"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="data-table-container">
        <EasyDataTable
          v-model:server-options="paginationMetaData"
          v-model:items-selected="itemsSelected"
          :server-items-length="count"
          :headers="headers"
          :items="discoveryList"
          alternating
          table-class-name="customize-table"
          class="data-table"
          :loading="loading"
          buttons-pagination
        >
          <template #item-discovery_status="item">
            <div class="status-wrapper">
              <div
                class="status"
                :class="[
                  item.discovery_status === 'Accepted'
                    ? 'approved'
                    : item.discovery_status === 'Declined'
                    ? 'rejected'
                    : 'pending',
                ]"
              >
                {{ item.discovery_status }}
              </div>
            </div>
          </template>

          <template #item-isApproved="item">
            <div class="status-wrapper">
              <div
                class="status"
                :class="[
                  item.isApproved === 'Approved'
                    ? 'approved'
                    : item.isApproved === 'Rejected'
                    ? 'rejected'
                    : 'pending',
                ]"
              >
                {{ item.isApproved }}
              </div>
            </div>
          </template>

          <template #item-actions="item">
            <div class="actions-wrapper">
              <div class="" @click="toggleActions(item)">
                <div
                  class="action-options"
                  v-if="isActionsOpened && item._id === itemClicked"
                >
                  <div class="item-wrapper">
                    <div class="actions-item" @click="openDetailsModal">
                      <div class="image">
                        <img
                          src="../../assets/green-eye.png"
                          alt=""
                          class="action-icon green-eye"
                        />
                      </div>
                      <p class="view-action">View details</p>
                    </div>
                    <div class="actions-item" @click="handleEdit(item._id)">
                      <div class="">
                        <img
                          src="../../assets/edit-icon.png"
                          alt=""
                          class="action-icon edit-icon"
                        />
                      </div>
                      <p class="edit-action">Edit</p>
                    </div>
                    <div class="actions-item" @click="openDeleteModal">
                      <div class="">
                        <img
                          src="../../assets/delete-icon.png"
                          alt=""
                          class="action-icon delete-icon"
                        />
                      </div>
                      <p class="delete-action">Delete</p>
                    </div>
                  </div>
                </div>
                <span>&#8942;</span>
              </div>
            </div>
          </template>
        </EasyDataTable>
      </section>
    </div>

    <div class="discovery-modal" v-if="isAddDiscoveryModalOpened">
      <AddDiscoveryModal1
        @onCloseModal="handleCloseDetailsModal"
        @openAddDiscoveryModal2="handleAddDiscovery"
      />
    </div>

    <div class="discovery-add-modal" v-if="isAddDiscoveryModalFormOpened">
      <AddDiscoveryModal2
        @onCloseModal="handleCloseDetailsModal"
        v-if="modalPageNum === 2"
      />
    </div>

    <div class="dicovery-details-modal" v-if="isDetailsModalOpened">
      <DiscoveryDetailsModal
        @onCloseModal="handleCloseDetailsModal"
        @openDeleteModal="openDeleteModal"
        @openEditModal="openEditModal"
        @openAcceptModal1="openAcceptsModal"
        @openRejectModal="openRejectsModal"
        @openAcceptModal2="openAcceptsModal2"
        @openRejectModal2="openRejectsModal2"
        :discoveryId="itemClicked"
      />
    </div>

    <div class="filter-modal" v-if="isFilterModalOpened">
      <DiscoverFilterModal @onCloseModal="handleCloseDetailsModal" />
    </div>

    <div class="discovery-delete-modal" v-if="isDeleteModalOpened">
      <DeleteModal
        @onCloseModal="handleCloseDetailsModal"
        :discoveryId="itemClicked"
      />
    </div>

    <div
      class="discovery-delete-modal"
      v-if="isDeleteModalOpened && selectedDiscoveryIds.length"
    >
      <DeleteManyModal
        :ids="selectedDiscoveryIds"
        heading="Discoveries"
        @onCloseModal="handleCloseDetailsModal"
      />
    </div>

    <div class="discovery-edit-modal" v-if="isEditModalOpened">
      <EditDiscovery
        @onCloseModal="handleCloseDetailsModal"
        :discoveryId="itemClicked"
      />
    </div>

    <div class="discovery-accepts-modal" v-if="isAcceptsModalOpened1">
      <AcceptsModal1
        @onCloseModal="handleCloseDetailsModal"
        :discoveryId="itemClicked"
      />
    </div>

    <div class="discovery-accepts-modal" v-if="isAcceptsModalOpened2">
      <AcceptsModal2
        @onCloseModal="handleCloseDetailsModal"
        :discoveryId="itemClicked"
      />
    </div>

    <div class="discovery-rejects-modal" v-if="isRejectsModalOpened">
      <RejectsModal
        @onCloseModal="handleCloseDetailsModal"
        :discoveryId="itemClicked"
      />
    </div>

    <div class="discovery-rejects-modal" v-if="isRejectsModal2Opened">
      <RejectsModal2
        @onCloseModal="handleCloseDetailsModal"
        :discoveryId="itemClicked"
      />
    </div>
  </div>

  <!-- Approval -->

  <div
    class="discovery-delete-modal"
    v-if="isApprovalStatus && selectedDiscoveryIds.length"
  >
    <ApproveManyModal
      :ids="selectedDiscoveryIds"
      heading="Discoveries"
      @onCloseModal="handleCloseDetailsModal"
    />
  </div>

  <div
    class="dark-overlay"
    v-if="
      isFilterModalOpened ||
      isDetailsModalOpened ||
      isAddDiscoveryModalFormOpened ||
      isEditModalOpened ||
      isDeleteModalOpened ||
      isAddDiscoveryModalOpened ||
      isRejectsModalOpened ||
      isRejectsModal2Opened ||
      isApprovalStatus ||
      isAcceptsModalOpened1 ||
      isAcceptsModalOpened2
    "
  ></div>
</template>

<script>
import DeleteManyModal from "../../components/modals/DeleteManyModal.vue";
import ApproveManyModal from "../../components/modals/ApproveManyModal.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import BlackButton from "../../components/buttons/black-button/BlackButton.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import { discoveryTableHeaders } from "./headers";
import DiscoveryDetailsModal from "./modals/DiscoveryDetailsModal.vue";
import { convertToFilterQueryString } from "../../utils";
import AddDiscoveryModal1 from "./modals/AddDiscoveryModal1.vue";
import AddDiscoveryModal2 from "./modals/AddDiscoveryModal2.vue";
import DiscoverFilterModal from "./modals/DiscoverFilterModal.vue";
import DeleteModal from "./modals/DeleteModal.vue";
import EditDiscovery from "./modals/EditDiscovery.vue";
import AcceptsModal1 from "./modals/AcceptsModal.vue";
import AcceptsModal2 from "./modals/AcceptsModal2.vue";
import RejectsModal from "./modals/RejectsModal.vue";
import RejectsModal2 from "./modals/RejectModal2.vue";
import { AuthUser } from "../../utils/authUser";

export default {
  name: "Discovery",
  components: {
    GreenButton,
    BlackButton,
    InvertedBlackButton,
    DiscoveryDetailsModal,
    AddDiscoveryModal1,
    AddDiscoveryModal2,
    DiscoverFilterModal,
    DeleteModal,
    EditDiscovery,
    AcceptsModal1,
    AcceptsModal2,
    RejectsModal,
    RejectsModal2,
    DeleteManyModal,
    ApproveManyModal,
  },
  data() {
    return {
      discoveryList: [],
      itemsSelected: [],
      count: 0,
      searchTerm: "",
      startDate: "",
      endDate: "",
      selectedDiscoveryIds: [],
      paginationMetaData: {
        page: 1,
        rowsPerPage: 100,
      },
      isActionsOpened: false,
      discoveryJSON: [],
      loading: false,
      headers: discoveryTableHeaders,
      isDetailsModalOpened: false,
      isAddDiscoveryModalOpened: false,
      isAddDiscoveryModalFormOpened: false,
      isDeleteModalOpened: false,
      isFilterModalOpened: false,
      isEditModalOpened: false,
      isAcceptsModalOpened1: false,
      isRejectsModalOpened: false,
      isAcceptsModalOpened2: false,
      isRejectsModal2Opened: false,
      isApprovalStatus: false,
      itemClicked: "",
      modalPageNum: 1,
      globalFilterQuery: "",
    };
  },

  mounted() {
    this.fetchAllDiscovery();
  },

  methods: {
    handleCloseDetailsModal(status) {
      this.isDetailsModalOpened = status;
      this.isAddDiscoveryModalOpened = status;
      this.isAddDiscoveryModalFormOpened = status;
      this.isFilterModalOpened = status;
      this.isDeleteModalOpened = status;
      this.isEditModalOpened = status;
      this.isAcceptsModalOpened1 = status;
      this.isAcceptsModalOpened2 = status;
      this.isRejectsModalOpened = status;
      this.isRejectsModal2Opened = status;
      this.isApprovalStatus = status;
    },

    openFilterModal() {
      this.isFilterModalOpened = !this.isFilterModalOpened;
    },

    handleEdit(id) {
      this.isEditModalOpened = !this.isEditModalOpened;
      this.$store.dispatch("discoveries/findDiscoveryToBeUpdated", id);
    },

    toggleActions(arg) {
      this.itemClicked = arg._id;
      this.isActionsOpened = !this.isActionsOpened;
    },

    openAddDiscoveryModal() {
      this.isAddDiscoveryModalOpened = !this.isAddDiscoveryModalOpened;
    },

    openApprovalModal() {
      this.isApprovalStatus = !this.isApprovalStatus;
    },

    openAddDiscoveryModalForm() {
      this.isAddDiscoveryModalFormOpened = !this.isAddDiscoveryModalFormOpened;
    },

    openDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },

    openEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },

    openAcceptsModal() {
      this.isAcceptsModalOpened1 = !this.isAcceptsModalOpened1;
    },

    openAcceptsModal2() {
      this.isAcceptsModalOpened2 = !this.isAcceptsModalOpened2;
    },

    openRejectsModal() {
      this.isRejectsModalOpened = !this.isRejectsModalOpened;
    },

    openRejectsModal2() {
      this.isRejectsModal2Opened = !this.isRejectsModal2Opened;
    },

    handleAddDiscovery(pageNum) {
      this.modalPageNum = pageNum;
      this.isAddDiscoveryModalFormOpened = !this.isAddDiscoveryModalFormOpened;
    },

    openDetailsModal() {
      this.isDetailsModalOpened = !this.isDetailsModalOpened;
    },
    async fetchAllDiscovery(page = 1, limit = 100, searchTerm = "") {
      this.loading = true;
      try {
        const resp = await this.$store.dispatch(
          "discoveries/fetchAllDiscovery",
          {
            page,
            limit,
            searchTerm,
          }
        );

        this.discoveryList = resp?.discoveries;
        this.discoveryJSON = resp?.discoveries;
        this.count = resp?.count;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async filterDiscovery(page, limit = 100, filterQuery) {
      try {
        this.loading = true;

        const { filteredDiscovery, count } = await this.$store.dispatch(
          "discoveries/filterDiscovery",
          {
            page,
            limit,
            filterQuery,
          }
        );

        this.discoveryList = filteredDiscovery;
        this.discoveryJSON = filteredDiscovery;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchByDatePeriod(page = 1, limit = 100) {
      if (this.startDate === "" || this.endDate === "") {
        return;
      }

      const query = convertToFilterQueryString({
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.loading = true;

      try {
        const { filteredDiscovery, count } = await this.$store.dispatch(
          "discoveries/filterDiscoveryByDatePeriod",
          { page, limit, filterQuery: query }
        );

        this.discoveryList = filteredDiscovery;
        this.discoveryJSON = filteredDiscovery;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async handleSearchText(e) {
      const pageNum = 1;
      this.fetchAllDiscovery(pageNum, 100, e.target.value);
    },
  },

  computed: {
    filterMetaData() {
      return this.$store.getters["discoveries/getFilterDiscoveryMetaData"];
    },

    getUserType() {
      const authUser = new AuthUser();
      const { userType } = authUser.getDetails();
      return userType?.name || "User";
    },
  },

  watch: {
    paginationMetaData: {
      handler: function (newValue) {
        console.log(newValue.rowsPerPage);
        const isFilter = this.$store.getters["discoveries/getFilterStatus"];

        const isDateFilter =
          this.$store.getters["discoveries/getIsDateFilterStatus"];

        const isDelete = this.$store.getters["discoveries/getDeleteStatus"];
        if (isFilter) {
          this.filterDiscovery(
            newValue.page,
            newValue.rowsPerPage,
            this.globalFilterQuery
          );
        } else if (isDateFilter) {
          this.fetchByDatePeriod(
            newValue.page,
            newValue.rowsPerPage,
            this.globalFilterQuery
          );
        } else if (isDelete) {
          this.fetchAllDiscovery(
            newValue.page,
            newValue.rowsPerPage,
            newValue.rowsPerPage
          );
        } else {
          this.fetchAllDiscovery(
            newValue.page,
            newValue.rowsPerPage,
            this.searchTerm
          );
        }
      },

      deep: true,
    },

    discoveryList(newValue) {
      this.discoveryJSON = newValue;
      this.discoveryList = newValue;
    },

    filterMetaData(newValue) {
      const { filteredDiscovery, count, query } = newValue;
      this.globalFilterQuery = query;
      this.discoveryList = filteredDiscovery;
      this.count = count;
    },

    itemsSelected: {
      handler: function (newValue) {
        this.selectedDiscoveryIds = newValue.map((discovery) => discovery._id);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.discovery-parent {
  width: 100%;
  margin: 0 auto;
  background: #f9fafe;
  height: 100vh;
  overflow-y: scroll;
}

.discovery-wrapper {
  width: 95%;
  margin: 1% auto 0 auto;
  height: 100%;
}

.discovery-search-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.multi-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
}

.date-filter-container {
  width: 100%;
  margin-top: 3%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 87%;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 27%;
}

.data-table-container {
  width: 100%;
  height: 79.9%;
  overflow-x: hidden;
  overflow-y: auto;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;
  width: 127px;
  height: 32px;
  border-radius: 41px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.approved {
  color: #669933;
  background: rgba(102, 153, 51, 0.15);
}

.rejected {
  color: #ed0a0a;
  background: rgba(237, 10, 10, 0.15);
}

.pending {
  color: #f19306;
  background: rgba(241, 147, 6, 0.15);
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5px;

  position: absolute;
  width: 130%;
  height: auto;
  right: 60px;

  /* White */

  background: #ffffff;
  /* Drop down shadow */

  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
}

.item-wrapper {
  width: 90%;
  margin: 0 auto;
}

.actions-item {
  display: flex;
  cursor: pointer;
}

.action-icon {
  margin-right: 5px;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.view-action {
  color: #669933;
}

.edit-action {
  color: #ea9a00;
}

.delete-action {
  color: #ed0a0a;
}

.discovery-modal {
  max-width: 500px;
  height: 400px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.discovery-add-modal,
.discovery-accepts-modal,
.discovery-rejects-modal {
  max-width: 600px;
  height: 650px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.discovery-accepts-modal {
  height: auto;
}

.discovery-rejects-modal {
  height: 520px;
}

.discovery-edit-modal {
  max-width: 600px;
  height: 650px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.discovery-delete-modal {
  max-width: 500px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.filter-modal,
.close-filter-modal {
  position: absolute;
  top: 0px;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter-modal {
  animation: slideFromRightToLeft 1s ease-in-out;
  animation-delay: 10ms;
  left: 72%;
  width: 450px;
}

.close-filter-modal {
  animation: slideBackIn 1s ease-in-out;
  animation-delay: 10ms;
  left: 100%;
  display: hidden;
}

.dicovery-details-modal {
  max-width: 1000px;
  height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

/* .vue3-easy-data-table__body td.direction-left[data-v-8c691ab2] {
  text-align: left; 
}

*/

.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 7px 11px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
  --easy-table-body-row-td-direction: center;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}
</style>
