<template>
  <div>
    <!-- <div class="community">
      <div class="community-header">
        <span class="arrow-back">
          <a href="#"><img src="../../assets/arrow.png" alt="" /></a>
        </span>
        <span class="community-header">
          <h1>Community</h1>
        </span>

        <span class="search">
          <a href="#"><img src="../../assets/search.png" alt="" /></a>
        </span>
      </div>
    </div>

    <a href="#" class="btn-container">
      <img src="../../assets/plus.png" alt="" />
      <p class="btn-community-text">Add Community</p>
    </a> -->

    <Header
      headerTitle="Community"
      @onSearchText="handleSearch"
      iconName="fa fa-search"
    />

    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <div class="button-container">
      <GreenButton label="Add Community" @click="routeToAddCommunityPage" />
    </div>

    <div class="" v-if="communities.length > 0">
      <TableContainer>
        <tr>
          <th>Name</th>
          <th>State</th>
          <th>LGA</th>
        </tr>

        <tbody>
          <tr v-for="community in communities" :key="community.id" @click="routeToDetailsPage(community._id)">
            <td>{{community.name}}</td>
            <td>{{community.slug}}</td>
            <td>{{community.parent_slug}}</td>
          </tr>
        </tbody>
      </TableContainer>
    </div>

    <div class="no-data" v-else-if="loading === false">
      <h2>No communities found</h2>
    </div>
  </div>
</template>

<script>
import { TableContainer } from "../table/table-container.styles";
import GreenButton from "../buttons/green-button/GreenButton.vue";
import Header  from "../header/Header.vue";
import Loading from "vue-loading-overlay";
export default {
  name: "CommunityPage",
  components: {
    TableContainer,
    Header,
    Loading,
    GreenButton
  },
  data() {
    return {
      communities: [],
      loading: false,
      fullPage: true,
    };
  },

  mounted() {
    this.getCommunities();
  },

  methods: {
    async getCommunities() {
      this.loading = true;

      try {
        const communityList =  await this.$store.dispatch("community/fetchCommunities");
        this.communities = communityList;

        this.loading = false;
        console.log(this.communities, "communities")


      } catch (error) {
        console.log(error);
      }
    },

    routeToAddCommunityPage() {
      this.$router.push({ name: "AddCommunity" });
    },

   routeToDetailsPage(communityId) {
     this.$router.push({
       name: "CommunityDetails",
       params: { communityId }
     })
   },

    async handleSearch(searchTerm) {
      // this.loading = true;
      // this.$store.dispatch("community/searchCommunities", searchText).then(() => {
      //   this.loading = false;
      //   this.communities = this.$store.getters.getSearchCommunities;
      // });

      try {
        if (searchTerm !== null) {
          this.loading = true;

          const communityList = await this.$store.dispatch('community/searchCommunities', searchTerm);
          console.log(communityList);

          if (!communityList) {
            this.communities = [];
            this.loading = false;
            return;
          }

          this.communities = communityList;
          this.loading = false;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<style scoped>
.community {
  font-size: 1rem;
  width: 100vw;
  height: 100vh;
  position: relative;
}


.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

.community-header {
  background-color: #669933;
  background-image: url(../../assets/community.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  color: #fff;
  width: 100%;
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-back {
  margin-right: 1rem;
}


.no-data {
  position: absolute;
  top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
  margin: 30px auto;
}
</style>
