<template>
  <div class="input-parent">
    <input
      :id="input"
      type="text"
      placeholder=""
      v-model="input"
      ref="input"
      required
      :class="[
        'input-field__input',
        { 'input-field__input--focused': isFocused },
      ]"
      @focus="isFocused = true"
      @blur="isFocused = false"
      :disabled="disabled"
    />
    <label
      :for="input"
      class="form__label"
      @click="clickPlaceholder"
      :class="[
        'input-field__placeholder',
        { 'input-field__placeholder--focused': floatOn },
      ]"
      >{{ placeholder }}
    </label>
  </div>
</template>

<script>
import { NameConvention } from "../../../utils/nameConvention";
export default {
  name: "TextInput",

  data() {
    return {
      input: this.inputValue,
      disabled: this.disabledValue,
      isFocused: false,
    };
  },

  computed: {
    floatOn() {
      return this.isFocused || this.input.length > 0;
    },
  },

  props: {
    placeholder: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    inputValue: {
      type: String,
      default: "",
    },

    disabledValue: {
      type: Boolean,
      default: false,
    },

    isSnakeCase: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    clickPlaceholder() {
      this.isFocused = true;
      this.$refs.input.focus();
    },
  },

  watch: {
    input(newValue) {
      const fieldName = new NameConvention(this.fieldName);
      const key = this.isSnakeCase ? fieldName.snakeCase() : this.fieldName;
      this.$emit("onInputChange", {
        [key]: newValue,
      });

      if (this.disabledValue === true) {
        this.$emit("onInputChange", {
          [key]: this.inputValue,
        });
      }
    },

    inputValue(newValue) {
      this.input = newValue;
    },
  },
};
</script>

<style scoped>
.input-parent {
  position: relative;
  border: 1px solid #fff;
}

.input-parent-2 {
  background: #ececec;
}


.input-field-wrapper {
  position: relative;
  padding-top: 10px;
}
.input-field__input {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  border: 1.5px solid #545454;
  border-radius: 4px;
  height: 55px;
  outline: none;
  font-size: 13px;
  background: none;
  text-align: left;
  padding-left: 0.7rem;
  margin-bottom: 20px;
}
.input-field__input--focused {
  border: 2px solid #3b3b3b;
}
.input-field__placeholder {
  position: absolute;
  left: 0.8rem;
  top: 1.1rem;
  width: 92%;
  font-size: 13px;
  color: #d3d3d3;
  transition: 0.3s;
  cursor: text;
}
.input-field__placeholder--focused {
  top: -0.5rem;
  left: 0.8rem;
  color: #000;
  font-size: 0.75rem;
  font-weight: 300;
  width: auto;
  z-index: 1;
  background: white;
  padding: 0 3px;
}
</style>
