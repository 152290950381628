<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <h5 class="modal-caption">Tag Mills</h5>

          <h5 class="modal-caption">Step 1 of 2</h5>
          <div class="header-content-right">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-section">
      <form class="form">
        <TextInput
          placeholder="Mill Name"
          @onInputChange="handleInputChange"
          fieldName="name"
          :inputValue="millForm?.name"
          :disable="false"
        />

        <SelectDropdown
          :options="['Yes', 'No']"
          class="select"
          dropdownTitle="Physically Tagged?"
          @onSelect="handleSelect"
          fieldName="physically_tagged"
          :inputValue="millForm?.physically_tagged"
          :disable="false"
        />

        <SelectDropdown
          :options="['Small', 'Medium', 'Large']"
          class="select"
          dropdownTitle="Mill Size"
          @onSelect="handleSelect"
          fieldName="size"
          :inputValue="millForm?.size"
        />

        <SelectDropdown
          :options="['Yes', 'No']"
          class="select"
          dropdownTitle="Have Cracker?"
          @onSelect="handleSelect"
          fieldName="cracker"
          :inputValue="millForm?.cracker"
        />

        <TextInput
          placeholder="Number of Pot"
          @onInputChange="handleInputChange"
          fieldName="number_of_pots"
          :inputValue="millForm?.number_of_pots"
        />

        <TextInput
          placeholder="Number of pressers"
          @onInputChange="handleInputChange"
          fieldName="number_of_pressers"
          :inputValue="millForm?.number_of_pressers"
        />
      </form>
    </section>

    <div class="progress-bar-container">
      <div class="indicator"></div>
    </div>

    <div class="button-container">
      <GreenButton
        label="Save and Continue"
        @click="openAddMillsModal"
        :disable="isRequiredFieldsEmpty"
        width="300px"
      />
    </div>
  </div>
</template>

<script>
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import { createToast } from "mosha-vue-toastify";

export default {
  name: "AddMillModal",
  components: {
    TextInput,
    SelectDropdown,
    GreenButton,
  },

  data() {
    return {
      millForm: this.$store.getters["mills/getInputtedMills"] || {
        name: "",
        physically_tagged: "",
        size: "",
        cracker: "",
        number_of_pots: "",
        number_of_pressers: "",
      },
      loading: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    openAddMillsModal(e) {
      e.preventDefault();

      const {
        name,
        physically_tagged,
        size,
        cracker,
        number_of_pots,
        number_of_pressers,
      } = this.millForm;

      if (
        !name ||
        !physically_tagged ||
        !size ||
        !cracker ||
        !number_of_pots ||
        !number_of_pressers
      ) {
        createToast("Please fill All Fields", {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });
        return;
      }

      const payload = {
        name,
        physically_tagged,
        size,
        cracker,
        number_of_pots,
        number_of_pressers,
      };

      this.$emit("onCloseModal");

      this.$store.dispatch("mills/setInputtedMill", payload);
      this.$emit("openAddMillsModal2", 2);
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  height: 230px;
  overflow-y: scroll;
}

.form-container::-webkit-scrollbar {
  display: none;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.input {
  width: 300px;
  height: 3.5rem;
  border: 0.5px solid #232a34;
  border-radius: 5px;
}

.input input {
  width: 100%;
  height: 100%;
}

.x-container:hover {
  background: #e8e8e8;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.category-container {
  margin-top: 70px;
}

.form-section {
  height: 420px;
  overflow-y: scroll;
}

.form {
  width: 65%;
  height: 70%;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 50%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 7% auto 2% auto;
}
</style>
