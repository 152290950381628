<template>
  <div class="dropdown-container" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="{ open: open }"
      @click="open = !open"
      tabindex="1"
    >
      <input
        type="text"
        name=""
        id=""
        v-model="selected"
        placeholder=""
        class="form__input"
        required
      />
      <label class="form__label">{{ dropdownTitle }} </label>
    </div>
    <div
      :class="[{ selectHide: !open }, location ? 'location' : 'items']"
      @mouseleave="open = false"
    >
      <div class="search-bar" style="">
        <SearchBar @onSearchTermChange="handleSearchTermChange" />
      </div>
      <div
        v-for="(option, i) of dropdownOptions"
        :key="i"
        @click="handleSelect(option, dropdownTitle)"
        class="options"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from "../../search-bar/SearchBar";

export default {
  name: "SearchableDropdown",

  components: {
    SearchBar,
  },

  props: {
    options: {
      type: Array,
      required: true,
    },
    dropdownTitle: {
      type: String,
      required: true,
      // default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    reset: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputValue: {
      type: String,
      required: false,
      default: "",
    },
    isLocation: {
      type: Boolean,
      required: false,
      default: false,
    },
    fieldName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selected: this.inputValue,
      open: false,
      isReset: this.reset,
      dropdownOptions: this.options,
      location: this.isLocation,
      searchTerm: "",
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },

  methods: {
    handleSelect(option) {
      this.isReset = false;
      this.selected = option;
      this.open = false;

      // emit selected option to the form
      this.$emit("onSelect", {
        [this.fieldName]: this.selected,
      });

      // emit updated reset value to the form
      this.$emit("onReset", this.isReset);
    },

    closeOptions() {
      console.log("close method called");
      this.open = false;
    },

    handleSearchTermChange(text) {
      this.searchTerm = text;
    },
  },

  watch: {
    reset(newValue) {
      if (newValue) this.selected = null;
    },

    searchTerm(newValue) {
      if (newValue) {
        this.dropdownOptions = this.dropdownOptions.filter(
          (el) => el.toLowerCase().search(newValue.toLowerCase()) !== -1
        );
      } else {
        this.$emit("onInput", {
          [this.fieldName]: this.selected,
        });
        this.dropdownOptions = this.options;
      }
    },

    options(newValue) {
      this.dropdownOptions = newValue;
    },
  },
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
}

.selected {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #545454;
  color: #669933;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  width: 100%;
  line-height: 35px;
  font-size: 0.8rem;
  position: relative;
  height: 55px;
  border: 1.5px solid #545454;
  margin-bottom: 20px !important;
  display: flex;
  /* position: relative; */
}

input {
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
}

input::placeholder {
  color: #b3b3b3;
}

input:focus ~ .form__label,
input:valid ~ .form__label {
  top: -1.1rem;
  left: 0.8rem;
  color: #000;
  font-size: 0.75rem;
  font-weight: 300;
  width: auto;
  background: white;
  padding: 0 3px;
  z-index: 1;
}

.form__label {
  position: absolute;
  left: 0.8rem;
  top: 0.6rem;
  width: 100%;
  font-size: 13px;
  color: #d3d3d3;
  transition: 0.3s;
  cursor: text;
}

.selected.open {
  border: 1.5px solid #545454;
  border-radius: 6px 6px 0px 0px;
}

.selected:after {
  position: absolute;
  content: "";
  top: 27px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #232a34 transparent transparent transparent;
}

.selected:focus p {
  top: -0.5rem;
  left: 0.8rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 10;
}

.name {
  display: flex;
  flex-direction: column;
}

.options {
  /* width: 95%; */
  width: 90%;
  /* margin-top: 8px; */
}

.options:hover {
  background-color: rgb(38, 91, 206);
}

.items,
.location {
  color: rgb(196, 196, 196);
  border-radius: 0px 0px 6px 6px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #545454;
  border-left: 1px solid #545454;
  border-bottom: 1px solid #545454;
  position: absolute;
  background-color: #fff;
  top: 54px;
  left: 0px;
  right: 70px;
  z-index: 10;
  padding: 0.4rem;
  margin: 0 auto;
  border: 1.5px solid #545454;
}

.location {
  height: 90%;
}

.items {
  height: 150px;
}

.items div,
.location div {
  color: #545454;
  padding: 0.5em;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  /* border-bottom: 1px solid #545454; */
  display: flex;
  align-items: center;
}

.items div:last-of-type {
  border-bottom: 0px;
}

.items div:hover {
  background-color: #fff;
}

.location div:last-of-type {
  border-bottom: 0px;
}

.location div:hover {
  background-color: #fff;
}

.selectHide {
  display: none;
}

.search-bar {
  width: 90%;
  margin: 0 auto;
  /* position: fixed;
  left: 20px;
  right: 25px; */
  /* border: 1px solid black; */
}

@media only screen and (min-width: 500px) {
  .items,
  .location {
    width: 100%;
    position: absolute;
    right: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .items,
  .location {
    width: 100%;
    position: absolute;
    right: 17px;
  }
}
</style>
