import { APIConfig } from "../../config/apiConfig";
import { roundToTwoDP } from "../../utils/numbers-formatter";
import moment from "moment";

const formatPurchasesData = (data) => {
  return data.map((purchase) => {
    return {
      _id: purchase._id,
      farmerName: `${purchase.farmerId?.first_name || "Nil"} ${
        purchase.farmerId?.last_name || "Nil"
      }`,
      farmer_id: purchase.farmerId?._id || "Nil",
      price: Math.round(purchase.price).toString(),
      price_per_ton:
        purchase.price_per_ton || purchase.price / purchase.actual_tonnage,
      millId: purchase.millId?.id,
      millName: purchase.millId?.name || "Nil",
      millRTM: purchase.millId?.mill_id || "Nil",
      latitude: purchase.millId?.location?.latitude || "Nil",
      longitude: purchase.millId?.location?.longitude || "Nil",
      state: purchase.millId?.state_id?.name || "Nil",
      lga: purchase.millId?.lga_id?.name || "Nil",
      community: purchase.millId?.community_id?.name || "Nil",
      purchaseId: purchase.purchase_id,
      estimatedTonnage: purchase.estimated_tonnage,
      actualTonnage: purchase.actual_tonnage,
      paymentStatus: purchase.payment_status,
      category: purchase.estimate_category || "Nil",
      authorizationStatus: purchase.authorization_status,
      availableTonnage: purchase.availableTonnage || 0,
      createdBy:
        purchase.createdBy?.first_name + " " + purchase.createdBy?.last_name ||
        "Nil",
      agentId: purchase.createdBy?._id,
      channel: purchase.channel || "Nil",
      purchaseDate: moment(purchase.purchase_date).format("MM/DD/yyyy"),
      createdAt: moment(purchase.createdAt).format("MM/DD/yyyy"),
    };
  });
};

const formatDateRangePurchasesData = (data) => {
  return data.map((purchase) => {
    return {
      _id: purchase._id,
      farmerName: `${purchase.farmerId?.first_name || "Nil"} ${
        purchase.farmerId?.last_name || "Nil"
      }`,
      farmer_id: purchase.farmerId?._id || "Nil",
      price: Math.round(purchase.price).toString(),
      price_per_ton:
        purchase.price_per_ton || purchase.price / purchase.actual_tonnage,
      millId: purchase.millId?.id,
      millName: purchase.millId?.name || "Nil",
      millRTM: purchase.millId?.mill_id || "Nil",
      latitude: purchase.millId?.location?.latitude || "Nil",
      longitude: purchase.millId?.location?.longitude || "Nil",
      state: purchase.millId?.state_id?.name || "Nil",
      lga: purchase.millId?.lga_id?.name || "Nil",
      community: purchase.millId?.community_id?.name || "Nil",
      purchaseId: purchase.purchase_id,
      p1Cost: Math.round(purchase.p1Cost).toString(),
      p1CostPerTon: Math.round(purchase.p1CostPerTon).toString(),
      estimatedTonnage: purchase.estimated_tonnage,
      actualTonnage: purchase.actual_tonnage,
      paymentStatus: purchase.payment_status,
      category: purchase.estimate_category || "Nil",
      authorizationStatus: purchase.authorization_status,
      availableTonnage: purchase.availableTonnage || 0,
      createdBy:
        purchase.createdBy?.first_name + " " + purchase.createdBy?.last_name ||
        "Nil",
      agentId: purchase.createdBy?._id,
      channel: purchase.channel || "Nil",
      purchaseDate: moment(purchase.purchase_date).format("MM/DD/yyyy"),
    };
  });
};

const formatSinglePurchase = (purchase) => {
  return {
    ...purchase,
    price: Math.round(purchase.price).toString(),
    pricePerTon: Math.round(
      purchase.price / purchase.estimated_tonnage
    ).toString(),
    farmerName: `${purchase.farmerId?.first_name || "Nil"} ${
      purchase.farmerId?.last_name || "Nil"
    }`,
    millName: purchase.millId?.name || "Nil",
    millLocation: purchase.millId?.location || "Nil",
    latitude: purchase.millId?.location?.latitude.toString(),
    longitude: purchase.millId?.location?.longitude.toString(),
    purchaseId: purchase.purchase_id,
    p1Cost: Math.round(purchase.p1Cost).toString(),
    p1CostPerTon: Math.round(purchase.p1CostPerTon).toString(),
    estimatedTonnage: purchase.estimated_tonnage.toFixed(2),
    actualTonnage: purchase.actual_tonnage,
    paymentStatus: purchase.payment_status,
    category: purchase.estimate_category || "Nil",
    authorizationStatus: purchase.authorization_status,
    availableTonnage: purchase.availableTonnage || 0,
    createdBy: purchase.createdBy?.first_name || "Nil",
    purchaseDate: moment(purchase.purchase_date).format("DD-MM-YYYY"),
    createdAt: moment(purchase.createdAt).format("DD-MM-YYYY"),
  };
};

const state = () => ({
  purchases: [],
  purchaseToBeUpdated: {},
  purchase: null,
  isFilter: false,
  isDateFilter: false,
  filterMetaData: {
    filteredPurchases: [],
    count: 0,
    query: "",
  },
  FarmerTransactionSummaryAnnually: []
});

const getters = {
  getFarmerTransactionSummaryAnnually: (state) => {
    return state.farmerTransactionSummaryAnnually;
  },
  getPurchases: (state) => {
    return state.purchases;
  },

  getSinglePurchase: (state) => {
    return state.purchase;
  },

  getFilterStatus: (state) => {
    return state.isFilter;
  },

  getDateFilterStatus: (state) => {
    return state.isDateFilter;
  },

  getFilterMetaData: (state) => {
    return state.filterMetaData;
  },

  getPurchaseToBeUpdated: (state) => {
    return state.purchaseToBeUpdated;
  },
};

const mutations = {
  saveFarmerTransactionSummaryAnnually(state, payload) {
    state.FarmerTransactionSummaryAnnually = payload
  },
  setPurchases(state, payload) {
    state.purchases = payload;
  },

  setPurchase(state, payload) {
    state.purchase = payload;
  },

  setFilterStatus(state, payload) {
    state.isFilter = payload;
  },

  setDateFilterStatus: (state, payload) => {
    state.isDateFilter = payload;
  },

  setFilterPurchaseMetaData: (state, metaData) => {
    state.filterMetaData = {
      ...state.filterMetaData,
      ...metaData,
    };
  },

  setPurchaseToBeUodated(state, purchase) {
    state.purchaseToBeUpdated = purchase;
  },
};

const actions = {
  findPurchaseToBeUpdated({ commit, state }, purchaseId) {
    let purchaseToBeUpdated = state.purchases.find(
      (purchase) => purchase._id === purchaseId
    );

    purchaseToBeUpdated = {
      ...purchaseToBeUpdated,
      purchase_date: purchaseToBeUpdated.purchaseDate,
      actual_tonnage: purchaseToBeUpdated.actualTonnage.toString(),
      latitude: purchaseToBeUpdated.latitude.toString(),
      longitude: purchaseToBeUpdated.longitude.toString(),
    };

    commit("setPurchaseToBeUodated", purchaseToBeUpdated);
  },

  async getPurchases({ commit }, params) {
    const { page, searchTerm, limit } = params;

    try {
      const { data } = await APIConfig.get(
        `purchases/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
      );

      const purchases = formatPurchasesData(data?.data);

      commit("setPurchases", purchases);

      return {
        count: data?.count || 0,
        purchases: purchases || [],
      };
    } catch (error) {
      console.log(error);
    }
  },

  async getOnePurchase({ commit }, purchaseId) {
    try {
      let { data } = await APIConfig.get(`/purchases/${purchaseId}`);

      data = formatSinglePurchase(data);

      commit("setPurchase", data);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async updatePurchase(_, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/purchases/${payload.id}`,
        payload
      );

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async approveManyPurchases(_, purchaseIds) {
    try {
      const { data } = await APIConfig.patch(`/purchases/approvals`, {
        purchaseIds,
      });

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async deletePurchase({ commit }, id) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(`/purchases/${id}`);

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async deleteManyPurchases({ commit }, ids) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(`/purchases?purchaseIds=${ids}`);

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async filterPurchases({ commit }, params) {
    const { page, filterQuery, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/purchases/purchase-filter/?page=${page}&limit=${limit}&${filterQuery}`
      );

      let response = {
        filteredPurchases: [],
        count: 0,
        query: filterQuery,
      };

      if (data?.count > 0) {
        response.filteredPurchases = formatPurchasesData(data?.data);
        response.count = data?.count;
        commit("setFilterStatus", true);
        commit("setFilterPurchaseMetaData", response);
      } else {
        commit("setFilterPurchaseMetaData", response);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async filterPurchasesByDatePeriod({ commit }, params) {
    const { page, filterQuery, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/purchases/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      commit("setDateFilterStatus", true);
      // dispatch("setIsFilter", true);

      let response = {
        filteredPurchases: [],
        count: 0,
        query: filterQuery,
      };

      if (Object.keys(data).length > 0) {
        const purchases = formatDateRangePurchasesData(data?.data);

        response.filteredPurchases = purchases;
        response.count = data?.count;

        commit("setFilterPurchaseMetaData", response);

        return response;
      } else {
        return {
          count: 0,
          filteredPurchases: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getFarmerTransactionSummary(_, farmerId) {
    try {
      const { data } = await APIConfig.get(
        `purchases/transaction-summary/${farmerId}`
      );

      const formattedData = {
        totalAmount: roundToTwoDP(data["totalAmount"]).toLocaleString("en-US"),
        noOfTransactions: data["noOfTransactions"],
        totalTonnage: roundToTwoDP(data["totalTonnage"]).toLocaleString(
          "en-US"
        ),
        avgTonPerTransaction: roundToTwoDP(
          data["avgTonPerTransaction"]
        ).toLocaleString("en-US"),
        avgPricePerTon: roundToTwoDP(data["avgPricePerTon"]).toLocaleString(
          "en-US"
        ),
      };

      return formattedData;
    } catch (error) {
      console.log(error);
    }
  },
  async getFarmerTransactionSummaryAnnually({ commit }, query) {
    try {
      const { data } = await APIConfig.get(
        `purchases/transaction-summary/${query}`
      );
      commit("saveFarmerTransactionSummaryAnnually", data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
