<template>
  <div class="dashboard-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <div class="dashboard-container">
      <section class="dashboard-metric">
        <div style="margin: 0 auto; width: 100%; padding: 30px">
          <router-link to="/dashboard">
            <div class="dashboard-back">
              <InvertedBlackButton
                label="Go Back"
                width="5rem"
                height="2.6rem"
                padding="0px 0px"
              ></InvertedBlackButton>
            </div>
          </router-link>

          <div class="metric-header">
            <p class="metric-title-text"><b>Landed Cost</b> | {{ title }}</p>
          </div>
          <div class="border"></div>
          <div class="metric-data-range">
            <div class="metric-data-time-range">
              <div class="metric-toolbar">
                <span class="metric-data-caption">Range:</span>
                <div class="input-select">
                  <select
                    v-model="dateRange"
                    @change="handlePeriodChange($event)"
                  >
                    <option value="this_week">This Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="this_month">This Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="this_year">This Year</option>
                    <option value="last_year">Last year</option>
                  </select>
                </div>

                <div class="input">
                  <input
                    type="text"
                    placeholder="Start Date"
                    onfocus="(this.type='date')"
                    v-model="startDate"
                  />
                </div>

                <div class="input">
                  <input
                    type="text"
                    placeholder="End Date"
                    onfocus="(this.type='date')"
                    v-model="endDate"
                  />
                </div>

                <GreenButton
                  label="Fetch"
                  width="5rem"
                  height="2rem"
                  @click="handleCustomDateChange"
                />
              </div>
            </div>
          </div>

          <div class="metric-kpi-block">
            <div class="metric-kpi-collapsible mr-4">
              <ul class="kpi-block">
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="totalLandedCost"
                    kpiLabel="Total Landed Cost"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="pricePerTon"
                    kpiLabel="RM Price/Ton"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="purchaseOverheadPerTon"
                    kpiLabel="Purchase Overhead/Ton"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="logisticsOverheadCostPerTon"
                    kpiLabel="Logistic Overhead/Ton"
                  >
                  </StyledMetricText>
                </li>
              </ul>
            </div>
          </div>

          <div class="chart-wrapper">
            <div class="chart-data">
              <BarChart3 :series="costs" :isStacked="true" />
            </div>
          </div>
          <router-view />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import BarChart3 from "../charts/Barchart3.vue";
import StyledMetricText from "../../../components/metric-text/MetricText.vue";
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import { convertToFilterQueryString } from "../../../utils";
import { formatNumber } from "../../../utils/numbers-formatter";

export default {
  name: "LandedDash",

  components: {
    InvertedBlackButton,
    GreenButton,
    BarChart3,
    StyledMetricText,
    Loading,
  },

  data() {
    return {
      collapsibleVisible: false,
      individualView: false,
      type: "",
      dateRange: "this_week",
      startDate: "",
      endDate: "",
      title: "",
      costs: [],
      loading: false,
      totalLandedCost: 0,
      pricePerTon: 0,
      purchaseOverheadPerTon: 0,
      logisticsOverheadCostPerTon: 0,
    };
  },

  mounted() {
    this.fetchCostsOverview();
  },

  methods: {
    toggleCollasible() {
      this.collapsibleVisible = !this.collapsibleVisible;
    },

    checkType(event) {
      if (event.target.value === "individual") this.individualView = true;
      else this.individualView = false;
    },

    async fetchCostsOverview(query = "this_week") {
      this.loading = true;
      const {
        transformedData,
        period,
        purchaseOverheadCostPerTon,
        logisticsOverheadCostPerTon,
        totalLandedCostPerTon,
        pricePerTon,
      } = await this.$store.dispatch("overviews/getCostsOverview", query);
      this.costs = transformedData;
      this.pricePerTon = pricePerTon ? `₦` + formatNumber(pricePerTon) : 0;
      this.totalLandedCost = totalLandedCostPerTon
        ? `₦` + formatNumber(totalLandedCostPerTon)
        : 0;
      this.purchaseOverheadPerTon = purchaseOverheadCostPerTon
        ? `₦` + formatNumber(purchaseOverheadCostPerTon)
        : 0;
      this.logisticsOverheadCostPerTon = logisticsOverheadCostPerTon
        ? `₦` + formatNumber(logisticsOverheadCostPerTon)
        : 0;
      this.title = period + " Data Report";
      this.loading = false;
    },

    handlePeriodChange(event) {
      this.dateRange = event.target.value;
      this.fetchCostsOverview(this.dateRange);
    },

    handleCustomDateChange() {
      if (this.startDate === "" || this.endDate === "") return;

      const query = convertToFilterQueryString({
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.fetchCostsOverview(query);
    },
  },
};
</script>

<style scoped>
.dashboard-parent {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  margin: 0 auto;
  background: #ffffff;
}

.dashboard-back {
  margin-bottom: 30px;
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-caption {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
}

.metric-title-text {
  font-size: 16px;
  font-weight: 300;
  color: #131313;
}

.metric-title-text b {
  font-weight: 800;
}

.image-metric {
  cursor: pointer;
}
.metric-data-range,
.metric-kpi-core,
.metric-kpi-collapsible,
.chart-wrapper {
  height: auto;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;
}

.metric-data-range {
  padding: 15px;
}

.metric-kpi-core {
  width: 60%;
  /* padding-left: 0px; */
  margin-right: 20px;
}

.metric-kpi-collapsible {
  width: 60%;
}

.metric-kpi-collapsible .metric-kpi {
  width: 33%;
}

.metric-data-time-range {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.metric-toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.metric-view-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  /* flex-direction: end; */
}

.metric-data-caption {
  color: #333;
  font-weight: 400;
  /* font-size: 14px; */
}

.metric-kpi-block {
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.kpi-block {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  width: 100%;
}

.metric-kpi {
  list-style: none;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  width: 25%;
  /* text-align: center; */
}

.metric-kpi:not(:last-child) {
  border-right: 1px solid #a7a7a726;
}

.metric-collapse {
  display: inline;
  float: right;
  font-size: 12px;
  font-weight: 400;
}

.input,
.input-select {
  height: 30px;
  width: 110px;
}

select {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

input {
  width: 100%;
  height: 100%;
}

.image-footer {
  width: 0px;
  height: 40px;
}

.month-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.month-caption-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.dashboard-chart {
  margin-top: 20px;
}

.timerange {
  display: flex;
  gap: 10px;
}

.location-range {
  display: flex;
  gap: 10px;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 40px 0;
  font-size: 0.8rem;
}

.chart-toolbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.chart-view-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chart-search {
  display: flex;
  width: 30%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}
.chart-data {
  width: 100%;
  height: 400px;
  /* border: 1px solid #e7e7e7; */
  border-radius: 4px;
}

.border {
  border: 1px solid #e7e7e7;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

/* reset default styles */
a:hover {
  text-decoration: none;
}
</style>
