<template>
  <div>
    <component :is="layout">
      <!-- <router-view /> -->
    </component>
  </div>
</template>

<script>
export default {
  name: "Admin",

  computed: {
    layout() {
      return "dashboardLayout";
    },
  },
};
</script>

<style scoped></style>
