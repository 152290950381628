<template>
  <div class="inventory-parent-modal">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="inventory-modal-header">
      <div style="margin: 0 auto; width: 96%">
        <div class="modal-header-content">
          <h5 class="modal-header-caption">Inventory Details</h5>

          <div class="logo-container">
            <img src="../../assets/releaf-logo.png" alt="releaf-logo" />
          </div>

          <div class="icons-container">
            <div class="x-container" @click="closeModal">
              <img src="../../assets/x-icon.png" alt="x-icon" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="wrapper">
      <div class="tabs-container">
        <div
          class="tab"
          :class="{
            active: activeModalTab === 'Inventory',
          }"
          @click="updateActiveTab(atom)"
        >
          {{ atom }}
        </div>

        <div
          class="tab"
          :class="{
            active: activeModalTab === 'Discrepancy',
          }"
          @click="updateActiveTab(discrepancy)"
        >
          {{ discrepancy }}
        </div>
      </div>

      <section class="inventory-details" style="padding: 0.2rem; height: 105%">
        <div class="" style="margin: 0 auto; width: 100%">
          <div class="details" v-if="activeModalTab === 'Inventory'">
            <div class="details_section">
              <InfoBox
                header="Inventory ID"
                :info="inventory.inventoryId"
                class="div1"
              />

              <InfoBox
                header="Purchase ID"
                :info="inventory.purchaseId"
                class="div2"
              />

              <InfoBox
                header="Commodity Type"
                :info="inventory.commodityType"
                class="div3"
              />

              <InfoBox header="Quantity" :info="inventory.qty" class="div4" />

              <InfoBox
                header="Processing Status"
                :info="inventory.processingStatus"
                :status="processingStatusHash[inventory.processingStatus]"
                class="div5"
              />

              <InfoBox
                header="Consumption Date"
                :info="inventory.consumptionDate"
                class="div6"
              />

              <InfoBox
                header="P1 Cost"
                :info="vueNumberFormat(inventory.p1Cost, {})"
                class="div7"
              />

              <InfoBox
                header="P1 Cost/Ton"
                :info="vueNumberFormat(inventory.p1CostPerTon, {})"
                class="div8"
              />
            </div>

            <div class="details_section">
              <InfoBox
                header="Amount Paid"
                :info="vueNumberFormat(inventory.price, {})"
                class="div1"
              />

              <InfoBox
                header="Purchase Date"
                :info="inventory.purchaseDate"
                class="div2"
              />
              <InfoBox
                header="Log Cost/Ton"
                :info="
                  vueNumberFormat(inventory.logisticsOverheadCostPerTon, {})
                "
                class="div3"
              />

              <InfoBox
                header="Landed Cost/Ton"
                :info="vueNumberFormat(inventory.landedCostPerTon, {})"
                class="div4"
              />

              <InfoBox
                header="Updated At"
                :info="inventory.updatedAt"
                class="div5"
              />

              <InfoBox
                header="Status"
                :info="inventory.status"
                :status="statusHash[inventory.status]"
                class="div6"
              />

              <InfoBox
                header="Updated By"
                :info="inventory.createdBy"
                class="div7"
              />
            </div>
          </div>

          <div class="details" v-if="activeModalTab === 'Discrepancy'">
            <div class="discrepancy-table">
              <table class="data-table">
                <tr>
                  <th>Location</th>
                  <th>Quantity Available</th>
                  <th>Discrepancy</th>
                  <th>Discrepancy in Naira</th>
                </tr>

                <tr
                  v-for="(discrepancy, i) in inventory.discrepancyData"
                  :key="i"
                >
                  <td>{{ discrepancy.location }}</td>
                  <td>{{ discrepancy.qty }}</td>
                  <td>{{ discrepancy.discrepancy }}</td>
                  <td>
                    {{ vueNumberFormat(discrepancy.discrepancyInNaira, {}) }}
                  </td>
                </tr>
              </table>

              <div class="summary">
                <div class="total-discrepancy">
                  Total : {{ inventory.discrepancySummary.totalDiscrepancy }}
                </div>
                <div class="total-discrepancy-naira">
                  Total:
                  {{
                    vueNumberFormat(
                      inventory.discrepancySummary.totalDiscrepancyInNaira,
                      {}
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="location-container"
        v-if="activeModalTab !== 'Discrepancy'"
      >
        <div class="" style="margin: 0 auto; width: 100%">
          <h2 class="location-section-header">Location</h2>
          <div class="green-border"></div>

          <div class="location-details">
            <div
              class="location"
              v-for="(location, index) in inventory.inventoryLocation"
              :key="index"
              :class="[
                !isLastElement(inventory.inventoryLocation, index)
                  ? 'pointer'
                  : '',
              ]"
            >
              <p
                class="location-name"
                :class="[
                  isLastElement(inventory.inventoryLocation, index)
                    ? 'current-location'
                    : '',
                ]"
              >
                <small
                  class="pointer-number"
                  :class="[
                    isLastElement(inventory.inventoryLocation, index)
                      ? 'current-pointer'
                      : '',
                  ]"
                  >{{ index + 1 }}</small
                >

                Location: <span>{{ location.type }} ({{ location.name }})</span>
              </p>
              <p class="date">Updated Date: {{ location.updatedAt }}</p>

              <p class="location-qty">
                Available Qty: {{ location.tonnageAvailable }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="modal-footer">
      <GreenButton label="Download Report" width="12rem" height="3rem" />
    </div>
  </div>
</template>
<script>
import {
  discrepancytableHeaders,
  data,
} from "./table-headers/discrepancyHeaders";
import { inventoryLocation } from "./table-headers/headers";
import InfoBox from "../../components/info-box/InfoBox.vue";
import Loading from "vue-loading-overlay";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";

export default {
  name: "InventoryDetailsModal",
  components: {
    InfoBox,
    Loading,
    GreenButton,
  },
  data() {
    return {
      loading: false,
      modalStatus: "",
      inventory: {},
      activeModalTab: "Inventory",
      atom: "Inventory",
      discrepancy: "Discrepancy",
      statusHash: {
        "Completely Moved": "moved",
        "Partially Moved": "partially",
        Unmoved: "unmoved",
      },
      processingStatusHash: {
        Consumed: "consumed",
        Aggregated: "partially",
        Available: "available",
      },
      headers: discrepancytableHeaders,
      data: data,
      inventoryLocation: inventoryLocation,
    };
  },

  props: {
    inventoryId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.fetchInventory();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    async fetchInventory() {
      const inventoryId = this.inventoryId;
      this.loading = true;

      const inventory = await this.$store.dispatch(
        "inventories/fetchOneInventory",
        inventoryId
      );

      this.inventory = {
        ...this.inventory,
        ...inventory,
      };

      console.log(this.inventory);

      this.loading = false;
    },

    updateActiveTab(name) {
      this.activeModalTab = name;
    },

    isLastElement(array, index) {
      return array.length - 1 === index;
    },

    openDeleteModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openDeleteModal", true);
    },

    openEditModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openEditModal", true);
    },
  },
};
</script>

<style scoped>
.inventory-parent-modal {
  background-color: #fff;
  height: 100%;
}

.inventory-modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.1rem 1rem;
  border-bottom: 1px solid #e3e6f0;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header-content,
.details,
.contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details {
  padding: 5px;
}

.modal-header-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;

  /* margin: 5px auto; */
  width: 94%;
  /* border: 1px solid #fff; */
  margin: 0;
}
.content-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.logo-container img {
  width: 25px;
  height: 25px;
}
.discovery-decision-details {
  background: #232a34;
  border-radius: 16px;
  height: 180px;
  margin: 20px;
  padding-top: 20px;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  width: 19%;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
  margin-left: auto;
}

.border,
.green-border {
  margin-top: 10px;
  background: #ffffff;
  width: 100%;
}

.decision-label {
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.8px;
}

.details_section {
  margin-top: 15px;
  width: 45%;
  height: 300px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 20px;
  padding: 0px;
}

.wrapper {
  width: 96%;
  height: 300px;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.first {
  grid-area: 1 / 1 / 2 / 2;
  /* border: 1px solid #fff; */
  align-self: center;
}

.second {
  grid-area: 1 / 2 / 2 / 3;
  /* border: 1px solid #fff; */
}

.third {
  grid-area: 2 / 1 / 3 / 2;
  /* border: 1px solid #fff; */
}

.four {
  grid-area: 2 / 2 / 3 / 3;
  /* border: 1px solid #fff; */
}

.five {
  grid-area: 1 / 1 / 2 / 2;
}

.six {
  grid-area: 1 / 2 / 2 / 3;
}

.seven {
  grid-area: 2 / 1 / 3 / 2;
}

.eight {
  grid-area: 2 / 2 / 3 / 3;
}

.green-border {
  border-bottom: 2px dotted #669933;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 2 / 1 / 3 / 2;
}
.div4 {
  grid-area: 2 / 2 / 3 / 3;
}
.div5 {
  grid-area: 3 / 1 / 4 / 2;
}
.div6 {
  grid-area: 3 / 2 / 4 / 3;
}

.discovery-decision {
  margin: 50px 0px;
}

.actions-container {
  width: 100%;
  height: 5rem;
  background: #fff;
  border-top: 1px solid #b3b3b3;
  padding: 1rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions-img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.image-container {
  height: 2rem;
  width: 2rem;
}

.image-item {
  height: 100%;
  width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.discovery-decision-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 27px;
}

.tabs-container {
  width: 100%;
  display: flex;
  margin-top: 0.1rem;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 0px;
  padding: 0px;
}

.tab {
  margin: 0px 10px;
  margin-bottom: 0px;
  color: #b3b3b3;
  font-size: 12px;
  cursor: pointer;
  padding: 0px;
}

.active {
  color: #232a34 !important;
  border-bottom: 2px solid #669933;
  padding: 8px 16px;
}

.modal-footer {
  height: 70px;
  display: flex;
  background: #ffffff;
  border-top: 1px solid #b3b3b3;
}

.discrepancy-table {
  width: 100%;
  height: 250px;
}

/* Data table Styles */
.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 9px 11px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.location-container {
  width: 100%;
  height: 35%;
}

.location-section-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 5px;
}

.green-border {
  margin-top: 0px;
  background: #ffffff;
  border-bottom: 2px dotted #669933;
  width: 100%;
}

.location-details {
  background: #232a34;
  border-radius: 16px;
  height: auto;
  margin-top: 15px;
  padding: 20px;
  color: #fff;
}

.location {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 45%;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
}

.pointer-number {
  background: #fff;
  color: #000;
  border-radius: 9px;
  width: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -6px;
}

.current-pointer {
  background: #669933;
}

.pointer {
  border-left: 1px solid #fff;
}

.location-name,
.date,
.location-qty {
  margin-bottom: 5px;
}

.location-name {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: flex;
}

.location-name span {
  font-weight: 700;
  font-size: 13px;
}

.date,
.location-qty {
  font-size: 11px;
}

.current-location {
  color: #669933;
}

.data-table {
  width: 100%;
}

.data-table td,
.data-table th {
  border: 1px solid #ddd;
  padding: 9px 11px;
  text-align: center;
}

.data-table tr:nth-child(even) {
  background-color: #f9fafe;
}

.data-table tr:hover {
  background-color: #f9fafe;
}

.data-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #e7e7e7;
  color: #232a34;
}

.data-table td {
  font-size: 12.4px;
  color: #333333;
}

.summary {
  width: 50%;
  display: flex;
  margin-top: 10px;
  margin-left: auto;
  font-size: 12.4px;
  color: #333333;
}

.total-discrepancy {
  width: 35%;
  margin-left: auto;
}

.total-discrepancy-naira {
  width: 55%;
  margin-left: auto;
}
</style>
