export const usersTableHeaders = [
  { text: "Name", value: "name" },
  { text: "Email", value: "email" },
  { text: "Role", value: "userType" },
  { text: "Registration Date", value: "createdAt" },
  { text: "Status", value: "isActive" },
  { text: "Actions", value: "actions" },
];

export const vehiclesTableHeaders = [
  { text: "Vehicle Name", value: "name" },
  { text: "Plate Number", value: "vehicleNumber" },
  { text: "Driver", value: "driver" },
  { text: "Maximum Capacity", value: "maxCapacity" },
  { text: "Vehicle Model", value: "vehicleModel" },
  { text: "Fuel Economy", value: "fuelEconomy" },
  { text: "Fuel Cost", value: "fuelCost" },
  { text: "Actions", value: "actions" },
];

export const driversTableHeaders = [
  { text: "Driver's Name", value: "name" },
  { text: "Email", value: "email" },
  { text: "Phone Number", value: "phoneNum" },
  { text: "Actions", value: "actions" },
];

export const apTableHeaders = [
  { text: "Name", value: "name" },
  { text: "LGA", value: "lga" },
  { text: "State", value: "state" },
  { text: "Available Raw Material", value: "availableP1" },
  { text: "Latitude", value: "lat" },
  { text: "Longitude", value: "long" },
  { text: "Status", value: "isActive" },
  { text: "Actions", value: "actions" },
];

export const factoryTableHeaders = [
  { text: "Name", value: "name" },
  { text: "LGA", value: "lga" },
  { text: "State", value: "state" },
  { text: "Available Raw Material", value: "availableP1" },
  { text: "Latitude", value: "lat" },
  { text: "Longitude", value: "long" },
  { text: "Status", value: "isActive" },
  { text: "Actions", value: "actions" },
];
