<template>
  <div class="filter-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <div class="x-container">
      <div class="x-container-image" @click="closeModal">
        <img src="../../../assets/x-icon.png" alt="x-icon" />
      </div>
    </div>

    <section class="header">
      <div style="margin: 6% auto 0 auto; width: 92%">
        <h2 class="caption">Filter</h2>
        <p>Choose one or more of the following options</p>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="none"
            v-model="noFilterOption"
          />
          <label for="no-option">None</label>
        </div>
      </div>
    </section>

    <section class="approval-status">
      <div class="wrapper">
        <h5 class="approval-caption">Approval Status</h5>

        <div class="border"></div>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="Pending"
            name="status"
            @change="onChanged($event)"
          />
          <label for="no-option">Pending</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="Approved"
            name="status"
            @change="onChanged($event)"
          />
          <label for="no-option">Approved</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="Rejected"
            name="status"
            @change="onChanged($event)"
          />
          <label for="no-option">Rejected</label>
        </div>
      </div>
    </section>

    <section class="location-filter">
      <div class="wrapper">
        <h5 class="location-caption">Location</h5>

        <div class="border"></div>

        <div class="location-items">
          <SearchabledropFarmers
            :options="states"
            class="select"
            dropdownTitle="State"
            @onSelect="handleSelect"
            fieldName="state"
            :inputValue="millForm?.state"
          />

          <SearchabledropFarmers
            :options="lgas"
            class="select"
            dropdownTitle="Local Government"
            @onSelect="handleSelect"
            fieldName="lga"
            :inputValue="millForm?.lga"
          />

          <SearchabledropFarmers
            :options="communities"
            class="select"
            dropdownTitle="Community"
            @onSelect="handleSelect"
            fieldName="community"
            :inputValue="millForm?.community"
          />
        </div>
      </div>

      <div class="button-container">
        <GreenButton label="Apply Filter" @click="filterMill(page)" />
      </div>
    </section>
  </div>
</template>

<script>
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
// import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import Loading from "vue-loading-overlay";
// import SelectDropdownLga from "../../../components/form-inputs/select-dropdown/SelectDropdownLga.vue";
import { convertToFilterQueryString } from "../../../utils";
// import { lgas } from "../../../data/location/lgaNames";
// import { states } from "../../../data/location/states";
import SearchabledropFarmers from "../../../components/form-inputs/select-dropdown/SearchabledropFarmers.vue";
import { states } from "../../../data/location/states";
import { LGAs } from "../../../data/location/lga";
import { createToast } from "mosha-vue-toastify";

// import { lgasNameToSlug } from "../../../data/location/lgasHash";
// import { stateHash } from "../../../data/location/statesHash";
export default {
  name: "FilterMillsModal",
  components: {
    GreenButton,
    // SelectDropdown,
    Loading,
    // SelectDropdownLga,
    SearchabledropFarmers,
  },

  data() {
    return {
      millForm: {
        state: "",
        community: "",
        lga: "",
        approval_status: "",
      },
      noFilterOption: "",
      states: this.sortState(states),
      communities: [],
      lgas: [],
      communityResp: [],

      page: 1,
      loading: false,
    };
  },

  methods: {
    onChange(e) {
      this.millForm.approval_status = e.target.value;
    },

    handleSelect(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    sortState(stateArr) {
      return stateArr.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },

    async fetchLgas(state_slug) {
      try {
        this.loading = true;

        const lga = LGAs.filter((el) => el.parent_slug === state_slug);

        if (lga.length > 0) {
          lga.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }

        this.lgas = lga;

        this.loading = false;
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },

    async fetchCommunities(parent_slug) {
      try {
        this.loading = true;
        const data = await this.$store.dispatch("community/getCommunites");

        // console.log(data, "checking");

        const communities = data.filter(
          (el) => el?.parent_slug === parent_slug
        );

        if (communities.length > 0) {
          communities.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }

        this.communities = communities;

        // console.log(this.communities, "communities");

        this.loading = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    handleInputChange(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    closeModal() {
      this.$emit("onCloseModal", false);
    },

    async filterMill(page = 1) {
      const { state, community, lga, approval_status } = this.millForm;

      const params = {
        state_id: state?._id,
        community_id: community?.id,
        lga_id: lga?._id,
        authorization_status: approval_status,
      };

      // console.log({ params });

      const filterQuery = convertToFilterQueryString(params);

      try {
        this.loading = true;

        const { filteredMills } = await this.$store.dispatch(
          "mills/filterMills",
          {
            page,
            filterQuery,
          }
        );

        if (filteredMills) {
          console.log({ filteredMills });
          this.$emit("onCloseModal");
          this.loading = false;
        }
      } catch (error) {
        // console.log(error.message);
        this.loading = false;
      }
    },
  },

  watch: {
    "millForm.state"(newValue) {
      // console.log({ newValue });
      this.fetchLgas(newValue.slug);
    },

    "millForm.lga"(newValue) {
      // console.log({ newValue });
      if (newValue && this.millForm.state === "") {
        createToast("Please select a state", {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });
      } else {
        this.fetchCommunities(newValue.slug);
      }
    },
  },
};
</script>

<style scoped>
.filter-parent {
  background-color: white;
  height: 100%;
  /* border: 1px solid #000; */
  padding: 10px;
}

.x-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.x-container-image {
  cursor: pointer;
}

.location-items {
  margin-top: 20px;
}

.caption,
.location-caption,
.approval-caption {
  color: #232a34;
  font-weight: 800;
  margin-bottom: 2px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 0px;
}

input {
  display: block;
  cursor: pointer;
}

input[type="radio"] {
  accent-color: #669933;
}
label {
  margin-bottom: 0px;
  font-size: 10px;
  color: #333333;
  font-size: 13px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% auto 0 auto;
  width: 92%;
  /* border: 1px solid red; */
}

.border {
  border-bottom: 1px solid #545454;
  color: #a0a2a3;
}

p {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 7% auto 2% auto;
}
</style>
