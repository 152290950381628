<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Update P2_PROC Settings</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-container">
      <form class="form-container-div">
        <NumberInput
          placeholder="P2_PROC"
          fieldName="p2_proc"
          :inputValue="proc2Form.p2_proc"
          @onInputChange="handleInputChange"
          :isReadOnly="true"
        />

        <TextInput
          placeholder="Cost to Produce 1T PKO"
          fieldName="cost_to_produce_1t_pko"
          :inputValue="proc2Form.cost_to_produce_1t_pko"
          @onInputChange="handleInputChange"
        />
      </form>
    </section>

    <div class="border"></div>

    <div class="button-container">
      <GreenButton
        label="Update"
        width="10.5rem"
        height="2.9rem"
        @click="updateP2Proc"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import NumberInput from "../../../components/form-inputs/number-input/NumberInput.vue";

import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";

export default {
  name: "EditP2PROCModal",
  components: {
    TextInput,
    GreenButton,
    Loading,
    NumberInput,
  },
  data() {
    return {
      proc2Form: {
        p2_proc: this.p_proc || "",
        cost_to_produce_1t_pko: this.cost_2_produce_1t_pko || "",
      },
      loading: false,
    };
  },

  props: {
    p_proc: {
      type: String,
      required: true,
    },

    cost_2_produce_1t_pko: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.proc2Form = {
        ...this.proc2Form,
        ...obj,
      };
    },

    async updateP2Proc() {
      this.loading = true;

      const { p2_proc, cost_to_produce_1t_pko } = this.proc2Form;

      try {
        const payload = {
          p2_proc: Number(p2_proc),
          cost_to_produce_1t_pko: Number(cost_to_produce_1t_pko),
        };

        const response = await this.$store.dispatch(
          "profitInput/updatePriceAtomSettings",
          payload
        );

        if (response.acknowledged) {
          this.loading = false;
          this.$emit("updateP2ProcessingCostEvent", this.proc2Form);

          createToast("P2_PROC updated successfully", {
            type: "success",
            position: "top-right",
            timeout: 5000,

            onClose: () => window.location.reload(),
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  height: 100%;
  background: #fff;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.form-container {
  height: 230px;
  overflow-y: scroll;
}

.form-container-div {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
</style>
