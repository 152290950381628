import axios from "axios";
import firebase from "../config/firebase";
import { AuthUser } from "../utils/authUser";

let baseURL;
if (window.location.hostname === "salt.releaf.ng") {
  baseURL = "https://api.v2.salt.releaf.ng/api/v2";
} else {
  baseURL = "http://localhost:3000/api/v2";
}

export const APIConfig = axios.create({
  baseURL,
});

APIConfig.interceptors.request.use(
  (config) => {
    const authUser = new AuthUser();
    if (authUser.getToken()) {
      config.headers["Authorization"] = "Bearer " + authUser.getToken();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

APIConfig.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;

    if (err.response) {
      // Access Token was expired

      if (err.response.status === 401 && !originalRequest._retry) {
        try {
          firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
              const newToken = await user.getIdToken();
              localStorage.setItem("userToken", newToken);
              originalRequest.headers.common[
                "Authorization"
              ] = `Bearer ${newToken}`;
            }
          });
        } catch (_error) {
          return Promise.reject(_error);
        }
        // console.log("retrying failed request with new token...");
        return APIConfig(originalRequest).then((res) => res);
      }
    }

    return Promise.reject(err);
  }
);
