/* eslint-disable no-useless-catch */
import { IdConfig2 } from "../../config/idConfig";

const state = () => ({
  roleList: [],
  permissionsList: [],
});

const getters = {
  getRoleList: (state) => state.roleList,
  getPermissionsList: (state) => state.permissionsList,
};

const mutations = {
  saveRoleList(state, roleList) {
    state.roleList = roleList;
  },

  savePermissionsList(state, permissionsList) {
    state.permissionsList = permissionsList;
  },
};

const actions = {
  async fetchAllRoles({ commit }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await IdConfig2.get("/user-types");

      const roleList = data?.map((el, i) => {
        return {
          ...el,
          sn: `${i + 1}`,
          name: el.name,
        };
      });

      commit("saveRoleList", roleList);

      console.log({ roleList });

      return roleList;
    } catch (error) {
      throw error;
    }
  },

  async fetchAllPermissions({ commit }) {
    try {
      const { data } = await IdConfig2.get("/permissions");

      console.log({ data });

      const permissionsList = data?.map((el, i) => {
        return {
          ...el,
          sn: `0${1 + i}`,
          name: el.name,
        };
      });

      commit("savePermissionsList", permissionsList);

      console.log({ permissionsList });

      return permissionsList;
    } catch (error) {
      throw error;
    }
  },

  async updateRolePermission({ commit }, payload) {
    try {
      const { data } = await IdConfig2.patch(
        `/user-types/${payload.id}`,
        payload
      );

      commit("saveRoleList", data);

      return data;
    } catch (error) {
      throw error;
    }
  },

  async createRole({ commit }, payload) {
    console.log(payload);
    try {
      const { data } = await IdConfig2.post(`/user-types`, {
        name: payload,
      });

      commit("saveRoleList", data);

      return data;
    } catch (error) {
      throw error;
    }
  },

  async createPermission({ commit }, payload) {
    // console.log(payload);
    try {
      const { data } = await IdConfig2.post(`/permissions`, {
        name: payload,
      });

      commit("savePermissionsList", data);

      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
