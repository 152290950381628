const state = () => ({
  searchTerm: "",
});

const getters = {
  getLogisticsSearchTerm: (state) => {
    return state.searchTerm;
  },
};

const mutations = {
  saveLogisticsSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm;
  },
};

const actions = {
  setLogisticsSearchTerm({ commit }, searchTerm) {
    commit("saveLogisticsSearchTerm", searchTerm);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
