<template>
  <div class="parent">
    <div class="parent-wrapper">
      <div class="overhead__container">
        <div
          class="overhead-type"
          :class="{ active: activeOverheadType === 'Field Agent Imprest' }"
          @click="setActiveOverheadType(ImprestOverhead)"
        >
          {{ ImprestOverhead }}
        </div>

        <div
          class="overhead-type"
          :class="{ active: activeOverheadType === 'Supervisor Imprest' }"
          @click="setActiveOverheadType(SupervisorOverhead)"
        >
          {{ SupervisorOverhead }}
        </div>

        <div
          class="overhead-type"
          :class="{ active: activeOverheadType === 'Management' }"
          @click="setActiveOverheadType(ManagementOverhead)"
        >
          {{ ManagementOverhead }}
        </div>

        <div
          class="overhead-type"
          :class="{ active: activeOverheadType === 'Other OH' }"
          @click="setActiveOverheadType(OtherOverhead)"
        >
          {{ OtherOverhead }}
        </div>

        <div
          class="overhead-type"
          :class="{ active: activeOverheadType === 'Diesel' }"
          @click="setActiveOverheadType(DieselOverhead)"
        >
          {{ DieselOverhead }}
        </div>

        <div
          class="overhead-type"
          :class="{
            active: activeOverheadType === 'LMV Repairs And Maintenance',
          }"
          @click="setActiveOverheadType(MainteananceOverhead)"
        >
          {{ MainteananceOverhead }}
        </div>

        <div
          class="overhead-type"
          :class="{ active: activeOverheadType === 'Loaders Salary' }"
          @click="setActiveOverheadType(LoadersOverhead)"
        >
          {{ LoadersOverhead }}
        </div>

        <div
          class="overhead-type"
          :class="{ active: activeOverheadType === 'Tolls' }"
          @click="setActiveOverheadType(TollsOverhead)"
        >
          {{ TollsOverhead }}
        </div>

        <div
          class="overhead-type"
          :class="{ active: activeOverheadType === 'Staff Salary' }"
          @click="setActiveOverheadType(StaffOverhead)"
        >
          {{ StaffOverhead }}
        </div>
      </div>
      <!-- Search Bar Section -->
      <section class="search-bar-container">
        <div class="search-form">
          <input
            type="text"
            class="search-input"
            placeholder="Search by username or email address"
            @keyup.enter="handleSearchText"
          />
          <button><img src="../../assets/search-icon.png" alt="" /></button>
        </div>

        <div class="create-buttons__container">
          <InvertedGreenButton
            label="
              Create Target
            "
            width="
             11rem
            "
            height="2.9rem"
            iconName="target-icon"
            @click="openCreateTargetModal"
          />

          <GreenButton
            :label="
              activeOverheadType === DieselOverhead
                ? 'Add diesel record'
                : activeOverheadType === ImprestOverhead
                ? 'Add imprest record'
                : activeOverheadType === MainteananceOverhead
                ? 'Add maintenance record'
                : activeOverheadType === LoadersOverhead
                ? 'Add loaders record'
                : activeOverheadType === SupervisorOverhead
                ? 'Add supervisor record'
                : activeOverheadType === TollsOverhead
                ? 'Add tolls record'
                : activeOverheadType === ManagementOverhead
                ? 'Add mgmt record'
                : activeOverheadType === StaffOverhead
                ? 'Add staff record'
                : activeOverheadType === OtherOverhead
                ? 'Add other record'
                : 'Add diesel record'
            "
            width="12.8rem"
            height="2.9rem"
            iconName="add-icon"
            @click="openCreateOverheadModal"
          />
        </div>
      </section>

      <!-- Date Filter Section -->
      <section class="date-filter-container">
        <div class="wrapper">
          <p class="">Search by Period</p>
          <div class="controls-container">
            <div class="inputs-container">
              <div class="input">
                <input
                  type="text"
                  name=""
                  id="date"
                  placeholder="Select Start Date"
                  onfocus="(this.type = 'date')"
                  v-model="startDate"
                />
              </div>
              <div class="input">
                <input
                  type="text"
                  name=""
                  id="date"
                  placeholder="Select End Date"
                  onfocus="(this.type = 'date')"
                  v-model="endDate"
                />
              </div>
              <GreenButton
                label="Fetch"
                width="5rem"
                height="2rem"
                @click="fetchByDatePeriod(page)"
              />
            </div>

            <div class="buttons-container">
              <div class="">
                <download-excel :data="costJSON">
                  <InvertedBlackButton
                    label="Export"
                    width="8rem"
                    height="2.6rem"
                    iconName="excel-icon"
                  ></InvertedBlackButton>
                </download-excel>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="data-table-container">
        <EasyDataTable
          v-model:server-options="paginationMetaData"
          v-model:items-selected="itemsSelected"
          :server-items-length="count"
          :headers="
            activeOverheadType === 'Diesel'
              ? dieselTableHeaders
              : activeOverheadType === 'Field Agent Imprest'
              ? imprestTableHeaders
              : activeOverheadType === 'LMV Repairs And Maintenance'
              ? maintenanceTableHeaders
              : activeOverheadType === 'Loaders Salary'
              ? loadersTableHeaders
              : activeOverheadType === 'Supervisor Imprest'
              ? supeTableHeaders
              : activeOverheadType === 'Tolls'
              ? tollsTableHeaders
              : activeOverheadType === 'Management'
              ? mgmtTableHeaders
              : activeOverheadType === 'Staff Salary'
              ? staffTableHeaders
              : activeOverheadType === 'Other OH'
              ? otherTableHeaders
              : dieselTableHeaders
          "
          :items="costs"
          alternating
          table-class-name="customize-table"
          class="data-table"
          :loading="loading"
          buttons-pagination
        >
          <template #item-costPerLitre="item">
            <div>{{ vueNumberFormat(item.costPerLitre, {}) }}</div>
          </template>
          <template #item-tollAmount="item">
            <div>{{ vueNumberFormat(item.tollAmount, {}) }}</div>
          </template>
          <template #item-totalAmount="item">
            <div>{{ vueNumberFormat(item.totalAmount, {}) }}</div>
          </template>
          <template #item-amountPaid="item">
            <div>{{ vueNumberFormat(item.amountPaid, {}) }}</div>
          </template>
          <template #item-impressAmount="item">
            <div>{{ vueNumberFormat(item.impressAmount, {}) }}</div>
          </template>
          <template #item-totalCostOfRepairs="item">
            <div>{{ vueNumberFormat(item.totalCostOfRepairs, {}) }}</div>
          </template>
          <template #item-totalManagementSalaries="item">
            <div>
              {{ vueNumberFormat(item.totalManagementSalaries, {}) }}
            </div>
          </template>
          <template #item-totalStaffSalaries="item">
            <div>
              {{ vueNumberFormat(item.totalStaffSalaries, {}) }}
            </div>
          </template>
          <template #item-totalAgentsSalaries="item">
            <div>
              {{ vueNumberFormat(item.totalAgentsSalaries, {}) }}
            </div>
          </template>
          <template #item-actions="item">
            <div class="actions-wrapper">
              <i class="fa fa-pencil" @click="handleEdit(item)"></i>
              <i class="fa fa-trash" @click="handleDelete(item)"></i>
            </div>
          </template>
        </EasyDataTable>
      </div>
    </div>
    <!-- create new records -->
    <div
      class="add-modal"
      v-if="
        isCreateOverheadModalOpened && activeOverheadType === DieselOverhead
      "
    >
      <AddDieselModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isEditOverheadModalOpened && activeOverheadType === DieselOverhead"
    >
      <EditDieselModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isCreateTargetModalOpened && activeOverheadType === DieselOverhead"
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateOverheadModalOpened && activeOverheadType === ImprestOverhead
      "
    >
      <AddImprestModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isEditOverheadModalOpened && activeOverheadType === ImprestOverhead"
    >
      <EditImprestModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isCreateTargetModalOpened && activeOverheadType === ImprestOverhead"
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateOverheadModalOpened &&
        activeOverheadType === MainteananceOverhead
      "
    >
      <AddMaintenanceModal
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 1"
      />
     <AddMaintenanceModal2
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 2"
      />
    </div>

    <div
      class="add-modal"
      v-if="
        isEditOverheadModalOpened && activeOverheadType === MainteananceOverhead
      "
    >
      <EditMaintenanceModal1
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 1"
      />
     <EditMaintenanceModal2
       @onCloseModal="handleCloseModal"
       @onSetAddModalPage="handleAddModalPage"
       v-if="modalPageNum === 2"
      />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateTargetModalOpened && activeOverheadType === MainteananceOverhead
      "
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateOverheadModalOpened && activeOverheadType === LoadersOverhead
      "
    >
      <AddLoaderModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isCreateTargetModalOpened && activeOverheadType === LoadersOverhead"
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="add-modal"
      v-if="isEditOverheadModalOpened && activeOverheadType === LoadersOverhead"
    >
      <EditLoaderModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateOverheadModalOpened && activeOverheadType === SupervisorOverhead
      "
    >
      <AddSupervisorModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="
        isEditOverheadModalOpened && activeOverheadType === SupervisorOverhead
      "
    >
      <EditSupervisorImprest @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateTargetModalOpened && activeOverheadType === SupervisorOverhead
      "
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="add-modal"
      v-if="isCreateOverheadModalOpened && activeOverheadType === TollsOverhead"
    >
      <AddTollModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isEditOverheadModalOpened && activeOverheadType === TollsOverhead"
    >
      <EditTollModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isCreateTargetModalOpened && activeOverheadType === TollsOverhead"
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateOverheadModalOpened && activeOverheadType === ManagementOverhead
      "
    >
      <AddMgmtModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="
        isEditOverheadModalOpened && activeOverheadType === ManagementOverhead
      "
    >
      <EditMgmtModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateTargetModalOpened && activeOverheadType === ManagementOverhead
      "
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="add-modal"
      v-if="isCreateOverheadModalOpened && activeOverheadType === StaffOverhead"
    >
      <AddStaffModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isEditOverheadModalOpened && activeOverheadType === StaffOverhead"
    >
      <EditStaffModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isCreateTargetModalOpened && activeOverheadType === StaffOverhead"
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="add-modal"
      v-if="isCreateOverheadModalOpened && activeOverheadType === OtherOverhead"
    >
      <AddOtherModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isEditOverheadModalOpened && activeOverheadType === OtherOverhead"
    >
      <EditOtherModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isCreateTargetModalOpened && activeOverheadType === OtherOverhead"
    >
      <CreateTargetModal
        :heading="activeOverheadType"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <!-- Edit modals -->
    <div class="add-modal" v-if="isEditUserModalOpened">
      <EditUserModal @onCloseModal="handleCloseModal" />
    </div>

    <!-- Delete Modals -->
    <div class="delete-modal" v-if="isDeleteModalOpened">
      <DeleteModal
        :heading="activeOverheadType"
        :itemId="itemId"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <!-- Overlay -->
    <div
      class="dark-overlay"
      v-if="
        isCreateOverheadModalOpened ||
        isEditOverheadModalOpened ||
        isDeleteModalOpened ||
        isCreateTargetModalOpened
      "
      @click="closeOverlay"
    ></div>
  </div>
</template>

<script>
import {
  dieselTableHeaders,
  imprestTableHeaders,
  maintenanceTableHeaders,
  loadersTableHeaders,
  supeTableHeaders,
  tollsTableHeaders,
  mgmtTableHeaders,
  staffTableHeaders,
  otherTableHeaders,
} from "./table-headers/headers";
import InvertedGreenButton from "../../components/buttons/inverted-green-button/InvertedGreenButton.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import AddDieselModal from "./modals/diesel/AddDieselCost.vue";
import EditDieselModal from "./modals/diesel/EditDieselCost.vue";
import CreateTargetModal from "./modals/CreateTargetModal.vue";
import AddImprestModal from "./modals/imprest/AddImprestModal.vue";
import EditImprestModal from "./modals/imprest/EditImprestModal.vue";
import AddLoaderModal from "./modals/loader/AddLoaderModal.vue";
import EditLoaderModal from "./modals/loader/EditLoaderModal.vue";
import AddMaintenanceModal from "./modals/maintenance/AddMaintenanceModal.vue";
import EditMaintenanceModal1 from "./modals/maintenance/EditMaintenanceModal1.vue";
import EditMaintenanceModal2 from "./modals/maintenance/EditMaintenanceModal2.vue";
import AddMaintenanceModal2 from "./modals/maintenance/AddMaintenanceModal2.vue";
import AddSupervisorModal from "./modals/supervisor/AddSupeModal.vue";
import EditSupervisorImprest from "./modals/supervisor/EditSupervisorImprest.vue";
import AddTollModal from "./modals/tolls/AddTollModal.vue";
import EditTollModal from "./modals/tolls/EditTollModal.vue";
import AddMgmtModal from "./modals/mgmt/AddMgmtModal.vue";
import EditMgmtModal from "./modals/mgmt/EditMgmtModal.vue";
import AddStaffModal from "./modals/staff/AddStaffModal.vue";
import EditStaffModal from "./modals/staff/EditStaffModal.vue";
import AddOtherModal from "./modals/other/AddOtherModal.vue";
import EditOtherModal from "./modals/other/EditOtherModal.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import DeleteModal from "./modals/DeleteModal.vue";

import { NameConvention } from "../../utils/nameConvention";
import { convertToFilterQueryString } from "../../utils";

export default {
  name: "Overhead",

  components: {
    GreenButton,
    AddDieselModal,
    CreateTargetModal,
    AddImprestModal,
    AddLoaderModal,
    AddMaintenanceModal,
    AddMaintenanceModal2,
    AddSupervisorModal,
    AddTollModal,
    AddMgmtModal,
    AddStaffModal,
    AddOtherModal,
    InvertedBlackButton,
    InvertedGreenButton,
    DeleteModal,
    EditDieselModal,
    EditImprestModal,
    EditLoaderModal,
    EditSupervisorImprest,
    EditTollModal,
    EditMgmtModal,
    EditStaffModal,
    EditOtherModal,
    EditMaintenanceModal1,
    EditMaintenanceModal2,
  },

  data() {
    return {
      modalPageNum: 1,
      startDate: "",
      endDate: "",
      activeOverheadType: "Field Agent Imprest",
      DieselOverhead: "Diesel",
      ImprestOverhead: "Field Agent Imprest",
      MainteananceOverhead: "LMV Repairs And Maintenance",
      LoadersOverhead: "Loaders Salary",
      SupervisorOverhead: "Supervisor Imprest",
      TollsOverhead: "Tolls",
      ManagementOverhead: "Management",
      StaffOverhead: "Staff Salary",
      OtherOverhead: "Other OH",
      itemsSelected: [],
      paginationMetaData: {
        page: 1,
        rowsPerPage: 10,
      },
      itemId: "",
      count: 0,
      costs: [],
      diesel: [],
      imprest: [],
      maintenance: [],
      loaders: [],
      tolls: [],
      supervisors: [],
      mgmt: [],
      staff: [],
      others: [],
      costJSON: [],
      loading: false,
      dieselTableHeaders: dieselTableHeaders,
      imprestTableHeaders: imprestTableHeaders,
      maintenanceTableHeaders: maintenanceTableHeaders,
      loadersTableHeaders: loadersTableHeaders,
      supeTableHeaders,
      tollsTableHeaders,
      mgmtTableHeaders,
      staffTableHeaders,
      otherTableHeaders,
      isCreateOverheadModalOpened: false,
      isCreateTargetModalOpened: false,
      isEditOverheadModalOpened: false,
      isDeleteModalOpened: false,
      globalFilterQuery: "",
    };
  },

  mounted() {
    this.fetchCosts();
  },

  computed: {
    refreshStatus() {
      return this.$store.getters["overhead/getRefreshStatus"];
    },

    filterMetaData() {
      return this.$store.getters["overhead/getFilterMetaData"];
    },
  },

  methods: {
    setActiveOverheadType(type) {
      this.activeOverheadType = type;
      this.$store.dispatch("overhead/setRefreshStatus", false);
    },

    async fetchCosts(page = 1, limit = 10) {
      const nameConvention = new NameConvention(this.activeOverheadType);
      const overheadCostsInCamelCase = nameConvention.camelize() + "Costs";
      const overheadCostsInPascalCase = nameConvention.toPascalCase() + "Costs";

      this.loading = true;

      this.costs = [];

      const { cost, count } = await this.$store.dispatch(
        `${overheadCostsInCamelCase}/fetch${overheadCostsInPascalCase}`,
        {
          page,
          limit,
        }
      );

      this.costs = cost;
      this.costJSON = cost;
      this.count = count;
      this.loading = false;

      this.$store.dispatch("overhead/updateDateFilterStatus", false);
    },

    async fetchByDatePeriod(page = 1, limit = 10) {
      const nameConvention = new NameConvention(this.activeOverheadType);
      const overheadCostsInCamelCase = nameConvention.camelize() + "Costs";

      if (this.startDate === "" || this.endDate === "") return;
      const query = convertToFilterQueryString({
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.loading = true;

      this.$store.dispatch("overhead/updateDateFilterStatus", true);

      this.costs = [];

      const { cost, count } = await this.$store.dispatch(
        `${overheadCostsInCamelCase}/filterByDatePeriod`,
        {
          page,
          limit,
          filterQuery: query,
        }
      );

      this.$store.dispatch("overhead/updateFilterMetaData", {
        filteredCosts: cost,
        count,
        query,
      });

      this.costs = cost;
      this.count = count;
      this.costJSON = cost;

      this.loading = false;
    },

    openCreateTargetModal() {
      this.isCreateTargetModalOpened = !this.isCreateTargetModalOpened;
    },

    openCreateOverheadModal() {
      this.isCreateOverheadModalOpened = !this.isCreateOverheadModalOpened;
    },

    openEditOverheadModal() {
      this.isEditOverheadModalOpened = !this.isEditOverheadModalOpened;
    },

    handleCloseModal(status) {
      this.isCreateOverheadModalOpened = status;
      this.isCreateTargetModalOpened = status;
      this.isEditOverheadModalOpened = status;
      this.isDeleteModalOpened = status;
    },

    handleEdit(item) {
      this.itemId = item._id;
      const nameConvention = new NameConvention(this.activeOverheadType);
      const overheadCostsInCamelCase = nameConvention.camelize() + "Costs";

      this.$store.dispatch(
        `${overheadCostsInCamelCase}/findCostToBeUpdated`,
        this.itemId
      );

      this.$store.dispatch("overhead/setRefreshStatus", false);

      this.openEditOverheadModal();
    },

    handleDelete(item) {
      this.itemId = item._id;
      this.isDeleteModalOpened = true;
    },

    handleAddModalPage(pagenum) {
      this.modalPageNum = pagenum;
    },

    closeOverlay() {
      this.isCreateOverheadModalOpened = false;
    },
  },

  watch: {
    activeOverheadType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchCosts();
      }
    },

    refreshStatus(newValue) {
      if (newValue) {
        this.fetchCosts();
      }
    },

    paginationMetaData: {
      handler: function (newValue) {
        const isDateFilter =
          this.$store.getters["overhead/getDateFilterStatus"];

        if (isDateFilter) {
          this.fetchByDatePeriod(
            newValue.page,
            newValue.rowsPerPage,
            this.globalFilterQuery
          );
        } else this.fetchCosts(newValue.page, newValue.rowsPerPage);
      },
      deep: true,
    },

    filterMetaData(newValue) {
      const { filteredCosts, count, query } = newValue;

      this.globalFilterQuery = query;
      this.costs = filteredCosts;
      this.count = count;
    },
  },
};
</script>

<style scoped>
.parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
  overflow-y: scroll;
}

.parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
  overflow-y: scroll;
}

.parent-wrapper {
  width: 98%;
  /* border: 1px solid #000; */
  margin: 1% auto 0 auto;
  height: 100%;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.border {
  margin-top: 10px;
  border-bottom: 0.7px solid #fff1f1;
}

.overhead__container {
  width: 100%;
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

.overhead-type {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #232a34;
  font-size: 13px;
  cursor: pointer;
}

.active {
  color: #232a34 !important;
  border-bottom: 2px solid #669933;
  padding: 8px 16px;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.date-filter-container {
  width: 100%;
  margin-top: 1.5%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 85%;
}

.wrapper p {
  margin-bottom: 0px;
}

.date-filter-container p {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 0.78rem;
  line-height: 24px;
  color: #333333;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.create-buttons__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 33%;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 11%;
}

.data-table-container {
  width: 100%;
  /* border: 1px solid green; */
  height: 79.9%;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

/* Data table Styles */
.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 7px 10px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;

  width: 100px;
  height: 32px;

  border-radius: 41px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.approved {
  color: #669933;
  background: rgba(102, 153, 51, 0.15);
}

.pending {
  color: #ed0a0a;
  background: rgba(237, 10, 10, 0.15);
}

.add-modal {
  max-width: 600px;
  height: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.delete-modal {
  max-width: 500px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

.actions-wrapper i {
  cursor: pointer;
  margin-right: 20px;
}
</style>
