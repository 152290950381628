import axios from "axios";

const redirectUser = () => {
  const idServiceHost = process.env.VUE_APP_AUTH_SERVICE_HOST;
  window.location.href = `${idServiceHost}/signin?redirect=${window.location.origin}${window.location.pathname}`;
};

export const getTokenFromCookie = () => {
  let token = null;

  if (
    process.env.VUE_APP_AXIOS_STATE === "bare_metal" ||
    process.env.VUE_APP_AXIOS_STATE === "docker-compose"
  ) {
    document.cookie = `token=${localStorage.getItem(
      "token"
    )}path=/;max-age=3600`;
    // "token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMzJhMzlmMTVjZGI3MDAxOThlNzBmYyIsImVtYWlsIjoiYWRtaW5AcmVsZWFmLm5nIiwicm9sZXMiOlsiY3VzdG9tZXIiXSwidXNlclR5cGUiOiJBRE1JTiIsImlhdCI6MTY3MDI0NjE5MX0.Ixa74lUw3yE7Fq3d5yi20hmxkq9LLw-hOpMlhvfpJek;path=/;max-age=3600";
  }

  // hardcode JWT
  // To be removed when login page is ready
  document.cookie = `token=${localStorage.getItem("token")}path=/;max-age=3600`;
  // "token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMzJhMzlmMTVjZGI3MDAxOThlNzBmYyIsImVtYWlsIjoiYWRtaW5AcmVsZWFmLm5nIiwicm9sZXMiOlsiY3VzdG9tZXIiXSwidXNlclR5cGUiOiJBRE1JTiIsImlhdCI6MTY3MDI0NjE5MX0.Ixa74lUw3yE7Fq3d5yi20hmxkq9LLw-hOpMlhvfpJek;path=/;max-age=3600";

  const cookies = document.cookie.split(";");
  // console.log('document ', document.cookie);

  // console.log('cookies ', cookies);
  if (cookies.length > 0) {
    // iterate through the values
    for (let i in cookies) {
      const item = cookies[i].split("=");
      const key = item[0];
      const value = item[1];
      if (key.trim() === "token") {
        token = value;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        break;
      }
    }

    return token;
  }

  console.log({ token });
  return token;
};

export { redirectUser };
