import moment from "moment";
import { stateSlugToName } from "../../data/location/statesHash";
import { lgaSlugToName } from "../../data/location/lgasHash";
import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  communityList: [],
  stateList: [],
  lgaList: [],
  community: null,
});

const getters = {
  getCommunityList: (state) => {
    return state.communityList;
  },

  getCommunity: (state) => {
    return state.community;
  },

  getLgaList: (state) => {
    return state.lgaList;
  },
};

const mutations = {
  saveCommunities(state, community) {
    state.communityList = community;
  },

  saveOneCommunity(state, community) {
    state.community = community;
  },

  saveLga(state, lga) {
    state.lgaList = lga;
  },

  saveStates(state, stateList) {
    state.stateList = stateList;
  },
};

const actions = {
  async fetchCommunities({ commit }) {
    try {
      const response = await APIConfig.get("/community");

      const list = response.data?.data.map((community) => {
        return {
          id: community._id,
          name: community.name,
        };
      });

      if (list.length) await commit("saveCommunities", list);

      return list;
    } catch (error) {
      console.log(error.message);
    }
  },

  async getCommunites({ commit }) {
    try {
      const response = await APIConfig.get("/community");


      const list = response.data?.data;

      if (list.length) await commit("saveCommunities", list);

      return list;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchLgas({ commit }) {
    try {
      const response = await APIConfig("/lga");

      const list = response.data.data?.slice(0, 20);

      if (list.length) await commit("saveLga", list);

      return list;
    } catch (error) {
      console.log(error.message);
    }
  },

  async searchCommunities({ commit }, searchTerm) {
    try {
      const response = await APIConfig.get(
        `/community/?searchTerm=${searchTerm}`
      );

      const list = response.data?.data?.slice(0, 20);

      if (list.length) await commit("saveCommunities", list);

      return list;
    } catch (error) {
      console.log(error.message);
    }
  },

  async addCommunity({ commit }, payload) {

    try {
      const { data } = await APIConfig.post("/community", payload);

      if (data) await commit("saveCommunities", data);
      return data;
    } catch (error) {
      console.log(error.message);
      return error.response.data;
    }
  },

  async fetchOneCommunity({ commit }, communityId) {
    try {
      let { data } = await APIConfig.get(`/community/${communityId}`);

      commit("saveOneCommunity", data);

      data = {
        ...data,
        state: stateSlugToName[data.state],
        LGA: lgaSlugToName[data.LGA],
        createdAt: moment(data.createdAt).format("DD-MM-YYYY"),
      };

      return data;
    } catch (error) {
      console.log(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
