<template>
  <div class="dashboard-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <div class="dashboard-container">
      <section class="dashboard-metric">
        <div style="margin: 0 auto; width: 96%">
          <router-link to="/dashboard">
            <div class="dashboard-back">
              <InvertedBlackButton
                label="Go Back"
                width="5rem"
                height="2.6rem"
                padding="0px 0px"
              ></InvertedBlackButton>
            </div>
          </router-link>

          <div class="metric-header">
            <p class="metric-title-text"><b>Overhead Cost</b> | {{ title }}</p>
          </div>
          <div class="border"></div>
          <div class="metric-data-range">
            <div class="metric-data-time-range">
              <div class="metric-toolbar">
                <span class="metric-data-caption">Range:</span>
                <div class="input-select">
                  <select
                    v-model="dateRange"
                    @change="handlePeriodChange($event)"
                  >
                    <option value="this_week">This Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="this_month">This Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="this_year">This Year</option>
                    <option value="last_year">Last year</option>
                  </select>
                </div>

                <div class="input">
                  <input
                    type="text"
                    placeholder="Start Date"
                    onfocus="(this.type='date')"
                    v-model="startDate"
                    @change="handleCustomDateChange"
                  />
                </div>

                <div class="input">
                  <input
                    type="text"
                    placeholder="End Date"
                    onfocus="(this.type='date')"
                    v-model="endDate"
                    @change="handleCustomDateChange"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="metric-kpi-block">
            <div class="metric-kpi-core">
              <ul class="kpi-block">
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="overheadVal + `k`"
                    kpiLabel="Current in Naira"
                  >
                  </StyledMetricText>
                  <!-- <span>Current/Target in Tonnes</span> -->
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="thresholdVal + 'K'"
                    kpiLabel="Threshold in Naira"
                    kpiColor2="#8fc54d"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="isNaN(percentVal) ? 0 : percentVal + `%`"
                    kpiColor1="#8fc54d"
                    kpiLabel="% of Threshold"
                  >
                  </StyledMetricText>
                </li>
              </ul>
            </div>
            <div class="metric-kpi-collapsible" v-show="collapsibleVisible">
              <ul class="kpi-block">
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="purchaseOverheadCostPerTon + 'K'"
                    kpiColor1="#8fc54d"
                    kpiLabel="Purchase Overhead"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="logisticsOverheadCostPerTon + 'K'"
                    kpiLabel="Logistics Overhead"
                  >
                  </StyledMetricText>
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="collapsibleVisible"
            class="metric-collapse"
            @click="toggleCollasible"
          >
            See less &gt;
          </div>
          <div v-else class="metric-collapse" @click="toggleCollasible">
            See more &lt;
          </div>
          <div class="chart-section">
            <div class="chart-left">
              <div class="chart-titles">
                <span class="chart-title-text">Overhead Cost Table</span>
              </div>
              <div class="chart-wrapper">
                <div class="chart-data">
                  <EasyDataTable
                    :headers="purchaseOveheadCostsHeaders"
                    :items="purchaseOverheadCostsList"
                    alternating
                    table-class-name="customize-table"
                    class="data-table"
                  >
                    <template #item-target="item">
                      <div>{{ vueNumberFormat(item.target, {}) }}</div>
                    </template>
                    <template #item-costPerTon="item">
                      <div>{{ vueNumberFormat(item.costPerTon, {}) }}</div>
                    </template>
                    <template #item-totalCost="item">
                      <div>{{ vueNumberFormat(item.totalCost, {}) }}</div>
                    </template>
                  </EasyDataTable>
                </div>

                <div class="chart-data">
                  <EasyDataTable
                    :headers="logisticsOveheadCostsHeaders"
                    :items="logisticsOverheadCostsList"
                    alternating
                    table-class-name="customize-table"
                    class="data-table"
                  >
                    <template #item-target="item">
                      <div>{{ vueNumberFormat(item.target, {}) }}</div>
                    </template>
                    <template #item-costPerTon="item">
                      <div>{{ vueNumberFormat(item.costPerTon, {}) }}</div>
                    </template>
                    <template #item-totalCost="item">
                      <div>{{ vueNumberFormat(item.totalCost, {}) }}</div>
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
            <div class="chart-right">
              <div class="chart-titles">
                <span class="chart-title-text">Overhead Cost Analytics</span>
              </div>
              <div class="chart-wrapper">
                <div class="input-select">
                  <select v-model="dept" @change="changeDept($event)">
                    <option value="purchase">Purchase</option>
                    <option value="logistics">Logistics</option>
                  </select>
                </div>
                <div class="chart-data">
                  <BarChart3
                    :series="
                      dept === 'purchase'
                        ? purchaseOverheadChart
                        : logisticsOverheadChart
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <router-view />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import StyledMetricText from "../../../components/metric-text/MetricText.vue";
import {
  purchaseOverheadCoststableHeaders,
  logisticsOverheadCoststableHeaders,
} from "../table-headers/headers";
import BarChart3 from "../charts/Barchart3.vue";
import { convertToFilterQueryString } from "../../../utils";

export default {
  name: "OverheadDash",

  data() {
    return {
      collapsibleVisible: true,
      individualView: false,
      type: "",
      overheadVal: 0,
      thresholdVal: 0,
      percentVal: 0,
      diffVal: 0,
      startDate: "",
      endDate: "",
      purchaseOveheadCostsHeaders: purchaseOverheadCoststableHeaders,
      logisticsOveheadCostsHeaders: logisticsOverheadCoststableHeaders,
      loading: false,
      purchaseOverheadChart: "",
      logisticsOverheadChart: "",
      dateRange: "this_week",
      view: "sourcing",
      purchaseOverheadCostsList: [],
      logisticsOverheadCostsList: [],
      purchaseOverheadCostPerTon: 0,
      logisticsOverheadCostPerTon: 0,
      dept: "purchase",
      title: "",
    };
  },

  components: {
    InvertedBlackButton,
    StyledMetricText,
    BarChart3,
    Loading,
  },

  mounted() {
    this.fetchOverheadCostData();
    this.fetchOverheadCostChartData();
  },

  methods: {
    toggleCollasible() {
      this.collapsibleVisible = !this.collapsibleVisible;
    },

    checkType(event) {
      if (event.target.value === "individual") this.individualView = true;
      else this.individualView = false;
    },

    setActiveNav(nav) {
      this.activeNav = nav;
    },

    handlePeriodChange(event) {
      this.dateRange = event.target.value;
      this.fetchOverheadCostData(this.dateRange);
      this.fetchOverheadCostChartData(this.dateRange);
    },

    changeDept(event) {
      this.dept = event.target.value;
    },

    handleCustomDateChange() {
      if (this.startDate === "" || this.endDate === "") return;

      if (this.startDate !== "" && this.endDate !== "") {
        const query = convertToFilterQueryString({
          startDate: this.startDate,
          endDate: this.endDate,
        });

        this.fetchOverheadCostData(query);
        this.fetchOverheadCostChartData(query);
      }
    },

    async fetchOverheadCostData(query = "this_week") {
      this.loading = true;
      const data = await this.$store.dispatch(
        "overviews/getOverheadCost",
        query
      );

      this.overheadVal = `₦` + data.totalCost;
      this.thresholdVal = `₦` + data.totalThreshold;
      this.purchaseOverheadCostPerTon = `₦` + data.purchaseOverheadCostPerTon;
      this.logisticsOverheadCostPerTon = `₦` + data.logisticsOverheadCostPerTon;
      this.diffVal = isFinite(this.thresholdVal - this.overheadVal)
        ? this.thresholdVal - this.overheadVal
        : 0;
      this.percentVal = isFinite(this.diffVal / this.thresholdVal)
        ? ((this.diffVal / this.thresholdVal) * 100).toFixed(0)
        : 0;
      this.title = data.period + " Data Report";
      this.loading = false;
    },

    async fetchOverheadCostChartData(query = "this_week") {
      this.loading = true;
      const {
        purchaseAndLogistics,
        purchaseOverheadCost,
        logisticsOverheadCost,
      } = await this.$store.dispatch(
        "overviews/getOverheadCostChartData",
        query
      );
      this.purchaseOverheadCostsList = purchaseOverheadCost;
      this.logisticsOverheadCostsList = logisticsOverheadCost;
      const purchaseOverheadCosts = purchaseAndLogistics.find(
        (group) => group.group === "purchase"
      ).series;
      const logisticsOverheadCosts = purchaseAndLogistics.find(
        (group) => group.group === "logistics"
      ).series;
      this.purchaseOverheadChart = purchaseOverheadCosts;
      this.logisticsOverheadChart = logisticsOverheadCosts;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.dashboard-parent {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  margin: 0 auto;
  background: #ffffff;
}

.dashboard-back {
  margin-top: 25px;
  margin-bottom: 30px;
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-caption {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
}

.metric-title-text {
  font-size: 16px;
  font-weight: 300;
  color: #131313;
}

.metric-title-text b {
  font-weight: 800;
}

.image-metric {
  cursor: pointer;
}
.metric-data-range,
.metric-kpi-core,
.metric-kpi-collapsible,
.chart-wrapper {
  height: auto;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;
}

.metric-data-range {
  padding: 15px;
}

.metric-kpi-core {
  width: 60%;
  /* padding-left: 0px; */
  margin-right: 20px;
}

.metric-kpi-collapsible {
  width: 40%;
}

.metric-kpi-collapsible .metric-kpi {
  width: 50%;
}

/* .metric-kpi-collapsible {
  width: 40%;
} */

.metric-data-time-range {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
}

.metric-toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.metric-data-caption {
  color: #333;
  font-weight: 400;
  /* font-size: 14px; */
}

.metric-kpi-block {
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.kpi-block {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  width: 100%;
}

.metric-kpi {
  list-style: none;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  width: 25%;
  /* text-align: center; */
}

.metric-kpi:not(:last-child) {
  border-right: 1px solid #a7a7a726;
}

.metric-collapse {
  display: inline;
  float: right;
  font-size: 12px;
  font-weight: 400;
}

.input,
.input-select {
  height: 30px;
  width: 110px;
}

select {
  width: 100%;
  height: 100%;
}

input {
  width: 100%;
  height: 100%;
}

.image-footer {
  width: 0px;
  height: 40px;
}

.month-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.month-caption-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.dashboard-chart {
  margin-top: 20px;
}

.timerange {
  display: flex;
  gap: 10px;
}

.location-range {
  display: flex;
  gap: 10px;
}

.chart-section {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
}

.chart-left,
.chart-right {
  width: 55%;
  height: auto;
  align-items: center;
}

.chart-title-text {
  font-size: 14px;
  font-weight: 900;
  color: #131010;
}
.chart-wrapper {
  padding: 30px;
  margin: 20px 0;
  font-size: 0.8rem;
  border-radius: 4px;
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.4);
}

.chart-toolbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.chart-view-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chart-search {
  display: flex;
  width: 30%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}
.chart-data {
  width: 100%;
}

.data-table {
  width: 100% !important;
}

.border {
  border: 1px solid #e7e7e7;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

/* reset default styles */
a:hover {
  text-decoration: none;
}

.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 9px 11px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}
</style>
