<template>
  <div>
    <apexchart
      width="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "BarChart2",
  props: {
    series: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "apexchart",
        },
        xaxis: {
          categories: [],
        },
      },

      seriesData: this.series,
      categories: [],
    };
  },

  methods: {
    updateXAxisCategories(data) {
      this.chartOptions = {
        xaxis: {
          categories: data,
        },
      };
    },
  },

  watch: {
    series(newValue) {
      this.categories = [...newValue[0]?.xAxis];
      this.updateXAxisCategories(this.categories);
    },
  },
};
</script>
