import { APIConfig } from "../../config/apiConfig";
import { roundToTwoDP } from "../../utils/numbers-formatter";

const state = () => ({
  overviewsListTeams: [],
  overviewsListPersonal: [],
  overviewsByFarmer: [],
  overviewsByFarmerSummary: [],
  overviewsByFarmerGraph: [],
  overviewsByFarmerGraphIndividual: [],
  sms: {},
  overviewsByFarmerRange: [],
  overviewsByFarmerSearch: [],
  commID: "",
  addCommitmentModal: false,
  updateCommitmentInfo: {},
  isDateFilter: false,
  isDateFilterRange: false
});

const getters = {
  getOverviewsByFarmer: (state) => state.overviewsByFarmer,
  getOverviewsByFarmerSummary: (state) => state.overviewsByFarmerSummary,
  getOverviewsByFarmerGraph: (state) => state.overviewsByFarmerGraph,
  getOverviewsByFarmerGraphIndividual: (state) => state.overviewsByFarmerGraphIndividual,
  getOverviewsByFarmerRange: (state) => state.overviewsByFarmerRange,
  getOverviewsByFarmerSearch: (state) => state.overviewsByFarmerSearch,
  getCommID: (state) => state.commID,
  getAddCommitmentModal: (state) => state.addCommitmentModal,
  getUpdateCommitmentInfo: (state) => state.updateCommitmentInfo,
  getIsDateFilter: (state) => state.isDateFilter,
  getIsDateFilterRange: (state) => state.isDateFilterRange,
  getOverviewsListTeams: (state) => {
    return state.overviewsListTeams;
  },
  getOverviewsListPersonal: (state) => {
    return state.overviewsListPersonal;
  },
};

const mutations = {
  saveOverviewsByFarmer(state, payload) {
    state.overviewsByFarmer = payload;
  },
  saveOverviewsByFarmerSummary(state, payload) {
    state.overviewsByFarmerSummary = payload;
  },
  saveOverviewsByFarmerRange(state, payload) {
    state.overviewsByFarmerRange = payload;
  },
  saveOverviewsByFarmerSearch(state, payload) {
    state.overviewsByFarmerSearch = payload
  },
  saveOverviewsByFarmerGraph(state, payload) {
    state.overviewsByFarmerGraph = payload;
  },
  saveOverviewsByFarmerGraphIndividual(state, payload) {
    state.overviewsByFarmerGraphIndividual = payload
  },
  saveSMS(state, payload) {
    state.sms = payload
  },
  saveCommID(state, payload) {
    state.commID = payload
  },
  saveAddCommitmentModal(state, payload) {
    state.addCommitmentModal = payload
  },
  saveUpdateCommitmentInfo(state, payload) {
    state.updateCommitmentInfo = payload
  },
  setOverviewsListTeams(state, payload) {
    state.overviewsListTeams = payload;
  },

  setOverviewsListPersonal(state, payload) {
    state.overviewsListPersonal = payload;
  },
  setIsDateFilter(state, payload) {
    state.isDateFilter = payload;
  },
  setIsDateFilterRange(state, payload) {
    state.isDateFilterRange = payload
  }
};

const actions = {
  async getOverViewsListTeams({ commit }, query) {
    try {
      const response = await APIConfig.get(`/overviews?timePeriod=${query}`);
      commit("setOverviewsListTeams", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async getOverviewP1Data({ commit }, query) {
    try {
      const response = await APIConfig.get(`/overviews/p1?timePeriod=${query}`);
      commit("setOverviewsListTeams", response.data.data);

      const transformedData = [
        {
          name: "Discoveries",
          data: response.data.discovered.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.discovered.map((item) => item.timestamps),
        },
        {
          name: "Dispatches",
          data: response.data.dispatched.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.dispatched.map((item) => item.timestamps),
        },
        {
          name: "Purchases",
          data: response.data.purchased.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.purchased.map((item) => item.timestamps),
        },
        {
          name: "Verified Dispatches",
          data: response.data.verified.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.verified.map((item) => item.timestamps),
        },
      ];
      return transformedData;
    } catch (error) {
      return error.response.data;
    }
  },

  async getOverviewDateRange({ commit }, query) {
    try {
      const response = await APIConfig.get(`/overviews?${query}`);
      commit("setOverviewsListTeams", response.data.data);
      return response.data;
    } catch (error) {
      error.response.data;
    }
  },

  async getOverviewPurchaseDateRange(_, query) {
    try {
      const response = await APIConfig.get(
        `/overviews/purchases-summary?${query}`
      );

      response.data.p1qty = roundToTwoDP(
        response.data.totalAmountSpent / response.data.avgPricePerTonne
      );
      return response.data;
    } catch (error) {
      console.log(error.response.data);
    }
  },

  async getOverviewPurchData({ commit }, query) {
    try {
      const response = await APIConfig.get(
        `/overviews/purchases?timePeriod=${query}`
      );
      commit("setOverviewsListTeams", response.data.data);
      const transformedData = [
        {
          name: "Quantity purchased",
          data: response.data.map((item) => roundToTwoDP(item.quantity)),
          xAxis: response.data.map((item) => item.timestamps),
        },
      ];
      return transformedData;
    } catch (error) {
      console.log(error);
    }
  },

  async getAgentPurchasedTonnes(_, args) {
    try {
      const response = args.dateQuery.includes("start")
        ? await APIConfig.get(
            `/overviews/agent-purchases/${args.agentId}?${args.dateQuery}&isAgent=Yes`
          )
        : await APIConfig.get(
            `/overviews/agent-purchases/${args.agentId}?timePeriod=${args.dateQuery}&isAgent=Yes`
          );

      const transformedData = [
        {
          name: "Quantity purchased",
          data: response.data.map((item) => roundToTwoDP(item.quantity)),
          xAxis: response.data.map((item) => item.timestamps),
        },
      ];
      return transformedData;
    } catch (error) {
      console.log(error.response.data);
    }
  },

  async getOverviewPurchDateRange({ commit }, query) {
    try {
      const response = await APIConfig.get(`/overviews/purchases?${query}`);
      commit("setOverviewsListTeams", response.data.data);
      const transformedData = [
        {
          name: "Quantity purchased",
          data: response.data.map((item) => roundToTwoDP(item.quantity)),
          xAxis: response.data.map((item) => item.timestamps),
        },
      ];
      return transformedData;
    } catch (error) {
      error.response.data;
    }
  },

  async getPurchaseData(_, query) {
    try {
      const response = await APIConfig.get(
        `/overviews/purchases-summary?timePeriod=${query}`
      );
      response.data.p1qty = roundToTwoDP(
        response.data.totalAmountSpent / response.data.avgPricePerTonne
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  async getInventorySummary(_, query) {
    try {
      const response = query.includes("start")
        ? await APIConfig.get(`/overviews/inventories-summary?${query}`)
        : await APIConfig.get(
            `/overviews/inventories-summary?timePeriod=${query}`
          );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async getOverviewInventoryDateRange({ commit }, query) {
    try {
      const response = await APIConfig.get(
        `/overviews/inventories-summary?${query}`
      );
      commit("setOverviewsListTeams", response.data.data);
      response.data.p1qty =
        response.data.totalAmountSpent / response.data.avgPricePerTonne;
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async getOverviewP1TrailingData(_, query) {
    try {
      const response = await APIConfig.get(
        `/overviews/trailing-inventory?timePeriod=${query}`
      );

      const tonnageDispatchedAndVerified = [
        {
          name: "Total Tonnes",
          data: response.data.totalInventoryTonnes.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.totalInventoryTonnes.map(
            (item) => item.timestamps
          ),
        },
      ];

      return tonnageDispatchedAndVerified;
    } catch (error) {
      return error.response.data;
    }
  },

  async getInventoryAtFactoryChart(_, query) {
    try {
      const response = await APIConfig.get(
        `/overviews/trailing-inventory?timePeriod=${query}`
      );

      const tonnageDispatchedAndVerified = [
        {
          name: "Tonnage Dispatched",
          data: response.data.dispatchedTonnes.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.dispatchedTonnes.map((item) => item.timestamps),
        },
        {
          name: "Verified Tonnage",
          data: response.data.verifiedDispatchedTonnes.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.verifiedDispatchedTonnes.map(
            (item) => item.timestamps
          ),
        },
      ];

      return tonnageDispatchedAndVerified;
    } catch (error) {
      return error.response.data;
    }
  },

  async getInventoryAtFactoryChartDateRange({ commit }, query) {
    try {
      const response = await APIConfig.get(
        `/overviews/moved-tonnes?timePeriod=${query}`
      );
      commit("setOverviewsListTeams", response.data.data);
      const transformedData = [
        {
          name: "P1 Inventory at Factory",
          data: response.data.map((item) => roundToTwoDP(item.quantity)),
          xAxis: response.data.map((item) => item.timestamps),
        },
      ];
      return transformedData;
    } catch (error) {
      return error.response.data;
    }
  },

  async getInventoryP1trailingDateRange({ commit }, query) {
    try {
      const response = await APIConfig.get(
        `/overviews/trailing-inventory?${query}`
      );
      commit("setOverviewsListTeams", response.data.data);
      const series = Object.entries(response.data).map(([name, data]) => {
        const attributeKeys = Object.keys(data[0]);
        const attribute = attributeKeys.find(
          (key) => key !== "frequency" && key !== "quantity"
        );

        let categories;
        let title;
        if (attribute) {
          categories = data.map((item) => item[attribute]);
          title = attribute;
        } else {
          const remainingAttributes = attributeKeys.filter(
            (key) => key !== "frequency" && key !== "quantity"
          );
          categories = remainingAttributes.flatMap((attr) =>
            data.map((item) => item[attr])
          );
          title = remainingAttributes.join(", ");
        }

        return {
          name,
          frequencyData: data.map((item) => item.frequency),
          data: data.map((item) => roundToTwoDP(item.quantity)),
          categories,
          title,
        };
      });
      return series;
    } catch (error) {
      return error.response.data;
    }
  },

  async getunmovedInventory({ commit }, query) {
    try {
      const response = await APIConfig.get(
        `overviews/unmoved-tonnes?timePeriod=${query}`
      );
      commit("setOverviewsListTeams", response.data.data);
      const transformedData = [
        {
          name: "P1 Inventory at Farmgate",
          data: response.data.map((item) => roundToTwoDP(item.quantity)),
          xAxis: response.data.map((item) => item.timestamps),
        },
      ];

      return transformedData;
    } catch (error) {
      return error.response.data;
    }
  },

  async getunmovedInventoryDateRange({ commit }, query) {
    try {
      const response = await APIConfig.get(`overviews/unmoved-tonnes?${query}`);
      commit("setOverviewsListTeams", response.data.data);
      const transformedData = [
        {
          name: "P1 Inventory at Farmgate",
          data: response.data.map((item) => roundToTwoDP(item.quantity)),
          xAxis: response.data.map((item) => item.timestamps),
        },
      ];

      return transformedData;
    } catch (error) {
      return error.response.data;
    }
  },

  async getOverheadCost(_, query) {
    try {
      const response = query.includes("start")
        ? await APIConfig.get(`/overviews/overhead-costs-summary?${query}`)
        : await APIConfig.get(
            `/overviews/overhead-costs-summary?timePeriod=${query}`
          );

      return {
        logisticsOverheadCostPerTon: roundToTwoDP(
          response.data.logisticsOverheadCostPerTon / 1000
        ),
        purchaseOverheadCostPerTon: +roundToTwoDP(
          response.data.purchaseOverheadCostPerTon / 1000
        ),
        percentageThreshold: roundToTwoDP(response.data.percentageThreshold),
        totalThreshold: roundToTwoDP(response.data.totalThreshold / 1000),
        totalCost: roundToTwoDP(response.data.totalCost / 1000),
        period: response.data.period,
      };
    } catch (error) {
      return error.response.data;
    }
  },

  async getCostsOverview(_, query) {
    try {
      const response = query.includes("start")
        ? await APIConfig.get(`/overviews/costs?${query}`)
        : await APIConfig.get(`/overviews/costs?timePeriod=${query}`);
      const transformedData = [
        {
          name: "RM Prices",
          data: response.data.RMPrices.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.landedCost.map((item) => item.timestamps),
        },
        {
          name: "Purchase Overhead",
          data: response.data.purchaseOverheadCost.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.purchaseOverheadCost.map(
            (item) => item.timestamps
          ),
        },
        {
          name: "Logistics Overhead",
          data: response.data.logisticsOverheadCost.map((item) =>
            roundToTwoDP(item.quantity)
          ),
          xAxis: response.data.landedCost.map((item) => item.timestamps),
        },
      ];

      return {
        transformedData,
        purchaseOverheadCostPerTon: response.data.purchaseOverheadCostPerTon,
        logisticsOverheadCostPerTon: response.data.logisticsOverheadCostPerTon,
        totalLandedCostPerTon: response.data.totalLandedCostPerTon,
        pricePerTon: response.data.pricePerTon,
        period: response.data.period,
      };
    } catch (error) {
      return error.response.data;
    }
  },

  async getOverheadCostChartData(__, query) {
    try {
      const response = query.includes("start")
        ? await APIConfig.get(`/overviews/overhead-costs?${query}`)
        : await APIConfig.get(`/overviews/overhead-costs?timePeriod=${query}`);

      return {
        sourcingAndFinance: response.data.sourcingAndFinance,
        purchaseAndLogistics: response.data.purchaseAndLogistics,
        purchaseOverheadCost: response.data.purchasesOverheadCost,
        logisticsOverheadCost: response.data.logisticsOverheadCost,
        period: response.data.period,
      };
    } catch (error) {
      return error.response.data;
    }
  },

  async getOverViewsListPersonal({ commit }) {
    try {
      const response = await APIConfig.get(`/overviews/agent`);
      commit("setOverviewsListPersonal", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async getKaaSSummary({ commit }, query) {
    try {
      const { data } = await APIConfig.get(
        `/overviews/kaas?timePeriod=${query}`
      );

      commit("setOverviewsListPersonal", data);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },

  async fetchPalmNutVolumeDataPoints({ commit }, query) {
    try {
      const { data } = await APIConfig.get(
        `/overviews/kaas/palm-nut-vol?timePeriod=${query}`
      );

      commit("setOverviewsListPersonal", data);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },

  async fetchEquivalentPalmKernelVolumeDataPoints({ commit }, query) {
    try {
      const { data } = await APIConfig.get(
        `/overviews/kaas/equivalent-palm-kernel-vol?timePeriod=${query}`
      );

      commit("setOverviewsListPersonal", data);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },

  async fetchAvgPalmKernelPriceDataPoints({ commit }, query) {
    try {
      const { data } = await APIConfig.get(
        `/overviews/kaas/avg-palm-kernel?timePeriod=${query}`
      );

      commit("setOverviewsListPersonal", data);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },

  async fetchFarmerOverviewsByDate({ commit }, params) {
    const { page, filterQuery } = params;

    try {
      const { data } = await APIConfig.get(
        `overviews/purchase-grouped-by-farmers?page=${page}&${filterQuery}`
      );
      commit("setIsDateFilter", true);
      commit("saveOverviewsByFarmer", data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  async FetchOverviewFarmersGraph({ commit }, query) {
    try {
      const { data } = await APIConfig.get(`overviews/supply-graph?${query}`);
      const transformedData = [
        {
          name: "Total Tonnage",
          data: data.map((item) => roundToTwoDP(item.totalTonnage)),
          xAxis: data.map((item) => item._id),
        },
      ];
      commit("saveOverviewsByFarmerGraph", transformedData);
      return transformedData;
    } catch (error) {
      console.log(error)
    }
  },
  async FetchOverviewFarmersGraphIndividual({commit}, query) {
    try {
      const  {data}  = await APIConfig.get(
        `overviews/supply-graph?farmerId=${query.globalFarmerid}&${query.query}`
      );
      const transformedData = [
        {
          name: "Total Tonnage",
          data: data.map((item) => roundToTwoDP(item.totalTonnage)),
          xAxis: data.map((item) => item._id),
        },
      ];

      commit("saveOverviewsByFarmerGraphIndividual", transformedData);
      return transformedData;
    } catch (error) {
      console.log(error)
    }
  },
  async fetchOverviewsByFarmerRangeDash(_, query) {
    try {
      const data = await APIConfig.get(
        `overviews/purchase-grouped-by-farmers?timePeriod=${query}`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  async fetchOverviewsByFarmerRange({ commit }, params) {
    const { page, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `overviews/purchase-grouped-by-farmers?page=${page}&timePeriod=${filterQuery}`
      );
      commit("setIsDateFilterRange", true);
      commit("saveOverviewsByFarmerRange", data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  async fetchOverviewsByFarmerSearch({commit}, query) {
    try {
      const  {data}  = await APIConfig.get(
        `overviews/purchase-grouped-by-farmers?searchKeyword=${query.search}&${query.query}`
      );
      commit("saveOverviewsByFarmerSearch", data);
      return data;
    } catch (error) {
      console.log(error)
    }
  },
  async sendSMSAction({commit}, sms) {
    try {
      const  {data}  = await APIConfig.post(
        "/sms/send", sms
      );

      commit("saveSMS", data);
      return data;
    } catch (error) {
      console.log(error)
    }
  },

  commID({commit}, commID) {
    commit("saveCommID", commID);
    return commID;
  },
  showAddCommitmentModal({commit}, bool) {
    commit("saveAddCommitmentModal", bool);
    return bool;
  },
  updateCommitmentInfoAction({commit}, info) {
    commit("saveUpdateCommitmentInfo", info);
    return info;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
