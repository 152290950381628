<template>
  <div class="modal-parent">
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Edit Farmer</h5>

            <p>Step 1 of 4</p>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <TextInput
          placeholder="First Name"
          @onInputChange="handleInputChange"
          fieldName="first_name"
          :inputValue="farmerForm?.first_name"
        />

        <TextInput
          placeholder="Last Name"
          @onInputChange="handleInputChange"
          fieldName="last_name"
          :inputValue="farmerForm?.last_name"
        />

        <TextInput
          placeholder="Other Name"
          @onInputChange="handleInputChange"
          fieldName="other_name"
          :inputValue="farmerForm?.other_name"
        />

        <TextInput
          placeholder="Phone Number"
          @onInputChange="handleInputChange"
          fieldName="phone"
          :inputValue="farmerForm?.phone"
        />

        <SelectDropdown
          :options="['Mr', 'Mrs', 'Miss']"
          class="select"
          dropdownTitle="Title"
          fieldName="title"
          @onSelect="handleSelect"
          :inputValue="farmerForm?.title"
        />

        <TextInput
          placeholder="Email Address (Optional)"
          @onInputChange="handleInputChange"
          fieldName="email_address"
          :inputValue="farmerForm?.email_address"
        />
      </form>
    </section>

    <div class="text-indicator">
      <p class=""><span>Step 1</span> of 4</p>
    </div>

    <!-- Progress Bar -->
    <div class="progress-bar-container">
      <div class="indicator"></div>
    </div>

    <div class="button-container">
      <GreenButton
        label="Save and Continue"
        @click="routeToNextPage"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";

export default {
  name: "EditFarmerModal1",

  components: { TextInput, GreenButton, SelectDropdown },

  data() {
    return {
      addFarmerPageNum: 1,
      farmerForm: this.$store.getters["farmers/getFarmerToBeUpdated"] || {
        first_name: "",
        last_name: "",
        other_name: "",
        title: "",
        phone: "",
        email_address: "",
      },
      globalInputtedFarmer: {},
    };
  },

  computed: {
    isRequiredFieldsEmpty() {
      const isEmpty =
        !this.farmerForm["first_name"]?.length ||
        !this.farmerForm["last_name"]?.length;

      // console.log(isEmpty);

      return isEmpty;
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.farmerForm = {
        ...this.farmerForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.farmerForm = {
        ...this.farmerForm,
        ...obj,
      };
    },

    routeToNextPage() {
      this.$emit("onSetAddModalPage", 2);

      this.globalInputtedFarmer =
        this.$store.getters["farmers/getFarmerToBeUpdated"];
      this.globalInputtedFarmer = {
        ...this.globalInputtedFarmer,
        ...this.farmerForm,
      };

      this.$store.dispatch(
        "farmers/setValuesForFarmerUpdate",
        this.globalInputtedFarmer
      );

    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: auto;
}

.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 25%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 7% auto 2% auto;
}
</style>
