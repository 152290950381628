import { APIConfig } from "../../config/apiConfig";
import moment from "moment";

const formatLoadersSalary = (data) => {
  return data.map((cost) => ({
    _id: cost._id,
    paymentType: cost.paymentType,
    noOfLoaders: cost.noOfLoaders,
    totalAmount: cost.totalAmount,
    description: cost.description,
    vehicle: cost.vehicle?.name || "Nil",
    vehicleId: cost.vehicle?._id,
    vehicleName: cost.vehicle?.name || "Nil",
    date: moment(cost.date).format("MM/DD/yyyy"),
    dateAdded: moment(cost.createdAt).format("MM/DD/yyyy"),
    createdBy: cost.agentDetails.first_name + " " + cost.agentDetails.last_name,
  }));
};

const state = () => ({
  loadersSalaryCosts: [],
  costsToBeUpdated: {},
  error: null,
});

const getters = {
  getCostsToBeUpdated: (state) => state.costsToBeUpdated,
};

const mutations = {
  populateCosts(state, loadersSalaryCosts) {
    state.loadersSalaryCosts = loadersSalaryCosts;
  },

  setCostsToBeUpdated(state, costs) {
    state.costsToBeUpdated = costs;
  },
};

const actions = {
  findCostToBeUpdated({ commit, state }, costId) {
    let costs = state.loadersSalaryCosts.find((cost) => cost._id === costId);

    costs = {
      ...costs,
      vehicle: costs.vehicleId,
    };

    commit("setCostsToBeUpdated", costs);
  },

  async fetchLoadersSalaryCosts({ commit }, params) {
    const { page, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/loader-salary/?page=${page}&limit=${limit}`
      );

      const loadersSalaryCosts = data?.data
        ? formatLoadersSalary(data?.data)
        : [];

      commit("populateCosts", loadersSalaryCosts);

      return {
        count: data?.count,
        cost: loadersSalaryCosts,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async addNewCosts(_, payload) {
    try {
      const { data } = await APIConfig.post(`/loader-salary`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async filterByDatePeriod({ commit }, params) {
    const { page, limit, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `/loader-salary/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      if (Object.keys(data).length > 0) {
        const loadersSalaryCosts = formatLoadersSalary(data?.data);

        commit("populateCosts", loadersSalaryCosts);

        return {
          count: data?.count,
          cost: loadersSalaryCosts,
        };
      } else {
        return {
          count: 0,
          cost: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async createTarget(_, payload) {
    try {
      const { data } = await APIConfig.post(`/loader-salary/target`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateCosts(_, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/loader-salary/${payload.id}`,
        payload
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteCosts(_, id) {
    try {
      const { data } = await APIConfig.delete(`/loader-salary/${id}`);

      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
