import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  factories: [],
  error: null,
});

const getters = {};
const mutations = {
  saveFactories(state, factories) {
    state.factories = factories;
  },
};
const actions = {
  async fetchAllFactories({ commit }, params) {
    const { page } = params;
    try {
      const { data } = await APIConfig.get(`/factories/?page=${page}`);

      const factories = data.map((el) => {
        return {
          id: el._id,
          name: el.name,
        };
      });

      commit("saveFactories", factories);

      return factories;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
