import { APIConfig } from "../../config/apiConfig";
import moment from "moment";

const formatData = (data) => {
  return data.map((vehicle) => ({
    _id: vehicle._id,
    name: vehicle.name,
    vehicleNumber: vehicle.vehicleNumber || "Nil",
    vehicleModel: vehicle.vehicleModel || "Nil",
    driver: vehicle.driverId.name || "Nil",
    driverName: vehicle.driverId.name || "Nil",
    driverId: vehicle.driverId._id || "Nil",
    loadingTime: vehicle.loadingTime || "Nil",
    offloadingTime: vehicle.offloadingTime || "Nil",
    loadingCostPerTon: vehicle.loadingCostPerTon
      ? `${vehicle.loadingCostPerTon.toString()}`
      : 0,
    vehicleCost: vehicle.vehicleCost ? `${vehicle.vehicleCost.toString()}` : 0,
    noOfLoaders: vehicle.noOfLoaders ? `${vehicle.noOfLoaders.toString()}` : 0,
    minCapacity: vehicle.minCapacity ? `${vehicle.minCapacity.toString()}` : 0,
    maxCapacity: vehicle.maxCapacity ? `${vehicle.maxCapacity.toString()}` : 0,
    fuelEconomy: vehicle.fuelEconomy ? `${vehicle.fuelEconomy.toString()}` : 0,
    fuelCost: vehicle.fuelCost?.toString() || 0,
    timeOfRepair: vehicle.timeOfRepair || "--",
    equipment: vehicle.equipment || "--",
    datePurchased: vehicle.datePurchased
      ? moment(vehicle.datePurchased).format("MM/DD/yyyy")
      : "",
    lastOverhaulDate: vehicle.lastOverhaulDate
      ? moment(vehicle.lastOverhaulDate).format("MM/DD/yyyy")
      : "",
    lastRepairDate: vehicle.lastRepairDate
      ? moment(vehicle.lastRepairDate).format("MM/DD/yyyy")
      : "",
  }));
};

const state = () => ({
  vehicles: [],
  vehicleToBeUpdated: {},
  id: "",
  inputtedData: {
    name: "",
    vehicleNumber: "",
    minCapacity: 0,
    maxCapacity: 0,
    vehicleModel: "",
    fuelEconomy: 0,
    fuelCost: 0,
    loadingCostPerTon: 0,
    loadingTime: "",
    offloadingTime: "",
    vehicleCost: 0,
    datePurchased: "",
    lastOverhaulDate: "",
    lastRepairDate: "",
    driverId: "",
    driverName: "",
    equipment: "",
  },
  error: null,
});

const getters = {
  getInputtedData: (state) => {
    return state.inputtedData;
  },

  getAssetToBeUpdated: (state) => state.vehicleToBeUpdated,
};

const mutations = {
  saveVehicles(state, vehicles) {
    state.vehicles = vehicles;
  },

  setInputtedData(state, data) {
    state.inputtedData = data;
  },

  setValuesForVehicleUpdate(state, vehicle) {
    state.vehicleToBeUpdated = {
      ...state.vehicleToBeUpdated,
      ...vehicle,
    };
  },

  setVehicleToBeUpdated(state, vehicle) {
    state.vehicleToBeUpdated = vehicle;
  },

  setVehicleId(state, id) {
    state.id = id;
  },
};

const actions = {
  setInputtedData({ commit }, vehicleData) {
    commit("setInputtedData", vehicleData);
  },

  setVehicleId({ commit }, vehicleId) {
    commit("setVehicleId", vehicleId);
  },

  setValuesForVehicleUpdate({ commit }, vehicle) {
    commit("setValuesForVehicleUpdate", vehicle);
  },

  findAssetToViewDetails({ commit, state }, vehicleId) {
    let vehicle = state.vehicles.find((vehicle) => vehicle._id === vehicleId);
    commit("setVehicleToBeUpdated", vehicle);
  },

  findAssetToBeUpdated({ commit, state }, vehicleId) {
    let vehicleToBeUpdated = state.vehicles.find(
      (vehicle) => vehicle._id === vehicleId
    );

    vehicleToBeUpdated = {
      ...vehicleToBeUpdated,
      vehicleCost: vehicleToBeUpdated.vehicleCost
        ? Number(vehicleToBeUpdated.vehicleCost)
        : 0,
      fuelCost: vehicleToBeUpdated.fuelCost
        ? Number(vehicleToBeUpdated.fuelCost)
        : 0,
      minCapacity: vehicleToBeUpdated.minCapacity
        ? Number(vehicleToBeUpdated.minCapacity)
        : 0,
      maxCapacity: vehicleToBeUpdated.maxCapacity
        ? Number(vehicleToBeUpdated.maxCapacity)
        : 0,
      fuelEconomy: vehicleToBeUpdated.fuelEconomy
        ? Number(vehicleToBeUpdated.fuelEconomy)
        : 0,
      loadingCostPerTon: vehicleToBeUpdated.loadingCostPerTon
        ? Number(vehicleToBeUpdated.loadingCostPerTon)
        : 0,
    };

    commit("setVehicleToBeUpdated", vehicleToBeUpdated);
  },

  async fetchVehicles({ commit }, params) {
    const { page } = params;
    try {
      const { data } = await APIConfig.get(`/vehicles/?page=${page}`);

      const vehicles = data?.data ? formatData(data?.data) : [];
      commit("saveVehicles", vehicles);

      return {
        count: data?.count,
        assets: vehicles,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async addNewVehicle(_, payload) {
    try {
      const { data } = await APIConfig.post(`/vehicles`, payload);
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateVehicle(_, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/vehicles/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteVehicle({ commit }, id) {
    try {
      const { data } = await APIConfig.delete(`vehicles/${id}`);

      return data;
    } catch (error) {
      console.log(error.message);
      commit("setErrors", error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
