import { APIConfig } from '../../config/apiConfig';

const state = () => ({
  checkInSessions: [],
  checkInSession: null,
});

const getters = {
  getCheckInSessions: (state) => {
    return state.checkInSessions;
  },

  getCheckInSession: (state) => {
    return state.checkInSession;
  },
};

const mutations = {
  saveCheckInSessions(state, checkInSessions) {
    state.checkInSessions = checkInSessions;
  },

  saveOneCheckInSession(state, checkInSession) {
    state.checkInSession = checkInSession;
  },
};

const actions = {
  async checkIn({ commit }, payload) {
    try {
      const { data } = await APIConfig.post('/check-in-session', payload);
      commit('saveOneCheckInSession', data);
      
      return data;
    } catch (error) {
      return error.response.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
