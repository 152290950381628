<template>
  <div class="parent-modal">
    <Loading
        v-model:active="loading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="#669933"
        loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Update Revenue</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo"/>
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
                class="x-container"
                style="cursor: pointer"
                @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-container">
      <form class="form-container-div">

        <TextInput
            placeholder="MTD Revenue"
            :inputValue="revenueStat.mtdRevenue"
            v-model="revenueStat.mtdRevenue"
            @onInputChange="handleMTDInputChange"
            fieldName="mtdRevenue"
        />

        <TextInput
            placeholder="Revenue Target"
            fieldName="revenueTarget"
            :inputValue="revenueStat.revenueTarget"
            v-model="revenueStat.revenueTarget"
            @onInputChange="handleTargetInputChange"
        />

        <TextInput
            placeholder="VOGP"
            :inputValue="revenueStat.VOGP"
            v-model="revenueStat.VOGP"
            @onInputChange="handleVOGPInputChange"
            fieldName="VOGP"
        />

        <TextInput
            placeholder="VOGP Percentage"
            fieldName="VOGP Percentage"
            :inputValue="revenueStat.VOGPPercentage"
            v-model="revenueStat.VOGPPercentage"
            @onInputChange="handleVOGPPercentageInputChange"
        />
        <TextInput
            placeholder="Gross Profit"
            :inputValue="revenueStat.grossProfit"
            v-model="revenueStat.grossProfit"
            @onInputChange="handlegrossProfitInputChange"
            fieldName="grossProfit"
        />

        <TextInput
            placeholder="MTD Revenue Percentage Margin"
            fieldName="mtdRevenuePercentageMargin"
            :inputValue="revenueStat.mtdRevenuePercentageMargin"
            v-model="revenueStat.mtdRevenuePercentageMargin"
            @onInputChange="handleMtdRevenuePercentageMarginInputChange"
        />
        <TextInput
            placeholder="revenue Target PercentageMargin"
            :inputValue="revenueStat.revenueTargetPercentageMargin"
            v-model="revenueStat.revenueTargetPercentageMargin"
            @onInputChange="handleRevenueTargetPercentageMarginInputChange"
            fieldName="revenueTargetPercentageMargin"
        />

        <TextInput
            placeholder="C1 Price"
            :inputValue="revenueStat.c1Price"
            v-model="revenueStat.c1Price"
            @onInputChange="handleC1PriceInputChange"
            fieldName="c1Price"
        />
        <TextInput
            placeholder="C2 Price"
            :inputValue="revenueStat.c2Price"
            v-model="revenueStat.c2Price"
            @onInputChange="handleC2PriceInputChange"
            fieldName="c2Price"
        />
        <TextInput
            placeholder="C1 Extraction"
            :inputValue="revenueStat.c1Extraction"
            v-model="revenueStat.c1Extraction"
            @onInputChange="handleC1ExtractionInputChange"
            fieldName="c1Extraction"
        />
        <TextInput
            placeholder="C2 Extraction"
            :inputValue="revenueStat.c2Extraction"
            v-model="revenueStat.c2Extraction"
            @onInputChange="handleC2ExtractionInputChange"
            fieldName="c2Extraction"
        />

        <TextInput
            placeholder="C1 Margin"
            :inputValue="revenueStat.c1GrossMargin"
            v-model="revenueStat.c1GrossMargin"
            @onInputChange="handleC1MarginInputChange"
            fieldName="c1GrossMargin"
        />
        <TextInput
            placeholder="C2 Margin"
            :inputValue="revenueStat.c2GrossMargin"
            v-model="revenueStat.c2GrossMargin"
            @onInputChange="handleC2MarginInputChange"
            fieldName="c2GrossMargin"
        />
        <TextInput
            placeholder="C1 Iron Clad"
            :inputValue="revenueStat.c1IronClad"
            v-model="revenueStat.c1IronClad"
            @onInputChange="handleC1IronCladInputChange"
            fieldName="c1IronClad"
        />
        <TextInput
            placeholder="C2 Iron Clad"
            :inputValue="revenueStat.c2IronClad"
            v-model="revenueStat.c2IronClad"
            @onInputChange="handleC2IronCladInputChange"
            fieldName="c2IronClad"
        />
      </form>
    </section>

    <div class="border"></div>

    <div class="button-container">
      <GreenButton
          label="Update"
          width="10.5rem"
          height="2.9rem"
          @click="updateRevenue"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import Loading from "vue-loading-overlay";
import {createToast} from "mosha-vue-toastify";

export default {
  name: "EditRevenueModal",
  components: {
    TextInput,
    GreenButton,
    Loading,
  },

  data() {

    return {
      loading: false,
      revenueStat: {
        mtdRevenue: this.mtdRevenue || "",
        revenueTarget: this.revenueTarget || "",
        VOGP: this.VOGP || "",
        VOGPPercentage: this.VOGPPercentage || "",
        grossProfit: this.grossProfit || "",
        mtdRevenuePercentageMargin: this.mtdRevenuePercentageMargin || "",
        revenueTargetPercentageMargin: this.revenueTargetPercentageMargin || "",
        c1Price: this.c1Price || "",
        c2Price: this.c2Price || "",
        c1Extraction: this.c1Extraction || "",
        c2Extraction: this.c2Extraction || "",
        c1GrossMargin: this.c1GrossMargin || "",
        c2GrossMargin: this.c2GrossMargin || "",
        c1IronClad: this.c1IronClad || "",
        c2IronClad: this.c2IronClad || "",

        id: this.revenueID
      },
    };
  },

  props: {

    mtdRevenue: {
      type: String,
      required: true,
    },
    revenueTarget: {
      type: String,
      required: true,
    },
    VOGP: {
      type: String,
      required: true,
    },
    VOGPPercentage: {
      type: String,
      required: true,
    },
    mtdRevenuePercentageMargin: {
      type: String,
      required: true,
    },
    revenueTargetPercentageMargin: {
      type: String,
      required: true,
    },
    grossProfit: {
      type: String,
      required: true,
    },
    c1Price: {
      type: String,
      required: true,
    },
    c2Price: {
      type: String,
      required: true,
    },
    c1Extraction: {
      type: String,
      required: true,
    },
    c2Extraction: {
      type: String,
      required: true,
    },

    c1GrossMargin: {
      type: String,
      required: true,
    },
    c2GrossMargin: {
      type: String,
      required: true,
    },
    c1IronClad: {
      type: String,
      required: true,
    },
    c2IronClad: {
      type: String,
      required: true,
    },
    revenueID: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },
    async updateRevenue() {
      try {
        this.loading = true;

        const {
          mtdRevenue,
          revenueTarget,
          VOGP,
          VOGPPercentage,
          grossProfit,
          mtdRevenuePercentageMargin,
          revenueTargetPercentageMargin,
          c1Price,
          c2Price,
          c1Extraction,
          c2Extraction,
          c1GrossMargin,
          c2GrossMargin,
          c1IronClad,
          c2IronClad,
        } = this.revenueStat;

        // console.log(this.revenueStat, "revenueStat");

        const payload = {
          mtdRevenue: Number(mtdRevenue),
          revenueTarget: Number(revenueTarget),
          VOGP: Number(VOGP),
          VOGPPercentage: Number(VOGPPercentage),
          grossProfit: Number(grossProfit),
          mtdRevenuePercentageMargin: Number(mtdRevenuePercentageMargin),
          revenueTargetPercentageMargin: Number(revenueTargetPercentageMargin),
          c1Price: Number(c1Price),
          c2Price: Number(c2Price),
          c1Extraction: Number(c1Extraction),
          c2Extraction: Number(c2Extraction),

          c1GrossMargin: Number(c1GrossMargin),
          c2GrossMargin: Number(c2GrossMargin),
          c1IronClad: Number(c1IronClad),
          c2IronClad: Number(c2IronClad),

          id: this.revenueStat.id
        };

        const response = await this.$store.dispatch(
            "revenue/updateRevenueStatistics",
            payload
        );

        console.log({response});

        if (response.status == 200) {
          createToast("Revenue Updated Successfully", {
            type: "success",
            position: "top-right",
            timeout: 3000,
          });

          this.loading = false;
          window.location.reload();
        }
        this.loading = false;
        window.location.reload();

      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    handleMTDInputChange(obj) {
      this.revenueStat.mtdRevenue = Number(obj.mtd_revenue);
    },

    handleTargetInputChange(obj) {
      this.revenueStat.revenueTarget = Number(obj.revenue_target);
    },

    handleVOGPInputChange(obj) {
      this.revenueStat.VOGP = Number(obj.v_o_g_p);
    },
    handleVOGPPercentageInputChange(obj) {
      this.revenueStat.VOGPPercentage = Number(obj.v_o_g_p_percentage);
    },
    handlegrossProfitInputChange(obj) {
      this.revenueStat.grossProfit = Number(obj.gross_profit);
    },
    handleMtdRevenuePercentageMarginInputChange(obj) {
      this.revenueStat.mtdRevenuePercentageMargin = Number(obj.mtd_revenue_percentage_margin);
    },
    handleRevenueTargetPercentageMarginInputChange(obj) {
      this.revenueStat.revenueTargetPercentageMargin = Number(obj.revenue_target_percentage_margin);
    },

    handleC1PriceInputChange(obj) {
      this.revenueStat.c1Price = Number(obj.c1_price);
    },
    handleC2PriceInputChange(obj) {
      this.revenueStat.c2Price = Number(obj.c2_price);
    },
    handleC1ExtractionInputChange(obj) {
      this.revenueStat.c1Extraction = Number(obj.c1_extraction);
    },
    handleC2ExtractionInputChange(obj) {
      this.revenueStat.c2Extraction = Number(obj.c2_extraction);
    },
    handleC1MarginInputChange(obj) {
      this.revenueStat.c1GrossMargin = Number(obj.c1_gross_margin);
    },
    handleC2MarginInputChange(obj) {
      this.revenueStat.c2GrossMargin = Number(obj.c2_gross_margin);
    },
    handleC1IronCladInputChange(obj) {
      this.revenueStat.c1IronClad = Number(obj.c1_iron_clad);
    },
    handleC2IronCladInputChange(obj) {
      this.revenueStat.c2IronClad = Number(obj.c2_iron_clad);
    }
  },

};
</script>

<style scoped>
.parent-modal {
  height: 100%;
  background: #fff;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.form-container {
  height: 230px;
  overflow-y: scroll;
}

.form-container-div {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
</style>
