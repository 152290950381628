import moment from "moment";
import { APIConfig } from "../../config/apiConfig";

const formatSingleAggregation = (aggregation) => {
  return {
    ...aggregation,
    AP: aggregation.destination_id ? aggregation.destination_id.name : "AP",
    createdBy: aggregation.createdBy ? aggregation.createdBy.first_name : "Nil",
    qtyMoved: aggregation.quantity_moved
      ? aggregation.quantity_moved.toString()
      : "Nil",
    verifiedTonnage: aggregation.verifiedTonnage
      ? aggregation.verifiedTonnage.toString()
      : "Nil",
    createdAt: moment(aggregation.createdAt).format("DD-MMMM-YYYY"),
    driver: aggregation.driverId ? aggregation.driverId.name : "Nil",
    status: aggregation.authorisationStatus,
    verificationStatus: aggregation.verification_status,
    vehicle: aggregation.vehicleId ? aggregation.vehicleId.name : "Nil",
  };
};

const formatAggregationData = (data) => {
  return data.map((el) => {
    return {
      ...el,
      AP: el.destination_id ? el.destination_id.name : "AP",
      createdBy: el.createdBy ? el.createdBy.first_name : "Nil",
      qtyMoved: el.quantity_moved ? el.quantity_moved : "Nil",
      createdAt: moment(el.createdAt).format("DD-MM-YYYY"),
      driver: el.driverId ? el.driverId.name : "Nil",
      status: el.authorisationStatus,
      verificationStatus: el.verification_status,
      vehicle: el.vehicleId ? el.vehicleId.name : "Nil",
      startTime: el.startTime || "Nil",
    };
  });
};

const state = () => ({
  aggregations: [],
  selectedAggregationIds: [],
  searchTerm: "",
  isFilter: false,
  isDateFilter: false,
  error: null,
  aggregationId: "",
  activeLogisticsTab: "",
  editModalStatus: false,
  filterModalStatus: false,
  verifyModalStatus: false,
  deleteModalStatus: false,
  isDetailsModalOpen: false,
  isApprovalModalStatus: false,
  aggregationToBeUpdated: {},
  inputtedAggregation: {
    apId: "",
    apName: "",
    id: "",
    purchase: "",
    purchasesToBeAggregated: [],
    qtyMoved: "",
    vehicle: "",
    vehicleName: "",
    driver: "",
    driverName: "",
    startDate: "",
    startTime: "",
    loaderCost: "",
    communityCost: "",
    fuelCost: "",
    tollCost: "",
    threePLCost: "",
  },
  filterMetaData: {
    filteredAggregations: [],
    count: 0,
    query: "",
  },
});

const getters = {
  getAggregations: (state) => {
    return state.aggregations;
  },

  getSelectedIds: (state) => {
    return state.selectedAggregationIds;
  },

  getSearchTerm: (state) => {
    return state.searchTerm;
  },

  getFilterStatus: (state) => {
    return state.isFilter;
  },

  getIsDateFilterStatus: (state) => {
    return state.isDateFilter;
  },

  getApprovalModalStatus: (state) => {
    return state.isApprovalModalStatus;
  },

  getError: (state) => {
    return state.error;
  },

  getActiveLogisticsTab: (state) => {
    return state.activeLogisticsTab;
  },

  getEditModalStatus: (state) => {
    return state.editModalStatus;
  },

  getVerifyModalStatus: (state) => {
    return state.verifyModalStatus;
  },

  getDeleteModalStatus: (state) => {
    return state.deleteModalStatus;
  },

  getFilterModalStatus: (state) => {
    return state.filterModalStatus;
  },

  getAggregationToBeUpdated: (state) => {
    return state.aggregationToBeUpdated;
  },

  getInputtedAggregation: (state) => {
    return state.inputtedAggregation;
  },

  getFilterMetaData: (state) => {
    return state.filterMetaData;
  },

  getDetailsModalStatus: (state) => {
    return state.isDetailsModalOpen;
  },

  getAggregationId: (state) => {
    return state.aggregationId;
  },
};

const mutations = {
  setSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm;
  },

  setAggregationIds(state, ids) {
    state.selectedAggregationIds = ids;
  },

  setIsFilter(state, status) {
    state.isFilter = status;
  },

  setDetailsModalStatus(state, status) {
    state.isDetailsModalOpen = status;
  },

  setApprovalModalStatus(state, status) {
    state.isApprovalModalStatus = status;
  },

  setActiveLogisticsTab(state, activeTab) {
    state.activeLogisticsTab = activeTab;
  },

  setEditModalStatus(state, status) {
    state.editModalStatus = status;
  },

  setVerifyModalStatus(state, status) {
    state.verifyModalStatus = status;
  },

  setFilterModalStatus: (state, status) => {
    state.filterModalStatus = status;
  },

  setDeleteModalStatus: (state, status) => {
    state.deleteModalStatus = status;
  },

  setAggregationId(state, id) {
    state.aggregationId = id;
  },

  saveAggregations(state, aggregations) {
    state.aggregations = aggregations;
  },

  setInputtedAggregation(state, aggregation) {
    state.inputtedAggregation = aggregation;
  },

  setIsDateFilter(state, status) {
    state.isDateFilter = status;
  },

  setValuesForAggregationUpdate(state, aggregation) {
    state.aggregationToBeUpdated = {
      ...state.aggregationToBeUpdated,
      ...aggregation,
    };
  },

  setAggregationToBeUpdated(state, aggregation) {
    state.aggregationToBeUpdated = aggregation;
  },

  setError(state, error) {
    state.error = error;
  },

  setFilterMetaData(state, metaData) {
    state.filterMetaData = {
      ...state.filterMetaData,
      ...metaData,
    };
  },

  resetForm(state, form) {
    state.inputtedAggregation = form;
  },
};

const actions = {
  setInputtedAggregation({ commit }, aggregation) {
    commit("setInputtedAggregation", aggregation);
  },

  updateMultipleIds({ commit }, ids) {
    commit("setAggregationIds", ids);
  },

  updateApprovalModalStatus({ commit }, status) {
    commit("setApprovalModalStatus", status);
  },

  setValuesForAggregationUpdate({ commit }, aggregation) {
    commit("setValuesForAggregationUpdate", aggregation);
  },

  setActiveLogisticsTab({ commit }, activeTab) {
    commit("setActiveLogisticsTab", activeTab);
  },

  setEditModalStatus({ commit }, status) {
    commit("setEditModalStatus", status);
  },

  setVerifyModalStatus({ commit }, status) {
    commit("setVerifyModalStatus", status);
  },

  updateFilterModalStatus: ({ commit }, status) => {
    commit("setFilterModalStatus", status);
  },

  updateDeleteModalStatus: ({ commit }, status) => {
    commit("setDeleteModalStatus", status);
  },

  setAggregationId({ commit }, id) {
    commit("setAggregationId", id);
  },

  setDetailsModalStatus({ commit }, status) {
    commit("setDetailsModalStatus", status);
  },

  resetAggregationForm({ commit }) {
    const aggregation = {
      apId: "",
      apName: "",
      id: "",
      purchase: "",
      purchasesToBeAggregated: [],
      qtyMoved: "",
      vehicle: "",
      vehicleName: "",
      driver: "",
      driverName: "",
      startDate: "",
      startTime: "",
      loaderCost: "",
      communityCost: "",
      fuelCost: "",
      tollCost: "",
      threePLCost: "",
    };
    commit("resetForm", aggregation);
  },

  findAggregationToBeUpdated({ commit, state }, aggregationId) {
    let aggregationToBeUpdated = state.aggregations.find(
      (aggregation) => aggregation._id === aggregationId
    );

    const purchasesAggregated = aggregationToBeUpdated.purchaseId.map((el) => {
      return {
        id: el._id,
        purchaseId: el.purchase_id,
        qtyMoved: el.qty_moved,
        amountLeft: el.availableTonnage,
      };
    });

    aggregationToBeUpdated = {
      ...aggregationToBeUpdated,
      apId: aggregationToBeUpdated.destination_id._id,
      driverId: aggregationToBeUpdated.driverId._id,
      vehicleId: aggregationToBeUpdated.vehicleId._id,
      purchasesAggregated: purchasesAggregated,
      startDate: moment(aggregationToBeUpdated["timeReceived"]).format(
        "YYYY-MM-DD"
      ),
    };

    commit("setAggregationToBeUpdated", aggregationToBeUpdated);
  },

  async fetchAllAggregations({ commit }, params) {
    const { page, searchTerm, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/aggregation/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
      );

      const aggregations = formatAggregationData(data?.data);

      commit("setSearchTerm", searchTerm);
      commit("saveAggregations", aggregations);

      return {
        count: data?.count,
        aggregations,
        searchText: searchTerm,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async startAggregation({ commit, dispatch }, payload) {
    try {
      const { data } = await APIConfig.post(`/aggregation`, payload);
      dispatch("resetAggregationForm");
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async updateAggregation({ commit }, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/aggregation/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async verifyAggregation({ commit }, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/aggregation/verify/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async filterAggregationsByDatePeriod({ commit }, params) {
    const { page, filterQuery, limit } = params;

    try {
      const { data } = await APIConfig.get(
        `/aggregation/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      commit("setIsDateFilter", true);


      if (Object.keys(data).length > 0) {
        const aggregations = formatAggregationData(data?.data);

        const response = {
          count: data?.count,
          aggregations,
          filterQuery,
        };

        commit("setFilterMetaData", response);

        return response;
      } else {
        return {
          count: 0,
          aggregations: [],
          filterQuery,
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async filterAggregations({ commit }, params) {
    const { page, filterQuery, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/aggregation/filter?page=${page}&limit=${limit}&${filterQuery}`
      );

      let response = {
        filteredAggregations: [],
        count: 0,
        query: filterQuery,
      };

      if (data?.count > 0) {
        response.filteredAggregations = formatAggregationData(data?.data);
        response.count = data?.count;
      }
      commit("setIsFilter", true);
      commit("setFilterMetaData", response);
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchOneAggregation({ commit }, aggregationId) {
    try {
      let { data } = await APIConfig.get(`/aggregation/${aggregationId}`);

      data = formatSingleAggregation(data);

      return data;
    } catch (error) {
      commit("setError", error.response.data);
      console.log(error.response.data);
    }
  },

  async approveManyAggregation({ commit }, aggregationsIds) {
    console.log(commit);
    try {
      const { data } = await APIConfig.patch(`/aggregation/approvals`, {
        aggregationsIds,
      });

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async deleteAggregation({ commit }, aggregationId) {
    try {
      const { data } = await APIConfig.delete(`/aggregation/${aggregationId}`);
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async deleteManyAggregation({ commit }, ids) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(
        `/aggregation?aggregationsIds=${ids}`
      );

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
