export const aveTonesTableHeaders = [
  { text: "Farmer's ID", value: "farmerRFID" },
  { text: "Farmer's Name", value: "name" },
  { text: "Phone Number", value: "phone" },
  { text: "Supply Volume", value: "totalTonnage" },
  { text: "Commitment", value: "commitment" },
  { text: "Number of Transactions", value: "noOfTransactions" },
  { text: "Avg Supply", value: "avgSupply" },
  { text: "Actions", value: "actions" }
];

  
