<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add Diesel Record</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <DateField
          placeholder="Date"
          @onInputChange="handleInputChange"
          fieldName="date"
          :inputValue="dieselForm?.date"
        />

        <Dropdown
          :options="vehicles"
          class="select"
          dropdownTitle="Select Vehicle"
          fieldName="vehicle"
          fieldValue="vehicleName"
          @onSelect="handleSelect"
          :inputValue="dieselForm?.vehicle"
        />

        <SelectDropdown
          :options="['PMS', 'AGO', 'GAS']"
          class="select"
          dropdownTitle="Fuel Type"
          fieldName="fuelType"
          @onSelect="handleSelect"
          :inputValue="dieselForm?.fuelType"
        />

        <NumberInput
          placeholder="Cost per liter"
          @onInputChange="handleInputChange"
          fieldName="cost"
          :inputValue="dieselForm?.cost"
        />

        <NumberInput
          placeholder="Number of liters"
          @onInputChange="handleInputChange"
          fieldName="numOfLiter"
          :inputValue="dieselForm?.numOfLiter"
        />

        <NumberInput
          placeholder="Amount paid"
          @onInputChange="handleInputChange"
          fieldName="amountPaid"
          :inputValue="dieselForm?.amountPaid"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton width="12rem" label="Submit" @click="addDieselRecord" />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import DateField from "../../../../components/form-inputs/date-input/DateField.vue";
import GreenButton from "../../../../components/buttons/green-button/GreenButton.vue";
import Dropdown from "../../../../components/form-inputs/select-dropdown/Dropdown.vue";
import SelectDropdown from "../../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import NumberInput from "../../../../components/form-inputs/number-input/NumberInput.vue";

export default {
  name: "AddDieselModal",

  components: {
    GreenButton,
    Dropdown,
    DateField,
    SelectDropdown,
    NumberInput,
    Loading,
  },

  data() {
    return {
      dieselForm: {
        date: "",
        vehicle: "",
        fuelType: "",
        cost: 0,
        numOfLiter: 0,
        amountPaid: 0,
      },
      loading: false,
      vehicles: [],
      isRefresh: false,
    };
  },

  mounted() {
    this.fetchVehicles();
  },

  methods: {
    async fetchVehicles(page = 1) {
      try {
        const { assets } = await this.$store.dispatch(
          "vehicleManagement/fetchVehicles",
          {
            page,
          }
        );

        this.vehicles = assets;
      } catch (error) {
        this.loading = false;
      }
    },

    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.dieselForm = {
        ...this.dieselForm,
        ...obj,
      };
    },
    handleInputChange(obj) {
      this.dieselForm = {
        ...this.dieselForm,
        ...obj,
      };
    },

    async addDieselRecord() {
      const { date, vehicle, fuelType, cost, numOfLiter, amountPaid } =
        this.dieselForm;
      if (
        !date ||
        !vehicle ||
        !fuelType ||
        !cost ||
        !numOfLiter ||
        !amountPaid
      ) {
        return createToast("All fields are required!", {
          type: "danger",
          timeout: 2000,
          position: "top-left",
        });
      }

      const payload = {
        date,
        vehicle,
        amountPaid,
        fuelType,
        costPerLitre: cost,
        noOfLitres: numOfLiter,
      };

      this.loading = true;
      const record = await this.$store.dispatch(
        "dieselCosts/addDieselCosts", // need to update api
        payload
      );

      if (record) {
        this.loading = false;
        createToast("Diesel costs added successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            this.$store.dispatch("overhead/setRefreshStatus", true);
            this.closeModal();
          },
        });
      }
    },
  },

  watch: {
    dieselForm: {
      handler: function (newValue) {
        newValue.amountPaid = newValue.cost * newValue.numOfLiter;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}
</style>
