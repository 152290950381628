<template>
  <div>
    <styled-button
      :width="width"
      :height="height"
      :bgColor="bgColor"
      :labelColor="labelColor"
      :padding="padding"
      ><img :src="imagePath" alt="" class="icon" v-if="iconName" /><span>{{
        label
      }}</span></styled-button
    >
  </div>
</template>

<script>
import { StyledButton } from "./invertedBlackButton.styles";

export default {
  name: "InvertedBlackButton",
  components: {
    StyledButton,
  },
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    padding: {
      type: String,
    },
    iconName: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    labelColor: {
      type: String,
    },
    bgColor: {
      type: String,
    },
  },

  computed: {
    imagePath() {
      return require(`../../../assets/${this.iconName}.png`);
    },
  },
};
</script>

<style scoped>
.icon {
  width: 12px;
  height: auto;
  margin-right: 6px;
}
</style>
