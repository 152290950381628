<template>
  <div>
    <div class="">
      <div class="layout-parent">
        <div class="side-bar-container"><Sidebar /></div>
        <div class="main-content-container">
          <div class="header-container"><Header /></div>

          <div class="main-content">
            <slot />
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
x

<script>
import Header from "../header/Header.vue";
import Sidebar from "../side-bar/SideBar.vue";
export default {
  name: "DashboardLayout",

  components: {
    Header,
    Sidebar,
  },

  data() {
    return {};
  },

  methods: {},

  computed: {
    isAuth() {
      return !!this.$store.getters["auth/getToken"];
    },
  },
};
</script>

<style scoped>
.layout-parent {
  display: flex;
  width: 100%;
  height: 100vh;
}

.side-bar-container {
  width: 18%;
  height: 110%;
}

.main-content-container {
  flex: 1;
}

.header-container {
  width: 100%;
  height: 8%;
}

.main-content {
  width: 100%;
  height: 100%;
}
</style>
