<template>
  <div>
    <Container>
      <Header>{{ header }}</Header>
      <Info :status="status">{{ validateInput }}</Info>
    </Container>
  </div>
</template>

<script>
import { Container, Header, Info } from "./info-box.styles";
export default {
  name: "InfoBox",

  data() {
    return {
      textStatus: "",
    };
  },

  props: {
    header: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    },
    status: {
      type: String,
    },
  },


  components: {
    Container,
    Header,
    Info,
  },

  computed: {
    validateInput() {
      return this.info?.length > 0 ? this.info : "Nil";
    },
  },
};
</script>
