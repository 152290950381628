import styled from "vue3-styled-components";

export const StyledButton = styled.button`
  background: #fff;
  border-radius: 40px;
  border: 2px solid #ed0a0a;
  outline: none;
  color: #ed0a0a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;

  @media only screen and (min-width: 280px) {
    padding: 0px 2.4rem;
    gap: 8px;

    width: ${({ width }) => (width ? width : "109%")};
    height: ${({ height }) => (height ? height : "3rem")};

    & i {
      color: #669933;
    }
  }
`;
