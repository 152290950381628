import { createWebHistory, createRouter } from "vue-router";
import Mills from "../pages/mills/Mills.vue";
import LandingPage from "../components/home/LandingPage.vue";
import ForgotPassword from "../components/auth/ForgotPassword.vue";
import ResetPassword from "../components/auth/ResetPassword.vue";
import VerificationPage from "../components/auth/VerificationPage.vue";
import CommunityPage from "../components/community/CommunityPage.vue";
import AddCommunity from "../components/community/AddCommunity.vue";
import CommunityDetails from "../components/community/CommunityDetails.vue";
import Farmers from "../pages/farmers/Farmers.vue";
import Purchases from "../pages/purchases/Purchases.vue";
import Discovery from "../components/discovery/Discovery.vue";

import Logistics from "../pages/logistics/Logistics.vue";
import Aggregation from "../pages/logistics/Aggregation.vue";
import Dispatch from "../pages/logistics/Dispatch.vue";
import Inventory from "../pages/inventory/Inventory.vue";
import InventoryList from "../pages/inventory/InventoryList.vue";
import InventoryTarget from "../pages/inventory/InventoryTarget.vue";
import Dashboard from "../pages/dashboard/Dashboard.vue";
import Admin from "../pages/dashboard/Admin.vue";
import AssetManagement from "../pages/asset-management/AssetManagement.vue";
import outboundAwareness from "../pages/outbound-awareness/OutboundAwareness.vue";
import Settings from "../pages/settings/Settings.vue";
import ProfitInput from "../pages/profit-input/ProfitInput.vue";
import Overhead from "../pages/overhead/Overhead.vue";
import Revenue from "../pages/revenue/Revenue.vue";
import FarmerDash from "../pages/dashboard/pages/FarmerDash.vue";
import PurchaseDash from  "../pages/dashboard/pages/PurchaseDash.vue";
import InventoryDash from "../pages/dashboard/pages/InventoryDash.vue";
import OverheadDash from "../pages/dashboard/pages/OverheadDash.vue";
import LandedDash from "../pages/dashboard/pages/LandedDash.vue";
import AveTonFarmer from "../pages/dashboard/pages/AveTonsFarmerDash.vue";
import SupplyValidation from "../pages/supplyValidation/SupplyValidation.vue"
import Login from "../components/auth/LoginComponent.vue";
import { AuthUser } from "../utils/authUser";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      requiresGuest: true,
    },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: Admin,
    children: [
      {
        path: "/dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/purchase-dash",
        component: PurchaseDash,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/inventory-dash",
        component: InventoryDash,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/inventory-dash/:activeNav",
        component: InventoryDash,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/overhead-dash",
        component: OverheadDash,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/landed-dash",
        component: LandedDash,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/aveTonFarmer-dash",
        component: AveTonFarmer,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/farmer-dash",
        component: FarmerDash,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/discovery",
        component: Discovery,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/purchases",
        component: Purchases,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/farmers",
        component: Farmers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/logistics",
        component: Logistics,
        children: [
          {
            path: "/dashboard/logistics/aggregation",
            component: Aggregation,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/dashboard/logistics/dispatch",
            component: Dispatch,
            meta: {
              requiresAuth: true,
            },
          },
        ],
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/mills",
        name: "Mills",
        component: Mills,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/supplyValidation",
        name: "SupplyValidation",
        component: SupplyValidation,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/inventory",
        name: "Inventory",
        component: Inventory,
        children: [
          {
            path: "/dashboard/inventory",
            component: InventoryList,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/dashboard/inventory/target",
            component: InventoryTarget,
            meta: {
              requiresAuth: true,
            },
          },
        ],
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/asset-management",
        component: AssetManagement,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/outbound-awareness",
        component: outboundAwareness,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/settings",
        component: Settings,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/profit-input",
        component: ProfitInput,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/overhead",
        component: Overhead,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/revenue",
        component: Revenue,
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/forgot",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/home",
    name: "LandingPage",
    component: LandingPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/verification",
    name: "VerificationPage",
    component: VerificationPage,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/community",
    name: "CommunityPage",
    component: CommunityPage,
  },

  {
    path: "/community/add",
    name: "AddCommunity",
    component: AddCommunity,
  },

  {
    path: "/community/:communityId",
    name: "CommunityDetails",
    component: CommunityDetails,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // const isAuth = store.getters["auth/getAuth"];
  const authUser = new AuthUser();
  const loggedInUser = authUser.getDetails();

  // console.log(isAuth);
  if (to.path === "/" && loggedInUser) {
    // router.push({ name: "Dashboard" });
    next("/dashboard");
    return;
  }
  if (requiresAuth && !loggedInUser) {
    router.push({ name: "Login" });
    return;
  } else {
    next();
  }
});

export default router;
