export const millsTableHeaders = [
  { text: "Name", value: "name" },
  { text: "Code", value: "code" },
  { text: "State", value: "state" },
  { text: "Local Govt", value: "lga" },
  { text: "Community", value: "community" },
  { text: "Latitude", value: "latitude" },
  { text: "Longitude", value: "longitude" },
  { text: "Actions", value: "actions" },
];
