<template>
  <div class="modal-parent">
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h6 class="modal-caption">Edit Aggregation</h6>

            <p>Step 1 of 2</p>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <section
          class="purchase__section"
          v-if="purchasesToBeAggregated.length > 0"
        >
          <div class="purchases__aggregated">
            <p class="purchase__header">Purchase</p>
            <p class="purchase__header">Quantity Moved</p>
            <p class="purchase__header">Left</p>
          </div>
          <div
            class="purchases__aggregated"
            v-for="purchase in purchasesToBeAggregated"
            :key="purchase.purchaseId"
          >
            <p class="purchase__values">{{ purchase.purchaseId }}</p>
            <p class="purchase__values">{{ purchase.qtyMoved }} Ton(s)</p>
            <p class="purchase__values">
              {{ purchase.amountLeft }} Ton(s)
              <span @click="removePurchaseItem(purchase.purchaseId)">x</span>
            </p>
          </div>
        </section>
        <PurchaseDropdown
          :options="purchases"
          class="select"
          dropdownTitle="Purchase"
          @onSelect="handleSelect"
          fieldName="purchase"
          fieldValue="purchase"
          :inputValue="aggregationForm?.purchase"
          :reset="reset"
          @onReset="handleReset"
        />

        <NumberInput
          placeholder="Quantity to be moved"
          @onInputChange="handleInputChange"
          fieldName="qtyMoved"
          :inputValue="aggregationForm?.qtyMoved"
        />

        <div class="add-purchase">
          <BlackButton
            label="Add Purchase"
            width="12rem"
            height="1.9rem"
            iconName="add-icon"
            @click="addPurchase"
          />
        </div>

        <Dropdown
          :options="vehicles"
          class="select"
          dropdownTitle="Vehicle"
          @onSelect="handleSelect"
          fieldName="vehicleId"
          fieldValue="vehicle"
          :inputValue="aggregationForm?.vehicle"
        />

        <Dropdown
          :options="drivers"
          class="select"
          dropdownTitle="Driver"
          @onSelect="handleSelect"
          fieldName="driverId"
          fieldValue="driver"
          :inputValue="aggregationForm?.driver"
        />

        <Dropdown
          :options="APs"
          class="select"
          dropdownTitle="Destination (Aggregation Point)"
          @onSelect="handleSelect"
          fieldName="apId"
          fieldValue="AP"
          :inputValue="aggregationForm?.AP"
        />

        <DateField
          placeholder="Start Date"
          @onInputChange="handleInputChange"
          fieldName="startDate"
          :inputValue="aggregationForm?.startDate"
        />

        <TimeField
          placeholder="Start time"
          @onInputChange="handleInputChange"
          fieldName="startTime"
          :inputValue="aggregationForm?.startTime"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton label="Save and Continue" @click="routeToNextPage" />
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";

import NumberInput from "../../../components/form-inputs/number-input/NumberInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import BlackButton from "../../../components/buttons/black-button/BlackButton.vue";
import Dropdown from "../../../components/form-inputs/select-dropdown/Dropdown.vue";
import PurchaseDropdown from "../../../components/form-inputs/select-dropdown/PurchaseDropdown.vue";
import DateField from "../../../components/form-inputs/date-input/DateField.vue";
import TimeField from "../../../components/form-inputs/date-input/TimeField.vue";

export default {
  name: "StartAggregationModal1",

  components: {
    GreenButton,
    BlackButton,
    DateField,
    Dropdown,
    NumberInput,
    PurchaseDropdown,
    TimeField,
  },

  data() {
    return {
      aggregationForm: this.$store.getters[
        "aggregation/getAggregationToBeUpdated"
      ] || {
        apId: "",
        AP: "",
        purchase: "",
        qtyMoved: "",
        vehicle: "",
        vehicleId: "",
        driver: "",
        driverId: "",
        startDate: "",
        startTime: "",
      },
      globalInputtedAggregation: {},
      APs: [],
      vehicles: [],
      drivers: [],
      purchases: [],
      purchasesToBeAggregated:
        this.$store.getters["aggregation/getAggregationToBeUpdated"][
          "purchasesAggregated"
        ],
      error: "",
      reset: false,
    };
  },

  mounted() {
    this.fetchAPs();
    this.fetchVehicles();
    this.fetchDrivers();
    this.fetchPurchases();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
      this.$store.dispatch("aggregation/setEditModalStatus", false);
    },

    handleReset(value) {
      this.reset = value;
    },

    handleSelect(obj) {
      this.aggregationForm = {
        ...this.aggregationForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.aggregationForm = {
        ...this.aggregationForm,
        ...obj,
      };
    },

    routeToNextPage() {
      // let { apName, vehicleName, driverName, startDate, startTime } =
      //   this.aggregationForm;

      // if (
      //   !this.purchasesToBeAggregated.length ||
      //   !apName ||
      //   !vehicleName ||
      //   !driverName ||
      //   !startDate ||
      //   !startTime
      // ) {
      //   createToast("All fields are required", {
      //     type: "danger",
      //     timeout: 5000,
      //     position: "top-right",
      //   });
      //   return;
      // }

      this.$emit("onSetAddModalPage", 2);

      this.globalInputtedAggregation =
        this.$store.getters["aggregation/getInputtedAggregation"];
      this.globalInputtedAggregation = {
        ...this.globalInputtedAggregation,
        ...this.aggregationForm,
        purchasesToBeAggregated: this.purchasesToBeAggregated,
      };

      this.$store.dispatch(
        "aggregation/setValuesForAggregationUpdate",
        this.globalInputtedAggregation
      );
    },

    addPurchase(e) {
      e.preventDefault();
      let { purchase, qtyMoved, availableQty, id } = this.aggregationForm;
      if (!purchase) {
        createToast("Please select a purchase", {
          type: "danger",
          timeout: 5000,
          position: "top-right",
        });

        return;
      }

      if (!qtyMoved) {
        createToast("Please input quantity", {
          type: "danger",
          timeout: 5000,
          position: "top-right",
        });
        return;
      }

      if (availableQty < qtyMoved) {
        createToast("Quantity inputted greater than available tonnage", {
          type: "danger",
          timeout: 5000,
          position: "top-right",
        });
        return;
      }

      const newPurchaseForAggregation = {
        id,
        purchaseId: purchase,
        qtyMoved,
        amountLeft: (availableQty - qtyMoved).toFixed(2),
      };

      this.aggregationForm.qtyMoved = 0;
      this.reset = true;

      const match = this.purchasesToBeAggregated.find(
        (el) => el.purchaseId === newPurchaseForAggregation.purchaseId
      );

      if (!match) {
        this.purchasesToBeAggregated.push(newPurchaseForAggregation);
      } else {
        this.purchasesToBeAggregated = this.purchasesToBeAggregated.map(
          (el) => {
            if (el.purchaseId === newPurchaseForAggregation.purchaseId) {
              return {
                ...el,
                qtyMoved: newPurchaseForAggregation.qtyMoved,
                amountLeft: newPurchaseForAggregation.amountLeft,
              };
            }

            return el;
          }
        );
      }
    },

    removePurchaseItem(id) {
      this.purchasesToBeAggregated = this.purchasesToBeAggregated.filter(
        (el) => el.purchaseId !== id
      );
    },

    async fetchPurchases(page = 1, searchTerm = "") {
      const { purchases } = await this.$store.dispatch(
        "purchases/getPurchases",
        {
          page,
          searchTerm,
        }
      );

      this.purchases = purchases;
    },

    async fetchAPs(page = 1) {
      this.APs = await this.$store.dispatch("aggregationPoint/fetchAllAPs", {
        page,
      });
    },

    async fetchVehicles(page = 1) {
      const { assets } = await this.$store.dispatch(
        "vehicleManagement/fetchVehicles",
        {
          page,
        }
      );

      this.vehicles = assets;
    },

    async fetchDrivers(page = 1) {
      this.drivers = await this.$store.dispatch("driver/fetchAllDrivers", {
        page,
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 800;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: 600px;
  display: flex;
  flex-direction: column;
}

.form {
  margin: 5% auto;
  width: 75%;
  height: auto;
  overflow: scroll;
  flex: 1;
}

.purchase__section {
  background: #232a34;
  border-radius: 16px;
  width: 100%;
  height: auto;
  margin-inline: auto;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-top: 25px;
  color: #fff;
}

.purchases__aggregated {
  margin-inline: auto;
  width: 90%;
  height: 85%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.purchase__header {
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  font-weight: 100;
  margin: 2px;
}

.purchase__values {
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  align-self: start;
  color: #fff;
  margin: 3px 5px;
}

.purchase__values span {
  color: #fff;
  margin-left: 8px;
  cursor: pointer;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 25%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 1% auto 2% auto;
}

.add-purchase {
  display: flex;
  width: 42%;
  margin-bottom: 2%;
  margin-left: auto;
}
</style>
