<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <h5 class="modal-caption">Edit Mills</h5>

          <div class="header-content-right">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-section">
      <form class="form">
        <TextInput
          placeholder="Mill Name"
          @onInputChange="handleInputChange"
          fieldName="name"
          :inputValue="millForm?.name"
          :disable="false"
        />

        <SelectDropdown
          :options="['Yes', 'No']"
          class="select"
          dropdownTitle="Physically Tagged?"
          @onSelect="handleSelect"
          fieldName="physically_tagged"
          :inputValue="millForm?.physically_tagged"
          :disable="false"
        />

        <SelectDropdown
          :options="['Small', 'Medium', 'Large']"
          class="select"
          dropdownTitle="Mill Size"
          @onSelect="handleSelect"
          fieldName="size"
          :inputValue="millForm?.size"
        />

        <SelectDropdown
          :options="['Yes', 'No']"
          class="select"
          dropdownTitle="Have Cracker?"
          @onSelect="handleSelect"
          fieldName="cracker"
          :inputValue="millForm?.cracker"
        />

        <TextInput
          placeholder="Number of Pot"
          @onInputChange="handleInputChange"
          fieldName="number_of_pots"
          :inputValue="millForm?.number_of_pots"
        />

        <TextInput
          placeholder="Number of pressers"
          @onInputChange="handleInputChange"
          fieldName="number_of_pressers"
          :inputValue="millForm?.number_of_pressers"
        />

        <TextInput
          placeholder="Latitude"
          @onInputChange="handleInputChange"
          fieldName="latitude"
          :inputValue="millForm?.location?.latitude"
        />

        <TextInput
          placeholder="Longitude"
          @onInputChange="handleInputChange"
          fieldName="longitude"
          :inputValue="millForm?.location?.longitude"
        />

        <SearchableStatesDropdown
          :options="states"
          class="select"
          dropdownTitle="State"
          @onSelect="handleSelect"
          fieldName="state_id"
          :inputValue="millForm?.state_id"
        />

        <SearchableStatesDropdown
          :options="lgas"
          class="select"
          dropdownTitle="Local Government"
          @onSelect="handleSelect"
          fieldName="lga_id"
          :inputValue="millForm?.lga_id"
        />

        <SearchabledropFarmers
          :options="communities"
          class="select"
          dropdownTitle="Community"
          @onSelect="handleSelect"
          fieldName="community_id"
          :inputValue="millForm?.community_id"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton
        label="Update Mill"
        @click="updateMill"
        :disable="isRequiredFieldsEmpty"
        width="300px"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";
import { states } from "../../../data/location/states";
import { LGAs } from "../../../data/location/lga";
import SearchabledropFarmers from "../../../components/form-inputs/select-dropdown/SearchabledropFarmers.vue";
import SearchableStatesDropdown from "../../../components/form-inputs/select-dropdown/SearchableStatesDropdown.vue";

export default {
  name: "EditMillModal",
  data() {
    return {
      millForm: this.$store.getters["mills/getMillToBeUpdated"] || {
        name: "",
        physically_tagged: "",
        size: "",
        cracker: "",
        number_of_pots: "",
        number_of_pressers: "",
        state_id: "",
        community_id: "",
        lga_id: "",
        latitude: 0,
        longitude: 0,
        location: {},
      },
      millId: this.$store.getters["mills/getMillToBeUpdated"]["id"],
      states: this.sortState(states),
      lgas: [],
      communities: [],
      loading: false,
    };
  },

  components: {
    TextInput,
    SelectDropdown,
    GreenButton,
    Loading,
    SearchabledropFarmers,
    SearchableStatesDropdown,
  },

  mounted() {
    this.fetchCommunities();
    console.log(this.$store.getters["mills/getMillToBeUpdated"]);
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    handleSelect(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    sortState(stateArr) {
      return stateArr.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },

    async fetchCommunities(parent_slug) {
      try {
        this.loading = true;
        const data = await this.$store.dispatch("community/getCommunites");

        const communities = data.filter(
          (el) => el?.parent_slug === parent_slug
        );

        if (communities.length > 0) {
          communities.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }

        this.communities = communities;

        this.loading = false;
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },

    fetchLgas(state_slug) {
      try {
        this.loading = true;

        const lga = LGAs.filter((el) => el.parent_slug === state_slug);
        if (lga.length > 0) {
          lga.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }

        this.lgas = lga;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async updateMill(e) {
      e.preventDefault();
      this.loading = true;
      let {
        name,
        physically_tagged,
        size,
        cracker,
        number_of_pots,
        number_of_pressers,
        state_id,
        community_id,
        lga_id,
        location,
        latitude,
        longitude,
      } = this.millForm;

      location = {
        ...location,
        latitude: Number(latitude),
        longitude: Number(longitude),
      };

      try {
        const payload = {
          id: this.millId,
          name,
          physically_tagged,
          size,
          cracker,
          number_of_pots: Number(number_of_pots),
          number_of_pressers: Number(number_of_pressers),
          stateId: state_id?._id,
          lgaId: lga_id?._id,
          communityId: community_id?._id,
          location,
        };

        // console.log(payload);

        const data = await this.$store.dispatch("mills/updateMill", payload);

        if (data.id) {
          this.loading = false;
          createToast("Mill updated successfully", {
            type: "success",
            position: "top-right",
            timeout: 3000,

            onClose: () => {
              window.location.reload();
            },
          });
        }

        this.$emit("onCloseModal");
      } catch (error) {
        this.loading = false;
      }
    },
  },

  watch: {
    "millForm.state_id"(newValue) {
      this.fetchLgas(newValue.slug);
    },
    "millForm.lga"(newValue) {
      console.log({ newValue });
      if (newValue && this.millForm.state === "") {
        createToast("Please select a state", {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });
      } else {
        this.fetchCommunities(newValue.slug);
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.input {
  width: 300px;
  height: 3.5rem;
  border: 0.5px solid #232a34;
  border-radius: 5px;
}

.input input {
  width: 100%;
  height: 100%;
}

.x-container:hover {
  background: #e8e8e8;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.category-container {
  margin-top: 70px;
}

.form-section {
  height: 430px;
  overflow-y: scroll;
}

.form {
  margin: 5% auto;
  width: 75%;
  height: 70%;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 100%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
