import styled from "vue3-styled-components";

export const StyledText = styled.h4`
  background: none;
  color: ${({ kpiColor1 }) => (kpiColor1 ? kpiColor1 : "#003")};
  text-align: center;
  font-size: 1.4rem;
  font-weight: 800;
  margin: 0 auto;
  padding: 0 0;


  &:focus {
    outline: none;
 } 

`;

export const SecondMetric = styled.span`
  color: ${({ kpiColor2 }) => (kpiColor2 ? kpiColor2 : "#003")};
  &:focus {
    outline: none;
  } 
`;
