import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  aggregationPoints: [],
  error: null,
});

const getters = {};
const mutations = {
  saveAPs(state, APs) {
    state.aggregationPoints = APs;
  },
};
const actions = {
  async fetchAllAPs({ commit }, params) {
    const { page } = params;
    try {
      const { data } = await APIConfig.get(`/destination/?page=${page}`);

      const APs = data.map((el) => {
        return {
          id: el._id,
          name: el.name,
        };
      });

      commit("saveAPs", APs);

      return APs;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
