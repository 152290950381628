export const LGAs = [
  {
    _id: "60c358bf2079e2001e77dfca",
    name: "Ohafia",
    slug: "OHAF",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975591",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087412984",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd0",
    name: "Umuahia South",
    slug: "UMSO",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975592",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087528558",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfce",
    name: "Ukwa West",
    slug: "UKWE",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975592",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087525422",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e00f",
    name: "Ihiala",
    slug: "IHIA",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975611",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088248787",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e01b",
    name: "Damban",
    slug: "DAMB",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975614",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088372288",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e045",
    name: "Tarka",
    slug: "TARK",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975627",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088963292",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e04a",
    name: "Bama",
    slug: "BAMA",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975627",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088969123",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e05a",
    name: "Mafa",
    slug: "MAFA",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089203885",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e065",
    name: "Akpabuyo",
    slug: "AKPA",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089325235",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e068",
    name: "Biase",
    slug: "BIAS",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089328873",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e070",
    name: "Obudu",
    slug: "OBUD",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089447985",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e080",
    name: "Okpe",
    slug: "OKPE",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089681837",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e09c",
    name: "Esan South-East",
    slug: "ESSE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089934399",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ad",
    name: "Ekiti West",
    slug: "EKWE",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090168193",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b3",
    name: "Ikole",
    slug: "IKOL",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090282287",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b4",
    name: "Ilejemeje",
    slug: "ILEJ",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090284012",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b8",
    name: "Oye",
    slug: "OYE",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090288791",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c3",
    name: "Nkanu West",
    slug: "NKWE",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090408914",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0df",
    name: "Isiala Mbano",
    slug: "ISMB",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090768398",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e2",
    name: "Ngor Okpala",
    slug: "NGOK",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090771547",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e10e",
    name: "Igabi",
    slug: "IGAB",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091364168",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e11d",
    name: "Sabon Gari",
    slug: "SAGA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091491952",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e11f",
    name: "Soba",
    slug: "SOBA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091494559",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e129",
    name: "Dawakin Kudu",
    slug: "DAKU",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091613459",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e152",
    name: "Dandume",
    slug: "DAND",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092202242",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e15b",
    name: "Jibia",
    slug: "JIBI",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092213305",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e170",
    name: "Augie",
    slug: "AUGI",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092561479",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e174",
    name: "Dandi",
    slug: "DAND",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092567294",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e180",
    name: "Yauri",
    slug: "YAUR",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092691999",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e183",
    name: "Ankpa",
    slug: "ANKP",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092696934",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e197",
    name: "Edu",
    slug: "EDU",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092933200",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b3",
    name: "Mushin",
    slug: "MUSH",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093290415",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ea",
    name: "Odeda",
    slug: "ODED",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094004538",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f1",
    name: "Akoko South-East",
    slug: "AKSE",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094013566",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ff",
    name: "Owo",
    slug: "OWO",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094246309",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e210",
    name: "Ilesa East",
    slug: "ILEA",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094482832",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e217",
    name: "Odo Otin",
    slug: "ODOT",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094492758",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e232",
    name: "Ogo Oluwa",
    slug: "OGOL",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094847576",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e233",
    name: "Olorunsogo",
    slug: "OLOR",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094848629",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e23f",
    name: "Jos East",
    slug: "JOEA",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094969652",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e247",
    name: "Mikang",
    slug: "MIKA",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095088360",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e262",
    name: "Tai",
    slug: "TAI",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095445399",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e26d",
    name: "Rabah",
    slug: "RABA",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095566912",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e26e",
    name: "Sabon Birni",
    slug: "SABI",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095568329",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e278",
    name: "Yabo",
    slug: "YABO",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095687101",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e279",
    name: "Bali",
    slug: "BALI",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095688411",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e28e",
    name: "Gulani",
    slug: "GULA",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095930783",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e291",
    name: "Machina",
    slug: "MACH",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095935463",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e297",
    name: "Yusufari",
    slug: "YUSU",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096048140",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd8",
    name: "Jada",
    slug: "JADA",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975593",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087643987",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe4",
    name: "Yola North",
    slug: "YONO",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975595",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087766556",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff6",
    name: "Mbo",
    slug: "MBO",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088004434",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfff",
    name: "Oruk Anam",
    slug: "ORAN",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088123191",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e011",
    name: "Nnewi North",
    slug: "NNNO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088250879",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e01a",
    name: "Bogoro",
    slug: "BOGO",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088371355",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e01c",
    name: "Darazo",
    slug: "DARA",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975614",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088481726",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e021",
    name: "Itas/Gadau",
    slug: "ITGA",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975614",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088487743",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e02b",
    name: "Zaki",
    slug: "ZAKI",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088606992",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e038",
    name: "Gwer East",
    slug: "GWEA",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088733089",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e03e",
    name: "Makurdi",
    slug: "MAKU",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975624",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088847120",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e04c",
    name: "Biu",
    slug: "BIU",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088972041",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e061",
    name: "Nganzai",
    slug: "NGAN",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089212516",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a1",
    name: "Igueben",
    slug: "IGUE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090045851",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0aa",
    name: "Efon",
    slug: "EFON",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090164720",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b1",
    name: "Ijero",
    slug: "IJER",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090174077",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0bc",
    name: "Enugu South",
    slug: "ENSO",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090401275",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c9",
    name: "Bwari",
    slug: "BWAR",
    parent_slug: "FCT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090526982",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ce",
    name: "Balanga",
    slug: "BALA",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090534569",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d8",
    name: "Ahiazu Mbaise",
    slug: "AHMB",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090651395",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f6",
    name: "Dutse",
    slug: "DUTS",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091011871",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e102",
    name: "Kiyawa",
    slug: "KIYA",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091242987",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e110",
    name: "Jaba",
    slug: "JABA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091366692",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e13c",
    name: "Madobi",
    slug: "MADO",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091851384",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e144",
    name: "Sumaila",
    slug: "SUMA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091969110",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e157",
    name: "Dutsin Ma",
    slug: "DUMA",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092208137",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e196",
    name: "Baruten",
    slug: "BARU",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092931972",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e19a",
    name: "Ilorin East",
    slug: "ILEA",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093045498",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b0",
    name: "Kosofe",
    slug: "KOSO",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093286706",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c2",
    name: "Toto",
    slug: "TOTO",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093524847",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d0",
    name: "Magama",
    slug: "MAGA",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093649281",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e5",
    name: "Ijebu Ode",
    slug: "IJOD",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093890841",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f8",
    name: "Ile Oluji/Okeigbo",
    slug: "ILOO",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094132257",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e202",
    name: "Aiyedire",
    slug: "AIYE",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094249695",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e207",
    name: "Ife Central",
    slug: "IFCE",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094363432",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e21c",
    name: "Osogbo",
    slug: "OSOG",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094603794",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e21e",
    name: "Atiba",
    slug: "ATIB",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094606192",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e22e",
    name: "Kajola",
    slug: "KAJO",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094842180",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e241",
    name: "Jos South",
    slug: "JOSO",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094972061",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e243",
    name: "Kanke",
    slug: "KANK",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095083188",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e256",
    name: "Etche",
    slug: "ETCH",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095322275",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e257",
    name: "Gokana",
    slug: "GOKA",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095323833",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e26c",
    name: "Kware",
    slug: "KWAR",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095565530",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e270",
    name: "Silame",
    slug: "SILA",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095570745",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e276",
    name: "Wamako",
    slug: "WAMA",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095684123",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e28d",
    name: "Gujba",
    slug: "GUJB",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095929443",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd7",
    name: "Hong",
    slug: "HONG",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975593",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087642824",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc4",
    name: "Bende",
    slug: "BEND",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975590",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087406557",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfdb",
    name: "Maiha",
    slug: "MAIH",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975593",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087647736",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfdd",
    name: "Michika",
    slug: "MICH",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975594",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087649837",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff8",
    name: "Nsit-Atai",
    slug: "NSAT",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088006956",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e00a",
    name: "Ayamelum",
    slug: "AYAM",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975611",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088243685",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e01f",
    name: "Ganjuwa",
    slug: "GANJ",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975614",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088485300",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e055",
    name: "Kaga",
    slug: "KAGA",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089090160",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e063",
    name: "Abi",
    slug: "ABI",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089322734",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e06e",
    name: "Obanliku",
    slug: "OBAN",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089445249",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e074",
    name: "Yala",
    slug: "YALA",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089453666",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e088",
    name: "Ukwuani",
    slug: "UKWU",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089690605",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0cd",
    name: "Municipal Area Council",
    slug: "MUAC",
    parent_slug: "FCT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090532951",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0da",
    name: "Ezinihitte",
    slug: "EZIN",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090762302",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0fa",
    name: "Guri",
    slug: "GURI",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091124427",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e108",
    name: "Roni",
    slug: "RONI",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091251351",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e111",
    name: "Jema'a",
    slug: "JEMA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091367854",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e11e",
    name: "Sanga",
    slug: "SANG",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091493434",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e125",
    name: "Bichi",
    slug: "BICH",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091608736",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e137",
    name: "Kibiya",
    slug: "KIBI",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091845471",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e13a",
    name: "Kunchi",
    slug: "KUNC",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091848982",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e13d",
    name: "Makoda",
    slug: "MAKO",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091852501",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e140",
    name: "Rano",
    slug: "RANO",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091964094",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e145",
    name: "Takai",
    slug: "TAKA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091970375",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e147",
    name: "Tofa",
    slug: "TOFA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091973090",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e14d",
    name: "Batagarawa",
    slug: "BATA",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092088216",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e17f",
    name: "Wasagu/Danko",
    slug: "WADA",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092690851",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e182",
    name: "Ajaokuta",
    slug: "AJAO",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092695371",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e192",
    name: "Olamaboro",
    slug: "OLAM",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092927322",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a4",
    name: "Pategi",
    slug: "PATE",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093164703",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b5",
    name: "Oshodi-Isolo",
    slug: "OSIS",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093292778",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b6",
    name: "Shomolu",
    slug: "SHOM",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093404897",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ba",
    name: "Karu",
    slug: "KARU",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093410031",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e203",
    name: "Boluwaduro",
    slug: "BOLU",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094251643",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e220",
    name: "Egbeda",
    slug: "EGBE",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094608628",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e223",
    name: "Ibadan North-West",
    slug: "IBNW",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094612279",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e249",
    name: "Qua'an Pan",
    slug: "QUPA",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095091151",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e24c",
    name: "Wase",
    slug: "WASE",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095202909",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e269",
    name: "Illela",
    slug: "ILLE",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095455491",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e27d",
    name: "Ibi",
    slug: "IBI",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095693454",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e284",
    name: "Ussa",
    slug: "USSA",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095809156",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e29a",
    name: "Bukkuyum",
    slug: "BUKK",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096051865",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfcd",
    name: "Ukwa East",
    slug: "UKEA",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975591",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087523782",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfde",
    name: "Mubi North",
    slug: "MUNO",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975594",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087650845",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe5",
    name: "Yola South",
    slug: "YOSO",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975595",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087767733",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe6",
    name: "Abak",
    slug: "ABAK",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975595",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087768870",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e018",
    name: "Oyi",
    slug: "OYI",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088368713",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e019",
    name: "Bauchi",
    slug: "BAUC",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088370249",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e025",
    name: "Misau",
    slug: "MISA",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975615",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088491935",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e032",
    name: "Yenagoa",
    slug: "YENA",
    parent_slug: "BAY",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088724448",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e033",
    name: "Apa",
    slug: "APA",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088726144",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e062",
    name: "Shani",
    slug: "SHAN",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089321465",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e06b",
    name: "Calabar South",
    slug: "CASO",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089332396",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e06f",
    name: "Obubra",
    slug: "OBUB",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089446843",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e085",
    name: "Udu",
    slug: "UDU",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089687284",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e4",
    name: "Nkwerre",
    slug: "NKWE",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090882385",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ee",
    name: "Owerri Municipal",
    slug: "OWMU",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091002158",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ff",
    name: "Kafin Hausa",
    slug: "KAHA",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091130783",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e12c",
    name: "Fagge",
    slug: "FAGG",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091724143",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e153",
    name: "Danja",
    slug: "DANJ",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092203268",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e17d",
    name: "Shanga",
    slug: "SHAN",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092686666",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ab",
    name: "Eti Osa",
    slug: "ETOS",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093173062",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c5",
    name: "Bida",
    slug: "BIDA",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093528675",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c8",
    name: "Chanchaga",
    slug: "CHAN",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093532181",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d3",
    name: "Mokwa",
    slug: "MOKW",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093652475",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1da",
    name: "Tafa",
    slug: "TAFA",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093771261",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1de",
    name: "Egbado North",
    slug: "EGNO",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093881952",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e6",
    name: "Ikenne",
    slug: "IKEN",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093892386",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f3",
    name: "Akure South",
    slug: "AKSO",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094124675",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e204",
    name: "Boripe",
    slug: "BORI",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094253543",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e22a",
    name: "Irepo",
    slug: "IREP",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094729312",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e22b",
    name: "Iseyin",
    slug: "ISEY",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094730600",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e240",
    name: "Jos North",
    slug: "JONO",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094970802",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e24e",
    name: "Ahoada West",
    slug: "AHWE",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095206528",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e25b",
    name: "Ogba/Egbema/Ndoni",
    slug: "OGEN",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095329207",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e25c",
    name: "Ogu/Bolo",
    slug: "OGBO",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095330489",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e27e",
    name: "Jalingo",
    slug: "JALI",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095802021",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e2a1",
    name: "Shinkafi",
    slug: "SHIN",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975663",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096167666",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd1",
    name: "Umu Nneochi",
    slug: "UMNN",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975592",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087530182",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc2",
    name: "Aba South",
    slug: "ABSO",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975588",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087402737",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc7",
    name: "Isiala Ngwa South",
    slug: "ISNS",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975591",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087409845",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff4",
    name: "Ini",
    slug: "INI",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088002024",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd5",
    name: "Gombi",
    slug: "GOMB",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975593",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087534348",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfdc",
    name: "Mayo Belwa",
    slug: "MABE",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975593",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087648920",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe1",
    name: "Shelleng",
    slug: "SHEL",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975594",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087763013",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe3",
    name: "Toungo",
    slug: "TOUN",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975595",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087765375",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe8",
    name: "Eket",
    slug: "EKET",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975606",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087771421",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfec",
    name: "Etinan",
    slug: "ETIN",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975608",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087884283",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfef",
    name: "Ibiono-Ibom",
    slug: "IBIB",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975608",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087887474",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff2",
    name: "Ikot Abasi",
    slug: "IKAB",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087890787",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff3",
    name: "Ikot-Ekpene",
    slug: "IKEK",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087892119",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff7",
    name: "Mkpat-Enin",
    slug: "MKEN",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088005660",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e002",
    name: "Uruan",
    slug: "URUA",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088126679",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e007",
    name: "Anaocha",
    slug: "ANAO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088132349",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e016",
    name: "Orumba North",
    slug: "ORNO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088365981",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e020",
    name: "Giade",
    slug: "GIAD",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975614",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088486755",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e024",
    name: "Kirfi",
    slug: "KIRF",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975615",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088490838",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e03f",
    name: "Obi",
    slug: "OBI",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975624",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088848628",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e050",
    name: "Gubio",
    slug: "GUBI",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089084221",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e083",
    name: "Patani",
    slug: "PATA",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089685183",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e097",
    name: "Ohaukwu",
    slug: "OHAU",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975634",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089926689",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e09e",
    name: "Etsako Central",
    slug: "ETCE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090042151",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a6",
    name: "Ovia South-West",
    slug: "OVSW",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090052107",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c2",
    name: "Nkanu East",
    slug: "NKEA",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090407943",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d7",
    name: "Yamaltu/Deba",
    slug: "YADE",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090650141",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e117",
    name: "Kaura",
    slug: "KAUR",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091482937",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e11b",
    name: "Lere",
    slug: "LERE",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091489544",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e134",
    name: "Kabo",
    slug: "KABO",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091841674",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e14b",
    name: "Warawa",
    slug: "WARA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092085812",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e14f",
    name: "Baure",
    slug: "BAUR",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092090827",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e151",
    name: "Charanchi",
    slug: "CHAR",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092093141",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e161",
    name: "Kurfi",
    slug: "KURF",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092329723",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e162",
    name: "Kusada",
    slug: "KUSA",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092330725",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e165",
    name: "Mani",
    slug: "MANI",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092335065",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e176",
    name: "Gwandu",
    slug: "GWAN",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092569775",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e17c",
    name: "Sakaba",
    slug: "SAKA",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092684887",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e181",
    name: "Zuru",
    slug: "ZURU",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092694086",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e185",
    name: "Dekina",
    slug: "DEKI",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092804291",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a0",
    name: "Moro",
    slug: "MORO",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093053492",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a7",
    name: "Amuwo-Odofin",
    slug: "AMOD",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093168324",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1af",
    name: "Ikorodu",
    slug: "IKOR",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093285643",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b9",
    name: "Doma",
    slug: "DOMA",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093408768",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1bd",
    name: "Kokona",
    slug: "KOKO",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093413775",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1cc",
    name: "Katcha",
    slug: "KATC",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093643867",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1dd",
    name: "Ado-Odo/Ota",
    slug: "ADOO",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093774937",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e0",
    name: "Ewekoro",
    slug: "EWEK",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093884578",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1fd",
    name: "Ondo West",
    slug: "ONWE",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094243819",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e20e",
    name: "Ifelodun",
    slug: "IFEL",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094372468",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e21b",
    name: "Orolu",
    slug: "OROL",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094602541",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e23d",
    name: "Barkin Ladi",
    slug: "BALA",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094967149",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e242",
    name: "Kanam",
    slug: "KANA",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095081850",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e24b",
    name: "Shendam",
    slug: "SHEN",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095093336",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e25f",
    name: "Opobo/Nkoro",
    slug: "OPNK",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095335385",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e27a",
    name: "Donga",
    slug: "DONG",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095689775",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e286",
    name: "Yorro",
    slug: "YORR",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095811988",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e294",
    name: "Potiskum",
    slug: "POTI",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096044462",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e299",
    name: "Birnin Magaji/Kiyaw",
    slug: "BIMK",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096050686",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc3",
    name: "Arochukwu",
    slug: "AROC",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975590",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087404994",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc5",
    name: "Ikwuano",
    slug: "IKWU",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975590",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087407719",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfda",
    name: "Madagali",
    slug: "MADA",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975593",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087646658",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe2",
    name: "Song",
    slug: "SONG",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975594",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087764202",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfee",
    name: "Ibesikpo Asutan",
    slug: "IBAS",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975608",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087886421",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dffd",
    name: "Onna",
    slug: "ONNA",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088012845",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e001",
    name: "Ukanafun",
    slug: "UKAN",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088125468",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e009",
    name: "Awka South",
    slug: "AWSO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975611",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088242589",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e014",
    name: "Onitsha North",
    slug: "ONNO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088363206",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e027",
    name: "Shira",
    slug: "SHIR",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975615",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088602821",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e028",
    name: "Tafawa Balewa",
    slug: "TABA",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975615",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088603688",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd9",
    name: "Lamurde",
    slug: "LAMU",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975593",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087645620",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e03b",
    name: "Konshisha",
    slug: "KONS",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088843356",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e03c",
    name: "Kwande",
    slug: "KWAN",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975624",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088844657",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfea",
    name: "Essien Udim",
    slug: "ESUD",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975606",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087881891",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e046",
    name: "Ukum",
    slug: "UKUM",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975627",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088964499",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e04b",
    name: "Bayo",
    slug: "BAYO",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088970533",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff9",
    name: "Nsit-Ibom",
    slug: "NSIB",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088008186",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e06a",
    name: "Calabar Municipal",
    slug: "CAMU",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089331305",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e086",
    name: "Ughelli North",
    slug: "UGNO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089688373",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e00d",
    name: "Idemili North",
    slug: "IDNO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975611",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088246841",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e08f",
    name: "Ebonyi",
    slug: "EBON",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089812313",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e022",
    name: "Jama'are",
    slug: "JAMA",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975614",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088488782",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e092",
    name: "Ikwo",
    slug: "IKWO",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975634",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089815951",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e037",
    name: "Guma",
    slug: "GUMA",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088731759",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e093",
    name: "Ishielu",
    slug: "ISHI",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975634",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089817072",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e042",
    name: "Oju",
    slug: "OJU",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975625",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088852190",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e075",
    name: "Aniocha South",
    slug: "ANSO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089455681",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e096",
    name: "Ohaozara",
    slug: "OHAO",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975634",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089925094",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a9",
    name: "Uhunmwonde",
    slug: "UHUN",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090163529",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e076",
    name: "Bomadi",
    slug: "BOMA",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089561556",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ac",
    name: "Ekiti South-West",
    slug: "EKSW",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090167077",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e07a",
    name: "Ika North East",
    slug: "IKNE",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089566178",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0af",
    name: "Gbonyin",
    slug: "GBON",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090171168",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d1",
    name: "Funakaye",
    slug: "FUNA",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090642808",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e08d",
    name: "Afikpo North",
    slug: "AFNO",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089809637",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0db",
    name: "Ideato North",
    slug: "IDNO",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090763369",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e09a",
    name: "Esan Central",
    slug: "ESCE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089931297",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ec",
    name: "Oru East",
    slug: "OREA",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090893016",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0be",
    name: "Igbo Etiti",
    slug: "IGET",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090403736",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e121",
    name: "Zaria",
    slug: "ZARI",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091603691",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0bf",
    name: "Igbo Eze North",
    slug: "IGEN",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090404646",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e132",
    name: "Gwale",
    slug: "GWAL",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091732255",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e3",
    name: "Njaba",
    slug: "NJAB",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090772674",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e6",
    name: "Obowo",
    slug: "OBOW",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090884642",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e16d",
    name: "Zango",
    slug: "ZANG",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092450636",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e109",
    name: "Sule Tankarkar",
    slug: "SUTA",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091253119",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e186",
    name: "Ibaji",
    slug: "IBAJ",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092806089",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e120",
    name: "Zangon Kataf",
    slug: "ZAKA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091601631",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f6",
    name: "Ifedore",
    slug: "IFED",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094128850",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e12b",
    name: "Doguwa",
    slug: "DOGU",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091722946",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1fb",
    name: "Okitipupa",
    slug: "OKIT",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094136040",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e168",
    name: "Musawa",
    slug: "MUSA",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092444159",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e200",
    name: "Atakunmosa West",
    slug: "ATWE",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094247381",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e19c",
    name: "Ilorin West",
    slug: "ILWE",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093047932",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e20b",
    name: "Egbedore",
    slug: "EGBE",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094368710",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e225",
    name: "Ibadan South-West",
    slug: "IBSW",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094722906",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a1",
    name: "Offa",
    slug: "OFFA",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093054734",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e265",
    name: "Gada",
    slug: "GADA",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095449990",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a6",
    name: "Alimosho",
    slug: "ALIM",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093167078",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ac",
    name: "Ibeju-Lekki",
    slug: "IBLE",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093282114",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c3",
    name: "Wamba",
    slug: "WAMB",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093526206",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c6",
    name: "Borgu",
    slug: "BORG",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093529963",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1cd",
    name: "Kontagora",
    slug: "KONT",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093645009",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d2",
    name: "Mashegu",
    slug: "MASH",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093651437",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d6",
    name: "Rafi",
    slug: "RAFI",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093765153",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e7",
    name: "Imeko Afon",
    slug: "IMAF",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093893538",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f2",
    name: "Akure North",
    slug: "AKNO",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094122523",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e231",
    name: "Ogbomosho South",
    slug: "OGSO",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094846325",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e23a",
    name: "Saki East",
    slug: "SAEA",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094963854",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e267",
    name: "Gudu",
    slug: "GUDU",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095452413",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e275",
    name: "Tureta",
    slug: "TURE",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095682859",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e28f",
    name: "Jakusko",
    slug: "JAKU",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095932406",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e29b",
    name: "Bungudu",
    slug: "BUNG",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096052973",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e29c",
    name: "Gummi",
    slug: "GUMM",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096161964",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e2a0",
    name: "Maru",
    slug: "MARU",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096166779",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e000",
    name: "Udung-Uko",
    slug: "UDUK",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088124340",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e00c",
    name: "Ekwusigo",
    slug: "EKWU",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975611",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088245774",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e023",
    name: "Katagum",
    slug: "KATA",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975615",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088489740",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e02a",
    name: "Warji",
    slug: "WARJ",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088606013",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e034",
    name: "Ado",
    slug: "ADO",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088727334",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e040",
    name: "Ogbadibo",
    slug: "OGBA",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975624",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088849757",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e049",
    name: "Askira/Uba",
    slug: "ASUB",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975627",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088967934",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e04f",
    name: "Dikwa",
    slug: "DIKW",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089083250",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e056",
    name: "Kala/Balge",
    slug: "KABA",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089091730",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e059",
    name: "Kwaya Kusar",
    slug: "KWKU",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089202363",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e081",
    name: "Oshimili North",
    slug: "OSNO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089682992",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e095",
    name: "Izzi",
    slug: "IZZI",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975634",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089923527",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b6",
    name: "Ise/Orun",
    slug: "ISOR",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090286453",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b9",
    name: "Awgu",
    slug: "AWGU",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090289932",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ba",
    name: "Enugu East",
    slug: "ENEA",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090291470",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c1",
    name: "Isi Uzo",
    slug: "ISUZ",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090406899",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c7",
    name: "Udi",
    slug: "UDI",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090523661",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0dc",
    name: "Ideato South",
    slug: "IDSO",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090764535",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0de",
    name: "Ikeduru",
    slug: "IKED",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090766837",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ef",
    name: "Owerri North",
    slug: "OWNO",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091003365",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0fc",
    name: "Gwiwa",
    slug: "GWIW",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091126813",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e104",
    name: "Maigatari",
    slug: "MAIG",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091245536",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e10d",
    name: "Giwa",
    slug: "GIWA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091362890",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e114",
    name: "Kaduna South",
    slug: "KASO",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091371494",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e115",
    name: "Kagarko",
    slug: "KAGA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091372764",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e139",
    name: "Kumbotso",
    slug: "KUMB",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091847732",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e143",
    name: "Shanono",
    slug: "SHAN",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091967804",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e16b",
    name: "Safana",
    slug: "SAFA",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092448057",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e172",
    name: "Birnin Kebbi",
    slug: "BIKE",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092564779",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e175",
    name: "Fakai",
    slug: "FAKA",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092568533",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e18f",
    name: "Ogori/Magongo",
    slug: "OGMA",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092923345",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e193",
    name: "Omala",
    slug: "OMAL",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092928379",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e19b",
    name: "Ilorin South",
    slug: "ILSO",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093046730",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a9",
    name: "Badagry",
    slug: "BADA",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093170558",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c9",
    name: "Edati",
    slug: "EDAT",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093533526",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d1",
    name: "Mariga",
    slug: "MARI",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093650174",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d9",
    name: "Suleja",
    slug: "SULE",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093769526",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1dc",
    name: "Abeokuta South",
    slug: "ABSO",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093773799",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e4",
    name: "Ijebu North East",
    slug: "IJNE",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093889522",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e9",
    name: "Obafemi Owode",
    slug: "OBOW",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094002956",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ee",
    name: "Shagamu",
    slug: "SHAG",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094009611",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f0",
    name: "Akoko South-West",
    slug: "AKSW",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094012189",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f5",
    name: "Idanre",
    slug: "IDAN",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094127586",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e214",
    name: "Isokan",
    slug: "ISOK",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094488242",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e215",
    name: "Iwo",
    slug: "IWO",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094489534",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e216",
    name: "Obokun",
    slug: "OBOK",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094490952",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e221",
    name: "Ibadan North",
    slug: "IBNO",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094609752",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e229",
    name: "Ido",
    slug: "IDO",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094728058",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e237",
    name: "Ori Ire",
    slug: "ORIR",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094853343",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e23b",
    name: "Saki West",
    slug: "SAWE",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094964873",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e23e",
    name: "Bassa",
    slug: "BASS",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094968412",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e248",
    name: "Pankshin",
    slug: "PANK",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095089494",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e24a",
    name: "Riyom",
    slug: "RIYO",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095092296",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e264",
    name: "Dange Shuni",
    slug: "DASH",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095448055",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e26b",
    name: "Kebbe",
    slug: "KEBB",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095563767",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e274",
    name: "Tangaza",
    slug: "TANG",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095681570",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e295",
    name: "Tarmuwa",
    slug: "TARM",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096045668",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e29f",
    name: "Maradun",
    slug: "MARA",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096165585",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e2a2",
    name: "Talata Mafara",
    slug: "TAMA",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975663",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096168687",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe0",
    name: "Numan",
    slug: "NUMA",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975594",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087761806",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd4",
    name: "Gayuk",
    slug: "GAYU",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975592",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087533057",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff5",
    name: "Itu",
    slug: "ITU",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088003271",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e003",
    name: "Urue-Offong/Oruko",
    slug: "UROO",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088128038",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e02e",
    name: "Nembe",
    slug: "NEMB",
    parent_slug: "BAY",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088610839",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e030",
    name: "Sagbama",
    slug: "SAGB",
    parent_slug: "BAY",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088721949",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e039",
    name: "Gwer West",
    slug: "GWWE",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088734175",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd6",
    name: "Grie",
    slug: "GRIE",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975593",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087641607",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e041",
    name: "Ohimini",
    slug: "OHIM",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975624",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088850970",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e006",
    name: "Anambra West",
    slug: "ANWE",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088131358",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e043",
    name: "Okpokwu",
    slug: "OKPO",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975625",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088853403",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e00b",
    name: "Dunukofia",
    slug: "DUNU",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975611",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088244816",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e04e",
    name: "Damboa",
    slug: "DAMB",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089082135",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e054",
    name: "Jere",
    slug: "JERE",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089088928",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e00e",
    name: "Idemili South",
    slug: "IDSO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975611",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088247939",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e057",
    name: "Konduga",
    slug: "KOND",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089093485",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e010",
    name: "Njikoka",
    slug: "NJIK",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975612",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088249962",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e058",
    name: "Kukawa",
    slug: "KUKA",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089201274",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e036",
    name: "Gboko",
    slug: "GBOK",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088730043",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e072",
    name: "Ogoja",
    slug: "OGOJ",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089450921",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e044",
    name: "Oturkpo",
    slug: "OTUR",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975627",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088961946",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a2",
    name: "Ikpoba Okha",
    slug: "IKOK",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090047124",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e05d",
    name: "Marte",
    slug: "MART",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089207494",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e066",
    name: "Bakassi",
    slug: "BAKA",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089326455",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0bd",
    name: "Ezeagu",
    slug: "EZEA",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090402474",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e06c",
    name: "Etung",
    slug: "ETUN",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089441980",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c4",
    name: "Nsukka",
    slug: "NSUK",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090410085",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e08e",
    name: "Afikpo South",
    slug: "AFSO",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089811299",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c6",
    name: "Udenu",
    slug: "UDEN",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090521935",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e090",
    name: "Ezza North",
    slug: "EZNO",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089813358",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0cc",
    name: "Kwali",
    slug: "KWAL",
    parent_slug: "FCT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090531296",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ae",
    name: "Emure",
    slug: "EMUR",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090169060",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d2",
    name: "Gombe",
    slug: "GOMB",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090644011",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b2",
    name: "Ikere",
    slug: "IKER",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090281296",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e5",
    name: "Nwangele",
    slug: "NWAN",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090883558",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0cb",
    name: "Kuje",
    slug: "KUJE",
    parent_slug: "FCT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090530186",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f7",
    name: "Gagarawa",
    slug: "GAGA",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091013118",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d5",
    name: "Nafada",
    slug: "NAFA",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090647611",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e105",
    name: "Malam Madori",
    slug: "MAMA",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091246862",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f4",
    name: "Birnin Kudu",
    slug: "BIKU",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091009439",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e107",
    name: "Ringim",
    slug: "RING",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091249661",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0fb",
    name: "Gwaram",
    slug: "GWAR",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091125557",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e113",
    name: "Kaduna North",
    slug: "KANO",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091370371",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0fe",
    name: "Jahun",
    slug: "JAHU",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091129307",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e116",
    name: "Kajuru",
    slug: "KAJU",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091481545",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e106",
    name: "Miga",
    slug: "MIGA",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091248100",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e133",
    name: "Gwarzo",
    slug: "GWAR",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091733409",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e11c",
    name: "Makarfi",
    slug: "MAKA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091490795",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e13f",
    name: "Nasarawa",
    slug: "NASA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091963015",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e12e",
    name: "Garko",
    slug: "GARK",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091726823",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e142",
    name: "Rogo",
    slug: "ROGO",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091966602",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e154",
    name: "Dan Musa",
    slug: "DAMU",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092204437",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e146",
    name: "Tarauni",
    slug: "TARA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091971430",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e16a",
    name: "Sabuwa",
    slug: "SABU",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092446773",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e149",
    name: "Tudun Wada",
    slug: "TUWA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092083117",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e16c",
    name: "Sandamu",
    slug: "SAND",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092449350",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e14c",
    name: "Wudil",
    slug: "WUDI",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092086945",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e16e",
    name: "Arewa Dandi",
    slug: "ARDA",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092452284",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e14e",
    name: "Batsari",
    slug: "BATS",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092089531",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e16f",
    name: "Argungu",
    slug: "ARGU",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092453571",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e159",
    name: "Funtua",
    slug: "FUNT",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092210491",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e184",
    name: "Bassa",
    slug: "BASS",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092802212",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e15d",
    name: "Kaita",
    slug: "KAIT",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092324354",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e188",
    name: "Igalamela Odolu",
    slug: "IGOD",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092809559",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e160",
    name: "Katsina",
    slug: "KATS",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092328442",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e177",
    name: "Jega",
    slug: "JEGA",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092571041",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e189",
    name: "Ijumu",
    slug: "IJUM",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092811131",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e17b",
    name: "Ngaski",
    slug: "NGAS",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092683543",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e18b",
    name: "Kogi",
    slug: "KOGI",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092816694",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e18a",
    name: "Kabba/Bunu",
    slug: "KABU",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092812843",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e18c",
    name: "Lokoja",
    slug: "LOKO",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092818315",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e190",
    name: "Okehi",
    slug: "OKEH",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092924637",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e18e",
    name: "Ofu",
    slug: "OFU",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092921804",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e198",
    name: "Ekiti",
    slug: "EKIT",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093042552",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e195",
    name: "Yagba West",
    slug: "YAWE",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092930792",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e199",
    name: "Ifelodun",
    slug: "IFEL",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093044393",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e19e",
    name: "Isin",
    slug: "ISIN",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093050515",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e19d",
    name: "Irepodun",
    slug: "IREP",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093049239",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a8",
    name: "Apapa",
    slug: "APAP",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093169582",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a2",
    name: "Oke Ero",
    slug: "OKER",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093162065",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b8",
    name: "Awe",
    slug: "AWE",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093407630",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1be",
    name: "Lafia",
    slug: "LAFI",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093414950",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c0",
    name: "Nasarawa Egon",
    slug: "NAEG",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093522237",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1bf",
    name: "Nasarawa",
    slug: "NASA",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093416364",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d7",
    name: "Rijau",
    slug: "RIJA",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093766866",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e222",
    name: "Ibadan North-East",
    slug: "IBNE",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094611022",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ca",
    name: "Gbako",
    slug: "GBAK",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093641282",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e227",
    name: "Ibarapa East",
    slug: "IBEA",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094725554",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e235",
    name: "Ona Ara",
    slug: "ONAR",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094851002",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d5",
    name: "Paikoro",
    slug: "PAIK",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093763448",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e23c",
    name: "Surulere",
    slug: "SURU",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094966003",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d8",
    name: "Shiroro",
    slug: "SHIR",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093768244",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e252",
    name: "Bonny",
    slug: "BONN",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095213112",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e8",
    name: "Ipokia",
    slug: "IPOK",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094001625",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e263",
    name: "Bodinga",
    slug: "BODI",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095446712",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ec",
    name: "Ogun Waterside",
    slug: "OGWA",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094006995",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e266",
    name: "Goronyo",
    slug: "GORO",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095451213",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e272",
    name: "Sokoto South",
    slug: "SOSO",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095573022",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e236",
    name: "Orelope",
    slug: "OREL",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094852176",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e280",
    name: "Kumi",
    slug: "KUMI",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095804504",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e24d",
    name: "Ahoada East",
    slug: "AHEA",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095204738",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e250",
    name: "Andoni",
    slug: "ANDO",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095209257",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e25a",
    name: "Obio/Akpor",
    slug: "OBAK",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095327926",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e285",
    name: "Wukari",
    slug: "WUKA",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095810312",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e26a",
    name: "Isa",
    slug: "ISA",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095562031",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e2a4",
    name: "Zurmi",
    slug: "ZURM",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975664",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096170866",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e271",
    name: "Sokoto North",
    slug: "SONO",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095571809",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e27c",
    name: "Gassol",
    slug: "GASS",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095692183",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e27f",
    name: "Karim Lamido",
    slug: "KALA",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095803314",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e289",
    name: "Damaturu",
    slug: "DAMA",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095924011",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e28b",
    name: "Fune",
    slug: "FUNE",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095926617",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e28c",
    name: "Geidam",
    slug: "GEID",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095927743",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e292",
    name: "Nangere",
    slug: "NANG",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096042044",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc9",
    name: "Obi Ngwa",
    slug: "OBNG",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975591",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087411959",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd3",
    name: "Ganye",
    slug: "GANY",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975592",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087532279",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe7",
    name: "Eastern Obolo",
    slug: "EAOB",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975606",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087770208",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfe9",
    name: "Esit Eket",
    slug: "ESEK",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975606",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087772572",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e005",
    name: "Anambra East",
    slug: "ANEA",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088130226",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e017",
    name: "Orumba South",
    slug: "ORSO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088367673",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e026",
    name: "Ningi",
    slug: "NING",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975615",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088601472",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e031",
    name: "Southern Ijaw",
    slug: "SOIJ",
    parent_slug: "BAY",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088723260",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e035",
    name: "Buruku",
    slug: "BURU",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088728524",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e03a",
    name: "Katsina-Ala",
    slug: "KAAL",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975617",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088842127",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e05f",
    name: "Monguno",
    slug: "MONG",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089210024",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e07e",
    name: "Ndokwa East",
    slug: "NDEA",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089570735",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e07f",
    name: "Ndokwa West",
    slug: "NDWE",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089571875",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e087",
    name: "Ughelli South",
    slug: "UGSO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089689479",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e08a",
    name: "Warri North",
    slug: "WANO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089802245",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a8",
    name: "Owan West",
    slug: "OWWE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090162194",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ab",
    name: "Ekiti East",
    slug: "EKEA",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090165948",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c0",
    name: "Igbo Eze South",
    slug: "IGES",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090405775",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e1",
    name: "Mbaitoli",
    slug: "MBAI",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090770477",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f3",
    name: "Biriniwa",
    slug: "BIRI",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091008226",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f5",
    name: "Buji",
    slug: "BUJI",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091010657",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f9",
    name: "Gumel",
    slug: "GUME",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091123284",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e112",
    name: "Kachia",
    slug: "KACH",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091369120",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e123",
    name: "Bagwai",
    slug: "BAGW",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091606296",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e128",
    name: "Dambatta",
    slug: "DAMB",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091612344",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e12d",
    name: "Gabasawa",
    slug: "GABA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091725344",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e135",
    name: "Kano Municipal",
    slug: "KAMU",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091842859",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e136",
    name: "Karaye",
    slug: "KARA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091844178",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e150",
    name: "Bindawa",
    slug: "BIND",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092091853",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e163",
    name: "Mai'Adua",
    slug: "MAI'",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092332578",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e167",
    name: "Matazu",
    slug: "MATA",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092442838",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e191",
    name: "Okene",
    slug: "OKEN",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092925906",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c4",
    name: "Agwara",
    slug: "AGWA",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093527387",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1d4",
    name: "Moya",
    slug: "MOYA",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093761581",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1db",
    name: "Wushishi",
    slug: "WUSH",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093772520",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e3",
    name: "Ijebu North",
    slug: "IJNO",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093888358",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1fa",
    name: "Odigbo",
    slug: "ODIG",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094134866",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e20d",
    name: "Ifedayo",
    slug: "IFED",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094371197",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e211",
    name: "Ilesa West",
    slug: "ILWE",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094484099",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e213",
    name: "Irewole",
    slug: "IREW",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094486941",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e228",
    name: "Ibarapa North",
    slug: "IBNO",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094726857",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e230",
    name: "Ogbomosho North",
    slug: "OGNO",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094844706",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e234",
    name: "Oluyole",
    slug: "OLUY",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094849753",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e24f",
    name: "Akuku-Toru",
    slug: "AKTO",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095207933",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e259",
    name: "Khana",
    slug: "KHAN",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095326442",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e25e",
    name: "Omuma",
    slug: "OMUM",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095333589",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e261",
    name: "Port Harcourt",
    slug: "POHA",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095444084",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e268",
    name: "Gwadabawa",
    slug: "GWAD",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095453720",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e26f",
    name: "Shagari",
    slug: "SHAG",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095569552",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e283",
    name: "Takum",
    slug: "TAKU",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095808089",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e288",
    name: "Bursari",
    slug: "BURS",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095922348",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e28a",
    name: "Fika",
    slug: "FIKA",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095925270",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e293",
    name: "Nguru",
    slug: "NGUR",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096043231",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e01d",
    name: "Dass",
    slug: "DASS",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975614",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088482958",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e03d",
    name: "Logo",
    slug: "LOGO",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975624",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088845915",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e047",
    name: "Ushongo",
    slug: "USHO",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975627",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088965440",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e05b",
    name: "Magumeri",
    slug: "MAGU",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089204996",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e05c",
    name: "Maiduguri",
    slug: "MAID",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089206288",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e069",
    name: "Boki",
    slug: "BOKI",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089330074",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e077",
    name: "Burutu",
    slug: "BURU",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089562766",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e078",
    name: "Ethiope East",
    slug: "ETEA",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089563906",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e082",
    name: "Oshimili South",
    slug: "OSSO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089684047",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e089",
    name: "Uvwie",
    slug: "UVWI",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089691706",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0bb",
    name: "Enugu North",
    slug: "ENNO",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090292466",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0cf",
    name: "Billiri",
    slug: "BILL",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090536234",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f8",
    name: "Garki",
    slug: "GARK",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091122147",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0fd",
    name: "Hadejia",
    slug: "HADE",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091128061",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e103",
    name: "Kaugama",
    slug: "KAUG",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091244191",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e118",
    name: "Kauru",
    slug: "KAUR",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091485234",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e124",
    name: "Bebeji",
    slug: "BEBE",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091607673",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e126",
    name: "Bunkure",
    slug: "BUNK",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091609818",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e127",
    name: "Dala",
    slug: "DALA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091611105",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e141",
    name: "Rimin Gado",
    slug: "RIGA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091965356",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e14a",
    name: "Ungogo",
    slug: "UNGO",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092084464",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e156",
    name: "Dutsi",
    slug: "DUTS",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092207023",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e15c",
    name: "Kafur",
    slug: "KAFU",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092323169",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e15e",
    name: "Kankara",
    slug: "KANK",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092325586",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e171",
    name: "Bagudo",
    slug: "BAGU",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092562740",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e194",
    name: "Yagba East",
    slug: "YAEA",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092929544",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b2",
    name: "Lagos Mainland",
    slug: "LAMA",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093289070",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ce",
    name: "Lapai",
    slug: "LAPA",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093646283",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e208",
    name: "Ife East",
    slug: "IFEA",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094364697",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e20a",
    name: "Ife South",
    slug: "IFSO",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094367460",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e20c",
    name: "Ejigbo",
    slug: "EJIG",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094369930",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e224",
    name: "Ibadan South-East",
    slug: "IBSE",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094721553",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e244",
    name: "Langtang South",
    slug: "LASO",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095084470",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e251",
    name: "Asari-Toru",
    slug: "ASTO",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095211187",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e260",
    name: "Oyigbo",
    slug: "OYIG",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095441437",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e277",
    name: "Wurno",
    slug: "WURN",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095685559",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e287",
    name: "Zing",
    slug: "ZING",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095813275",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e296",
    name: "Yunusari",
    slug: "YUNU",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096046900",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc6",
    name: "Isiala Ngwa North",
    slug: "ISNN",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975590",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087408734",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfeb",
    name: "Etim Ekpo",
    slug: "ETEK",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975608",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087883007",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc8",
    name: "Isuikwuato",
    slug: "ISUI",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975591",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087411005",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfcc",
    name: "Ugwunagbo",
    slug: "UGWU",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975591",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087522054",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dffa",
    name: "Nsit-Ubium",
    slug: "NSUB",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088009418",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dffe",
    name: "Oron",
    slug: "ORON",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088122086",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e008",
    name: "Awka North",
    slug: "AWNO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975611",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088241319",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e012",
    name: "Nnewi South",
    slug: "NNSO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088361180",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e029",
    name: "Toro",
    slug: "TORO",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088604833",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e02d",
    name: "Kolokuma/Opokuma",
    slug: "KOOP",
    parent_slug: "BAY",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088609204",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e04d",
    name: "Chibok",
    slug: "CHIB",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088974307",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e053",
    name: "Hawul",
    slug: "HAWU",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089087856",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e07b",
    name: "Ika South",
    slug: "IKSO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089567325",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e08c",
    name: "Warri South West",
    slug: "WASW",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089808404",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e094",
    name: "Ivo",
    slug: "IVO",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975634",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089922155",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a4",
    name: "Oredo",
    slug: "ORED",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090049686",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c5",
    name: "Oji River",
    slug: "OJRI",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975638",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090411322",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e9",
    name: "Okigwe",
    slug: "OKIG",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090888889",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f2",
    name: "Babura",
    slug: "BABU",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091006984",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e101",
    name: "Kiri Kasama",
    slug: "KIKA",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091133169",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e10b",
    name: "Yankwashi",
    slug: "YANK",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091256425",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e119",
    name: "Kubau",
    slug: "KUBA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091487223",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e12a",
    name: "Dawakin Tofa",
    slug: "DATO",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091721626",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e155",
    name: "Daura",
    slug: "DAUR",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092205745",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e178",
    name: "Kalgo",
    slug: "KALG",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092572308",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e179",
    name: "Koko/Besse",
    slug: "KOBE",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092573525",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e17a",
    name: "Maiyama",
    slug: "MAIY",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092682537",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a3",
    name: "Oyun",
    slug: "OYUN",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093163417",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1bc",
    name: "Keffi",
    slug: "KEFF",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093412412",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c1",
    name: "Obi",
    slug: "OBI",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093523521",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1cb",
    name: "Gurara",
    slug: "GURA",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093642426",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1eb",
    name: "Odogbolu",
    slug: "ODOG",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094005714",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1fc",
    name: "Ondo East",
    slug: "ONEA",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094242516",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e20f",
    name: "Ila",
    slug: "ILA",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094373723",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e219",
    name: "Olorunda",
    slug: "OLOR",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094495745",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e21a",
    name: "Oriade",
    slug: "ORIA",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094601374",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e238",
    name: "Oyo",
    slug: "OYO",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094961674",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e258",
    name: "Ikwerre",
    slug: "IKWE",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095325144",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e290",
    name: "Karasuwa",
    slug: "KARA",
    parent_slug: "YOB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095933731",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e29e",
    name: "Kaura Namoda",
    slug: "KANA",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096164320",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfcb",
    name: "Osisioma",
    slug: "OSIS",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975591",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087414569",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff0",
    name: "Ika",
    slug: "IKA",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975608",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087888602",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dffc",
    name: "Okobo",
    slug: "OKOB",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088011790",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e02c",
    name: "Ekeremor",
    slug: "EKER",
    parent_slug: "BAY",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088607983",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e02f",
    name: "Ogbia",
    slug: "OGBI",
    parent_slug: "BAY",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975616",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088612056",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e048",
    name: "Vandeikya",
    slug: "VAND",
    parent_slug: "BEN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975627",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088966737",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e071",
    name: "Odukpani",
    slug: "ODUK",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089449278",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e073",
    name: "Yakuur",
    slug: "YAKU",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975631",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089452147",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e07c",
    name: "Isoko North",
    slug: "ISNO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089568468",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e07d",
    name: "Isoko South",
    slug: "ISSO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089569601",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e091",
    name: "Ezza South",
    slug: "EZSO",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975634",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089814813",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e098",
    name: "Onicha",
    slug: "ONIC",
    parent_slug: "EBO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975634",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089928088",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e099",
    name: "Egor",
    slug: "EGOR",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089929816",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a3",
    name: "Orhionmwon",
    slug: "ORHI",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090048724",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a5",
    name: "Ovia North-East",
    slug: "OVNE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090050859",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b0",
    name: "Ido Osi",
    slug: "IDOS",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090172867",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0c8",
    name: "Uzo Uwani",
    slug: "UZUW",
    parent_slug: "ENU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090525290",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0dd",
    name: "Ihitte/Uboma",
    slug: "IHUB",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090765525",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e7",
    name: "Oguta",
    slug: "OGUT",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090886264",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e8",
    name: "Ohaji/Egbema",
    slug: "OHEG",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090887300",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ea",
    name: "Orlu",
    slug: "ORLU",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090890137",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e100",
    name: "Kazaure",
    slug: "KAZA",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975642",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091131892",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e10a",
    name: "Taura",
    slug: "TAUR",
    parent_slug: "JIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091254757",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e10c",
    name: "Chikun",
    slug: "CHIK",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091361445",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e122",
    name: "Albasu",
    slug: "ALBA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091605083",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e13b",
    name: "Kura",
    slug: "KURA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091850145",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e158",
    name: "Faskari",
    slug: "FASK",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092209183",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e164",
    name: "Malumfashi",
    slug: "MALU",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092333937",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e166",
    name: "Mashi",
    slug: "MASH",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092441383",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e169",
    name: "Rimi",
    slug: "RIMI",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092445457",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e173",
    name: "Bunza",
    slug: "BUNZ",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092566033",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e187",
    name: "Idah",
    slug: "IDAH",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092808227",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e18d",
    name: "Mopa Muro",
    slug: "MOMU",
    parent_slug: "KOG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975649",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092819715",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e19f",
    name: "Kaiama",
    slug: "KAIA",
    parent_slug: "KWA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093051841",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1cf",
    name: "Lavun",
    slug: "LAVU",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975652",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093648050",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1fe",
    name: "Ose",
    slug: "OSE",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094245145",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e205",
    name: "Ede North",
    slug: "EDNO",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094254690",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e209",
    name: "Ife North",
    slug: "IFNO",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094366248",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e21d",
    name: "Akinyele",
    slug: "AKIN",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094604946",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e22c",
    name: "Itesiwaju",
    slug: "ITES",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094732117",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e22d",
    name: "Iwajowa",
    slug: "IWAJ",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094733307",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e245",
    name: "Langtang North",
    slug: "LANO",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095085633",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e253",
    name: "Degema",
    slug: "DEGE",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095214250",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e27b",
    name: "Gashaka",
    slug: "GASH",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095691054",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e282",
    name: "Sardauna",
    slug: "SARD",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095806672",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfcf",
    name: "Umuahia North",
    slug: "UMNO",
    parent_slug: "ABI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975592",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087527028",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfd2",
    name: "Fufure",
    slug: "FUFU",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975592",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087531205",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfdf",
    name: "Mubi South",
    slug: "MUSO",
    parent_slug: "ADA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975594",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087651790",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfed",
    name: "Ibeno",
    slug: "IBEN",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975608",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087885298",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dffb",
    name: "Obot Akara",
    slug: "OBAK",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975609",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088010621",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e013",
    name: "Ogbaru",
    slug: "OGBA",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088362213",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e015",
    name: "Onitsha South",
    slug: "ONSO",
    parent_slug: "ANA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975613",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088364912",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e051",
    name: "Guzamala",
    slug: "GUZA",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089085459",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e052",
    name: "Gwoza",
    slug: "GWOZ",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975628",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089086622",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e064",
    name: "Akamkpa",
    slug: "AKAM",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089323959",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e067",
    name: "Bekwarra",
    slug: "BEKW",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089327673",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e06d",
    name: "Ikom",
    slug: "IKOM",
    parent_slug: "CRO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975630",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089443570",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e08b",
    name: "Warri South",
    slug: "WASO",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975633",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089807114",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e09d",
    name: "Esan West",
    slug: "ESWE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089936016",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e09f",
    name: "Etsako East",
    slug: "ETEA",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090043453",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a0",
    name: "Etsako West",
    slug: "ETWE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090044581",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ca",
    name: "Gwagwalada",
    slug: "GWAG",
    parent_slug: "FCT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090528672",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d0",
    name: "Dukku",
    slug: "DUKK",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975639",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090641566",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0eb",
    name: "Orsu",
    slug: "ORSU",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090891570",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e11a",
    name: "Kudan",
    slug: "KUDA",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091488515",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e130",
    name: "Gaya",
    slug: "GAYA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091729582",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e138",
    name: "Kiru",
    slug: "KIRU",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091846551",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e13e",
    name: "Minjibir",
    slug: "MINJ",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091961862",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e148",
    name: "Tsanyawa",
    slug: "TSAN",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092082052",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e17e",
    name: "Suru",
    slug: "SURU",
    parent_slug: "KEB",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975648",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092689082",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1aa",
    name: "Epe",
    slug: "EPE",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093171913",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b1",
    name: "Lagos Island",
    slug: "LAIS",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093287996",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b7",
    name: "Surulere",
    slug: "SURU",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093406517",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1df",
    name: "Egbado South",
    slug: "EGSO",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093883244",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f4",
    name: "Ese Odo",
    slug: "ESOD",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094125931",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f7",
    name: "Ilaje",
    slug: "ILAJ",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094130094",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1f9",
    name: "Irele",
    slug: "IREL",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094133638",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e201",
    name: "Aiyedaade",
    slug: "AIYE",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094248650",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e212",
    name: "Irepodun",
    slug: "IREP",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094485346",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e218",
    name: "Ola Oluwa",
    slug: "OLOL",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094494428",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e21f",
    name: "Atisbo",
    slug: "ATIS",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975655",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094607470",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e254",
    name: "Eleme",
    slug: "ELEM",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095215507",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e25d",
    name: "Okrika",
    slug: "OKRI",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095332044",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e281",
    name: "Lau",
    slug: "LAU",
    parent_slug: "TAR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975660",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095805410",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e2a3",
    name: "Chafe",
    slug: "CHAF",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975664",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096169847",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dff1",
    name: "Ikono",
    slug: "IKON",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975608",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631087889623",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e004",
    name: "Uyo",
    slug: "UYO",
    parent_slug: "AKW",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975610",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088129126",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e01e",
    name: "Gamawa",
    slug: "GAMA",
    parent_slug: "BAU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975614",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631088484053",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e05e",
    name: "Mobbar",
    slug: "MOBB",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089208628",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e060",
    name: "Ngala",
    slug: "NGAL",
    parent_slug: "BOR",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975629",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089211411",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e079",
    name: "Ethiope West",
    slug: "ETWE",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089565039",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e084",
    name: "Sapele",
    slug: "SAPE",
    parent_slug: "DEL",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975632",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089686156",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e09b",
    name: "Esan North-East",
    slug: "ESNE",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975635",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631089932589",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0a7",
    name: "Owan East",
    slug: "OWEA",
    parent_slug: "EDO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975636",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090053361",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b5",
    name: "Irepodun/Ifelodun",
    slug: "IRIF",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090285312",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0b7",
    name: "Moba",
    slug: "MOBA",
    parent_slug: "EKI",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975637",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090287560",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d3",
    name: "Kaltungo",
    slug: "KALT",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090645240",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d4",
    name: "Kwami",
    slug: "KWAM",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090646370",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d6",
    name: "Shongom",
    slug: "SHON",
    parent_slug: "GOM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090648885",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0d9",
    name: "Ehime Mbano",
    slug: "EHMB",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090652611",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0e0",
    name: "Isu",
    slug: "ISU",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975640",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090769490",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0ed",
    name: "Oru West",
    slug: "ORWE",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631090894236",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f0",
    name: "Owerri West",
    slug: "OWWE",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091004516",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e0f1",
    name: "Unuimo",
    slug: "UNUI",
    parent_slug: "IMO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975641",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091005744",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e10f",
    name: "Ikara",
    slug: "IKAR",
    parent_slug: "KAD",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975643",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091365450",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e12f",
    name: "Garun Mallam",
    slug: "GAMA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975644",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091728183",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e131",
    name: "Gezawa",
    slug: "GEZA",
    parent_slug: "KAN",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975645",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631091731054",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e15a",
    name: "Ingawa",
    slug: "INGA",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975646",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092212037",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e15f",
    name: "Kankia",
    slug: "KANK",
    parent_slug: "KAT",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975647",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631092326834",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1a5",
    name: "Ajeromi-Ifelodun",
    slug: "AJIF",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093165834",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ad",
    name: "Ifako-Ijaiye",
    slug: "IFIJ",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093283086",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ae",
    name: "Ikeja",
    slug: "IKEJ",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975650",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093284394",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1b4",
    name: "Ojo",
    slug: "OJO",
    parent_slug: "LAG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093291544",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1bb",
    name: "Keana",
    slug: "KEAN",
    parent_slug: "NAS",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093411203",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1c7",
    name: "Bosso",
    slug: "BOSS",
    parent_slug: "NIG",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975651",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093531085",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e1",
    name: "Ifo",
    slug: "IFO",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093885771",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1e2",
    name: "Ijebu East",
    slug: "IJEA",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631093887064",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ed",
    name: "Remo North",
    slug: "RENO",
    parent_slug: "OGU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094008341",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e1ef",
    name: "Akoko North-West",
    slug: "AKNW",
    parent_slug: "OND",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975653",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094010904",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e206",
    name: "Ede South",
    slug: "EDSO",
    parent_slug: "OSU",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975654",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094362078",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e226",
    name: "Ibarapa Central",
    slug: "IBCE",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094724260",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e22f",
    name: "Lagelu",
    slug: "LAGE",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975656",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094843440",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e239",
    name: "Oyo East",
    slug: "OYEA",
    parent_slug: "OYO",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631094962806",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e246",
    name: "Mangu",
    slug: "MANG",
    parent_slug: "PLA",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975657",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095087167",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e255",
    name: "Emuoha",
    slug: "EMUO",
    parent_slug: "RIV",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975658",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095216776",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e273",
    name: "Tambuwal",
    slug: "TAMB",
    parent_slug: "SOK",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975659",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631095574171",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e298",
    name: "Bakura",
    slug: "BAKU",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096049425",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77e29d",
    name: "Gusau",
    slug: "GUSA",
    parent_slug: "ZAM",
    version: 1,
    createdAt: {
      $date: {
        $numberLong: "1623414975661",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1631096163047",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "61a8d8b8fd1d5500342eda58",
    added_to_sheet: true,
    name: "Sokoto",
    slug: "SOK",
    parent_slug: "SKO",
    createdAt: {
      $date: {
        $numberLong: "1638455480335",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1638455483604",
      },
    },
    version: 1,
  },
];
