export const supplyHeaders = [
  { text: "Phone Number", value: "phoneNumber" },
  { text: "Name", value: "name" },
  { text: "Quantity", value: "quantity" },
  { text: "Farmer's Updated", value: "farmersUpd" },
  { text: "Date", value: "date" },
  { text: "Status", value: "status" },
  { text: "Actions", value: "actions" },
];

export const dummyData = [
  {
    supplyID: "ew4w1",
    phoneNumber: "+2348101234567",
    name: "David Lamidi",
    quantity: `${45} Tons`,
    farmersUpd: "yes",
    date: "12-Jul-2023",
    status: "Sell Now",
  },
  {
    supplyID: "ew4w2",
    phoneNumber: "+2348101234567",
    name: "Apapa Okoro",
    quantity: `${125} Tons`,
    farmersUpd: "yes",
    date: "12-Jul-2023",
    status: "Cracking",
  },
  {
    supplyID: "ew4w3",
    phoneNumber: "+2348101234567",
    name: "Desmond Rufai",
    quantity: `${225} Tons`,
    farmersUpd: "yes",
    date: "12-Jul-2023",
    status: "Not Available",
  },
]

export const ussdHeaders = [
  { text: "Phone Number", value: "phoneNumber" },
  { text: "Name", value: "name" },
  { text: "Date", value: "date" },
  { text: "Status", value: "status" },
  {text: "Actions", value: "actions"}
];

export const dummyDataUSSD = [
  {
    supplyID: "ew4w1",
    phoneNumber: "+2348101234567",
    name: "David Lamidi",
    date: "12-Jul-2023",
    status: "Completed",
  },
  {
    supplyID: "ew4w2",
    phoneNumber: "+2348101234567",
    name: "Seyi Sanusi",
    date: "12-Jul-2023",
    status: "Pending",
  },
  {
    supplyID: "ew4w3",
    phoneNumber: "+2348101234567",
    name: "Frank Edem",
    date: "12-Jul-2023",
    status: "Rejected",
  },
]
