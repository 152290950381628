import { createStore } from "vuex";
import auth from "./modules/auth";
import mills from "./modules/mills";
import community from "./modules/community";
import farmers from "./modules/farmers";
import purchases from "./modules/purchases";
import agents from "./modules/agents";
import checkIn from "./modules/checkIn";
import layout from "./modules/layout";
import logistics from "./modules/logistics";
import aggregation from "./modules/aggregation";
import dispatch from "./modules/dispatch";
import inventories from "./modules/inventories";
import discoveries from "./modules/discoveries";
import aggregationPoint from "./modules/aggregationPoint";
import factories from "./modules/factories";
import vehicleManagement from "./modules/vehicleManagement";
import driver from "./modules/driver";
import profitInput from "./modules/price-atom-settings";
import userManagement from "./modules/userManagement";
import rolepermissions from "./modules/rolepermissions";
import revenue from "./modules/revenue";
import roles from "./modules/roles";
import procurementDetails from "./modules/procurementDetails";
import overviews from "./modules/overviews";
import dieselCosts from "./modules/dieselCosts";
import fieldAgentImprestCosts from "./modules/fieldAgentImprestCosts";
import loadersSalaryCosts from "./modules/loadersSalaryCosts";
import supervisorImprestCosts from "./modules/supervisorImprestCosts";
import tollsCosts from "./modules/tollsCosts";
import overhead from "./modules/overhead";
import otherOHCosts from "./modules/otherOHCosts";
import managementCosts from "./modules/managementCosts";
import staffSalaryCosts from "./modules/staffSalaryCosts";
import lMVRepairsAndMaintenanceCosts from "./modules/lMVRepairsAndMaintenanceCosts";
import assetManagement from "./modules/assetManagement";

export default createStore({
  mutations: {},

  actions: {},

  getters: {},
  modules: {
    auth,
    mills,
    community,
    farmers,
    agents,
    checkIn,
    purchases,
    layout,
    logistics,
    aggregation,
    dispatch,
    discoveries,
    aggregationPoint,
    factories,
    vehicleManagement,
    driver,
    profitInput,
    rolepermissions,
    revenue,
    userManagement,
    roles,
    procurementDetails,
    overviews,
    inventories,
    dieselCosts,
    fieldAgentImprestCosts,
    loadersSalaryCosts,
    supervisorImprestCosts,
    tollsCosts,
    overhead,
    otherOHCosts,
    managementCosts,
    staffSalaryCosts,
    lMVRepairsAndMaintenanceCosts,
    assetManagement,
  },
});
