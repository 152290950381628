<template>
  <Section>
    <div class="wrapper">
      <div class="home">
        <h2>
          Hello, <span>{{ getCurrentUser }}!</span>
        </h2>
        <!-- <p>Welcome back, <span>{{$store.state.currentUser}}!</span></p> -->
        <div class="image-container">
          <img class="image" src="../../assets/account.png" />
        </div>
      </div>
      <div class="header-text">
        <h4>Overview</h4>

        <select
          class="dropdown"
          name="overview"
          id="overview"
          v-model="selectedOverview"
        >
          <option value="Team">Team</option>
          <option value="Personal">Personal</option>
        </select>
      </div>

      <div class="card" v-if="overviewsList">
        <div class="feature green">
          <h3>{{ overviewsList.purchases }}</h3>
          <h5>Purchased</h5>
        </div>
        <div class="feature blue">
          <h3>{{ overviewsList.aggregates }}</h3>
          <h5>Aggregated</h5>
        </div>
        <div class="feature purple">
          <h3>{{ overviewsList.dispatch }}</h3>
          <h5>Dispatch</h5>
        </div>
        <div class="feature yellow">
          <h3>{{ overviewsList.mills }}</h3>
          <p>Mills</p>
        </div>

        <div class="feature ash">
          <h3>{{ overviewsList.farmers }}</h3>
          <p>Farmers</p>
        </div>

        <div class="feature green">
          <h3>{{ overviewsList.averageP1Price }}</h3>
          <p>Avg Pt Price</p>
        </div>
      </div>
    </div>

    <div class="action-wrapper">
      <div class="header-text-primary">
        <h4>Actions</h4>
      </div>

      <div class="container">
        <router-link to="/declines">
          <div class="box">
            <img src="../../assets/aggregate.png" alt="" />
            <p class="text">Discovery</p>
          </div>
        </router-link>

        <router-link to="/farmers">
          <div class="box">
            <img src="../../assets/logistics.png" alt="" />
            <p class="text">Farmers</p>
          </div>
        </router-link>

        <div class="box">
          <img src="../../assets/logistics.png" alt="" />
          <p class="text">Haulage</p>
        </div>

        <router-link to="/mills">
          <div class="box">
            <img src="../../assets/logistics.png" alt="" />
            <p class="text">Mills</p>
          </div>
        </router-link>

        <router-link to="/community">
          <div class="box">
            <img src="../../assets/logistics.png" alt="" />
            <p class="text">Community</p>
          </div>
        </router-link>

        <div class="box">
          <img src="../../assets/logistics.png" alt="" />
          <p class="text">My Target</p>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="footer-link">
        <img src="../../assets/home.png" alt="" />
        <p>Home</p>
      </div>
      <div class="footer-link">
        <img src="../../assets/notification.png" />
        <p>Notification</p>
      </div>
      <div class="footer-link">
        <img src="../../assets/map.png" />
        <p>Map</p>
      </div>

      <router-link to="/accounts">
        <div class="footer-link">
          <img src="../../assets/account.png" />
          <p>Account</p>
        </div>
      </router-link>
    </footer>
  </Section>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      currentUser: "",
      overviewsList: {},
      selectedOverview: "Team",
    };
  },

  methods: {
    fetchCurrentUser() {
      const currentUser = this.$store.getters["auth/getUser"];

      if (currentUser) {
        this.currentUser = currentUser.first_name;
      }

      return this.currentUser;
    },

    async fetchOverviewsPersonal() {
      try {
        const overviews = await this.$store.dispatch(
          "overviews/getOverViewsListPersonal"
        );

        const reformedOverviews = {
          purchases: this.addDotKToNumbers(overviews.purchases),
          aggregates: this.addDotKToNumbers(overviews.aggregates),
          dispatch: this.addDotKToNumbers(overviews.dispatch),
          mills: this.addDotKToNumbers(overviews.mills),
          farmers: this.addDotKToNumbers(overviews.farmers),
          averageP1Price: this.addDotKToNumbers(overviews.averageP1Price),
        };


        return reformedOverviews;
      } catch (error) {
        return error;
      }
    },

    async fetchOverviewsTeams() {
      try {
        const overviews = await this.$store.dispatch(
          "overviews/getOverViewsListTeams"
        );

        const reformedOverviews = {
          purchases: this.addDotKToNumbers(overviews.purchases),
          aggregates: this.addDotKToNumbers(overviews.aggregates),
          dispatch: this.addDotKToNumbers(overviews.dispatch),
          mills: this.addDotKToNumbers(overviews.mills),
          farmers: this.addDotKToNumbers(overviews.farmers),
          averageP1Price: this.addDotKToNumbers(overviews.averageP1Price),
        };

        return reformedOverviews;
      } catch (error) {
        return error;
      }
    },

    addDotKToNumbers(nums) {
      const strNumbers = nums.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (strNumbers.length > 3) {
        const arrNumbers = strNumbers.split(".");
        console.log(arrNumbers, "arrNumbers");

        const num = arrNumbers[0];

        const dec = arrNumbers[1];

        const roundSeconds = Math.round(dec);

        const result = Number(`${num}.${roundSeconds}`);

        return `${result.toFixed(1)}K`;
      } else {
        return Number(strNumbers);
      }
    },
  },

  computed: {
    getCurrentUser() {
      const currentUser = this.$store.getters["auth/getUser"];

      return currentUser?.first_name;
    },
  },

  mounted() {
    const currentUser = this.$store.getters["auth/getUser"];

    if (currentUser) {
      this.currentUser = currentUser.first_name;
    }

    // console.log(this.overviewsList, "this.overviewsList");

    this.fetchOverviewsTeams().then((overviews) => {
      this.overviewsList = overviews;
    });
  },

  watch: {
    async selectedOverview(newValue) {
      console.log(newValue, "newValue");
      try {
        if (newValue === "Personal") {
          this.overviewsList = await this.fetchOverviewsPersonal();
          console.log(this.overviewsList, "watcher personal");
        } else if (newValue === "Team") {
          this.overviewsList = await this.fetchOverviewsTeams();
          // console.log(this.overviewsList, "watcher teams");
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000;
}
.wrapper {
  top: 0%;
}
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-left: 0;
}
.home {
  display: flex;
  gap: 70px;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
}
.image-container {
  width: 35px;
  height: 35px;
  background-color: #c4c4c4;
  border-radius: 50%;
}
.image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
span {
  font-weight: 600;
  color: rgba(135, 184, 62, 0.729);
}
.action-wrapper {
  width: 300px;

  height: 300px;
  overflow: scroll;
}
.card {
  display: grid;
  grid-template-columns: repeat(6, 50px);
  gap: 3.5rem;
  width: 300px;
  border-radius: 8px;
  overflow-x: scroll;
  
}
.feature {
  background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 150px;
}
.feature h3 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: -10px;
}
.feature h5 {
  font-size: 0.8rem;
  font-weight: 500;
}
.green {
  width: 100px;
  height: 150px;
  background-color: #bad69e;
  box-shadow: 0px 4px 16px rgba(143, 197, 77, 0.1);
  border-radius: 8px;
}
.blue {
  width: 100px;
  height: 150px;
  background-color: #93c3c0;
  box-shadow: 0px 4px 16px rgba(143, 197, 77, 0.1);
  border-radius: 8px;
}
.purple {
  width: 100px;
  height: 150px;
  background-color: #a893c3;
  box-shadow: 0px 4px 16px rgba(143, 197, 77, 0.1);
  border-radius: 8px;
}
.yellow {
  width: 100px;
  height: 150px;
  background: #e9c683;
  box-shadow: 0px 4px 16px rgba(143, 197, 77, 0.1);
  border-radius: 8px;
}
.ash {
  width: 100px;
  height: 150px;
  background-color: #c3c2c2;
  box-shadow: 0px 4px 16px rgba(143, 197, 77, 0.1);
  border-radius: 8px;
}
.header-text {
  display: flex;
  gap: 120px;
  align-items: center;
  font-weight: 450;
  font-size: 18px;
}
.header-text-primary {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin-right: 14rem;
}
.dropdown {
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 20px;
  outline: none;
}
.box {
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 150px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(171, 171, 171, 0.2);
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}
.text {
  font-size: 14px;
  text-align: center;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  position: fixed;
  bottom: 0%;

  gap: 30px;
  background-color: white;
  width: 100%;
  height: 10%;
}
.footer-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin-top: 1rem;
}
</style>
