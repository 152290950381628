import moment from "moment";
import { IdConfig2 } from "../../config/idConfig";
import firebase from "../../config/firebase";

const formatUsersData = (data) => {
  return data.map((el) => {
    return {
      ...el,
      name: `${el.first_name} ${el.last_name}`,
      userType: el.userType ? el.userType?.name : "Nil",
      createdAt: moment(data.createdAt).format("DD-MM-YYYY"),
    };
  });
};

const state = () => ({
  users: [],
  agents: [],
  userTypes: [],
  user: null,
  userToBeUpdated: {},
  isRefresh: false,
  errors: null,
});

const getters = {
  getUsers: (state) => state.users,
  getOneUser: (state) => state.user,
  getUserToBeUpdated: (state) => state.userToBeUpdated,
  getRefreshStatus: (state) => state.isRefresh,
  getUserTypes: (state) => state.userTypes,
  getErrors: (state) => state.errors,
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },

  setOneUser(state, user) {
    state.user = user;
  },

  setUserToBeUpdated(state, user) {
    state.userToBeUpdated = user;
  },

  setIsRefresh(state, status) {
    state.isRefresh = status;
  },

  setErrors(state, errors) {
    state.errors = errors;
  },

  setUserTypes(state, payload) {
    state.userTypes = payload;
  },
};

const actions = {
  setRefresh({ commit }, status) {
    commit("setIsRefresh", status);
  },

  findAssetToBeUpdated({ commit, state }, userId) {
    let userToUpdated = state.users.find((user) => user._id === userId);
    userToUpdated = {
      ...userToUpdated,
      isActive: userToUpdated["isActive"] ? "Active" : "Inactive",
    };
    commit("setUserToBeUpdated", userToUpdated);
  },

  async fetchUsers({ commit }, params) {
    const { page, searchTerm } = params;
    try {
      const { data } = await IdConfig2.get(
        `user/all?page=${page}&searchTerm=${searchTerm}`
      );

      const users = data?.data ? formatUsersData(data?.data) : [];

      commit("setUsers", users);

      return {
        count: data?.count,
        assets: users,
      };
    } catch (error) {
      console.log(error.message);
      commit("setErrors", error.message);
    }
  },

  async fetchAgents({ commit }, params) {
    const { page } = params;
    try {
      const { data } = await IdConfig2.get(`user/agents?page=${page}`);

      const agents = formatUsersData(data?.data);

      commit("setUsers", agents);

      return {
        count: data?.count,
        agents,
      };
    } catch (error) {
      console.log(error.message);
      commit("setErrors", error.message);
    }
  },

  async addNewUser({ commit }, payload) {
    try {
      const { data } = await IdConfig2.post("user", payload);

      const userCred = await firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, "p@ssword");

      // console.log(userCred);

      if (data && userCred) return data;
    } catch (error) {
      console.log(error.message);
      commit("setErrors", error.message);
    }
  },

  async updateUser({ commit }, payload) {
    try {
      const { data } = await IdConfig2.patch(`user/${payload.id}`, payload);

      return data;
    } catch (error) {
      console.log(error.message);
      commit("setErrors", error.message);
    }
  },

  async deleteUser({ commit }, id) {
    try {
      const { data } = await IdConfig2.delete(`user/${id}`);

      // await firebase.auth().currentUser.delete();

      return data;
    } catch (error) {
      console.log(error.message);
      commit("setErrors", error.message);
    }
  },

  async fetchUserType({ commit }) {
    try {
      const response = await IdConfig2.get(`/user-types`);

      commit("setUserTypes", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
