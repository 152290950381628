<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add Staff Record</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <DateField
          placeholder="Date Of Payment"
          @onInputChange="handleInputChange"
          fieldName="date"
          :inputValue="staffSalaryForm?.date"
        />

        <MonthPicker
          placeholder="Month"
          @onInputChange="handleInputChange"
          fieldName="month"
          :inputValue="staffSalaryForm?.month"
        />

        <NumberInput
          placeholder="Number Of Staff"
          @onInputChange="handleInputChange"
          fieldName="noOfStaff"
          :inputValue="staffSalaryForm?.noOfStaff"
        />

        <NumberInput
          placeholder="Total Staff Salaries"
          @onInputChange="handleInputChange"
          fieldName="totalStaffSalaries"
          :inputValue="staffSalaryForm?.totalStaffSalaries"
        />

        <NumberInput
          placeholder="Percentage Split"
          @onInputChange="handleInputChange"
          fieldName="split"
          :inputValue="staffSalaryForm?.split"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton width="12rem" label="Submit" @click="addNewCosts" />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import DateField from "../../../../components/form-inputs/date-input/DateField.vue";
import GreenButton from "../../../../components/buttons/green-button/GreenButton.vue";
import NumberInput from "../../../../components/form-inputs/number-input/NumberInput.vue";
import MonthPicker from "../../../../components/form-inputs/date-input/MonthPicker.vue";

export default {
  name: "AddStaffModal",

  components: {
    GreenButton,
    DateField,
    NumberInput,
    Loading,
    MonthPicker,
  },

  data() {
    return {
      staffSalaryForm: {
        date: "",
        month: "",
        totalStaffSalaries: 0,
        noOfStaff: 0,
        split: 0.65,
      },
      loading: false,

      isRefresh: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.staffSalaryForm = {
        ...this.staffSalaryForm,
        ...obj,
      };
    },
    handleInputChange(obj) {
      this.staffSalaryForm = {
        ...this.staffSalaryForm,
        ...obj,
      };
    },

    async addNewCosts() {
      const { date, month, totalStaffSalaries, noOfStaff } =
        this.staffSalaryForm;
      if (!date || !month || !totalStaffSalaries || !noOfStaff) {
        return createToast("All fields are required!", {
          type: "danger",
          timeout: 2000,
          position: "top-left",
        });
      }

      try {
        this.loading = true;
        const record = await this.$store.dispatch(
          "staffSalaryCosts/addNewCosts", // need to update api
          this.staffSalaryForm
        );

        if (record) {
          this.loading = false;
          createToast("Staff Salary record added successfully!", {
            type: "success",
            timeout: 3000,
            position: "top-right",
            onClose: () => {
              this.$store.dispatch("overhead/setRefreshStatus", true);
              this.closeModal();
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}
</style>
