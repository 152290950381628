<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Edit User</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <TextInput
          placeholder="First Name"
          @onInputChange="handleInputChange"
          fieldName="first_name"
          :inputValue="userForm?.first_name"
        />
        <TextInput
          placeholder="Last Name"
          @onInputChange="handleInputChange"
          fieldName="last_name"
          :inputValue="userForm?.last_name"
        />
        <TextInput
          placeholder="Email"
          @onInputChange="handleInputChange"
          fieldName="email"
          :inputValue="userForm?.email"
        />
        <Dropdown
          :options="roles"
          class="select"
          dropdownTitle="Role"
          fieldName="roleId"
          fieldValue="userType"
          @onSelect="handleSelect"
          :inputValue="userForm?.userType"
        />
        <SelectDropdown
          :options="['Active', 'Inactive']"
          class="select"
          dropdownTitle="Status"
          fieldName="isActive"
          @onSelect="handleSelect"
          :inputValue="userForm?.isActive"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton width="12rem" label="Update user" @click="updateUser" />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import Dropdown from "../../../components/form-inputs/select-dropdown/Dropdown.vue";

export default {
  name: "EditUserModal",

  components: {
    TextInput,
    GreenButton,
    SelectDropdown,
    Dropdown,
    Loading,
  },

  data() {
    return {
      userForm: this.$store.getters["userManagement/getUserToBeUpdated"] || {
        first_name: "",
        last_name: "",
        email: "",
        isActive: "",
      },
      roles: [],
      loading: false,

      isRefresh: false,
    };
  },

  mounted() {
    this.fetchUserRoles();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.userForm = {
        ...this.userForm,
        ...obj,
      };
    },
    handleInputChange(obj) {
      this.userForm = {
        ...this.userForm,
        ...obj,
      };
    },

    async fetchUserRoles() {
      this.roles = await this.$store.dispatch("roles/getUserRoles");
    },

    async updateUser() {
      const rolesHash = this.$store.getters["roles/geRolesHash"];
      this.userForm = {
        ...this.userForm,
        userType: rolesHash[this.userForm.userType],
        isActive: this.userForm["isActive"] === "Active" ? true : false,
      };

      try {
        this.loading = true;
        const updatedUser = await this.$store.dispatch(
          "userManagement/updateUser",
          this.userForm
        );

        if (updatedUser) {
          this.loading = false;
          createToast("User updated successfully!", {
            type: "success",
            timeout: 3000,
            position: "top-right",
            onClose: () => {
              this.$store.dispatch("userManagement/setRefresh", true);
              this.closeModal();
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}
</style>
