export const priceHeaders = [
  { text: "S/N", value: "sn" },
  { text: "Name", value: "name" },
  { text: "Price(₦)", value: "price" },
  { text: "Weight(Kg)", value: "weight" },
  { text: "Actions", value: "actions" },
];

export const smsHeaders = [
  { text: "S/N", value: "sn" },
  { text: "Name", value: "name" },
  { text: "Content", value: "content" },
  { text: "Actions", value: "actions" },
];

export const rolesPermissionHeaders = [
  { text: "S/N", value: "sn" },
  { text: "Role Name", value: "name" },
  { text: "Actions", value: "actions" },
];

export const permissionHeaders = [
  { text: "S/N", value: "sn" },
  { text: "Permission Name", value: "name" },
  { text: "Actions", value: "actions" },
]
