import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  priceAtomSettingsList: [],
  priceAtomSettings: null,
  p1_to_p2_dry_qty: "",
});

const getters = {
  getPriceAtomSettingsList: (state) => {
    return state.priceAtomSettingsList;
  },

  getP1toP2DryQty: (state) => {
    return state.p1_to_p2_dry_qty;
  },
};

const mutations = {
  setPriceAtomSettingsList(state, payload) {
    state.priceAtomSettingsList = payload;
  },

  setP1toP2DryQty(state, payload) {
    state.p1_to_p2_dry_qty = payload;
  },
};

const actions = {
  async profitInput({ commit }) {
    try {
      const response = await APIConfig.get(`/profit/inputs`);
      commit("setPriceAtomSettingsList", response.data);
      return response.data[0];
    } catch (error) {
      return error.response.data;
    }
  },

  async getProfitPerAtom({ commit }, queryParams) {
    const { p1_cost_per_ton, millLat, millLng } = queryParams;
    try {
      const response = await APIConfig.get(
        `/profit/profit-per-atom?p1_cost_per_ton=${p1_cost_per_ton}&millLat=${millLat}&millLng=${millLng}`
      );
      commit("setPriceAtomSettingsList", response.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async updatePriceAtomSettings({ commit }, payload) {
    try {
      const response = await APIConfig.patch(`/profit/inputs`, payload);

      commit("setPriceAtomSettingsList", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async getOptimumP1PriceForProfitTarget(_, params) {
    const { millLat, millLng } = params;
    try {
      const { data } = await APIConfig.get(
        `/profit/optimum-p1-cost?millLat=${millLat}&millLng=${millLng}`
      );

      return data;
    } catch (error) {
      return error.response.data;
    }
  },

  async addP1toP2Dry({ commit }, payload) {
    commit("setP1toP2DryQty", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
