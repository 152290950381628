<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <!-- Header section -->
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <h5 class="modal-caption">Scheduled Dispatch Details</h5>

          <div class="logo-container">
            <img src="../../assets/releaf-logo.png" alt="" />
          </div>

          <div class="icons-container">
            <div class="" style="cursor: pointer">
              <img src="../../assets/edit-icon.png" alt="" />
            </div>
            <div class="" style="cursor: pointer" @click="deleteFarmer">
              <img src="../../assets/delete-icon.png" alt="" />
            </div>
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Dispatch details -->
    <section class="dispatch-details" v-if="dispatch">
      <div
        class=""
        style="margin: 0 auto; width: 96%; height: 420px; overflow: scroll"
      >
        <div class="details">
          <div class="details__section">
            <InfoBox header="Purchase" :info="dispatch.purchase" class="div1" />
            <InfoBox header="Factory" :info="dispatch.factory" class="div2" />
            <InfoBox
              header="Quantity Moved"
              :info="dispatch.qtyMoved + ' Ton(s)'"
              class="div3"
            />
            <InfoBox header="Vehicle" :info="dispatch.vehicle" class="div4" />
            <InfoBox
              header="Scheduled By"
              :info="dispatch.createdBy"
              class="div5"
            />
            <InfoBox
              header="Started By"
              :info="dispatch.createdBy"
              class="div6"
            />
            <InfoBox
              header="Vehicle Type"
              :info="dispatch.vehicle"
              class="div7"
            />
            <InfoBox header="Driver" :info="dispatch.driver" class="div8" />
            <InfoBox
              header="Scheduled Date"
              :info="dispatch.startDate"
              class="div9"
            />
            <InfoBox
              header="Start Date"
              :info="dispatch.startDate"
              class="div10"
            />
            <InfoBox header="End Date" :info="dispatch.endDate" class="div11" />
          </div>
        </div>
        <div class="border"></div>

        <div class="details">
          <div class="details__section">
            <InfoBox
              header="Loader Cost"
              :info="vueNumberFormat(dispatch.loaderCost, {})"
              class="div1"
            />
            <InfoBox
              header="Checkpoint Cost"
              :info="vueNumberFormat(dispatch.checkPointCost, {})"
              class="div2"
            />
            <InfoBox
              header="Community Cost"
              :info="vueNumberFormat(dispatch.communityCost, {})"
              class="div3"
            />
            <InfoBox
              header="Offloading Cost"
              :info="vueNumberFormat(dispatch.offloadingCost, {})"
              class="div4"
            />
            <InfoBox
              header="Imprest Cost"
              :info="vueNumberFormat(dispatch.imprestCost, {})"
              class="div5"
            />
            <InfoBox
              header="Total Dispatch Cost"
              :info="vueNumberFormat(dispatch.imprestCost, {})"
              class="div6"
            />
            <InfoBox
              header="Approval Status"
              :info="dispatch.authStatus"
              :status="authStatusHash[dispatch.authStatus]"
              class="div7"
            />
            <InfoBox
              header="Status"
              :info="dispatch.dispatchStatus"
              :status="dispatchStatusHash[dispatch.dispatchStatus]"
              class="div8"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="actions-container">
      <div class="" style="margin: 0 auto; width: 96%">
        <h6 class="action-section-header">Actions</h6>

        <div class="green-border"></div>

        <div class="action-buttons-container">
          <InvertedBlackButton
            label="Verify"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#669933"
          />

          <InvertedBlackButton
            label="Reject"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#fff"
            bgColor="#ED0A0A"
            @click="rejectDispatchEntry"
          />

          <GreenButton
            label="Approve"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#669933"
            @click="approveDispatchEntry"
          />
        </div>
      </div>
    </section>

    <!-- Dispatch details -->
  </div>
</template>

<script>
import InfoBox from "../../components/info-box/InfoBox.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";

export default {
  name: "ScheduledDispatchDetails",

  components: { InvertedBlackButton, InfoBox, GreenButton, Loading },

  data() {
    return {
      modalStatus: "",
      dispatch: {},
      authStatusHash: {
        Approved: "approved",
        Rejected: "rejected",
        Pending: "pending",
      },
      dispatchStatusHash: {
        Scheduled: "scheduled",
        Delivered: "delivered",
        "In-transit": "in-transit",
      },
      loading: false,
    };
  },

  props: {
    dispatchId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.fetchDispatch();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    async approveDispatchEntry() {
      const payload = {
        id: this.dispatchId,
        isApproved: true,
        authorisationStatus: "Approved",
      };

      this.loading = true;
      const data = await this.$store.dispatch(
        "dispatch/updateDispatch",
        payload
      );

      if (data.id) {
        this.loading = false;
        window.location.reload();
        createToast("Dispatch Approved successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            return window.location.reload();
          },
        });
      }
    },

    async rejectDispatchEntry() {
      const payload = {
        id: this.dispatchId,
        authorisationStatus: "Rejected",
      };

      this.loading = true;

      const data = await this.$store.dispatch(
        "dispatch/updateDispatch",
        payload
      );

      if (data.id) {
        this.loading = false;
        createToast("Dispatch Rejected successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            return window.location.reload();
          },
        });
      }
    },

    async fetchDispatch() {
      const dispatchId = this.dispatchId;

      try {
        const dispatch = await this.$store.dispatch(
          "dispatch/fetchOneDispatch",
          dispatchId
        );

        this.dispatch = {
          ...this.dispatch,
          ...dispatch,
        };
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content,
.details,
.contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details,
.header-content {
  padding: 5px;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 19%;
}

.x-container:hover {
  background: #e8e8e8;
}

.border,
.green-border {
  margin-top: 10px;
  background: #ffffff;
  width: 100%;
}

.border {
  border-bottom: 1px solid #b3b3b3;
}

.green-border {
  border-bottom: 2px dotted #669933;
}

.dispatch-details {
  height: 320px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.details {
}

.details__section {
  margin-top: 15px;
  width: 100%;
  height: 210px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  padding: 0px;
  /* border: 1px solid black; */
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 1 / 4 / 2 / 5;
}
.div5 {
  grid-area: 2 / 1 / 3 / 2;
}
.div6 {
  grid-area: 2 / 2 / 3 / 3;
}
.div7 {
  grid-area: 2 / 3 / 3 / 4;
}
.div8 {
  grid-area: 2 / 4 / 3 / 5;
}
.div9 {
  grid-area: 3 / 1 / 4 / 2;
}
.div10 {
  grid-area: 3 / 2 / 4 / 3;
}
.div11 {
  grid-area: 3 / 3 / 4 / 4;
}
.div12 {
  grid-area: 3 / 4 / 4 / 5;
}
.div13 {
  grid-area: 4 / 1 / 5 / 2;
}
.div14 {
  grid-area: 4 / 2 / 5 / 3;
}
.div15 {
  grid-area: 4 / 3 / 5 / 4;
}
.div16 {
  grid-area: 4 / 4 / 5 / 5;
}

.actions-container {
  margin-top: 15px;
}

.action-section-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 23px;
}

.action-buttons-container {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.action-button {
  margin-right: 15px;
}
</style>
