<template>
  <div class="mills-parent-modal">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="modal-header">
      <div style="margin: 0 auto; width: 96%">
        <div class="modal-header-content">
          <h5 class="modal-header-caption">Mills Details</h5>

          <div class="logo-container">
            <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
          </div>

          <div class="icons-container">
            <div style="cursor: pointer">
              <img
                src="../../../assets/edit-icon.png"
                @click="openEditModal"
                alt="edit-icon"
              />
            </div>

            <div style="cursor: pointer" @click="openDeleteModal">
              <img src="../../../assets/delete-icon.png" alt="delete-icon" />
            </div>

            <div class="x-container" @click="closeModal">
              <img src="../../../assets/x-icon.png" alt="x-icon" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mills-details">
      <div style="display: flex; justify-content: center; align-items: center">
        <div class="section-1">
          <div class="content-box">
            <p class="mills-label">Mill Name</p>
            <p class="mills-value">{{ mill.name }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">Physically Tagged</p>
            <p class="mills-value">{{ mill.physically_tagged || 'Nil'  }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">Mill Size</p>
            <p class="mills-value">{{ mill.size || 'Nil'  }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">Have Cracker</p>
            <p class="mills-value">{{ mill.have_cracker || 'Nil'  }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">No of Pots</p>
            <p class="mills-value">{{ mill.number_of_pots || 'Nil'  }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">No of Presser</p>
            <p class="mills-value">{{ mill.number_of_pressers || 'Nil' }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">State</p>
            <p class="mills-value">{{ mill.state }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">Community</p>
            <p class="mills-value">{{ mill.community }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">Longitude</p>
            <p class="mills-value">{{ mill.longitude }}</p>
          </div>

          <div class="content-box">
            <p class="mills-label">Latitude</p>
            <p class="mills-value">{{ mill.latitude }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="actions-container">
      <div class="actions-wrapper">
        <div class="action-heading">
          <h5>Actions</h5>
        </div>
        <hr />
        <div class="buttons-container">
          <InvertedRedButton
            label="Delete"
            :iconName="'delete-icon'"
            width="158px"
          />
          <InvertedGreenButton
            label="Approve"
            width="158px"
            @click="approveMill"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import InvertedGreenButton from "../../../components/buttons/inverted-green-button/InvertedGreenButton.vue";
import InvertedRedButton from "../../../components/buttons/inverted-red-button/InvertedRedButton.vue";
import { createToast } from "mosha-vue-toastify";

export default {
  name: "MillsDetailsModal",
  components: {
    Loading,
    InvertedGreenButton,
    InvertedRedButton,
  },

  data() {
    return {
      loading: false,
      modalStatus: "",
      mill: {},
    };
  },

  props: {
    millId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchMills();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    openEditModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openEditModal", true);
    },

    openDeleteModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openDeleteModal", true);
    },
    async fetchMills() {
      const millId = this.millId;

      try {
        this.loading = true;

        const mill = await this.$store.dispatch("mills/getOneMill", millId);

        this.mill = mill;
        this.loading = false;
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },

    async approveMill() {
      this.loading = true;

      const payload = {
        id: this.millId,
        isApproved: true,
        authorization_status: "Approved",
      };

      const updatedMill = await this.$store.dispatch(
        "mills/updateMill",
        payload
      );

      if (updatedMill.id) {
        this.loading = false;
        createToast("Mill approved successfully", {
          type: "success",
          position: "top-right",
          timeout: 3000,

          onClose: () => {
            this.closeModal();
            window.location.reload();
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.mills-parent-modal {
  background-color: #fff;
  height: 100%;
}

.modal-header-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 19%;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.modal-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mills-details {
  margin: 10px 0;
}

.section-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;

  /* margin: 5px auto; */
  width: 94%;
  /* border: 1px solid #fff; */
  margin: 0;
}

.content-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.mills-label {
  font-size: 16px;
  color: #8c8c8c;
}

.mills-value {
  font-size: 16px;
  color: #333;
  font-weight: 700;
}

.buttons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.action-heading {
  font-size: 24px;
  font-weight: 700;
  color: #669933;
}

.actions-container {
  width: 100%;
  height: 7rem;
  background: #fff;
  padding: 1rem;
}

hr {
  border-top: 2px dashed #8fc54d;
  margin: 0;
}

.actions-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
