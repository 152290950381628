<template>
  <div style="width: 100%">
    <div class="search-form">
      <input
        type="text"
        class="search-input"
        placeholder="Search"
        v-model="searchTerm"
        @keyup.enter="handleSearchText"
      />
      <button><img src="../../assets/search-icon.png" alt="" /></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",

  data() {
    return {
      searchTerm: "",
    };
  },

  //   methods: {
  //     handleSearchTextChange() {
  //       this.$emit("onSearchTermChange", this.searchTerm);
  //     },
  //   },

  watch: {
    searchTerm(newValue) {
      this.$emit("onSearchTermChange", newValue);
    },
  },
};
</script>

<style scoped>
.search-form {
  display: flex;
  width: 150%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.5rem;
  padding: 0 9px;
  background: #f9fafe;
  /* border: 1px solid red; */
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}
</style>
