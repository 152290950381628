import styled from "vue3-styled-components";

export const StyledButton = styled.button`
  background: ${({ bgColor }) => (bgColor ? bgColor : "#fff")}; 
  border-radius: 40px;
  border: 2px solid ${({ bgColor }) =>
    bgColor ? bgColor : "#232a34"};;
  outline: none;
  color: ${({ labelColor }) => (labelColor ? labelColor : "#232a34")}; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem

  &:focus {
     outline: none;
  }

  @media only screen and (min-width: 280px) {
    padding: ${({ padding }) => (padding ? padding : "0px 2.4rem")};
    gap: 8px;

    width: ${({ width }) => (width ? width : "109%")};
    height: ${({ height }) => (height ? height : "3rem")};

    & i {
      color: #669933;
    }
  }
`;
