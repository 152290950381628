<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h6 class="modal-caption">Verify Aggregation</h6>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <NumberInput
          placeholder="Tonnage Received"
          @onInputChange="handleInputChange"
          fieldName="quantity_moved"
          :inputValue="aggregationForm?.quantity_moved"
          :isReadOnly="true"
        />
        <NumberInput
          placeholder="Verified Tonnage"
          @onInputChange="handleInputChange"
          fieldName="verifiedTonnage"
          :inputValue="aggregationForm?.verifiedTonnage"
        />

        <DateField
          placeholder="End Date"
          @onInputChange="handleInputChange"
          fieldName="endDate"
          :inputValue="aggregationForm?.endDate"
        />

        <TimeField
          placeholder="End time"
          @onInputChange="handleInputChange"
          fieldName="endTime"
          :inputValue="aggregationForm?.endTime"
        />

        <TextInput
          placeholder="Reciept Number"
          @onInputChange="handleInputChange"
          fieldName="receipt_number"
          :inputValue="aggregationForm?.receipt_number"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton
        label="Submit"
        @click="verifyAggregation"
        width="12.5rem"
      />
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";

import NumberInput from "../../../components/form-inputs/number-input/NumberInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import DateField from "../../../components/form-inputs/date-input/DateField.vue";
import TimeField from "../../../components/form-inputs/date-input/TimeField.vue";
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";

export default {
  name: "VerifyAggregationModal",

  components: {
    GreenButton,
    NumberInput,
    DateField,
    TimeField,
    TextInput,
    Loading,
  },

  data() {
    return {
      aggregationForm: this.$store.getters[
        "aggregation/getAggregationToBeUpdated"
      ] || {
        endDate: "",
        endTime: "",
        receipt_number: "",
        verifiedTonnage: 0,
        quantity_moved: 0,
      },
      globalInputtedAggregation: {},
      error: "",
      loading: false,
      aggregationId:
        this.$store.getters["aggregation/getAggregationToBeUpdated"]["_id"],
    };
  },

  computed: {
    errors() {
      return this.$store.getters["aggregations/getError"];
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.aggregationForm = {
        ...this.aggregationForm,
        ...obj,
      };

      //   console.log(this.aggregationForm);
    },

    handleInputChange(obj) {
      this.aggregationForm = {
        ...this.aggregationForm,
        ...obj,
      };
    },

    async verifyAggregation() {
      const {
        endDate,
        endTime,
        receipt_number,
        verifiedTonnage,
        quantity_moved,
      } = this.aggregationForm;

      const payload = {
        id: this.aggregationId,
        endDate,
        endTime,
        receiptNumber: receipt_number,
        tonnageReceived: quantity_moved,
        verifiedTonnage,
        verification_status: "Verified",
      };

      this.loading = true;

      const data = await this.$store.dispatch(
        "aggregation/verifyAggregation",
        payload
      );

      if (data._id || data.id) {
        this.loading = false;
        createToast("Aggregation verified successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            return window.location.reload();
          },
        });
      }
    },
  },

  watch: {
    errors(newError) {
      this.loading = false;
      createToast(newError.message || newError.error, {
        type: "danger",
        timeout: 5000,
        position: "top-right",
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 800;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: 400px;
  display: flex;
  flex-direction: column;
}

.form {
  margin: 5% auto;
  width: 75%;
  height: auto;
  /* overflow: scroll; */
  flex: 1;
}

.purchase__section {
  background: #232a34;
  border-radius: 16px;
  width: 100%;
  height: auto;
  margin-inline: auto;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-top: 25px;
  color: #fff;
}

.purchases__aggregated {
  margin-inline: auto;
  width: 90%;
  height: 85%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.purchase__header {
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  font-weight: 100;
  margin: 2px;
}

.purchase__values {
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  align-self: start;
  color: #fff;
  margin: 3px 5px;
}

.purchase__values span {
  color: #fff;
  margin-left: 8px;
  cursor: pointer;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 25%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 1% auto 2% auto;
  gap: 20px;
}

.add-purchase {
  display: flex;
  width: 42%;
  margin-bottom: 2%;
  margin-left: auto;
}
</style>
