<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">{{ userTypeName }} Permission</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-container">
      <div class="border"></div>
      <form class="form-container-div">
        <div class="pko-input">
          <p class="pko-text">PKO Settings</p>

          <SelectDropdown
            :options="['Can View', 'Can Edit', 'None']"
            dropdownTitle=""
            fieldName="pko_settings"
            :reset="reset"
            :inputValue="permissionsForm.pko_settings"
            @onSelect="handleSelect"
          />
        </div>

        <div class="border"></div>

        <div class="pko-input">
          <p class="pko-text">PKC Settings</p>

          <SelectDropdown
            :options="['Can View', 'Can Edit', 'None']"
            dropdownTitle=""
            fieldName="pkc_settings"
            :reset="reset"
            :inputValue="permissionsForm.pkc_settings"
            @onSelect="handleSelect"
          />
        </div>

        <div class="border"></div>

        <div class="pko-input">
          <p class="pko-text">PKS Settings</p>

          <SelectDropdown
            :options="['Can View', 'Can Edit', 'None']"
            dropdownTitle=""
            fieldName="pks_settings"
            :reset="reset"
            :inputValue="permissionsForm.pks_settings"
            @onSelect="handleSelect"
          />
        </div>

        <div class="border"></div>

        <div class="pko-input">
          <p class="pko-text">Logistics Settings</p>

          <SelectDropdown
            :options="['Can View', 'Can Edit', 'None']"
            dropdownTitle=""
            fieldName="logistics_settings"
            :reset="reset"
            :inputValue="permissionsForm.logistics_settings"
            @onSelect="handleSelect"
          />
        </div>

        <div class="border"></div>

        <div class="pko-input">
          <p class="pko-text">PROC Settings</p>

          <SelectDropdown
            :options="['Can View', 'Can Edit', 'None']"
            dropdownTitle=""
            fieldName="proc_settings"
            :reset="reset"
            :inputValue="permissionsForm.proc_settings"
            @onSelect="handleSelect"
          />
        </div>

        <div class="border"></div>

        <div class="pko-input">
          <p class="pko-text">Atom Settings</p>

          <SelectDropdown
            :options="['Can View', 'Can Edit', 'None']"
            dropdownTitle=""
            fieldName="atom_settings"
            :reset="reset"
            :inputValue="permissionsForm.atom_settings"
            @onSelect="handleSelect"
          />
        </div>

        <div class="border"></div>
      </form>
    </section>

    <div class="button-container">
      <GreenButton
        label="Update"
        width="10.5rem"
        height="2.9rem"
        @click="updatePermission"
      />
    </div>
  </div>
</template>

<script>
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import { IdConfig2 } from "../../../config/idConfig";

export default {
  name: "RolesPermissionModal",
  components: {
    SelectDropdown,
    GreenButton,
    Loading,
  },
  data() {
    return {
      loading: false,
      reset: false,
      permissionsForm: this.userType.permissions || {
        pko_settings: "",
        pkc_settings: "",
        pks_settings: "",
        logistics_settings: "",
        proc_settings: "",
        atom_settings: "",
      },
      dataArray: [],
      permissionList: [],
      permissionsToId: {},
      userTypeName: this.userType.name,
      valueObj: {
        "Can View": "View",
        "Can Edit": "Edit",
        None: "None",
      },
      CanEdit: "Edit",
      CanView: "View",
    };
  },

  props: {
    userType: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.fetchAllPermission();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleReset() {
      this.reset = !this.reset;
    },

    handleSelect(obj) {
      this.permissionsForm = {
        ...this.permissionsForm,
        ...obj,
      };
    },

    async fetchCurrentUser() {
      const { data } = await IdConfig2.get(`/auth/currentUser`);

      if (data) {
        localStorage.setItem("user", JSON.stringify(data));
        window.location.reload();
      }
    },

    async fetchAllPermission() {
      this.loading = true;

      try {
        const data = await this.$store.dispatch(
          "rolepermissions/fetchAllPermissions"
        );

        this.permissionList = data;

        this.permissionList.forEach((el) => {
          this.permissionsToId = {
            ...this.permissionsToId,
            [el.name.toLocaleLowerCase() + "_settings"]: el.id,
          };
        });

        console.log(this.permissionsToId);

        this.loading = false;
      } catch (error) {
        console.log(error);

        this.loading = false;
      }
    },

    async updatePermission() {
      this.loading = true;
      // if (this.permissionsForm.pkc_settings === "Can View") {
      //   this.dataArray.push({
      //     permissionId: this.permissionList[0].id,
      //     action: this.valueObj["Can View"],
      //   });
      // }

      console.log(this.permissionsForm);

      const {
        pko_settings,
        pkc_settings,
        pks_settings,
        logistics_settings,
        proc_settings,
        atom_settings,
      } = this.permissionsForm;

      if (pko_settings !== "") {
        this.dataArray.push({
          permissionId: this.permissionsToId["pko_settings"],
          action: this.valueObj[pko_settings],
        });
      }

      if (pkc_settings !== "") {
        this.dataArray.push({
          permissionId: this.permissionsToId["pkc_settings"],
          action: this.valueObj[pkc_settings],
        });
      }

      if (pks_settings !== "") {
        this.dataArray.push({
          permissionId: this.permissionsToId["pks_settings"],
          action: this.valueObj[pks_settings],
        });
      }

      if (logistics_settings !== "") {
        this.dataArray.push({
          permissionId: this.permissionsToId["logistics_settings"],
          action: this.valueObj[logistics_settings],
        });
      }

      if (proc_settings !== "") {
        this.dataArray.push({
          permissionId: this.permissionsToId["proc_settings"],
          action: this.valueObj[proc_settings],
        });
      }

      if (atom_settings !== "") {
        this.dataArray.push({
          permissionId: this.permissionsToId["atom_settings"],
          action: this.valueObj[atom_settings],
        });
      }

      console.log(this.dataArray);

      try {
        // console.log(this.dataArray);
        const resp = await this.$store.dispatch(
          "rolepermissions/updateRolePermission",
          { id: this.userType.id, payload: this.dataArray }
        );
        if (resp.message) {
          this.loading = false;
          createToast(resp.message, {
            type: "success",
            position: "top-right",
            timeout: 3000,
            onClose: () => {
              this.fetchCurrentUser();
            },
          });
        }
        // window.location.reload();
      } catch (error) {
        console.log(error);

        this.loading = false;
      }
    },
  },

  watch: {
    "permissionsForm.pko_settings": function (val) {
      if (val) {
        this.dataArray.push({
          permissionId: this.permissionsToId["pko_settings"],
          action: this.valueObj[val],
        });

        console.log(this.dataArray);
      }
    },

    "permissionsForm.pkc_settings": function (val) {
      console.log({ val });

      if (val) {
        this.dataArray.push({
          permissionId: this.permissionsToId["pkc_settings"],
          action: this.valueObj[val],
        });
      }
    },

    "permissionsForm.pks_settings": function (val) {
      console.log({ val });
      if (val) {
        this.dataArray.push({
          permissionId: this.permissionsToId["pks_settings"],
          action: this.valueObj[val],
        });
      }
    },

    "permissionsForm.logistics_settings": function (val) {
      console.log({ val });
      if (val) {
        this.dataArray.push({
          permissionId: this.permissionsToId["logistics_settings"],
          action: this.valueObj[val],
        });
      }
    },

    "permissionsForm.proc_settings": function (val) {
      console.log({ val });
      if (val) {
        this.dataArray.push({
          permissionId: this.permissionsToId["proc_settings"],
          action: this.valueObj[val],
        });
      }
    },

    "permissionsForm.atom_settings": function (val) {
      console.log({ val });
      if (val) {
        this.dataArray.push({
          permissionId: this.permissionsToId["atom_settings"],
          action: this.valueObj[val],
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  height: 100%;
  background: #fff;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.form-container {
  height: 330px;
  overflow-y: scroll;
  padding-left: 2rem;
  padding-right: 2rem;
}
.form-container-div {
  width: 100%;
  height: 70%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pko-input {
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.pko-text {
  font-size: 16px;
  font-weight: 400;
  color: #232a34;
}
</style>
