<template>
  <div class="filter-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="false"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 6% auto 0 auto; width: 92%">
        <h2 class="caption">Filter</h2>
        <p>Choose one or more of the following options</p>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="none"
            v-model="noFilterOption"
          />
          <label for="no-option">None</label>
        </div>
      </div>
    </section>

    <section class="slider-section">
      <div class="wrapper">
        <h5 class="caption">Purchase Price</h5>
        <div class="container">
          <div class="input-container">
            <span>Min:</span>
            <input type="number" v-model="filterParams.minPurchasePrice" />
          </div>

          <div class="input-container">
            <span>Max:</span>
            <input type="number" v-model="filterParams.maxPurchasePrice" />
          </div>
        </div>
        <Slider
          v-model="purchasePrice"
          :tooltips="tooltipsMode"
          class="slider"
          @update="handlePurchasPriceChange(val)"
        />
      </div>
    </section>

    <section class="slider-section">
      <div class="wrapper">
        <h5 class="caption">Aggregation Logistic Cost</h5>
        <div class="container">
          <div class="input-container">
            <span>Min:</span>
            <input
              type="number"
              v-model="filterParams.minAggregationLogisticCost"
            />
          </div>

          <div class="input-container">
            <span>Max:</span>
            <input
              type="number"
              v-model="filterParams.maxAggregationLogisticCost"
            />
          </div>
        </div>
        <Slider
          v-model="aggregationLogisticCost"
          :tooltips="tooltipsMode"
          class="slider"
          @update="handleAggregationLogisticCostChange(val)"
        />
      </div>
    </section>

    <section class="slider-section">
      <div class="wrapper">
        <h5 class="caption">Profit/Atom</h5>
        <div class="container">
          <div class="input-container">
            <span>Min:</span>
            <input type="number" v-model="filterParams.minProfitPerAtom" />
          </div>

          <div class="input-container">
            <span>Max:</span>
            <input type="number" v-model="filterParams.maxProfitPerAtom" />
          </div>
        </div>
        <Slider
          v-model="profitPerAtom"
          :tooltips="tooltipsMode"
          class="slider"
          @update="handleProfitPerAtom(val)"
        />
      </div>
    </section>

    <section class="location-section">
      <div class="wrapper">
        <h5 class="caption">Others</h5>

        <div class="border"></div>

        <SelectDropdown
          :options="['Unmoved', 'Partially Moved', 'Completely Moved']"
          class="select"
          dropdownTitle="Status"
          @onSelect="handleSelect"
          fieldName="status"
          :inputValue="filterParams?.status"
        />

        <SelectDropdown
          :options="['Available', 'Aggregated', 'Consumed']"
          class="select"
          dropdownTitle="Processing Status"
          @onSelect="handleSelect"
          fieldName="processingStatus"
          :inputValue="filterParams?.processingStatus"
        />

        <div class="button-container">
          <GreenButton label="Apply Filter" @click="filterInventories(page)" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import Loading from "vue-loading-overlay";

import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import SelectDropdown from "../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import { statesDoc } from "../../data/docs/statesDoc";
import { lgasDoc } from "../../data/docs/lgasDoc";

import { convertToFilterQueryString } from "../../utils";

export default {
  name: "InventoryFilterForm",

  components: {
    Slider,
    GreenButton,
    SelectDropdown,
    Loading,
  },

  data() {
    return {
      noFilterOption: "",
      avgTonnagePerSupply: 0,
      noOfTransactions: 0,
      totalAmount: 0,
      states: statesDoc,
      lgas: lgasDoc,
      lazy: false,
      filterParams: {
        authorization_status: "",
        stateId: "",
        lgId: "",
        communityId: "",
        minPurchasePrice: 0,
        maxPurchasePrice: 0,
        minAggregationLogisticCost: 0,
        maxAggregationLogisticCost: 0,
        minProfitPerAtom: 0,
        maxProfitPerAtom: 0,
        status: "",
        processingStatus: "",
      },
      loading: false,
      page: 1,
      communities: [],
      communityResp: [],
      communitiesNameToSlug: {},
      communitiesSlugToName: {},
      purchasePrice: [0, 0],
      aggregationLogisticCost: [0, 0],
      profitPerAtom: [0, 0],
      tooltipsMode: false,
      APs: [],
    };
  },

  methods: {
    onChange(event) {
      this.filterParams.authorization_status = event.target.value;
    },

    handlePurchasPriceChange() {
      this.filterParams.minPurchasePrice = this.purchasePrice[0];
      this.filterParams.maxPurchasePrice = this.purchasePrice[1];
    },

    handleAggregationLogisticCostChange() {
      this.filterParams.minAggregationLogisticCost =
        this.aggregationLogisticCost[0];
      this.filterParams.maxAggregationLogisticCost =
        this.aggregationLogisticCost[1];
    },

    handleProfitPerAtom() {
      this.filterParams.minProfitPerAtom = this.profitPerAtom[0];
      this.filterParams.maxProfitPerAtom = this.profitPerAtom[1];
    },

    handleSelect(obj) {
      this.filterParams = {
        ...this.filterParams,
        ...obj,
      };
    },

    async filterInventories(page = 1, limit = 100) {
      const {
        stateId,
        lgId,
        communityId,
        authorization_status,
        minPurchasePrice,
        maxPurchasePrice,
        minProfitPerAtom,
        maxProfitPerAtom,
        minAggregationLogisticCost,
        maxAggregationLogisticCost,
        status,
        processingStatus,
      } = this.filterParams;

      const payload = {
        stateId,
        lgId,
        communityId,
        authorization_status,
        minPurchasePrice,
        maxPurchasePrice,
        minProfitPerAtom,
        maxProfitPerAtom,
        minAggregationLogisticCost,
        maxAggregationLogisticCost,
        status,
        processingStatus,
      };
      const filterQuery = convertToFilterQueryString(payload);

      this.loading = true;

      try {
        const { filteredInventories } = await this.$store.dispatch(
          "inventories/filterInventories",
          {
            page,
            limit,
            filterQuery,
          }
        );
        if (filteredInventories.length) this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  watch: {
    avgTonnagePerSupply(newValue) {
      console.log(newValue);
    },

    communityResp(newValue) {
      newValue.forEach((el) => {
        this.communitiesNameToSlug = {
          ...this.communitiesNameToSlug,
          [el.name]: el.slug,
        };
      });

      //communitiesSlugToName
      newValue.forEach((el) => {
        this.communitiesSlugToName = {
          ...this.communitiesSlugToName,
          [el.slug]: el.name,
        };
      });
    },
  },
};
</script>

<style lang="css" scoped>
.filter-parent {
  background-color: white;
  height: 100%;
  /* border: 1px solid #000; */
}

.caption {
  color: #232a34;
  font-weight: 800;
  margin-bottom: 2px;
}

.caption + p {
  color: #000000;
  /* font-weight: 100; */
  font-size: 14px;
  margin-bottom: 2px;
}

.input-group {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 0px;
}

input {
  display: block;
  margin-right: 15px;
  cursor: pointer;
}

input[type="radio"] {
  accent-color: #669933;
}

label {
  margin-bottom: 0px;
  font-size: 10px;
  color: #333333;
  font-size: 13px;
}

.slider-section {
  /* border: 1px solid red; */
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3% auto 0 auto;
  width: 90%;
  /* border: 1px solid red; */
}

.authorisation-caption,
.caption {
  color: #000000;
  font-weight: 700;
}

.caption {
  margin: 20px 0px 8px 0px;
}

.border {
  border-bottom: 1px solid #545454;
  color: #a0a2a3;
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 25px;
  /* border: 1px solid red; */
}

.input-container span {
  align-self: center;
}

.input-container input {
  width: 70%;
  text-align: center;
}

p {
  color: #448800;
  font-weight: 500;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 7% auto 2% auto;
}

.slider {
  --slider-connect-bg: #448800;
  --slider-tooltip-bg: #448800;
  --slider-handle-ring-color: #ef444430;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
