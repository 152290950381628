const state = () => ({
  refreshStatus: false,
  deleteModalStatus: false,
  error: null,
});

const getters = {
  getRefreshStatus: (state) => state.refreshStatus,

  getDeleteModalStatus: (state) => {
    return state.deleteModalStatus;
  },
};

const mutations = {
  updateRefreshStatus(state, refreshStatus) {
    state.refreshStatus = refreshStatus;
  },

  setDeleteModalStatus: (state, status) => {
    state.deleteModalStatus = status;
  },
};

const actions = {
  setRefreshStatus({ commit }, status) {
    commit("updateRefreshStatus", status);
  },

  updateDeleteModalStatus: ({ commit }, status) => {
    commit("setDeleteModalStatus", status);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
