<template>
  <div>
    <!-- Date Filter Section -->
    <section class="date-filter-container">
      <div class="wrapper">
        <p class="">Search by Period</p>
        <div class="controls-container">
          <div class="inputs-container">
            <div class="input">
              <input
                type="text"
                name=""
                id="date"
                placeholder="Select Start Date"
                onfocus="(this.type = 'date')"
                v-model="startDate"
              />
            </div>
            <div class="input">
              <input
                type="text"
                name=""
                id="date"
                placeholder="Select End Date"
                onfocus="(this.type = 'date')"
                v-model="endDate"
              />
            </div>
            <GreenButton
              label="Fetch"
              width="5rem"
              height="2rem"
              @click="fetchByDatePeriod(page)"
            />
          </div>

          <div v-if="selectedAggregationIds.length > 0" class="multi-actions">
            <i
              class="fa fa-trash"
              style="font-size: 24px; color: red; cursor: pointer"
              @click="openDeleteManyModal"
            ></i>

            <GreenButton
              label="Approve"
              width="5rem"
              height="2rem"
              @click="openApprovalModal"
            />
          </div>

          <div class="buttons-container">
            <div class="">
              <download-excel
                :data="
                  activeLogisticsType === 'Aggregation'
                    ? aggregations
                    : dispatches
                "
              >
                <InvertedBlackButton
                  label="Export"
                  width="8rem"
                  height="2.6rem"
                  iconName="excel-icon"
                ></InvertedBlackButton>
              </download-excel>
            </div>
            <div class="">
              <BlackButton
                label="Open Filter"
                width="10rem"
                height="2.6rem"
                iconName="filter-icon"
                @click="openFilterModal"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Data table section -->
    <div class="data-table-container">
      <EasyDataTable
        v-model:server-options="paginationMetaData"
        v-model:items-selected="itemsSelected"
        :server-items-length="count"
        :headers="aggregationTableHeaders"
        :items="aggregations"
        alternating
        table-class-name="customize-table"
        class="data-table"
        :loading="loading"
        buttons-pagination
      >
        <template #item-status="item">
          <div class="status-wrapper">
            <div
              class="status"
              :class="[
                item.authorisationStatus === 'Approved'
                  ? 'approved'
                  : item.authorisationStatus === 'Rejected'
                  ? 'rejected'
                  : 'pending',
              ]"
            >
              {{ item.status }}
            </div>
          </div>
        </template>
        <template #item-verificationStatus="item">
          <div class="status-wrapper">
            <div
              class="status"
              :class="[
                item.verificationStatus === 'Verified' ? 'approved' : 'pending',
              ]"
            >
              {{ item.verificationStatus }}
            </div>
          </div>
        </template>
        <template #item-dispatchStatus="item">
          <div class="status-wrapper">
            <div
              class="status"
              :class="[
                item.dispatchStatus === 'In-transit'
                  ? 'approved'
                  : item.dispatchStatus === 'Delivered'
                  ? 'approved'
                  : 'pending',
              ]"
            >
              {{ item.dispatchStatus }}
            </div>
          </div>
        </template>
        <template #item-actions="item">
          <div class="actions-wrapper">
            <div class="" @click="toggleActions(item)">
              <div
                class="action-options"
                v-if="isActionsOpened && item._id === itemClicked"
              >
                <div class="item-wrapper">
                  <div class="actions-item" @click="openDetailsModal">
                    <div class="image">
                      <img
                        src="../../assets/green-eye.png"
                        alt=""
                        class="action-icon green-eye"
                      />
                    </div>
                    <p class="view-action">View details</p>
                  </div>
                  <div class="actions-item" @click="handleEdit(item._id)">
                    <div class="">
                      <img
                        src="../../assets/edit-icon.png"
                        alt=""
                        class="action-icon edit-icon"
                      />
                    </div>
                    <p class="edit-action">Edit</p>
                  </div>
                  <div class="actions-item" @click="openDeleteModal(item._id)">
                    <div class="">
                      <img
                        src="../../assets/delete-icon.png"
                        alt=""
                        class="action-icon delete-icon"
                      />
                    </div>
                    <p class="delete-action">Delete</p>
                  </div>
                </div>
              </div>
              <span>&#8942;</span>
            </div>
          </div>
        </template>
      </EasyDataTable>
    </div>
  </div>
</template>

<script>
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import BlackButton from "../../components/buttons/black-button/BlackButton.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import { aggregationTableHeaders } from "./table-headers/headers";
import { convertToFilterQueryString } from "../../utils";

export default {
  name: "Aggregation",

  components: {
    GreenButton,
    BlackButton,
    InvertedBlackButton,
  },

  data() {
    return {
      modalPageNum: 1,
      aggregation: "Aggregation",
      dispatch: "Dispatch",
      scheduled: "Scheduled",
      startDate: "",
      endDate: "",
      activeLogisticsType: "Scheduled",
      searchTerm: "",
      loading: false,
      itemsSelected: [],
      paginationMetaData: {
        page: 1,
        rowsPerPage: 100,
      },

      aggregationTableHeaders: aggregationTableHeaders,
      aggregations: [],
      selectedAggregationIds: [],
      dispatches: [],
      scheduledDispatches: [],
      dataToExcel: [],
      globalSearchTerm: "",
      globalFilterQuery: "",
      itemClicked: "",
      isActionsOpened: false,
      isCreateLogisticsOpened: false,
      isEditLogisticsModalOpened: false,
      isDetailsModalOpened: false,
      isFilterModalOpened: false,
      isApprovalStatus: false,
    };
  },

  mounted() {
    this.fetchAggregations();
  },

  computed: {
    logisticsSearchTerm() {
      return this.$store.getters["logistics/getLogisticsSearchTerm"];
    },

    filterMetaData() {
      return this.$store.getters["aggregation/getFilterMetaData"];
    },
  },

  methods: {
    async fetchAggregations(page = 1, limit = 25, searchTerm = "") {
      try {
        this.loading = true;

        const { aggregations, count } = await this.$store.dispatch(
          "aggregation/fetchAllAggregations",
          {
            page,
            searchTerm,
            limit,
          }
        );

        this.aggregations = aggregations;
        this.count = count;
        this.globalSearchTerm = searchTerm;
        this.loading = false;
        this.dataToExcel = aggregations;
      } catch (error) {
        this.loading = false;
      }
    },

    toggleActions(arg) {
      this.itemClicked = arg._id;
      this.isActionsOpened = !this.isActionsOpened;
    },

    openDetailsModal() {
      this.$store.dispatch("aggregation/setAggregationId", this.itemClicked);
      this.$store.dispatch("aggregation/setDetailsModalStatus", true);
    },

    openApprovalModal() {
      this.$store.dispatch(
        "aggregation/updateMultipleIds",
        this.selectedAggregationIds
      );
      this.$store.dispatch("aggregation/updateApprovalModalStatus", true);
    },

    async fetchByDatePeriod(page = 1, limit = 100, filterQuery = "") {
      if (this.startDate === "" || this.endDate === "") return;
      let query = "";

      if (!filterQuery) {
        query = convertToFilterQueryString({
          startDate: this.startDate,
          endDate: this.endDate,
        });
      } else {
        query = filterQuery;
      }
      this.loading = true;

      try {
        const { count, aggregations, filterQuery } = await this.$store.dispatch(
          "aggregation/filterAggregationsByDatePeriod",
          {
            page,
            filterQuery: query,
            limit,
          }
        );

        this.loading = false;

        this.aggregations = aggregations;
        this.count = count;
        this.globalFilterQuery = filterQuery;
      } catch (error) {
        console.log(error);
      }
    },

    async filterLogistics(page, limit = 100, filterQuery) {
      this.loading = true;

      try {
        const { filteredAggregations, count } = await this.$store.dispatch(
          "aggregation/filterAggregations",
          {
            page,
            filterQuery,
            limit,
          }
        );

        this.aggregations = filteredAggregations;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    openEditModal() {
      this.isEditLogisticsModalOpened = !this.isEditLogisticsModalOpened;
    },

    handleEdit(id) {
      this.$store.dispatch("aggregation/findAggregationToBeUpdated", id);
      this.$store.dispatch("aggregation/setEditModalStatus", true);
      this.$store.dispatch("aggregation/setActiveLogisticsTab", "Aggregation");

      this.openEditModal();
    },

    openFilterModal() {
      this.$store.dispatch("aggregation/updateFilterModalStatus", true);
      this.$store.dispatch("aggregation/setActiveLogisticsTab", "Aggregation");
    },

    openDeleteModal(id) {
      this.$store.dispatch("aggregation/updateDeleteModalStatus", true);
      this.$store.dispatch("aggregation/setAggregationId", id);
      this.$store.dispatch("aggregation/setActiveLogisticsTab", "Aggregation");
    },

    openDeleteManyModal() {
      this.$store.dispatch(
        "aggregation/updateMultipleIds",
        this.selectedAggregationIds
      );
      this.$store.dispatch("aggregation/updateDeleteModalStatus", true);
      this.$store.dispatch("aggregation/setActiveLogisticsTab", "Aggregation");
    },
  },

  watch: {
    logisticsSearchTerm(newValue) {
      const pageNum = 1;
      this.searchTerm = newValue;
      this.fetchAggregations(pageNum, this.searchTerm);
    },

    paginationMetaData: {
      handler: function (newValue) {
        const isFilter = this.$store.getters["aggregation/getFilterStatus"];

        const isAggregationDateFilter =
          this.$store.getters["aggregation/getIsDateFilterStatus"];

        if (isFilter) {
          this.filterLogistics(newValue.page, newValue.rowsPerPage, this.globalFilterQuery);
        } else if (isAggregationDateFilter) {
          this.fetchByDatePeriod(newValue.page, newValue.rowsPerPage, this.globalFilterQuery);
        } else this.fetchAggregations(newValue.page, newValue.rowsPerPage, this.searchTerm);
      },
    },

    filterMetaData(newValue) {
      const { filteredAggregations, count, query } = newValue;
      this.globalFilterQuery = query;
      this.aggregations = filteredAggregations;
      this.count = count;
    },

    itemsSelected: {
      handler: function (newValue) {
        this.selectedAggregationIds = newValue.map(
          (aggregation) => aggregation._id
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.date-filter-container {
  width: 100%;
  margin-top: 1.5%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 85%;
}

.wrapper p {
  margin-bottom: 0px;
}

.date-filter-container p {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 0.78rem;
  line-height: 24px;
  color: #333333;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

.multi-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.create-buttons__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 36%;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 27%;
  /* border: 1px solid #000; */
}

.data-table-container {
  width: 100%;
  /* border: 1px solid green; */
  height: 79.9%;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

/* Data table Styles */
.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 7px 10px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;

  width: 127px;
  height: 32px;

  border-radius: 41px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.approved {
  color: #669933;
  background: rgba(102, 153, 51, 0.15);
}

.rejected {
  color: #ed0a0a;
  background: rgba(237, 10, 10, 0.15);
}

.pending {
  color: #f19306 !important;
  background: rgba(241, 147, 6, 0.15);
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5px;

  position: absolute;
  width: 110%;
  height: auto;
  right: 60px;
  background: #ffffff;
  /* Drop down shadow */
  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
}

.item-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 5px;
}

.actions-item {
  display: flex;
  cursor: pointer;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.view-action,
.edit-action,
.delete-action {
  margin-left: 5px;
}

.view-action {
  color: #669933;
}

.edit-action {
  color: #ea9a00;
}

.delete-action {
  color: #ed0a0a;
}
</style>
