export const dieselTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Payment", value: "date" },
  { text: "LMV", value: "vehicleName" },
  { text: "Fuel Type", value: "fuelType" },
  { text: "Cost per liter", value: "costPerLitre" },
  { text: "No of liters", value: "noOfLitres" },
  { text: "Amount Paid", value: "amountPaid" },
  { text: "Created By", value: "createdBy" },
  { text: "Actions", value: "actions" },
];

export const imprestTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Payment", value: "date" },
  { text: "Agent", value: "agent" },
  { text: "Amount Paid", value: "impressAmount" },
  { text: "Created By", value: "createdBy" },
  { text: "Actions", value: "actions" },
];

export const maintenanceTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Maintenance", value: "dateOfMaintenance" },
  { text: "Vehicle", value: "vehicle" },
  { text: "Maintenance Type", value: "maintenanceType" },
  { text: "Parts needed", value: "partsNeeded" },
  { text: "Total Cost Of Repairs", value: "totalCostOfRepairs" },
  { text: "Time Repairs", value: "timeOfRepair" },
  { text: "Created By", value: "createdBy" },
  { text: "Actions", value: "actions" },
];

export const loadersTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Payment", value: "date" },
  { text: "Payment Type", value: "paymentType" },
  { text: "Loaders' Number", value: "noOfLoaders" },
  { text: "Vehicle", value: "vehicle" },
  { text: "Amount Paid", value: "totalAmount" },
  { text: "Created By", value: "createdBy" },
  { text: "Actions", value: "actions" },
];

export const supeTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Payment", value: "date" },
  { text: "Type", value: "type" },
  { text: "Month", value: "month" },
  { text: "Amount Paid", value: "impressAmount" },
  { text: "Created By", value: "createdBy" },
  { text: "Actions", value: "actions" },
];

export const tollsTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Payment", value: "date" },
  { text: "Month", value: "month" },
  { text: "Weeks", value: "noOfWeeks" },
  { text: "Created By", value: "createdBy" },
  { text: "Amount Paid", value: "tollAmount" },
  { text: "Actions", value: "actions" },
];

export const mgmtTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Payment", value: "date" },
  { text: "Type", value: "type" },
  { text: "Month", value: "month" },
  { text: "Number Of Staff", value: "noOfManagementStaff" },
  { text: "Total Mgt Salaries", value: "totalManagementSalaries" },
  { text: "Created By", value: "createdBy" },
  { text: "Actions", value: "actions" },
];

export const staffTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Payment", value: "date" },
  { text: "Month", value: "month" },
  { text: "Number Of Staff", value: "noOfStaff" },
  { text: "Total Staff Salaries", value: "totalStaffSalaries" },
  { text: "Created By", value: "createdBy" },
  { text: "Actions", value: "actions" },
];

export const otherTableHeaders = [
  { text: "Date Added", value: "dateAdded" },
  { text: "Date Of Payment", value: "date" },
  { text: "Type", value: "type" },
  { text: "Month", value: "month" },
  { text: "Number Of Agents", value: "noOfAgents" },
  { text: "Total Agents Salaries", value: "totalAgentsSalaries" },
  { text: "Created By", value: "createdBy" },
  { text: "Actions", value: "actions" },
];
