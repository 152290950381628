<template>
    <div>
        <styled-button :width="width" :height="height"><img :src="imagePath" alt="" class="icon"
                v-if="iconName" /><span>{{
        label
                }}</span></styled-button>
    </div>
</template>

<script>
import { StyledButton } from "./invertedRedButton.styles";

export default {
    name: "InvertedGreenButton",
    components: {
        StyledButton,
    },
    props: {
        width: {
            type: String,
        },
        height: {
            type: String,
        },
        iconName: {
            type: String,
        },
        label: {
            type: String,
            required: true,
        },
    },

    computed: {
        imagePath() {
            return require(`../../../assets/${this.iconName}.png`);
        },
    },
};
</script>
