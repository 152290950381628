export const lgasDoc = [
  { id: "60c358bf2079e2001e77dfc2", name: "Aba South", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfc3", name: "Arochukwu", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfc4", name: "Bende", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfc5", name: "Ikwuano", parentSlug: "ABI" },
  {
    id: "60c358bf2079e2001e77dfc6",
    name: "Isiala Ngwa North",
    parentSlug: "ABI",
  },
  {
    id: "60c358bf2079e2001e77dfc7",
    name: "Isiala Ngwa South",
    parentSlug: "ABI",
  },
  { id: "60c358bf2079e2001e77dfc8", name: "Isuikwuato", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfc9", name: "Obi Ngwa", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfca", name: "Ohafia", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfcb", name: "Osisioma", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfcc", name: "Ugwunagbo", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfcd", name: "Ukwa East", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfce", name: "Ukwa West", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfcf", name: "Umuahia North", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfd0", name: "Umuahia South", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfd1", name: "Umu Nneochi", parentSlug: "ABI" },
  { id: "60c358bf2079e2001e77dfd2", name: "Fufure", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfd3", name: "Ganye", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfd4", name: "Gayuk", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfd5", name: "Gombi", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfd6", name: "Grie", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfd7", name: "Hong", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfd8", name: "Jada", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfd9", name: "Lamurde", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfda", name: "Madagali", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfdb", name: "Maiha", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfdc", name: "Mayo Belwa", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfdd", name: "Michika", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfde", name: "Mubi North", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfdf", name: "Mubi South", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfe0", name: "Numan", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfe1", name: "Shelleng", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfe2", name: "Song", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfe3", name: "Toungo", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfe4", name: "Yola North", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfe5", name: "Yola South", parentSlug: "ADA" },
  { id: "60c358bf2079e2001e77dfe6", name: "Abak", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dfe7", name: "Eastern Obolo", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dfe8", name: "Eket", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dfe9", name: "Esit Eket", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dfea", name: "Essien Udim", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dfeb", name: "Etim Ekpo", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dfec", name: "Etinan", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dfed", name: "Ibeno", parentSlug: "AKW" },
  {
    id: "60c358bf2079e2001e77dfee",
    name: "Ibesikpo Asutan",
    parentSlug: "AKW",
  },
  { id: "60c358bf2079e2001e77dfef", name: "Ibiono-Ibom", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff0", name: "Ika", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff1", name: "Ikono", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff2", name: "Ikot Abasi", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff3", name: "Ikot-Ekpene", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff4", name: "Ini", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff5", name: "Itu", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff6", name: "Mbo", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff7", name: "Mkpat-Enin", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff8", name: "Nsit-Atai", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dff9", name: "Nsit-Ibom", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dffa", name: "Nsit-Ubium", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dffb", name: "Obot Akara", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dffc", name: "Okobo", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dffd", name: "Onna", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dffe", name: "Oron", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77dfff", name: "Oruk Anam", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77e000", name: "Udung-Uko", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77e001", name: "Ukanafun", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77e002", name: "Uruan", parentSlug: "AKW" },
  {
    id: "60c358bf2079e2001e77e003",
    name: "Urue-Offong/Oruko",
    parentSlug: "AKW",
  },
  { id: "60c358bf2079e2001e77e004", name: "Uyo", parentSlug: "AKW" },
  { id: "60c358bf2079e2001e77e005", name: "Anambra East", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e006", name: "Anambra West", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e007", name: "Anaocha", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e008", name: "Awka North", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e009", name: "Awka South", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e00a", name: "Ayamelum", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e00b", name: "Dunukofia", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e00c", name: "Ekwusigo", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e00d", name: "Idemili North", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e00e", name: "Idemili South", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e00f", name: "Ihiala", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e010", name: "Njikoka", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e011", name: "Nnewi North", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e012", name: "Nnewi South", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e013", name: "Ogbaru", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e014", name: "Onitsha North", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e015", name: "Onitsha South", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e016", name: "Orumba North", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e017", name: "Orumba South", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e018", name: "Oyi", parentSlug: "ANA" },
  { id: "60c358bf2079e2001e77e019", name: "Bauchi", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e01a", name: "Bogoro", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e01b", name: "Damban", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e01c", name: "Darazo", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e01d", name: "Dass", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e01e", name: "Gamawa", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e01f", name: "Ganjuwa", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e020", name: "Giade", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e021", name: "Itas/Gadau", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e022", name: "Jama'are", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e023", name: "Katagum", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e024", name: "Kirfi", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e025", name: "Misau", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e026", name: "Ningi", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e027", name: "Shira", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e028", name: "Tafawa Balewa", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e029", name: "Toro", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e02a", name: "Warji", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e02b", name: "Zaki", parentSlug: "BAU" },
  { id: "60c358bf2079e2001e77e02c", name: "Ekeremor", parentSlug: "BAY" },
  {
    id: "60c358bf2079e2001e77e02d",
    name: "Kolokuma/Opokuma",
    parentSlug: "BAY",
  },
  { id: "60c358bf2079e2001e77e02e", name: "Nembe", parentSlug: "BAY" },
  { id: "60c358bf2079e2001e77e02f", name: "Ogbia", parentSlug: "BAY" },
  { id: "60c358bf2079e2001e77e030", name: "Sagbama", parentSlug: "BAY" },
  { id: "60c358bf2079e2001e77e031", name: "Southern Ijaw", parentSlug: "BAY" },
  { id: "60c358bf2079e2001e77e032", name: "Yenagoa", parentSlug: "BAY" },
  { id: "60c358bf2079e2001e77e033", name: "Apa", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e034", name: "Ado", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e035", name: "Buruku", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e036", name: "Gboko", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e037", name: "Guma", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e038", name: "Gwer East", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e039", name: "Gwer West", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e03a", name: "Katsina-Ala", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e03b", name: "Konshisha", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e03c", name: "Kwande", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e03d", name: "Logo", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e03e", name: "Makurdi", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e03f", name: "Obi", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e040", name: "Ogbadibo", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e041", name: "Ohimini", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e042", name: "Oju", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e043", name: "Okpokwu", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e044", name: "Oturkpo", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e045", name: "Tarka", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e046", name: "Ukum", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e047", name: "Ushongo", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e048", name: "Vandeikya", parentSlug: "BEN" },
  { id: "60c358bf2079e2001e77e049", name: "Askira/Uba", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e04a", name: "Bama", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e04b", name: "Bayo", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e04c", name: "Biu", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e04d", name: "Chibok", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e04e", name: "Damboa", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e04f", name: "Dikwa", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e050", name: "Gubio", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e051", name: "Guzamala", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e052", name: "Gwoza", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e053", name: "Hawul", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e054", name: "Jere", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e055", name: "Kaga", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e056", name: "Kala/Balge", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e057", name: "Konduga", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e058", name: "Kukawa", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e059", name: "Kwaya Kusar", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e05a", name: "Mafa", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e05b", name: "Magumeri", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e05c", name: "Maiduguri", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e05d", name: "Marte", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e05e", name: "Mobbar", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e05f", name: "Monguno", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e060", name: "Ngala", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e061", name: "Nganzai", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e062", name: "Shani", parentSlug: "BOR" },
  { id: "60c358bf2079e2001e77e063", name: "Abi", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e064", name: "Akamkpa", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e065", name: "Akpabuyo", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e066", name: "Bakassi", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e067", name: "Bekwarra", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e068", name: "Biase", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e069", name: "Boki", parentSlug: "CRO" },
  {
    id: "60c358bf2079e2001e77e06a",
    name: "Calabar Municipal",
    parentSlug: "CRO",
  },
  { id: "60c358bf2079e2001e77e06b", name: "Calabar South", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e06c", name: "Etung", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e06d", name: "Ikom", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e06e", name: "Obanliku", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e06f", name: "Obubra", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e070", name: "Obudu", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e071", name: "Odukpani", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e072", name: "Ogoja", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e073", name: "Yakuur", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e074", name: "Yala", parentSlug: "CRO" },
  { id: "60c358bf2079e2001e77e075", name: "Aniocha South", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e076", name: "Bomadi", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e077", name: "Burutu", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e078", name: "Ethiope East", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e079", name: "Ethiope West", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e07a", name: "Ika North East", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e07b", name: "Ika South", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e07c", name: "Isoko North", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e07d", name: "Isoko South", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e07e", name: "Ndokwa East", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e07f", name: "Ndokwa West", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e080", name: "Okpe", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e081", name: "Oshimili North", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e082", name: "Oshimili South", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e083", name: "Patani", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e084", name: "Sapele", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e085", name: "Udu", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e086", name: "Ughelli North", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e087", name: "Ughelli South", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e088", name: "Ukwuani", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e089", name: "Uvwie", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e08a", name: "Warri North", parentSlug: "DEL" },
  { id: "60c358bf2079e2001e77e08b", name: "Warri South", parentSlug: "DEL" },
  {
    id: "60c358bf2079e2001e77e08c",
    name: "Warri South West",
    parentSlug: "DEL",
  },
  { id: "60c358bf2079e2001e77e08d", name: "Afikpo North", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e08e", name: "Afikpo South", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e08f", name: "Ebonyi", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e090", name: "Ezza North", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e091", name: "Ezza South", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e092", name: "Ikwo", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e093", name: "Ishielu", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e094", name: "Ivo", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e095", name: "Izzi", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e096", name: "Ohaozara", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e097", name: "Ohaukwu", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e098", name: "Onicha", parentSlug: "EBO" },
  { id: "60c358bf2079e2001e77e099", name: "Egor", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e09a", name: "Esan Central", parentSlug: "EDO" },
  {
    id: "60c358bf2079e2001e77e09b",
    name: "Esan North-East",
    parentSlug: "EDO",
  },
  {
    id: "60c358bf2079e2001e77e09c",
    name: "Esan South-East",
    parentSlug: "EDO",
  },
  { id: "60c358bf2079e2001e77e09d", name: "Esan West", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e09e", name: "Etsako Central", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e09f", name: "Etsako East", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e0a0", name: "Etsako West", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e0a1", name: "Igueben", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e0a2", name: "Ikpoba Okha", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e0a3", name: "Orhionmwon", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e0a4", name: "Oredo", parentSlug: "EDO" },
  {
    id: "60c358bf2079e2001e77e0a5",
    name: "Ovia North-East",
    parentSlug: "EDO",
  },
  {
    id: "60c358bf2079e2001e77e0a6",
    name: "Ovia South-West",
    parentSlug: "EDO",
  },
  { id: "60c358bf2079e2001e77e0a7", name: "Owan East", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e0a8", name: "Owan West", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e0a9", name: "Uhunmwonde", parentSlug: "EDO" },
  { id: "60c358bf2079e2001e77e0aa", name: "Efon", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0ab", name: "Ekiti East", parentSlug: "EKI" },
  {
    id: "60c358bf2079e2001e77e0ac",
    name: "Ekiti South-West",
    parentSlug: "EKI",
  },
  { id: "60c358bf2079e2001e77e0ad", name: "Ekiti West", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0ae", name: "Emure", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0af", name: "Gbonyin", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0b0", name: "Ido Osi", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0b1", name: "Ijero", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0b2", name: "Ikere", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0b3", name: "Ikole", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0b4", name: "Ilejemeje", parentSlug: "EKI" },
  {
    id: "60c358bf2079e2001e77e0b5",
    name: "Irepodun/Ifelodun",
    parentSlug: "EKI",
  },
  { id: "60c358bf2079e2001e77e0b6", name: "Ise/Orun", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0b7", name: "Moba", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0b8", name: "Oye", parentSlug: "EKI" },
  { id: "60c358bf2079e2001e77e0b9", name: "Awgu", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0ba", name: "Enugu East", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0bb", name: "Enugu North", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0bc", name: "Enugu South", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0bd", name: "Ezeagu", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0be", name: "Igbo Etiti", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0bf", name: "Igbo Eze North", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c0", name: "Igbo Eze South", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c1", name: "Isi Uzo", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c2", name: "Nkanu East", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c3", name: "Nkanu West", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c4", name: "Nsukka", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c5", name: "Oji River", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c6", name: "Udenu", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c7", name: "Udi", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c8", name: "Uzo Uwani", parentSlug: "ENU" },
  { id: "60c358bf2079e2001e77e0c9", name: "Bwari", parentSlug: "FCT" },
  { id: "60c358bf2079e2001e77e0ca", name: "Gwagwalada", parentSlug: "FCT" },
  { id: "60c358bf2079e2001e77e0cb", name: "Kuje", parentSlug: "FCT" },
  { id: "60c358bf2079e2001e77e0cc", name: "Kwali", parentSlug: "FCT" },
  {
    id: "60c358bf2079e2001e77e0cd",
    name: "Municipal Area Council",
    parentSlug: "FCT",
  },
  { id: "60c358bf2079e2001e77e0ce", name: "Balanga", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0cf", name: "Billiri", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d0", name: "Dukku", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d1", name: "Funakaye", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d2", name: "Gombe", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d3", name: "Kaltungo", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d4", name: "Kwami", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d5", name: "Nafada", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d6", name: "Shongom", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d7", name: "Yamaltu/Deba", parentSlug: "GOM" },
  { id: "60c358bf2079e2001e77e0d8", name: "Ahiazu Mbaise", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0d9", name: "Ehime Mbano", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0da", name: "Ezinihitte", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0db", name: "Ideato North", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0dc", name: "Ideato South", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0dd", name: "Ihitte/Uboma", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0de", name: "Ikeduru", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0df", name: "Isiala Mbano", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e0", name: "Isu", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e1", name: "Mbaitoli", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e2", name: "Ngor Okpala", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e3", name: "Njaba", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e4", name: "Nkwerre", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e5", name: "Nwangele", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e6", name: "Obowo", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e7", name: "Oguta", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e8", name: "Ohaji/Egbema", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0e9", name: "Okigwe", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0ea", name: "Orlu", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0eb", name: "Orsu", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0ec", name: "Oru East", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0ed", name: "Oru West", parentSlug: "IMO" },
  {
    id: "60c358bf2079e2001e77e0ee",
    name: "Owerri Municipal",
    parentSlug: "IMO",
  },
  { id: "60c358bf2079e2001e77e0ef", name: "Owerri North", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0f0", name: "Owerri West", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0f1", name: "Unuimo", parentSlug: "IMO" },
  { id: "60c358bf2079e2001e77e0f2", name: "Babura", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0f3", name: "Biriniwa", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0f4", name: "Birnin Kudu", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0f5", name: "Buji", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0f6", name: "Dutse", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0f7", name: "Gagarawa", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0f8", name: "Garki", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0f9", name: "Gumel", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0fa", name: "Guri", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0fb", name: "Gwaram", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0fc", name: "Gwiwa", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0fd", name: "Hadejia", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0fe", name: "Jahun", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e0ff", name: "Kafin Hausa", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e100", name: "Kazaure", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e101", name: "Kiri Kasama", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e102", name: "Kiyawa", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e103", name: "Kaugama", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e104", name: "Maigatari", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e105", name: "Malam Madori", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e106", name: "Miga", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e107", name: "Ringim", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e108", name: "Roni", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e109", name: "Sule Tankarkar", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e10a", name: "Taura", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e10b", name: "Yankwashi", parentSlug: "JIG" },
  { id: "60c358bf2079e2001e77e10c", name: "Chikun", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e10d", name: "Giwa", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e10e", name: "Igabi", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e10f", name: "Ikara", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e110", name: "Jaba", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e111", name: "Jema'a", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e112", name: "Kachia", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e113", name: "Kaduna North", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e114", name: "Kaduna South", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e115", name: "Kagarko", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e116", name: "Kajuru", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e117", name: "Kaura", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e118", name: "Kauru", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e119", name: "Kubau", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e11a", name: "Kudan", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e11b", name: "Lere", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e11c", name: "Makarfi", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e11d", name: "Sabon Gari", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e11e", name: "Sanga", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e11f", name: "Soba", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e120", name: "Zangon Kataf", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e121", name: "Zaria", parentSlug: "KAD" },
  { id: "60c358bf2079e2001e77e122", name: "Albasu", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e123", name: "Bagwai", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e124", name: "Bebeji", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e125", name: "Bichi", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e126", name: "Bunkure", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e127", name: "Dala", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e128", name: "Dambatta", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e129", name: "Dawakin Kudu", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e12a", name: "Dawakin Tofa", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e12b", name: "Doguwa", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e12c", name: "Fagge", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e12d", name: "Gabasawa", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e12e", name: "Garko", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e12f", name: "Garun Mallam", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e130", name: "Gaya", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e131", name: "Gezawa", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e132", name: "Gwale", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e133", name: "Gwarzo", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e134", name: "Kabo", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e135", name: "Kano Municipal", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e136", name: "Karaye", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e137", name: "Kibiya", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e138", name: "Kiru", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e139", name: "Kumbotso", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e13a", name: "Kunchi", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e13b", name: "Kura", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e13c", name: "Madobi", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e13d", name: "Makoda", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e13e", name: "Minjibir", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e13f", name: "Nasarawa", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e140", name: "Rano", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e141", name: "Rimin Gado", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e142", name: "Rogo", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e143", name: "Shanono", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e144", name: "Sumaila", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e145", name: "Takai", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e146", name: "Tarauni", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e147", name: "Tofa", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e148", name: "Tsanyawa", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e149", name: "Tudun Wada", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e14a", name: "Ungogo", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e14b", name: "Warawa", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e14c", name: "Wudil", parentSlug: "KAN" },
  { id: "60c358bf2079e2001e77e14d", name: "Batagarawa", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e14e", name: "Batsari", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e14f", name: "Baure", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e150", name: "Bindawa", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e151", name: "Charanchi", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e152", name: "Dandume", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e153", name: "Danja", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e154", name: "Dan Musa", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e155", name: "Daura", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e156", name: "Dutsi", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e157", name: "Dutsin Ma", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e158", name: "Faskari", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e159", name: "Funtua", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e15a", name: "Ingawa", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e15b", name: "Jibia", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e15c", name: "Kafur", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e15d", name: "Kaita", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e15e", name: "Kankara", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e15f", name: "Kankia", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e160", name: "Katsina", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e161", name: "Kurfi", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e162", name: "Kusada", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e163", name: "Mai'Adua", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e164", name: "Malumfashi", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e165", name: "Mani", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e166", name: "Mashi", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e167", name: "Matazu", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e168", name: "Musawa", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e169", name: "Rimi", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e16a", name: "Sabuwa", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e16b", name: "Safana", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e16c", name: "Sandamu", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e16d", name: "Zango", parentSlug: "KAT" },
  { id: "60c358bf2079e2001e77e16e", name: "Arewa Dandi", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e16f", name: "Argungu", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e170", name: "Augie", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e171", name: "Bagudo", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e172", name: "Birnin Kebbi", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e173", name: "Bunza", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e174", name: "Dandi", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e175", name: "Fakai", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e176", name: "Gwandu", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e177", name: "Jega", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e178", name: "Kalgo", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e179", name: "Koko/Besse", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e17a", name: "Maiyama", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e17b", name: "Ngaski", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e17c", name: "Sakaba", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e17d", name: "Shanga", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e17e", name: "Suru", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e17f", name: "Wasagu/Danko", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e180", name: "Yauri", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e181", name: "Zuru", parentSlug: "KEB" },
  { id: "60c358bf2079e2001e77e182", name: "Ajaokuta", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e183", name: "Ankpa", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e184", name: "Bassa", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e185", name: "Dekina", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e186", name: "Ibaji", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e187", name: "Idah", parentSlug: "KOG" },
  {
    id: "60c358bf2079e2001e77e188",
    name: "Igalamela Odolu",
    parentSlug: "KOG",
  },
  { id: "60c358bf2079e2001e77e189", name: "Ijumu", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e18a", name: "Kabba/Bunu", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e18b", name: "Kogi", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e18c", name: "Lokoja", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e18d", name: "Mopa Muro", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e18e", name: "Ofu", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e18f", name: "Ogori/Magongo", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e190", name: "Okehi", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e191", name: "Okene", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e192", name: "Olamaboro", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e193", name: "Omala", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e194", name: "Yagba East", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e195", name: "Yagba West", parentSlug: "KOG" },
  { id: "60c358bf2079e2001e77e196", name: "Baruten", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e197", name: "Edu", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e198", name: "Ekiti", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e199", name: "Ifelodun", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e19a", name: "Ilorin East", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e19b", name: "Ilorin South", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e19c", name: "Ilorin West", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e19d", name: "Irepodun", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e19e", name: "Isin", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e19f", name: "Kaiama", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e1a0", name: "Moro", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e1a1", name: "Offa", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e1a2", name: "Oke Ero", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e1a3", name: "Oyun", parentSlug: "KWA" },
  { id: "60c358bf2079e2001e77e1a4", name: "Pategi", parentSlug: "KWA" },
  {
    id: "60c358bf2079e2001e77e1a5",
    name: "Ajeromi-Ifelodun",
    parentSlug: "LAG",
  },
  { id: "60c358bf2079e2001e77e1a6", name: "Alimosho", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1a7", name: "Amuwo-Odofin", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1a8", name: "Apapa", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1a9", name: "Badagry", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1aa", name: "Epe", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1ab", name: "Eti Osa", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1ac", name: "Ibeju-Lekki", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1ad", name: "Ifako-Ijaiye", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1ae", name: "Ikeja", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1af", name: "Ikorodu", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b0", name: "Kosofe", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b1", name: "Lagos Island", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b2", name: "Lagos Mainland", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b3", name: "Mushin", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b4", name: "Ojo", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b5", name: "Oshodi-Isolo", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b6", name: "Shomolu", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b7", name: "Surulere", parentSlug: "LAG" },
  { id: "60c358bf2079e2001e77e1b8", name: "Awe", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1b9", name: "Doma", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1ba", name: "Karu", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1bb", name: "Keana", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1bc", name: "Keffi", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1bd", name: "Kokona", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1be", name: "Lafia", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1bf", name: "Nasarawa", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1c0", name: "Nasarawa Egon", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1c1", name: "Obi", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1c2", name: "Toto", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1c3", name: "Wamba", parentSlug: "NAS" },
  { id: "60c358bf2079e2001e77e1c4", name: "Agwara", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1c5", name: "Bida", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1c6", name: "Borgu", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1c7", name: "Bosso", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1c8", name: "Chanchaga", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1c9", name: "Edati", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1ca", name: "Gbako", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1cb", name: "Gurara", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1cc", name: "Katcha", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1cd", name: "Kontagora", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1ce", name: "Lapai", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1cf", name: "Lavun", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d0", name: "Magama", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d1", name: "Mariga", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d2", name: "Mashegu", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d3", name: "Mokwa", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d4", name: "Moya", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d5", name: "Paikoro", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d6", name: "Rafi", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d7", name: "Rijau", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d8", name: "Shiroro", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1d9", name: "Suleja", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1da", name: "Tafa", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1db", name: "Wushishi", parentSlug: "NIG" },
  { id: "60c358bf2079e2001e77e1dc", name: "Abeokuta South", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1dd", name: "Ado-Odo/Ota", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1de", name: "Egbado North", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1df", name: "Egbado South", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1e0", name: "Ewekoro", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1e1", name: "Ifo", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1e2", name: "Ijebu East", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1e3", name: "Ijebu North", parentSlug: "OGU" },
  {
    id: "60c358bf2079e2001e77e1e4",
    name: "Ijebu North East",
    parentSlug: "OGU",
  },
  { id: "60c358bf2079e2001e77e1e5", name: "Ijebu Ode", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1e6", name: "Ikenne", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1e7", name: "Imeko Afon", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1e8", name: "Ipokia", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1e9", name: "Obafemi Owode", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1ea", name: "Odeda", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1eb", name: "Odogbolu", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1ec", name: "Ogun Waterside", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1ed", name: "Remo North", parentSlug: "OGU" },
  { id: "60c358bf2079e2001e77e1ee", name: "Shagamu", parentSlug: "OGU" },
  {
    id: "60c358bf2079e2001e77e1ef",
    name: "Akoko North-West",
    parentSlug: "OND",
  },
  {
    id: "60c358bf2079e2001e77e1f0",
    name: "Akoko South-West",
    parentSlug: "OND",
  },
  {
    id: "60c358bf2079e2001e77e1f1",
    name: "Akoko South-East",
    parentSlug: "OND",
  },
  { id: "60c358bf2079e2001e77e1f2", name: "Akure North", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1f3", name: "Akure South", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1f4", name: "Ese Odo", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1f5", name: "Idanre", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1f6", name: "Ifedore", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1f7", name: "Ilaje", parentSlug: "OND" },
  {
    id: "60c358bf2079e2001e77e1f8",
    name: "Ile Oluji/Okeigbo",
    parentSlug: "OND",
  },
  { id: "60c358bf2079e2001e77e1f9", name: "Irele", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1fa", name: "Odigbo", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1fb", name: "Okitipupa", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1fc", name: "Ondo East", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1fd", name: "Ondo West", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1fe", name: "Ose", parentSlug: "OND" },
  { id: "60c358bf2079e2001e77e1ff", name: "Owo", parentSlug: "OND" },
  {
    id: "60c358bf2079e2001e77e200",
    name: "Atakunmosa West",
    parentSlug: "OSU",
  },
  { id: "60c358bf2079e2001e77e201", name: "Aiyedaade", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e202", name: "Aiyedire", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e203", name: "Boluwaduro", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e204", name: "Boripe", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e205", name: "Ede North", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e206", name: "Ede South", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e207", name: "Ife Central", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e208", name: "Ife East", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e209", name: "Ife North", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e20a", name: "Ife South", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e20b", name: "Egbedore", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e20c", name: "Ejigbo", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e20d", name: "Ifedayo", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e20e", name: "Ifelodun", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e20f", name: "Ila", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e210", name: "Ilesa East", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e211", name: "Ilesa West", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e212", name: "Irepodun", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e213", name: "Irewole", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e214", name: "Isokan", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e215", name: "Iwo", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e216", name: "Obokun", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e217", name: "Odo Otin", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e218", name: "Ola Oluwa", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e219", name: "Olorunda", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e21a", name: "Oriade", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e21b", name: "Orolu", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e21c", name: "Osogbo", parentSlug: "OSU" },
  { id: "60c358bf2079e2001e77e21d", name: "Akinyele", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e21e", name: "Atiba", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e21f", name: "Atisbo", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e220", name: "Egbeda", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e221", name: "Ibadan North", parentSlug: "OYO" },
  {
    id: "60c358bf2079e2001e77e222",
    name: "Ibadan North-East",
    parentSlug: "OYO",
  },
  {
    id: "60c358bf2079e2001e77e223",
    name: "Ibadan North-West",
    parentSlug: "OYO",
  },
  {
    id: "60c358bf2079e2001e77e224",
    name: "Ibadan South-East",
    parentSlug: "OYO",
  },
  {
    id: "60c358bf2079e2001e77e225",
    name: "Ibadan South-West",
    parentSlug: "OYO",
  },
  {
    id: "60c358bf2079e2001e77e226",
    name: "Ibarapa Central",
    parentSlug: "OYO",
  },
  { id: "60c358bf2079e2001e77e227", name: "Ibarapa East", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e228", name: "Ibarapa North", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e229", name: "Ido", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e22a", name: "Irepo", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e22b", name: "Iseyin", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e22c", name: "Itesiwaju", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e22d", name: "Iwajowa", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e22e", name: "Kajola", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e22f", name: "Lagelu", parentSlug: "OYO" },
  {
    id: "60c358bf2079e2001e77e230",
    name: "Ogbomosho North",
    parentSlug: "OYO",
  },
  {
    id: "60c358bf2079e2001e77e231",
    name: "Ogbomosho South",
    parentSlug: "OYO",
  },
  { id: "60c358bf2079e2001e77e232", name: "Ogo Oluwa", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e233", name: "Olorunsogo", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e234", name: "Oluyole", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e235", name: "Ona Ara", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e236", name: "Orelope", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e237", name: "Ori Ire", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e238", name: "Oyo", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e239", name: "Oyo East", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e23a", name: "Saki East", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e23b", name: "Saki West", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e23c", name: "Surulere", parentSlug: "OYO" },
  { id: "60c358bf2079e2001e77e23d", name: "Barkin Ladi", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e23e", name: "Bassa", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e23f", name: "Jos East", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e240", name: "Jos North", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e241", name: "Jos South", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e242", name: "Kanam", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e243", name: "Kanke", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e244", name: "Langtang South", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e245", name: "Langtang North", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e246", name: "Mangu", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e247", name: "Mikang", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e248", name: "Pankshin", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e249", name: "Qua'an Pan", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e24a", name: "Riyom", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e24b", name: "Shendam", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e24c", name: "Wase", parentSlug: "PLA" },
  { id: "60c358bf2079e2001e77e24d", name: "Ahoada East", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e24e", name: "Ahoada West", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e24f", name: "Akuku-Toru", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e250", name: "Andoni", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e251", name: "Asari-Toru", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e252", name: "Bonny", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e253", name: "Degema", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e254", name: "Eleme", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e255", name: "Emuoha", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e256", name: "Etche", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e257", name: "Gokana", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e258", name: "Ikwerre", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e259", name: "Khana", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e25a", name: "Obio/Akpor", parentSlug: "RIV" },
  {
    id: "60c358bf2079e2001e77e25b",
    name: "Ogba/Egbema/Ndoni",
    parentSlug: "RIV",
  },
  { id: "60c358bf2079e2001e77e25c", name: "Ogu/Bolo", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e25d", name: "Okrika", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e25e", name: "Omuma", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e25f", name: "Opobo/Nkoro", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e260", name: "Oyigbo", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e261", name: "Port Harcourt", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e262", name: "Tai", parentSlug: "RIV" },
  { id: "60c358bf2079e2001e77e263", name: "Bodinga", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e264", name: "Dange Shuni", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e265", name: "Gada", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e266", name: "Goronyo", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e267", name: "Gudu", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e268", name: "Gwadabawa", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e269", name: "Illela", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e26a", name: "Isa", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e26b", name: "Kebbe", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e26c", name: "Kware", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e26d", name: "Rabah", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e26e", name: "Sabon Birni", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e26f", name: "Shagari", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e270", name: "Silame", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e271", name: "Sokoto North", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e272", name: "Sokoto South", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e273", name: "Tambuwal", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e274", name: "Tangaza", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e275", name: "Tureta", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e276", name: "Wamako", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e277", name: "Wurno", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e278", name: "Yabo", parentSlug: "SOK" },
  { id: "60c358bf2079e2001e77e279", name: "Bali", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e27a", name: "Donga", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e27b", name: "Gashaka", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e27c", name: "Gassol", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e27d", name: "Ibi", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e27e", name: "Jalingo", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e27f", name: "Karim Lamido", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e280", name: "Kumi", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e281", name: "Lau", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e282", name: "Sardauna", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e283", name: "Takum", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e284", name: "Ussa", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e285", name: "Wukari", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e286", name: "Yorro", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e287", name: "Zing", parentSlug: "TAR" },
  { id: "60c358bf2079e2001e77e288", name: "Bursari", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e289", name: "Damaturu", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e28a", name: "Fika", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e28b", name: "Fune", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e28c", name: "Geidam", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e28d", name: "Gujba", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e28e", name: "Gulani", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e28f", name: "Jakusko", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e290", name: "Karasuwa", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e291", name: "Machina", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e292", name: "Nangere", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e293", name: "Nguru", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e294", name: "Potiskum", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e295", name: "Tarmuwa", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e296", name: "Yunusari", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e297", name: "Yusufari", parentSlug: "YOB" },
  { id: "60c358bf2079e2001e77e298", name: "Bakura", parentSlug: "ZAM" },
  {
    id: "60c358bf2079e2001e77e299",
    name: "Birnin Magaji/Kiyaw",
    parentSlug: "ZAM",
  },
  { id: "60c358bf2079e2001e77e29a", name: "Bukkuyum", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e29b", name: "Bungudu", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e29c", name: "Gummi", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e29d", name: "Gusau", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e29e", name: "Kaura Namoda", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e29f", name: "Maradun", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e2a0", name: "Maru", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e2a1", name: "Shinkafi", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e2a2", name: "Talata Mafara", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e2a3", name: "Chafe", parentSlug: "ZAM" },
  { id: "60c358bf2079e2001e77e2a4", name: "Zurmi", parentSlug: "ZAM" },
  { id: "61a8d8b8fd1d5500342eda58", name: "Sokoto", parentSlug: "SKO" },
];
