import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  revenueStatistics: [],
});

const getters = {
  getRevenueStatistics: (state) => {
    return state.priceAtomSettingsList;
  },


};

const mutations = {
  setRevenueStatistics(state, payload) {
    state.getRevenueStatistics = payload;
  },

};

const actions = {
  async fetchRevenueStatistics({ commit }) {
    try {
      const response = await APIConfig.get(`/revenues`);
      commit("setRevenueStatistics", response.data);
      return response.data[0];
    } catch (error) {
      return error.response.data;
    }
  },

  async updateRevenueStatistics({ commit }, payload) {
    console.log("The payload" );
    console.log({ payload });
    try {
      const response = await APIConfig.patch(`/revenues/${payload.id}`, payload);
      console.log({ response });
      commit("setRevenueStatistics", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },


};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
