import { IdConfig2 } from "../../config/idConfig";

const formatRolesData = (data) => {
  return data.map((el) => {
    return {
      id: el.id,
      name: el.name,
    };
  });
};

const state = () => ({
  roles: [],
  rolesHash: {},
  errors: null,
});

const getters = {
  getUserRoles: (state) => state.roles,
  geRolesHash: (state) => state.rolesHash,
  getErrors: (state) => state.errors,
};

const mutations = {
  setErrors(state, errors) {
    state.errors = errors;
  },

  setRoleHash(state, hash) {
    state.rolesHash = hash;
  },
};

const actions = {
  async getUserRoles({ commit }) {
    try {
      let { data } = await IdConfig2.get("user-types");

      data = formatRolesData(data);
      
      let hash = {};

      data.forEach((el) => {
        if (!hash[el]) {
          hash = {
            ...hash,
            [el.name]: el.id,
          };
        }
      });

      commit("setRoleHash", hash);

      return data;
    } catch (error) {
      console.log(error.message);
      commit("setErrors", error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
