<template>
  <div>
    <div class="tab-buttons">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        @click="activeTabIndex = index"
        :class="{ active: activeTabIndex === index }"
      >
        {{ tab.label }}
      </div>
    </div>
    <div class="tab-content">
      <slot :name="tabs[activeTabIndex].slotName"></slot>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Tabs",
  props: {
    tabs: Array,
  },
  data() {
    return {
      activeTabIndex: 0,
    };
  },
};
</script>
  
  <style scoped>
.tab-buttons {
  display: flex;
}
.tab-buttons div {
  color: #222a34;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
}
.tab-buttons div.active {
  color: #fff;
  background: #222a34;
}
.tab-content {
  margin-top: 0px;
  padding: 20px;
}
</style>
  