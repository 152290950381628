<template>
  <div class="dashboard-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <div class="dashboard-container" v-if="getUserType !== 'FactoryCustomer'">
      <section class="dashboard-metric">
        <div style="margin: 0 auto; width: 100%; padding: 30px">
          <div class="metric-header">
            <p class="metric-caption">Salt Metric</p>

            <div class="image-metric" @click="toggleSection">
              <img
                src="../../assets/arrow-left.png"
                class="metric-img"
                alt="metric-dropdown"
              />
            </div>
          </div>

          <div class="border"></div>

          <div class="metric-data" v-if="isSectionMetricOpened">
            <div class="metric-data-time-range">
              <div class="metric-toolbar">
                <span class="metric-data-caption">Range:</span>
                <div class="input-select">
                  <select
                    v-model="dateRange"
                    @change="changeDateAction($event)"
                  >
                    <option value="this_week">This Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="this_month">This Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="this_year">This Year</option>
                    <option value="last_year">Last year</option>
                  </select>
                </div>

                <div class="input">
                  <input
                    type="text"
                    name=""
                    id="date"
                    placeholder="Select Start Date"
                    onfocus="(this.type = 'date')"
                    v-model="startDate"
                    @change="fetchbyDateRange"
                  />
                </div>

                <div class="input">
                  <input
                    type="text"
                    name=""
                    id="date"
                    placeholder="Select End Date"
                    onfocus="(this.type = 'date')"
                    v-model="endDate"
                    @change="fetchbyDateRange"
                  />
                </div>
              </div>
            </div>
            <div class="metric-data-card">
              <router-link to="/dashboard/purchase-dash">
                <div class="card">
                  <div class="card-body">
                    <p class="card-caption">{{ purchVal }}</p>
                  </div>
                  <div class="card-target">
                    <p class="card-target-caption"></p>
                  </div>

                  <div class="card-footer">
                    <p class="card-footer-caption">Total Purchase</p>
                  </div>
                </div>
              </router-link>
              <router-view />

              <router-link
                :to="`/dashboard/inventory-dash/factory`"
                :class="{ active: activeNav === 'factory' }"
              >
                <div class="card">
                  <div class="card-body">
                    <p class="card-caption">{{ movedVal }}</p>
                  </div>
                  <div class="card-target">
                    <p class="card-target-caption"></p>
                  </div>

                  <div class="card-footer">
                    <p class="card-footer-caption">Total Moved</p>
                  </div>
                </div>
              </router-link>

              <router-link to="/dashboard/inventory-dash">
                <div class="card">
                  <div class="card-body">
                    <p class="card-caption">{{ inventoryVal }}</p>
                  </div>
                  <div class="card-target">
                    <p class="card-target-caption"></p>
                  </div>

                  <div class="card-footer">
                    <p class="card-footer-caption">Inventory</p>
                  </div>
                </div>
              </router-link>

              <router-link to="/dashboard/overhead-dash">
                <div class="card">
                  <div class="card-body">
                    <p class="card-caption">
                      {{ overheadVal }}
                    </p>
                  </div>
                  <div class="card-target">
                    <p class="card-target-caption"></p>
                  </div>

                  <div class="card-footer">
                    <p class="card-footer-caption">Overhead Cost</p>
                  </div>
                </div>
              </router-link>

              <router-link to="/dashboard/landed-dash">
                <div class="card">
                  <div class="card-body">
                    <p class="card-caption">
                      {{ totalLandedCostPerTon }}
                    </p>
                  </div>
                  <div class="card-target">
                    <p class="card-target-caption"></p>
                  </div>

                  <div class="card-footer">
                    <p class="card-footer-caption">Landed Cost</p>
                  </div>
                </div>
              </router-link>

              <!-- For Average Tonnes/Farmer -->
              <router-link to="/dashboard/aveTonFarmer-dash">
                <div class="card">
                  <div class="card-body">
                    <p class="card-caption">
                      {{ aveTonFarmer ? aveTonFarmer : 0 }}
                    </p>
                  </div>
                  <div class="card-target">
                    <p class="card-target-caption"></p>
                  </div>

                  <div class="card-footer">
                    <p class="card-footer-caption">Ave.Tonnes/Farmer</p>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="month-caption">
              <p class="month-caption-text">{{ title }}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="dashboard-chart">
        <div style="margin: 0 auto; width: 96%">
          <div class="border"></div>

          <div class="chart-wrapper" v-if="isSectionDataOpened">
            <div class="chart-wrapper-left">
              <div class="metric-caption-wrapper">
                <p class="metric-caption">P1 Data</p>
              </div>
              <div class="chart-main-wrapper">
                <div class="metric-data-time-range">
                  <div class="metric-toolbar">
                    <span class="metric-data-caption">Range:</span>
                    <div class="input-select">
                      <select
                        v-model="p1DateRange"
                        @change="changeP1DateAction($event)"
                      >
                        <option value="this_week">This Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="this_month">This Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last year</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="chart-data">
                  <LineCharts :series="purchSeriesData" />
                </div>
              </div>
            </div>

            <div class="chart-wrapper-right">
              <div class="metric-caption-wrapper">
                <p class="metric-caption">Overhead Cost</p>
              </div>
              <div class="chart-main-wrapper">
                <div class="chart-right-timerange">
                  <div class="timerange">
                    <span class="metric-data-caption">Range:</span>

                    <div class="input-select">
                      <select
                        v-model="overDateRange"
                        @change="changeOverheadDate($event)"
                      >
                        <option value="this_week">This Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="this_month">This Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last year</option>
                      </select>
                    </div>
                  </div>

                  <div class="location-range">
                    <div class="input-select">
                      <select v-model="dept" @change="changeDept($event)">
                        <option value="purchase">Purchase</option>
                        <option value="logistics">Logistics</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="chart">
                  <BarChart3
                    :series="
                      dept === 'purchase'
                        ? purchaseOverheadChart
                        : logisticsOverheadChart
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Factory Customer Dashboard View begins here -->
    <section
      class="factory-customer__container"
      v-if="getUserType === 'FactoryCustomer'"
    >
      <FactoryCustomerDashboard />
    </section>

    <div class="procurement-modal" v-if="isProcurementDetailsModalOpened">
      <ProcurementDetailsForm @onCloseModal="handleCloseModal" />
    </div>

    <!-- Overlay -->
    <div
      class="dark-overlay"
      v-if="isProcurementDetailsModalOpened"
      @click="closeOverlay"
    ></div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import LineCharts from "./charts/LineCharts.vue";
import BarChart3 from "./charts/Barchart3.vue";
import ProcurementDetailsForm from "./modals/ProcurementDetailsForm.vue";
import FactoryCustomerDashboard from "./FactoryCustomerDashboard.vue";
import { AuthUser } from "../../utils/authUser";
import { convertToFilterQueryString } from "../../utils";
import { formatNumber } from "../../utils/numbers-formatter";

export default {
  name: "Dashboard",
  data() {
    return {
      isSectionMetricOpened: true,
      isSectionDataOpened: true,
      isProcurementDetailsModalOpened: false,
      imageUp: "../../assets/arrow-left.png",
      imageDown: "../../assets/arrow-back.png",
      purchasedPalmNut: "Purchased Palm Nut",
      equivalentPalmKernel: "Equivalent Palm Kernel",
      avgReleafPalmKernelPrice: "Avg. Releaf Palm Kernel Price",
      activeMetricTab: "Purchased Palm Nut",
      activeNav: "factory",
      purchVal: 0,
      purchPercent: 0,
      purchTarget: 0,
      movedVal: 0,
      unmovedVal: 0,
      inventoryVal: 0,
      overheadVal: 0,
      totalLandedCostPerTon: 0,
      dateRange: "this_week",
      p1DateRange: "this_week",
      categories: "",
      purchSeriesData: "",
      p1DataChartOption: "",
      chartOptions1: {},
      startDate: "",
      endDate: "",
      targPurchase: 200,
      targMoved: 200,
      targUnmoved: 100,
      targInventory: 200,
      targOverhead: 6000,
      targLanded: 50000,
      purchaseOverheadChart: [],
      logisticsOverheadChart: [],
      overDateRange: "this_week",
      dept: "purchase",
      column1: [30, 40, 45, 50, 49, 60, 70, 91, 125, 150, 200, 300],
      column2: [50, 60, 65, 50, 49, 60, 70, 60, 30, 65, 30, 70],
      three: [100, 40, 45, 150, 249, 40, 50, 91, 125, 86, 50, 150],
      data: [30, 40, 45, 50, 49, 60, 70, 91, 125, 150, 200, 300],
      procurementDetails: {},
      loading: false,
      title: "",
      aveTonFarmer: "",
    };
  },
  components: {
    LineCharts,
    ProcurementDetailsForm,
    FactoryCustomerDashboard,
    BarChart3,
    Loading,
  },

  mounted() {
    this.fetchCustomerProcurementDetails();
    this.fetchOverviewSummary();
    this.fetchP1Data();
    this.fetchOverheadCostChartData();
  },

  computed: {
    imagePath() {
      if (this.isSectionMetricOpened) {
        return this.imageUp;
      }

      return this.imageDown;
    },

    procurementDetailsModalStatus() {
      return this.$store.getters["procurementDetails/getDetailModalStatus"];
    },

    getUserType() {
      const authUser = new AuthUser();
      const { userType } = authUser.getDetails();
      return userType?.name || "User";
    },
  },

  methods: {
    toggleSection() {
      this.isSectionMetricOpened = !this.isSectionMetricOpened;
    },

    toggleSectionData() {
      this.isSectionDataOpened = !this.isSectionDataOpened;
    },

    openProcurementDetailsModal() {
      this.isProcurementDetailsModalOpened =
        !this.isProcurementDetailsModalOpened;
    },

    handleCloseModal(status) {
      this.isProcurementDetailsModalOpened = status;
      this.$store.dispatch("procurementDetails/setDetailsModalStatus", status);
    },

    closeOverlay() {
      this.isProcurementDetailsModalOpened = false;
      this.$store.dispatch("procurementDetails/setDetailsModalStatus", false);
    },

    setActiveMetricTab(tab) {
      this.activeMetricTab = tab;
    },

    changeDateAction(event) {
      this.dateRange = event.target.value;
      this.fetchOverviewSummary(this.dateRange);
    },

    changeP1DateAction(event) {
      this.p1DateRange = event.target.value;
      this.fetchP1Data(this.p1DateRange);

      console.log("this.p1DateRange", this.p1DateRange);
      // this.fetchOverviewsByFarmerRange(this.p1DateRange);
    },

    changeOverheadDate(event) {
      this.overDateRange = event.target.value;

      this.fetchOverheadCostChartData(this.overDateRange);
    },

    changeDept(event) {
      this.dept = event.target.value;
    },

    async fetchCustomerProcurementDetails() {
      const data = await this.$store.dispatch(
        "procurementDetails/getCustomerProcurementDetails"
      );

      this.procurementDetails = data;
    },

    async fetchOverviewSummary(query = "this_week") {
      this.loading = true;

      const [firstResponse, secondResponse, thirdResponse] = await Promise.all([
        await this.$store.dispatch("overviews/getOverViewsListTeams", query),
        await this.$store.dispatch("overviews/getOverheadCost", query),
        await this.$store.dispatch(
          "overviews/fetchOverviewsByFarmerRangeDash",
          query
        ),
      ]);

      this.aveTonFarmer = thirdResponse.data.summary.averageTonsPerFarmer
        ? formatNumber(thirdResponse.data.summary.averageTonsPerFarmer) + `T`
        : 0;

      this.purchVal = firstResponse.purchaseTonnes
        ? formatNumber(firstResponse.purchaseTonnes) + `T`
        : 0;
      this.movedVal = firstResponse.movedInventories
        ? formatNumber(firstResponse.movedInventories) + `T`
        : 0;
      this.unmovedVal = firstResponse.unmovedInventories
        ? formatNumber(firstResponse.unmovedInventories) + `T`
        : 0;
      this.inventoryVal = firstResponse.inventoryTonnes
        ? formatNumber(firstResponse.inventoryTonnes) + `T`
        : 0;
      this.totalLandedCostPerTon = firstResponse.totalLandedCostPerTon
        ? `₦` + formatNumber(firstResponse.totalLandedCostPerTon)
        : 0;
      this.overheadVal = secondResponse.totalCost
        ? `₦` + formatNumber(secondResponse.totalCost) + "K"
        : 0;
      this.title = firstResponse.period + " Data Report";

      this.loading = false;
    },

    async fetchbyDateRange() {
      if (this.startDate === "" || this.endDate === "") return;
      else if (this.startDate !== "" && this.endDate !== "") {
        const query = convertToFilterQueryString({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        try {
          const data = await this.$store.dispatch(
            "overviews/getOverviewDateRange",
            query
          );
          this.purchVal = data.purchaseTonnes.toFixed(0);
          this.movedVal = data.movedInventories.toFixed(0);
          this.unmovedVal = data.unmovedInventories.toFixed(0);
          this.inventoryVal = data.inventoryTonnes.toFixed(0);

          const aveTonnesFarmer = await this.$store.dispatch(
            "overviews/fetchOverviewsByFarmerRange",
            query
          );
          this.aveTonFarmer =
            aveTonnesFarmer.summary.averageTonsPerFarmer.toFixed(0);
        } catch (error) {
          console.log(error);
        }
      }
    },

    async fetchP1Data(query = "this_week") {
      this.loading = true;
      const data = await this.$store.dispatch(
        "overviews/getOverviewP1Data",
        query
      );
      this.purchSeriesData = data;
      this.loading = false;
    },

    async fetchOverheadCostChartData(query = "this_week") {
      this.loading = true;
      const { purchaseAndLogistics } = await this.$store.dispatch(
        "overviews/getOverheadCostChartData",
        query
      );
      const purchaseOverheadCosts = purchaseAndLogistics.find(
        (group) => group.group === "purchase"
      ).series;
      const logisticsOverheadCosts = purchaseAndLogistics.find(
        (group) => group.group === "logistics"
      ).series;
      this.purchaseOverheadChart = purchaseOverheadCosts;
      this.logisticsOverheadChart = logisticsOverheadCosts;
      this.loading = false;
    },
  },

  watch: {
    activeMetricTab(newValue) {
      if (newValue === this.purchasedPalmNut) {
        this.data = this.one;
      } else if (newValue === this.equivalentPalmKernel) {
        this.data = this.two;
      } else {
        this.data = this.three;
      }
    },

    procurementDetailsModalStatus(newValue) {
      if (newValue) {
        this.isProcurementDetailsModalOpened = newValue;
      }
    },
  },
};
</script>

<style scoped>
.dashboard-parent {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  margin: 0 auto;
  background: #f9fafe;
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-caption {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
}

.image-metric {
  cursor: pointer;
}

.metric-data {
  height: 330px;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  background: white;
}

.metric-data-time-range {
  display: flex;
  align-items: center;
}

.metric-toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.metric-data-caption {
  color: #333;
  font-weight: 400;
  font-size: 14px;
}

.input,
.input-select {
  height: 30px;
  width: 110px;
}

select {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

input {
  width: 100%;
  height: 100%;
}

.metric-data-card {
  height: 190px;
  display: flex;
  /* grid-template-columns: repeat(6, 1fr); */
  justify-content: space-around;
  gap: 10px;
}

.card {
  max-width: 220px;
  min-width: 160px;
  height: 100%;
  background-image: url(../../assets/releaf-logo-white.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}

.card-body {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 100;
  padding-bottom: 0;
}

.card-body p,
.card-footer p {
  margin-bottom: 0;
}

.card-caption {
  font-weight: 800;
  font-size: 40px;
  color: #333;
}

.card-small-image {
  display: flex;
  justify-content: center;
}
.card-caption-small {
  font-weight: 400;
  font-size: 14px;
  color: #669933;
}

.card-target {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #373434;
}

.card-target-caption {
  font-size: 14px;
  font-weight: 300;
}

.card-target-caption b {
  font-weight: 600;
}

.card-footer {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
  border-top: 0.933845px solid #b3b3b3;
}

.card-footer-caption {
  font-size: 14px;
  color: #333;
  opacity: 0.5;
}

.image-footer {
  width: 0px;
  opacity: 0.5;
}

.month-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.month-caption-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.dashboard-chart {
  margin-top: 20px;
}

.chart-wrapper {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  gap: 10px;
}

.chart-wrapper-left,
.chart-wrapper-right {
  width: 48%;
  height: auto;
  /* background: #fff; */
}

.chart-right-timerange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.timerange {
  display: flex;
  gap: 10px;
  align-items: center;
}

.chart-main-wrapper {
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.4);
}

.location-range {
  display: flex;
  gap: 10px;
}

.chart-data {
  width: 100%;
  height: 400px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

.border {
  border: 1px solid #e7e7e7;
}

.procurement-modal {
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
  max-width: 680px;
  height: 500px;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

/* reset default styles */
a:hover {
  text-decoration: none;
}
</style>
