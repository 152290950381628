export const discoveryTableHeaders = [
  { text: "ID", value: "decline_id" },
  { text: "Discovery Date", value: "discovery_date" },
  { text: "CreatedBy", value: "createdBy" },
  { text: "Farmer Name", value: "farmer_name" },
  { text: "Mill", value: "mill_name" },
  { text: "Estimated Tonnage", value: "estimated_tonnage" },
  { text: "Channel", value: "channel" },
  { text: "Discovery Status", value: "discovery_status" },
  { text: "Approval Status", value: "isApproved" },
  { text: "Actions", value: "actions" },
];
