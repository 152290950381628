import { APIConfig } from "../../config/apiConfig";
import moment from "moment";

const formatData = (data) => {
  return data.map((cost) => ({
    _id: cost._id,
    vehicle: cost.vehicle?.name || "Nil",
    vehicleId: cost.vehicle?._id,
    vehicleName: cost.vehicle?.name || "Nil",
    maintenanceType: cost.maintenanceType,
    recommendation: cost.recommendation,
    partsNeeded: cost.partsNeeded,
    issueDescription: cost.issueDescription,
    costOfParts: cost.costOfParts,
    totalCostOfRepairs: cost.totalCostOfRepairs,
    timeOfRepair: cost.timeOfRepair,
    durationOfPurchase: cost.durationOfPurchase,
    dateOfMaintenance: moment(cost.dateOfMaintenance).format("MM/DD/yyyy"),
    dateOfIssue: moment(cost.dateOfIssue).format("MM/DD/yyyy"),
    dateAdded: moment(cost.createdAt).format("MM/DD/yyyy"),
    createdBy: cost.agentDetails.first_name + " " + cost.agentDetails.last_name,
  }));
};

const state = () => ({
  lmvRepairsCosts: [],
  costsToBeUpdated: {},
  inputtedCosts: {
    dateOfMaintenance: "",
    vehicle: "",
    dateOfIssue: "",
    issueDescription: "",
    maintenanceType: "",
    recommendation: "",
    costOfParts: 0,
    totalCostOfRepairs: 0,
    timeOfRepair: "",
    partsNeeded: [],
    tags: [],
    duration: "",
    durationOfPurchase: "",
    proratedDuration: "",
  },
  error: null,
});

const getters = {
  getInputtedCosts: (state) => {
    return state.inputtedCosts;
  },

  getCostsToBeUpdated: (state) => state.costsToBeUpdated,
};

const mutations = {
  populateLMVRepairCosts(state, lmvRepairsCosts) {
    state.lmvRepairsCosts = lmvRepairsCosts;
  },

  setInputtedCosts(state, costs) {
    state.inputtedCosts = costs;
  },

  setCostsToBeUpdated(state, costs) {
    state.costsToBeUpdated = costs;
  },

  setValuesForMaintenanceUpdate(state, costs) {
    state.costsToBeUpdated = {
      ...state.costsToBeUpdated,
      ...costs,
    };
  },

  resetForm(state) {
    state.inputtedCosts = {
      dateOfMaintenance: "",
      vehicle: "",
      dateOfIssue: "",
      issueDescription: "",
      maintenanceType: "",
      recommendation: "",
      costOfParts: 0,
      totalCostOfRepairs: 0,
      timeOfRepair: "",
      partsNeeded: [],
      tags: [],
      duration: "",
      durationOfPurchase: "",
      proratedDuration: "",
    };
  },
};

const actions = {
  findCostToBeUpdated({ commit, state }, costId) {
    let costs = state.lmvRepairsCosts.find((cost) => cost._id === costId);

    costs = {
      ...costs,
      vehicle: costs.vehicleId,
      tags: [...costs.partsNeeded],
      timeOfRepair: costs.timeOfRepair.split(" ")[0],
      duration: costs.timeOfRepair.split(" ")[1],
      durationOfPurchase: costs.durationOfPurchase?.split(" ")[0],
      proratedDuration: costs.durationOfPurchase?.split(" ")[1],
    };

    commit("setCostsToBeUpdated", costs);
  },

  setValuesForMaintenanceUpdate({ commit }, costs) {
    commit("setValuesForMaintenanceUpdate", costs);
  },

  setInputtedCosts({ commit }, dispatch) {
    commit("setInputtedCosts", dispatch);
  },

  async fetchLmvRepairsAndMaintenanceCosts({ commit }, params) {
    const { page, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/lmv-repairs/?page=${page}&limit=${limit}`
      );

      const lmvRepairsCosts = data?.data ? formatData(data?.data) : [];

      commit("populateLMVRepairCosts", lmvRepairsCosts);

      return {
        count: data?.count,
        cost: lmvRepairsCosts,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async addNewCosts({ commit }, payload) {
    try {
      const { data } = await APIConfig.post(`/lmv-repairs`, payload);
      commit("resetForm");
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async filterByDatePeriod({ commit }, params) {
    const { page, limit, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `/lmv-repairs/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      if (Object.keys(data).length > 0) {
        const lmvRepairsCosts = formatData(data?.data);

        commit("populateLMVRepairCosts", lmvRepairsCosts);

        return {
          count: data?.count,
          cost: lmvRepairsCosts,
        };
      } else {
        return {
          count: 0,
          cost: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async createTarget(_, payload) {
    try {
      const { data } = await APIConfig.post(`/lmv-repairs/target`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateCosts({ commit }, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/lmv-repairs/${payload.id}`,
        payload
      );
      commit("resetForm");
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteCosts(_, id) {
    try {
      const { data } = await APIConfig.delete(`/lmv-repairs/${id}`);

      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
