<template>
  <div class="login">
    <div class="login-box">
      <div class="login-container">
        <form class="login-form" @submit="SendEmail">
          <h1 class="login-heading">Reset Password</h1>
          <p>
            Input your email address below to start initiating your password
            reset.
          </p>
          <fieldset>
            <legend>Email</legend>
            <div class="input">
              <i class="fa fa-envelope"></i>
              <input
                type="email"
                class="form-control"
                v-model="email"
                id="email"
                placeholder="Email"
              />
            </div>
          </fieldset>

          <a href="/" class="btn-link"><p>Back</p></a>

          <div class="">
            <ul style="list-style: none">
              <li
                style="color: #cf6363; font-size: 1.2rem"
                v-for="(error, index) in errors"
                :key="index"
              >
                {{ error }}
              </li>
            </ul>
          </div>

          <button
            type="submit"
            :class="isFormEmpty ? 'disabled' : 'btn'"
            :disabled="isFormEmpty"
          >
            Submit
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import { validateLoginInput } from "../utils";
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      errors: [],
      showPassword: false,
    };
  },

  computed: {
    isFormEmpty() {
      const isEmpty = Boolean(this.email);
      return !isEmpty;
    },
  },

  methods: {
    async SendEmail(e) {
      e.preventDefault();
      // function to send email for password reset method from store
    },

    togglePasswordState() {
      this.showPassword = !this.showPassword;
      console.log(this.showPassword);
    },
  },
};
</script>

<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/mask.png");
  background-color: rgba(102, 153, 51, 0.927);
  width: 100vw;
  height: 100vh;
}

.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 290px;
  background: #ffff;
  border-radius: 8px;
}

.login-form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.4rem;
}

.login-heading {
  font-weight: 700;
  font-size: 2rem;
  color: #232a34;
}

.form-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control {
  border: none;
  outline: none;
  width: 100%;
  outline-color: none;
  background-color: none;
}
fieldset {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1.5px solid #73a238;
}
.input {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.9rem;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background: #73a238;
  border: none;
  border-radius: 50px;
  color: #fff;
  margin-top: -25px;
  margin-bottom: 70px;
}

.disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background: rgba(102, 153, 51, 0.4);
  border: none;
  border-radius: 50px;
  color: #fff;
  margin-top: -25px;
  margin-bottom: 70px;
}

.btn-link {
  font-size: 0.9rem;
  color: black;
  margin-left: 9.6rem;
  text-decoration: none;
}
</style>
