<template>
  <div class="modal-parent">
    <Loading v-model:active="loading" :can-cancel="true" :is-full-page="true" />
    <section class="headers">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Update Commitment</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <!-- edit section -->
    <div class="edit-body-commitment">
      <div class="edit-cards">
        <div class="cards">
          <p>Year</p>
          <span>{{ edit.year }}</span>
        </div>
        <div class="cards both">
          <p>Supplied</p>
          <span>{{ edit.supplied }}</span>
        </div>
        <div class="cards">
          <p>% of filled commitment</p>
          <span>{{ edit.percentSupplied }}</span>
        </div>
      </div>

      <div class="edit-input">
        <small>Amount Commited</small>
        <input type="text" v-model="edit.commitment" />
      </div>
      <div class="edit-input">
        <small>Farmer's Comment</small>
        <input type="text" v-model="edit.comment" />
      </div>
    </div>

    <div class="edit-footer-commitment">
      <div @click="handleSubmitCommitmentUpdate" class="btn-commitment">
        Update commitment
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
export default {
  name: "SaltClientAddCommitmentModal",

  data() {
    return {
      edit: {},
    };
  },

  props: {
    farmerId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.handleUpdatedFarmerCommitment();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
      window.location.reload();
    },
    handleUpdatedFarmerCommitment() {
      const editValue =
        this.$store.getters["overviews/getUpdateCommitmentInfo"];
      this.edit = editValue.editInfo;
    },

    async handleSubmitCommitmentUpdate() {
      this.loading = true;
      try {
        const updateEdit = {
          amount: this.edit.commitment,
          farmerLastComment: this.edit.comment,
          _id: this.edit.commitmentId,
        };

        await this.$store.dispatch(
          "farmers/updateFarmersCommitment",
          updateEdit
        );
        this.closeModal();

        createToast("Commitment Updated Successfully", {
          type: "success",
          position: "top-right",
          timeout: 3000,
          onClose: () => {
            window.location.reload();
          },
        });
        this.loading = false;
      } catch (error) {
        console.log(error.message);
      }
    },
  },

  computed: {},

  watch: {},
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  width: 500px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.delete-action-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  padding: 20px 0px;
}

p {
  font-size: 1.2rem;
  font-weight: 500;
  color: #232a34;
}

h5 {
  font-size: 17px;
  margin: 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-bottom: 40px;
}

.edit-commitment {
  position: fixed;
  top: 130%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: blue;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.edit-input {
  display: block;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 0px 20px;
}

.edit-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  border: #ccc 1px solid;
  height: 60px;
  margin: 0px 20px;
}
.cards {
  text-align: center;
  padding: 5px 25px;
  height: 40px;
}
.cards p {
  font-size: 12px;
  margin: 0px;
  display: block;
}
.cards span {
  margin: 0px;
  display: block;
  font-weight: bold;
  color: #282828;
}
.all {
  border-left: #ccc 1px solid;
}
.both {
  border-left: #ccc 1px solid;
  border-right: #ccc 1px solid;
}

input[type="text"] {
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 100%;
}
input[type="number"] {
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 100%;
}
input[type="text"]:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  outline: none;
}
input[type="number"]:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  outline: none;
}

.edit-input input[type="text"] {
  padding: 5px;
  border: 1px solid #353535;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}
.edit-input input[type="text"]:focus {
  border-color: #669933;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  outline: none;
}

.btn-commitment {
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #669933;
  width: 200px;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
}

.edit-footer-commitment {
  padding: 20px 0px;
}

.closeX {
  cursor: pointer;
}

.no-data {
  margin-top: 40px;
  padding: 20px;
  display: grid;
  place-items: center;
}

.enrol-farmer-img {
  width: 32px;
}
.enrol-farmer-p {
  color: #121212;
  margin: 20px 0;
  font-size: 12px;
}
.enrol-farmer-btn {
  color: #669933;
  font-size: 12px;
  padding: 10px 25px;
  background: #fff;
  border: #669933 1px solid;
  border-radius: 30px;
  cursor: pointer;
}

#data-table {
  border-collapse: collapse;
  width: 80%;
  margin: 20px auto;
}

#data-table th {
  border: 1px solid #ccc;
  text-align: center;
  height: 40px;
}
#data-table td {
  border: 1px solid #ccc;
  text-align: center;
}

#data-table th {
  background-color: #f0f0f0;
}
.trash-can {
  cursor: pointer;
}

select {
  padding: 8px;
  border: 1px solid rgba(64, 14, 172, 0);
  border-radius: 5px;
  background-color: #fff;
  font-size: 10px;
}
select:focus {
  outline: none;
  border-color: #ccc;
  box-shadow: 0px 2px 4px rgba(0, 123, 255, 0);
}

#add-row-button {
  width: 80px;
  color: #669933;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  left: 390px;
}
</style>