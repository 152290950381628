export const states = [
  {
    _id: "60c358bf2079e2001e77dfa7",
    parent_slug: "NIG",
    name: "Ebonyi",
    slug: "EBO",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975418",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676442571",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfad",

    parent_slug: "NIG",
    name: "Imo",
    slug: "IMO",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975419",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676452642",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfbb",
    parent_slug: "NIG",
    name: "Oyo",
    slug: "OYO",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975422",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676563023",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa9",
    parent_slug: "NIG",
    name: "Ekiti",
    slug: "EKI",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975419",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676445968",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77df9d",
    parent_slug: "NIG",
    name: "Abia",
    slug: "ABI",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975416",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676383026",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb5",
    parent_slug: "NIG",
    name: "Lagos",
    slug: "LAG",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975421",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676508565",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc0",
    parent_slug: "NIG",
    name: "Yobe",
    slug: "YOB",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975423",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676570693",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa2",
    parent_slug: "NIG",
    name: "Bayelsa",
    slug: "BAY",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975418",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676391044",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfaa",
    parent_slug: "NIG",
    name: "Enugu",
    slug: "ENU",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975419",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676447717",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb1",
    parent_slug: "NIG",
    name: "Katsina",
    slug: "KAT",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975420",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676502399",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfba",
    parent_slug: "NIG",
    name: "Osun",
    slug: "OSU",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975422",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676515850",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfbe",
    parent_slug: "NIG",
    name: "Sokoto",
    slug: "SKO",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975422",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676567650",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb8",
    parent_slug: "NIG",
    name: "Ogun",
    slug: "OGU",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975421",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676513218",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb9",
    parent_slug: "NIG",
    name: "Ondo",
    slug: "OND",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975421",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676514488",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa5",
    parent_slug: "NIG",
    name: "Cross River",
    slug: "CRO",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975418",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676396260",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77df9e",
    parent_slug: "NIG",
    name: "Adamawa",
    slug: "ADA",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975417",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676384632",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa4",
    parent_slug: "NIG",
    name: "Borno",
    slug: "BOR",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975418",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676394696",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa0",
    parent_slug: "NIG",
    name: "Anambra",
    slug: "ANA",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975417",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676387938",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa6",
    parent_slug: "NIG",
    name: "Delta",
    slug: "DEL",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975418",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676397828",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb2",
    parent_slug: "NIG",
    name: "Kebbi",
    slug: "KEB",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975420",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676503852",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfbc",
    parent_slug: "NIG",
    name: "Plateau",
    slug: "PLA",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975422",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676564581",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfac",
    parent_slug: "NIG",
    name: "Gombe",
    slug: "GOM",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975419",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676451025",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb3",
    parent_slug: "NIG",
    name: "Kogi",
    slug: "KOG",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975420",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676505438",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfbd",
    parent_slug: "NIG",
    name: "Rivers",
    slug: "RIV",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975422",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676566136",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfbf",
    parent_slug: "NIG",
    name: "Taraba",
    slug: "TAR",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975422",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676569127",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfaf",
    parent_slug: "NIG",
    name: "Kaduna",
    slug: "KAD",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975420",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676456459",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb0",
    parent_slug: "NIG",
    name: "Kano",
    slug: "KAN",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975420",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676458648",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa8",
    parent_slug: "NIG",
    name: "Edo",
    slug: "EDO",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975419",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676444178",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfc1",
    parent_slug: "NIG",
    name: "Zamfara",
    slug: "ZAM",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975423",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676572181",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77df9f",
    parent_slug: "NIG",
    name: "Akwa Ibom",
    slug: "AKW",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975417",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676386280",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa1",
    parent_slug: "NIG",
    name: "Bauchi",
    slug: "BAU",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975417",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676389514",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb7",
    parent_slug: "NIG",
    name: "Niger",
    slug: "NIG",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975421",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676511628",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfab",
    parent_slug: "NIG",
    name: "FCT",
    slug: "FCT",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975419",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676449430",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb6",
    parent_slug: "NIG",
    name: "Nasarawa",
    slug: "NAS",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975421",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676510010",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfa3",
    parent_slug: "NIG",
    name: "Benue",
    slug: "BEN",
    version: 3,
    createdAt: {
      $date: {
        $numberLong: "1623414975418",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676393143",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfae",
    parent_slug: "NIG",
    name: "Jigawa",
    slug: "JIG",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975420",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676454281",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "60c358bf2079e2001e77dfb4",
    parent_slug: "NIG",
    name: "Kwara",
    slug: "KWA",
    version: 2,
    createdAt: {
      $date: {
        $numberLong: "1623414975421",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1632676506959",
      },
    },
    added_to_sheet: true,
  },
  {
    _id: "61a5d696fd1d5500342d83a8",
    added_to_sheet: true,
    name: "Sokoto",
    slug: "SOK",
    parent_slug: "SKO",
    createdAt: {
      $date: {
        $numberLong: "1638258326111",
      },
    },
    updatedAt: {
      $date: {
        $numberLong: "1638258328458",
      },
    },
    version: 1,
  },
];
