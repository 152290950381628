<template>
  <div class="discovery-parent-modal">
    <!-- <h4>Discovery Modal</h4> -->
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="modal-header">
      <div style="margin: 0 auto; width: 96%">
        <div class="modal-header-content">
          <h5 class="modal-header-caption">Discovery Details</h5>

          <div class="logo-container">
            <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
          </div>

          <div class="icons-container">
            <div style="cursor: pointer">
              <img
                src="../../../assets/edit-icon.png"
                @click="openEditModal"
                alt="edit-icon"
              />
            </div>

            <div style="cursor: pointer" @click="openDeleteModal">
              <img src="../../../assets/delete-icon.png" alt="delete-icon" />
            </div>

            <div class="x-container" @click="closeModal">
              <img src="../../../assets/x-icon.png" alt="x-icon" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="discovery-details" style="padding: 1rem">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="details">
          <div class="details_section">
            <InfoBox
              header="Farmer's Name"
              :info="discovery.farmer_name"
              class="div1"
            />

            <InfoBox
              header="Estimated Tonnage"
              :info="discovery.estimated_tonnage"
              class="div2"
            />

            <InfoBox header="Mill" :info="discovery.mill_name" class="div3" />

            <InfoBox
              header="Community"
              :info="discovery.community"
              class="div4"
            />

            <InfoBox
              header="CreatedBy"
              :info="discovery.createdBy"
              class="div5"
            />

            <InfoBox
              header="Discovery Date"
              :info="discovery.discovery_date"
              class="div6"
            />
            <InfoBox
              header="Logistics Cost"
              :info="vueNumberFormat(logisticCostPerTon, {})"
              class="div7"
            />
            <InfoBox
              header="Landed Cost"
              :info="vueNumberFormat(landedCostPerTon, {})"
              class="div8"
            />
          </div>

          <div class="details_section">
            <InfoBox
              header="Commodity"
              :info="discovery.commodity"
              class="div1"
            />

            <InfoBox
              header="Category"
              :info="discovery.estimate_category"
              class="div2"
            />

            <InfoBox header="LGA" :info="discovery.lga" class="div3" />

            <InfoBox header="State" :info="discovery.state" class="div4" />

            <InfoBox header="Channel" :info="discovery.channel" class="div5" />
            <InfoBox
              header="Price/T"
              :info="vueNumberFormat(discovery.pricePerTon, {})"
              class="div6"
            />
            <InfoBox
              header="Latitude"
              :info="discovery.latitude"
              class="div7"
            />
            <InfoBox
              header="Longitude"
              :info="discovery.longitude"
              class="div8"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="discovery-decision">
      <div style="margin: 0 auto; width: 96%">
        <h3 class="discovery-decision-header">Discovery Decision</h3>

        <div class="green-border"></div>

        <div class="discovery-decision-details">
          <div class="" style="margin: 10px auto; width: 95%; height: 90%">
            <div class="contents">
              <div class="section-1">
                <div class="content-box">
                  <p class="decision-label">Status</p>
                  <p class="decision-label">
                    {{ discovery.discovery_status }}
                  </p>
                </div>

                <div class="content-box">
                  <p class="decision-label">Actual Tonnage</p>
                  <p class="decision-label">
                    {{ discovery.actual_tonnage }}
                  </p>
                </div>


                <div class="content-box">
                  <p class="decision-label">Buying Price/Ton</p>
                  <p class="decision-label">
                    {{ discovery.buy_price_per_ton }}
                  </p>
                </div>

                <div class="content-box">
                  <p class="decision-label">Payment Status</p>
                  <p class="decision-label">
                    {{ discovery.transaction_status }}
                  </p>
                </div>

                <div class="content-box">
                  <p class="decision-label">Approval Status</p>
                  <p class="decision-label">
                    {{ discovery.isApproved }}
                  </p>
                </div>

                <div v-if="profitPerAtom" class="content-box">
                  <p class="decision-label">Estimated Profit/Atom</p>
                  <p class="decision-label">
                    {{ vueNumberFormat(profitPerAtom, {}) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="approval-container">
      <div class="" style="margin: 0 auto; width: 96%">
        <h6 class="action-section-header">Actions</h6>

        <div class="green-border"></div>

        <div class="action-buttons-container">
          <InvertedBlackButton
            label="Reject"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#fff"
            bgColor="#ED0A0A"
          />

          <GreenButton
            v-if="
              discovery.isApproved === 'Pending' &&
              discovery.discovery_status === 'Accepted'
            "
            label="Approve"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#669933"
            @click="approveDiscovery"
          />
        </div>
      </div>
    </section>

    <section class="actions-container">
      <div class="actions">
        <div class="actions-img">
          <div class="image-container">
            <img
              src="../../../assets/carbon_information-filled.png"
              class="image-item"
              alt="information-icon"
            />
          </div>

          <p class="discovery-text">
            Use the buttons to update the discovery status / farmer's decision
          </p>
        </div>

        <div class="buttons-container">
          <div class="buttons-action">
            <RedButton
              label="Declines"
              width="8.4rem"
              height="2.9rem"
              @click="rejectDiscovery"
              :disable="isDiscoveryDeclined"
            />
          </div>
          <div class="buttons-action">
            <GreenButton
              label="Accepts"
              width="8.4rem"
              height="2.9rem"
              @click="acceptDiscovery"
              :disable="isDiscoveryApproved"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import InfoBox from "../../info-box/InfoBox.vue";
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";

import RedButton from "../../buttons/red-button/RedButton.vue";
import GreenButton from "../../buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../buttons/inverted-black-button/InvertedBlackButton.vue";

export default {
  name: "DiscoveryDetailsModal",
  components: {
    InfoBox,
    RedButton,
    GreenButton,
    Loading,
    InvertedBlackButton,
  },
  data() {
    return {
      loading: false,
      modalStatus: "",
      discovery: {},
      profitPerAtom: "",
      logisticCostPerTon: "",
      landedCostPerTon: "",
    };
  },

  props: {
    discoveryId: {
      type: String,
      required: true,
    },
  },

  computed: {
    isDiscoveryApproved() {
      if (this.discovery.discovery_status === "Accepted") {
        return true;
      } else {
        return false;
      }
    },

    isDiscoveryDeclined() {
      if (this.discovery.discovery_status === "Declined") {
        return true;
      } else {
        return false;
      }
    },
  },

  mounted() {
    this.fetchDiscovery();
  },
  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    async fetchDiscovery() {
      const discoveryId = this.discoveryId;

      try {
        this.loading = true;

        const discovery = await this.$store.dispatch(
          "discoveries/fetchOneDiscovery",
          discoveryId
        );

        console.log(discovery);

        if (discovery?.is_purchased && discovery?.millId?.location) {
          const queryParams = {
            millLat: discovery?.millId?.location.latitude,
            millLng: discovery?.millId?.location.longitude,
            p1_cost_per_ton: discovery?.buy_price_per_ton
              ? discovery?.buy_price_per_ton
              : discovery.amount_payable / Number(discovery.estimated_tonnage),
          };
          const resp = await this.$store.dispatch(
            "profitInput/getProfitPerAtom",
            queryParams
          );

          this.profitPerAtom = Math.round(resp?.profitPerAtom).toString();
          this.logisticCostPerTon = Math.round(
            resp?.logisticsCostPerTon
          ).toString();
          this.landedCostPerTon = Math.round(resp?.landedCostPerTon).toString();
        }
        this.loading = false;
        this.discovery = discovery;
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },

    async approveDiscovery() {
      this.loading = true;
      const data = await this.$store.dispatch("discoveries/updateDiscovery", {
        id: this.discoveryId,
        isApproved: "Approved",
      });

      if (data._id) {
        this.loading = false;
        createToast("Discovery Approved Successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            return window.location.reload();
          },
        });
      }
    },

    openDeleteModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openDeleteModal", true);
    },

    openEditModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openEditModal", true);
      this.$store.dispatch(
        "discoveries/findDiscoveryToBeUpdated",
        this.discoveryId
      );
    },

    rejectDiscovery() {
      if (
        this.discovery.estimate_category === "pile_estimate" ||
        this.discovery.estimate_category === "Pile Estimation"
      ) {
        this.$emit("onCloseModal", false);
        this.$emit("openRejectModal", true);
      } else if (
        this.discovery.estimate_category === "direct_purchase" ||
        this.discovery.estimate_category === "Direct Purchase" ||
        this.discovery.estimate_category === "Weight Buying"
      ) {
        this.$emit("onCloseModal", false);
        this.$emit("openRejectModal2", true);
      }
    },

    acceptDiscovery() {
      if (
        this.discovery.estimate_category === "pile_estimate" ||
        this.discovery.estimate_category === "Pile Estimation"
      ) {
        this.$emit("onCloseModal", false);
        this.$emit("openAcceptModal1", true);
      } else if (
        this.discovery.estimate_category === "direct_purchase" ||
        this.discovery.estimate_category === "Direct Purchase" ||
        this.discovery.estimate_category === "Weight Buying"
      ) {
        this.$emit("onCloseModal", false);
        this.$emit("openAcceptModal2", true);
      }
    },
  },
};
</script>

<style scoped>
.discovery-parent-modal {
  background-color: #fff;
  height: 100%;
}

.modal-header-content,
.details,
.contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details {
  padding: 5px;
}

.modal-header-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;

  /* margin: 5px auto; */
  width: 94%;
  /* border: 1px solid #fff; */
  margin: 0;
}
.content-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.logo-container img {
  width: 30px;
  height: auto;
}
.discovery-decision-details {
  background: #232a34;
  border-radius: 16px;
  height: 180px;
  margin: 20px;
  padding-top: 20px;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 19%;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.border,
.green-border {
  margin-top: 3px;
  background: #ffffff;
  width: 100%;
}

.decision-label {
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.8px;
}

.details_section {
  margin-top: 15px;
  width: 42%;
  height: 220px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 20px;
  padding: 0px;
}

.first {
  grid-area: 1 / 1 / 2 / 2;
  /* border: 1px solid #fff; */
  align-self: center;
}

.second {
  grid-area: 1 / 2 / 2 / 3;
  /* border: 1px solid #fff; */
}

.third {
  grid-area: 2 / 1 / 3 / 2;
  /* border: 1px solid #fff; */
}

.four {
  grid-area: 2 / 2 / 3 / 3;
  /* border: 1px solid #fff; */
}

.five {
  grid-area: 1 / 1 / 2 / 2;
}

.six {
  grid-area: 1 / 2 / 2 / 3;
}

.seven {
  grid-area: 2 / 1 / 3 / 2;
}

.eight {
  grid-area: 2 / 2 / 3 / 3;
}

.green-border {
  border-bottom: 2px dotted #669933;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 2 / 1 / 3 / 2;
}
.div4 {
  grid-area: 2 / 2 / 3 / 3;
}
.div5 {
  grid-area: 3 / 1 / 4 / 2;
}
.div6 {
  grid-area: 3 / 2 / 4 / 3;
}

.discovery-decision {
  margin: 20px 0px;
}

.actions-container,
.approval-container {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 1rem;
}

.actions-container {
  border-top: 1px solid #b3b3b3;
}

.action-section-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 23px;
}

.action-buttons-container {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  gap: 15px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions-img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.image-container {
  height: 2rem;
  width: 2rem;
}

.image-item {
  height: 100%;
  width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.discovery-decision {
  padding: 0.7rem 0rem;
}

.discovery-decision-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 27px;
  margin-top: 2rem;
}
</style>
