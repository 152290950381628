const state = () => ({
  clickedMenuItem: "Dashboard",
});

const getters = {
  getClickedMenuItem: (state) => {
    return state.clickedMenuItem;
  },
};

const mutations = {
  setClickedMenuItem(state, clickedMenuItem) {
    state.clickedMenuItem = clickedMenuItem;
    console.log(state.clickedMenuItem);
  },
};

const actions = {
  setClickedMenuItem({ commit }, clickedMenuItem) {
    commit("setClickedMenuItem", clickedMenuItem);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
