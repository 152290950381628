export const purchaseOverheadCoststableHeaders = [
  { text: "Purchase Overhead Cost/T", value: "name" },
  { text: "Total", value: "totalCost" },
  { text: "Target", value: "target" },
  { text: "Actual", value: "costPerTon" },
  { text: "% Of Threshold", value: "percentageThreshold" },
];


export const logisticsOverheadCoststableHeaders = [
  { text: "Logistics Overhead Cost/T", value: "name" },
  { text: "Total", value: "totalCost" },
  { text: "Target", value: "target" },
  { text: "Actual", value: "costPerTon" },
  { text: "% Of Threshold", value: "percentageThreshold" },
];
