<template>
  <div class="sidebar-link__parent">
    <router-link
      :to="to"
      class="link"
      @click="clicked"
      :class="{ active: isActive }"
    >
      <img :src="imagePath" alt="" class="menu-logo" />
      <transition name="fade">
        <slot />
      </transition>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SidebarLink",

  data() {
    return {};
  },

  props: {
    to: { type: String, required: true },
    iconName: { type: String, required: true },
    route: { type: String, required: true },
  },

  computed: {
    imagePath() {
      if (this.isActive) {
        return require(`../../assets/black-${this.iconName}.png`);
      }
      return require(`../../assets/${this.iconName}.png`);
    },

    isActive() {
      return this.$route.path === this.to;

    },
  },

  methods: {
    clicked() {
      this.$store.dispatch("layout/setClickedMenuItem", this.route);
    },
  },
};
</script>

<style scoped>
.sidebar-link__parent {
  padding: 5px;
  margin-bottom: 6px;
}

.link {
  display: flex;
  align-items: center;

  cursor: pointer;
  position: relative;
  font-weight: 400;
  user-select: none;

  margin: 0.1em 0;
  padding: 6px;
  border-radius: 0.25em;
  height: 2.5em;

  color: white;
  text-decoration: none;
  font-size: 15px;
}

.link:hover {
  background-color: rgb(135, 113, 113);
  border-radius: 5px;
}

.link.active {
  background-color: #fff;
  color: #000;
  border-right: 5px solid #8fc54d;
}

.menu-logo {
  width: 20px;
  height: auto;
  margin-right: 15px;
}
</style>
