export const lgasNameToSlug = {
  'Aba South': 'ABSO',
  Arochukwu: 'AROC',
  Bende: 'BEND',
  Ikwuano: 'IKWU',
  'Isiala Ngwa North': 'ISNN',
  'Isiala Ngwa South': 'ISNS',
  Isuikwuato: 'ISUI',
  'Obi Ngwa': 'OBNG',
  Ohafia: 'OHAF',
  Osisioma: 'OSIS',
  Ugwunagbo: 'UGWU',
  'Ukwa East': 'UKEA',
  'Ukwa West': 'UKWE',
  'Umuahia North': 'UMNO',
  'Umuahia South': 'UMSO',
  'Umu Nneochi': 'UMNN',
  Fufure: 'FUFU',
  Ganye: 'GANY',
  Gayuk: 'GAYU',
  Gombi: 'GOMB',
  Grie: 'GRIE',
  Hong: 'HONG',
  Jada: 'JADA',
  Lamurde: 'LAMU',
  Madagali: 'MADA',
  Maiha: 'MAIH',
  'Mayo Belwa': 'MABE',
  Michika: 'MICH',
  'Mubi North': 'MUNO',
  'Mubi South': 'MUSO',
  Numan: 'NUMA',
  Shelleng: 'SHEL',
  Song: 'SONG',
  Toungo: 'TOUN',
  'Yola North': 'YONO',
  'Yola South': 'YOSO',
  Abak: 'ABAK',
  'Eastern Obolo': 'EAOB',
  Eket: 'EKET',
  'Esit Eket': 'ESEK',
  'Essien Udim': 'ESUD',
  'Etim Ekpo': 'ETEK',
  Etinan: 'ETIN',
  Ibeno: 'IBEN',
  'Ibesikpo Asutan': 'IBAS',
  'Ibiono-Ibom': 'IBIB',
  Ika: 'IKA',
  Ikono: 'IKON',
  'Ikot Abasi': 'IKAB',
  'Ikot-Ekpene': 'IKEK',
  Ini: 'INI',
  Itu: 'ITU',
  Mbo: 'MBO',
  'Mkpat-Enin': 'MKEN',
  'Nsit-Atai': 'NSAT',
  'Nsit-Ibom': 'NSIB',
  'Nsit-Ubium': 'NSUB',
  'Obot Akara': 'OBAK',
  Okobo: 'OKOB',
  Onna: 'ONNA',
  Oron: 'ORON',
  'Oruk Anam': 'ORAN',
  'Udung-Uko': 'UDUK',
  Ukanafun: 'UKAN',
  Uruan: 'URUA',
  'Urue-Offong/Oruko': 'UROO',
  Uyo: 'UYO',
  'Anambra East': 'ANEA',
  'Anambra West': 'ANWE',
  Anaocha: 'ANAO',
  'Awka North': 'AWNO',
  'Awka South': 'AWSO',
  Ayamelum: 'AYAM',
  Dunukofia: 'DUNU',
  Ekwusigo: 'EKWU',
  'Idemili North': 'IDNO',
  'Idemili South': 'IDSO',
  Ihiala: 'IHIA',
  Njikoka: 'NJIK',
  'Nnewi North': 'NNNO',
  'Nnewi South': 'NNSO',
  Ogbaru: 'OGBA',
  'Onitsha North': 'ONNO',
  'Onitsha South': 'ONSO',
  'Orumba North': 'ORNO',
  'Orumba South': 'ORSO',
  Oyi: 'OYI',
  Bauchi: 'BAUC',
  Bogoro: 'BOGO',
  Damban: 'DAMB',
  Darazo: 'DARA',
  Dass: 'DASS',
  Gamawa: 'GAMA',
  Ganjuwa: 'GANJ',
  Giade: 'GIAD',
  'Itas/Gadau': 'ITGA',
  "Jama'are": 'JAMA',
  Katagum: 'KATA',
  Kirfi: 'KIRF',
  Misau: 'MISA',
  Ningi: 'NING',
  Shira: 'SHIR',
  'Tafawa Balewa': 'TABA',
  Toro: 'TORO',
  Warji: 'WARJ',
  Zaki: 'ZAKI',
  Ekeremor: 'EKER',
  'Kolokuma/Opokuma': 'KOOP',
  Nembe: 'NEMB',
  Ogbia: 'OGBI',
  Sagbama: 'SAGB',
  'Southern Ijaw': 'SOIJ',
  Yenagoa: 'YENA',
  Apa: 'APA',
  Ado: 'ADO',
  Buruku: 'BURU',
  Gboko: 'GBOK',
  Guma: 'GUMA',
  'Gwer East': 'GWEA',
  'Gwer West': 'GWWE',
  'Katsina-Ala': 'KAAL',
  Konshisha: 'KONS',
  Kwande: 'KWAN',
  Logo: 'LOGO',
  Makurdi: 'MAKU',
  Obi: 'OBI',
  Ogbadibo: 'OGBA',
  Ohimini: 'OHIM',
  Oju: 'OJU',
  Okpokwu: 'OKPO',
  Oturkpo: 'OTUR',
  Tarka: 'TARK',
  Ukum: 'UKUM',
  Ushongo: 'USHO',
  Vandeikya: 'VAND',
  'Askira/Uba': 'ASUB',
  Bama: 'BAMA',
  Bayo: 'BAYO',
  Biu: 'BIU',
  Chibok: 'CHIB',
  Damboa: 'DAMB',
  Dikwa: 'DIKW',
  Gubio: 'GUBI',
  Guzamala: 'GUZA',
  Gwoza: 'GWOZ',
  Hawul: 'HAWU',
  Jere: 'JERE',
  Kaga: 'KAGA',
  'Kala/Balge': 'KABA',
  Konduga: 'KOND',
  Kukawa: 'KUKA',
  'Kwaya Kusar': 'KWKU',
  Mafa: 'MAFA',
  Magumeri: 'MAGU',
  Maiduguri: 'MAID',
  Marte: 'MART',
  Mobbar: 'MOBB',
  Monguno: 'MONG',
  Ngala: 'NGAL',
  Nganzai: 'NGAN',
  Shani: 'SHAN',
  Abi: 'ABI',
  Akamkpa: 'AKAM',
  Akpabuyo: 'AKPA',
  Bakassi: 'BAKA',
  Bekwarra: 'BEKW',
  Biase: 'BIAS',
  Boki: 'BOKI',
  'Calabar Municipal': 'CAMU',
  'Calabar South': 'CASO',
  Etung: 'ETUN',
  Ikom: 'IKOM',
  Obanliku: 'OBAN',
  Obubra: 'OBUB',
  Obudu: 'OBUD',
  Odukpani: 'ODUK',
  Ogoja: 'OGOJ',
  Yakuur: 'YAKU',
  Yala: 'YALA',
  'Aniocha South': 'ANSO',
  Bomadi: 'BOMA',
  Burutu: 'BURU',
  'Ethiope East': 'ETEA',
  'Ethiope West': 'ETWE',
  'Ika North East': 'IKNE',
  'Ika South': 'IKSO',
  'Isoko North': 'ISNO',
  'Isoko South': 'ISSO',
  'Ndokwa East': 'NDEA',
  'Ndokwa West': 'NDWE',
  Okpe: 'OKPE',
  'Oshimili North': 'OSNO',
  'Oshimili South': 'OSSO',
  Patani: 'PATA',
  Sapele: 'SAPE',
  Udu: 'UDU',
  'Ughelli North': 'UGNO',
  'Ughelli South': 'UGSO',
  Ukwuani: 'UKWU',
  Uvwie: 'UVWI',
  'Warri North': 'WANO',
  'Warri South': 'WASO',
  'Warri South West': 'WASW',
  'Afikpo North': 'AFNO',
  'Afikpo South': 'AFSO',
  Ebonyi: 'EBON',
  'Ezza North': 'EZNO',
  'Ezza South': 'EZSO',
  Ikwo: 'IKWO',
  Ishielu: 'ISHI',
  Ivo: 'IVO',
  Izzi: 'IZZI',
  Ohaozara: 'OHAO',
  Ohaukwu: 'OHAU',
  Onicha: 'ONIC',
  Egor: 'EGOR',
  'Esan Central': 'ESCE',
  'Esan North-East': 'ESNE',
  'Esan South-East': 'ESSE',
  'Esan West': 'ESWE',
  'Etsako Central': 'ETCE',
  'Etsako East': 'ETEA',
  'Etsako West': 'ETWE',
  Igueben: 'IGUE',
  'Ikpoba Okha': 'IKOK',
  Orhionmwon: 'ORHI',
  Oredo: 'ORED',
  'Ovia North-East': 'OVNE',
  'Ovia South-West': 'OVSW',
  'Owan East': 'OWEA',
  'Owan West': 'OWWE',
  Uhunmwonde: 'UHUN',
  Efon: 'EFON',
  'Ekiti East': 'EKEA',
  'Ekiti South-West': 'EKSW',
  'Ekiti West': 'EKWE',
  Emure: 'EMUR',
  Gbonyin: 'GBON',
  'Ido Osi': 'IDOS',
  Ijero: 'IJER',
  Ikere: 'IKER',
  Ikole: 'IKOL',
  Ilejemeje: 'ILEJ',
  'Irepodun/Ifelodun': 'IRIF',
  'Ise/Orun': 'ISOR',
  Moba: 'MOBA',
  Oye: 'OYE',
  Awgu: 'AWGU',
  'Enugu East': 'ENEA',
  'Enugu North': 'ENNO',
  'Enugu South': 'ENSO',
  Ezeagu: 'EZEA',
  'Igbo Etiti': 'IGET',
  'Igbo Eze North': 'IGEN',
  'Igbo Eze South': 'IGES',
  'Isi Uzo': 'ISUZ',
  'Nkanu East': 'NKEA',
  'Nkanu West': 'NKWE',
  Nsukka: 'NSUK',
  'Oji River': 'OJRI',
  Udenu: 'UDEN',
  Udi: 'UDI',
  'Uzo Uwani': 'UZUW',
  Bwari: 'BWAR',
  Gwagwalada: 'GWAG',
  Kuje: 'KUJE',
  Kwali: 'KWAL',
  'Municipal Area Council': 'MUAC',
  Balanga: 'BALA',
  Billiri: 'BILL',
  Dukku: 'DUKK',
  Funakaye: 'FUNA',
  Gombe: 'GOMB',
  Kaltungo: 'KALT',
  Kwami: 'KWAM',
  Nafada: 'NAFA',
  Shongom: 'SHON',
  'Yamaltu/Deba': 'YADE',
  'Ahiazu Mbaise': 'AHMB',
  'Ehime Mbano': 'EHMB',
  Ezinihitte: 'EZIN',
  'Ideato North': 'IDNO',
  'Ideato South': 'IDSO',
  'Ihitte/Uboma': 'IHUB',
  Ikeduru: 'IKED',
  'Isiala Mbano': 'ISMB',
  Isu: 'ISU',
  Mbaitoli: 'MBAI',
  'Ngor Okpala': 'NGOK',
  Njaba: 'NJAB',
  Nkwerre: 'NKWE',
  Nwangele: 'NWAN',
  Obowo: 'OBOW',
  Oguta: 'OGUT',
  'Ohaji/Egbema': 'OHEG',
  Okigwe: 'OKIG',
  Orlu: 'ORLU',
  Orsu: 'ORSU',
  'Oru East': 'OREA',
  'Oru West': 'ORWE',
  'Owerri Municipal': 'OWMU',
  'Owerri North': 'OWNO',
  'Owerri West': 'OWWE',
  Unuimo: 'UNUI',
  Babura: 'BABU',
  Biriniwa: 'BIRI',
  'Birnin Kudu': 'BIKU',
  Buji: 'BUJI',
  Dutse: 'DUTS',
  Gagarawa: 'GAGA',
  Garki: 'GARK',
  Gumel: 'GUME',
  Guri: 'GURI',
  Gwaram: 'GWAR',
  Gwiwa: 'GWIW',
  Hadejia: 'HADE',
  Jahun: 'JAHU',
  'Kafin Hausa': 'KAHA',
  Kazaure: 'KAZA',
  'Kiri Kasama': 'KIKA',
  Kiyawa: 'KIYA',
  Kaugama: 'KAUG',
  Maigatari: 'MAIG',
  'Malam Madori': 'MAMA',
  Miga: 'MIGA',
  Ringim: 'RING',
  Roni: 'RONI',
  'Sule Tankarkar': 'SUTA',
  Taura: 'TAUR',
  Yankwashi: 'YANK',
  Chikun: 'CHIK',
  Giwa: 'GIWA',
  Igabi: 'IGAB',
  Ikara: 'IKAR',
  Jaba: 'JABA',
  "Jema'a": 'JEMA',
  Kachia: 'KACH',
  'Kaduna North': 'KANO',
  'Kaduna South': 'KASO',
  Kagarko: 'KAGA',
  Kajuru: 'KAJU',
  Kaura: 'KAUR',
  Kauru: 'KAUR',
  Kubau: 'KUBA',
  Kudan: 'KUDA',
  Lere: 'LERE',
  Makarfi: 'MAKA',
  'Sabon Gari': 'SAGA',
  Sanga: 'SANG',
  Soba: 'SOBA',
  'Zangon Kataf': 'ZAKA',
  Zaria: 'ZARI',
  Albasu: 'ALBA',
  Bagwai: 'BAGW',
  Bebeji: 'BEBE',
  Bichi: 'BICH',
  Bunkure: 'BUNK',
  Dala: 'DALA',
  Dambatta: 'DAMB',
  'Dawakin Kudu': 'DAKU',
  'Dawakin Tofa': 'DATO',
  Doguwa: 'DOGU',
  Fagge: 'FAGG',
  Gabasawa: 'GABA',
  Garko: 'GARK',
  'Garun Mallam': 'GAMA',
  Gaya: 'GAYA',
  Gezawa: 'GEZA',
  Gwale: 'GWAL',
  Gwarzo: 'GWAR',
  Kabo: 'KABO',
  'Kano Municipal': 'KAMU',
  Karaye: 'KARA',
  Kibiya: 'KIBI',
  Kiru: 'KIRU',
  Kumbotso: 'KUMB',
  Kunchi: 'KUNC',
  Kura: 'KURA',
  Madobi: 'MADO',
  Makoda: 'MAKO',
  Minjibir: 'MINJ',
  Nasarawa: 'NASA',
  Rano: 'RANO',
  'Rimin Gado': 'RIGA',
  Rogo: 'ROGO',
  Shanono: 'SHAN',
  Sumaila: 'SUMA',
  Takai: 'TAKA',
  Tarauni: 'TARA',
  Tofa: 'TOFA',
  Tsanyawa: 'TSAN',
  'Tudun Wada': 'TUWA',
  Ungogo: 'UNGO',
  Warawa: 'WARA',
  Wudil: 'WUDI',
  Batagarawa: 'BATA',
  Batsari: 'BATS',
  Baure: 'BAUR',
  Bindawa: 'BIND',
  Charanchi: 'CHAR',
  Dandume: 'DAND',
  Danja: 'DANJ',
  'Dan Musa': 'DAMU',
  Daura: 'DAUR',
  Dutsi: 'DUTS',
  'Dutsin Ma': 'DUMA',
  Faskari: 'FASK',
  Funtua: 'FUNT',
  Ingawa: 'INGA',
  Jibia: 'JIBI',
  Kafur: 'KAFU',
  Kaita: 'KAIT',
  Kankara: 'KANK',
  Kankia: 'KANK',
  Katsina: 'KATS',
  Kurfi: 'KURF',
  Kusada: 'KUSA',
  "Mai'Adua": "MAI'",
  Malumfashi: 'MALU',
  Mani: 'MANI',
  Mashi: 'MASH',
  Matazu: 'MATA',
  Musawa: 'MUSA',
  Rimi: 'RIMI',
  Sabuwa: 'SABU',
  Safana: 'SAFA',
  Sandamu: 'SAND',
  Zango: 'ZANG',
  'Arewa Dandi': 'ARDA',
  Argungu: 'ARGU',
  Augie: 'AUGI',
  Bagudo: 'BAGU',
  'Birnin Kebbi': 'BIKE',
  Bunza: 'BUNZ',
  Dandi: 'DAND',
  Fakai: 'FAKA',
  Gwandu: 'GWAN',
  Jega: 'JEGA',
  Kalgo: 'KALG',
  'Koko/Besse': 'KOBE',
  Maiyama: 'MAIY',
  Ngaski: 'NGAS',
  Sakaba: 'SAKA',
  Shanga: 'SHAN',
  Suru: 'SURU',
  'Wasagu/Danko': 'WADA',
  Yauri: 'YAUR',
  Zuru: 'ZURU',
  Ajaokuta: 'AJAO',
  Ankpa: 'ANKP',
  Bassa: 'BASS',
  Dekina: 'DEKI',
  Ibaji: 'IBAJ',
  Idah: 'IDAH',
  'Igalamela Odolu': 'IGOD',
  Ijumu: 'IJUM',
  'Kabba/Bunu': 'KABU',
  Kogi: 'KOGI',
  Lokoja: 'LOKO',
  'Mopa Muro': 'MOMU',
  Ofu: 'OFU',
  'Ogori/Magongo': 'OGMA',
  Okehi: 'OKEH',
  Okene: 'OKEN',
  Olamaboro: 'OLAM',
  Omala: 'OMAL',
  'Yagba East': 'YAEA',
  'Yagba West': 'YAWE',
  Baruten: 'BARU',
  Edu: 'EDU',
  Ekiti: 'EKIT',
  Ifelodun: 'IFEL',
  'Ilorin East': 'ILEA',
  'Ilorin South': 'ILSO',
  'Ilorin West': 'ILWE',
  Irepodun: 'IREP',
  Isin: 'ISIN',
  Kaiama: 'KAIA',
  Moro: 'MORO',
  Offa: 'OFFA',
  'Oke Ero': 'OKER',
  Oyun: 'OYUN',
  Pategi: 'PATE',
  'Ajeromi-Ifelodun': 'AJIF',
  Alimosho: 'ALIM',
  'Amuwo-Odofin': 'AMOD',
  Apapa: 'APAP',
  Badagry: 'BADA',
  Epe: 'EPE',
  'Eti Osa': 'ETOS',
  'Ibeju-Lekki': 'IBLE',
  'Ifako-Ijaiye': 'IFIJ',
  Ikeja: 'IKEJ',
  Ikorodu: 'IKOR',
  Kosofe: 'KOSO',
  'Lagos Island': 'LAIS',
  'Lagos Mainland': 'LAMA',
  Mushin: 'MUSH',
  Ojo: 'OJO',
  'Oshodi-Isolo': 'OSIS',
  Shomolu: 'SHOM',
  Surulere: 'SURU',
  Awe: 'AWE',
  Doma: 'DOMA',
  Karu: 'KARU',
  Keana: 'KEAN',
  Keffi: 'KEFF',
  Kokona: 'KOKO',
  Lafia: 'LAFI',
  'Nasarawa Egon': 'NAEG',
  Toto: 'TOTO',
  Wamba: 'WAMB',
  Agwara: 'AGWA',
  Bida: 'BIDA',
  Borgu: 'BORG',
  Bosso: 'BOSS',
  Chanchaga: 'CHAN',
  Edati: 'EDAT',
  Gbako: 'GBAK',
  Gurara: 'GURA',
  Katcha: 'KATC',
  Kontagora: 'KONT',
  Lapai: 'LAPA',
  Lavun: 'LAVU',
  Magama: 'MAGA',
  Mariga: 'MARI',
  Mashegu: 'MASH',
  Mokwa: 'MOKW',
  Moya: 'MOYA',
  Paikoro: 'PAIK',
  Rafi: 'RAFI',
  Rijau: 'RIJA',
  Shiroro: 'SHIR',
  Suleja: 'SULE',
  Tafa: 'TAFA',
  Wushishi: 'WUSH',
  'Abeokuta South': 'ABSO',
  'Ado-Odo/Ota': 'ADOO',
  'Egbado North': 'EGNO',
  'Egbado South': 'EGSO',
  Ewekoro: 'EWEK',
  Ifo: 'IFO',
  'Ijebu East': 'IJEA',
  'Ijebu North': 'IJNO',
  'Ijebu North East': 'IJNE',
  'Ijebu Ode': 'IJOD',
  Ikenne: 'IKEN',
  'Imeko Afon': 'IMAF',
  Ipokia: 'IPOK',
  'Obafemi Owode': 'OBOW',
  Odeda: 'ODED',
  Odogbolu: 'ODOG',
  'Ogun Waterside': 'OGWA',
  'Remo North': 'RENO',
  Shagamu: 'SHAG',
  'Akoko North-West': 'AKNW',
  'Akoko South-West': 'AKSW',
  'Akoko South-East': 'AKSE',
  'Akure North': 'AKNO',
  'Akure South': 'AKSO',
  'Ese Odo': 'ESOD',
  Idanre: 'IDAN',
  Ifedore: 'IFED',
  Ilaje: 'ILAJ',
  'Ile Oluji/Okeigbo': 'ILOO',
  Irele: 'IREL',
  Odigbo: 'ODIG',
  Okitipupa: 'OKIT',
  'Ondo East': 'ONEA',
  'Ondo West': 'ONWE',
  Ose: 'OSE',
  Owo: 'OWO',
  'Atakunmosa West': 'ATWE',
  Aiyedaade: 'AIYE',
  Aiyedire: 'AIYE',
  Boluwaduro: 'BOLU',
  Boripe: 'BORI',
  'Ede North': 'EDNO',
  'Ede South': 'EDSO',
  'Ife Central': 'IFCE',
  'Ife East': 'IFEA',
  'Ife North': 'IFNO',
  'Ife South': 'IFSO',
  Egbedore: 'EGBE',
  Ejigbo: 'EJIG',
  Ifedayo: 'IFED',
  Ila: 'ILA',
  'Ilesa East': 'ILEA',
  'Ilesa West': 'ILWE',
  Irewole: 'IREW',
  Isokan: 'ISOK',
  Iwo: 'IWO',
  Obokun: 'OBOK',
  'Odo Otin': 'ODOT',
  'Ola Oluwa': 'OLOL',
  Olorunda: 'OLOR',
  Oriade: 'ORIA',
  Orolu: 'OROL',
  Osogbo: 'OSOG',
  Akinyele: 'AKIN',
  Atiba: 'ATIB',
  Atisbo: 'ATIS',
  Egbeda: 'EGBE',
  'Ibadan North': 'IBNO',
  'Ibadan North-East': 'IBNE',
  'Ibadan North-West': 'IBNW',
  'Ibadan South-East': 'IBSE',
  'Ibadan South-West': 'IBSW',
  'Ibarapa Central': 'IBCE',
  'Ibarapa East': 'IBEA',
  'Ibarapa North': 'IBNO',
  Ido: 'IDO',
  Irepo: 'IREP',
  Iseyin: 'ISEY',
  Itesiwaju: 'ITES',
  Iwajowa: 'IWAJ',
  Kajola: 'KAJO',
  Lagelu: 'LAGE',
  'Ogbomosho North': 'OGNO',
  'Ogbomosho South': 'OGSO',
  'Ogo Oluwa': 'OGOL',
  Olorunsogo: 'OLOR',
  Oluyole: 'OLUY',
  'Ona Ara': 'ONAR',
  Orelope: 'OREL',
  'Ori Ire': 'ORIR',
  Oyo: 'OYO',
  'Oyo East': 'OYEA',
  'Saki East': 'SAEA',
  'Saki West': 'SAWE',
  'Barkin Ladi': 'BALA',
  'Jos East': 'JOEA',
  'Jos North': 'JONO',
  'Jos South': 'JOSO',
  Kanam: 'KANA',
  Kanke: 'KANK',
  'Langtang South': 'LASO',
  'Langtang North': 'LANO',
  Mangu: 'MANG',
  Mikang: 'MIKA',
  Pankshin: 'PANK',
  "Qua'an Pan": 'QUPA',
  Riyom: 'RIYO',
  Shendam: 'SHEN',
  Wase: 'WASE',
  'Ahoada East': 'AHEA',
  'Ahoada West': 'AHWE',
  'Akuku-Toru': 'AKTO',
  Andoni: 'ANDO',
  'Asari-Toru': 'ASTO',
  Bonny: 'BONN',
  Degema: 'DEGE',
  Eleme: 'ELEM',
  Emuoha: 'EMUO',
  Etche: 'ETCH',
  Gokana: 'GOKA',
  Ikwerre: 'IKWE',
  Khana: 'KHAN',
  'Obio/Akpor': 'OBAK',
  'Ogba/Egbema/Ndoni': 'OGEN',
  'Ogu/Bolo': 'OGBO',
  Okrika: 'OKRI',
  Omuma: 'OMUM',
  'Opobo/Nkoro': 'OPNK',
  Oyigbo: 'OYIG',
  'Port Harcourt': 'POHA',
  Tai: 'TAI',
  Bodinga: 'BODI',
  'Dange Shuni': 'DASH',
  Gada: 'GADA',
  Goronyo: 'GORO',
  Gudu: 'GUDU',
  Gwadabawa: 'GWAD',
  Illela: 'ILLE',
  Isa: 'ISA',
  Kebbe: 'KEBB',
  Kware: 'KWAR',
  Rabah: 'RABA',
  'Sabon Birni': 'SABI',
  Shagari: 'SHAG',
  Silame: 'SILA',
  'Sokoto North': 'SONO',
  'Sokoto South': 'SOSO',
  Tambuwal: 'TAMB',
  Tangaza: 'TANG',
  Tureta: 'TURE',
  Wamako: 'WAMA',
  Wurno: 'WURN',
  Yabo: 'YABO',
  Bali: 'BALI',
  Donga: 'DONG',
  Gashaka: 'GASH',
  Gassol: 'GASS',
  Ibi: 'IBI',
  Jalingo: 'JALI',
  'Karim Lamido': 'KALA',
  Kumi: 'KUMI',
  Lau: 'LAU',
  Sardauna: 'SARD',
  Takum: 'TAKU',
  Ussa: 'USSA',
  Wukari: 'WUKA',
  Yorro: 'YORR',
  Zing: 'ZING',
  Bursari: 'BURS',
  Damaturu: 'DAMA',
  Fika: 'FIKA',
  Fune: 'FUNE',
  Geidam: 'GEID',
  Gujba: 'GUJB',
  Gulani: 'GULA',
  Jakusko: 'JAKU',
  Karasuwa: 'KARA',
  Machina: 'MACH',
  Nangere: 'NANG',
  Nguru: 'NGUR',
  Potiskum: 'POTI',
  Tarmuwa: 'TARM',
  Yunusari: 'YUNU',
  Yusufari: 'YUSU',
  Bakura: 'BAKU',
  'Birnin Magaji/Kiyaw': 'BIMK',
  Bukkuyum: 'BUKK',
  Bungudu: 'BUNG',
  Gummi: 'GUMM',
  Gusau: 'GUSA',
  'Kaura Namoda': 'KANA',
  Maradun: 'MARA',
  Maru: 'MARU',
  Shinkafi: 'SHIN',
  'Talata Mafara': 'TAMA',
  Chafe: 'CHAF',
  Zurmi: 'ZURM',
};





export const lgaSlugToName = {
  ABSO: 'Abeokuta South',
  AROC: 'Arochukwu',
  BEND: 'Bende',
  IKWU: 'Ikwuano',
  ISNN: 'Isiala Ngwa North',
  ISNS: 'Isiala Ngwa South',
  ISUI: 'Isuikwuato',
  OBNG: 'Obi Ngwa',
  OHAF: 'Ohafia',
  OSIS: 'Oshodi-Isolo',
  UGWU: 'Ugwunagbo',
  UKEA: 'Ukwa East',
  UKWE: 'Ukwa West',
  UMNO: 'Umuahia North',
  UMSO: 'Umuahia South',
  UMNN: 'Umu Nneochi',
  FUFU: 'Fufure',
  GANY: 'Ganye',
  GAYU: 'Gayuk',
  GOMB: 'Gombe',
  GRIE: 'Grie',
  HONG: 'Hong',
  JADA: 'Jada',
  LAMU: 'Lamurde',
  MADA: 'Madagali',
  MAIH: 'Maiha',
  MABE: 'Mayo Belwa',
  MICH: 'Michika',
  MUNO: 'Mubi North',
  MUSO: 'Mubi South',
  NUMA: 'Numan',
  SHEL: 'Shelleng',
  SONG: 'Song',
  TOUN: 'Toungo',
  YONO: 'Yola North',
  YOSO: 'Yola South',
  ABAK: 'Abak',
  EAOB: 'Eastern Obolo',
  EKET: 'Eket',
  ESEK: 'Esit Eket',
  ESUD: 'Essien Udim',
  ETEK: 'Etim Ekpo',
  ETIN: 'Etinan',
  IBEN: 'Ibeno',
  IBAS: 'Ibesikpo Asutan',
  IBIB: 'Ibiono-Ibom',
  IKA: 'Ika',
  IKON: 'Ikono',
  IKAB: 'Ikot Abasi',
  IKEK: 'Ikot-Ekpene',
  INI: 'Ini',
  ITU: 'Itu',
  MBO: 'Mbo',
  MKEN: 'Mkpat-Enin',
  NSAT: 'Nsit-Atai',
  NSIB: 'Nsit-Ibom',
  NSUB: 'Nsit-Ubium',
  OBAK: 'Obio/Akpor',
  OKOB: 'Okobo',
  ONNA: 'Onna',
  ORON: 'Oron',
  ORAN: 'Oruk Anam',
  UDUK: 'Udung-Uko',
  UKAN: 'Ukanafun',
  URUA: 'Uruan',
  UROO: 'Urue-Offong/Oruko',
  UYO: 'Uyo',
  ANEA: 'Anambra East',
  ANWE: 'Anambra West',
  ANAO: 'Anaocha',
  AWNO: 'Awka North',
  AWSO: 'Awka South',
  AYAM: 'Ayamelum',
  DUNU: 'Dunukofia',
  EKWU: 'Ekwusigo',
  IDNO: 'Ideato North',
  IDSO: 'Ideato South',
  IHIA: 'Ihiala',
  NJIK: 'Njikoka',
  NNNO: 'Nnewi North',
  NNSO: 'Nnewi South',
  OGBA: 'Ogbadibo',
  ONNO: 'Onitsha North',
  ONSO: 'Onitsha South',
  ORNO: 'Orumba North',
  ORSO: 'Orumba South',
  OYI: 'Oyi',
  BAUC: 'Bauchi',
  BOGO: 'Bogoro',
  DAMB: 'Dambatta',
  DARA: 'Darazo',
  DASS: 'Dass',
  GAMA: 'Garun Mallam',
  GANJ: 'Ganjuwa',
  GIAD: 'Giade',
  ITGA: 'Itas/Gadau',
  JAMA: "Jama'are",
  KATA: 'Katagum',
  KIRF: 'Kirfi',
  MISA: 'Misau',
  NING: 'Ningi',
  SHIR: 'Shiroro',
  TABA: 'Tafawa Balewa',
  TORO: 'Toro',
  WARJ: 'Warji',
  ZAKI: 'Zaki',
  EKER: 'Ekeremor',
  KOOP: 'Kolokuma/Opokuma',
  NEMB: 'Nembe',
  OGBI: 'Ogbia',
  SAGB: 'Sagbama',
  SOIJ: 'Southern Ijaw',
  YENA: 'Yenagoa',
  APA: 'Apa',
  ADO: 'Ado',
  BURU: 'Burutu',
  GBOK: 'Gboko',
  GUMA: 'Guma',
  GWEA: 'Gwer East',
  GWWE: 'Gwer West',
  KAAL: 'Katsina-Ala',
  KONS: 'Konshisha',
  KWAN: 'Kwande',
  LOGO: 'Logo',
  MAKU: 'Makurdi',
  OBI: 'Obi',
  OHIM: 'Ohimini',
  OJU: 'Oju',
  OKPO: 'Okpokwu',
  OTUR: 'Oturkpo',
  TARK: 'Tarka',
  UKUM: 'Ukum',
  USHO: 'Ushongo',
  VAND: 'Vandeikya',
  ASUB: 'Askira/Uba',
  BAMA: 'Bama',
  BAYO: 'Bayo',
  BIU: 'Biu',
  CHIB: 'Chibok',
  DIKW: 'Dikwa',
  GUBI: 'Gubio',
  GUZA: 'Guzamala',
  GWOZ: 'Gwoza',
  HAWU: 'Hawul',
  JERE: 'Jere',
  KAGA: 'Kagarko',
  KABA: 'Kala/Balge',
  KOND: 'Konduga',
  KUKA: 'Kukawa',
  KWKU: 'Kwaya Kusar',
  MAFA: 'Mafa',
  MAGU: 'Magumeri',
  MAID: 'Maiduguri',
  MART: 'Marte',
  MOBB: 'Mobbar',
  MONG: 'Monguno',
  NGAL: 'Ngala',
  NGAN: 'Nganzai',
  SHAN: 'Shanga',
  ABI: 'Abi',
  AKAM: 'Akamkpa',
  AKPA: 'Akpabuyo',
  BAKA: 'Bakassi',
  BEKW: 'Bekwarra',
  BIAS: 'Biase',
  BOKI: 'Boki',
  CAMU: 'Calabar Municipal',
  CASO: 'Calabar South',
  ETUN: 'Etung',
  IKOM: 'Ikom',
  OBAN: 'Obanliku',
  OBUB: 'Obubra',
  OBUD: 'Obudu',
  ODUK: 'Odukpani',
  OGOJ: 'Ogoja',
  YAKU: 'Yakuur',
  YALA: 'Yala',
  ANSO: 'Aniocha South',
  BOMA: 'Bomadi',
  ETEA: 'Etsako East',
  ETWE: 'Etsako West',
  IKNE: 'Ika North East',
  IKSO: 'Ika South',
  ISNO: 'Isoko North',
  ISSO: 'Isoko South',
  NDEA: 'Ndokwa East',
  NDWE: 'Ndokwa West',
  OKPE: 'Okpe',
  OSNO: 'Oshimili North',
  OSSO: 'Oshimili South',
  PATA: 'Patani',
  SAPE: 'Sapele',
  UDU: 'Udu',
  UGNO: 'Ughelli North',
  UGSO: 'Ughelli South',
  UKWU: 'Ukwuani',
  UVWI: 'Uvwie',
  WANO: 'Warri North',
  WASO: 'Warri South',
  WASW: 'Warri South West',
  AFNO: 'Afikpo North',
  AFSO: 'Afikpo South',
  EBON: 'Ebonyi',
  EZNO: 'Ezza North',
  EZSO: 'Ezza South',
  IKWO: 'Ikwo',
  ISHI: 'Ishielu',
  IVO: 'Ivo',
  IZZI: 'Izzi',
  OHAO: 'Ohaozara',
  OHAU: 'Ohaukwu',
  ONIC: 'Onicha',
  EGOR: 'Egor',
  ESCE: 'Esan Central',
  ESNE: 'Esan North-East',
  ESSE: 'Esan South-East',
  ESWE: 'Esan West',
  ETCE: 'Etsako Central',
  IGUE: 'Igueben',
  IKOK: 'Ikpoba Okha',
  ORHI: 'Orhionmwon',
  ORED: 'Oredo',
  OVNE: 'Ovia North-East',
  OVSW: 'Ovia South-West',
  OWEA: 'Owan East',
  OWWE: 'Owerri West',
  UHUN: 'Uhunmwonde',
  EFON: 'Efon',
  EKEA: 'Ekiti East',
  EKSW: 'Ekiti South-West',
  EKWE: 'Ekiti West',
  EMUR: 'Emure',
  GBON: 'Gbonyin',
  IDOS: 'Ido Osi',
  IJER: 'Ijero',
  IKER: 'Ikere',
  IKOL: 'Ikole',
  ILEJ: 'Ilejemeje',
  IRIF: 'Irepodun/Ifelodun',
  ISOR: 'Ise/Orun',
  MOBA: 'Moba',
  OYE: 'Oye',
  AWGU: 'Awgu',
  ENEA: 'Enugu East',
  ENNO: 'Enugu North',
  ENSO: 'Enugu South',
  EZEA: 'Ezeagu',
  IGET: 'Igbo Etiti',
  IGEN: 'Igbo Eze North',
  IGES: 'Igbo Eze South',
  ISUZ: 'Isi Uzo',
  NKEA: 'Nkanu East',
  NKWE: 'Nkwerre',
  NSUK: 'Nsukka',
  OJRI: 'Oji River',
  UDEN: 'Udenu',
  UDI: 'Udi',
  UZUW: 'Uzo Uwani',
  BWAR: 'Bwari',
  GWAG: 'Gwagwalada',
  KUJE: 'Kuje',
  KWAL: 'Kwali',
  MUAC: 'Municipal Area Council',
  BALA: 'Barkin Ladi',
  BILL: 'Billiri',
  DUKK: 'Dukku',
  FUNA: 'Funakaye',
  KALT: 'Kaltungo',
  KWAM: 'Kwami',
  NAFA: 'Nafada',
  SHON: 'Shongom',
  YADE: 'Yamaltu/Deba',
  AHMB: 'Ahiazu Mbaise',
  EHMB: 'Ehime Mbano',
  EZIN: 'Ezinihitte',
  IHUB: 'Ihitte/Uboma',
  IKED: 'Ikeduru',
  ISMB: 'Isiala Mbano',
  ISU: 'Isu',
  MBAI: 'Mbaitoli',
  NGOK: 'Ngor Okpala',
  NJAB: 'Njaba',
  NWAN: 'Nwangele',
  OBOW: 'Obafemi Owode',
  OGUT: 'Oguta',
  OHEG: 'Ohaji/Egbema',
  OKIG: 'Okigwe',
  ORLU: 'Orlu',
  ORSU: 'Orsu',
  OREA: 'Oru East',
  ORWE: 'Oru West',
  OWMU: 'Owerri Municipal',
  OWNO: 'Owerri North',
  UNUI: 'Unuimo',
  BABU: 'Babura',
  BIRI: 'Biriniwa',
  BIKU: 'Birnin Kudu',
  BUJI: 'Buji',
  DUTS: 'Dutsi',
  GAGA: 'Gagarawa',
  GARK: 'Garko',
  GUME: 'Gumel',
  GURI: 'Guri',
  GWAR: 'Gwarzo',
  GWIW: 'Gwiwa',
  HADE: 'Hadejia',
  JAHU: 'Jahun',
  KAHA: 'Kafin Hausa',
  KAZA: 'Kazaure',
  KIKA: 'Kiri Kasama',
  KIYA: 'Kiyawa',
  KAUG: 'Kaugama',
  MAIG: 'Maigatari',
  MAMA: 'Malam Madori',
  MIGA: 'Miga',
  RING: 'Ringim',
  RONI: 'Roni',
  SUTA: 'Sule Tankarkar',
  TAUR: 'Taura',
  YANK: 'Yankwashi',
  CHIK: 'Chikun',
  GIWA: 'Giwa',
  IGAB: 'Igabi',
  IKAR: 'Ikara',
  JABA: 'Jaba',
  JEMA: "Jema'a",
  KACH: 'Kachia',
  KANO: 'Kaduna North',
  KASO: 'Kaduna South',
  KAJU: 'Kajuru',
  KAUR: 'Kauru',
  KUBA: 'Kubau',
  KUDA: 'Kudan',
  LERE: 'Lere',
  MAKA: 'Makarfi',
  SAGA: 'Sabon Gari',
  SANG: 'Sanga',
  SOBA: 'Soba',
  ZAKA: 'Zangon Kataf',
  ZARI: 'Zaria',
  ALBA: 'Albasu',
  BAGW: 'Bagwai',
  BEBE: 'Bebeji',
  BICH: 'Bichi',
  BUNK: 'Bunkure',
  DALA: 'Dala',
  DAKU: 'Dawakin Kudu',
  DATO: 'Dawakin Tofa',
  DOGU: 'Doguwa',
  FAGG: 'Fagge',
  GABA: 'Gabasawa',
  GAYA: 'Gaya',
  GEZA: 'Gezawa',
  GWAL: 'Gwale',
  KABO: 'Kabo',
  KAMU: 'Kano Municipal',
  KARA: 'Karasuwa',
  KIBI: 'Kibiya',
  KIRU: 'Kiru',
  KUMB: 'Kumbotso',
  KUNC: 'Kunchi',
  KURA: 'Kura',
  MADO: 'Madobi',
  MAKO: 'Makoda',
  MINJ: 'Minjibir',
  NASA: 'Nasarawa',
  RANO: 'Rano',
  RIGA: 'Rimin Gado',
  ROGO: 'Rogo',
  SUMA: 'Sumaila',
  TAKA: 'Takai',
  TARA: 'Tarauni',
  TOFA: 'Tofa',
  TSAN: 'Tsanyawa',
  TUWA: 'Tudun Wada',
  UNGO: 'Ungogo',
  WARA: 'Warawa',
  WUDI: 'Wudil',
  BATA: 'Batagarawa',
  BATS: 'Batsari',
  BAUR: 'Baure',
  BIND: 'Bindawa',
  CHAR: 'Charanchi',
  DAND: 'Dandi',
  DANJ: 'Danja',
  DAMU: 'Dan Musa',
  DAUR: 'Daura',
  DUMA: 'Dutsin Ma',
  FASK: 'Faskari',
  FUNT: 'Funtua',
  INGA: 'Ingawa',
  JIBI: 'Jibia',
  KAFU: 'Kafur',
  KAIT: 'Kaita',
  KANK: 'Kanke',
  KATS: 'Katsina',
  KURF: 'Kurfi',
  KUSA: 'Kusada',
  "MAI'": "Mai'Adua",
  MALU: 'Malumfashi',
  MANI: 'Mani',
  MASH: 'Mashegu',
  MATA: 'Matazu',
  MUSA: 'Musawa',
  RIMI: 'Rimi',
  SABU: 'Sabuwa',
  SAFA: 'Safana',
  SAND: 'Sandamu',
  ZANG: 'Zango',
  ARDA: 'Arewa Dandi',
  ARGU: 'Argungu',
  AUGI: 'Augie',
  BAGU: 'Bagudo',
  BIKE: 'Birnin Kebbi',
  BUNZ: 'Bunza',
  FAKA: 'Fakai',
  GWAN: 'Gwandu',
  JEGA: 'Jega',
  KALG: 'Kalgo',
  KOBE: 'Koko/Besse',
  MAIY: 'Maiyama',
  NGAS: 'Ngaski',
  SAKA: 'Sakaba',
  SURU: 'Surulere',
  WADA: 'Wasagu/Danko',
  YAUR: 'Yauri',
  ZURU: 'Zuru',
  AJAO: 'Ajaokuta',
  ANKP: 'Ankpa',
  BASS: 'Bassa',
  DEKI: 'Dekina',
  IBAJ: 'Ibaji',
  IDAH: 'Idah',
  IGOD: 'Igalamela Odolu',
  IJUM: 'Ijumu',
  KABU: 'Kabba/Bunu',
  KOGI: 'Kogi',
  LOKO: 'Lokoja',
  MOMU: 'Mopa Muro',
  OFU: 'Ofu',
  OGMA: 'Ogori/Magongo',
  OKEH: 'Okehi',
  OKEN: 'Okene',
  OLAM: 'Olamaboro',
  OMAL: 'Omala',
  YAEA: 'Yagba East',
  YAWE: 'Yagba West',
  BARU: 'Baruten',
  EDU: 'Edu',
  EKIT: 'Ekiti',
  IFEL: 'Ifelodun',
  ILEA: 'Ilesa East',
  ILSO: 'Ilorin South',
  ILWE: 'Ilesa West',
  IREP: 'Irepo',
  ISIN: 'Isin',
  KAIA: 'Kaiama',
  MORO: 'Moro',
  OFFA: 'Offa',
  OKER: 'Oke Ero',
  OYUN: 'Oyun',
  PATE: 'Pategi',
  AJIF: 'Ajeromi-Ifelodun',
  ALIM: 'Alimosho',
  AMOD: 'Amuwo-Odofin',
  APAP: 'Apapa',
  BADA: 'Badagry',
  EPE: 'Epe',
  ETOS: 'Eti Osa',
  IBLE: 'Ibeju-Lekki',
  IFIJ: 'Ifako-Ijaiye',
  IKEJ: 'Ikeja',
  IKOR: 'Ikorodu',
  KOSO: 'Kosofe',
  LAIS: 'Lagos Island',
  LAMA: 'Lagos Mainland',
  MUSH: 'Mushin',
  OJO: 'Ojo',
  SHOM: 'Shomolu',
  AWE: 'Awe',
  DOMA: 'Doma',
  KARU: 'Karu',
  KEAN: 'Keana',
  KEFF: 'Keffi',
  KOKO: 'Kokona',
  LAFI: 'Lafia',
  NAEG: 'Nasarawa Egon',
  TOTO: 'Toto',
  WAMB: 'Wamba',
  AGWA: 'Agwara',
  BIDA: 'Bida',
  BORG: 'Borgu',
  BOSS: 'Bosso',
  CHAN: 'Chanchaga',
  EDAT: 'Edati',
  GBAK: 'Gbako',
  GURA: 'Gurara',
  KATC: 'Katcha',
  KONT: 'Kontagora',
  LAPA: 'Lapai',
  LAVU: 'Lavun',
  MAGA: 'Magama',
  MARI: 'Mariga',
  MOKW: 'Mokwa',
  MOYA: 'Moya',
  PAIK: 'Paikoro',
  RAFI: 'Rafi',
  RIJA: 'Rijau',
  SULE: 'Suleja',
  TAFA: 'Tafa',
  WUSH: 'Wushishi',
  ADOO: 'Ado-Odo/Ota',
  EGNO: 'Egbado North',
  EGSO: 'Egbado South',
  EWEK: 'Ewekoro',
  IFO: 'Ifo',
  IJEA: 'Ijebu East',
  IJNO: 'Ijebu North',
  IJNE: 'Ijebu North East',
  IJOD: 'Ijebu Ode',
  IKEN: 'Ikenne',
  IMAF: 'Imeko Afon',
  IPOK: 'Ipokia',
  ODED: 'Odeda',
  ODOG: 'Odogbolu',
  OGWA: 'Ogun Waterside',
  RENO: 'Remo North',
  SHAG: 'Shagari',
  AKNW: 'Akoko North-West',
  AKSW: 'Akoko South-West',
  AKSE: 'Akoko South-East',
  AKNO: 'Akure North',
  AKSO: 'Akure South',
  ESOD: 'Ese Odo',
  IDAN: 'Idanre',
  IFED: 'Ifedayo',
  ILAJ: 'Ilaje',
  ILOO: 'Ile Oluji/Okeigbo',
  IREL: 'Irele',
  ODIG: 'Odigbo',
  OKIT: 'Okitipupa',
  ONEA: 'Ondo East',
  ONWE: 'Ondo West',
  OSE: 'Ose',
  OWO: 'Owo',
  ATWE: 'Atakunmosa West',
  AIYE: 'Aiyedire',
  BOLU: 'Boluwaduro',
  BORI: 'Boripe',
  EDNO: 'Ede North',
  EDSO: 'Ede South',
  IFCE: 'Ife Central',
  IFEA: 'Ife East',
  IFNO: 'Ife North',
  IFSO: 'Ife South',
  EGBE: 'Egbeda',
  EJIG: 'Ejigbo',
  ILA: 'Ila',
  IREW: 'Irewole',
  ISOK: 'Isokan',
  IWO: 'Iwo',
  OBOK: 'Obokun',
  ODOT: 'Odo Otin',
  OLOL: 'Ola Oluwa',
  OLOR: 'Olorunsogo',
  ORIA: 'Oriade',
  OROL: 'Orolu',
  OSOG: 'Osogbo',
  AKIN: 'Akinyele',
  ATIB: 'Atiba',
  ATIS: 'Atisbo',
  IBNO: 'Ibarapa North',
  IBNE: 'Ibadan North-East',
  IBNW: 'Ibadan North-West',
  IBSE: 'Ibadan South-East',
  IBSW: 'Ibadan South-West',
  IBCE: 'Ibarapa Central',
  IBEA: 'Ibarapa East',
  IDO: 'Ido',
  ISEY: 'Iseyin',
  ITES: 'Itesiwaju',
  IWAJ: 'Iwajowa',
  KAJO: 'Kajola',
  LAGE: 'Lagelu',
  OGNO: 'Ogbomosho North',
  OGSO: 'Ogbomosho South',
  OGOL: 'Ogo Oluwa',
  OLUY: 'Oluyole',
  ONAR: 'Ona Ara',
  OREL: 'Orelope',
  ORIR: 'Ori Ire',
  OYO: 'Oyo',
  OYEA: 'Oyo East',
  SAEA: 'Saki East',
  SAWE: 'Saki West',
  JOEA: 'Jos East',
  JONO: 'Jos North',
  JOSO: 'Jos South',
  KANA: 'Kaura Namoda',
  LASO: 'Langtang South',
  LANO: 'Langtang North',
  MANG: 'Mangu',
  MIKA: 'Mikang',
  PANK: 'Pankshin',
  QUPA: "Qua'an Pan",
  RIYO: 'Riyom',
  SHEN: 'Shendam',
  WASE: 'Wase',
  AHEA: 'Ahoada East',
  AHWE: 'Ahoada West',
  AKTO: 'Akuku-Toru',
  ANDO: 'Andoni',
  ASTO: 'Asari-Toru',
  BONN: 'Bonny',
  DEGE: 'Degema',
  ELEM: 'Eleme',
  EMUO: 'Emuoha',
  ETCH: 'Etche',
  GOKA: 'Gokana',
  IKWE: 'Ikwerre',
  KHAN: 'Khana',
  OGEN: 'Ogba/Egbema/Ndoni',
  OGBO: 'Ogu/Bolo',
  OKRI: 'Okrika',
  OMUM: 'Omuma',
  OPNK: 'Opobo/Nkoro',
  OYIG: 'Oyigbo',
  POHA: 'Port Harcourt',
  TAI: 'Tai',
  BODI: 'Bodinga',
  DASH: 'Dange Shuni',
  GADA: 'Gada',
  GORO: 'Goronyo',
  GUDU: 'Gudu',
  GWAD: 'Gwadabawa',
  ILLE: 'Illela',
  ISA: 'Isa',
  KEBB: 'Kebbe',
  KWAR: 'Kware',
  RABA: 'Rabah',
  SABI: 'Sabon Birni',
  SILA: 'Silame',
  SONO: 'Sokoto North',
  SOSO: 'Sokoto South',
  TAMB: 'Tambuwal',
  TANG: 'Tangaza',
  TURE: 'Tureta',
  WAMA: 'Wamako',
  WURN: 'Wurno',
  YABO: 'Yabo',
  BALI: 'Bali',
  DONG: 'Donga',
  GASH: 'Gashaka',
  GASS: 'Gassol',
  IBI: 'Ibi',
  JALI: 'Jalingo',
  KALA: 'Karim Lamido',
  KUMI: 'Kumi',
  LAU: 'Lau',
  SARD: 'Sardauna',
  TAKU: 'Takum',
  USSA: 'Ussa',
  WUKA: 'Wukari',
  YORR: 'Yorro',
  ZING: 'Zing',
  BURS: 'Bursari',
  DAMA: 'Damaturu',
  FIKA: 'Fika',
  FUNE: 'Fune',
  GEID: 'Geidam',
  GUJB: 'Gujba',
  GULA: 'Gulani',
  JAKU: 'Jakusko',
  MACH: 'Machina',
  NANG: 'Nangere',
  NGUR: 'Nguru',
  POTI: 'Potiskum',
  TARM: 'Tarmuwa',
  YUNU: 'Yunusari',
  YUSU: 'Yusufari',
  BAKU: 'Bakura',
  BIMK: 'Birnin Magaji/Kiyaw',
  BUKK: 'Bukkuyum',
  BUNG: 'Bungudu',
  GUMM: 'Gummi',
  GUSA: 'Gusau',
  MARA: 'Maradun',
  MARU: 'Maru',
  SHIN: 'Shinkafi',
  TAMA: 'Talata Mafara',
  CHAF: 'Chafe',
  ZURM: 'Zurmi',
};
