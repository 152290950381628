import styled from "vue3-styled-components";

export const StyledButton = styled.button`
  background: #232a34;
  border-radius: 40px;
  border: 1px solid #232a34;
  outline: none;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:focus {
    outline: none;
  }

  @media only screen and (min-width: 280px) {
    padding: 0px 1.1rem;

    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "2.7rem")};
    font-size: 0.7rem;
  }

  @media only screen and (min-width: 300px) {
    padding: 0px 2.4rem;

    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "3rem")};
    font-size: 0.76rem;
  }

  @media only screen and (min-width: 600px) {
    padding: 0px 2.4rem;

    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "3.4rem")};
  }
`;
