import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  drivers: [],
  error: null,
});

const getters = {};

const mutations = {
  saveDrivers(state, drivers) {
    state.drivers = drivers;
  },
};

const actions = {
  async fetchAllDrivers({ commit }, params) {
    const { page } = params;
    try {
      const { data } = await APIConfig.get(`/driver/?page=${page}`);

      const drivers = data?.data.map((el) => {
        return {
          id: el._id,
          name: el.name,
        };
      });

      commit("saveDrivers", drivers);

      return drivers;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
