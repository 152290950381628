import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  procurementDetails: {},
  detailsModalStatus: false,
});

const getters = {
  getProcurementDetails: (state) => {
    return state.procurementDetails;
  },

  getDetailModalStatus: (state) => {
    return state.detailsModalStatus;
  },
};

const mutations = {
  setProcurementDetails(state, payload) {
    state.procurementDetails = payload;
  },

  setDetailsModalStatus(state, status) {
    console.log(status);
    state.detailsModalStatus = status;
  },
};

const actions = {
  setDetailsModalStatus({ commit }, status) {
    commit("setDetailsModalStatus", status);
  },
  async getCustomerProcurementDetails({ commit }) {
    try {
      const { data } = await APIConfig.get("procurement-details");

      commit("setProcurementDetails", data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async createCustomerProcurementDetails({ commit }, payload) {
    console.log(commit);

    try {
      const { data } = await APIConfig.post("procurement-details", payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateCustomerProcurementDetails({ commit }, payload) {
    console.log(commit);

    try {
      const { data } = await APIConfig.patch(
        `procurement-details/${payload._id}`,
        payload
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
