<template>
  <div class="filter-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="false"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 6% auto 0 auto; width: 92%">
        <h2 class="caption">Filter</h2>
        <p>Choose one or more of the following options</p>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="none"
            v-model="noFilterOption"
          />
          <label for="no-option">None</label>
        </div>
      </div>
    </section>

    <section class="authorisation-status">
      <div class="wrapper">
        <h5 class="authorisation-caption">Authorisation Status</h5>
        <div class="border"></div>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="Pending"
            name="status"
            @change="onChange($event)"
          />
          <label for="no-option">Pending</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="status"
            value="Approved"
            name="status"
            @change="onChange($event)"
          />
          <label for="status">Approved</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="status"
            value="Rejected"
            name="status"
            @change="onChange($event)"
          />
          <label for="status">Rejected</label>
        </div>
      </div>
    </section>

    <section class="transaction-history">
      <div class="wrapper">
        <h5 class="transaction-history__caption">Number Of Transactions</h5>
        <div class="container">
          <div class="input-container">
            <span>Min:</span>
            <input type="number" v-model="filterParams.minTransactionNumber" />
          </div>

          <div class="input-container">
            <span>Max:</span>
            <input type="number" v-model="filterParams.maxTransactionNumber" />
          </div>
        </div>
        <Slider
          v-model="noOfTransactionValue"
          :tooltips="tooltipsMode"
          class="slider"
          @update="handleTransactionChange(val)"
        />
      </div>
    </section>

    <section class="transaction-history">
      <div class="wrapper">
        <h5 class="transaction-history__caption">Total Amount Transacted</h5>
        <div class="container">
          <div class="input-container">
            <span>Min:</span>
            <input type="number" v-model="filterParams.minTransactionSize" />
          </div>

          <div class="input-container">
            <span>Max:</span>
            <input type="number" v-model="filterParams.maxTransactionSize" />
          </div>
        </div>
        <Slider
          v-model="transactionSizeValue"
          :tooltips="tooltipsMode"
          class="slider"
          @update="handleTransactionSizeChange(val)"
        />
      </div>
    </section>

    <section class="location-section">
      <div class="wrapper">
        <h5 class="authorisation-caption">Location</h5>

        <div class="border"></div>

        <Dropdown
          :options="states"
          class="select"
          dropdownTitle="State"
          @onSelect="handleSelect"
          fieldName="stateId"
        />

        <Dropdown
          :options="lgas"
          class="select"
          dropdownTitle="LGA"
          @onSelect="handleSelect"
          fieldName="lgaId"
        />

        <Dropdown
          v-if="communities.length > 0"
          :options="communities"
          class="select"
          dropdownTitle="Community"
          @onSelect="handleSelect"
          fieldName="communityId"
        />

        <div class="button-container">
          <GreenButton label="Apply Filter" @click="filterFarmers(page)" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import Loading from "vue-loading-overlay";

import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import Dropdown from "../../components/form-inputs/select-dropdown/Dropdown.vue";
import { statesDoc } from "../../data/docs/statesDoc";
import { lgasDoc } from "../../data/docs/lgasDoc";

import { convertToFilterQueryString } from "../../utils";

export default {
  name: "FarmersFilterForm",

  components: {
    Slider,
    GreenButton,
    Dropdown,
    Loading,
  },

  data() {
    return {
      noFilterOption: "",
      avgTonnagePerSupply: 0,
      noOfTransactions: 0,
      totalAmount: 0,
      states: statesDoc,
      lgas: lgasDoc,
      lazy: false,
      filterParams: {
        authorization_status: "",
        stateId: "",
        lgId: "",
        communityId: "",
        minTransactionNumber: 0,
        maxTransactionNumber: 100,
        minTransactionSize: 0,
        maxTransactionSize: 100,
      },
      loading: false,
      page: 1,
      communities: [],
      communityResp: [],
      communitiesNameToSlug: {},
      communitiesSlugToName: {},
      noOfTransactionValue: [0, 0],
      transactionSizeValue: [0, 0],
      tooltipsMode: false,
    };
  },

  mounted() {
    this.fetchCommunities();
  },

  methods: {
    onChange(event) {
      this.filterParams.authorization_status = event.target.value;
    },

    handleTransactionChange() {
      this.filterParams.minTransactionNumber = this.noOfTransactionValue[0];
      this.filterParams.maxTransactionNumber = this.noOfTransactionValue[1];
    },

    handleTransactionSizeChange() {
      this.filterParams.minTransactionSize = this.transactionSizeValue[0];
      this.filterParams.maxTransactionSize = this.transactionSizeValue[1];
    },

    handleSelect(obj) {
      this.filterParams = {
        ...this.filterParams,
        ...obj,
      };
    },

    async filterFarmers(page = 1) {
      const {
        stateId,
        lgId,
        communityId,
        authorization_status,
        minTransactionNumber,
        maxTransactionNumber,
        maxTransactionSize,
        minTransactionSize,
      } = this.filterParams;
      const payload = {
        stateId,
        lgId,
        communityId,
        authorization_status,
        minTransactionNumber,
        maxTransactionNumber,
        maxTransactionSize,
        minTransactionSize,
      };
      const filterQuery = convertToFilterQueryString(payload);

      this.loading = true;

      try {
        const { filteredFarmers } = await this.$store.dispatch(
          "farmers/filterFarmers",
          {
            page,
            filterQuery,
          }
        );
        if (filteredFarmers) this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCommunities() {
      try {
        const data = await this.$store.dispatch("community/fetchCommunities");
        this.communityResp = data;
        this.communities = data;
      } catch (error) {
        console.log(error.message);
      }
    },
  },

  watch: {
    avgTonnagePerSupply(newValue) {
      console.log(newValue);
    },

    communityResp(newValue) {
      newValue.forEach((el) => {
        this.communitiesNameToSlug = {
          ...this.communitiesNameToSlug,
          [el.name]: el.slug,
        };
      });

      //communitiesSlugToName
      newValue.forEach((el) => {
        this.communitiesSlugToName = {
          ...this.communitiesSlugToName,
          [el.slug]: el.name,
        };
      });
    },
  },
};
</script>

<style lang="css" scoped>
.filter-parent {
  background-color: white;
  height: 100%;
  /* border: 1px solid #000; */
}

.caption {
  color: #232a34;
  font-weight: 800;
  margin-bottom: 2px;
}

.caption + p {
  color: #000000;
  /* font-weight: 100; */
  font-size: 14px;
  margin-bottom: 2px;
}

.input-group {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 0px;
}

input {
  display: block;
  margin-right: 15px;
  cursor: pointer;
}

input[type="radio"] {
  accent-color: #669933;
}

label {
  margin-bottom: 0px;
  font-size: 10px;
  color: #333333;
  font-size: 13px;
}

.transaction-history {
  /* border: 1px solid red; */
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% auto 0 auto;
  width: 90%;
  /* border: 1px solid red; */
}

.authorisation-caption,
.transaction-history__caption {
  color: #000000;
  font-weight: 700;
}

.transaction-history__caption {
  margin: 20px 0px 8px 0px;
}

.border {
  border-bottom: 1px solid #545454;
  color: #a0a2a3;
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 25px;
  /* border: 1px solid red; */
}

.input-container span {
  align-self: center;
}

.input-container input {
  width: 70%;
  text-align: center;
}

p {
  color: #448800;
  font-weight: 500;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 7% auto 2% auto;
}

.slider {
  --slider-connect-bg: #448800;
  --slider-tooltip-bg: #448800;
  --slider-handle-ring-color: #ef444430;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
