<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Discovery Status</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="border"></div>

    <section class="form-container">
      <form class="form-container-div">
        <TextInput
          placeholder="Accepts"
          @onInputChange="handleInputChange"
          :inputValue="discovery_status"
          fieldName="status"
          disabled="true"
        />

        <TextInput
          placeholder="Actual Tonnage"
          @onInputChange="handleInputChange"
          :inputValue="discoveryForm.actual_tonnage"
          fieldName="actual_tonnage"
        />

        <TextInput
          placeholder="Amount Payable"
          @onInputChange="handleInputChange"
          :inputValue="discoveryForm.amount_payable"
          fieldName="amount_payable"
        />

        <TextInput
          placeholder="Buying Price/Ton"
          @onInputChange="handleInputChange"
          :inputValue="discoveryForm.buy_price_per_ton"
          fieldName="buy_price_per_ton"
          :disabled="true"
        />

        <SelectDropdown
          :options="['Not Yet Paid', 'Partially Paid', 'Fully Paid']"
          dropdownTitle="Payment Status"
          fieldName="payment_status"
          :reset="reset"
          @onSelect="handleSelect"
          :inputValue="discoveryForm.payment_status"
        />

        <TextInput
          placeholder="Amount Paid"
          @onInputChange="handleInputChange"
          :inputValue="discoveryForm.amount_paid"
          fieldName="amount_paid"
          v-if="isAmountPartialPaid"
          style="margin-top: 20px"
        />
      </form>
    </section>

    <div class="border"></div>

    <section class="button-container">
      <div class="btn-container">
        <GreenButton label="Submit" @click="submitDiscovery" />
      </div>
    </section>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import GreenButton from "../../buttons/green-button/GreenButton.vue";
import SelectDropdown from "../../form-inputs/select-dropdown/SelectDropdown.vue";
import TextInput from "../../form-inputs/text-input/TextInput.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "AcceptsModal1",
  components: {
    TextInput,
    SelectDropdown,
    GreenButton,
    Loading,
  },
  data() {
    return {
      discoveryForm: {
        discovery_status: "Accepted",
        actual_tonnage: "",
        amount_payable: "",
        payment_status: "",
        amount_paid: "",
        buy_price_per_ton: "",
      },
      isAmountPartialPaid: false,
      reset: false,
      loading: false,
    };
  },

  props: {
    discoveryId: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal");
    },

    handleSelect(obj) {
      this.discoveryForm = {
        ...this.discoveryForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.discoveryForm = {
        ...this.discoveryForm,
        ...obj,
      };
    },

    async submitDiscovery(e) {
      e.preventDefault();
      this.loading = true;

      let {
        discovery_status,
        actual_tonnage,
        amount_payable,
        payment_status,
        amount_paid,
        buy_price_per_ton,
      } = this.discoveryForm;

      if (!actual_tonnage || !amount_payable || !payment_status) {
        createToast("Please fill all fields", {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });

        return;
      }

      const id = this.discoveryId;

      const payload = {
        id,
        discovery_status,
        actual_tonnage: Number(actual_tonnage),
        amount_payable: Number(amount_payable - (amount_paid || 0)),
        payment_status,
        amount_paid: Number(amount_paid || 0),
        buy_price_per_ton: Number(buy_price_per_ton),
        isApproved: "Approved",
      };

      try {
        const data = await this.$store.dispatch(
          "discoveries/updateDiscoveryStatus",
          payload
        );

        if (data._id) {
          createToast("The discovery status has been updated successfully", {
            type: "success",
            position: "top-right",
            timeout: 3000,

            onClose: () => {
              window.location.reload();
            },
          });
        }

        this.$emit("onCloseModal", false);

        this.loading = false;
      } catch (error) {
        createToast(error.message, {
          type: "danger",
          position: "top-right",
          timeout: 3000,

          onClose: () => {
            window.location.reload();
          },
        });

        this.loading = false;
      }
    },
  },

  watch: {
    "discoveryForm.payment_status"(newValue) {
      if (newValue === "Partially Paid") {
        this.isAmountPartialPaid = true;
      } else {
        this.isAmountPartialPaid = false;
      }
    },

    "discoveryForm.amount_payable"(newValue) {
      if (newValue) {
        this.discoveryForm.buy_price_per_ton = Math.round(
          newValue / this.discoveryForm.actual_tonnage
        );
      }
    },

    "discoveryForm.actual_tonnage"(newValue) {
      if (newValue) {
        this.discoveryForm.buy_price_per_ton = Math.round(
          this.discoveryForm.amount_payable / newValue
        );
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.form-container-div {
  width: 72.5%;
  margin: 15px auto 0px auto;
}

.form-container {
  height: auto;
  display: flex;
  margin: 0 auto;
  width: 96%;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.button-container {
  width: 100%;
  height: 5rem;
  background: #fff;
  padding: 1rem;
}
</style>
