<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <h5 class="modal-caption">Compose SMS</h5>

          <div class="header-content-right">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-section">
      <form class="form">
        <TextAreaInput
          placeholder="Phone number"
          @onInputChange="handleInputChange"
          fieldName="phone"
          :disable="false"
        />

        <TextAreaInput
          placeholder="Message"
          @onInputChange="handleInputChange"
          fieldName="message"
          :disable="false"
        />

        <SearchableStatesDropdown
          :options="states"
          class="select"
          dropdownTitle="State"
          @onSelect="handleSelect"
          fieldName="state_id"
        />

        <SearchableStatesDropdown
          :options="lgas"
          class="select"
          dropdownTitle="Local Government"
          @onSelect="handleSelect"
          fieldName="lga_id"
        />

        <label>
          <input
            type="checkbox"
            v-model="checkboxValue"
            @change="handleCheckboxChange"
          />
          Schedule later
        </label>

        <DateField
          v-if="showDate"
          placeholder="Schedule later"
          @onInputChange="handleInputChange"
          fieldName="date"
          :disable="false"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton
        label="Submit"
        @click="sendSMS"
        :disable="isRequiredFieldsEmpty"
        width="300px"
      />
    </div>
  </div>
</template>

<script>
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";
import { states } from "../../../data/location/states";
import { LGAs } from "../../../data/location/lga";
import SearchableStatesDropdown from "../../../components/form-inputs/select-dropdown/SearchableStatesDropdown.vue";
import TextAreaInput from "@/components/form-inputs/text-area/TextAreaInput.vue";
import DateField from "../../../components/form-inputs/date-input/DateField.vue";

export default {
  name: "SendSMSModal",
  data() {
    return {
      millForm: this.$store.getters["mills/getMillToBeUpdated"],
      states: this.sortState(states),
      lgas: [],
      checkboxValue: false,
      showDate: false,
      loading: false,
    };
  },

  components: {
    DateField,
    TextAreaInput,
    GreenButton,
    Loading,
    SearchableStatesDropdown,
  },

  mounted() {},

  props: ["farmerPhoneNumber"],
  methods: {
    handleCheckboxChange() {
      if (this.checkboxValue) {
        this.showDate = true;
      } else {
        this.showDate = false;
      }
    },
    async sendSMS() {
      this.loading = true;
      let is_scheduled = null;
      let schedule_for = "";
      if (this.millForm.date) {
        is_scheduled = true;
        schedule_for = this.millForm.date;
      } else {
        is_scheduled = false;
      }
      const phone = this.millForm.phone.split(",");
      const PhoneNumbersArray = phone.map((number) => number.trim());
      const message = this.millForm.message;
      const smsObject = {
        recipients: PhoneNumbersArray,
        is_scheduled: is_scheduled,
        schedule_for: schedule_for,
        message: message,
      };

      const smsSent = await this.$store.dispatch(
        "overviews/sendSMSAction",
        smsObject
      );

      console.log("SMS send", smsSent);
      this.closeModal();
      this.loading = false;
    },
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    handleSelect(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    sortState(stateArr) {
      return stateArr.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },

    fetchLgas(state_slug) {
      try {
        this.loading = true;

        const lga = LGAs.filter((el) => el.parent_slug === state_slug);
        if (lga.length > 0) {
          lga.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }

        this.lgas = lga;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },

  watch: {
    "millForm.state_id"(newValue) {
      this.fetchLgas(newValue.slug);
    },
    "millForm.lga"(newValue) {
      if (newValue && this.millForm.state === "") {
        createToast("Please select a state", {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.input {
  width: 300px;
  height: 3.5rem;
  border: 0.5px solid #232a34;
  border-radius: 5px;
}

.input input {
  width: 100%;
  height: 100%;
}

.x-container:hover {
  background: #e8e8e8;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.category-container {
  margin-top: 70px;
}

.form-section {
  height: 430px;
  overflow-y: scroll;
}

.form {
  margin: 5% auto;
  width: 75%;
  height: 70%;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 100%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
