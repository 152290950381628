<template>
  <div class="modal-parent">
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Edit Dispatch</h5>

            <p>Step 1 of 2</p>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <Dropdown
          v-if="dispatchForm?.apName?.length"
          :options="APs"
          class="select"
          dropdownTitle="Aggregation Point"
          @onSelect="handleSelect"
          fieldName="apId"
          fieldValue="apName"
          :inputValue="dispatchForm?.apName"
        />

        <SearchableDropdown
          :options="['C1']"
          class="select"
          dropdownTitle="Factory"
          @onSelect="handleSelect"
          fieldName="factory"
          :inputValue="dispatchForm?.factory"
        />

        <NumberInput
          placeholder="Quantity to be moved"
          @onInputChange="handleInputChange"
          fieldName="qtyMoved"
          :inputValue="dispatchForm?.qtyMoved"
        />

        <Dropdown
          :options="vehicles"
          class="select"
          dropdownTitle="Vehicle"
          @onSelect="handleSelect"
          fieldName="vehicle"
          fieldValue="vehicleName"
          :inputValue="dispatchForm?.vehicleName"
        />

        <Dropdown
          :options="drivers"
          class="select"
          dropdownTitle="Driver"
          @onSelect="handleSelect"
          fieldName="driver"
          fieldValue="driverName"
          :inputValue="dispatchForm?.driverName"
        />

        <DateField
          placeholder="Dispatch Start Date"
          @onInputChange="handleInputChange"
          fieldName="dispatch_starts_at"
          :inputValue="dispatchForm?.dispatch_starts_at"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton label="Save and Continue" @click="routeToNextPage" />
    </div>
  </div>
</template>

<script>
import NumberInput from "../../../components/form-inputs/number-input/NumberInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import SearchableDropdown from "../../../components/form-inputs/select-dropdown/SearchableDropdown.vue";
import Dropdown from "../../../components/form-inputs/select-dropdown/Dropdown.vue";
import DateField from "../../../components/form-inputs/date-input/DateField.vue";

export default {
  name: "EditDispatchModal1",

  components: {
    GreenButton,
    DateField,
    Dropdown,
    SearchableDropdown,
    NumberInput,
  },

  data() {
    return {
      dispatchForm: this.$store.getters["dispatch/getDispatchToBeUpdated"] || {
        apId: "",
        apName: "",
        factory: "",
        qtyMoved: "",
        vehicle: "",
        vehicleName: "",
        driver: "",
        driverName: "",
        dispatch_starts_at: "",
      },
      globalInputtedDispatch: {},
      APs: [],
      vehicles: [],
      drivers: [],
    };
  },

  mounted() {
    this.fetchAPs();
    this.fetchVehicles();
    this.fetchDrivers();
  },

  computed: {
    isRequiredFieldsEmpty() {
      const isEmpty =
        !this.farmerForm["first_name"].length ||
        !this.farmerForm["last_name"].length;

      // console.log(isEmpty);

      return isEmpty;
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
      this.$store.dispatch("dispatch/setEditModalStatus", false);
    },

    handleSelect(obj) {
      this.dispatchForm = {
        ...this.dispatchForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.dispatchForm = {
        ...this.dispatchForm,
        ...obj,
      };
    },

    routeToNextPage() {
      this.$emit("onSetAddModalPage", 2);

      this.globalInputtedDispatch =
        this.$store.getters["dispatch/getDispatchToBeUpdated"];
      this.globalInputtedDispatch = {
        ...this.globalInputtedDispatch,
        ...this.dispatchForm,
      };

      this.$store.dispatch(
        "dispatch/setValuesForDispatchUpdate",
        this.globalInputtedDispatch
      );
    },

    async fetchAPs(page = 1) {
      this.APs = await this.$store.dispatch("aggregationPoint/fetchAllAPs", {
        page,
      });
    },

    async fetchVehicles(page = 1) {
      const { assets } = await this.$store.dispatch(
        "vehicleManagement/fetchVehicles",
        {
          page,
        }
      );

      this.vehicles = assets;
    },

    async fetchDrivers(page = 1) {
      this.drivers = await this.$store.dispatch("driver/fetchAllDrivers", {
        page,
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: auto;
}

.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 25%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 7% auto 2% auto;
}
</style>
