import { APIConfig } from "../../config/apiConfig";
import { roundToTwoDP } from "../../utils/numbers-formatter";
import moment from "moment";

const formatInventoriesData = (data) => {
  return data.map((inventory) => ({
    _id: inventory._id,
    inventoryId: inventory.inventoryId,
    purchaseDate: moment(inventory.purchaseDate).format("MM/DD/yyyy"),
    qty: inventory.purchaseQty,
    price: roundToTwoDP(inventory.amountPaid).toString(),
    logisticCost: roundToTwoDP(inventory.amountPaid).toString(),
    status: inventory.status,
    updatedAt: moment(inventory.updatedAt).format("MM/DD/yyyy"),
    processingStatus: inventory.processingStatus,
  }));
};

const formatSingleInventory = (inventory) => {
  return {
    ...inventory,
    purchaseId: inventory.purchaseId?.purchase_id,
    landingCost: inventory.purchaseId?.inBoundlogisticsCost,
    inventoryId: inventory.inventoryId,
    purchaseDate: moment(inventory.purchaseDate).format("MM/DD/yyyy"),
    qty: inventory.purchaseQty.toString(),
    price: roundToTwoDP(inventory.amountPaid).toString(),
    p1Cost: Math.round(inventory.p1Cost).toString(),
    p1CostPerTon: Math.round(inventory.p1CostPerTon).toString(),
    logisticsOverheadCostPerTon: Math.round(
      inventory.logisticsOverheadCostPerTon
    ).toString(),
    landedCostPerTon: Math.round(inventory.landedCostPerTon).toString(),
    logisticCost: roundToTwoDP(inventory.amountPaid).toString(),
    status: inventory.status,
    updatedAt: moment(inventory.updatedAt).format("MM/DD/yyyy"),
    processingStatus: inventory.processingStatus,
    inventoryLocation: inventory.locations.map((location) => ({
      ...location,
      updatedAt: moment(location.updatedAt).format("MM/DD/yyyy"),
    })),
    discrepancyData: inventory.locations.map((location, i) => ({
      no: i + 1,
      location: location.name,
      qty: location.tonnageAvailable,
      discrepancy: inventory?.discrepancySummary?.discrepancy[i]?.toFixed(2),
      discrepancyInNaira:
        inventory?.discrepancySummary.logisticsCost[i] *
        inventory?.discrepancySummary.discrepancy[i],
    })),
    totalDiscrepancy: inventory?.discrepancySummary?.totalDiscrepancy,
    totalDiscrepancyInNaira:
      inventory?.discrepancySummary?.totalDiscrepancyInNaira,
    createdBy: `${inventory?.createdBy?.first_name || "Nil"} ${
      inventory.createdBy?.last_name || "Nil"
    }`,
  };
};

const state = () => ({
  inventories: [],
  isDateFilter: false,
  error: null,
  deleteModalStatus: false,
  isFilter: false,
  filterMetaData: {
    filteredInventories: [],
    count: 0,
    query: "",
  },
});

const getters = {
  getDeleteModalStatus: (state) => {
    return state.deleteModalStatus;
  },

  getFilterStatus: (state) => {
    return state.isFilter;
  },

  getDateFilterStatus: (state) => {
    return state.isDateFilter;
  },

  getFilterMetaData: (state) => {
    return state.filterMetaData;
  },
};

const mutations = {
  saveInventories(state, inventories) {
    state.inventories = inventories;
  },

  setDateFilterStatus: (state, payload) => {
    state.isDateFilter = payload;
  },

  setDeleteModalStatus: (state, status) => {
    state.deleteModalStatus = status;
  },

  setFilterInventoryMetaData: (state, metaData) => {
    state.filterMetaData = {
      ...state.filterMetaData,
      ...metaData,
    };
  },

  setFilterStatus(state, payload) {
    state.isFilter = payload;
  },
};

const actions = {
  async fetchAllInventories({ commit }, params) {
    const { page, searchTerm, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/inventories/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
      );

      const inventories = formatInventoriesData(data?.data);

      commit("saveInventories", inventories);

      return {
        inventories: inventories || [],
        count: data?.count || 0,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async fetchOneInventory({ commit }, inventoryId) {
    console.log(commit);
    try {
      let { data } = await APIConfig.get(`/inventories/${inventoryId}`);

      data = formatSingleInventory(data);

      //   commit("setPurchase", data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteInventory({ commit }, inventoryId) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(`/inventories/${inventoryId}`);

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async deleteManyInventories({ commit }, ids) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(
        `/inventories?inventoriesIds=${ids}`
      );

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async filterInventories({ commit }, params) {
    const { page, filterQuery, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/inventories/filter/?page=${page}&limit=${limit}&${filterQuery}`
      );

      let response = {
        filteredInventories: [],
        count: 0,
        query: filterQuery,
      };

      if (data?.count > 0) {
        response.filteredInventories = formatInventoriesData(data?.data);
        response.count = data?.count;
        commit("setFilterStatus", true);
        commit("setFilterInventoryMetaData", response);
      } else {
        commit("setFilterInventoryMetaData", response);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async filterInventoriesByDatePeriod({ commit }, params) {
    const { page, filterQuery, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/inventories/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      commit("setDateFilterStatus", true);

      let response = {
        filteredInventories: [],
        count: 0,
        query: filterQuery,
      };

      if (Object.keys(data).length > 0) {
        const inventories = formatInventoriesData(data?.data);

        response.filteredInventories = inventories;
        response.count = data?.count;

        commit("setFilterInventoryMetaData", response);

        return response;
      } else {
        return {
          count: 0,
          filteredPurchases: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async approveManyInventories({ commit }, inventoryIds) {
    console.log(commit);
    try {
      const { data } = await APIConfig.patch(`/inventories/approvals`, {
        inventoryIds,
      });

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  updateDeleteModalStatus: ({ commit }, status) => {
    commit("setDeleteModalStatus", status);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
