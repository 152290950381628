<template>
  <div class="parent-modal">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Update PKS Settings</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-container">
      <form class="form-container-div">
        <!-- <TextInput
          placeholder="PKS_R/T P1"
          :inputValue="pksForm.pks_RTP1"
          @onInputChange="handleInputChange"
          fieldName="pks_RTP1"
          :disabledValue="true"
        /> -->

        <NumberInput
          placeholder="PKS_R/T P1"
          @onInputChange="handleInputChange"
          fieldName="pks_RTP1"
          :inputValue="pksForm.pks_RTP1"
          :isReadOnly="true"
        />

        <NumberInput
          placeholder="PKS_SP"
          :inputValue="pksForm.PKS_SP"
          @onInputChange="handleInputChange"
          fieldName="PKS_SP"
        />

        <NumberInput
          placeholder="P1 to PKS"
          fieldName="p1_to_pks"
          :inputValue="pksForm?.p1_to_pks"
          @onInputChange="handleInputChange"
        />
      </form>
    </section>

    <div class="border"></div>

    <div class="button-container">
      <GreenButton
        label="Update"
        width="10.5rem"
        height="2.9rem"
        @click="updatePKS"
      />
    </div>
  </div>
</template>

<script>
import NumberInput from "../../../components/form-inputs/number-input/NumberInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";

export default {
  name: "EditPKSModal",
  components: {
    // TextInput,
    GreenButton,
    NumberInput,
    Loading,
  },

  data() {
    return {
      loading: false,
      fullPage: true,
      pksForm: {
        pks_RTP1: this.pks_selling_price_per_ton || "",
        PKS_SP: this.pks_selling_price || "",
        p1_to_pks: this.p1_to_pks_qty || "",
      },
    };
  },

  props: {
    pks_selling_price: {
      type: String,
      required: true,
    },
    pks_selling_price_per_ton: {
      type: String,
      required: true,
    },

    p1_to_pks_qty: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },
    async updatePKS() {
      try {
        this.loading = true;

        const { pks_RTP1, PKS_SP, p1_to_pks } = this.pksForm;

        const payload = {
          pks_selling_price_per_ton: Number(pks_RTP1),
          pks_selling_price: Number(PKS_SP),
          p1_to_pks_qty: Number(p1_to_pks),
        };

        const response = await this.$store.dispatch(
          "profitInput/updatePriceAtomSettings",
          payload
        );

        if (response.acknowledged) {
          this.loading = false;
          this.$emit('updatePKSEvent', this.pksForm)
          createToast("PKS Settings Updated Successfully", {
            type: "success",
            position: "top-right",
            timeout: 3000,

            onClose: () => {
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    handleInputChange(obj) {
      this.pksForm = {
        ...this.pksForm,
        ...obj,
      };
    },
  },

  watch: {
    "pksForm.p1_to_pks": function (val) {
      console.log(val);
      if (val) {
        this.pksForm.pks_RTP1 = val * this.pksForm.PKS_SP;
      }

      this.handleInputChange({
        pks_RTP1: this.pksForm.pks_RTP1,
      });
    },

    "pksForm.PKS_SP": function (val) {
      console.log({ val });
      if (val) {
        this.pksForm.pks_RTP1 = val * this.pksForm.p1_to_pks;
      }

      this.handleInputChange({
        pks_RTP1: this.pksForm.pks_RTP1,
      });
    },
  },
};
</script>

<style scoped>
.parent-modal {
  height: 100%;
  background: #fff;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.form-container {
  height: 230px;
  overflow-y: scroll;
}

.form-container-div {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
</style>
