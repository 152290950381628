<template>
  <div class="filter-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="false"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 6% auto 0 auto; width: 92%">
        <h2 class="caption">Filter</h2>
        <p>Choose one or more of the following options</p>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="none"
            v-model="noFilterOption"
          />
          <label for="no-option">None</label>
        </div>
      </div>
    </section>

    <section class="authorisation-status">
      <div class="wrapper">
        <h5 class="authorisation-caption">Authorisation Status</h5>
        <div class="border"></div>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="Pending"
            name="status"
            @change="onAuthChange($event)"
          />
          <label for="no-option">Pending</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="status"
            value="Approved"
            name="status"
            @change="onAuthChange($event)"
          />
          <label for="status">Approved</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="status"
            value="Rejected"
            name="status"
            @change="onAuthChange($event)"
          />
          <label for="status">Rejected</label>
        </div>
      </div>
    </section>

    <section class="verification-status">
      <div class="wrapper">
        <h5 class="authorisation-caption">Verification Status</h5>
        <div class="border"></div>

        <div class="input-group">
          <input
            type="radio"
            id="pending"
            value="Pending"
            name="verification-status"
            @change="onVerificationChange($event)"
          />
          <label for="pending">Pending</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="verified"
            value="Verified"
            name="verification-status"
            @change="onVerificationChange($event)"
          />
          <label for="verified">Verified</label>
        </div>
      </div>
    </section>

    <div class="tonnage-section">
      <div class="wrapper">
        <h5 class="tonnage__caption">Tonnage</h5>
        <div class="tonnage__input-container">
          <div class="input-container">
            <span>Min:</span>
            <input type="number" v-model="filterParams.min" />
          </div>

          <div class="input-container">
            <span>Max:</span>
            <input type="number" v-model="filterParams.max" />
          </div>
        </div>
        <Slider
          v-model="value"
          :tooltips="tooltipsMode"
          class="slider"
          @update="handleChange(val)"
        />
      </div>
    </div>

    <section class="location-section">
      <div class="wrapper">
        <APDropdown
          :options="APs"
          class="select"
          dropdownTitle="Aggregation Point"
          @onSelect="handleSelect"
          fieldName="destination_id"
        />

        <SearchableDropdown
          :options="['C1']"
          class="select"
          dropdownTitle="Factory"
          @onSelect="handleSelect"
          fieldName="factory"
        />

        <div class="button-container">
          <GreenButton label="Apply Filter" @click="filterLogistics(page)" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import Slider from "vue3-slider";
import Loading from "vue-loading-overlay";
import Slider from "@vueform/slider";

import SearchableDropdown from "../../components/form-inputs/select-dropdown/SearchableDropdown.vue";
import APDropdown from "../../components/form-inputs/select-dropdown/APDropdown.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import { states } from "../../data/location/states";
import { lgas } from "../../data/location/lgaNames";

import { convertToFilterQueryString } from "../../utils";

export default {
  name: "FarmersFilterForm",

  components: { SearchableDropdown, GreenButton, APDropdown, Slider, Loading },

  data() {
    return {
      noFilterOption: "",
      avgTonnagePerSupply: 0,
      noOfTransactions: 0,
      totalAmount: 0,
      states: states,
      lgas: lgas,
      filterParams: {
        authorisationStatus: "",
        verification_status: "",
        destination_id: "",
        factory: "",
        min: 0,
        max: 100,
      },
      loading: false,
      page: 1,
      APs: [],
      value: [0, 100],
      tooltipsMode: false,
    };
  },

  props: {
    activeLogisticsType: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.fetchAPs();
  },

  methods: {
    onAuthChange(event) {
      this.filterParams.authorisationStatus = event.target.value;
    },

    onVerificationChange(event) {
      this.filterParams.verification_status = event.target.value;
    },

    handleChange() {
      this.filterParams.min = this.value[0];
      this.filterParams.max = this.value[1];

      // console.log("min ", this.filterParams.min);
      // console.log("max ", this.filterParams.max);
    },

    handleSelect(obj) {
      this.filterParams = {
        ...this.filterParams,
        ...obj,
      };
    },

    async fetchAPs(page = 1) {
      this.APs = await this.$store.dispatch("aggregationPoint/fetchAllAPs", {
        page,
      });
    },

    async filterLogistics(page = 1, limit = 100) {
      const {
        factory,
        authorisationStatus,
        verification_status,
        destination_id,
        min,
        max,
      } = this.filterParams;

      const payload = {
        factory,
        authorisationStatus,
        destination_id,
        verification_status,
        min,
        max,
      };
      const filterQuery = convertToFilterQueryString(payload);

      try {
        this.loading = true;
        if (this.activeLogisticsType === "Aggregation") {
          const { filteredAggregations } = await this.$store.dispatch(
            "aggregations/filterAggregations",
            {
              page,
              filterQuery,
              limit,
            }
          );

          if (filteredAggregations) this.loading = false;
        } else {
          const { filteredDispatches } = await this.$store.dispatch(
            "dispatch/filterDispatches",
            {
              page,
              filterQuery,
              limit,
            }
          );

          if (filteredDispatches) this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  watch: {
    avgTonnagePerSupply(newValue) {
      console.log(newValue);
    },
  },
};
</script>

<style lang="css" scoped>
.filter-parent {
  background-color: white;
  height: 100%;
  /* border: 1px solid #000; */
}

.caption {
  color: #232a34;
  font-weight: 800;
  margin-bottom: 2px;
}

.caption + p {
  color: #000000;
  /* font-weight: 100; */
  font-size: 14px;
  margin-bottom: 2px;
}

.input-group {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 0px;
}

input {
  display: block;
  margin-right: 15px;
  cursor: pointer;
}

input[type="radio"] {
  accent-color: #669933;
}

label {
  margin-bottom: 0px;
  font-size: 10px;
  color: #333333;
  font-size: 13px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% auto 0 auto;
  width: 90%;
  /* border: 1px solid red; */
}

.authorisation-caption,
.tonnage__caption {
  color: #000000;
  font-weight: 700;
}

.tonnage__input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 25px;
  /* border: 1px solid red; */
}

.input-container {
  display: flex;
  width: 35%;
  gap: 5px;
}

.input-container span {
  align-self: center;
}

.input-container input {
  width: 70%;
  text-align: center;
}

.input-container .transaction-history__caption {
  margin: 20px 0px 8px 0px;
}

.border {
  border-bottom: 1px solid #545454;
  color: #a0a2a3;
}

p {
  color: #448800;
  font-weight: 500;
}

.slider {
  --slider-connect-bg: #448800;
  --slider-tooltip-bg: #448800;
  --slider-handle-ring-color: #ef444430;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 7% auto 2% auto;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
