<template>
  <div class="filter-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <div class="x-container">
      <div class="x-container-image" @click="closeModal">
        <img src="../../../assets/x-icon.png" alt="x-icon" />
      </div>
    </div>

    <section class="header">
      <div style="margin: 6% auto 0 auto; width: 92%">
        <h2 class="caption">Filter</h2>
        <p>Choose one or more of the following options</p>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="none"
            v-model="noFilterOption"
          />
          <label for="no-option">None</label>
        </div>
      </div>
    </section>

    <section class="transaction-status">
      <div class="wrapper">
        <h5 class="transaction-caption">Discovery Status</h5>

        <div class="border"></div>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="Pending"
            name="status"
            @change="onDiscoveryStatusChange($event)"
          />
          <label for="status">Pending</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="Accepted"
            name="status"
            @change="onDiscoveryStatusChange($event)"
          />
          <label for="status">Accepted(Purchased)</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="no-option"
            value="Declined"
            name="status"
            @change="onDiscoveryStatusChange($event)"
          />
          <label for="status">Declined</label>
        </div>
      </div>
    </section>

    <section class="authorization-status">
      <div class="wrapper">
        <h5 class="authorization-caption">Approval Status</h5>
        <div class="border"></div>

        <div class="input-group">
          <input
            type="radio"
            id="pending"
            value="Pending"
            name="approvalStatus"
            @change="onAuthorizationStateChange($event)"
          />
          <label for="pending">Pending</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="accepted"
            value="Accepted"
            name="approvalStatus"
            @change="onAuthorizationStateChange($event)"
          />
          <label for="approved">Approved</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="rejected"
            value="Rejected"
            name="approvalStatus"
            @change="onAuthorizationStateChange($event)"
          />
          <label for="rejected">Rejected</label>
        </div>
      </div>
    </section>

    <section class="authorization-status">
      <div class="wrapper">
        <h5 class="authorization-caption">Category</h5>
        <div class="border"></div>

        <div class="input-group">
          <input
            type="radio"
            id="pile_estimate"
            value="pile_estimate"
            name="estimate_category"
            @change="onCategoryChange($event)"
          />
          <label for="pile_estimate">Pile Estimate</label>
        </div>

        <div class="input-group">
          <input
            type="radio"
            id="direct_purchase"
            value="direct_purchase"
            name="estimate_category"
            @change="onCategoryChange($event)"
          />
          <label for="direct_purchase">Direct Purchase</label>
        </div>
      </div>
    </section>

    <div class="tonnage-section">
      <div class="wrapper">
        <h5 class="tonnage__caption">Tonnage</h5>
        <div class="tonnage__input-container">
          <div class="input-container">
            <span>Min:</span>
            <input type="number" v-model="filterParams.minTonnage" />
          </div>

          <div class="input-container">
            <span>Max:</span>
            <input type="number" v-model="filterParams.maxTonnage" />
          </div>
        </div>
        <Slider
          v-model="value"
          :tooltips="tooltipsMode"
          class="slider"
          @update="handleChange(val)"
        />
      </div>
    </div>

    <div class="tonnage-section">
      <div class="wrapper">
        <h5 class="tonnage__caption">Amount Payable</h5>
        <div class="tonnage__input-container">
          <div class="input-container">
            <span>Min:</span>
            <input type="number" v-model="filterParams.minPrice" />
          </div>

          <div class="input-container">
            <span>Max:</span>
            <input type="number" v-model="filterParams.maxPrice" />
          </div>
        </div>
        <Slider
          v-model="value"
          :tooltips="tooltipsMode"
          class="slider"
          @update="handleChange(val)"
        />
      </div>
    </div>

    <section class="location-section">
      <div class="wrapper">
        <SearchableMillsDropdown
          :options="mills"
          dropdownTitle="Mill"
          fieldName="mill"
          @onSelect="handleSelect"
          :inputValue="mill"
        />
        <div class="button-container">
          <GreenButton label="Apply Filter" @click="filterDiscovery(page)" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import SearchableMillsDropdown from "../../form-inputs/select-dropdown/SearchableMillsDropdown.vue";

import Loading from "vue-loading-overlay";
import GreenButton from "../../buttons/green-button/GreenButton.vue";
import { convertToFilterQueryString } from "../../../utils";
export default {
  name: "DiscoverFilterModal",
  components: {
    Slider,
    Loading,
    GreenButton,
    SearchableMillsDropdown,
  },

  data() {
    return {
      noFilterOption: "",
      loading: false,
      filterParams: {
        approval_status: "",
        discovery_status: "",
        estimate_category: "",
        estimated_tonnage: 0,
        amountPayable: 0,
        minTonnage: 0,
        maxTonnage: 0,
        minPrice: 0,
        maxPrice: 0,
        millId: "",
      },
      value: [0, 100],
      page: 1,
      maxTon: 100,
      minTon: 0,
      maxAmount: 10000,
      minAmount: 0,
      tooltipsMode: false,
      mills: [],
    };
  },

  mounted() {
    this.getMills();
  },

  methods: {
    onDiscoveryStatusChange(e) {
      this.filterParams.discovery_status = e.target.value;
    },

    onCategoryChange(e) {
      this.filterParams.estimate_category = e.target.value;
    },

    async getMills() {
      const params = {
        page: 1,
        searchTerm: "",
      };
      try {
        const { mills } = await this.$store.dispatch("mills/getMills", params);

        console.log(mills);

        if (mills.length) {
          this.mills = mills.map((mill) => {
            return {
              id: mill.id,
              name: mill.name,
              latitude: mill.latitude,
              longitude: mill.longitude,
            };
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    onAuthorizationStateChange(e) {
      this.filterParams.approval_status = e.target.value;
    },

    handleSelect(obj) {
      const { mill } = obj;
      this.filterParams.millId = mill.id;
    },

    closeModal() {
      this.$emit("onCloseModal", false);
    },

    async filterDiscovery(page = 1, limit = 100) {
      const {
        approval_status,
        discovery_status,
        estimate_category,
        estimated_tonnage,
        amountPayable,
        minTonnage,
        maxTonnage,
        minPrice,
        maxPrice,
        millId,
      } = this.filterParams;

      const params = {
        approval_status,
        discovery_status,
        estimate_category,
        estimated_tonnage,
        amountPayable,
        minTonnage,
        maxTonnage,
        minPrice,
        maxPrice,
        millId,
      };

      const filterQuery = convertToFilterQueryString(params);

      try {
        this.loading = true;
        const { filteredDiscovery } = await this.$store.dispatch(
          "discoveries/filterDiscovery",
          {
            page,
            filterQuery,
            limit,
          }
        );

        if (filteredDiscovery) {
          this.$emit("onCloseModal");
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.filter-parent {
  background-color: white;
  height: 100%;
  /* border: 1px solid #000; */
  /* padding: 10px; */
}

.caption {
  color: #232a34;
  font-weight: 800;
  margin-bottom: 2px;
}

.caption + p {
  color: #000000;
  /* font-weight: 100; */
  font-size: 14px;
  margin-bottom: 2px;
}

.input-group {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 0px;
}

input {
  display: block;
  margin-right: 15px;
  cursor: pointer;
}

input[type="radio"] {
  accent-color: #669933;
}

label {
  margin-bottom: 0px;
  font-size: 10px;
  color: #333333;
  font-size: 13px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% auto 0 auto;
  width: 90%;
  /* border: 1px solid red; */
}

.authorization-caption,
.tonnage-filter-one {
  color: #000000;
  font-weight: 700;
}

.tonnage__caption {
  color: #000000;
  font-weight: 700;
}

.tonnage__input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 25px;
  /* border: 1px solid red; */
}

.input-container span {
  align-self: center;
}

.input-container input {
  width: 70%;
  text-align: center;
}

.authorization-caption,
.transaction-caption {
  color: #000;
  font-weight: 700;
}

.tonnage-filter-one {
  margin: 20px 0px 8px 0px;
}

.border {
  border-bottom: 1px solid #545454;
  color: #a0a2a3;
}

.tonnage-indicator {
  color: #448800;
  font-weight: 500;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 7% auto 2% auto;
}

.x-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.x-container-image {
  cursor: pointer;
}

.tonnage-filter-ton__setter,
.tonnage-filter-amount__setter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.max-tonnage,
.min-tonnage {
  display: flex;
  gap: 10px;
}

.min-amountPayable,
.max-amountPayable {
  display: flex;
  gap: 10px;
}

.min-text,
.max-text {
  font-weight: 500;
  font-size: 14px;
  color: #333;
}

.input {
  width: 50px;
}

.max-tonnage-input,
.min-tonnage-input {
  width: 100%;
}

.slider {
  --slider-connect-bg: #448800;
  --slider-tooltip-bg: #448800;
  --slider-handle-ring-color: #ef444430;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
