<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <!-- Header section -->
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <h5 class="modal-caption">Aggregation Details</h5>

          <div class="logo-container">
            <img src="../../assets/releaf-logo.png" alt="" />
          </div>

          <div class="icons-container">
            <div class="" style="cursor: pointer">
              <img
                src="../../assets/edit-icon.png"
                alt=""
                @click="openEditModal"
              />
            </div>
            <div class="" style="cursor: pointer" @click="openDeleteModal">
              <img src="../../assets/delete-icon.png" alt="" />
            </div>
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <section class="purchase__section" v-if="aggregation">
      <div class="purchases__aggregated">
        <p class="purchase__header">Purchase</p>
        <p class="purchase__header">Quantity Moved</p>
        <p class="purchase__header">Left</p>
      </div>
      <div
        class="purchases__aggregated"
        v-for="purchase in aggregation.aggregations"
        :key="purchase.id"
      >
        <p class="purchase__values">{{ purchase.purchaseId }}</p>
        <p class="purchase__values">{{ purchase.qtyMoved }} Ton(s)</p>
        <p class="purchase__values">
          {{ purchase.qtyLeft?.toFixed(2) || 0 }}
        </p>
      </div>
    </section>

    <!-- Dispatch details -->
    <section class="dispatch-details" v-if="aggregation">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="details">
          <div class="details__section">
            <InfoBox header="AP" info="Island" class="div1" />
            <InfoBox header="Logistic Type" info="Aggregation" class="div2" />
            <InfoBox
              header="Total Tonnage"
              :info="aggregation.qtyMoved + ' Ton(s)'"
              class="div3"
            />
            <InfoBox
              header="Vehicle"
              :info="aggregation.vehicle"
              class="div4"
            />
            <InfoBox
              header="Created By"
              :info="aggregation.createdBy"
              class="div5"
            />
            <InfoBox header="Driver" :info="aggregation.driver" class="div6" />
            <InfoBox
              header="Approval"
              :info="aggregation.authorisationStatus"
              :status="authStatusHash[aggregation.authorisationStatus]"
              class="div7"
            />
            <InfoBox
              header="Verification Status"
              :info="aggregation.verificationStatus"
              :status="verificationStatusHash[aggregation.verificationStatus]"
              class="div8"
            />
             <InfoBox
              header="Verified Tonnage"
              :info="aggregation.verifiedTonnage"
              class="div9"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="actions-container">
      <div class="" style="margin: 0 auto; width: 96%">
        <h6 class="action-section-header">Actions</h6>

        <div class="green-border"></div>

        <div class="action-buttons-container">
          <InvertedBlackButton
            label="Reject"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#fff"
            bgColor="#ED0A0A"
            @click="rejectDispatchEntry"
          />

          <GreenButton
            v-if="aggregation.status !== 'Approved'"
            label="Approve"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#669933"
            @click="approveAggregationEntry"
          />

          <InvertedBlackButton
            v-if="
              aggregation.status === 'Approved' &&
              aggregation.verificationStatus === 'Pending'
            "
            label="Verify"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#669933"
            @click="openVerifyAggregationModal"
          />
        </div>
      </div>
    </section>

    <!-- Dispatch details -->
  </div>
</template>

<script>
import InfoBox from "../../components/info-box/InfoBox.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";

export default {
  name: "AggregationDetailsModal",

  components: {
    InvertedBlackButton,
    InfoBox,
    GreenButton,
    Loading,
  },

  data() {
    return {
      modalStatus: "",
      aggregation: {},
      authStatusHash: {
        Approved: "approved",
        Rejected: "rejected",
        Pending: "pending",
      },
      verificationStatusHash: {
        Verified: "verified",
        Pending: "pending",
      },
      loading: false,
    };
  },

  props: {
    aggregationId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.fetchAggregation();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    async fetchAggregation() {
      const aggregationId = this.aggregationId;
      this.loading = true;

      try {
        const aggregation = await this.$store.dispatch(
          "aggregation/fetchOneAggregation",
          aggregationId
        );
        this.aggregation = {
          ...this.aggregation,
          ...aggregation,
        };

        this.loading = false;
      } catch (error) {
        console.log(error.message);
      }
    },

    openDeleteModal() {
      this.$emit("onCloseModal", false);
      this.$store.dispatch("aggregation/updateDeleteModalStatus", true);
      this.$store.dispatch("aggregation/setAggregationId", this.aggregationId);
      this.$store.dispatch("aggregation/setActiveLogisticsTab", "Aggregation");
    },

    async approveAggregationEntry() {
      const payload = {
        id: this.aggregationId,
        isApproved: true,
        authorisationStatus: "Approved",
      };

      this.loading = true;
      const data = await this.$store.dispatch(
        "aggregation/updateAggregation",
        payload
      );

      if (data._id) {
        this.loading = false;
        window.location.reload();
        createToast("Aggregation Approved successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            return window.location.reload();
          },
        });
      }
    },

    openEditModal() {
      this.$emit("onCloseModal", false);
      this.$store.dispatch(
        "aggregation/findAggregationToBeUpdated",
        this.aggregationId
      );
      this.$store.dispatch("aggregation/setEditModalStatus", true);
      this.$store.dispatch("aggregation/setActiveLogisticsTab", "Aggregation");
    },

    openVerifyAggregationModal() {
      this.$emit("onCloseModal", false);
      this.$store.dispatch(
        "aggregation/findAggregationToBeUpdated",
        this.aggregationId
      );
      this.$store.dispatch("aggregation/setVerifyModalStatus", true);
      this.$store.dispatch("aggregation/setActiveLogisticsTab", "Aggregation");
    },

    async rejectDispatchEntry() {
      const payload = {
        id: this.aggregationId,
        authorisationStatus: "Rejected",
      };

      this.loading = true;

      const data = await this.$store.dispatch(
        "aggregation/updateAggregation",
        payload
      );

      if (data._id) {
        this.loading = false;
        createToast("Aggregation Rejected successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            return window.location.reload();
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content,
.details,
.contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details,
.header-content {
  padding: 5px;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 19%;
}

.x-container:hover {
  background: #e8e8e8;
}

.border,
.green-border {
  margin-top: 10px;
  background: #ffffff;
  width: 100%;
}

.border {
  border-bottom: 1px solid #b3b3b3;
}

.green-border {
  border-bottom: 2px dotted #669933;
}

.purchase__section {
  background: #232a34;
  border-radius: 16px;
  width: 93%;
  height: auto;
  margin-inline: auto;
  margin-top: 25px;
  padding-top: 25px;
}

.purchases__aggregated {
  margin-inline: auto;
  width: 90%;
  height: 85%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.purchase__header {
  font-size: 14px;
  line-height: 24px;
}

.purchase__values {
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  align-self: start;
}

.dispatch-details {
  height: 70%;
}

.details__section {
  margin-top: 15px;
  width: 100%;
  height: 200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  padding: 0px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 1 / 4 / 2 / 5;
}
.div5 {
  grid-area: 2 / 1 / 3 / 2;
}
.div6 {
  grid-area: 2 / 2 / 3 / 3;
}
.div7 {
  grid-area: 2 / 3 / 3 / 4;
}
.div8 {
  grid-area: 2 / 4 / 3 / 5;
}

.approved {
  color: #669933 !important;
}

.rejected {
  color: #ed0a0a !important;
}

.pending {
  color: #f19306 !important;
}

.action-section-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 23px;
}

.action-buttons-container {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.action-button {
  margin-right: 15px;
}
</style>
