<template>
  <div class="chart-parent">
    <apexchart
      width="100%"
      height="100%"
      type="line"
      :options="chartOptions"
      :series="seriesLine"
      :colors="['#EA9A00', '#EA9A00']"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "KaaSCharts",
  data() {
    return {
      chartOptions: {
        chart: {
          id: "apexchart",
        },
        xaxis: {},
      },
      colors: ["#EA9A00", "#EA9A00"],

      seriesLine: [
        {
          name: "Purchase",
          data: this.data,
        },
      ],

      chartData: [],
    };
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    updateChart(data) {
      this.seriesLine = [
        {
          name: "Purchase",
          data: data,
        },
      ];
    },
  },

  watch: {
    data(newValue) {
      this.chartData = newValue;
      this.updateChart(this.chartData);
    },
  },
};
</script>
<style scoped>
.chart-parent {
  height: 100%;
}
</style>
