<template>
  <div class="dashboard-parent">
    <!-- <SendSMS /> -->
    <div class="mills-edit-modal" v-if="sendSMS">
      <SendSMS
        @onCloseModal="handleCloseModal"
        :farmerPhoneNumber="farmerPhoneNumber"
        :millId="itemClicked"
      />
    </div>

    <div class="dashboard-container">
      <section class="dashboard-metric">
        <div style="margin: 0px; width: 96%">
          <router-link to="/dashboard">
            <div class="dashboard-back">
              <InvertedBlackButton
                label="Go Back"
                width="5rem"
                height="2.6rem"
                padding="0px 0px"
              ></InvertedBlackButton>
            </div>
          </router-link>

          <div class="metric-header">
            <p class="metric-title-text">
              <b>Average Tonnes/Farmer</b> | {{ titleName }} Data Report
            </p>
          </div>
          <div class="border"></div>
          <div class="metric-data-range">
            <div class="metric-data-time-range">
              <div class="metric-toolbar">
                <span class="metric-data-caption">Range:</span>
                <div class="input-select">
                  <select
                    v-model="dateRange"
                    @change="changeP1DateAction($event)"
                    class="dateRange"
                  >
                    <!-- <option value="">Select an option</option> -->
                    <option value="this_week">This Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="this_month">This Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="this_year">This Year</option>
                    <option value="last_year">Last year</option>
                  </select>
                </div>
                <div class="input">
                  <input
                    type="text"
                    name=""
                    id="date"
                    placeholder="Select Start Date"
                    onfocus="(this.type = 'date')"
                    v-model="startDate"
                    @change="fetchFarmerDataByDate(page)"
                  />
                </div>

                <div class="input">
                  <input
                    type="text"
                    name=""
                    id="date"
                    placeholder="Select End Date"
                    onfocus="(this.type = 'date')"
                    v-model="endDate"
                    @change="fetchFarmerDataByDate(page)"
                  />
                </div>
              </div>
              <div class="metric-view-selector">
                <div class="input-select">
                  <select
                    v-model="convertP1"
                    @change="convertQuantityToValue($event)"
                  >
                    <!-- <option value="">Select an option</option> -->
                    <option value="">Quantity</option>
                    <option value="value">Value (₦)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="metric-kpi-block">
            <div
              class="metric-kpi-collapsible mr-4"
              v-show="collapsibleVisible"
            >
              <ul class="kpi-block">
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="purchaseTonnes"
                    kpiLabel="Currently in Tonnes"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="totalPreviousWeight"
                    kpiLabel="Previously"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="percentChange"
                    kpiLabel="% Change"
                    kpiColor1="#8fc54d"
                  >
                  </StyledMetricText>
                </li>
              </ul>
            </div>
            <div class="metric-kpi-collapsible" v-show="collapsibleVisible">
              <ul class="kpi-block">
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="totalCommitment"
                    kpiLabel="Total Commitment"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="commitmentTrend"
                    kpiLabel="Commitment Trend"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="percentCompletion"
                    kpiLabel="% Completion"
                    kpiColor1="#EA9A01"
                  >
                  </StyledMetricText>
                </li>
              </ul>
            </div>
          </div>

          <!-- Table -->
          <div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  >Table</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  >Analytics</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <!-- Table -->
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="table">
                  <!-- Top Nav area -->
                  <div class="mt-5 topNav">
                    <nav class="navbar">
                      <div class="navbar-left"></div>
                      <!-- right nav -->
                      <div class="navbar-right">
                        <div class="search-form">
                          <input
                            type="text"
                            class="search-input"
                            placeholder="Search by farmer's name or id"
                            v-model="searchTerm"
                            @keyup.enter="handleSearchText($event)"
                          />
                          <button>
                            <img
                              @click="handleSearchText($event)"
                              src="../../../assets/search-icon.png"
                              alt=""
                            />
                          </button>
                        </div>

                        <div class="buttons-container">
                          <div class="">
                            <download-excel :data="overviewsByFarmer">
                              <InvertedBlackButton
                                label="Export"
                                width="8rem"
                                height="2.6rem"
                                iconName="excel-icon"
                              ></InvertedBlackButton>
                            </download-excel>
                          </div>
                          <div class="">
                            <BlackButton
                              label="Open Filter"
                              width="10rem"
                              height="2.6rem"
                              iconName="filter-icon"
                              @click="openFilterModal"
                            />
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>

                  <!-- Easy Table component -->
                  <div class="data-table-container">
                    <EasyDataTable
                      v-model:server-options="paginationMetaData"
                      v-model:items-selected="itemsSelected"
                      :server-items-length="count"
                      body-text-direction="center"
                      header-text-direction="center"
                      :headers="headers"
                      :items="overviewsByFarmer"
                      alternating
                      table-class-name="customize-table"
                      class="data-table"
                      :loading="loading"
                      buttons-pagination
                    >
                      <template #item-actions="item">
                        <div class="actions-wrapper">
                          <div class="" @click="toggleActions(item)">
                            <div
                              class="action-options"
                              v-if="
                                isActionsOpened &&
                                item.farmerRFID === itemClicked
                              "
                            >
                              <div class="item-wrapper">
                                <div
                                  class="actions-item"
                                  @click="openSMSModal(item)"
                                >
                                  <div class="image">
                                    <img
                                      src="../../../assets/envelope-solid.svg"
                                      alt=""
                                      class="action-icon green-eye"
                                    />
                                  </div>
                                  <p class="view-action">Send SMS</p>
                                </div>
                              </div>
                            </div>
                            <span>&#8942;</span>
                          </div>
                        </div>
                      </template>
                    </EasyDataTable>
                  </div>
                </div>
              </div>
              <!-- Analytics -->
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div class="analytics">
                  <!-- Top Nav area -->
                  <div class="mt-5 topNav">
                    <nav class="navbar">
                      <!-- left nav -->
                      <div class="navbar-left">
                        <label class="line" for="dropdown">Type:</label>
                        <select
                          id="dropdown"
                          class="analytics-select"
                          v-model="chartType"
                          @change="handleChartChange"
                        >
                          <option value="">All Farmers</option>
                          <option value="Individual">Individual</option>
                        </select>
                        <div
                          v-if="showSearchIndividualFarmer"
                          class="search-form farmer-search"
                        >
                          <input
                            type="text"
                            class="search-input"
                            placeholder="Search by farmer's name or id"
                            v-model="searchFarmerTerm"
                            @keyup.enter="searchFarmerDataByDate($event)"
                          />
                          <button @click="searchFarmerDataByDate($event)">
                            <img src="../../../assets/search-icon.png" alt="" />
                          </button>
                        </div>
                      </div>
                      <!-- right nav -->
                      <div class="navbar-right">
                        <select id="dropdown" class="table-select">
                          <option value="option1">Total Supply Volume</option>
                          <option value="option2">Average Tonnes/Farmer</option>
                        </select>
                      </div>
                    </nav>
                  </div>
                  <div class="chart-data">
                    <LineCharts :series="purchSeriesData" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-view />
        </div>
      </section>
    </div>
    <!-- Overlay -->
    <div
      class="dark-overlay"
      v-if="
        isDeleteMillsModalOpened ||
        isFilterMillsModalOpened ||
        sendSMS ||
        isAddMillOpened2 ||
        isAddMillOpened1 ||
        isMillsDetailsModalOpened ||
        isApprovalStatus
      "
    ></div>
  </div>
</template>

<script>
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import StyledMetricText from "../../../components/metric-text/MetricText.vue";
import { aveTonesTableHeaders } from "../table-headers/aveTonsFarmersHeaders";
import VueApexCharts from "vue-apexcharts";
import { convertToFilterQueryString } from "../../../utils";
import LineCharts from "../charts/LineCharts.vue";
import { nigerian_states, lgaList } from "../data/left-data";
import SendSMS from "../modals/SendSMS.vue";
import { formatDate } from "../../../utils/date-formatter";

export default {
  name: "AveTonsFarmerDash",

  components: {
    LineCharts,
    InvertedBlackButton,
    StyledMetricText,
    SendSMS,
  },

  data() {
    return {
      lgas: lgaList,
      lgaListNew: [],
      states: nigerian_states,
      selectedState: "",
      convertP1: "",
      searchTerm: "",
      purchSeriesData: [],
      VueApexCharts,
      dateRange: "this_week",
      customDateRange: "",
      farmerTonnes: [],
      overviewsByFarmer: [],
      collapsibleVisible: true,
      individualView: false,
      type: "",
      percentVal: "",
      headers: aveTonesTableHeaders,
      loading: false,
      overheadChart: "",
      overheadFinChart: "",
      view: "sourcing",
      sendSMS: false,
      sendSMStext: false,
      isChecked: false,
      isActionsOpened: false,
      itemClicked: "",
      isDetailsModalOpened: false,
      isDeleteModalOpened: false,
      isApprovalStatus: false,
      startDate: "",
      endDate: "",
      farmerPhoneNumber: "",
      chartType: "",
      searchFarmerTerm: "",
      showSearchIndividualFarmer: false,
      paginationMetaData: {
        page: 1,
        rowsPerPage: 10,
      },
      count: 0,
      pageNum: 1,
      titleName: "",
    };
  },

  mounted() {
    this.fetchOverviewsByFarmerRange();
    this.getFarmersChartByRange();
  },

  methods: {
    handleChartChange() {
      if (this.showSearchIndividualFarmer == false) {
        this.showSearchIndividualFarmer = true;
      } else if (this.showSearchIndividualFarmer == true) {
        this.showSearchIndividualFarmer = false;
        this.getFarmersChartByRange();
      }
    },
    handleSearchText(e) {
      this.searchTerm = e.target.value;
      this.fetchOverviewsByFarmerSearch(this.searchTerm);
    },

    changeP1DateAction(e) {
      this.dateRange = e.target.value;
      this.fetchOverviewsByFarmerRange(this.dateRange, 1);
      this.getFarmersChartByRange();
      if (this.dateRange) {
        this.convertP1 = "";
      }
    },

    async fetchOverviewsByFarmerSearch() {
      this.loading = true;
      this.timePeriod = `timePeriod=${this.preGlobalRangeQuery}`;
      const searchObject = {
        search: this.searchTerm,
        query: this.timePeriod,
      };
      if (this.globalDateQuery) {
        searchObject.query = this.globalDateQuery;
        this.timePeriod = "";
      }
      const searchResult = await this.$store.dispatch(
        `overviews/fetchOverviewsByFarmerSearch`,
        searchObject
      );
      this.mappedData = searchResult.data;
      const modifiedResponse = this.mappedData.map((result) => {
        const avgSupply = `${result.avgSupply.toFixed(2)} Tonnes`;
        const farmerRFID = result.farmerRFID;
        const name = result.name;
        const noOfTransactions = result.noOfTransactions;
        const commitment = `${result.commitment.toFixed(2)} Tonnes`;
        const phone = result.phone;
        const totalTonnage = `${result.totalTonnage.toFixed(2)} Tonnes`;

        const resulting = {
          avgSupply,
          name,
          farmerRFID,
          noOfTransactions,
          commitment,
          phone,
          totalTonnage,
        };
        return resulting;
      });
      this.overviewsByFarmer = modifiedResponse;
      this.loading = false;
    },

    async fetchOverviewsByFarmerRange(query = "this_week", page) {
      page = page ? page : 1;
      this.loading = true;
      this.preGlobalRangeQuery = query;
      this.globalDateQuery = "";
      this.startDate = "";
      this.endDate = "";
      const currentDate = new Date();
      const currentMonthName = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(currentDate);
      const currentYear = currentDate.getFullYear();
      this.titleName = `${currentMonthName}, ${currentYear}`;

      const { count, data, summary, currentP1Price } =
        await this.$store.dispatch("overviews/fetchOverviewsByFarmerRange", {
          page,
          filterQuery: query,
        });

      this.count = count;
      this.mappedData = data;
      this.valueP1Price = currentP1Price;

      const modifiedResponse = this.mappedData.map((result) => {
        const avgSupply = `${result.avgSupply.toFixed(2)} Tonnes`;
        const farmerRFID = result.farmerRFID;
        const name = result.name;
        const noOfTransactions = result.noOfTransactions;
        const commitment = `${result.commitment.toFixed(2)} Tonnes`;
        const phone = result.phone;
        const totalTonnage = `${result.totalTonnage.toFixed(2)} Tonnes`;
        const resulting = {
          avgSupply,
          name,
          farmerRFID,
          noOfTransactions,
          commitment,
          phone,
          totalTonnage,
        };
        return resulting;
      });
      this.overviewsByFarmer = modifiedResponse;
      this.overviewsByFarmerSummary = summary;
      this.purchaseTonnes = `${this.overviewsByFarmerSummary.averageTonsPerFarmer.toFixed(
        0
      )}T`;
      this.totalPreviousWeight = `${this.overviewsByFarmerSummary.totalPreviousWeight.toFixed(
        0
      )}T`;
      this.totalCommitment = `${this.overviewsByFarmerSummary.totalCommitment.toFixed(
        0
      )}T`;
      this.commitmentTrend = `${this.overviewsByFarmerSummary.totalSuppliedCommitment.toFixed(
        0
      )}T`;
      const numerator2 =
        this.overviewsByFarmerSummary.totalSuppliedCommitment.toFixed(0);
      const denominator2 =
        this.overviewsByFarmerSummary.totalCommitment.toFixed(0);

      if (denominator2 !== 0) {
        const commitTrend = (numerator2 / denominator2) * 100;
        isNaN(commitTrend)
          ? (this.percentCompletion = "0%")
          : (this.percentCompletion = `${commitTrend.toFixed(0)}%`);
      } else {
        this.percentCompletion = "0%";
      }

      const denominator = this.overviewsByFarmerSummary.averageTonsPerFarmer;
      const numerator = this.overviewsByFarmerSummary.totalPreviousWeight;

      if (denominator !== 0) {
        const perChange = (numerator / denominator) * 100;
        isNaN(perChange)
          ? (this.percentChange = "0%")
          : (this.percentChange = `${perChange.toFixed(0)}%`);
      } else {
        this.percentChange = "0%";
      }

      this.loading = false;
    },
    async fetchFarmerDataByDate(page = 1) {
      this.loading = true;
      if (this.startDate === "" || this.endDate === "") return;
      else if (this.startDate !== "" && this.endDate !== "") {
        this.dateRange = "";
        this.convertP1 = "";
        const query = convertToFilterQueryString({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        this.globalDateQuery = query;

        try {
          const { count, data, summary, currentP1Price } =
            await this.$store.dispatch("overviews/fetchFarmerOverviewsByDate", {
              page,
              filterQuery: this.globalDateQuery,
            });

          this.count = count;
          this.overviewsByFarmerSummary = summary;
          this.mappedData = data;
          this.valueP1Price = currentP1Price;
          this.searchFarmerDataByDate();

          const modifiedResponse = data.map((result) => {
            const avgSupply = `${result.avgSupply.toFixed(2)} Tonnes`;
            const farmerRFID = result.farmerRFID;
            const name = result.name;
            const noOfTransactions = result.noOfTransactions;
            const commitment = `${result.commitment.toFixed(2)} Tonnes`;
            const phone = result.phone;
            const totalTonnage = `${result.totalTonnage.toFixed(2)} Tonnes`;

            const resulting = {
              avgSupply,
              name,
              farmerRFID,
              noOfTransactions,
              commitment,
              phone,
              totalTonnage,
            };

            return resulting;
          });
          this.overviewsByFarmer = modifiedResponse;
          this.purchaseTonnes = `${this.overviewsByFarmerSummary.averageTonsPerFarmer.toFixed(
            0
          )}T`;
          this.totalPreviousWeight = `${this.overviewsByFarmerSummary.totalPreviousWeight.toFixed(
            0
          )}T`;
          const denominator =
            this.overviewsByFarmerSummary.averageTonsPerFarmer;
          const numerator = this.overviewsByFarmerSummary.totalPreviousWeight;

          if (denominator !== 0) {
            const perChange = (numerator / denominator) * 100;
            isNaN(perChange)
              ? (this.percentChange = "0%")
              : (this.percentChange = `${perChange.toFixed(0)}%`);
          } else {
            this.percentChange = "0%";
          }

          this.totalCommitment = `${this.overviewsByFarmerSummary.totalCommitment.toFixed(
            0
          )}T`;
          this.commitmentTrend = `${this.overviewsByFarmerSummary.totalSuppliedCommitment.toFixed(
            0
          )}T`;

          const numerator2 =
            this.overviewsByFarmerSummary.totalSuppliedCommitment.toFixed(0);
          const denominator2 =
            this.overviewsByFarmerSummary.totalCommitment.toFixed(0);

          if (denominator2 !== 0) {
            const commitTrend = (numerator2 / denominator2) * 100;
            isNaN(commitTrend)
              ? (this.percentCompletion = "0%")
              : (this.percentCompletion = `${commitTrend.toFixed(0)}%`);
          } else {
            this.percentCompletion = "0%";
          }

          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },

    async convertQuantityToValue(e) {
      this.convertP1 = e.target.value;
      function numberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (this.convertP1) {
        const convertedTonnes = (
          (this.overviewsByFarmerSummary.averageTonsPerFarmer *
            this.valueP1Price) /
          1000000
        ).toFixed(1);
        this.purchaseTonnes = `₦${convertedTonnes}M`;
        const convertedPreviousTonnes = (
          (this.overviewsByFarmerSummary.totalPreviousWeight *
            this.valueP1Price) /
          1000000
        ).toFixed(1);
        this.totalPreviousWeight = `₦${convertedPreviousTonnes}M`;
        const convertedCommitment = (
          (this.overviewsByFarmerSummary.totalCommitment * this.valueP1Price) /
          1000000
        ).toFixed(1);
        this.totalCommitment = `₦${convertedCommitment}M`;
        const convertedCommitmentTrend = (
          (this.overviewsByFarmerSummary.totalSuppliedCommitment *
            this.valueP1Price) /
          1000000
        ).toFixed(1);
        this.commitmentTrend = `₦${convertedCommitmentTrend}M`;
        this.loading = false;
        const modifiedResponse = this.mappedData.map((result) => {
          const localAvgSupply = (result.avgSupply * this.valueP1Price).toFixed(
            1
          );
          const avgSupply = numberWithCommas(localAvgSupply);
          const farmerRFID = result.farmerRFID;
          const name = result.name;
          const noOfTransactions = result.noOfTransactions;
          const localCommitment = (
            result.commitment * this.valueP1Price
          ).toFixed(1);
          const commitment = numberWithCommas(localCommitment);
          const phone = result.phone;
          const localTotalTonnage = (
            result.totalTonnage * this.valueP1Price
          ).toFixed(1);
          const totalTonnage = numberWithCommas(localTotalTonnage);
          const resulting = {
            avgSupply,
            name,
            farmerRFID,
            noOfTransactions,
            commitment,
            phone,
            totalTonnage,
          };
          return resulting;
        });
        this.overviewsByFarmer = modifiedResponse;
        return [
          this.purchaseTonnes,
          this.totalPreviousWeight,
          this.totalCommitment,
          this.commitmentTrend,
        ];
      } else {
        if (this.dateRange) {
          this.fetchOverviewsByFarmerRange(this.dateRange, 1);
        } else {
          this.fetchFarmerDataByDate();
        }
      }
    },

    async searchFarmerDataByDate(e) {
      this.loading = true;
      try {
        if (this.chartType) {
          this.searchFarmerTerm = e.target.value;
          this.timePeriod = `timePeriod=${this.preGlobalRangeQuery}`;
          const searchObject = {
            search: this.searchFarmerTerm,
            query: this.timePeriod,
          };
          if (this.globalDateQuery) {
            searchObject.query = this.globalDateQuery;
          } else if (this.globalDateQuery == "") {
            searchObject.query = this.rangeToDateQuery;
          }
          const { data } = await this.$store.dispatch(
            `overviews/fetchOverviewsByFarmerSearch`,
            searchObject
          );
          this.globalFarmerid = data[0]._id;
          const queryArray = {
            globalFarmerid: this.globalFarmerid,
            query: searchObject.query,
          };
          this.loading = true;
          this.purchSeriesData = await this.$store.dispatch(
            "overviews/FetchOverviewFarmersGraphIndividual",
            queryArray
          );
        } else {
          this.purchSeriesData = await this.$store.dispatch(
            "overviews/FetchOverviewFarmersGraph",
            this.globalDateQuery
          );
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async getFarmersChartByRange() {
      if (this.preGlobalRangeQuery == "this_week") {
        let today = new Date();
        let currentDayOfWeek = today.getDay();
        let startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - currentDayOfWeek);
        let endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + (6 - currentDayOfWeek));
        startOfWeek.setHours(0, 0, 0, 0);
        endOfWeek.setHours(23, 59, 59, 999);

        const date = `startDate=${formatDate(startOfWeek)}&endDate=${formatDate(
          endOfWeek
        )}`;
        this.rangeToDateQuery = date;

        this.purchSeriesData = await this.$store.dispatch(
          "overviews/FetchOverviewFarmersGraph",
          date
        );
      } else if (this.preGlobalRangeQuery == "last_week") {
        let today = new Date();
        let currentDayOfWeek = today.getDay();
        let startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - currentDayOfWeek);
        let endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + 6 - currentDayOfWeek);
        let startOfLastWeek = new Date(startOfWeek);
        startOfLastWeek.setDate(startOfWeek.getDate() - 7);
        let endOfLastWeek = new Date(endOfWeek);
        endOfLastWeek.setDate(endOfWeek.getDate() - 7);
        startOfLastWeek.setHours(0, 0, 0, 0);
        endOfLastWeek.setHours(23, 59, 59, 999);
        const date = `startDate=${formatDate(
          startOfLastWeek
        )}&endDate=${formatDate(endOfLastWeek)}`;
        this.rangeToDateQuery = date;

        this.purchSeriesData = await this.$store.dispatch(
          "overviews/FetchOverviewFarmersGraph",
          date
        );
      } else if (this.preGlobalRangeQuery == "this_month") {
        let today = new Date();
        let startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        let endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        startOfMonth.setHours(0, 0, 0, 0);
        endOfMonth.setHours(23, 59, 59, 999);
        const date = `startDate=${formatDate(
          startOfMonth
        )}&endDate=${formatDate(endOfMonth)}`;
        this.rangeToDateQuery = date;

        this.purchSeriesData = await this.$store.dispatch(
          "overviews/FetchOverviewFarmersGraph",
          date
        );
      } else if (this.preGlobalRangeQuery == "last_month") {
        let today = new Date();
        let startOfLastMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        let endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        startOfLastMonth.setHours(0, 0, 0, 0);
        endOfLastMonth.setHours(23, 59, 59, 999);
        const date = `startDate=${formatDate(
          startOfLastMonth
        )}&endDate=${formatDate(endOfLastMonth)}`;
        this.rangeToDateQuery = date;

        this.purchSeriesData = await this.$store.dispatch(
          "overviews/FetchOverviewFarmersGraph",
          date
        );
      } else if (this.preGlobalRangeQuery == "this_year") {
        let today = new Date();
        let startOfYear = new Date(today.getFullYear(), 0, 1);
        let endOfYear = new Date(today.getFullYear(), 11, 31);
        startOfYear.setHours(0, 0, 0, 0);
        endOfYear.setHours(23, 59, 59, 999);
        const date = `startDate=${formatDate(startOfYear)}&endDate=${formatDate(
          endOfYear
        )}`;
        this.rangeToDateQuery = date;

        this.purchSeriesData = await this.$store.dispatch(
          "overviews/FetchOverviewFarmersGraph",
          date
        );
      } else if (this.preGlobalRangeQuery == "last_year") {
        let today = new Date();
        let startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
        let endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
        startOfLastYear.setHours(0, 0, 0, 0);
        endOfLastYear.setHours(23, 59, 59, 999);
        const date = `startDate=${formatDate(
          startOfLastYear
        )}&endDate=${formatDate(endOfLastYear)}`;
        this.rangeToDateQuery = date;

        this.purchSeriesData = await this.$store.dispatch(
          "overviews/FetchOverviewFarmersGraph",
          date
        );
      }
    },

    getTarget(template, divisor) {
      let targetPurchase, divider, suffix;
      if (template) {
        switch (template) {
          case "last_week":
            targetPurchase = 200;
            divider = 6;
            suffix = "day";
            break;
          case "this_week":
            targetPurchase = 200;
            divider = 6;
            suffix = "day";
            break;
          case "last_month":
            targetPurchase = 800;
            divider = 4;
            suffix = "week";
            break;
          case "this_month":
            targetPurchase = 800;
            divider = 4;
            suffix = "week";
            break;
          case "this_year":
            targetPurchase = 9600;
            divider = 12;
            suffix = "month";
            break;
          case "last_year":
            targetPurchase = 9600;
            divider = 12;
            suffix = "month";
            break;
          default:
            targetPurchase = 800 * 12;
        }

        if (divisor === "yes") {
          targetPurchase = targetPurchase / divider;
        }
        return {
          targetPurchase,
          divider,
          suffix,
        };
      }
    },
    getTimeLeft(template) {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let end;
      let unit;

      switch (template) {
        case "this_week":
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + (6 - now.getDay())
          );
          unit = "day";
          break;
        case "last_week":
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - now.getDay() - 1
          );
          unit = "day";
          break;
        case "this_month":
          end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          unit = "week";
          break;
        case "last_month":
          end = new Date(now.getFullYear(), now.getMonth(), 0);
          unit = "week";
          break;
        case "last_year":
          end = new Date(now.getFullYear(), 0, 0);
          unit = "month";
          break;
        case "this_year":
          end = new Date(now.getFullYear() + 1, 0, 0);
          unit = "month";
          break;
        default:
          throw new Error("Invalid template provided.");
      }

      const diff = Math.ceil(
        (end.getTime() - today.getTime()) / (1000 * 3600 * 24)
      );

      if (unit === "day") {
        return Math.max(0, diff);
      } else if (unit === "week") {
        return Math.max(0, Math.floor(diff / 7));
      } else if (unit === "month") {
        const diffMonths =
          (end.getFullYear() - today.getFullYear()) * 12 +
          (end.getMonth() - today.getMonth());
        return Math.max(0, diffMonths);
      }
    },

    toggleActions(arg) {
      this.sendSMStext = !this.sendSMStext;
      this.itemClicked = arg.farmerRFID;
      this.isActionsOpened = !this.isActionsOpened;
    },
    openSMSModal(item) {
      this.isDetailsModalOpened = !this.isDetailsModalOpened;
      this.sendSMS = true;
      this.farmerPhoneNumber = item.phone;
    },

    handleCloseModal(status) {
      this.sendSMS = status;
      this.isDetailsModalOpened = status;
      this.isFilterModalOpened = status;
      this.isDeleteModalOpened = status;
      this.isApprovalStatus = status;
    },

    toggleCollasible() {
      this.collapsibleVisible = !this.collapsibleVisible;
    },

    checkType(event) {
      if (event.target.value === "individual") this.individualView = true;
      else this.individualView = false;
    },

    setActiveNav(nav) {
      this.activeNav = nav;
    },
  },
  watch: {
    paginationMetaData: {
      handler: function (newValue) {
        const isDateFilter = this.$store.getters["overviews/getIsDateFilter"];
        const isDateFilterRange =
          this.$store.getters["overviews/getIsDateFilterRange"];
        if (isDateFilter) {
          this.fetchFarmerDataByDate(newValue.page);
        } else if (isDateFilterRange) {
          const page = newValue.page;
          this.fetchOverviewsByFarmerRange(this.dateRange, page);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
vue3-easy-data-table__header th {
  padding: 5px !important;
}
.customize-table {
  --easy-table-header-item-padding: 10px 15px;
  --easy-table-header-background-color: #cfd0d5;
  --easy-table-body-item-padding: 15px 15px;
  --easy-table-body-even-row-background-color: #fff;
  --easy-table-body-row-background-color: #fff;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.data-table-container {
  width: 100%;
  height: 79.9%;
  overflow-x: hidden;
  overflow-y: auto;
}
.dashboard-parent {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  margin: 0 auto;
  background: #ffffff;
  padding: 30px;
}
.dashboard-back {
  margin-bottom: 30px;
}
.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-caption {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
}

.metric-title-text {
  font-size: 16px;
  font-weight: 300;
  color: #131313;
}

.metric-title-text b {
  font-weight: 800;
}

.x-button,
.image-metric {
  cursor: pointer;
}
.metric-data-range,
.metric-kpi-core,
.metric-kpi-collapsible,
.chart-wrapper {
  height: auto;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;
}

.metric-data-range {
  padding: 15px;
}

.metric-kpi-core {
  width: 60%;
  margin-right: 20px;
}

.metric-kpi-collapsible {
  width: 40%;
}

.metric-kpi-collapsible .metric-kpi {
  width: 33%;
}
.metric-data-time-range {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.metric-toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.metric-view-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.metric-data-caption {
  color: #333;
  font-weight: 400;
}

.metric-kpi-block {
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.kpi-block {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  width: 100%;
}

.metric-kpi {
  list-style: none;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  width: 25%;
  /* text-align: center; */
}

.metric-kpi:not(:last-child) {
  border-right: 1px solid #a7a7a726;
}

.metric-collapse {
  display: inline;
  float: right;
  font-size: 12px;
  font-weight: 400;
}

.input-select {
  height: 30px;
  width: 100px;
}

.input {
  width: 100px;
  height: 30px;
}

select {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

input {
  width: 100%;
  height: 100%;
}

.image-footer {
  width: 0px;
  height: 40px;
}

.month-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.month-caption-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.dashboard-chart {
  margin-top: 20px;
}

.timerange {
  display: flex;
  gap: 10px;
}

.location-range {
  display: flex;
  gap: 10px;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 40px 0;
  font-size: 0.8rem;
}

.chart-toolbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.chart-view-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chart-search {
  display: flex;
  width: 30%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-form {
  display: flex;
  width: 250px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #fff;
  margin-right: 20px;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #fff;
  color: #726e6e;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}
.chart-data {
  width: 100%;
}

.border {
  border: 1px solid #e7e7e7;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

a:hover {
  text-decoration: none;
}

/* Styles for the navbar */

.dateRange {
  margin-right: 10px;
  padding: 0px;
  border-radius: 0px;
  border: 1px solid #797979;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
}
.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left label {
  margin: 0px;
  margin-right: 10px;
}

.navbar-left label.line {
  margin: 0px;
  margin-right: 10px;
  width: 50px;
}

.navbar-left select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

select.analytics-select {
  background: none;
  border: 1px solid #ccc;
  padding: 10px 5px;
  border-radius: 4px;
}

select.table-select {
  background: none;
  border: 1px solid #ccc;
  padding: 10px 5px;
  border-radius: 4px;
}

select::-ms-expand {
  display: none;
}

select::after {
  content: "\25BC";
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.item-wrapper {
  width: 70%;
  margin: auto auto;
}

.actions-item {
  display: flex;
  cursor: pointer;
}
.actions-item p {
  margin: 0px;
  color: black;
}

.action-icon {
  margin-right: 5px;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

.farmer-search {
  margin-left: 20px;
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  position: absolute;
  width: 150%;
  right: 80px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
  gap: 10px;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.chart-data {
  width: 100%;
  height: 400px;
  border-radius: 4px;
}

.multi-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
}

.mills-edit-modal {
  max-width: 550px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}
</style>