<template>
  <div class="styled-metric-text">

    <styled-text
      :kpiColor1="kpiColor1"
    >
    {{ kpiValue1  }} <span v-if="kpiValue2">/ </span> <second-metric :kpiColor2="kpiColor2">  {{ kpiValue2 }}</second-metric>
    </styled-text>
    <p class="">{{ kpiLabel }}</p>
  </div>
</template>

<script>
import { StyledText, SecondMetric } from "./MetricText.styles.js";

export default {
  name: "MetricText",

  props: {
    kpiColor1: {
      type: String
    },
    kpiColor2: {
      type: String
    },
    kpiValue1: {
      type: Number
    },
    kpiValue2: {
      type: String || Number
    },
    kpiLabel: {
      type: String
    },

  },

  components: {
    StyledText,
    SecondMetric
  }

}
</script>

<style scoped>
.styled-metric-text {
  display: flex;
  flex-direction: column;
  padding-top: 15px ;
  width: 100%;
  /* padding-right: 20px; */
}

p {
  text-align: center;
  font-size: 11px;
  margin-top: 5px;
}
</style>