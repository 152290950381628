<template>
  <div class="mills-parent">
    <div class="mills-wrapper">
      <section class="mills-search-container">
        <div class="search-form">
          <input
            type="text"
            class="search-input"
            placeholder="Search by Mill name or mill ID"
            v-model="searchTerm"
            @keyup.enter="handleSearchText"
          />
          <button>
            <img
              class="search-button"
              src="../../assets/search-icon.png"
              alt="search icon"
            />
          </button>
        </div>

        <GreenButton
          label="Tag New Mill"
          width="12.9rem"
          iconName="add-icon"
          @click="openAddMillModal"
        />
      </section>

      <section class="date-filter-container">
        <div class="wrapper">
          <p>Search by Period</p>

          <div class="controls-container">
            <div class="inputs-container">
              <div class="input">
                <input
                  type="text"
                  name=""
                  id="date"
                  placeholder="Select Start Date"
                  onfocus="(this.type = 'date')"
                  v-model="startDate"
                />
              </div>

              <div class="input">
                <input
                  type="text"
                  name=""
                  id="date"
                  placeholder="Select End Date"
                  onfocus="(this.type = 'date')"
                  v-model="endDate"
                />
              </div>

              <GreenButton
                label="Fetch"
                width="5rem"
                height="2rem"
                @click="fetchByDatePeriod(page)"
              />
            </div>

            <div v-if="itemsSelected.length > 0" class="multi-actions">
              <i
                class="fa fa-trash"
                style="font-size: 24px; color: red; cursor: pointer"
                @click="openDeleteModal"
              ></i>

              <GreenButton
                label="Approve"
                width="5rem"
                height="2rem"
                @click="openApprovalModal"
              />
            </div>

            <div class="buttons-container">
              <div class="">
                <download-excel :data="millsJSON">
                  <InvertedBlackButton
                    label="Export"
                    width="8rem"
                    height="2.6rem"
                    iconName="excel-icon"
                  >
                  </InvertedBlackButton>
                </download-excel>
              </div>

              <div class="">
                <BlackButton
                  label="Open Filter"
                  width="10rem"
                  height="2.6rem"
                  iconName="filter-icon"
                  @click="openFilterModal"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="data-table-container">
        <EasyDataTable
          v-model:server-options="paginationMetaData"
          v-model:items-selected="itemsSelected"
          :server-items-length="count"
          :headers="headers"
          :items="millsList"
          alternating
          table-class-name="customize-table"
          class="data-table"
          :loading="loading"
          buttons-pagination
        >
          <template #item-actions="item">
            <div class="actions-wrapper">
              <div class="" @click="toggleActions(item)">
                <div
                  class="action-options"
                  v-if="isActionsOpened && item.id === itemClicked"
                >
                  <div class="item-wrapper">
                    <div class="actions-item" @click="openDetailsModal">
                      <div class="image">
                        <img
                          src="../../assets/green-eye.png"
                          alt=""
                          class="action-icon green-eye"
                        />
                      </div>
                      <p class="view-action">View details</p>
                    </div>
                    <div class="actions-item" @click="handleEdit(item.id)">
                      <div class="">
                        <img
                          src="../../assets/edit-icon.png"
                          alt=""
                          class="action-icon edit-icon"
                        />
                      </div>
                      <p class="edit-action">Edit</p>
                    </div>
                    <div class="actions-item" @click="openDeleteModal">
                      <div class="">
                        <img
                          src="../../assets/delete-icon.png"
                          alt=""
                          class="action-icon delete-icon"
                        />
                      </div>
                      <p class="delete-action">Delete</p>
                    </div>
                  </div>
                </div>
                <span>&#8942;</span>
              </div>
            </div>
          </template>
        </EasyDataTable>
      </section>
    </div>

    <div class="mills-details-modal" v-if="isMillsDetailsModalOpened">
      <MillsDetailsModal
        @onCloseModal="handleCloseModal"
        @openEditModal="openEditModal"
        @openDeleteModal="openDeleteModal"
        :millId="itemClicked"
      />
    </div>

    <div class="mills-add-modal-1" v-if="isAddMillOpened1">
      <AddMillModal
        @onCloseModal="handleCloseModal"
        @openAddMillsModal2="handleAddMillsModal"
      />
    </div>

    <div class="mills-add-modal-2" v-if="isAddMillOpened2">
      <AddMIllsModal2
        @onCloseModal="handleCloseModal"
        @openAddMillsModal="openAddMillModal"
        v-if="modalPageNum === 2"
      />
    </div>

    <div class="mills-edit-modal" v-if="isEditMillsModalOpened">
      <EditMillsModal @onCloseModal="handleCloseModal" :millId="itemClicked" />
    </div>

    <div class="filter-modal" v-if="isFilterMillsModalOpened">
      <FilterMillsModal @onCloseModal="handleCloseModal" />
    </div>

    <div class="mills-delete-modal" v-if="isDeleteMillsModalOpened">
      <DeleteMillsModal
        @onCloseModal="handleCloseModal"
        :millId="itemClicked"
      />
    </div>

    <div
      class="mills-delete-modal"
      v-if="isDeleteMillsModalOpened && selectedMillsIds.length"
    >
      <DeleteManyModal
        :ids="selectedMillsIds"
        heading="Mills"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <!-- Approval -->
    <div
      class="mills-delete-modal"
      v-if="isApprovalStatus && selectedMillsIds.length"
    >
      <ApproveManyModal
        :ids="selectedMillsIds"
        heading="Mills"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <!-- Overlay -->
    <div
      class="dark-overlay"
      v-if="
        isDeleteMillsModalOpened ||
        isFilterMillsModalOpened ||
        isEditMillsModalOpened ||
        isAddMillOpened2 ||
        isAddMillOpened1 ||
        isMillsDetailsModalOpened ||
        isApprovalStatus
      "
    ></div>
  </div>
</template>

<script>
import BlackButton from "../../components/buttons/black-button/BlackButton.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import { convertToFilterQueryString } from "../../utils";
import { millsTableHeaders } from "./header/headers";
import MillsDetailsModal from "./modals/MillsDetailsModal.vue";
import AddMillModal from "./modals/AddMillsModal.vue";
import AddMIllsModal2 from "./modals/AddMIllsModal2.vue";
import EditMillsModal from "./modals/EditMillModal.vue";
import DeleteMillsModal from "./modals/DeleteMillsModal.vue";
import FilterMillsModal from "./modals/FilterMillsModal.vue";
import DeleteManyModal from "../../components/modals/DeleteManyModal.vue";
import ApproveManyModal from "../../components/modals/ApproveManyModal.vue";

export default {
  name: "Mills",
  components: {
    GreenButton,
    BlackButton,
    InvertedBlackButton,
    MillsDetailsModal,
    AddMillModal,
    AddMIllsModal2,
    EditMillsModal,
    DeleteMillsModal,
    FilterMillsModal,
    DeleteManyModal,
    ApproveManyModal,
  },

  data() {
    return {
      millsList: [],
      itemsSelected: [],
      selectedMillsIds: [],
      count: 0,
      searchTerm: "",
      startDate: "",
      endDate: "",
      paginationMetaData: {
        page: 1,
        rowsPerPage: 100,
      },
      isActionOpened: false,
      headers: millsTableHeaders,
      itemClicked: "",
      millsJSON: [],
      loading: false,
      isActionsOpened: false,
      isMillsDetailsModalOpened: false,
      isAddMillOpened1: false,
      isAddMillOpened2: false,
      isEditMillsModalOpened: false,
      isFilterMillsModalOpened: false,
      isDeleteMillsModalOpened: false,
      isApprovalStatus: false,
      modalPageNum: 1,
      globalFilterQUery: "",
    };
  },

  mounted() {
    this.fetchAllMills();
  },

  methods: {
    openDetailsModal() {
      this.isMillsDetailsModalOpened = !this.isMillsDetailsModalOpened;
    },

    handleCloseModal(status) {
      this.isMillsDetailsModalOpened = status;
      this.isAddMillOpened1 = status;
      this.isAddMillOpened2 = status;
      this.isEditMillsModalOpened = status;
      this.isFilterMillsModalOpened = status;
      this.isDeleteMillsModalOpened = status;
      this.isApprovalStatus = status;
    },

    openAddMillModal() {
      this.isAddMillOpened1 = !this.isAddMillOpened1;
    },

    handleAddMillsModal(pageNum) {
      this.modalPageNum = pageNum;
      this.isAddMillOpened2 = !this.isAddMillOpened2;
    },

    openAddMillModal2() {
      this.isAddMillOpened2 = !this.isAddMillOpened2;
    },

    handleEdit(id) {
      this.isEditMillsModalOpened = !this.isEditMillsModalOpened;
      this.$store.dispatch("mills/findMillToBeUpdate", id);
    },

    openEditModal() {
      this.isEditMillsModalOpened = !this.isEditMillsModalOpened;
    },

    openFilterModal() {
      this.isFilterMillsModalOpened = !this.isFilterMillsModalOpened;
    },

    openDeleteModal() {
      this.isDeleteMillsModalOpened = !this.isDeleteMillsModalOpened;
    },

    openApprovalModal() {
      this.isApprovalStatus = !this.isApprovalStatus;
    },

    toggleActions(arg) {
      this.itemClicked = arg.id;
      this.isActionsOpened = !this.isActionsOpened;
    },
    async fetchAllMills(page = 1, limit = 100, searchTerm = "") {
      this.loading = true;
      try {
        const { mills, count } = await this.$store.dispatch("mills/getMills", {
          page,
          searchTerm,
          limit,
        });

        this.millsList = mills;

        this.millsJSON = mills;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async handleSearchText(e) {
      const pageNum = 1;
      await this.fetchAllMills(pageNum, 100, e.target.value);
    },

    async fetchByDatePeriod(page = 1, limit = 100) {
      if (!this.startDate || !this.endDate) {
        return;
      }

      const query = convertToFilterQueryString({
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.loading = true;

      try {
        const { mills, count } = await this.$store.dispatch(
          "mills/filterMillsDatePeriod",
          {
            page,
            filterQuery: query,
            limit,
          }
        );

        this.millsList = mills;
        this.millsJSON = mills;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async filterMills(page, limit = 100, filterQuery) {
      try {
        this.loading = true;

        const { filteredMills, count } = this.$store.dispatch(
          "mills/filterMills",
          {
            page,
            query: filterQuery,
            limit,
          }
        );

        this.millsList = filteredMills;
        this.millsJSON = filteredMills;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  computed: {
    filterMetaData() {
      return this.$store.getters["mills/getFilteredMillMetaData"];
    },
  },

  watch: {
    paginationMetaData: {
      handler: function (newValue) {
        // this.fetchAllMills(newValue.page);
        const isFilter = this.$store.getters["mills/getFilterStatus"];

        const isDateFilter = this.$store.getters["mills/getIsDateFilterStatus"];

        if (isFilter) {
          this.filterMills(
            newValue.page,
            newValue.rowsPerPage,
            this.globalFilterQUery
          );
        } else if (isDateFilter) {
          this.fetchByDatePeriod(newValue.page, newValue.rowsPerPage);
        } else {
          this.fetchAllMills(newValue.page, newValue.rowsPerPage);
        }
      },
    },

    millsList(newValue) {
      this.millsJSON = newValue;
      this.millsList = newValue;
    },

    filterMetaData(newValue) {
      const { filteredMills, count, query } = newValue;

      this.globalFilterQuery = query;
      this.millsList = filteredMills;
      this.millsJSON = filteredMills;
      this.count = count;
    },

    itemsSelected: {
      handler: function (newValue) {
        this.selectedMillsIds = newValue.map((mill) => mill.id);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.mills-parent {
  width: 100%;
  margin: 0 auto;
  background: #f9fafe;
  height: 100vh;
  overflow-y: scroll;
}

.mills-wrapper {
  width: 95%;
  margin: 1% auto 0 auto;
  height: 100%;
}

.mills-details-modal {
  max-width: 800px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.mills-add-modal-1,
.mills-edit-modal {
  max-width: 550px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.mills-add-modal-2 {
  max-width: 500px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.filter-modal {
  position: absolute;
  top: 0px;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  animation: slideFromRightToLeft 1s ease-in-out;
  animation-delay: 10ms;
  left: 72%;
  width: 450px;
}

.mills-delete-modal {
  max-width: 500px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.mills-search-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}
.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.multi-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
}

.date-filter-container {
  width: 100%;
  margin-top: 3%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 87%;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 25%;
}

.data-table-container {
  width: 100%;
  height: 79.9%;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;
  width: 127px;
  height: 32px;
  border-radius: 41px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.approved {
  color: #669933;
  background: rgba(102, 153, 51, 0.15);
}

.rejected {
  color: #ed0a0a;
  background: rgba(237, 10, 10, 0.15);
}

.pending {
  color: #f19306;
  background: rgba(241, 147, 6, 0.15);
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5px;

  position: absolute;
  width: 130%;
  height: auto;
  right: 60px;

  /* White */

  background: #ffffff;
  /* Drop down shadow */

  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
}

.item-wrapper {
  width: 90%;
  margin: 0 auto;
}

.actions-item {
  display: flex;
  cursor: pointer;
}

.action-icon {
  margin-right: 5px;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.view-action {
  color: #669933;
}

.edit-action {
  color: #ea9a00;
}

.delete-action {
  color: #ed0a0a;
}

.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 12px 12px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
  --easy-table-body-row-td-direction: center;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}
</style>
