<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Create {{ heading }} Target</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <NumberInput
          placeholder="Amount"
          @onInputChange="handleInputChange"
          fieldName="targetAmount"
          :inputValue="targetForm?.targetAmount"
        />

        <VueDatePicker v-model="period" range class="date-picker" />
      </form>
    </section>

    <div class="button-container">
      <GreenButton width="12rem" label="Submit" @click="createTarget" />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import NumberInput from "../../../components/form-inputs/number-input/NumberInput.vue";
import { NameConvention } from "../../../utils/nameConvention";

export default {
  name: "CreateTargetModal",

  components: {
    GreenButton,
    NumberInput,
    Loading,
  },

  data() {
    return {
      targetForm: {
        targetStartDate: "",
        targetEndDate: "",
        targetAmount: 0,
      },
      period: "",
      end: "",
      loading: false,
      isRefresh: false,
    };
  },

  computed: {
    formatHeading() {
      return this.heading.split(" ")[0];
    },
  },

  props: {
    itemId: {
      type: String,
      required: true,
    },

    heading: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.targetForm = {
        ...this.targetForm,
        ...obj,
      };
    },

    async createTarget() {
      const nameConvention = new NameConvention(this.heading);
      const headingInCamelCase = nameConvention.camelize() + "Costs";

      const { targetStartDate, targetEndDate, targetAmount } = this.targetForm;

      if (!targetStartDate || !targetEndDate || !targetAmount) {
        return createToast("All fields are required!", {
          type: "danger",
          timeout: 2000,
          position: "top-left",
        });
      }

      const payload = {
        targetStartDate,
        targetEndDate,
        targetAmount,
      };

      this.loading = true;
      const record = await this.$store.dispatch(
        `${headingInCamelCase}/createTarget`, // need to update api
        payload
      );

      if (record) {
        this.loading = false;
        createToast("Target created successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            this.$store.dispatch("overhead/setRefreshStatus", true);
            this.closeModal();
          },
        });
      }
    },
  },

  watch: {
    period(newValue) {
      if (newValue) {
        this.targetForm.targetStartDate = newValue[0]?.toISOString();
        this.targetForm.targetEndDate = newValue[1]?.toISOString();
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}

.date-picker {
  --dp-menu-min-width: 360px;
  border: 1.5px solid #3b3b3b;
  border-radius: 4px;
  overflow: hidden;
  outline: none;
  width: 100%;
}
</style>
