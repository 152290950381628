<template>
  <div>Target</div>
</template>

<script>
export default {
  name: "InventoryTarget",
};
</script>

<style scoped></style>
