export const stateHash = {
  Abia: "ABI",
  Adamawa: "ADA",
  "Akwa-Ibom": "AKW",
  "Anambra State": "ANA",
  "Bauchi State": "BAU",
  "Bayelsa State": "BAY",
  "Benue State": "BEN",
  "Borno State": "BOR",
  "Cross River": "CRO",
  "Delta State": "DEL",
  "Ebonyi State": "EBO",
  "Edo State": "EDO",
  "Ekiti State": "EKI",
  "Enugu State": "ENU",
  FCT: "FCT",
  "Gombe State": "GOM",
  "Imo State": "IMO",
  "Jigawa State": "JIG",
  "Kaduna State": "KAD",
  "Kano State": "KAN",
  "Katsina State": "KAT",
  "Kebbi State": "KEB",
  "Kogi State": "KOG",
  "Kwara State": "KWA",
  "Lagos State": "LAG",
  "Nasarawa State": "NAS",
  "Niger State": "NIG",
  "Ogun State": "OGU",
  "Ondo State": "OND",
  "Osun State": "OSU",
  "Oyo State": "OYO",
  "Plateau State": "PLA",
  "Rivers State": "RIV",
  "Sokoto State": "SKO",
  "Taraba State": "TAR",
  "Yobe State": "YOB",
  "Zamfara State": "ZAM",
};

export const stateSlugToName = {
  ABI: "Abia",
  ADA: "Adamawa",
  AKW: "Akwa-Ibom",
  ANA: "Anambra State",
  BAU: "Bauchi State",
  BAY: "Bayelsa State",
  BEN: "Benue State",
  BOR: "Borno State",
  CRO: "Cross River",
  DEL: "Delta State",
  EBO: "Ebonyi State",
  EDO: "Edo State",
  EKI: "Ekiti State",
  ENU: "Enugu State",
  FCT: "FCT",
  GOM: "Gombe State",
  IMO: "Imo State",
  JIG: "Jigawa State",
  KAD: "Kaduna State",
  KAN: "Kano State",
  KAT: "Katsina State",
  KEB: "Kebbi State",
  KOG: "Kogi State",
  KWA: "Kwara State",
  LAG: "Lagos State",
  NAS: "Nasarawa State",
  NIG: "Niger State",
  OGU: "Ogun State",
  OND: "Ondo State",
  OSU: "Osun State",
  OYO: "Oyo State",
  PLA: "Plateau State",
  RIV: "Rivers State",
  SKO: "Sokoto State",
  TAR: "Taraba State",
  YOB: "Yobe State",
  ZAM: "Zamfara State",
};
