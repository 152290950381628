<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <!-- Header section -->
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <h5 class="modal-caption">Farmer's Details</h5>

          <div class="logo-container">
            <img src="../../assets/releaf-logo.png" alt="" />
          </div>

          <div class="icons-container">
            <div class="" style="cursor: pointer">
              <img
                src="../../assets/edit-icon.png"
                alt=""
                @click="openEditModal"
              />
            </div>
            <div class="" style="cursor: pointer" @click="deleteFarmer">
              <img src="../../assets/delete-icon.png" alt="" />
            </div>
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- farmer details -->
    <section class="farmer-details" v-if="farmer">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="details">
          <div class="details_section">
            <InfoBox header="ID" :info="farmer.farmer_id" class="div1" />
            <InfoBox
              header="First Name"
              :info="farmer.first_name"
              class="div2"
            />
            <InfoBox header="Last Name" :info="farmer.last_name" class="div3" />
            <InfoBox
              header="Other Name"
              :info="farmer.other_name || 'Nil'"
              class="div4"
            />
            <InfoBox header="Community" :info="farmer.community" class="div5" />
            <InfoBox header="Gender" :info="farmer.sex" class="div6" />
          </div>
          <div class="details_section">
            <InfoBox header="Phone Number" :info="farmer.phone" class="div1" />
            <InfoBox header="Email" :info="farmer.email_address" class="div2" />
            <InfoBox
              header="LGA of Residence"
              :info="farmer.lga"
              class="div3"
            />
            <InfoBox
              header="State Of Residence"
              :info="farmer.state"
              class="div4"
            />
            <InfoBox
              header="Marital Status"
              :info="farmer.marital_status"
              class="div5"
            />
            <InfoBox
              header="Nationality"
              :info="farmer.nationality"
              class="div6"
            />
          </div>
        </div>

        <div class="details">
          <div class="details_section">
            <InfoBox header="Title" :info="farmer.title" class="div1" />
            <InfoBox
              header="State Of Origin"
              :info="farmer.state_of_origin"
              class="div2"
            />
            <InfoBox
              header="Supplier Type"
              :info="farmer.supplierType"
              class="div3"
            />
            <InfoBox header="Bank" :info="farmer.bank" class="div4" />
            <InfoBox header="BVN" :info="farmer.bvn || 'Nil'" class="div5" />
            <InfoBox
              header="Status"
              :info="farmer.authorization_status"
              class="div6"
            />
          </div>
          <div class="details_section">
            <InfoBox
              header="LGA Of Origin"
              :info="farmer.lga_of_origin"
              class="div1"
            />
            <InfoBox
              header="Smart phone"
              :info="farmer.have_smartphone"
              class="div2"
            />
            <InfoBox header="NIN" :info="farmer.nin" class="div3" />
            <InfoBox header="State" :info="farmer.state" class="div4" />
            <InfoBox
              header="Created By"
              :info="farmer.createdBy && farmer.createdBy.first_name"
              class="div5"
            />
            <InfoBox
              header="Channel"
              :info="farmer.channel || 'USSD'"
              class="div6"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Transaction History -->
    <section class="transaction-history-container">
      <div class="" style="margin: 0 auto; width: 96%">
        <h3 class="transaction-section-header">Transaction History</h3>

        <div class="green-border"></div>

        <div class="transaction-details">
          <div class="" style="margin: 10px auto; width: 95%; height: 90%">
            <div class="contents">
              <div class="section-1">
                <div class="content-box first">
                  <p class="transaction-label">Total Amount</p>
                  <p class="transaction-value">
                    #{{ transactionSummary.totalAmount }}
                  </p>
                </div>
                <div class="content-box second">
                  <p class="transaction-label">No of Transactions</p>
                  <p class="transaction-value">
                    {{ transactionSummary.noOfTransactions
                    }}<span>View Details</span>
                  </p>
                </div>
                <div class="content-box third">
                  <p class="transaction-label">Average Tonnage Per Supply</p>
                  <p class="transaction-value">
                    {{ transactionSummary.avgTonPerTransaction }}
                  </p>
                </div>
                <div class="content-box four">
                  <p class="transaction-label">Average Price Per Ton</p>
                  <p class="transaction-value">
                    #{{ transactionSummary.avgPricePerTon }}
                  </p>
                </div>
              </div>

              <div class="section-2">
                <div class="content-box five">
                  <p class="transaction-label">Total Tonnage Supplied</p>
                  <p class="transaction-value">
                    {{ transactionSummary.totalTonnage }}
                  </p>
                </div>
                <div class="content-box seven"></div>
                <div class="content-box eight"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Farmer's Commitment -->
    <section class="transaction-history-container">
      <div class="" style="margin: 0 auto; width: 96%">
        <h3 class="transaction-section-header">Commitment History</h3>

        <div class="green-border mt-4"></div>

        <div v-if="showNoData" class="no-data">
          <img class="enrol-farmer-img" src="../../assets/no-data.png" alt="" />
          <P class="enrol-farmer-p">No data to show here</P>
          <div @click="enrollCommitment" class="enrol-farmer-btn">
            Enrol farmer on a contract
          </div>
        </div>
        <div v-if="showFarmersCommitment" class="commitmentTable mt-4">
          <EasyDataTable
            v-model:server-options="paginationMetaData"
            v-model:items-selected="itemsSelected"
            :server-items-length="count"
            :headers="headerCommitment"
            :items="farmersCommitment"
            header-text-direction="center"
            body-text-direction="center"
            alternating
            table-class-name="customize-table"
            class="data-table"
            :loading="loading"
            buttons-pagination
          >
            <template #item-actions="item">
              <div class="actions-wrapper">
                <div class="" @click="toggleCommitmentModal(item)">
                  <div
                    class="action-options"
                    v-if="isActionsOpened && item.commitmentId === itemClicked"
                  >
                    <div class="item-wrapper">
                      <!-- @click="toggleEditModal(item)" -->
                      <div class="actions-item">
                        <div class="image">
                          <img
                            src="../../assets/pen-solid.svg"
                            alt=""
                            class="action-icon green-eye"
                          />
                        </div>
                        <p
                          @click="openEditCommentModal(item)"
                          class="view-action"
                        >
                          edit commitment
                        </p>
                      </div>
                      <hr />
                      <div
                        @click="deleteFarmerCommitment(item)"
                        class="actions-item"
                      >
                        <div class="image">
                          <img
                            src="../../assets/trash-can-solid.svg"
                            alt=""
                            class="action-icon green-eye"
                          />
                        </div>
                        <p class="view-action">delete commitment</p>
                      </div>
                    </div>
                  </div>
                  <span>&#8942;</span>
                </div>
              </div>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </section>

    <section class="actions-container">
      <div class="" style="margin: 0 auto; width: 96%">
        <h6 class="action-section-header">Actions</h6>

        <div class="green-border"></div>

        <div class="action-buttons-container">
          <InvertedBlackButton
            label="Reject"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#fff"
            bgColor="#ED0A0A"
            @click="rejectFarmerEntry"
          />

          <InvertedBlackButton
            label="Add Commitment Data"
            width="15.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#fff"
            bgColor="#669933"
            @click="enrollCommitment"
          />

          <GreenButton
            v-if="farmer.authorization_status === 'Pending'"
            label="Approve"
            width="8.4rem"
            height="2.9rem"
            class="action-button"
            labelColor="#669933"
            @click="approveFarmerEntry"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import InfoBox from "../../components/info-box/InfoBox.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";

import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import Loading from "vue-loading-overlay";
import { commitmentHeaders } from "./table-headers/headers";

export default {
  name: "DetailsModal",
  components: {
    InfoBox,
    InvertedBlackButton,
    GreenButton,
    Loading,
  },
  data() {
    return {
      headerCommitment: commitmentHeaders,
      farmersCommitment: [],
      modalStatus: "",
      farmer: {},
      loading: false,
      transactionSummary: {},
      isActionsOpened: false,
      editFarmerCommitment: false,
      itemClicked: "",
      editCommitment: "",
      editModal: false,
      commitmentId: "",
      showFarmersCommitment: false,
      showNoData: false,
      addCommitModal: false,
      edit: {},
    };
  },

  props: {
    farmerId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.fetchFarmer();
    this.getFarmerTransactionSummary();
    this.getFarmersCommitment();
  },

  computed: {
    errors() {
      return this.$store.getters["farmers/getError"];
    },
    getYearValidation() {
      return this.$store.getters["farmers/getFarmersCommitment"];
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },
    closeXmodal() {
      this.editModal = false;
    },

    async getFarmersCommitment() {
      const farmerId = this.farmerId;

      try {
        const fetchFarmerCommitment = await this.$store.dispatch(
          "farmers/fetchFarmersCommitment",
          farmerId
        );
        const commitSuppliedPromises = [];
        const commitment = fetchFarmerCommitment.map((response) => {
          this.commited = response.amount;
          const jsonDate = response.year;
          const dateObject = new Date(jsonDate);
          const year = dateObject.getFullYear();
          const farmersComment = response.farmerLastComment;
          const farmersID = response.farmerId._id;
          const commitmentID = response._id;
          const query = `${farmerId}?year=${year}`;

          const commitSuppliedPromise = this.$store.dispatch(
            "purchases/getFarmerTransactionSummaryAnnually",
            query
          );
          commitSuppliedPromises.push(commitSuppliedPromise);
          this.result = {
            year: year,
            commited: `${this.commited} Tonnes`,
            farmerLastComment: farmersComment,
            farmerId: farmersID,
            commitmentId: commitmentID,
          };
          return this.result;
        });
        const commitSuppliedValues = await Promise.all(commitSuppliedPromises);

        commitment.forEach((result, index) => {
          result.supplied = `${commitSuppliedValues[index].totalTonnage.toFixed(
            1
          )} Tonnes`;

          const numberValue = parseFloat(result.commited);

          const percentage = (
            (commitSuppliedValues[index].totalTonnage / numberValue) *
            100
          ).toFixed(0);
          result.percentage = `${isNaN(percentage) ? 0 : percentage}%`;
        });

        this.farmersCommitment = commitment;
        if (this.farmersCommitment.length == 0) {
          this.showFarmersCommitment = false;
          this.showNoData = true;
        } else {
          this.showFarmersCommitment = true;
          this.showNoData = false;
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    enrollCommitment() {
      const bool = true;
      this.$store.dispatch("overviews/showAddCommitmentModal", bool);
    },

    async toggleCommitmentModal(arg) {
      this.loading = true;
      this.itemClicked = arg.commitmentId;

      const commitmentId = this.itemClicked;
      try {
        const oneFarmerCommitment = await this.$store.dispatch(
          "farmers/fetchOneFarmerCommitment",
          commitmentId
        );
        const jsonDate = oneFarmerCommitment.year;
        const dateObject = new Date(jsonDate);
        const year = dateObject.getFullYear();
        const query = `${this.farmerId}?year=${year}`;

        const commitSuppliedPromise = await this.$store.dispatch(
          "purchases/getFarmerTransactionSummaryAnnually",
          query
        );

        const commitment = oneFarmerCommitment.amount;
        const supplied = commitSuppliedPromise.totalTonnage.toFixed(1);
        const percent = ((supplied / commitment) * 100).toFixed(1);
        const percentSupplied = isNaN(percent) ? 0 : percent;
        const comment = oneFarmerCommitment.farmerLastComment;
        const edit = {
          supplied,
          commitment,
          percentSupplied,
          comment,
          year,
          commitmentId,
        };
        this.edit = edit;
        this.loading = false;
        this.isActionsOpened = !this.isActionsOpened;
      } catch (error) {
        console.log(error.message);
      }
    },

    async openEditCommentModal() {
      const editModal = true;
      const edit = {
        editBool: editModal,
        editInfo: this.edit,
      };
      await this.$store.dispatch("overviews/updateCommitmentInfoAction", edit);
    },

    async deleteFarmerCommitment(arg) {
      const commID = arg.commitmentId;
      const query = {
        commID: commID,
        showDeleteModal: true,
      };
      await this.$store.dispatch("overviews/commID", query);
      this.editModal = false;
    },

    async getFarmerTransactionSummary() {
      try {
        const data = await this.$store.dispatch(
          "purchases/getFarmerTransactionSummary",
          this.farmerId
        );
        this.transactionSummary = {
          ...data,
        };
      } catch (error) {
        console.log(error);
      }
    },

    async fetchFarmer() {
      const farmerId = this.farmerId;
      this.loading = true;

      try {
        const farmer = await this.$store.dispatch(
          "farmers/fetchOneFarmer",
          farmerId
        );
        this.loading = false;

        this.farmer = {
          ...this.farmer,
          ...farmer,
        };
      } catch (error) {
        console.log(error.message);
      }
    },

    openEditModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openEditModal", true);
      this.$store.dispatch("farmers/findFarmerToBeUpdated", this.farmerId);
    },

    async deleteFarmer() {
      this.$emit("onCloseModal", false);
      this.$emit("openDeleteModal", true);
    },

    async approveFarmerEntry() {
      try {
        this.loading = true;
        const data = await this.$store.dispatch("farmers/updateFarmer", {
          id: this.farmerId,
          isApproved: true,
          authorization_status: "Approved",
        });

        if (data._id) {
          this.loading = false;
          createToast("Farmer Entry Approved Successfully!", {
            type: "success",
            timeout: 3000,
            position: "top-right",
            onClose: () => {
              return window.location.reload();
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async rejectFarmerEntry() {
      try {
        this.loading = true;
        const data = await this.$store.dispatch("farmers/updateFarmer", {
          id: this.farmerId,
          authorization_status: "Rejected",
        });

        if (data._id) {
          this.loading = false;
          createToast("Farmer Entry Rejected Successfully!", {
            type: "success",
            timeout: 3000,
            position: "top-right",
            onClose: () => {
              return window.location.reload();
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },

  watch: {
    errors(newError) {
      console.log(newError);
      this.loading = false;
      createToast(newError.message || newError.error, {
        type: "danger",
        timeout: 5000,
        position: "top-left",
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content,
.details,
.contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details,
.header-content {
  padding: 5px;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  width: 19%;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.x-container:hover {
  background: #e8e8e8;
}

.border,
.green-border {
  margin-top: 10px;
  background: #ffffff;
  width: 100%;
}

.border {
  border-bottom: 1px solid #b3b3b3;
}

.green-border {
  border-bottom: 2px dotted #669933;
}

.details_section {
  margin-top: 15px;
  width: 50%;
  height: 220px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  padding: 0px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 2 / 1 / 3 / 2;
}
.div4 {
  grid-area: 2 / 2 / 3 / 3;
}
.div5 {
  grid-area: 3 / 1 / 4 / 2;
}
.div6 {
  grid-area: 3 / 2 / 4 / 3;
}

.transaction-history-container {
  margin: 50px 0px;
}

.transaction-section-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 27px;
}

.transaction-details {
  background: #232a34;
  border-radius: 16px;
  height: 180px;
  margin-top: 20px;
  padding-top: 20px;
}

.section-1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;
  width: 94%;
  margin: 0;
}

.content-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.transaction-label {
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.8px;
}

.transaction-value {
  font-weight: 900;
  font-size: 12px;
}

.transaction-value span {
  font-weight: 400;
  margin-left: 10px;
  color: #8fc54d;
}

.first {
  grid-area: 1 / 1 / 2 / 2;
  align-self: center;
}
.second {
  grid-area: 1 / 2 / 2 / 3;
}
.third {
  grid-area: 2 / 1 / 3 / 2;
}
.four {
  grid-area: 2 / 2 / 3 / 3;
}

.section-2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  color: #fff;
  width: 60%;
  height: 80%;
  margin: 0;
}

.five {
  grid-area: 1 / 1 / 2 / 2;
}
.six {
  grid-area: 1 / 2 / 2 / 3;
}
.seven {
  grid-area: 2 / 1 / 3 / 2;
}
.eight {
  grid-area: 2 / 2 / 3 / 3;
}

.action-section-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 23px;
}

.action-buttons-container {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.action-button {
  margin-right: 15px;
}

.customize-table {
  --easy-table-header-item-padding: 10px 15px;
  --easy-table-header-background-color: #cfd0d5;
  --easy-table-body-item-padding: 15px 15px;
  --easy-table-body-even-row-background-color: #fff;
  --easy-table-body-row-background-color: #fff;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.actions-item {
  display: flex;
  cursor: pointer;
}
.actions-item p {
  margin: 0px;
  color: black;
}
hr {
  margin: 5px !important;
}

.action-icon {
  margin-left: 5px;
  margin-right: 10px;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  position: absolute;
  width: 150%;
  right: 80px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
  gap: 10px;
}

.item-wrapper {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.view-action {
  color: #282828;
}

.edit-commitment {
  position: fixed;
  top: 130%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: blue;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.edit-input {
  display: block;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 0px 20px;
}

.edit-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  border: #ccc 1px solid;
  height: 60px;
  margin: 0px 20px;
}
.cards {
  text-align: center;
  padding: 5px 25px;
  height: 40px;
}
.cards p {
  margin: 0px;
  display: block;
}
.cards span {
  margin: 0px;
  display: block;
  font-weight: bold;
  color: #282828;
}
.all {
  border-left: #ccc 1px solid;
}
.both {
  border-left: #ccc 1px solid;
  border-right: #ccc 1px solid;
}

input[type="text"] {
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 100%;
}
input[type="number"] {
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 100%;
}
input[type="text"]:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  outline: none;
}
input[type="number"]:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  outline: none;
}

.edit-input input[type="text"] {
  padding: 5px;
  border: 1px solid #353535;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}
.edit-input input[type="text"]:focus {
  border-color: #669933;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  outline: none;
}

.btn-commitment {
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #669933;
  width: 200px;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
}

.closeX {
  cursor: pointer;
}

.no-data {
  margin-top: 40px;
  padding: 20px;
  display: grid;
  place-items: center;
}

.enrol-farmer-img {
  width: 32px;
}
.enrol-farmer-p {
  color: #121212;
  margin: 20px 0;
  font-size: 12px;
}
.enrol-farmer-btn {
  color: #669933;
  font-size: 12px;
  padding: 10px 25px;
  background: #fff;
  border: #669933 1px solid;
  border-radius: 30px;
  cursor: pointer;
}

#data-table {
  border-collapse: collapse;
  width: 80%;
  margin: 20px auto;
}

#data-table th {
  border: 1px solid #ccc;
  text-align: center;
  height: 40px;
}
#data-table td {
  border: 1px solid #ccc;
  text-align: center;
}

#data-table th {
  background-color: #f0f0f0;
}
.trash-can {
  cursor: pointer;
}

select {
  padding: 8px;
  border: 1px solid rgba(64, 14, 172, 0);
  border-radius: 5px;
  background-color: #fff;
  font-size: 10px;
}
select:focus {
  outline: none;
  border-color: #ccc;
  box-shadow: 0px 2px 4px rgba(0, 123, 255, 0);
}

#add-row-button {
  width: 80px;
  color: #669933;
  cursor: pointer;
  font-size: 12px;
  margin-left: 550px;
}
</style>
