<template>
  <div class="parent">
    <div class="parent-wrapper">
      <div class="assets-management__container">
        <div
          class="management-type"
          :class="{ active: activeManagementType === 'Vehicle Management' }"
          @click="setActiveManagementType(vehicleManagement)"
        >
          {{ vehicleManagement }}
        </div>

        <div
          class="management-type"
          :class="{ active: activeManagementType === 'Driver Management' }"
          @click="setActiveManagementType(driverManagement)"
        >
          {{ driverManagement }}
        </div>

        <div
          class="management-type"
          :class="{ active: activeManagementType === 'AP Management' }"
          @click="setActiveManagementType(APManagement)"
        >
          {{ APManagement }}
        </div>

        <div
          class="management-type"
          :class="{ active: activeManagementType === 'Factory Management' }"
          @click="setActiveManagementType(factoryManagement)"
        >
          {{ factoryManagement }}
        </div>

        <div
          class="management-type"
          :class="{ active: activeManagementType === 'User Management' }"
          @click="setActiveManagementType(userManagement)"
        >
          {{ userManagement }}
        </div>
      </div>
      <!-- Search Bar Section -->
      <section class="search-bar-container">
        <div class="search-form">
          <input
            type="text"
            class="search-input"
            placeholder="Search by username or email address"
            @keyup.enter="handleSearchText"
          />
          <button><img src="../../assets/search-icon.png" alt="" /></button>
        </div>

        <div class="create-buttons__container">
          <GreenButton
            :label="
              activeManagementType === 'User Management'
                ? 'Add New User'
                : activeManagementType === 'Vehicle Management'
                ? 'Add New Vehicle'
                : activeManagementType === 'Driver Management'
                ? 'Add New Driver'
                : activeManagementType === 'AP Management'
                ? 'Add New AP'
                : activeManagementType === 'Factory Management'
                ? 'Add New Factory'
                : 'Add New User'
            "
            height="2.9rem"
            iconName="add-icon"
            @click="openCreateAssetModal"
          />
        </div>
      </section>

      <!-- Data table section -->
      <div class="data-table-container">
        <EasyDataTable
          v-model:server-options="paginationMetaData"
          v-model:items-selected="itemsSelected"
          :server-items-length="count"
          :headers="
            activeManagementType === 'User Management'
              ? usersTableHeaders
              : activeManagementType === 'Vehicle Management'
              ? vehiclesTableHeaders
              : activeManagementType === 'Driver Management'
              ? driversTableHeaders
              : activeManagementType === 'AP Management'
              ? apTableHeaders
              : activeManagementType === 'Factory Management'
              ? factoryTableHeaders
              : usersTableHeaders
          "
          :items="assets"
          alternating
          table-class-name="customize-table"
          class="data-table"
          :loading="loading"
          buttons-pagination
        >
          <template #item-fuelCost="item">
            <div>{{ vueNumberFormat(item.fuelCost, {}) }}</div>
          </template>

          <template #item-minCapacity="item">
            <div>{{ item.minCapacity }} Tons</div>
          </template>

          <template #item-maxCapacity="item">
            <div>{{ item.maxCapacity }} Tons</div>
          </template>

          <template #item-fuelEconomy="item">
            <div>{{ item.fuelEconomy }} L/KM</div>
          </template>

          <template #item-isActive="item">
            <div class="status-wrapper">
              <div
                class="status"
                :class="[item.isActive ? 'approved' : 'pending']"
              >
                {{ item.isActive ? "Active" : "Inactive" }}
              </div>
            </div>
          </template>

          <template #item-actions="item">
            <div class="actions-wrapper">
              <i
                class="fa fa-eye"
                @click="handleViewDetails(item)"
                v-if="activeManagementType === 'Vehicle Management'"
              ></i>
              <i class="fa fa-pencil" @click="handleEdit(item)"></i>
              <i class="fa fa-trash" @click="handleDelete(item)"></i>
            </div>
          </template>
        </EasyDataTable>
      </div>
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateAssetModalOpened && activeManagementType == vehicleManagement
      "
    >
      <AddVehicleModal1
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 1"
      />
      <AddVehicleModal2
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 2"
      />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateAssetModalOpened && activeManagementType == driverManagement
      "
    >
      <AddDriverModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isCreateAssetModalOpened && activeManagementType == APManagement"
    >
      <AddApModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="
        isCreateAssetModalOpened && activeManagementType == factoryManagement
      "
    >
      <AddFactoryModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="add-modal"
      v-if="isCreateAssetModalOpened && activeManagementType == userManagement"
    >
      <AddUserModal @onCloseModal="handleCloseModal" />
    </div>

    <!-- Details Modal -->
    <div
      class="details-modal"
      v-if="
        isAssetDetailsModalOpened && activeManagementType == vehicleManagement
      "
    >
      <VehicleDetails
        @onCloseModal="handleCloseModal"
        @openDeleteModal="openDeleteModal"
        @openEditModal="openEditModal"
      />
    </div>

    <!-- Edit Modals -->
    <div
      class="add-modal"
      v-if="isEditAssetModalOpened && activeManagementType == vehicleManagement"
    >
      <EditVehicleModal1
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 1"
      />
      <EditVehicleModal2
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 2"
      />
    </div>

    <!-- Delete Modals -->
    <div class="delete-modal" v-if="isDeleteModalOpened">
      <DeleteModal
        :heading="activeManagementType"
        :itemId="itemId"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <!-- Edit modals -->
    <div
      class="add-modal"
      v-if="isEditAssetModalOpened && activeManagementType == userManagement"
    >
      <EditUserModal @onCloseModal="handleCloseModal" />
    </div>

    <!-- Overlay -->
    <div
      class="dark-overlay"
      v-if="
        isCreateAssetModalOpened ||
        isEditAssetModalOpened ||
        isDeleteModalOpened ||
        isAssetDetailsModalOpened
      "
      @click="closeOverlay"
    ></div>
  </div>
</template>

<script>
import {
  apTableHeaders,
  driversTableHeaders,
  factoryTableHeaders,
  usersTableHeaders,
  vehiclesTableHeaders,
} from "./table-headers/headers";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import AddUserModal from "./modals/AddUserModal.vue";
import EditUserModal from "./modals/EditUserModal.vue";
import AddApModal from "./modals/ap/AddApModal.vue";
import AddDriverModal from "./modals/driver/AddDriverModal.vue";
import AddFactoryModal from "./modals/factory/AddFactoryModal.vue";
import AddVehicleModal1 from "./modals/vehicle/AddVehicleModal1.vue";
import AddVehicleModal2 from "./modals/vehicle/AddVehicleModal2.vue";
import DeleteModal from "./modals/DeleteModal.vue";
import VehicleDetails from "./modals/vehicle/VehicleDetails.vue";
import EditVehicleModal1 from "./modals/vehicle/EditVehicleModal1.vue";
import EditVehicleModal2 from "./modals/vehicle/EditVehicleModal2.vue";
import { NameConvention } from "../../utils/nameConvention";

export default {
  name: "AssetManagement",

  components: {
    GreenButton,
    AddUserModal,
    EditUserModal,
    AddApModal,
    AddDriverModal,
    AddFactoryModal,
    AddVehicleModal1,
    AddVehicleModal2,
    DeleteModal,
    VehicleDetails,
    EditVehicleModal1,
    EditVehicleModal2,
  },

  data() {
    return {
      modalPageNum: 1,
      activeManagementType: "Vehicle Management",
      vehicleManagement: "Vehicle Management",
      driverManagement: "Driver Management",
      APManagement: "AP Management",
      factoryManagement: "Factory Management",
      userManagement: "User Management",
      itemsSelected: [],
      paginationMetaData: {
        page: 1,
        rowsPerPage: 10,
      },
      itemId: "",
      usersTableHeaders: usersTableHeaders,
      vehiclesTableHeaders,
      driversTableHeaders,
      apTableHeaders,
      factoryTableHeaders,
      vehicles: [],
      drivers: [],
      APs: [],
      factories: [],
      users: [],
      assets: [],
      loading: false,
      count: 0,
      isAddUserModalOpened: false,
      isEditAssetModalOpened: false,
      isAssetDetailsModalOpened: false,
      itemClicked: "",
      isCreateAssetModalOpened: false,
      isDeleteModalOpened: false,
    };
  },

  mounted() {
    this.fetchAssets();
  },

  computed: {
    refreshStatus() {
      return this.$store.getters["assetManagement/getRefreshStatus"];
    },

    deleteModalStatus() {
      return this.$store.getters["assetManagement/getDeleteModalStatus"];
    },
  },

  methods: {
    openAddUserModal() {
      this.isAddUserModalOpened = !this.isAddUserModalOpened;
    },

    openEditUserModal() {
      this.isEditAssetModalOpened = !this.isEditAssetModalOpened;
    },

    openCreateAssetModal() {
      this.isCreateAssetModalOpened = !this.isCreateAssetModalOpened;
    },

    openEditAssetModal() {
      this.isEditAssetModalOpened = !this.isEditAssetModalOpened;
    },

    handleSearchText(e) {
      const pageNum = 1;
      this.fetchAssets(pageNum, e.target.value);
    },

    setActiveManagementType(type) {
      this.activeManagementType = type;
      this.$store.dispatch("assetManagement/setRefreshStatus", false);
    },

    handleCloseModal(status) {
      this.isCreateAssetModalOpened = status;
      this.isEditAssetModalOpened = status;
      this.isEditOverheadModalOpened = status;
      this.isDeleteModalOpened = status;
      this.isAssetDetailsModalOpened = status;
    },

    handleAddModalPage(pagenum) {
      this.modalPageNum = pagenum;
    },

    openDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },

    openEditModal() {
      this.isEditAssetModalOpened = !this.isEditAssetModalOpened;
    },

    closeOverlay() {
      this.isCreateAssetModalOpened = false;
      this.isAssetDetailsModalOpened = false;
    },

    handleViewDetails(item) {
      const nameConvention = new NameConvention(this.activeManagementType);
      const assetsInCamelCase = nameConvention.camelize();

      this.isAssetDetailsModalOpened = true;

      this.$store.dispatch(
        `${assetsInCamelCase}/findAssetToViewDetails`,
        item._id
      );
    },

    handleEdit(item) {
      this.openEditUserModal();
      const nameConvention = new NameConvention(this.activeManagementType);
      const assetsInCamelCase = nameConvention.camelize();

      this.$store.dispatch(
        `${assetsInCamelCase}/findAssetToBeUpdated`,
        item._id
      );
    },

    async handleDelete(item) {
      // show modal for delete action
      this.itemId = item._id;
      this.isDeleteModalOpened = true;
    },

    async fetchAssets(page = 1, searchTerm = "") {
      const nameConvention = new NameConvention(this.activeManagementType);
      const assetsInCamelCase = nameConvention.camelize();

      this.loading = true;

      this.assets = [];

      const { assets, count } = await this.$store.dispatch(
        `${assetsInCamelCase}/fetch${this.activeManagementType.split(" ")[0]}s`,
        {
          page,
          searchTerm,
        }
      );
      this.loading = false;

      this.assets = assets;
      this.count = count;
      this.$store.dispatch("assetManagement/setRefreshStatus", false);
    },
  },

  watch: {
    paginationMetaData: {
      handler: function (newValue) {
        this.fetchAssets(newValue.page);
      },
      deep: true,
    },

    activeManagementType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchAssets();
      }
    },

    refreshStatus(newValue) {
      if (newValue) {
        this.fetchAssets();
      }
    },

    deleteModalStatus(newValue) {
      this.isDeleteModalOpened = newValue;
    },
  },
};
</script>

<style scoped>
.parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
  overflow-y: scroll;
}

.parent-wrapper {
  width: 98%;
  /* border: 1px solid #000; */
  margin: 1% auto 0 auto;
  height: 100%;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.border {
  margin-top: 10px;
  border-bottom: 0.7px solid #fff1f1;
}

.assets-management__container {
  width: 100%;
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

.management-type {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #232a34;
  font-size: 13px;
  cursor: pointer;
}

.active {
  color: #232a34 !important;
  border-bottom: 2px solid #669933;
  padding: 8px 16px;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.date-filter-container {
  width: 100%;
  margin-top: 1.5%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 85%;
}

.wrapper p {
  margin-bottom: 0px;
}

.date-filter-container p {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 0.78rem;
  line-height: 24px;
  color: #333333;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.create-buttons__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 16%;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 27%;
  /* border: 1px solid #000; */
}

.data-table-container {
  width: 100%;
  /* border: 1px solid green; */
  height: 79.9%;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

/* Data table Styles */
.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 7px 10px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;

  width: 100px;
  height: 32px;

  border-radius: 41px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.approved {
  color: #669933;
  background: rgba(102, 153, 51, 0.15);
}

.pending {
  color: #ed0a0a;
  background: rgba(237, 10, 10, 0.15);
}

.details-modal {
  max-width: 880px;
  height: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 0.8rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.add-modal {
  max-width: 600px;
  height: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.delete-modal {
  max-width: 500px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

.actions-wrapper i {
  cursor: pointer;
  margin-right: 20px;
}
</style>
