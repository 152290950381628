import styled from "vue3-styled-components";

export const StyledButton = styled.button`
  background: #669933;
  border-radius: 32px;
  border: 1px solid #669933;
  outline: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.7" : "1")};

  @media only screen and (min-width: 280px) {
    padding: 0px 1.1rem;

    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "2.7rem")};
    font-size: 0.7rem;
  }

  @media only screen and (min-width: 300px) {
    padding: 0px 2.4rem;

    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "3rem")};
    font-size: 0.8rem;
  }

  @media only screen and (min-width: 600px) {
    padding: 0px 2.4rem;

    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "3rem")};
  }
`;

// opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};
