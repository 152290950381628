<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add New Vehicle</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <TimeField
          placeholder="Loading Time"
          @onInputChange="handleInputChange"
          fieldName="loadingTime"
          :inputValue="vehicleForm?.loadingTime"
        />

        <TimeField
          placeholder="Offloading Time"
          @onInputChange="handleInputChange"
          fieldName="offloadingTime"
          :inputValue="vehicleForm?.offloadingTime"
        />

        <NumberInput
          placeholder="Vehicle Cost (optional)"
          @onInputChange="handleInputChange"
          fieldName="vehicleCost"
          :inputValue="vehicleForm?.vehicleCost"
        />

        <DateField
          placeholder="Date of Purchase (Optional)"
          @onInputChange="handleInputChange"
          fieldName="datePurchased"
          :inputValue="vehicleForm?.datePurchased"
        />

        <DateField
          placeholder="Last Overhaul Date (Optional)"
          @onInputChange="handleInputChange"
          fieldName="lastOverhaulDate"
          :inputValue="vehicleForm?.lastOverhaulDate"
        />

        <DateField
          placeholder="Last Repair Date (Optional)"
          @onInputChange="handleInputChange"
          fieldName="lastRepairDate"
          :inputValue="vehicleForm?.lastRepairDate"
        />

        <Dropdown
          :options="drivers"
          class="select"
          dropdownTitle="Driver"
          @onSelect="handleSelect"
          fieldName="driverId"
          fieldValue="driverName"
          :inputValue="vehicleForm?.driverName"
        />

        <TextInput
          placeholder="Equipments (Optional)"
          @onInputChange="handleInputChange"
          fieldName="equipment"
          :inputValue="vehicleForm?.equipment"
        />
      </form>
    </section>

    <div class="button-container">
      <InvertedBlackButton
        label="Back"
        width="8rem"
        class="back-btn"
        @click="routeToPreviousPage"
      />
      <GreenButton width="12.8rem" label="Submit" @click="addNewVehicle" />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import DateField from "../../../../components/form-inputs/date-input/DateField.vue";
import TimeField from "../../../../components/form-inputs/date-input/TimeField.vue";
import TextInput from "../../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../../components/buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import Dropdown from "../../../../components/form-inputs/select-dropdown/Dropdown.vue";
import NumberInput from "../../../../components/form-inputs/number-input/NumberInput.vue";

export default {
  name: "AddVehicleModal2",

  components: {
    GreenButton,
    InvertedBlackButton,
    Dropdown,
    DateField,
    NumberInput,
    TextInput,
    TimeField,
    Loading,
  },

  data() {
    return {
      vehicleForm: this.$store.getters["vehicleManagement/getInputtedData"] || {
        loadingTime: "",
        offloadingTime: "",
        vehicleCost: 0,
        datePurchased: "",
        lastOverhaulDate: "",
        lastRepairDate: "",
        driverId: "",
        driverName: "",
        equipment: "",
      },
      vehicleDataFromStore: {},
      drivers: [],
      loading: false,

      isRefresh: false,
    };
  },

  mounted() {
    this.fetchDrivers();
  },

  methods: {
    async fetchVehicles(page = 1) {
      const { assets } = await this.$store.dispatch(
        "vehicleManagement/fetchVehicles",
        {
          page,
        }
      );

      this.vehicles = assets;
    },

    getVehicleNames(vehicles) {
      return vehicles.name;
    },
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.vehicleForm = {
        ...this.vehicleForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.vehicleForm = {
        ...this.vehicleForm,
        ...obj,
      };
    },

    async fetchDrivers(page = 1) {
      this.drivers = await this.$store.dispatch("driver/fetchAllDrivers", {
        page,
      });
    },

    routeToPreviousPage() {
      this.$emit("onSetAddModalPage", 1);

      this.vehicleDataFromStore =
        this.$store.getters["vehicleManagement/getInputtedData"];
      this.vehicleDataFromStore = {
        ...this.vehicleDataFromStore,
        ...this.vehicleForm,
      };

      this.$store.dispatch(
        "vehicleManagement/setInputtedData",
        this.vehicleDataFromStore
      );
    },

    async addNewVehicle() {
      this.loading = true;
      const record = await this.$store.dispatch(
        "vehicleManagement/addNewVehicle",
        this.vehicleForm
      );

      if (record._id || record.id) {
        this.loading = false;
        createToast("New vehicle added successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            this.$store.dispatch("assetManagement/setRefreshStatus", true);
            this.closeModal();
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}
</style>
