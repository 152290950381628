import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  millsList: [],
  mill: null,
  isFilter: false,
  isDateFilter: false,
  inputtedMill: {
    name: "",
    physically_tagged: "",
    size: "",
    cracker: "",
    number_of_pots: "",
    number_of_pressers: "",
  },
  millToBeUpdated: {},
  filterMetaData: {
    filteredMills: [],
    count: 0,
    query: "",
  },
});

const getters = {
  getMillsList: (state) => {
    return state.millsList;
  },
  getMill: (state) => {
    return state.mill;
  },

  getFilterStatus: (state) => {
    return state.isFilter;
  },

  getIsDateFilterStatus: (state) => {
    return state.isDateFilter;
  },

  getInputtedMills: (state) => {
    return state.inputtedMill;
  },

  getMillToBeUpdated: (state) => {
    return state.millToBeUpdated;
  },

  getFilteredMillMetaData: (state) => {
    return state.filterMetaData;
  },
};

const mutations = {
  saveMills(state, mills) {
    state.millsList = mills;
  },

  saveOneMill(state, mill) {
    state.mill = mill;
    console.log(state.mill);
  },

  setIsFilter(state, status) {
    state.isFilter = status;
  },

  setIsDateFilterStatus(state, status) {
    state.isDateFilter = status;
  },

  setInputtedMill(state, mill) {
    state.inputtedMill = mill;
  },

  setValuesForMillUpdate(state, mill) {
    state.millToBeUpdated = {
      ...state.millToBeUpdated,
      ...mill,
    };
  },

  setMillToBeUpdated: (state, payload) => {
    state.millToBeUpdated = payload;
  },

  setFilteredMillMetaData: (state, metaData) => {
    state.filterMetaData = {
      ...state.filterMetaData,
      ...metaData,
    };
  },
};

const actions = {
  setIsFilter({ commit }, status) {
    commit("setIsFilter", status);
  },

  setInputtedMill({ commit }, mill) {
    commit("setInputtedMill", mill);
  },

  setValuesForMillUpdate({ commit }, mill) {
    commit("setValuesForMillUpdate", mill);
  },

  findMillToBeUpdate({ commit, state }, millId) {
    let millToBeUpdated = state.millsList.find((mill) => {
      return mill.id === millId;
    });

    millToBeUpdated = {
      ...millToBeUpdated,
      latitude: millToBeUpdated?.location?.latitude,
      longitude: millToBeUpdated?.location?.longitude,
    };

    commit("setMillToBeUpdated", millToBeUpdated);
  },

  async fetchMills({ commit }) {
    try {
      const response = await APIConfig.get("/mills");

      const list = response.data.data?.slice(0, 20);

      if (list.length) await commit("saveMills", list);

      return list;
    } catch (error) {
      console.log(error);
    }
  },

  async getMills({ commit }, params) {
    const { page, searchTerm, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/mills/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
      );

      if (data) {
        commit("saveMills", data.data);
      }

      if (Object.keys(data).length > 0) {
        const mills = data.data?.map((el) => {
          return {
            ...el,
            id: el.id || el._id,
            name: el.name || "N/A",
            code: el.code || "N/A",
            state: el.state_id?.name || "N/A",
            lga: el.lga_id?.name || "N/A",
            community: el.community_id?.name || "N/A",
            longitude: el.location?.longitude || "N/A",
            latitude: el.location?.latitude || "N/A",
            createdBy: el.createdBy?.first_name || "N/A",
          };
        });

        return {
          count: data?.count,
          mills,
        };
      } else {
        return {
          count: 0,
          mills: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async filterMillsDatePeriod({ commit }, params) {
    const { page, filterQuery, limit } = params;

    try {
      const { data } = await APIConfig.get(
        `/mills/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      commit("setIsDateFilterStatus", true);

      if (Object.keys(data).length > 0) {
        const mills = data?.data?.map((el) => {
          return {
            ...el,
            name: el.name || "N/A",
            code: el.code || "N/A",
            state: el.state_id?.name || "N/A",
            lga: el.lga_id?.name || "N/A",
            community: el.community_id?.name || "N/A",
            longitude: el.location?.longitude || "N/A",
            latitude: el.location?.latitude || "N/A",
            createdBy: el.createdBy?.first_name || "N/A",
          };
        });

        return {
          count: data?.count,
          mills,
        };
      } else {
        return {
          count: 0,
          mills: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async searchMills({ commit }, searchTerm) {
    try {
      const response = await APIConfig.get(`/mills/?searchTerm=${searchTerm}`);

      const list = response.data?.data?.slice(0, 20);

      if (!response.data.data) {
        console.log("no data found");
        await commit("saveMills", []);
      }


      if (list?.length) await commit("saveMills", list);

      return list;
    } catch (error) {
      console.log(error);
    }
  },

  async filterMills({ commit }, params) {
    const { page, filterQuery, limit } = params;

    try {
      const { data } = await APIConfig.get(
        `/mills/filter/?page=${page}&limit=${limit}&${filterQuery}`
      );

      let response = {
        filteredMills: [],
        count: 0,
        query: filterQuery,
      };

      if (data?.count > 0) {
        response.filteredMills = data?.data?.map((el) => {
          return {
            ...el,
            name: el.name || "N/A",
            code: el.code || "N/A",
            state: el.state_id?.name || "N/A",
            lga: el.lga_id?.name || "N/A",
            community: el.community_id?.name || "N/A",
            longitude: el.location?.longitude || "N/A",
            latitude: el.location?.latitude || "N/A",
            createdBy: el.createdBy?.first_name || "N/A",
          };
        });

        response.count = data?.count;

        await commit("setIsFilter", true);
        await commit("setFilteredMillMetaData", response);
      } else {
        await commit("setFilteredMillMetaData", response);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getNearestMillWithCurrentLocation({ commit }, locationQuery) {
    try {
      const { data } = await APIConfig.get(`/mills/location?${locationQuery}`);

      commit("saveOneMill", data);

      console.log(data);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async getOneMill({ commit }, millId) {
    try {
      let { data } = await APIConfig.get(`/mills/${millId}`);

      // const mill = response.data;
      if (data) {
        commit("saveOneMill", data);
      }
      data = {
        ...data,
        name: data.name || "N/A",
        code: data.code || "N/A",
        state: data.state_id?.name || "N/A",
        lga: data.lga_id?.name || "N/A",
        community: data.community_id?.name || "N/A",
        longitude: data.location?.longitude || "N/A",
        latitude: data.location?.latitude || "N/A",
        createdBy: data.createdBy?.first_name || "N/A",
      };

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async tagMill({ commit }, payload) {
    console.log(commit);
    try {
      const { data } = await APIConfig.post("/mills", payload);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },

  async updateMill({ commit }, payload) {
    console.log(commit);
    try {
      const { data } = await APIConfig.patch(`/mills/${payload.id}`, payload);
      return data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  },

  async approveManyMills({ commit }, millIds) {
    console.log(commit);
    try {
      const { data } = await APIConfig.patch(`/mills/approvals`, {
        millIds,
      });

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async deleteMill({ commit }, millId) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(`/mills/${millId}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  },

  async deleteManyMills({ commit }, ids) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(`/mills?millsIds=${ids}`);

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
