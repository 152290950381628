<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h6 class="modal-caption">Start Dispatch</h6>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <section
          class="purchase__section"
          v-if="purchasesToBeAggregated.length > 0"
        >
          <div class="purchases__aggregated">
            <p class="purchase__header">Purchase</p>
            <p class="purchase__header">Quantity Moved</p>
            <p class="purchase__header">Left</p>
          </div>
          <div
            class="purchases__aggregated"
            v-for="purchase in purchasesToBeAggregated"
            :key="purchase.purchaseId"
          >
            <p class="purchase__values">{{ purchase.purchaseId }}</p>
            <p class="purchase__values">{{ purchase.qtyMoved }} Ton(s)</p>
            <p class="purchase__values">
              {{ purchase.amountLeft }} Ton(s)
              <span @click="removePurchaseItem(purchase.purchaseId)">x</span>
            </p>
          </div>
        </section>
        <PurchaseDropdown
          :options="purchases"
          class="select"
          dropdownTitle="Purchase"
          @onSelect="handleSelect"
          fieldName="purchase"
          fieldValue="purchase"
          :inputValue="dispatchForm?.purchase"
          :reset="reset"
          @onReset="handleReset"
        />

        <NumberInput
          placeholder="Quantity to be moved"
          @onInputChange="handleInputChange"
          fieldName="qtyMoved"
          :inputValue="dispatchForm?.qtyMoved"
        />

        <div class="add-purchase">
          <BlackButton
            label="Add Purchase"
            width="12rem"
            height="1.9rem"
            iconName="add-icon"
            @click="addPurchase"
          />
        </div>

        <Dropdown
          :options="vehicles"
          class="select"
          dropdownTitle="Vehicle"
          @onSelect="handleSelect"
          fieldName="vehicle"
          fieldValue="vehicleName"
          :inputValue="dispatchForm?.vehicleName"
        />

        <Dropdown
          :options="drivers"
          class="select"
          dropdownTitle="Driver"
          @onSelect="handleSelect"
          fieldName="driver"
          fieldValue="driverName"
          :inputValue="dispatchForm?.driverName"
        />

        <Dropdown
          :options="factories"
          class="select"
          dropdownTitle="Factory"
          @onSelect="handleSelect"
          fieldName="factoryId"
          fieldValue="factoryName"
          :inputValue="dispatchForm?.factoryName"
        />

        <DateField
          placeholder="Start Date"
          @onInputChange="handleInputChange"
          fieldName="dispatch_starts_at"
          :inputValue="dispatchForm?.dispatch_starts_at"
        />

        <TimeField
          placeholder="Start time"
          @onInputChange="handleInputChange"
          fieldName="dispatchStartTime"
          :inputValue="dispatchForm?.dispatchStartTime"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton label="Submit" @click="startDispatch" />
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";

import NumberInput from "../../../components/form-inputs/number-input/NumberInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";

import BlackButton from "../../../components/buttons/black-button/BlackButton.vue";
import Dropdown from "../../../components/form-inputs/select-dropdown/Dropdown.vue";
import PurchaseDropdown from "../../../components/form-inputs/select-dropdown/PurchaseDropdown.vue";
import DateField from "../../../components/form-inputs/date-input/DateField.vue";
import TimeField from "../../../components/form-inputs/date-input/TimeField.vue";

export default {
  name: "MillToFactoryDispatch",

  components: {
    GreenButton,
    BlackButton,
    DateField,
    Dropdown,
    NumberInput,
    PurchaseDropdown,
    TimeField,
    Loading,
  },

  data() {
    return {
      dispatchForm: {
        factoryId: "",
        factoryName: "",
        purchase: "",
        qtyMoved: "",
        vehicle: "",
        vehicleName: "",
        driver: "",
        driverName: "",
        dispatch_starts_at: "",
        dispatchStartTime: "",
      },
      globalInputtedAggregation: {},
      factories: [],
      vehicles: [],
      drivers: [],
      purchases: [],
      purchasesToBeAggregated: [],
      error: "",
      reset: false,
      loading: false,
    };
  },

  mounted() {
    this.fetchFactories();
    this.fetchVehicles();
    this.fetchDrivers();
    this.fetchPurchases();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
      this.$emit("onSetAddModalPage", 1);
    },

    handleReset(value) {
      this.reset = value;
    },

    handleSelect(obj) {
      this.dispatchForm = {
        ...this.dispatchForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.dispatchForm = {
        ...this.dispatchForm,
        ...obj,
      };
    },

    async startDispatch() {
      this.loading = true;
      let {
        factoryName,
        vehicleName,
        driverName,
        dispatch_starts_at,
        dispatchStartTime,
        factoryId,
        driver,
        vehicle,
      } = this.dispatchForm;

      if (
        !this.purchasesToBeAggregated.length ||
        !factoryName ||
        !vehicleName ||
        !driverName ||
        !dispatch_starts_at ||
        !dispatchStartTime
      ) {
        this.loading = false;
        createToast("All fields are required", {
          type: "danger",
          timeout: 5000,
          position: "top-right",
        });
        return;
      }

      const payload = {
        purchases: this.purchasesToBeAggregated,
        driver,
        factoryId,
        vehicle,
        dispatch_starts_at,
        dispatchStartTime,
      };

      const data = await this.$store.dispatch(
        "dispatch/startDispatchFromMill",
        payload
      );

      if (data.id) {
        this.loading = false;
        createToast("Dispatch started successfully!", {
          type: "success",
          timeout: 5000,
          position: "top-right",

          onClose: () => {
            return window.location.reload();
          },
        });
      }
    },

    addPurchase(e) {
      e.preventDefault();
      let { purchase, qtyMoved, availableQty, id } = this.dispatchForm;
      if (!purchase) {
        createToast("Please select a purchase", {
          type: "danger",
          timeout: 5000,
          position: "top-right",
        });

        return;
      }

      if (!qtyMoved) {
        createToast("Please input quantity", {
          type: "danger",
          timeout: 5000,
          position: "top-right",
        });
        return;
      }

      if (availableQty < qtyMoved) {
        createToast("Quantity inputted greater than available tonnage", {
          type: "danger",
          timeout: 5000,
          position: "top-right",
        });
        return;
      }

      const newPurchaseForAggregation = {
        id,
        purchaseId: purchase,
        qtyMoved,
        amountLeft: (availableQty - qtyMoved).toFixed(2),
      };

      this.dispatchForm.qtyMoved = 0;
      this.reset = true;

      this.purchasesToBeAggregated.push(newPurchaseForAggregation);
    },

    removePurchaseItem(id) {
      this.purchasesToBeAggregated = this.purchasesToBeAggregated.filter(
        (el) => el.purchaseId !== id
      );
    },

    async fetchPurchases(page = 1, searchTerm = "") {
      const { purchases } = await this.$store.dispatch(
        "purchases/getPurchases",
        {
          page,
          searchTerm,
        }
      );
      this.purchases = purchases;
    },

    async fetchFactories(page = 1) {
      this.factories = await this.$store.dispatch(
        "factories/fetchAllFactories",
        {
          page,
        }
      );
    },

    async fetchVehicles(page = 1) {
      const { assets } = await this.$store.dispatch(
        "vehicleManagement/fetchVehicles",
        {
          page,
        }
      );

      this.vehicles = assets;
    },

    async fetchDrivers(page = 1) {
      this.drivers = await this.$store.dispatch("driver/fetchAllDrivers", {
        page,
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 800;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: 600px;
  display: flex;
  flex-direction: column;
}

.form {
  margin: 5% auto;
  width: 85%;
  height: auto;
  overflow: scroll;
  flex: 1;
}

.purchase__section {
  background: #232a34;
  border-radius: 16px;
  width: 100%;
  height: auto;
  margin-inline: auto;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-top: 25px;
  color: #fff;
}

.purchases__aggregated {
  margin-inline: auto;
  width: 90%;
  height: 85%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.purchase__header {
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  font-weight: 100;
  margin: 2px;
}

.purchase__values {
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  align-self: start;
  color: #fff;
  margin: 3px 5px;
}

.purchase__values span {
  color: #fff;
  margin-left: 8px;
  cursor: pointer;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 25%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 1% auto 2% auto;
}

.add-purchase {
  display: flex;
  width: 42%;
  margin-bottom: 2%;
  margin-left: auto;
}
</style>
