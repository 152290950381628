<template>
  <div>
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <div class="factory-customer__wrapper">
      <p class="customer-greeting">Welcome back, <span>FMCG!</span></p>

      <p class="customer-cta">
        View and edit your procurement details
        <span @click="openProcurementDetailsModal">here</span>
      </p>

      <section class="report">
        <h2>Report Snapshot</h2>

        <div class="dropdown-container">
          <!-- <p>Range:</p> -->
          <!-- <v-select
            :options="['Yearly', 'Monthly', 'Weekly']"
            placeholder="Period"
            class="dropdown"
          ></v-select> -->
          <v-select
            :options="months"
            class="dropdown"
            v-model="selected"
            placeholder="month"
          ></v-select>
          <v-select
            v-if="false"
            :options="['Canada', 'United States']"
            class="dropdown"
          ></v-select>
        </div>
      </section>

      <!-- Report Area -->
      <section class="main-report-area">
        <!-- chart section -->
        <div class="chart-area">
          <div class="metrics-summary">
            <div
              class="metric-card"
              @click="setActiveMetricTab(purchasedPalmNut)"
              :class="{
                activeCard: activeMetricTab === purchasedPalmNut,
              }"
            >
              <h2
                :class="{
                  active: activeMetricTab === purchasedPalmNut,
                }"
              >
                {{ purchasedPalmNut }}
              </h2>
              <p class="metric-value">{{ totalTonnes }}T</p>
            </div>
            <div
              class="metric-card"
              @click="setActiveMetricTab(equivalentPalmKernel)"
              :class="{
                activeCard: activeMetricTab === equivalentPalmKernel,
              }"
            >
              <h2
                :class="{
                  active: activeMetricTab === equivalentPalmKernel,
                }"
              >
                {{ equivalentPalmKernel }}
              </h2>
              <p class="metric-value">{{ equiPalmKernel }}T</p>
            </div>
            <div
              class="metric-card"
              :class="{
                activeCard: activeMetricTab === avgReleafPalmKernelPrice,
              }"
              @click="setActiveMetricTab(avgReleafPalmKernelPrice)"
            >
              <h2
                :class="{
                  active: activeMetricTab === avgReleafPalmKernelPrice,
                }"
              >
                {{ avgReleafPalmKernelPrice }}
              </h2>
              <p class="metric-value">
                {{ vueNumberFormat(avgPalmKernelPrice, {}) }}
              </p>
            </div>
          </div>

          <div class="chart">
            <KaaSCharts :data="data" />
          </div>
        </div>

        <!-- vol/price section -->
        <div class="vol-price-area">
          <div class="vol">
            <h2 class="heading">Volume</h2>
            <p class="vol-description">
              Monthly Palm Kernel Sourced Target/Releaf Palm Kernel Sourced
            </p>

            <p class="vol-values">
              {{ procurementDetails?.monthlyPalmKernelTarget || 0 }}T /
              <span class="releaf-vol">{{ totalTonnes }}T</span>
            </p>

            <!-- Progress Bar -->
            <div class="progress-bar-container">
              <div class="indicator"></div>
            </div>
            <span>{{ getPercentageSourced || 0 }}% sourced</span>
          </div>
          <!-- border -->
          <div class="border"></div>
          <div class="price">
            <h2 class="heading">Price</h2>
            <p class="price-description">
              Kernel Sourced Target/Releaf Palm Kernel Price
            </p>

            <p class="price-values">
              {{
                vueNumberFormat(procurementDetails?.palmKernelPricePerTonne, {})
              }}
              /
              <span class="releaf-price">{{
                vueNumberFormat(avgPalmKernelPrice, {})
              }}</span>
            </p>

            <p style="font-size: 13px">
              <span class="releaf-price"
                >{{ vueNumberFormat(priceMetric.diff, {}) }} ({{
                  priceMetric.percentageDiff || 0
                }}%)</span
              >
              Cheaper/Tonne
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import KaaSCharts from "./charts/KaaSChart.vue";
import Loading from "vue-loading-overlay";

import { months } from "./data/left-data";

export default {
  name: "FactoryCustomerDashboard",

  data() {
    return {
      loading: false,
      isSectionMetricOpened: true,
      isSectionDataOpened: true,
      isProcecureDetailsModalOpened: false,
      imageUp: "../../assets/arrow-left.png",
      imageDown: "../../assets/arrow-back.png",
      purchasedPalmNut: "Purchased Palm Nut",
      equivalentPalmKernel: "Equivalent Palm Kernel",
      avgReleafPalmKernelPrice: "Avg. Releaf Palm Kernel Price",
      activeMetricTab: "Purchased Palm Nut",

      one: [30, 40, 45, 50, 49, 60, 70, 91, 125, 150, 200, 300],
      two: [50, 60, 65, 50, 49, 60, 70, 60, 30, 65, 30, 70],
      three: [100, 40, 45, 150, 249, 40, 50, 91, 125, 86, 50, 150],
      data: [30, 40, 45, 50, 49, 60, 70, 91, 125, 150, 200, 300],
      procurementDetails: {},
      releafPalmKernelSourced: 50,
      releafPalmKernelPrice: 45000,
      months: months,
      selected: "",
      totalTonnes: 0,
      equiPalmKernel: 0,
      avgPalmKernelPrice: 0,
      palmNutVol: [],
      equivalentpalmKernelVol: [],
      avgPalmKernelPricePoints: [],
    };
  },
  components: {
    KaaSCharts,
    Loading,
  },

  mounted() {
    this.fetchCustomerProcurementDetails();
    this.fetchKaaSSummary();
    this.fetchPalmNutVol();
    this.fetchEquivalPalmKerneltVol();
    this.fetchAvgPalmKernelPriceDataPoints();
  },

  computed: {
    customerProcurementDetails() {
      return this.$store.getters["procurementDetails/getProcurementDetails"];
    },

    getPercentageSourced() {
      return Math.floor(
        (this.totalTonnes / this.procurementDetails?.monthlyPalmKernelTarget) *
          100
      );
    },

    getTargetDifference() {
      return Math.floor(
        this.procurementDetails?.palmKernelPricePerTonne -
          this.avgPalmKernelPrice
      );
    },

    priceMetric() {
      const diff =
        this.procurementDetails?.palmKernelPricePerTonne -
        this.avgPalmKernelPrice;

      const percentageDiff = Math.floor(
        (diff / this.procurementDetails?.palmKernelPricePerTonne) * 100
      );

      return {
        diff,
        percentageDiff,
      };
    },
  },

  methods: {
    setActiveMetricTab(tab) {
      this.activeMetricTab = tab;
    },

    async fetchCustomerProcurementDetails() {
      const data = await this.$store.dispatch(
        "procurementDetails/getCustomerProcurementDetails"
      );

      this.procurementDetails = data;
    },

    async fetchKaaSSummary(query = "January") {
      this.loading = true;
      const data = await this.$store.dispatch(
        "overviews/getKaaSSummary",
        query
      );

      this.loading = false;

      this.totalTonnes = data?.totalTonnes.toFixed(2);
      this.equiPalmKernel = data?.equivalentPalmKernel.toFixed(2);
      this.avgPalmKernelPrice =
        Number(data?.avgPalmPernelPrice?.toFixed(2)) || "0";
    },

    async fetchPalmNutVol(query = "January") {
      const data = await this.$store.dispatch(
        "overviews/fetchPalmNutVolumeDataPoints",
        query
      );

      this.palmNutVol = data;
    },

    async fetchEquivalPalmKerneltVol(query = "January") {
      const data = await this.$store.dispatch(
        "overviews/fetchEquivalentPalmKernelVolumeDataPoints",
        query
      );

      this.equivalentpalmKernelVol = data;
    },

    async fetchAvgPalmKernelPriceDataPoints(query = "January") {
      const data = await this.$store.dispatch(
        "overviews/fetchAvgPalmKernelPriceDataPoints",
        query
      );

      this.avgPalmKernelPricePoints = data;
    },

    openProcurementDetailsModal() {
      this.isProcecureDetailsModalOpened = !this.isProcecureDetailsModalOpened;
      this.$store.dispatch("procurementDetails/setDetailsModalStatus", true);
    },
  },

  watch: {
    activeMetricTab(newValue) {
      if (newValue === this.purchasedPalmNut) {
        this.data = this.palmNutVol.map((vol) => Math.floor(vol.tonsAmount));
        console.log(this.data);
      } else if (newValue === this.equivalentPalmKernel) {
        this.data = this.equivalentpalmKernelVol.map((vol) =>
          Math.floor(vol.equivalentPalmKernelAmount)
        );
      } else {
        this.data = this.avgPalmKernelPricePoints.map((price) =>
          Math.floor(price.palmKernelPrice)
        );
      }
    },

    customerProcurementDetails(newValue) {
      this.procurementDetails = newValue;
    },

    selected(newValue) {
      this.fetchKaaSSummary(newValue);
      this.fetchPalmNutVol(newValue);
      this.fetchEquivalPalmKerneltVol(newValue);
      this.fetchAvgPalmKernelPriceDataPoints(newValue);
    },

    palmNutVol(newValue) {
      this.data = newValue.map((vol) => Math.floor(vol.tonsAmount));
    },
  },
};
</script>

<style scoped>
.factory-customer__wrapper {
  margin: 1.5rem auto;
  width: 96%;
}

.customer-greeting {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  color: #333333;
  margin-bottom: 0;
}

.customer-greeting span {
  color: #8fc54d;
}

.customer-cta {
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

.customer-cta span {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  cursor: pointer;
}

.procurement-modal {
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
  max-width: 680px;
  height: 500px;
}

.report {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1.4rem;
  padding: 0.6rem 0;
  border-bottom: 1px solid #b3b3b3;
  /* border: 1px solid red; */
}

.report h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #333333;
}

.dropdown-container {
  display: flex;
  width: 35%;
  height: 100%;
  align-items: center;
  gap: 0.2rem;
}

.dropdown {
  width: 35%;
  margin-left: auto;
}

.main-report-area {
  width: 100%;
  height: 450px;
  margin-top: 1.5rem;
  display: flex;
  gap: 1.1rem;
}

.chart-area {
  width: 70%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
}

.metrics-summary {
  display: flex;
  width: 100%;
  height: 30%;
}

.metric-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.3rem;
  width: 33.33%;
  cursor: pointer;
}

.metric-card h2 {
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #545454;
  margin-bottom: 0px;
}

.metric-value {
  margin-top: 0px;
  font-weight: 600;
  font-size: 33px;
  line-height: 72px;
  color: #232a34;
}

.vol-price-area {
  width: 35%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
}

.vol,
.price {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #b3b3b3; */
  margin: 1rem auto;
  width: 90%;
  height: 40%;
}

.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #232a34;
}

.vol-description,
.price-description {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #545454;
  margin-bottom: 5px;
}

.activeCard {
  border-top: 2px solid #8fc54d;
}

.active {
  color: #8fc54d !important;
}

.chart {
  width: 100%;
  height: 70%;
}

.vol-values,
.price-values {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  color: #545454;
}

.releaf-vol,
.releaf-price {
  color: #8fc54d;
}

.progress-bar-container {
  width: 75%;
  background: #dadada;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 50%;
  width: 80%;
  background: #8fc54d;
  border-radius: 9px;
  border: 1px solid #8fc54d;
  padding: 2px;
}

>>> {
  --vs-dropdown-min-width: 30%;
  --vs-dropdown-max-width: 30%;
  --vs-font-size: 0.8rem;
  --vs-line-height: 1;
  --vs-dropdown-option-padding: 5px 10px;
}
</style>
