<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add New Vehicle</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <TextInput
          placeholder="Name"
          @onInputChange="handleInputChange"
          fieldName="name"
          :inputValue="vehicleForm?.name"
          :isSnakeCase="false"
        />

        <TextInput
          placeholder="Plate number"
          @onInputChange="handleInputChange"
          fieldName="vehicleNumber"
          :inputValue="vehicleForm?.vehicleNumber"
          :isSnakeCase="false"
        />

        <NumberInput
          placeholder="Min Capacity"
          @onInputChange="handleInputChange"
          fieldName="minCapacity"
          :inputValue="vehicleForm?.minCapacity"
        />

        <NumberInput
          placeholder="Max Capacity"
          @onInputChange="handleInputChange"
          fieldName="maxCapacity"
          :inputValue="vehicleForm?.maxCapacity"
        />

        <TextInput
          placeholder="Model"
          @onInputChange="handleInputChange"
          fieldName="vehicleModel"
          :inputValue="vehicleForm?.vehicleModel"
          :isSnakeCase="false"
        />

        <NumberInput
          placeholder="Fuel Economy"
          @onInputChange="handleInputChange"
          fieldName="fuelEconomy"
          :inputValue="vehicleForm?.fuelEconomy"
        />

        <NumberInput
          placeholder="Fuel Cost"
          @onInputChange="handleInputChange"
          fieldName="fuelCost"
          :inputValue="vehicleForm?.fuelCost"
        />

        <NumberInput
          placeholder="Loading Cost"
          @onInputChange="handleInputChange"
          fieldName="loadingCostPerTon"
          :inputValue="vehicleForm?.loadingCostPerTon"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton
        width="12.8rem"
        label="Save and Continue"
        @click="routeToNextPage"
      />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import TextInput from "../../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../../components/buttons/green-button/GreenButton.vue";
import NumberInput from "../../../../components/form-inputs/number-input/NumberInput.vue";

export default {
  name: "AddVehicleModal1",

  components: {
    GreenButton,
    NumberInput,
    TextInput,
    Loading,
  },

  data() {
    return {
      vehicleForm: this.$store.getters["vehicleManagement/getInputtedData"] || {
        name: "",
        vehicleNumber: "",
        minCapacity: 0,
        maxCapacity: 0,
        vehicleModel: "",
        fuelEconomy: 0,
        fuelCost: 0,
        loadingCostPerTon: 0,
      },
      vehicleDataFromStore: {},
      loading: false,
      isRefresh: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.vehicleForm = {
        ...this.vehicleForm,
        ...obj,
      };

    },
    handleInputChange(obj) {
      this.vehicleForm = {
        ...this.vehicleForm,
        ...obj,
      };
    },

    routeToNextPage() {
      this.$emit("onSetAddModalPage", 2);

      this.vehicleDataFromStore =
        this.$store.getters["vehicleManagement/getInputtedData"];
      this.vehicleDataFromStore = {
        ...this.vehicleDataFromStore,
        ...this.vehicleForm,
      };

      this.$store.dispatch(
        "vehicleManagement/setInputtedData",
        this.vehicleDataFromStore
      );
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}
</style>
