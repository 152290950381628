<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add Repair Record</h5>
            <P>Step 2 of 2</P>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <TextAreaInput
          placeholder="Recommendation"
          @onInputChange="handleInputChange"
          fieldName="recommendation"
          :inputValue="maintenanceForm?.recommendation"
          :isSnakeCase="false"
        />

        <Multiselect
          v-model="maintenanceForm.tags"
          mode="tags"
          placeholder="Parts needed"
          :close-on-select="false"
          :searchable="true"
          :create-option="true"
          class="tags"
        />

        <NumberInput
          placeholder="Costs of Parts Needed"
          @onInputChange="handleInputChange"
          fieldName="costOfParts"
          :inputValue="maintenanceForm?.costOfParts"
        />

        <NumberInput
          placeholder="Total Cost of Repairs"
          @onInputChange="handleInputChange"
          fieldName="totalCostOfRepairs"
          :inputValue="maintenanceForm?.totalCostOfRepairs"
        />

        <div class="time-of-repair__container">
          <TextInput
            placeholder="Time Of Repair"
            @onInputChange="handleInputChange"
            fieldName="timeOfRepair"
            :inputValue="maintenanceForm?.timeOfRepair"
            :isSnakeCase="false"
            class="time-of-repair"
          />
          <SelectDropdown
            :options="['Minute(s)', 'Hour(s)', 'Day(s)', 'Week(s)', 'Month(s)']"
            class="select"
            dropdownTitle="Duration"
            fieldName="duration"
            @onSelect="handleSelect"
            :inputValue="maintenanceForm?.duration"
          />
        </div>

        <div class="duration-of-purchase__container" v-if="isProrationChecked">
          <TextInput
            placeholder="Duration Of Purchase"
            @onInputChange="handleInputChange"
            fieldName="durationOfPurchase"
            :inputValue="maintenanceForm?.durationOfPurchase"
            :isSnakeCase="false"
            class="time-of-repair"
          />
          <SelectDropdown
            :options="['Day(s)', 'Week(s)', 'Month(s)']"
            class="select"
            dropdownTitle="Duration"
            fieldName="proratedDuration"
            @onSelect="handleSelect"
            :inputValue="maintenanceForm?.proratedDuration"
          />
        </div>

        <div class="checkbox__container">
          <input
            type="checkbox"
            value="isChecked"
            name="check"
            v-model="isChecked"
          />
          <label for="check">Prorate?</label><br />
        </div>
      </form>
    </section>

    <div class="button-container">
      <InvertedBlackButton
        label="Back"
        width="8rem"
        class="back-btn"
        @click="routeToPreviousPage"
      />
      <GreenButton
        width="12.8rem"
        label="Submit"
        @click="addMaintenanceRecord"
      />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import SelectDropdown from "../../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import TextInput from "../../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../../components/buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import NumberInput from "../../../../components/form-inputs/number-input/NumberInput.vue";
import TextAreaInput from "../../../../components/form-inputs/text-area/TextAreaInput.vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "AddMaintenanceModal2",

  components: {
    GreenButton,
    InvertedBlackButton,
    SelectDropdown,
    NumberInput,
    TextInput,
    Loading,
    Multiselect,
    TextAreaInput,
  },

  data() {
    return {
      maintenanceForm: this.$store.getters[
        "lMVRepairsAndMaintenanceCosts/getInputtedCosts"
      ] || {
        recommendation: "",
        costOfParts: 0,
        totalCostOfRepairs: 0,
        timeOfRepair: "",
        tags: [],
        partsNeeded: [],
        duration: "",
        durationOfPurchase: "",
        proratedDuration: "",
      },
      sampleTags: [],
      globalInputtedDispatch: {},
      loading: false,
      isRefresh: false,
      isChecked: false,
      isProrationChecked: false,
      value: [],
    };
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.maintenanceForm = {
        ...this.maintenanceForm,
        ...obj,
      };
    },
    handleInputChange(obj) {
      this.maintenanceForm = {
        ...this.maintenanceForm,
        ...obj,
      };
    },

    handleChangeTag(tags) {
      const newtags = [...tags];
      this.tags = tags;
      this.maintenanceForm = {
        ...this.maintenanceForm,
        tags,
        partsNeeded: newtags,
      };
    },

    routeToPreviousPage() {
      this.$emit("onSetAddModalPage", 1);

      this.globalInputtedDispatch =
        this.$store.getters["lMVRepairsAndMaintenanceCosts/getInputtedCosts"];
      this.globalInputtedDispatch = {
        ...this.globalInputtedDispatch,
        ...this.maintenanceForm,
      };

      this.$store.dispatch(
        "lMVRepairsAndMaintenanceCosts/setInputtedCosts",
        this.globalInputtedDispatch
      );
    },

    async addMaintenanceRecord() {
      const {
        recommendation,
        costOfParts,
        totalCostOfRepairs,
        timeOfRepair,
        partsNeeded,
        duration,
        durationOfPurchase,
        proratedDuration,
      } = this.maintenanceForm;
      if (
        !recommendation ||
        !costOfParts ||
        !totalCostOfRepairs ||
        !timeOfRepair ||
        !partsNeeded ||
        !duration
      ) {
        return createToast("All fields are required!", {
          type: "danger",
          timeout: 2000,
          position: "top-left",
        });
      }

      const payload = {
        ...this.$store.getters[
          "lMVRepairsAndMaintenanceCosts/getInputtedCosts"
        ],
        recommendation,
        costOfParts,
        totalCostOfRepairs,
        timeOfRepair: `${timeOfRepair} ${duration}`,
        partsNeeded,
        durationOfPurchase: `${durationOfPurchase} ${proratedDuration}`,
      };

      this.loading = true;
      const record = await this.$store.dispatch(
        "lMVRepairsAndMaintenanceCosts/addNewCosts", // need to update api
        payload
      );

      if (record) {
        this.loading = false;
        createToast("Maintenance record added successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            this.$store.dispatch("overhead/setRefreshStatus", true);
            this.closeModal();
          },
        });
      }
    },
  },

  watch: {
    maintenanceForm: {
      handler: function (newValue) {
        newValue.partsNeeded = newValue.tags;
      },
      deep: true,
    },

    isChecked(newValue) {
      if (newValue) {
        this.isProrationChecked = newValue;
      } else {
        this.isProrationChecked = newValue;
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}

.tags {
  height: 55px;
  margin-bottom: 20px;
  border: 1.5px solid #545454;
  height: 55px;
  outline: none !important;
  font-size: 13px;
  background: none;
}

.time-of-repair__container,
.duration-of-purchase__container {
  display: flex;
  width: 100%;
}

.time-of-repair {
  width: 80%;
}

.checkbox__container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkbox__container input {
  cursor: pointer;
}

.checkbox__container label {
  font-size: 11.6px;
  margin-top: 7px;
  color: #000;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>