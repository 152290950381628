<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Discovery Status</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-container">
      <div style="margin: 0 auto; width: 96%"></div>

      <div class="form-container-div">
        <TextInput
          placeholder="Declined"
          @onInputChange="handleInputChange"
          :inputValue="discoveryForm.discovery_status"
          fieldName="status"
          disabled="true"
        />

        <TextInput
          placeholder="Target P1 Price/Ton"
          @onInputChange="handleInputChange"
          :inputValue="discoveryForm.price_per_ton"
          fieldName="price_per_ton"
          disabled="true"
        />

        <TextInput
          placeholder="Asking Price/Ton"
          @onInputChange="handleInputChange"
          :inputValue="discoveryForm.ask_price_per_ton"
          fieldName="ask_price_per_ton"
        />

        <SelectDropdown
          :options="['Yes', 'No']"
          dropdownTitle="Is weighed?"
          @onInputChange="handleInputChange"
          @onSelect="handleSelect"
          :inputValue="discoveryForm.is_weighed"
          fieldName="is_weighed"
        />

        <TextInput
          placeholder="Actual Tonnage"
          @onInputChange="handleInputChange"
          :inputValue="discoveryForm.actual_tonnage"
          fieldName="actual_tonnage"
          v-if="isWeighedIsYes"
          style="margin-top: 20px"
        />
      </div>
    </section>

    <div class="border"></div>

    <section class="button-container">
      <div class="btn-container">
        <GreenButton label="Submit" @click="submitDiscovery" />
      </div>
    </section>
  </div>
</template>

<script>
import SelectDropdown from "../../form-inputs/select-dropdown/SelectDropdown.vue";
import TextInput from "../../form-inputs/text-input/TextInput.vue";
import GreenButton from "../../buttons/green-button/GreenButton.vue";
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "RejectModal2",
  components: {
    TextInput,
    SelectDropdown,
    GreenButton,
    Loading,
  },
  props: {
    discoveryId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      discoveryForm: {
        discovery_status: "Declined",
        price_per_ton: "",
        ask_price_per_ton: "",
        is_weighed: "",
        actual_tonnage: "",
      },
      isWeighedIsYes: false,
      loading: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal");
    },

    handleSelect(obj) {
      this.discoveryForm = {
        ...this.discoveryForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.discoveryForm = {
        ...this.discoveryForm,
        ...obj,
      };
    },

    async submitDiscovery(e) {
      e.preventDefault();
      this.loading = true;

      let {
        discovery_status,
        price_per_ton,
        ask_price_per_ton,
        is_weighed,
        actual_tonnage,
      } = this.discoveryForm;

      if (!ask_price_per_ton || !is_weighed) {
        createToast("Please fill all fields", {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });
        this.loading = false;
        return;
      }

      const id = this.discoveryId;

      const payload = {
        id,
        ask_price_per_ton: Number(ask_price_per_ton),
        is_weighed,
        actual_tonnage: Number(actual_tonnage),
        price_per_ton: Number(price_per_ton),
        discovery_status,
      };

      try {
        const data = await this.$store.dispatch(
          "discoveries/updateDiscoveryStatus",
          payload
        );



        if (data._id) {
          createToast("The discovery status has been updated successfully", {
            type: "success",
            position: "top-right",
            timeout: "3000",
          });
        }

        this.$emit("onCloseModal", false);

        window.location.reload();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        createToast(error.response.data.message, {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });
      }
    },
  },

  watch: {
    "discoveryForm.is_weighed"(newValue) {
      if (newValue === "Yes") {
        this.isWeighedIsYes = true;
      } else {
        this.isWeighedIsYes = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.form-container-div {
  width: 72.5%;
  margin: 25px auto 0px auto;
}

.form-container {
  height: 350px;
  overflow-y: scroll;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  width: 100%;
  height: 5rem;
  background: #fff;
  padding: 1rem;
}
</style>
