<template>
  <Header headerTitle="Add Commnity" iconName="fa fa-search" />

  <form class="form" @submit="handleSubmit">
    <div class="">
      <ul style="list-style: none">
        <li
          style="color: #cf6363; font-size: 1.2rem"
          v-for="(error, index) in errors"
          :key="index"
        >
          {{ error }}
        </li>
      </ul>
    </div>

    <Loading
      v-model:active="loading"
      :can-cancel="true"
      color="#669933"
      loader="spinner"
    />
    <TextInput
      placeholder="Name"
      @onInputChange="handleInputChange"
      :inputValue="communityForm?.name"
    />

    <!-- <TextInput
      placeholder="State"
      @onInputChange="handleInputChange"
      :inputValue="communityForm?.state"
    /> -->

    <SelectDropdown
      :options="states"
      class="select"
      dropdownTitle="State"
      @onSelect="handleSelect"
      :reset="reset"
      @onReset="handleReset"
      isLocation
    />

    <!-- <TextInput
      placeholder="LGA"
      @onInputChange="handleInputChange"
      :inputValue="communityForm?.lga_slug"
    /> -->

    <SelectDropdown
      :options="lgas"
      class="select"
      dropdownTitle="LGA"
      @onSelect="handleSelect"
      :reset="reset"
      @onReset="handleReset"
      isLocation
    />

    <SelectDropdown
      :options="['no', 'yes']"
      class="select"
      dropdownTitle="Currently at community"
      @onSelect="handleSelect"
      :reset="reset"
      @onReset="handleReset"
    />

    <p>If yes, community location will be geo tagged automatically.</p>
    <TextInput
      v-if="showCoordinateInput"
      placeholder="Longitude"
      @onInputChange="handleInputChange"
      :inputValue="communityForm?.longitude"
    />

    <TextInput
      v-if="showCoordinateInput"
      placeholder="Latitude"
      @onInputChange="handleInputChange"
      :inputValue="communityForm?.latitude"
    />

    <div class="button-container">
      <GreenButton label="Add Community" />
    </div>
  </form>
</template>

<script>
import Header from "../header/Header.vue";
import TextInput from "../form-inputs/text-input/TextInput.vue";
import SelectDropdown from "../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import { states } from "../../data/location/states";
// import { stateHash } from '../../data/location/statesHash';
import { lgas } from "../../data/location/lgaNames";
// import { lgasNameToSlug, lgaSlugToName } from '../../data/location/lgasHash';
// import { LGAs } from '../../data/location/lga';
import Loading from "vue-loading-overlay";
import GreenButton from "../buttons/green-button/GreenButton.vue";
import { getUserLocation } from '../../helpers/location/getUserLocation';
import { stateHash } from "../../data/location/statesHash";
import { lgasNameToSlug } from "../../data/location/lgasHash";
import { LGAs } from "../../data/location/lga";


export default {
  name: "AddCommunity",
  components: {
    Header,
    TextInput,
    GreenButton,
    SelectDropdown,
    Loading,
  },
  data() {
    return {
      communityForm: {
        name: "",
        state: "",
        lga: "",
        longitude: null,
        latitude: null,
        currently_at_community: '',
      },

      states: states,
      lgas: lgas,
      reset: false,
      loading: false,
      showCoordinateInput: false,
      errors: [],
    };
  },

  methods: {
    handleInputChange(obj) {
      this.communityForm = {
        ...this.communityForm,
        ...obj,
      };

    },

    getUserLocation() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
          (error) => {
            console.log(error.message);
            reject(error.message);
          }
        );
      });
    },

    handleSelect(obj) {
      this.communityForm = {
        ...this.communityForm,
        ...obj,
      };
    },

    handleReset(value) {
      this.reset = value;
    },


    clearForm() {
      this.communityForm = {
        name: "",
        state: "",
        lga: "",
        longitude: null,
        latitude: null,
        currently_at_community: '',
      };
    },

    async handleSubmit(e) {
      e.preventDefault();
      this.loading = true;

      if (
        !this.communityForm.name ||
        !this.communityForm.state ||
        !this.communityForm.lga ||
        !this.communityForm.longitude ||
        !this.communityForm.latitude
      ) {
        this.loading = false;
        this.errors = ["Please, all fields are required"];
        return;
      }
      // let slugForState = this.communityForm.state
      //   .replace(/ /g, "")
      //   .toUpperCase();

      // const state_slug = slugForState.substring(0, 3);

      // let slugForLga = this.communityForm.lga.replace(/ /g, "").toUpperCase();

      // const lga_slug = slugForLga.substring(0, 3);

      const payload = {
        name: this.communityForm.name,
        state_slug: stateHash[this.communityForm.state],
        lga_slug: lgasNameToSlug[this.communityForm.lga],
        longitude: Number(this.communityForm.longitude),
        latitude: Number(this.communityForm.latitude),
      };

      console.log(payload, "payload data");
      try {
        this.loading = true;

        const data = await this.$store.dispatch(
          "community/addCommunity",
          payload
        );

        console.log(data, "data from client");

        // Check if a community was successfully added

        if (data.name) {
          this.loading = false;

          alert("New community added successfully");

          this.clearForm();

          this.$router.push({ name: "CommunityPage" });
        } else {
          this.loading = false;
          this.errors = data.errors;
        }
           
        
      } catch (error) {
        console.log(error.message);
        alert("Error adding community");
      }
    },

    handleSetLgas(state_slug) {
      const selected_state_lgas = LGAs.filter((lga) => {
        return lga.state_slug === state_slug;
      })

      this.lgas = selected_state_lgas;
    },
  },

  watch: {

    'communityForm.state'(newValue) {
      const stateSlug = stateHash[newValue];
      this.handleSetLgas(stateSlug);
    },

    'communityForm.lga'(newValue) {
      const lgaSlug = lgasNameToSlug[newValue];

      console.log(lgaSlug, "lga slug");
    },
    async 'communityForm.currently_at_community'(newValue) {
      console.log(newValue, "newValue");
      if (newValue === "yes") {
        this.showCoordinateInput = true;

        try {
          this.loading = true;

          const response = await getUserLocation();

          this.loading = false;

          this.communityForm.latitude = response.latitude;
          this.communityForm.longitude = response.longitude;

        } catch (error) {
          console.log(error.message)
          this.loading = false
        }
      } else if (newValue === "no") {
        this.showCoordinateInput = true;
      }
    }
  }
};
</script>

<style>
.form {
  width: 90%;
  margin: 2rem auto 0px auto;
}
</style>
