<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Update Measurement Unit</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-container">
      <form class="form-container-div">
        <TextInput
          placeholder="Unit Name"
          fieldName="unitName"
          :value="price?.unitName"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="Default Price/Unit"
          fieldName="defaultPrice"
          :value="price?.defaultPrice"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="Weight(1 unit = ?kg)"
          fieldName="weight"
          :value="price?.weight"
          @onInputChange="handleInputChange"
        />
      </form>
    </section>

    <div class="border"></div>

    <div class="button-container">
      <GreenButton
        label="Update"
        width="10.5rem"
        height="2.9rem"
        @click="openAddDiscoveryModal2"
        :disable="isEmpty"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "EditPriceModal",
  components: {
    TextInput,
    GreenButton,
    Loading,
  },
};
</script>

<style scoped>
.modal-parent {
  height: 100%;
  background: #fff;
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  height: 230px;
  overflow-y: scroll;
}

.form-container::-webkit-scrollbar {
  display: none;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.input {
  width: 300px;
  height: 3.5rem;
  border: 0.5px solid #232a34;
  border-radius: 5px;
}

.input input {
  width: 100%;
  height: 100%;
}

.x-container:hover {
  background: #e8e8e8;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.category-container {
  margin-top: 70px;
}

.form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

p {
  font-size: 14px;
}

.form-container-div {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.actions-container {
  width: 100%;
  height: 5rem;
  background: #fff;
  border-top: 1px solid #b3b3b3;
  padding: 1rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions-img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.image-container {
  height: 40px;
  width: 40px;
}

.image-item {
  height: 100%;
  width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.discovery-text {
  font-size: 8px;
  color: #232a34;
  font-weight: 700;
}
</style>
