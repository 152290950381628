export const discrepancytableHeaders = [
  { text: "S/N", value: "no" },
  { text: "Location", value: "location" },
  { text: "Quantity Available", value: "qty" },
  { text: "Discrepancy", value: "discrepancy" },
  { text: "Discrepancy in Naira", value: "discrepancyInNaira" },
];

export const data = [
  {
    no: 1,
    location: "Farm Gate",
    purchaseDate: "July 24, 2023",
    qty: "125kg",
    discrepancy: "0.25kg",
    discrepancyInNaira: "100,000",
  },
  {
    no: 2,
    location: "C2",
    purchaseDate: "July 24, 2023",
    qty: "125kg",
    discrepancy: "0.25kg",
    discrepancyInNaira: "100,000",
  },
];
