export default {
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
};

export const roundToTwoDP = (num) => {
  return Number(Math.round(num + "e+2") + "e-2");
};

export const formatNumber = (num) => {
  let formattedNumber = num;

  if (formattedNumber >= 1000) {
    formattedNumber = (formattedNumber / 1000).toFixed(1) + "K";
  } else {
    formattedNumber = formattedNumber.toFixed(0);
  }

  return formattedNumber;
};
