export const aggregationTableHeaders = [
  { text: "ID", value: "aggregation_id" },
  { text: "Date", value: "createdAt" },
  { text: "Start Time", value: "startTime" },
  { text: "Created By", value: "createdBy" },
  { text: "AP", value: "AP" },
  //   { text: "Type", value: "phone" },
  { text: "Tonnage", value: "qtyMoved" },
  { text: "Driver", value: "driver" },
  { text: "Vehicle", value: "vehicle" },
  { text: "Approval Status", value: "status" },
  { text: "Verification Status", value: "verificationStatus" },
  { text: "Actions", value: "actions" },
];

export const dispatchTableHeaders = [
  { text: "ID", value: "dispatch_id" },
  { text: "Date", value: "createdAt" },
  { text: "Started By", value: "createdBy" },
  { text: "Factory", value: "factory" },
  { text: "Tonnage", value: "qtyMoved" },
  { text: "Driver", value: "driverName" },
  { text: "Vehicle", value: "vehicleName" },
  { text: "Approval Status", value: "authorisationStatus" },
  { text: "Verification Status", value: "verificationStatus" },
  { text: "Actions", value: "actions" },
];

export const scheduledDispatchTableHeaders = [
  { text: "Scheduled Date", value: "createdAt" },
  { text: "Scheduled By", value: "createdBy" },
  { text: "Purchase", value: "purchase" },
  { text: "Quantity", value: "qtyMoved" },
  { text: "Factory", value: "factory" },
  { text: "Approval Status", value: "status" },
  { text: "Status", value: "dispatchStatus" },
  { text: "Actions", value: "actions" },
];
