<template>
  <div class="settings-parent">
    <div class="settings-wrapper">
      <div class="settings-type__container">
        <div
          class="settings-type"
          :class="{
            active: activeLogisticsType === 'Price',
          }"
          @click="setActiveSettingsType(price)"
        >
          {{ price }} Settings
        </div>

        <div
          class="settings-type"
          :class="{ active: activeLogisticsType === 'SMS' }"
          @click="setActiveSettingsType(sms)"
        >
          {{ sms }} Settings
        </div>

        <div
          class="settings-type"
          :class="{ active: activeLogisticsType === 'Roles' }"
          @click="setActiveSettingsType(usertypesettings)"
        >
          {{ usertypesettings }} Settings
        </div>

        <div
          class="settings-type"
          :class="{ active: activeLogisticsType === 'Permissions' }"
          @click="setActiveSettingsType(permissions)"
        >
          {{ permissions }} Settings
        </div>
      </div>

      <div
        class=""
        v-if="
          activeLogisticsType === 'Price' ||
          activeLogisticsType === 'SMS' ||
          activeLogisticsType === usertypesettings ||
          activeLogisticsType === permissions
        "
      >
        <section class="search-bar-container">
          <div class="search-form">
            <input
              type="text"
              class="search-input"
              :class="activeLogisticsType === 'Price' && 'SMS'"
              placeholder="Search"
              v-model="searchTerm"
              @keyup.enter="handleSearchText"
            />
            <button><img src="../../assets/search-icon.png" alt="" /></button>
          </div>

          <GreenButton
            v-if="
              activeLogisticsType === 'SMS' ||
              activeLogisticsType === usertypesettings ||
              activeLogisticsType === permissions
            "
            :label="
              activeLogisticsType === 'SMS'
                ? 'Create SMS Template'
                : activeLogisticsType === usertypesettings
                ? 'Create User Type'
                : 'Create Permission'
            "
            width="14.5rem"
            height="2.9rem"
            iconName="add-icon"
            @click="openCreateUserTypeModal"
          />
        </section>

        <div class="data-table-container">
          <EasyDataTable
            v-model:items-selected="itemsSelected"
            :headers="
              activeLogisticsType === 'Price'
                ? priceHeaders
                : activeLogisticsType === 'SMS'
                ? smsHeaders
                : activeLogisticsType === usertypesettings
                ? rolesPermissionHeaders
                : permissionHeaders
            "
            :items="
              activeLogisticsType === 'Price'
                ? prices
                : activeLogisticsType === 'SMS'
                ? sms
                : activeLogisticsType === usertypesettings
                ? rolesPermissionList
                : permissionsList
            "
            alternating
            table-class-name="customize-table"
            class="data-table"
            :loading="loading"
          >
            <template #item-price="item">
              <div>{{ vueNumberFormat(item.price, {}) }}</div>
            </template>
            <template #item-actions="item">
              <div
                class="actions-wrapper"
                v-if="activeLogisticsType === 'Price' && item"
              >
                <div class="action" @click="openEditPriceModal">
                  <div class="actions-image">
                    <img
                      class="table-image"
                      src="../../assets/black-edit-pencil.png"
                      alt=""
                    />
                  </div>

                  <!-- <p class="table-text">Edit</p> -->
                </div>
                <div class="action">
                  <div class="actions-image-2">
                    <img
                      class="table-image"
                      src="../../assets/make-default.png"
                      alt=""
                    />
                  </div>

                  <!-- <p class="table-text">Make Default</p> -->
                </div>
              </div>

              <div
                class="actions-container"
                v-if="activeLogisticsType === usertypesettings"
              >
                <div class="action-2" @click="openRolesPermissionModal(item)">
                  <div class="action-image-container">
                    <img
                      class="action-image"
                      src="../../assets/black-eye.png"
                      alt=""
                    />
                  </div>
                  <p class="action-text">View Details</p>
                </div>
              </div>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>

    <div
      class="edit-price-modal"
      v-if="editPriceModal && activeLogisticsType === 'Price'"
    >
      <EditPriceModal @onCloseModal="handleCloseModal" />
    </div>

    <div class="roles-and-permission-modal" v-if="isRolesPermissionModalOpen">
      <RolesPermissionModal
        @onCloseModal="handleCloseModal"
        :userType="userType"
      />
    </div>

    <div
      class="create-usertype-modal"
      v-if="
        isCreateUserTypeModalOpen && activeLogisticsType === usertypesettings
      "
    >
      <CreateUserTypeModal @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="create-usertype-modal"
      v-if="isCreateUserTypeModalOpen && activeLogisticsType === permissions"
    >
      <CreatePermissionModal @onCloseModal="handleCloseModal" />
    </div>
  </div>
</template>

<script>
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import EditPriceModal from "./modals/EditPriceModal.vue";
import RolesPermissionModal from "./modals/RolesPermissionModal.vue";
import CreateUserTypeModal from "./modals/CreateUserTypeModal.vue";
import {
  priceHeaders,
  smsHeaders,
  rolesPermissionHeaders,
  permissionHeaders,
} from "./headers/headers";
import CreatePermissionModal from "./modals/CreatePermissionModal.vue";
export default {
  name: "Settings",
  components: {
    GreenButton,
    EditPriceModal,
    RolesPermissionModal,
    CreateUserTypeModal,
    CreatePermissionModal,
  },
  data() {
    return {
      price: "Price",
      sms: "SMS",
      usertypesettings: "Roles",
      activeLogisticsType: "Price",
      activeAtomType: "Settings",
      permissions: "Permissions",
      loading: false,
      priceHeaders,
      smsHeaders,
      prices: [
        {
          sn: "01",
          name: "Others",
          price: "₦1000",
          weight: "1kg",
        },
      ],
      messages: [],
      settings: "Settings",
      rolespermission: "Roles Persmission",
      rolesPermissionHeaders,
      permissionHeaders,
      rolesPermissionList: [],
      permissionsList: [],
      editPriceModal: false,
      isRolesPermissionModalOpen: false,
      itemClicked: "",
      userType: {},
      isCreateUserTypeModalOpen: false,
    };
  },

  mounted() {
    this.fetchAllUserTypesList();
    this.fetchAllPermission();
    // console.log(JSON.parse(localStorage.getItem("user")));

    // console.log(JSON.parse(localStorage.getItem("user")), "user");
  },

  methods: {
    setActiveSettingsType(type) {
      this.activeLogisticsType = type;
    },

    setActiveRolesTypes(type) {
      this.activeAtomType = type;
    },

    openEditPriceModal() {
      this.editPriceModal = !this.editPriceModal;
    },

    openRolesPermissionModal(arg) {
      this.isRolesPermissionModalOpen = !this.isRolesPermissionModalOpen;
      this.itemClicked = arg.id;
      this.filterUserTypesList();
    },

    handleCloseModal(status) {
      this.editPriceModal = status;
      this.isRolesPermissionModalOpen = status;
      this.isCreateUserTypeModalOpen = status;
    },

    openCreateUserTypeModal() {
      this.isCreateUserTypeModalOpen = !this.isCreateUserTypeModalOpen;
    },

    async fetchAllUserTypesList() {
      this.loading = true;

      try {
        const data = await this.$store.dispatch(
          "rolepermissions/fetchAllRoles"
        );

        this.rolesPermissionList = data;

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    filterUserTypesList() {
      this.loading = true;

      this.userType = this.rolesPermissionList.find(
        (item) => item.id === this.itemClicked
      );

      let permissions = {};

      let editPermissions = {};
      let viewPermissions = {};

      this.userType.editPermission?.forEach((el) => {
        editPermissions = {
          ...editPermissions,
          [el.name.toLocaleLowerCase() + "_settings"]: "Can Edit",
        };
      });

      this.userType.viewPermission?.forEach((el) => {
        viewPermissions = {
          ...viewPermissions,
          [el.name.toLocaleLowerCase() + "_settings"]: "Can View",
        };
      });

      // console.log(editPermissions);
      // console.log(viewPermissions);

      const viewKeys = Object.keys(viewPermissions);
      const edittKeys = Object.keys(editPermissions);

      viewKeys.forEach((el) => {
        if (edittKeys.includes(el)) {
          delete viewPermissions[el];
        }
      });
      // console.log(editPermissions);
      // console.log(viewPermissions);
      permissions = { ...viewPermissions, ...editPermissions };

      this.userType.permissions = permissions;

      this.loading = false;
    },

    async fetchAllPermission() {
      this.loading = true;

      try {
        const data = await this.$store.dispatch(
          "rolepermissions/fetchAllPermissions"
        );

        this.permissionsList = data;

        this.loading = false;
      } catch (error) {
        console.log(error);

        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.settings-parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
}

.settings-wrapper {
  width: 98%;
  margin: 1% auto 0 auto;
  height: 100%;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.settings-type__container {
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  /* border-bottom: 1px solid #b3b3b3; */

  margin-bottom: 30px;
}

.atom-type__container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 0px;
  align-items: center;
  margin-bottom: 20px;
  overflow-y: scroll;
}

.settings-type,
.atom-type {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #b3b3b3;
  font-size: 13px;
  cursor: pointer;
}

.active {
  color: #232a34 !important;
  border-bottom: 2px solid #669933;
  padding: 8px 16px;
}

/* .actions-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
}
*/

.actions-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
}

.actions-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* .action {
  cursor: pointer;
  gap: 8px;
}

*/

.action {
  cursor: pointer;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.action-2 {
  display: flex;
  gap: 10px;
}
.action-image-container {
  width: 18px;
  height: 18px;
}

.action-image {
  width: 100%;
  height: 100%;
}

.table-image {
  width: 100%;
  height: 100%;
}

.actions-image,
.actions-image-2 {
  width: 15px;
  height: 15px;
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

.actions-image:hover {
  top: -10px;
}

.actions-image-2:hover {
  top: -10px;
}

.actions-image:hover:after {
  content: "Edit";
  font-size: 8px;
  color: #232a34;
}

.actions-image-2:hover:after {
  content: "MakeDefault";
  font-size: 8px;
  color: #232a34;
  width: 100px;
}

.action-text {
  font-size: 10px;
  color: #232a34;
}
.table-text {
  display: none;
  color: #232a34;
  font-size: 6px;
}

.action:hover .table-text {
  display: block;
  transition: 0.8s ease;
}

.activeNow {
  color: #fff !important;
  background: #232a34;
  border-radius: 37px;
  padding: 8px 16px;
}

.data-table-container {
  width: 100%;
  /* border: 1px solid green; */
  height: 79.9%;
}

.data-table-container-role {
  width: 75%;
  height: 79.9%;
}

.data-table-container-2 {
  display: none;
}

.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 10px 16px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.atom-settings,
.pks-settings {
  height: 10rem;
}

.pko-settings {
  height: 30rem;
}

.atom-caption-wrapper,
.pks-caption-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.atom-caption,
.pks-caption {
  font-size: 14px;
  font-weight: 700;
  color: #669933;
}

.atom-caption-image,
.pks-caption-image {
  width: 20px;
  height: 20px;
}

.image-text {
  width: 100%;
  height: 100%;
}

.atom-box-container {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.pks-box-container {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.atom-box-text,
.pks-box-text {
  color: #333;
  font-weight: 800;
  font-size: 12px;
}

.atom-box-caption,
.pks-box-caption {
  color: #8c8c8c;
  font-weight: 400;
  font-size: 12px;
}

.edit-price-modal {
  max-width: 600px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.create-usertype-modal {
  max-width: 500px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.roles-and-permission-modal {
  max-width: 800px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}
</style>
