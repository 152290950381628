import styled from "vue3-styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // border: 1px solid red;
  padding-left: 10px;
  flex-wrap: wrap;
`;

export const Header = styled.p`
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 100;
  margin: 0px;
  width: 100%;
  // border: 1px solid red;
`;

export const Info = styled.p`
  color: ${({ status }) =>
    status === "approved"
      ? "#669933"
      : status === "verified"
      ? "#669933"
      : status === "moved"
      ? "#669933"
      : status === "available"
      ? "#669933"
      : status === "in-transit"
      ? "#669933"
      : status === "consumed"
      ? "#545454"
      : status === "rejected"
      ? "#ed0a0a"
      : status === "unmoved"
      ? "#ed0a0a"
      : status === "scheduled"
      ? "#f19306"
      : status === "pending"
      ? "#f19306"
      : status === "partially"
      ? "#f19306"
      : "#333333"};
  font-size: 13px;
  font-weight: 700;
  margin-top: 5px;
  width: 100%;
`;
