<template>
    <div v-if="community">
        <Header headerTitle="Community Details"/>

        <div class="info-container">
            <InfoBox header="Name" :info="community.name" class="div1" />

            <InfoBox header="State" :info="community.slug" class="div2" />

            <InfoBox header="LGA" :info="community.parent_slug" class="div3" />

            <InfoBox header="Create At" :info="community.createdAt" class="div4" />
        </div>
    </div>
</template>


<script>
import Header from "../header/Header.vue";
import InfoBox from "../info-box/InfoBox.vue";
export default {
    name: "CommunityDetails",
    components: {
        Header,
        InfoBox,
    },

    data() {
        return {
            community: null,
            loading: false,
            fullPage: true,
        }
    },

    mounted() {
        this.fetchCommunity();
    },

    methods: {
        async fetchCommunity() {
            const communityId = this.$route.params.communityId;

            try {
              this.community = await this.$store.dispatch("community/fetchOneCommunity", communityId);  
            } catch (error) {
                console.log(error.message)
            }
        },
    }
}
</script>


<style scoped>
.info-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 95%;
  margin: 20px auto 0px auto;
}


.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 2 / 1 / 3 / 2;
}

.div4 {
  grid-area: 2 / 2 / 3 / 3;
}


</style>