<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Edit Purchase</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <TextInput
          placeholder="Amount Paid"
          @onInputChange="handleInputChange"
          fieldName="price"
          :inputValue="purchaseForm?.price"
        />

        <TextInput
          placeholder="Actual Tonnage"
          @onInputChange="handleInputChange"
          fieldName="actual_tonnage"
          :inputValue="purchaseForm?.actual_tonnage"
        />

        <SelectDropdown
          :options="['Not Yet Paid', 'Partially Paid', 'Fully Paid']"
          dropdownTitle="Payment Status"
          @onSelect="handlePaymentStatusSelect"
          :inputValue="purchaseForm.paymentStatus"
          :reset="reset"
          fieldName="paymentStatus"
        />

        <SearchableMillsDropdown
          :options="mills"
          dropdownTitle="Mill"
          fieldName="millId"
          @onSelect="handleSelect"
          :inputValue="purchaseForm?.millName"
        />

        <DateField
          placeholder="Purchase Date"
          fieldName="purchase_date"
          :inputValue="purchaseForm?.purchase_date"
          @onInputChange="handleInputChange"
        />

        <!-- paymentStatus -->
      </form>
    </section>

    <div class="button-container">
      <GreenButton label="Update" @click="updatePurchase" />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import SearchableMillsDropdown from "../../../components/form-inputs/select-dropdown/SearchableMillsDropdown.vue";
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import DateField from "../../../components/form-inputs/date-input/DateField.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";

export default {
  name: "PurchaseEditModal",

  components: {
    TextInput,
    GreenButton,
    SearchableMillsDropdown,
    SelectDropdown,
    DateField,
    Loading,
  },

  data() {
    return {
      purchaseForm: this.$store.getters["purchases/getPurchaseToBeUpdated"] || {
        price: "",
        actual_tonnage: "",
        millId: "",
        millName: "",
        paymentStatus: "",
        purchase_date: "",
      },
      mills: [],
      loading: false,
    };
  },

  mounted() {
    this.getMills();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      const { millId } = obj;
      this.purchaseForm.millId = millId.id;
    },

    handlePaymentStatusSelect(obj) {
      this.purchaseForm = {
        ...this.purchaseForm,
        ...obj,
      };
    },

    async getMills() {
      const params = {
        page: 1,
        searchTerm: "",
      };
      try {
        const { mills } = await this.$store.dispatch("mills/getMills", params);

        // console.log(mills);

        if (mills.length) {
          this.mills = mills.map((mill) => {
            return {
              id: mill.id,
              name: mill.name,
              latitude: mill.latitude,
              longitude: mill.longitude,
            };
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    handleInputChange(obj) {
      this.purchaseForm = {
        ...this.purchaseForm,
        ...obj,
      };
    },

    async updatePurchase() {
      const payload = {
        id: this.purchaseForm._id,
        price: Number(this.purchaseForm.price),
        actual_tonnage: Number(this.purchaseForm.actual_tonnage),
        millId: this.purchaseForm.millId,
        payment_status: this.purchaseForm.paymentStatus,
        purchase_date: this.purchaseForm.purchase_date,
      };

      this.loading = true;

      try {
        const updatedPurchase = await this.$store.dispatch(
          "purchases/updatePurchase",
          payload
        );

        if (updatedPurchase._id) {
          this.loading = false;
          createToast("Purchase updated successfully!", {
            type: "success",
            timeout: 3000,
            position: "top-right",
            onClose: () => {
              return window.location.reload();
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: auto;
}

.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 7% auto 2% auto;
}
</style>
