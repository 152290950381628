<template>
  <div class="custom-sidebar__container">
    <div class="custom-sidebar__header">
      <div class="header-logo__container">
        <div class="wrapper">
          <div class="logo-container">
            <img
              src="../../assets/header-logo.png"
              alt=""
              class="header-logo"
            />
          </div>
          <h6>Releaf</h6>
        </div>
      </div>
    </div>

    <section class="sidebar-menu">
      <div class="sidebar-menu__wrapper">
        <SidebarLink to="/dashboard" iconName="dashboard-icon" route="Dashboard"
          >Dashboard</SidebarLink
        >

        <div class="white-border"></div>

        <SidebarLink
          to="/dashboard/discovery"
          class="discovery"
          iconName="dashboard-icon"
          route="Discovery"
          >Discovery</SidebarLink
        >

        <SidebarLink
          to="/dashboard/purchases"
          iconName="purchase"
          route="Purchases"
          >Purchases</SidebarLink
        >

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/farmers"
          iconName="farmer-icon"
          route="Farmers"
          >Farmers</SidebarLink
        >

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/logistics/dispatch"
          iconName="logistics-icon"
          route="Logistics"
          >Logistics</SidebarLink
        >

        <SidebarLink
          to="/dashboard/mills"
          iconName="mill-icon"
          route="Mills"
          v-if="getUserType !== 'FactoryCustomer'"
          >Mills</SidebarLink
        >

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/inventory"
          iconName="inventory-icon"
          route="Inventory"
          >Inventory</SidebarLink
        >

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/supplyValidation"
          iconName="tag-solid"
          route="Supply Validation"
          >Supply Validation</SidebarLink
        >

        <div class="white-border"></div>

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/asset-management"
          iconName="settings-icon"
          route="Asset Management"
          >Asset Management</SidebarLink
        >

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/outbound-awareness"
          iconName="bound-solid"
          route="Outbound Awareness"
          >Outbound Awareness</SidebarLink
        >

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/settings"
          iconName="settings-icon"
          route="Settings"
          >Settings</SidebarLink
        >

        <SidebarLink
          to="/dashboard/profit-input"
          iconName="profit-input"
          route="Inputs to Determine Profitability of P1 Purchases"
          >Profit Input</SidebarLink
        >

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/overhead"
          iconName="profit-input"
          route="Overhead costs"
          >Overhead Costs</SidebarLink
        >

        <SidebarLink
          v-if="getUserType !== 'FactoryCustomer'"
          to="/dashboard/revenue"
          iconName="profit-input"
          route="ProfitInput"
          >Revenue</SidebarLink
        >
      </div>
    </section>
  </div>
</template>

<script>
import { AuthUser } from "../../utils/authUser";
import SidebarLink from "../sidebar-link/SideBarLink.vue";

export default {
  name: "SideBar",
  components: {
    SidebarLink,
  },

  computed: {
    getUserType() {
      const authUser = new AuthUser();
      const { userType } = authUser.getDetails();
      return userType?.name || "User";
    },
  },
};
</script>

<style scoped>
.custom-sidebar__container {
  width: 100%;
  height: 100%;
  background: #232a34;
}

.custom-sidebar__header {
  height: 7.2%;
  background: #ffffff;
  box-shadow: 4px 0px 16px rgba(171, 171, 171, 0.16);
}

.wrapper {
  display: flex;
  width: 80%;
  height: 70%;
  margin-inline: auto;
  align-items: center;
}

.header-logo {
  width: 40px;
  height: 40px;
}

.logo-container {
  margin-top: 17px;
}

h6 {
  color: #8fc54d;
  text-transform: uppercase;
  margin-left: 5px;
  font-weight: bold;
  font-size: 21px;
  margin-top: 17px;
}

.sidebar-menu__wrapper {
  display: flex;
  flex-direction: column;
  width: 85%;
  height: auto;
  margin-top: 8px;
  margin-inline: auto;
  padding: 10px 3px;
}

.white-border {
  margin-top: 10px;
  border-bottom: 1px solid #fff;
}

.discovery {
  margin-top: 20px;
}
</style>
