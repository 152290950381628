<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <h5 class="modal-caption">Tag Mills</h5>

          <h5 class="modal-caption">Step 2 of 2</h5>
          <div class="header-content-right">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-section">
      <form class="form">
        <SearchableStatesDropdown
          :options="states"
          class="select"
          dropdownTitle="State"
          @onSelect="handleSelect"
          fieldName="state"
          :inputValue="millForm?.state"
        />

        <SearchableStatesDropdown
          :options="lgas"
          class="select"
          dropdownTitle="Local Government"
          @onSelect="handleSelect"
          fieldName="lga"
          :inputValue="millForm?.lga"
        />

        <SearchableStatesDropdown
          :options="communities"
          class="select"
          dropdownTitle="Community"
          @onSelect="handleSelect"
          fieldName="community"
          :inputValue="millForm?.community"
        />

        <SelectDropdown
          :options="['Yes', 'No']"
          class="select"
          dropdownTitle="Are you currently at the mill site?"
          :inputValue="millForm.currently_at_mill_site"
          fieldName="currently_at_mill_site"
          @onSelect="handleSelect"
          @onInputChange="handleInputChange"
        />

        <p>If yes, mill will be geo tagged automatically.</p>
        <TextInput
          v-if="showCoordinateInput"
          placeholder="Latitude"
          @onInputChange="handleInputChange"
          :inputValue="millForm?.latitude"
          fieldName="latitude"
        />
        <TextInput
          v-if="showCoordinateInput"
          placeholder="Longitude"
          @onInputChange="handleInputChange"
          :inputValue="millForm?.longitude"
          fieldName="longitude"
        />
      </form>
    </section>
    <div class="text-indicator">
      <p class=""><span>Step 2</span> of 2</p>
    </div>

    <div class="progress-bar-container">
      <div class="indicator"></div>
    </div>

    <div class="button-container">
      <InvertedGreenButton
        label="Back"
        @click="routeToPreviousPage"
        width="108px"
      />

      <GreenButton label="Tag Mill" @click="tagMill" width="128px" />
    </div>
  </div>
</template>

<script>
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import InvertedGreenButton from "../../../components/buttons/inverted-green-button/InvertedGreenButton.vue";
import { getUserLocation } from "../../../helpers/location/getUserLocation";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";
import SearchableStatesDropdown from "../../../components/form-inputs/select-dropdown/SearchableStatesDropdown.vue";
import { LGAs } from "../../../data/location/lga";
import { states } from "../../../data/location/states";

export default {
  name: "AddMillsModal2",
  components: {
    SelectDropdown,
    TextInput,
    GreenButton,
    InvertedGreenButton,
    Loading,
    SearchableStatesDropdown,
  },
  data() {
    return {
      millForm: {
        state: "",
        community: "",
        lga: "",
        latitude: "",
        longitude: "",
        currently_at_mill_site: "",
      },
      showCoordinateInput: false,

      loading: false,
      states: this.sortState(states),
      communities: [],
      lgas: [],
    };
  },

  methods: {
    handleInputChange(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    closeModal() {
      this.$emit("onCloseModal", false);
    },

    routeToPreviousPage() {
      this.$emit("onCloseModal");
      this.$emit("openAddMillsModal", true);
    },

    getUserLocation() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
          (error) => {
            console.log(error.message);
            reject(error.message);
          }
        );
      });
    },

    sortState(stateArr) {
      return stateArr.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },

    fetchLgas(state_slug) {
      try {
        this.loading = true;

        const lga = LGAs.filter((el) => el.parent_slug === state_slug);

        if (lga.length > 0) {
          lga.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }

        this.lgas = lga;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async fetchCommunities(parent_slug) {
      try {
        this.loading = true;
        const data = await this.$store.dispatch("community/getCommunites");

        const communities = data.filter(
          (el) => el?.parent_slug === parent_slug
        );

        if (communities.length > 0) {
          communities.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }

        this.communities = communities;
        console.log(this.communities);

        this.loading = false;
      } catch (error) {
        console.log(error.message);
      }
    },

    handleSelect(obj) {
      this.millForm = {
        ...this.millForm,
        ...obj,
      };
    },

    handleReset(value) {
      this.reset = value;
    },

    async tagMill(e) {
      e.preventDefault();

      let { state, community, latitude, longitude, lga } = this.millForm;

      if (!state) {
        createToast("Please fill all field", {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });
        return;
      }

      try {
        this.loading = true;

        const {
          name,
          physically_tagged,
          size,
          cracker,
          number_of_pots,
          number_of_pressers,
        } = await this.$store.getters["mills/getInputtedMills"];

        const payload = {
          name,
          physically_tagged,
          size,
          cracker,
          number_of_pots,
          number_of_pressers,
          stateId: state?._id,
          communityId: community?.id,
          lgaId: lga._id,
          location: {
            latitude: latitude && Number(latitude),
            longitude: longitude && Number(longitude),
          },
        };

        const data = await this.$store.dispatch("mills/tagMill", payload);

        if (data.id) {
          createToast("Mill tagged successfully", {
            type: "success",
            position: "top-right",
            timeout: 3000,
          });
        }

        this.loading = false;
        window.location.reload();
        this.$emit("onCloseModal");
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },
  },

  watch: {
    async "millForm.currently_at_mill_site"(newValue) {
      console.log({ newValue });
      if (newValue === "Yes") {
        this.showCoordinateInput = true;

        try {
          this.loading = true;
          const res = await getUserLocation();

          this.millForm.latitude = res.latitude;
          this.millForm.longitude = res.longitude;

          this.loading = false;
        } catch (error) {
          console.log(error.message);
          this.loading = false;
        }
      } else if (newValue === "No") {
        this.showCoordinateInput = true;
        this.millForm.latitude = "";
        this.millForm.longitude = "";
      } else {
        this.showCoordinateInput = false;
      }
    },

    "millForm.state"(newValue) {
      // console.log({ newValue });
      this.fetchLgas(newValue.slug);
    },

    "millForm.lga"(newValue) {
      // console.log({ newValue });
      if (newValue && this.millForm.state === "") {
        createToast("Please select a state", {
          type: "danger",
          position: "top-right",
          timeout: 3000,
        });
      } else {
        this.fetchCommunities(newValue.slug);
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.input {
  width: 300px;
  height: 3.5rem;
  border: 0.5px solid #232a34;
  border-radius: 5px;
}

.input input {
  width: 100%;
  height: 100%;
}

.x-container:hover {
  background: #e8e8e8;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.category-container {
  margin-top: 70px;
}

.form-section {
  height: 200px;
  overflow-y: scroll;
}

.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 100%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
