import { IdConfig } from '../../config/idConfig';

const state = () => ({
  fieldAgents: [],
});

const getters = {
  getFieldAgentsList: (state) => state.fieldAgents,
};

const mutations = {
  setFieldAgents(state, fieldAgents) {
    state.fieldAgents = fieldAgents;
  },
};

const actions = {
  async fetchFieldAgents(
    { commit },
    roles = [
      'sourcing_field_agent',
      'sourcing_extension_lead',
      'sourcing_extension_officer',
    ]
  ) {
    try {
      const res = await IdConfig.get('/list', {
        params: {
          roles,
        },
      });

      const fieldAgents = res.data;


      commit('setFieldAgents', fieldAgents);

      return fieldAgents;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
