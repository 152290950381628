import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import 'firebase/compat/analytics';
import store from "../store";

const firebaseConfig = {
  apiKey: "AIzaSyDq_yrQvkEobb4dp9AcaxDmy6ez_mBTgv4",
  authDomain: "salt-releaf.firebaseapp.com",
  databaseURL: "https://salt-releaf.firebaseio.com",
  projectId: "salt-releaf",
  storageBucket: "salt-releaf.appspot.com",
  messagingSenderId: "402499647053",
  appId: "1:402499647053:web:3d91af9a953d344b244e04",
  measurementId: "G-LBG88T6LZW",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const catchFirebaseAuthError = (errorType) => {
  switch (errorType.code) {
    case "auth/invalid-email":
      store.dispatch("auth/setError", "Invalid email");
      break;
    case "auth/user-not-found":
      store.dispatch("auth/setError", "No account with that email was found");
      break;
    case "auth/wrong-password":
      store.dispatch("auth/setError", "Email or password was incorrect");

      break;
    case "auth/network-request-failed":
      store.dispatch(
        "auth/setError",
        "Network error. Please check your connection"
      );

      break;
  }
};

export default firebase;
