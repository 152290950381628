<template>
  <div class="custom-header__parent">
    <div class="wrapper">
      <h5>{{ moduleName }}</h5>

      <div class="user-info__container">
        <div class="user-info">
          <!-- <p class="name">Gideon Ogunkola</p> -->
          <p class="name">{{ getUserNames }}</p>
          <div class="designation-wrapper">
            <p class="designation">{{ getUserType }}</p>
            <i
              class="fa fa-sort-desc"
              style="font-size: 15px"
              @click="toggleLoggOutButton"
            ></i>
          </div>

          <div class="logout" v-if="isLoggOutOpened" @click="logOut">
            Log out
          </div>
        </div>
        <div class="user-avatar__container">
          <img src="../../assets/avatar.png" alt="" class="avatar" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthUser } from "../../utils/authUser";

export default {
  name: "Header",

  data() {
    return {
      moduleName: this.$store.getters["layout/getClickedMenuItem"],
      isLoggOutOpened: false,
    };
  },

  computed: {
    clickedModule() {
      return this.$store.getters["layout/getClickedMenuItem"];
    },

    getUserNames() {
      const authUser = new AuthUser();
      const { first_name, last_name } = authUser.getDetails();
      return `${first_name} ${last_name}`;
    },

    getUserType() {
      const authUser = new AuthUser();
      const { userType } = authUser.getDetails();
      return userType?.name || "User";
    },
  },

  methods: {
    async logOut() {
      await this.$store.dispatch("auth/logout");
    },

    toggleLoggOutButton() {
      this.isLoggOutOpened = !this.isLoggOutOpened;
    },
  },

  watch: {
    clickedModule(newValue) {
      this.moduleName = newValue;
    },
  },
};
</script>

<style scoped>
.custom-header__parent {
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 16px rgba(171, 171, 171, 0.18);
}

.wrapper {
  /* width: 94%; */
  height: 95%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 15px 10px -15px rgb(0 0 0 / 5%);
  padding-left: 20px;
}

h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #232a34;
}

.user-info__container {
  display: flex;
  align-items: center;
  width: 20%;
  height: 70%;
}

.user-info {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.designation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.name {
  margin-left: auto;
  height: 8px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 27px;
  color: #669933;
}

.designation {
  margin-left: auto;
  margin-right: 5px;
  height: inherit;
  height: 6px;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #333333;
}

.user-avatar__container {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
}

.logout {
  /* border: 1px solid black; */
  background-color: #fff;
  border-radius: 3px;
  width: 60%;
  cursor: pointer;
  color: red;
  font-size: 12px;
  text-align: center;
  top: 45px;
  right: 0px;
  position: absolute;
  z-index: 5;
  box-shadow: 0px 4px 16px rgba(171, 171, 171, 0.18);
}
</style>
