<template>
  <div class="profit-input-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <div class="profit-wrapper">
      <div class="atom-settings-container">
        <div class="pks-settings">
          <div class="pks-caption-wrapper">
            <p class="pks-caption">MTD Revenue</p>

            <div
              class="pks-caption-image"
              v-if="EditPKS === true"
              @click="openEditPKSModal"
            >
              <img
                class="image-text"
                src="../../assets/edit-pencil.png"
                alt="edit pencil"
              />
            </div>
          </div>

          <div class="pks-box-container">
            <div class="pks-box">
              <p class="pks-box-caption">MTD Revenue</p>
              <p class="pks-box-text">
                {{ vueNumberFormat(revenueStatistics.mtdRevenue, {}) }}
              </p>
            </div>

            <div class="pks-box">
              <p class="pks-box-caption">Revenue Target</p>
              <p class="pks-box-text">
                {{ vueNumberFormat(revenueStatistics.revenueTarget, {}) }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">VOGP</p>
              <p class="pks-box-text">
                {{ vueNumberFormat(revenueStatistics.VOGP, {}) }}
              </p>
            </div>

            <div class="pks-box">
              <p class="pks-box-caption">VOGP Percentage</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.VOGPPercentage, {
                    prefix: "",
                    suffix: "%",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">Gross Profit</p>
              <p class="pks-box-text">
                {{ vueNumberFormat(revenueStatistics.grossProfit, {}) }}
              </p>
            </div>

            <div class="pks-box">
              <p class="pks-box-caption">MTD Revenue Percentage Margin</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(
                    revenueStatistics.mtdRevenuePercentageMargin,
                    {
                      prefix: "",
                      suffix: "%",
                      decimal: ".",
                      thousand: ",",
                      precision: "0",
                    }
                  )
                }}
              </p>
            </div>

            <div class="pks-box">
              <p class="pks-box-caption">Revenue Target Percentage Margin</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(
                    revenueStatistics.revenueTargetPercentageMargin,
                    {
                      prefix: "",
                      suffix: "%",
                      decimal: ".",
                      thousand: ",",
                      precision: "0",
                    }
                  )
                }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">C1 Price number</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.c1Price, {
                    prefix: "",
                    suffix: "",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">C2 Price Number</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.c2Price, {
                    prefix: "",
                    suffix: "",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">C1 Extraction number</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.c1Extraction, {
                    prefix: "",
                    suffix: "",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">C2 Extraction Number</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.c2Extraction, {
                    prefix: "",
                    suffix: "",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>

            <div class="pks-box">
              <p class="pks-box-caption">C1 Margin number</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.c1GrossMargin, {
                    prefix: "",
                    suffix: "",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">C2 Margin Number</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.c2GrossMargin, {
                    prefix: "",
                    suffix: "",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">C1 IronClad number</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.c1IronClad, {
                    prefix: "",
                    suffix: "",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>
            <div class="pks-box">
              <p class="pks-box-caption">C2 IronClad Number</p>
              <p class="pks-box-text">
                {{
                  vueNumberFormat(revenueStatistics.c2IronClad, {
                    prefix: "",
                    suffix: "",
                    decimal: ".",
                    thousand: ",",
                    precision: "0",
                  })
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="edit-pks-modal" v-if="isEditPKSModalOpened">
      <EditRevenueModal
        @onCloseModal="handleCloseModal"
        :mtdRevenue="revenueStatistics.mtdRevenue"
        :revenueTarget="revenueStatistics.revenueTarget"
        :VOGP="revenueStatistics.VOGP"
        :grossProfit="revenueStatistics.grossProfit"
        :VOGPPercentage="revenueStatistics.VOGPPercentage"
        :mtdRevenuePercentageMargin="
          revenueStatistics.mtdRevenuePercentageMargin
        "
        :revenueTargetPercentageMargin="
          revenueStatistics.revenueTargetPercentageMargin
        "
        :c1Price="revenueStatistics.c1Price"
        :c2Price="revenueStatistics.c2Price"
        :c1Extraction="revenueStatistics.c1Extraction"
        :c2Extraction="revenueStatistics.c2Extraction"
        :c1IronClad="revenueStatistics.c1IronClad"
        :c2IronClad="revenueStatistics.c2IronClad"
        :c1GrossMargin="revenueStatistics.c1GrossMargin"
        :c2GrossMargin="revenueStatistics.c2GrossMargin"
        :revenueID="revenueStatistics._id"
      />
    </div>
  </div>
</template>

<script>
import EditRevenueModal from "./modals/EditRevenueModal.vue";
import Loading from "vue-loading-overlay";
import { AuthUser } from "../../utils/authUser";

export default {
  name: "Revenue",
  components: {
    EditRevenueModal,
    Loading,
  },

  data() {
    return {
      atom: "Atom",
      pks: "PKS",
      pko: "PKO",
      pkc: "PKC",
      logistics: "Logistics",
      proc: "PROC",
      activeProfitType: "Atom",
      lmv_type: "<3T,<7T,<10T,<15T",
      lmv_type2: "<15T,<35T",
      isEditAtomModalOpened: false,
      isEditPKSModalOpened: false,
      isEditPKOModalOpened: false,
      isEditPKCModalOpened: false,
      isEditP1_LogisticsModalOpened: false,
      isEditP2_LogisticsModalOpened: false,
      isEditP1PROCModalOpened: false,
      isEditP2PROCModalOpened: false,
      revenueStatistics: {},
      loading: false,
      ViewAtom: false,
      ViewPKO: false,
      ViewPKS: false,
      ViewPKC: false,
      ViewLogistics: false,
      ViewPROC: false,
      EditAtom: false,
      EditPKO: false,
      EditPKC: false,
      EditLogistics: false,
      EditPROC: false,
      EditPKS: false,
    };
  },

  mounted() {
    this.fetchRevenueStatistics();
    this.getUserFromLocalStorage();
  },

  methods: {
    setActiveProfitType(profitType) {
      this.activeProfitType = profitType;
    },

    openEditAtomModal() {
      this.isEditAtomModalOpened = !this.isEditAtomModalOpened;
    },

    openEditPKSModal() {
      this.isEditPKSModalOpened = !this.isEditPKSModalOpened;
    },

    openPKCModal() {
      this.isEditPKCModalOpened = !this.isEditPKCModalOpened;
    },

    openP1_LogisticsModal() {
      this.isEditP1_LogisticsModalOpened = !this.isEditP1_LogisticsModalOpened;
    },

    openP2_LogisticsModal() {
      this.isEditP2_LogisticsModalOpened = !this.isEditP2_LogisticsModalOpened;
    },

    openP1PROCModal() {
      this.isEditP1PROCModalOpened = !this.isEditP1PROCModalOpened;
    },

    openP2PROCModal() {
      this.isEditP2PROCModalOpened = !this.isEditP2PROCModalOpened;
    },

    handleCloseModal(status) {
      this.isEditAtomModalOpened = status;
      this.isEditPKSModalOpened = status;
      this.isEditPKOModalOpened = status;
      this.isEditPKCModalOpened = status;
      this.isEditP1_LogisticsModalOpened = status;
      this.isEditP2_LogisticsModalOpened = status;
      this.isEditP1PROCModalOpened = status;
      this.isEditP2PROCModalOpened = status;
    },

    async fetchRevenueStatistics() {
      this.loading = true;
      try {
        this.revenueStatistics = await this.$store.dispatch(
          "revenue/fetchRevenueStatistics"
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    getUserFromLocalStorage() {
      this.loading = true;

      const authUser = new AuthUser();
      const user = authUser.getDetails();

      user?.permissions.forEach((el) => {
        if (el === "ViewPKO") {
          this.ViewPKO = true;
        }
        if (el === "ViewPKS") {
          this.ViewPKS = true;
        }
        if (el === "ViewPKC") {
          this.ViewPKC = true;
        }
        if (el === "ViewLogistics") {
          this.ViewLogistics = true;
        }
        if (el === "ViewPROC") {
          this.ViewPROC = true;
        }
        if (el === "EditPKO") {
          this.EditPKO = true;
          this.ViewPKO = true;
        }
        if (el === "EditPKS") {
          this.EditPKS = true;
          this.ViewPKS = true;
        }
        if (el === "EditPKC") {
          this.EditPKC = true;
          this.ViewPKC = true;
        }
        if (el === "EditLogistics") {
          this.EditLogistics = true;
          this.ViewLogistics = true;
        }
        if (el === "EditPROC") {
          this.EditPROC = true;
          this.ViewPROC = true;
        }

        if (el === "ViewAtom") {
          this.ViewAtom = true;
          this.ViewAtom = true;
        }

        if (el === "EditAtom") {
          this.EditAtom = true;
          this.ViewAtom = true;
        }
      });

      this.loading = false;
    },
  },

  computed: {
    config() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "₦ ",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
        disabled: true,
      };
    },
  },
};
</script>

<style scoped>
.profit-input-parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
}

.profit-wrapper {
  width: 98%;
  margin: 1% auto 0 auto;
  height: 100%;
}

.profit-type__container {
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 30px;
}

.profit-type {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #b3b3b3;
  font-size: 13px;
  cursor: pointer;
}

.active {
  color: #232a34 !important;
  border-bottom: 2px solid #669933;
  padding: 8px 16px;
}

.atom-settings-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.atom-settings,
.pks-settings {
  height: 10rem;
}

.atom-caption-wrapper,
.pks-caption-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.atom-caption,
.pks-caption {
  font-size: 14px;
  font-weight: 700;
  color: #669933;
}

.atom-caption-image,
.pks-caption-image {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.image-text {
  width: 100%;
  height: 100%;
}

.atom-box-container,
.pks-box-container {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.p1_log-container,
.p2_outlong {
  width: 100%;
  height: 20rem;
}

.p1_proc-settings,
.p2_proc-settings {
  width: 100%;
  height: 10rem;
}

.logistics-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.atom-box-text,
.pks-box-text {
  color: #333;
  font-weight: 800;
  font-size: 12px;
}

.pko-settings {
  height: 30rem;
}

.atom-box-caption,
.pks-box-caption {
  color: #8c8c8c;
  font-weight: 400;
  font-size: 12px;
}

.edit-atom-modal {
  max-width: 600px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.edit-pks-modal,
.edit-pko-modal,
.edit-pkc-modal,
.edit-p1-logistics-modal,
.edit-p2-logistic-modal,
.edit-p1-proc-modal,
.edit-p2-proc-modal {
  max-width: 600px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}
</style>
