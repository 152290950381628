import styled from 'vue3-styled-components';

export const TableContainer = styled.table`
  @media only screen and (min-width: 280px) {
    border-collapse: collapse;
    width: 95%;
    margin: 5% auto;

    & th {
      padding: 0.8rem 0;
      text-align: left;
      color: #232a34;
      font-size: 11px;
    }

    & td {
      width: 333px;
      height: 0px;
      border-top: 1px solid #e6e6e6;
      padding: 0.7rem 0px;
      color: #333333;
      font-size: 11px;
    }
  }
`;
