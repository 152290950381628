<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add Discovery</h5>
          </div>
          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="x-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="border"></div>

    <section class="form-container">
      <form class="form-container-div">
        <SelectDropdown
          :options="['Oil Palm']"
          dropdownTitle="Commodity"
          fieldName="commodity"
          :reset="reset"
          @onSelect="handleSelect"
          :inputValue="discoveryForm.commodity"
        />

        <DateField
          placeholder="Discovery Date"
          fieldName="declines_date"
          :inputValue="discoveryForm.declines_date"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="Estimated tonnage"
          fieldName="estimated_tonnage"
          :inputValue="discoveryForm.estimated_tonnage"
          @onInputChange="handleInputChange"
        />

        <SearchabledropFarmers
          :options="farmers"
          dropdownTitle="Farmer"
          fieldName="farmer"
          :reset="reset"
          @onSelect="handleSelect"
          :inputValue="discoveryForm.farmer"
        />

        <SearchableMillsDropdown
          :options="mills"
          dropdownTitle="Mill"
          fieldName="mill"
          :reset="reset"
          @onSelect="handleSelect"
          :inputValue="discoveryForm.mill"
        />
      </form>
    </section>

    <section class="actions-container">
      <div class="actions">
        <div class="actions-img">
          <div class="image-container">
            <img
              src="../../../assets/carbon_information-filled.png"
              class="image-item"
              alt="information-icon"
            />
          </div>

          <p class="discovery-text">
            You will be able to update the farmer's decision after you've added
            the discovery
          </p>
        </div>

        <div class="buttons-container">
          <div class="buttons-action">
            <InvertedGreenButton
              label="Back"
              width="8.4rem"
              height="2.9rem"
              @click="closeModal"
            />
          </div>
          <div class="buttons-action">
            <GreenButton
              label="Add Discovery"
              width="10.4rem"
              height="2.9rem"
              @click="handleSubmit"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SelectDropdown from "../../form-inputs/select-dropdown/SelectDropdown.vue";
import InvertedGreenButton from "../../../components/buttons/inverted-green-button/InvertedGreenButton.vue";
import GreenButton from "../../buttons/green-button/GreenButton.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";
import TextInput from "../../form-inputs/text-input/TextInput.vue";
import DateField from "../../form-inputs/date-input/DateField.vue";
import SearchabledropFarmers from "../../form-inputs/select-dropdown/SearchabledropFarmers.vue";
import SearchableMillsDropdown from "../../form-inputs/select-dropdown/SearchableMillsDropdown.vue";

export default {
  name: "AddDiscoveryModal2",
  components: {
    SelectDropdown,
    InvertedGreenButton,
    GreenButton,
    Loading,
    DateField,
    TextInput,
    SearchabledropFarmers,
    SearchableMillsDropdown,
  },

  data() {
    return {
      discoveryForm: {
        commodity: "",
        declines_date: "",
        estimated_tonnage: "",
        farmer: "",
        mill: "",
      },
      farmers: [],
      mills: [],
      loading: false,
      errors: [],
    };
  },

  mounted() {
    this.getFarmers();
    this.getMills();
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal");
    },

    handleSelect(obj) {
      this.discoveryForm = {
        ...this.discoveryForm,
        ...obj,
      };
    },

    handleInputChange(obj) {
      this.discoveryForm = {
        ...this.discoveryForm,
        ...obj,
      };
    },
    async getFarmers() {
      try {
        const { farmers } = await this.$store.dispatch(
          "farmers/fetchAllFarmersWithoutParams"
        );

        if (farmers.length) {
          this.farmers = farmers.map((farmer) => {
            return {
              id: farmer._id,
              name: `${farmer.name} - ${farmer.phone}`,
            };
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    async getMills() {
      const params = {
        page: 1,
        searchTerm: "",
      };
      try {
        const data = await this.$store.dispatch("mills/fetchMills", params);

        console.log(data);
        if (data) {
          this.mills = data.map((mill) => {
            return {
              id: mill.id,
              name: mill.name,
            };
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    async handleSubmit(e) {
      e.preventDefault();

      this.loading = true;

      const { commodity, declines_date, estimated_tonnage, farmer, mill } =
        this.discoveryForm;

      console.log(this.discoveryForm);

      if (
        !commodity ||
        !declines_date ||
        !estimated_tonnage ||
        !farmer ||
        !mill
      ) {
        this.errors.push("All fields are required");
        this.loading = false;
        return;
      }

      const estimatedCategory = await this.$store.getters[
        "discoveries/getEstimateCategory"
      ];

      try {
        const payload = {
          commodity,
          decline_date: declines_date,
          estimated_tonnage: Number(estimated_tonnage),
          estimate_category: estimatedCategory.estimate_category,
          farmerId: farmer.id,
          millId: mill.id,
          channel: "Web",
        };

        const data = await this.$store.dispatch(
          "discoveries/addDiscovery",
          payload
        );

        if (data) {
          this.loading = false;
          createToast("New Discovery Added", {
            type: "success",
            position: "top-right",
            timeout: 5000,
            onClose: () => {
              window.location.reload();
            },
          });

          this.$emit("onCloseModal");
        }
      } catch (error) {
        console.log(error.message);

        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  height: 450px;
}

.form-container::-webkit-scrollbar {
  display: none;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.input {
  width: 300px;
  height: 3.5rem;
  border: 0.5px solid #232a34;
  border-radius: 5px;
}

.input input {
  width: 100%;
  height: 100%;
}

.x-container:hover {
  background: #e8e8e8;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin: 10px 0px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.category-container {
  margin-top: 70px;
}

.form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

p {
  font-size: 14px;
}

.form-container-div {
  margin: 3% auto;
  width: 80%;
  height: 100%;
}
.actions-container {
  width: 100%;
  height: 4rem;
  background: #fff;
  border-top: 1px solid #b3b3b3;
  padding: 1rem;
  margin-top: 10px;
  flex: 1;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions-img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.image-container {
  height: 40px;
  width: 40px;
}

.image-item {
  height: 100%;
  width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.discovery-text {
  font-size: 8px;
  color: #232a34;
  font-weight: 700;
}
</style>
