<template>
  <div class="modal-parent">
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Add Discovery</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="border"></div>

    <section class="category-container">
      <form class="form">
        <p class="modal-caption">What type of discovery do you want to add?</p>
        <SelectDropdown
          :options="['Pile Estimation', 'Weight Buying']"
          dropdownTitle="Type of Discovery"
          fieldName="estimate_category"
          @onSelect="handleSelect"
          :reset="reset"
        />
      </form>
    </section>

    <div class="border"></div>

    <div class="button-container">
      <GreenButton
        label="Continue"
        width="7.5rem"
        height="2.9rem"
        @click="openAddDiscoveryModal2"
        :disable="isEmpty"
      />
    </div>
  </div>
</template>

<script>
import SelectDropdown from "../../form-inputs/select-dropdown/SelectDropdown.vue";
import GreenButton from "../../buttons/green-button/GreenButton.vue";
export default {
  name: "AddDiscoveryModal1",
  components: {
    SelectDropdown,
    GreenButton,
  },

  data() {
    return {
      obj: {
        estimate_category: "",
      },
    };
  },

  computed: {
    isEmpty() {
      const { estimate_category } = this.obj;
      if (!estimate_category) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    openAddDiscoveryModal2() {
      this.$emit("openAddDiscoveryModal2", 2);

      this.$store.dispatch(
        "discoveries/addEstimateCategory",
        this.obj.estimate_category
      );
    },
    closeModal() {
      this.$emit("onCloseModal");
    },

    handleSelect(value) {
      this.obj.estimate_category = value;
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.x-container:hover {
  background: #e8e8e8;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.category-container {
  height: 200px;
  padding-top: 30px;
}
.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}
.category-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

p {
  font-size: 14px;
}
</style>
