import { APIConfig } from "../../config/apiConfig";
import moment from "moment";

const formatData = (data) => {
  return data.map((cost) => ({
    _id: cost._id,
    noOfAgents: cost.noOfAgents,
    type: cost.type,
    split: cost.split,
    totalAgentsSalaries: cost.totalAgentsSalaries,
    month: moment(cost.month).format("MMMM-yyyy"),
    date: moment(cost.date).format("MM/DD/yyyy"),
    dateAdded: moment(cost.createdAt).format("MM/DD/yyyy"),
    createdBy: cost.agentDetails.first_name + " " + cost.agentDetails.last_name,
  }));
};

const state = () => ({
  otherOverheadCosts: [],
  costsToBeUpdated: {},
  error: null,
});

const getters = {
  getCostsToBeUpdated: (state) => state.costsToBeUpdated,
};

const mutations = {
  populateOtherOverheadCosts(state, otherOverheadCosts) {
    state.otherOverheadCosts = otherOverheadCosts;
  },

  setCostsToBeUpdated(state, costs) {
    state.costsToBeUpdated = costs;
  },
};

const actions = {
  findCostToBeUpdated({ commit, state }, costId) {
    let costs = state.otherOverheadCosts.find((cost) => cost._id === costId);

    commit("setCostsToBeUpdated", costs);
  },

  async fetchOtherOhCosts({ commit }, params) {
    const { page, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/other-overhead/?page=${page}&limit=${limit}`
      );

      const otherOverheadCosts = data?.data ? formatData(data?.data) : [];

      commit("populateOtherOverheadCosts", otherOverheadCosts);

      return {
        count: data?.count,
        cost: otherOverheadCosts,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async addNewCosts(_, payload) {
    try {
      const { data } = await APIConfig.post(`/other-overhead`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async filterByDatePeriod({ commit }, params) {
    const { page, limit, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `/other-overhead/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      if (Object.keys(data).length > 0) {
        const otherOverheadCosts = formatData(data?.data);

        commit("populateOtherOverheadCosts", otherOverheadCosts);

        return {
          count: data?.count,
          cost: otherOverheadCosts,
        };
      } else {
        return {
          count: 0,
          cost: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async createTarget(_, payload) {
    try {
      const { data } = await APIConfig.post(`/other-overhead/target`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateCosts(_, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/other-overhead/${payload.id}`,
        payload
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteCosts(_, id) {
    try {
      const { data } = await APIConfig.delete(`/other-overhead/${id}`);

      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
