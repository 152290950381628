<template>
  <div class="modal-parent">
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Edit Farmer</h5>

            <p>Step 2 of 4</p>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <Dropdown
          :options="statesDoc"
          class="select"
          dropdownTitle="State Of Residence"
          @onSelect="handleSelect"
          fieldName="stateId"
          fieldValue="stateName"
          :inputValue="farmerForm?.stateName"
        />

        <Dropdown
          :options="lgas"
          class="select"
          dropdownTitle="LGA Of Residence"
          @onSelect="handleSelect"
          fieldName="lgaId"
          fieldValue="lgaName"
          :inputValue="farmerForm?.lgaName"
        />

        <Dropdown
          v-if="communities.length > 0"
          :options="communities"
          class="select"
          dropdownTitle="Community Of Residence"
          fieldName="communityId"
          fieldValue="communityName"
          @onSelect="handleSelect"
          :inputValue="farmerForm?.communityName"
        />

        <TextInput
          placeholder="Address"
          @onInputChange="handleInputChange"
          fieldName="address"
          :inputValue="farmerForm?.address"
        />

        <SelectDropdown
          :options="['Nigeria']"
          class="select"
          dropdownTitle="Nationality"
          @onSelect="handleSelect"
          fieldName="nationality"
          :inputValue="farmerForm?.nationality"
        />
      </form>
    </section>

    <div class="text-indicator">
      <p class=""><span>Step 2</span> of 4</p>
    </div>

    <!-- Progress Bar -->
    <div class="progress-bar-container">
      <div class="indicator"></div>
    </div>

    <div class="button-container">
      <InvertedBlackButton
        label="Back"
        width="8rem"
        class="back-btn"
        @click="routeToPreviousPage"
      />
      <GreenButton label="Save and Continue" @click="routeToNextPage" />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
// import SearchableDropdown from "../../../components/form-inputs/select-dropdown/SearchableDropdown.vue";
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import Dropdown from "../../../components/form-inputs/select-dropdown/Dropdown.vue";

// import { states } from "../../../data/location/states";
import { statesDoc } from "../../../data/docs/statesDoc";
import { lgasDoc } from "../../../data/docs/lgasDoc";

export default {
  name: "EditFarmerModal2",

  components: {
    TextInput,
    GreenButton,
    InvertedBlackButton,
    // SearchableDropdown,
    SelectDropdown,
    Dropdown,
  },

  data() {
    return {
      farmerForm: this.$store.getters["farmers/getFarmerToBeUpdated"] || {
        state_of_origin: "",
        nationality: "",
        address: "",
        communityId: "",
        communityName: "",
        lgaName: "",
        lgaId: "",
        stateName: "",
        stateId: "",
      },
      states: statesDoc,
      statesDoc: statesDoc,
      lgas: lgasDoc,
      communities: [],
      communityResp: [],
      communitiesNameToSlug: {},
      communitiesSlugToName: {},
      globalInputtedFarmer: {},
    };
  },

  mounted() {
    this.fetchCommunities();
  },

  computed: {
    isRequiredFieldsEmpty() {
      const isEmpty =
        !this.farmerForm["state_of_origin"]?.length ||
        !this.farmerForm["nationality"]?.length;

      return isEmpty;
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.farmerForm = {
        ...this.farmerForm,
        ...obj,
      };
    },

    handleSelect(obj) {
      console.log(obj);
      this.farmerForm = {
        ...this.farmerForm,
        ...obj,
      };
    },

    routeToPreviousPage() {
      this.$emit("onSetAddModalPage", 1);
    },

    routeToNextPage() {
      this.$emit("onSetAddModalPage", 3);
      this.globalInputtedFarmer =
        this.$store.getters["farmers/getFarmerToBeUpdated"];
      this.globalInputtedFarmer = {
        ...this.globalInputtedFarmer,
        ...this.farmerForm,
      };

      this.$store.dispatch(
        "farmers/setValuesForFarmerUpdate",
        this.globalInputtedFarmer
      );
    },

    async fetchCommunities() {
      try {
        const data = await this.$store.dispatch("community/fetchCommunities");
        this.communityResp = data;
        this.communities = data.map((community) => ({
          id: community.id || community._id,
          name: community.name,
        }));
        console.log(this.communities);
      } catch (error) {
        console.log(error.message);
      }
    },
  },

  watch: {
    communityResp(newValue) {
      newValue.forEach((el) => {
        this.communitiesNameToSlug = {
          ...this.communitiesNameToSlug,
          [el.name]: el.slug,
        };
      });

      //communitiesSlugToName
      newValue.forEach((el) => {
        this.communitiesSlugToName = {
          ...this.communitiesSlugToName,
          [el.slug]: el.name,
        };
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: auto;
}

.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 50%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 7% auto 2% auto;
}

.back-btn {
  width: 33%;
}
</style>
