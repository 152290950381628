<template>
  <div>
    <styled-button :width="width" :height="height"
      ><img :src="imagePath" alt="" class="icon" v-if="iconName" /><span>{{
        label
      }}</span></styled-button
    >
  </div>
</template>

<script>
import { StyledButton } from "./blackButton.styles";

export default {
  name: "BlackButton",
  components: {
    StyledButton,
  },
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    iconName: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
  },


  computed: {
    imagePath() {
      return require(`../../../assets/${this.iconName}.png`);
    },
  },
};
</script>

<style scoped>
.icon {
  width: 11px;
  height: auto;
  display: block;
}

span {
  margin-left: 6px;
  display: block;
}
</style>
