export const tableHeaders = [
  { text: "Inv. ID", value: "inventoryId" },
  { text: "Purchase Date", value: "purchaseDate" },
  { text: "Quantity", value: "qty" },
  { text: "Purchase Cost", value: "price" },
  { text: "UpdatedAt", value: "updatedAt" },
  { text: "Status", value: "status" },
  { text: "Processing Status", value: "processingStatus" },
  { text: "Actions", value: "actions" },
];

export const data = [
  {
    _id: 1,
    atomId: "1234",
    purchaseDate: "July 24, 2023",
    qty: "125",
    price: "50,000",
    logisticCost: "100,000",
    updatedAt: "13-May-2022",
    status: "Completely Moved",
    processingStatus: "Consumed",
  },
  {
    _id: 2,
    atomId: "1234",
    purchaseDate: "July 24, 2023",
    qty: "125",
    price: "50,000",
    logisticCost: "100,000",
    updatedAt: "13-May-2022",
    status: "Partially Moved",
    processingStatus: "Aggregated",
  },
  {
    _id: 3,
    atomId: "1234",
    purchaseDate: "July 24, 2023",
    qty: "125",
    price: "50,000",
    logisticCost: "100,000",
    updatedAt: "13-May-2022",
    status: "Unmoved",
    processingStatus: "Available",
  },
];

export const inventoryLocation = [
  {
    name: "John",
    type: "Mill",
    tonnageAvailable: 0,
  },
  {
    name: "John",
    type: "Factory",
    tonnageAvailable: 5,
  },
];
