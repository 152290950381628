<template>
  <div>
    <H1>Farmer Dashboard</H1>
  </div>
</template>

<script>
  export default {
    name: "FarmerDash"
  }
</script>

<style scoped>

</style>