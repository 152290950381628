const state = () => ({
  refreshStatus: false,
  isDateFilter: false,
  filterMetaData: {
    filteredCosts: [],
    count: 0,
    query: "",
  },
  error: null,
});

const getters = {
  getRefreshStatus: (state) => state.refreshStatus,
  getDateFilterStatus: (state) => state.isDateFilter,
  getFilterMetaData: (state) => state.filterMetaData,
};

const mutations = {
  updateRefreshStatus(state, refreshStatus) {
    state.refreshStatus = refreshStatus;
  },

  setDateFilterStatus: (state, payload) => {
    state.isDateFilter = payload;
  },

  setFilterMetaData: (state, metaData) => {
    state.filterMetaData = {
      ...state.filterMetaData,
      ...metaData,
    };
  },
};

const actions = {
  setRefreshStatus({ commit }, status) {
    commit("updateRefreshStatus", status);
  },

  updateDateFilterStatus({ commit }, status) {
    commit("setDateFilterStatus", status);
  },

  updateFilterMetaData({ commit }, data) {
    commit("setFilterMetaData", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
