<template>
  <div class="chart-parent">
    <apexchart
      width="100%"
      height="100%"
      type="line"
      :series="series"
      :options="chartOptions"
      :colors="['#EA9A00', '#8FC54D']"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "LineCharts",
  props: {
    series: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "apexchart",
          toolbar: false,
        },
        grid: {
          show: true,
          strokeDashArray: 2,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          // categories: this.series,
          // title: {
          //   text: "",
          // },
          categories: [],
          title: {
            text: "",
          },
        },
        stroke: {
          curve: "smooth",
        },
        legend: {
          show: true,
          position: "top",
        },
      },
      colors: ["#EA9A00", "#8FC54D"],

      seriesLine: this.series,
    };
  },

  methods: {
    updateXAxisCategories(data) {
      this.chartOptions = {
        xaxis: {
          categories: data,
        },
      };
    },
  },

  watch: {
    series(newValue) {
      this.categories = [...newValue[0]?.xAxis];
      this.updateXAxisCategories(this.categories);
    },
  },
};
</script>
<style scoped>
.chart-parent {
  height: 100%;
}
</style>
