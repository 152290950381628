/* eslint-disable no-useless-catch */
import axios from "axios";
import router from "../../router";
import { IdConfig2 } from "../../config/idConfig";
import firebase from "../../config/firebase";
import { getAuth, signOut } from "firebase/auth";

const state = () => ({
  token: localStorage.getItem("userToken") || null,
  user: null,
  userRole: null,
  isAuth: false,
  currentUser: null,
  error: null,
  farmersCommitment: null
});

const getters = {
  getToken: (state) => state.token,
  getUser: (state) => state.user,
  getError: (state) => state.error,
  getUserRole: (state) => state.userRole,
  getAuth: (state) => state.isAuth,
  getCurrentUser: (state) => state.currentUser,
  isAuthenticated: (state) => !!state.token,
  getFarmersCommitment: (state) => state.farmersCommitment

};

const mutations = {
  saveFarmersCommitment(state, payload) {
    state.farmersCommitment = payload
  },
  setUser(state, data) {
    state.user = data;
  },

  setToken(state, token) {
    state.token = token;
  },

  setError(state, error) {
    state.error = error;
  },
  setUserRole(state, role) {
    state.userRole = role;
  },

  setAuth(state, isAuth) {
    state.isAuth = isAuth;
  },
};

const actions = {
  async fetchFarmersCommitment({ commit }) {
    try {

      // const resp = await APIConfig.get('/farmer-commitment');
      const resp = await axios.get('https://api.salt.releaf.ng/api/v2/farmer-commitment')
      console.log(resp);

      const commitment = resp.data;

      // const mappedDepots = depots.map((depot)=>depot.properties);
      console.log(commitment);

      await commit('saveFarmersCommitment', commitment);

      return commitment;
    } catch (error) {
      console.log(error);
      console.log('error in fetching the Aps');
    }
  },

  setCurrentUser({ commit }, user) {
    if (user) {
      commit("setUser", user);

      localStorage.setItem("user", JSON.stringify(user));
    } else {
      commit("setUser", null);
      localStorage.setItem("user", null);
    }
  },

  setError({ commit }, error) {
    console.log(error);
    commit("setError", error);
  },

  async login({ commit, dispatch }, { email, password }) {
    try {
      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      if (user) {
        const token = await user.getIdToken();

        if (!token) {
          return console.log("Token could not be generated");
        }

        localStorage.setItem("userToken", token);

        const { data } = await IdConfig2.get(`/auth/currentUser`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data) {
          dispatch("setCurrentUser", data);
          commit("setUser", data);
          commit("setAuth", true);
          return true;
        }
      }
    } catch (error) {
      console.log({ error });
    }
  },

  setAuth({ commit }, state) {
    commit("setAuth", state);
  },

  readLocalStorage() {
    return !!localStorage.getItem("token");
  },

  async resetPassword(email) {
    // commit("auth_request");
    const response = await axios.post(
      "http:/localhost:7001/api/auth/resetPassword",
      email
    );
    // console.log(response);
    // commit("auth_success", response.data);

    if (response.data.status === "success") {
      router.push({
        name: "Login",
        params: { message: "Password reset link has been sent to your email" },
      });
    } else {
      router.push({ name: "Login", message: response.data.message });
    }
    // router.push({ name: "Login" });
    // commit("auth_error", error.response.data.error);
  },

  async logout({ commit }) {
    // const router = useRouter();
    // console.log(router);
    try {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          commit("setUser", null);
          commit("setToken", null);
          localStorage.removeItem("userToken");
          localStorage.removeItem("user");

          router.push({ path: "/" });
        })
        .catch((error) => {
          // An error happened.
          console.log(error.message);
        });
      // firebase
      //   .auth()
      //   .signOut()
      //   .then(() => {
      //     commit("setUser", null);
      //     commit("setToken", null);
      //     localStorage.removeItem("userToken");
      //     localStorage.removeItem("user");

      //     router.push({ path: "/" });
      //   });
      return true;
    } catch (error) {
      console.log(error.message);
    }

    // if (router.currentRoute.name !== "Login") {
    //   return router.push({ name: "Login" });
    // } else {
    //   return router.go(0);
    // }
  },

  async fetchUser({ commit }, id) {
    try {
      const { data } = await IdConfig2.get(`/user/${id}`);

      // console.log({ data });

      commit("setUser", data);

      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
