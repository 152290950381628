<template>
  <div class="parent">
    <div class="parent-wrapper">
      <!-- Search Bar Section -->
      <section class="search-bar-container">
        <div class="search-form">
          <input
            type="text"
            class="search-input"
            placeholder="Search by farmer's name, phone number and state"
            v-model="searchTerm"
            @keyup.enter="handleSearchText"
          />
          <button><img src="../../assets/search-icon.png" alt="" /></button>
        </div>
        <!-- <div class="">Add Farmer Button</div> -->
        <GreenButton
          label="Add New farmer"
          width="12.9rem"
          height="2.9rem"
          iconName="add-icon"
          @click="openAddFarmerModal"
        />
      </section>

      <!-- Date Filter Section -->
      <section class="date-filter-container">
        <div class="wrapper">
          <p class="">Search by Period</p>
          <div class="controls-container">
            <div class="inputs-container">
              <div class="input">
                <input
                  type="text"
                  name=""
                  id="date"
                  placeholder="Select Start Date"
                  onfocus="(this.type = 'date')"
                  v-model="startDate"
                />
              </div>
              <div class="input">
                <input
                  type="text"
                  name=""
                  id="date"
                  placeholder="Select End Date"
                  onfocus="(this.type = 'date')"
                  v-model="endDate"
                />
              </div>
              <GreenButton
                label="Fetch"
                width="5rem"
                height="2rem"
                @click="fetchByDatePeriod(page)"
              />
            </div>

            <div v-if="itemsSelected.length > 0" class="multi-actions">
              <i
                class="fa fa-trash"
                style="font-size: 24px; color: red; cursor: pointer"
                @click="openDeleteModal"
              ></i>

              <GreenButton
                label="Approve"
                width="5rem"
                height="2rem"
                @click="openApprovalModal"
              />
            </div>

            <div class="buttons-container">
              <div class="">
                <download-excel :data="farmerJSON">
                  <InvertedBlackButton
                    label="Export"
                    width="8rem"
                    height="2.6rem"
                    iconName="excel-icon"
                  ></InvertedBlackButton>
                </download-excel>
              </div>
              <div class="">
                <BlackButton
                  label="Open Filter"
                  width="10rem"
                  height="2.6rem"
                  iconName="filter-icon"
                  @click="openFilterModal"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Data table section -->
      <section class="data-table-container">
        <EasyDataTable
          v-model:server-options="paginationMetaData"
          v-model:items-selected="itemsSelected"
          :server-items-length="count"
          :headers="headers"
          :items="farmers"
          alternating
          table-class-name="customize-table"
          class="data-table"
          :loading="loading"
          buttons-pagination
        >
          <template #item-authorization_status="item">
            <div class="status-wrapper">
              <div
                class="status"
                :class="[
                  item.authorization_status === 'Approved'
                    ? 'approved'
                    : item.authorization_status === 'Rejected'
                    ? 'rejected'
                    : 'pending',
                ]"
              >
                {{ item.authorization_status }}
              </div>
            </div>
          </template>
          <template #item-actions="item">
            <div class="actions-wrapper">
              <div class="" @click="toggleActions(item)">
                <div
                  class="action-options"
                  v-if="isActionsOpened && item._id === itemClicked"
                >
                  <div class="item-wrapper">
                    <div class="actions-item" @click="openDetailsModal">
                      <div class="image">
                        <img
                          src="../../assets/green-eye.png"
                          alt=""
                          class="action-icon green-eye"
                        />
                      </div>
                      <p class="view-action">View details</p>
                    </div>
                    <div class="actions-item" @click="handleEdit(item._id)">
                      <div class="">
                        <img
                          src="../../assets/edit-icon.png"
                          alt=""
                          class="action-icon edit-icon"
                        />
                      </div>
                      <p class="edit-action">Edit</p>
                    </div>
                    <div class="actions-item" @click="openDeleteModal">
                      <div class="">
                        <img
                          src="../../assets/delete-icon.png"
                          alt=""
                          class="action-icon delete-icon"
                        />
                      </div>
                      <p class="delete-action">Delete</p>
                    </div>
                  </div>
                </div>
                <span>&#8942;</span>
              </div>
            </div>
          </template>
        </EasyDataTable>
      </section>
    </div>

    <div class="farmer-modal" v-if="isAddFarmerModalOpened">
      <AddFarmerModal1
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 1"
      />
      <AddFarmerModal2
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 2"
      />
      <AddFarmerModal3
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 3"
      />
      <AddFarmerModal4
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 4"
      />
    </div>

    <div class="farmer-modal" v-if="isEditFarmerModalOpened">
      <EditFarmerModal1
        @onCloseModal="closeEditModal"
        @onSetAddModalPage="handleEditModalPage"
        v-if="modalPageNum === 1"
      />
      <EditFarmerModal2
        @onCloseModal="closeEditModal"
        @onSetAddModalPage="handleEditModalPage"
        v-if="modalPageNum === 2"
      />
      <EditFarmerModal3
        @onCloseModal="closeEditModal"
        @onSetAddModalPage="handleEditModalPage"
        v-if="modalPageNum === 3"
      />
      <EditFarmerModal4
        @onCloseModal="closeEditModal"
        @onSetAddModalPage="handleEditModalPage"
        v-if="modalPageNum === 4"
      />
    </div>

    <!-- Details Modal -->
    <div class="details-modal" v-if="isDetailsModalOpened">
      <DetailsModal
        @onCloseModal="handleCloseModal"
        :farmerId="itemClicked"
        @openDeleteModal="openDeleteModal"
        @openEditModal="openEditFarmerModal"
      />
    </div>

    <!-- Filter Modal -->
    <div class="filter-modal" v-if="isFilterModalOpened">
      <FarmersFilterForm />
    </div>

    <!-- Delete Modals -->
    <div class="delete-modal" v-if="isDeleteModalOpened">
      <DeleteModal :farmerId="itemClicked" @onCloseModal="handleCloseModal" />
    </div>

    <div
      class="delete-modal"
      v-if="isDeleteModalOpened && selectedFarmersIds.length"
    >
      <DeleteManyModal
        heading="Farmers"
        :ids="selectedFarmersIds"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <!-- Approval -->
    <div
      class="delete-modal"
      v-if="isApprovalStatus && selectedFarmersIds.length"
    >
      <ApproveManyModal
        heading="Farmers"
        :ids="selectedFarmersIds"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div class="confirmDelete" v-if="showDeletePrompt">
      <ConfirmDelete @onCloseModal="handleCloseModal" :commID="commID" />
    </div>
    <div class="addCommitmentModal" v-if="showAddCommitmentModal">
      <AddCommitmentModal
        @onCloseModal="handleCloseModal"
        :farmerId="itemClicked"
      />
    </div>
    <div class="updateCommitmentModal" v-if="showUpdateCommitmentModal">
      <UpdateCommitmentModal
        @onCloseModal="handleCloseModal"
        :farmerId="itemClicked"
      />
    </div>

    <!-- Overlay -->
    <div
      class="dark-overlay"
      v-if="
        isDetailsModalOpened ||
        isAddFarmerModalOpened ||
        isEditFarmerModalOpened ||
        isFilterModalOpened ||
        isDeleteModalOpened ||
        isApprovalStatus ||
        showDeletePrompt ||
        showAddCommitmentModal ||
        showUpdateCommitmentModal
      "
      @click="closeOverlay"
    ></div>
  </div>
</template>

<script>
import ConfirmDelete from "./ConfirmDelete.vue";
import AddCommitmentModal from "./commitment-modals/AddCommitmentModal.vue";
import UpdateCommitmentModal from "./commitment-modals/UpdateCommitmentModal.vue";
import DeleteModal from "./DeleteModal.vue";
import DeleteManyModal from "../../components/modals/DeleteManyModal.vue";
import ApproveManyModal from "../../components/modals/ApproveManyModal.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import BlackButton from "../../components/buttons/black-button/BlackButton.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import AddFarmerModal1 from "../../pages/farmers/add-farmer-modals/AddFarmerModal1.vue";
import AddFarmerModal2 from "../../pages/farmers/add-farmer-modals/AddFarmerModal2.vue";
import AddFarmerModal3 from "../../pages/farmers/add-farmer-modals/AddFarmerModal3.vue";
import AddFarmerModal4 from "../../pages/farmers/add-farmer-modals/AddFarmerModal4.vue";
import EditFarmerModal1 from "../../pages/farmers/edit-farmer-modals/EditFarmerModal1.vue";
import EditFarmerModal2 from "../../pages/farmers/edit-farmer-modals/EditFarmerModal2.vue";
import EditFarmerModal3 from "../../pages/farmers/edit-farmer-modals/EditFarmerModal3.vue";
import EditFarmerModal4 from "../../pages/farmers/edit-farmer-modals/EditFarmerModal4.vue";
import FarmersFilterForm from "./FarmersFilterForm.vue";
import DetailsModal from "./DetailsModal.vue";
import { tableHeaders } from "./table-headers/headers";
import { convertToFilterQueryString } from "../../utils";

export default {
  name: "Farmers",
  components: {
    GreenButton,
    BlackButton,
    InvertedBlackButton,
    DetailsModal,
    AddFarmerModal1,
    AddFarmerModal2,
    AddFarmerModal3,
    AddFarmerModal4,
    EditFarmerModal1,
    EditFarmerModal2,
    EditFarmerModal3,
    EditFarmerModal4,
    FarmersFilterForm,
    DeleteModal,
    DeleteManyModal,
    ApproveManyModal,
    ConfirmDelete,
    AddCommitmentModal,
    UpdateCommitmentModal,
  },
  data() {
    return {
      modalPageNum: 1,
      searchTerm: "",
      startDate: "",
      endDate: "",
      itemsSelected: [],
      selectedFarmersIds: [],
      list: [],
      headers: tableHeaders,
      loading: false,
      farmers: [],
      paginationMetaData: {
        page: 1,
        rowsPerPage: 100,
      },
      count: 0,
      isActionsOpened: false,
      itemClicked: "",
      isDetailsModalOpened: false,
      isAddFarmerModalOpened: false,
      isEditFarmerModalOpened: false,
      isFilterModalOpened: false,
      isDeleteModalOpened: false,
      isApprovalStatus: false,
      globalFilterQuery: "",
      showDeletePrompt: false,
      showAddCommitmentModal: false,
      showUpdateCommitmentModal: false,
      commID: "",
    };
  },

  mounted() {
    this.fetchAllFarmers();
  },

  methods: {
    async fetchAllFarmers(page = 1, limit = 100, searchTerm = "") {
      try {
        this.loading = true;
        const { farmers, count } = await this.$store.dispatch(
          "farmers/fetchAllFarmers",
          {
            page,
            limit,
            searchTerm,
          }
        );

        this.farmers = farmers;
        this.farmerJSON = farmers;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },

    openFilterModal() {
      this.isFilterModalOpened = !this.isFilterModalOpened;
    },

    openAddFarmerModal() {
      this.isAddFarmerModalOpened = !this.isAddFarmerModalOpened;
    },

    openEditFarmerModal() {
      this.isEditFarmerModalOpened = !this.isEditFarmerModalOpened;
    },

    openDetailsModal() {
      this.isDetailsModalOpened = !this.isDetailsModalOpened;
    },

    openDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },

    openApprovalModal() {
      this.isApprovalStatus = !this.isApprovalStatus;
    },

    handleCloseModal(status) {
      this.isDetailsModalOpened = status;
      this.isAddFarmerModalOpened = status;
      this.isDeleteModalOpened = status;
      this.isApprovalStatus = status;
      this.showDeletePrompt = status;
      this.showAddCommitmentModal = status;
      this.showUpdateCommitmentModal = status;
    },

    closeEditModal(status) {
      this.isEditFarmerModalOpened = status;
    },

    handleAddModalPage(pageNum) {
      this.modalPageNum = pageNum;
    },

    handleEditModalPage(pageNum) {
      this.modalPageNum = pageNum;
    },

    closeOverlay() {
      this.isDetailsModalOpened = false;
      this.isAddFarmerModalOpened = false;
      this.isEditFarmerModalOpened = false;
      this.isFilterModalOpened = false;
      this.isDeleteModalOpened = false;
      this.showDeletePrompt = false;
      this.showAddCommitmentModal = false;
      this.showUpdateCommitmentModal = false;
    },

    async handleSearchText(e) {
      const pageNum = 1;
      this.fetchAllFarmers(pageNum, 100, e.target.value);
    },

    toggleActions(arg) {
      this.itemClicked = arg._id;
      this.isActionsOpened = !this.isActionsOpened;
    },

    handleEdit(id) {
      this.openEditFarmerModal();
      this.$store.dispatch("farmers/findFarmerToBeUpdated", id);
    },

    async fetchByDatePeriod(page = 1) {
      if (this.startDate === "" || this.endDate === "") return;
      const query = convertToFilterQueryString({
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.loading = true;
      try {
        const { count, farmers } = await this.$store.dispatch(
          "farmers/filterFarmersByDatePeriod",
          {
            page,
            filterQuery: query,
          }
        );

        this.farmers = farmers;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async filterFarmers(page, filterQuery) {
      try {
        this.loading = true;
        const { filteredFarmers, count } = await this.$store.dispatch(
          "farmers/filterFarmers",
          {
            page,
            filterQuery,
          }
        );

        this.farmers = filteredFarmers;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    deleteItem(val) {
      this.items = this.items.filter((item) => item.id !== val.id);
    },
  },

  computed: {
    filterMetaData() {
      return this.$store.getters["farmers/getFilterFarmerMetaData"];
    },
    handleCommIDValue() {
      return this.$store.getters["overviews/getCommID"];
    },
    handleShowAddCommitmentModal() {
      return this.$store.getters["overviews/getAddCommitmentModal"];
    },
    handleUpdatedFarmerCommitment() {
      return this.$store.getters["overviews/getUpdateCommitmentInfo"];
    },
  },

  watch: {
    paginationMetaData: {
      handler: function (newValue) {
        const isFilter = this.$store.getters["farmers/getFilterStatus"];

        const isDateFilter =
          this.$store.getters["farmers/getIsDateFilterStatus"];

        if (isFilter) {
          this.filterFarmers(newValue.page, this.globalFilterQuery);
        } else if (isDateFilter) {
          this.fetchByDatePeriod(newValue.page);
        } else {
          this.fetchAllFarmers(
            newValue.page,
            newValue.rowsPerPage,
            this.searchTerm
          );
        }
      },
      deep: true,
    },

    handleCommIDValue(newValue) {
      if (newValue) {
        this.commID = newValue.commID;
        this.showDeletePrompt = newValue.showDeleteModal;
      }
    },
    handleShowAddCommitmentModal(newValue) {
      this.showAddCommitmentModal = newValue;
    },
    handleUpdatedFarmerCommitment(newValue) {
      this.showUpdateCommitmentModal = newValue.editBool;
    },

    farmers(newValue) {
      this.farmerJSON = newValue;
    },

    filterMetaData(newValue) {
      const { filteredFarmers, count, query } = newValue;

      this.globalFilterQuery = query;
      this.farmers = filteredFarmers;
      this.count = count;
    },

    itemsSelected: {
      handler: function (newValue) {
        this.selectedFarmersIds = newValue.map((farmer) => farmer._id);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
  overflow-y: scroll;
}

.parent-wrapper {
  width: 98%;
  margin: 1% auto 0 auto;
  height: 100%;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.multi-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
}

.date-filter-container {
  width: 100%;
  margin-top: 3%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 85%;
}

.wrapper p {
  margin-bottom: 0px;
}

.date-filter-container p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.78rem;
  line-height: 24px;
  color: #333333;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 27%;
  /* border: 1px solid #000; */
}

.data-table-container {
  width: 100%;
  /* border: 1px solid green; */
  height: 79.9%;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

/* Data table Styles */
.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 9px 11px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;

  width: 127px;
  height: 32px;

  border-radius: 41px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.approved {
  color: #669933;
  background: rgba(102, 153, 51, 0.15);
}

.rejected {
  color: #ed0a0a;
  background: rgba(237, 10, 10, 0.15);
}

.pending {
  color: #f19306;
  background: rgba(241, 147, 6, 0.15);
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5px;

  position: absolute;
  width: 160%;
  height: auto;
  right: 60px;

  /* White */

  background: #ffffff;
  /* Drop down shadow */

  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
}

.item-wrapper {
  width: 90%;
  margin: 0 auto;
}

.actions-item {
  display: flex;
  cursor: pointer;
}

.action-icon {
  margin-right: 5px;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.view-action {
  color: #669933;
}

.edit-action {
  color: #ea9a00;
}

.delete-action {
  color: #ed0a0a;
}

.details-modal {
  max-width: 1000px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.farmer-modal {
  max-width: 720px;
  height: auto;
}

.details-modal,
.farmer-modal {
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.filter-modal,
.close-filter-modal {
  position: absolute;
  top: 0px;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter-modal {
  animation: slideFromRightToLeft 1s ease-in-out;
  animation-delay: 10ms;
  left: 72%;
  width: 450px;
}

.close-filter-modal {
  animation: slideBackIn 1s ease-in-out;
  animation-delay: 10ms;
  left: 100%;
  display: hidden;
}

.delete-modal {
  max-width: 500px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

@keyframes slideFromRightToLeft {
  0% {
    left: 100%;
  }
  50% {
    left: 72%;
  }
  100% {
    left: 72%;
  }
}

@keyframes slideBackIn {
  0% {
    left: 72%;
  }
  50% {
    left: 72%;
  }
  100% {
    left: 100%;
  }
}
.confirmDelete,
.addCommitmentModal,
.updateCommitmentModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: blue;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}
</style>
