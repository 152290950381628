import { createApp } from "vue";
import App from "./App.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import moshaToast from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import Vue3JsonExcel from "vue3-json-excel";
import JsonExcel from "vue-json-excel3";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import router from "./router";
import store from "./store/index";
import VueApexCharts from "vue3-apexcharts";
import VueNumberFormat from "vue-number-format";
import vSelect from "vue-select";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "../public/assets/css/global.css"

import "vue-select/dist/vue-select.css";

import DashboardLayout from "./components/layout/DashboardLayout.vue";
import firebase from "./config/firebase";

let app = createApp(App);

app.use(VueApexCharts);
app.use(router);
app.use(store);
app.use(moshaToast);
app.component("vue3JsonExcel", Vue3JsonExcel.Vue3JsonExcel);
app.component("downloadExcel", JsonExcel);
app.component("EasyDataTable", Vue3EasyDataTable);
app.component("dashboardLayout", DashboardLayout);
app.component("v-select", vSelect);
app.component("VueDatePicker", VueDatePicker);

app.use(VueNumberFormat, {
  prefix: "₦",
  decimal: ".",
  thousand: ",",
  precision: "0",
});

app.mount("#app");

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch("auth/setAuth", true);
  } else {
    store.dispatch("auth/setAuth", false);
  }
});
