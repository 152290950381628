import axios from "axios";
import { getTokenFromCookie } from "../../src/helpers/middleware/crossSharing";
import firebase from "../config/firebase";
import { AuthUser } from "../utils/authUser";

const baseURL = `${process.env.VUE_APP_AUTH_SERVICE_BASE_URL}/api/user`;

const baseUrl2 = `${process.env.VUE_APP_AUTH_SERVICE_BASE_URL}/api/`;
export const IdConfig = axios.create({
  baseURL,
  headers: {
    Authorization: getTokenFromCookie(),
  },
});

export const IdConfig2 = axios.create({
  baseURL: baseUrl2,
});

IdConfig2.interceptors.request.use(
  (config) => {
    const authUser = new AuthUser();
    if (authUser.getToken()) {
      config.headers["Authorization"] = "Bearer " + authUser.getToken();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

IdConfig2.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
              const newToken = await user.getIdToken();
              localStorage.setItem("userToken", newToken);

              originalRequest.headers.common[
                "Authorization"
              ] = `Bearer ${newToken}`;

              return IdConfig2(originalRequest);
            }
          });
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);
