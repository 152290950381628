<template>
  <div class="modal-parent">
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add Farmer</h5>

            <p>Step 3 of 4</p>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <SearchableDropdown
          :options="lgas"
          class="select"
          dropdownTitle="LGA Of Origin"
          @onSelect="handleSelect"
          fieldName="lga_of_origin"
          :inputValue="farmerForm?.lga_of_origin"
        />

        <SelectDropdown
          :options="['Single', 'Married', 'Divorce', 'Widow', 'Widower']"
          class="select"
          dropdownTitle="Marital Status"
          fieldName="marital_status"
          @onSelect="handleSelect"
          :inputValue="farmerForm?.marital_status"
        />

        <SelectDropdown
          :options="[
            'Mill Owner',
            'P1 Owner',
            'Middleman',
            'Supplier',
            'P1 Merchant',
            'Aggregator',
          ]"
          class="select"
          dropdownTitle="Supplier Type"
          fieldName="supplierType"
          @onSelect="handleSelect"
          :inputValue="farmerForm?.supplierType"
        />

        <TextInput
          placeholder="Bank"
          @onInputChange="handleInputChange"
          fieldName="bank"
          :inputValue="farmerForm?.bank"
        />

         <TextInput
          placeholder="BVN"
          @onInputChange="handleInputChange"
          fieldName="bvn"
          :inputValue="farmerForm?.bvn"
        />

        <TextInput
          placeholder="National Identification Number"
          @onInputChange="handleInputChange"
          :inputValue="farmerForm?.nin"
          fieldName="nin"
        />
      </form>
    </section>

    <div class="text-indicator">
      <p class=""><span>Step 3</span> of 4</p>
    </div>

    <!-- Progress Bar -->
    <div class="progress-bar-container">
      <div class="indicator"></div>
    </div>

    <div class="button-container">
      <InvertedBlackButton
        label="Back"
        width="8rem"
        class="back-btn"
        @click="routeToPreviousPage"
      />
      <GreenButton
        label="Save and Continue"
        @click="routeToNextPage"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import SearchableDropdown from "../../../components/form-inputs/select-dropdown/SearchableDropdown.vue";

import { lgas } from "../../../data/location/lgaNames";

export default {
  name: "AddFarmerModal3",

  components: {
    TextInput,
    GreenButton,
    InvertedBlackButton,
    SelectDropdown,
    SearchableDropdown,
  },

  data() {
    return {
      farmerForm: this.$store.getters["farmers/getInputtedFarmer"] || {
        lga_of_origin: "",
        marital_status: "",
        supplierType: "",
        bank: "",
        bvn: "",
        nin: "",
      },
      lgas: lgas,
      globalInputtedFarmer: {},
    };
  },

  computed: {
    isRequiredFieldsEmpty() {
      const requiredFields = {
        lga_of_origin: this.farmerForm["lga_of_origin"],
        marital_status: this.farmerForm["marital_status"],
        supplierType: this.farmerForm["supplierType"],
        bank: this.farmerForm["bank"],
        nin: this.farmerForm["nin"],
      };
      const isEmpty = Object.values(requiredFields).some((el) => !el?.length);
      return isEmpty;
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    routeToPreviousPage() {
      this.$emit("onSetAddModalPage", 2);
    },

    handleInputChange(obj) {
      this.farmerForm = {
        ...this.farmerForm,
        ...obj,
      };
    },

    handleSelect(obj) {
      this.farmerForm = {
        ...this.farmerForm,
        ...obj,
      };
    },

    routeToNextPage() {
      this.$emit("onSetAddModalPage", 4);
      this.globalInputtedFarmer =
        this.$store.getters["farmers/getInputtedFarmer"];
      this.globalInputtedFarmer = {
        ...this.globalInputtedFarmer,
        ...this.farmerForm,
      };

      this.$store.dispatch(
        "farmers/setInputtedFarmer",
        this.globalInputtedFarmer
      );

    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: auto;
}

.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 75%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 7% auto 2% auto;
}

.back-btn {
  width: 33%;
}
</style>
