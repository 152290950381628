import moment from "moment";
import { APIConfig } from "../../config/apiConfig";

const formatSingleDispatch = (dispatch) => {
  return {
    ...dispatch,
    purchase: dispatch.purchaseId?.purchase_id || "Nil",
    factory: dispatch.factory ? dispatch.factory.name : "Factory",
    ap: dispatch.apId ? dispatch.apId.name : "Nil",
    createdBy: dispatch.startedBy
      ? dispatch.startedBy.first_name
      : dispatch.createdBy
      ? dispatch.createdBy.first_name
      : "Nil",
    qtyMoved: dispatch.qtyMoved ? dispatch.qtyMoved.toString() : "Nil",
    verifiedTonnage: dispatch.verifiedTonnage
      ? dispatch.verifiedTonnage.toString()
      : "Nil",
    loaderCost: dispatch.loaderCost ? dispatch.loaderCost.toString() : "Nil",
    imprestCost: dispatch.imprestCost ? dispatch.imprestCost.toString() : "Nil",
    fuelCost: dispatch.fuelCost ? dispatch.fuelCost.toString() : "Nil",
    checkPointCost: dispatch.checkPointCost
      ? dispatch.checkPointCost.toString()
      : "Nil",
    communityCost: dispatch.communityCost
      ? dispatch.communityCost.toString()
      : "Nil",
    offloadingCost: dispatch.offloadingCost
      ? dispatch.offloadingCost.toString()
      : "Nil",
    createdAt: moment(dispatch.dispatch_starts_at).format("DD-MM-YYYY"),
    startDate: moment(dispatch.dispatch_starts_at).format("DD-MMMM-YYYY"),
    endDate: dispatch.dispatch_ends_at
      ? moment(dispatch.dispatch_ends_at).format("DD-MM-YYYY")
      : "Nil",
    driver: dispatch.driver ? dispatch.driver.name : "Nil",
    authStatus: dispatch.authorisationStatus,
    dispatchStatus: dispatch.status,
    verificationStatus: dispatch.verification_status,
    vehicle: dispatch.vehicle ? dispatch.vehicle.name : "Nil",
  };
};

const formatDispatchData = (data) => {
  return data.map((dispatch) => {
    return {
      _id: dispatch.id,
      dispatch_id: dispatch.dispatch_id,
      factory: dispatch.factory ? dispatch.factory.name : "Nil",
      dispatches: JSON.stringify(
        dispatch.dispatches.map((item) => ({
          purchaseId: item.purchaseId,
          qtyMoved: item.qtyMoved,
          qtyLeft: item.qtyLeft,
        }))
      ),
      apName: dispatch.apId?.name || "Nil",
      createdBy: dispatch.startedBy
        ? `${dispatch.startedBy.first_name} ${dispatch.startedBy.last_name}`
        : dispatch.createdBy
        ? `${dispatch.createdBy.first_name} ${dispatch.createdBy.last_name}`
        : "Nil",
      qtyMoved: dispatch.qtyMoved ? dispatch.qtyMoved : "Nil",
      verifiedTonnage: dispatch.verifiedTonnage
        ? dispatch.verifiedTonnage
        : "Nil",
      loaderCost: dispatch.loaderCost ? dispatch.loaderCost : 0,
      imprestCost: dispatch.imprestCost ? dispatch.imprestCost : 0,
      fuelCost: dispatch.fuelCost ? dispatch.fuelCost : 0,
      communityCost: dispatch.communityCost ? dispatch.communityCost : 0,
      offloadingCost: dispatch.offloadingCost ? dispatch.offloadingCost : 0,
      checkPointCost: dispatch.checkPointCost ? dispatch.checkPointCost : 0,
      totalDispatchCost: dispatch.totalDispatchCost
        ? dispatch.totalDispatchCost
        : 0,
      createdAt: moment(dispatch.dispatch_starts_at).format("DD-MM-YYYY"),
      dispatch_starts_at: moment(dispatch.dispatch_starts_at).format(
        "DD-MM-YYYY"
      ),
      dispatch_ends_at: moment(dispatch.dispatch_ends_at).format("DD-MM-YYYY"),
      updatedAt: moment(dispatch.updatedAt).format("DD-MM-YYYY"),
      driverName: dispatch.driver ? dispatch.driver.name : "Nil",
      status: dispatch.status,
      authorisationStatus: dispatch.authorisationStatus,
      verificationStatus: dispatch.verification_status,
      vehicleName: dispatch.vehicle ? dispatch.vehicle.name : "Nil",
    };
  });
};

const formatScheduledDispatchData = (data) => {
  return data.map((dispatch) => {
    return {
      ...dispatch,
      _id: dispatch.id,
      factory: dispatch.factory ? dispatch.factory.name : "Nil",
      purchase: dispatch.purchaseId?.purchase_id || "Nil",
      createdBy: dispatch.startedBy ? dispatch.startedBy.first_name : "Nil",
      qtyMoved: dispatch.qtyMoved ? dispatch.qtyMoved : "Nil",
      createdAt: moment(dispatch.dispatch_starts_at).format("DD-MM-YYYY"),
      status: dispatch.authorisationStatus,
      dispatchStatus: dispatch.status,
    };
  });
};

const state = () => ({
  dispatches: [],
  dispatchToBeUpdated: {},
  selectedDispatchIds: [],
  searchTerm: "",
  isFilter: false,
  isDateFilter: false,
  error: null,
  dispatchId: "",
  activeLogisticsTab: "",
  editModalStatus: false,
  filterModalStatus: false,
  deleteModalStatus: false,
  isDetailsModalOpen: false,
  isApprovalModalStatus: false,
  verifyModalStatus: false,
  inputtedDispatch: {
    apName: "",
    apId: "",
    factory: "",
    qtyMoved: "",
    vehicle: "",
    vehicleName: "",
    driver: "",
    driverName: "",
    dispatch_starts_at: "",
    loaderCost: "",
    fuelCost: "",
    imprestCost: "",
    communityCost: "",
    offloadingCost: "",
    checkPointCost: "",
    totalDispatchCost: 0,
  },
  filterMetaData: {
    filteredDispatches: [],
    count: 0,
    query: "",
  },
});

const getters = {
  getDispatches: (state) => {
    return state.dispatches;
  },

  getSelectedIds: (state) => {
    return state.selectedDispatchIds;
  },

  getDetailsModalStatus: (state) => {
    return state.isDetailsModalOpen;
  },

  getDispatchId: (state) => {
    return state.dispatchId;
  },

  getVerifyModalStatus: (state) => {
    return state.verifyModalStatus;
  },

  getApprovalModalStatus: (state) => {
    return state.isApprovalModalStatus;
  },

  getSearchTerm: (state) => {
    return state.searchTerm;
  },

  getFilterStatus: (state) => {
    return state.isFilter;
  },

  getIsDateFilterStatus: (state) => {
    return state.isDateFilter;
  },

  getActiveLogisticsTab: (state) => {
    return state.activeLogisticsTab;
  },

  getEditModalStatus: (state) => {
    return state.editModalStatus;
  },

  getDeleteModalStatus: (state) => {
    return state.deleteModalStatus;
  },

  getFilterModalStatus: (state) => {
    return state.filterModalStatus;
  },

  getDispatchToBeUpdated: (state) => {
    return state.dispatchToBeUpdated;
  },
  getError: (state) => {
    return state.error;
  },
  getFilterMetaData: (state) => {
    return state.filterMetaData;
  },

  getInputtedDispatch: (state) => {
    return state.inputtedDispatch;
  },
};

const mutations = {
  setSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm;
  },

  setDispatchIds(state, ids) {
    state.selectedDispatchIds = ids;
  },

  setApprovalModalStatus(state, status) {
    state.isApprovalModalStatus = status;
  },

  setVerifyModalStatus(state, status) {
    state.verifyModalStatus = status;
  },

  setDetailsModalStatus(state, status) {
    state.isDetailsModalOpen = status;
  },

  setActiveLogisticsTab(state, activeTab) {
    state.activeLogisticsTab = activeTab;
  },

  setEditModalStatus(state, status) {
    state.editModalStatus = status;
  },

  setFilterModalStatus: (state, status) => {
    state.filterModalStatus = status;
  },

  setDeleteModalStatus: (state, status) => {
    state.deleteModalStatus = status;
  },

  setDispatchId(state, id) {
    state.dispatchId = id;
  },

  setIsFilter(state, status) {
    state.isFilter = status;
  },

  setIsDateFilter(state, status) {
    state.isDateFilter = status;
  },

  setInputtedDispatch(state, dispatch) {
    state.inputtedDispatch = dispatch;
  },

  saveDispatches(state, dispatches) {
    state.dispatches = dispatches;
  },

  setDispatchToBeUodated(state, dispatch) {
    state.dispatchToBeUpdated = dispatch;
  },

  setError(state, error) {
    state.error = error;
  },

  setValuesForDispatchUpdate(state, dispatch) {
    state.dispatchToBeUpdated = {
      ...state.dispatchToBeUpdated,
      ...dispatch,
    };
  },
  setFilterMetaData(state, metaData) {
    state.filterMetaData = {
      ...state.filterMetaData,
      ...metaData,
    };
  },

  resetForm(state, form) {
    state.inputtedDispatch = form;
  },
};

const actions = {
  setInputtedDispatch({ commit }, dispatch) {
    commit("setInputtedDispatch", dispatch);
  },

  updateMultipleIds({ commit }, ids) {
    commit("setDispatchIds", ids);
  },

  updateApprovalModalStatus({ commit }, status) {
    commit("setApprovalModalStatus", status);
  },

  setVerifyModalStatus({ commit }, status) {
    commit("setVerifyModalStatus", status);
  },

  setDispatchId({ commit }, id) {
    commit("setDispatchId", id);
  },

  setDetailsModalStatus({ commit }, status) {
    commit("setDetailsModalStatus", status);
  },

  setValuesForDispatchUpdate({ commit }, dispatch) {
    commit("setValuesForDispatchUpdate", dispatch);
  },

  setActiveLogisticsTab({ commit }, activeTab) {
    commit("setActiveLogisticsTab", activeTab);
  },

  setEditModalStatus({ commit }, status) {
    commit("setEditModalStatus", status);
  },

  updateFilterModalStatus: ({ commit }, status) => {
    commit("setFilterModalStatus", status);
  },

  updateDeleteModalStatus: ({ commit }, status) => {
    commit("setDeleteModalStatus", status);
  },

  findDispatchToBeUpdated({ commit, state }, dispatchId) {
    let dispatchToUpdated = state.dispatches.find(
      (dispatch) => dispatch._id === dispatchId
    );

    dispatchToUpdated = {
      ...dispatchToUpdated,
      apId: dispatchToUpdated.apId?._id,
      dispatch_starts_at: moment(
        dispatchToUpdated["dispatch_starts_at"]
      ).format("YYYY-MM-DD"),
    };

    commit("setDispatchToBeUodated", dispatchToUpdated);
  },

  resetDispatchForm({ commit }) {
    const dispatch = {
      apName: "",
      apId: "",
      factory: "",
      qtyMoved: "",
      vehicle: "",
      vehicleName: "",
      driver: "",
      driverName: "",
      dispatch_starts_at: "",
      loaderCost: "",
      fuelCost: "",
      imprestCost: "",
      communityCost: "",
      offloadingCost: "",
      checkPointCost: "",
      totalDispatchCost: 0,
    };
    commit("resetForm", dispatch);
  },

  async startDispatchFromMill({ commit, dispatch }, payload) {
    try {
      const { data } = await APIConfig.post("/dispatch/start", payload);
      dispatch("resetDispatchForm");
      return data;
    } catch (error) {
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async startDispatchFromAP({ commit, dispatch }, payload) {
    try {
      const { data } = await APIConfig.post("/dispatch", payload);
      dispatch("resetDispatchForm");
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async scheduleDispatch({ commit }, payload) {
    try {
      const { data } = await APIConfig.post("/dispatch/schedule", payload);
      // dispatch("resetDispatchForm");
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async updateDispatch({ commit }, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/dispatch/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async verifyDispatch({ commit }, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/dispatch/verify/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async fetchAllDispatches({ commit }, params) {
    const { page, searchTerm, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/dispatch/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
      );

      const dispatches = formatDispatchData(data?.data);

      commit("saveDispatches", dispatches);

      return {
        count: data?.count,
        dispatches,
        searchText: searchTerm,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async fetchOnlyScheduledDispatches({ commit }, params) {
    const { page, searchTerm } = params;
    try {
      const { data } = await APIConfig.get(
        `/dispatch/scheduled?page=${page}&searchTerm=${searchTerm}`
      );

      const dispatches = formatScheduledDispatchData(data?.data);

      commit("saveDispatches", dispatches);

      return {
        count: data?.count,
        dispatches,
        searchText: searchTerm,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async filterDispatchesByDatePeriod({ commit }, params) {
    const { page, filterQuery, limit } = params;

    try {
      const { data } = await APIConfig.get(
        `/dispatch/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      commit("setIsDateFilter", true);

      if (Object.keys(data).length > 0) {
        const dispatches = formatDispatchData(data?.data);

        commit("setFilterMetaData", {
          count: data?.count,
          dispatches,
          filterQuery,
        });

        return {
          count: data?.count,
          dispatches,
          filterQuery,
        };
      } else {
        return {
          count: 0,
          dispatches: [],
          filterQuery,
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async filterScheduledDispatchesByDatePeriod({ commit }, params) {
    const { page, filterQuery } = params;

    try {
      const { data } = await APIConfig.get(
        `/dispatch/scheduled/date-range?page=${page}&${filterQuery}`
      );

      commit("setIsDateFilter", true);

      // commit("setFilterFarmerMetaData", filterQuery);

      if (Object.keys(data).length > 0) {
        const dispatches = formatScheduledDispatchData(data?.data);

        commit("saveDispatches", dispatches);

        commit("setFilterMetaData", {
          count: data?.count,
          dispatches,
          filterQuery,
        });

        return {
          count: data?.count,
          dispatches,
          filterQuery,
        };
      } else {
        return {
          count: 0,
          dispatches: [],
          filterQuery,
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async filterDispatches({ commit }, params) {
    const { page, filterQuery, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/dispatch/filter?page=${page}&limit=${limit}&${filterQuery}`
      );

      let response = {
        filteredDispatches: [],
        count: 0,
        query: filterQuery,
      };

      if (data?.count > 0) {
        response.filteredDispatches = formatDispatchData(data?.data);
        response.count = data?.count;
      }
      commit("setIsFilter", true);
      commit("setFilterMetaData", response);

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchOneDispatch({ commit }, dispatchId) {
    try {
      let { data } = await APIConfig.get(`/dispatch/${dispatchId}`);
      data = formatSingleDispatch(data);

      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async approveManyDispatch({ commit }, dispatchIds) {
    console.log(commit);
    try {
      const { data } = await APIConfig.patch(`/dispatch/approvals`, {
        dispatchIds,
      });

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async deleteDispatch({ commit }, dispatchId) {
    try {
      const { data } = await APIConfig.delete(`/dispatch/${dispatchId}`);
      return data;
    } catch (error) {
      console.log(error.response.data);
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async deleteManyDispatch({ commit }, ids) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(`/dispatch?dispatchIds=${ids}`);

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
