import { APIConfig } from "../../config/apiConfig";
import { stateSlugToName } from "../../data/location/statesHash";
import { lgaSlugToName } from "../../data/location/lgasHash";
import moment from "moment";

const formatSingleFarmer = (farmer) => {
  return {
    ...farmer,
    name: farmer.first_name ? farmer.first_name : "Nil",
    community: farmer.communityId?.name || "Nil",
    lga: farmer.lgaId
      ? farmer.lgaId.name
      : lgaSlugToName[farmer.LGA]
      ? lgaSlugToName[farmer.LGA]
      : "Nil",
    state: farmer.stateId
      ? farmer.stateId.name
      : stateSlugToName[farmer.state]
      ? stateSlugToName[farmer.state]
      : "Nil",
    lga_of_origin: lgaSlugToName[farmer.lga_of_origin]
      ? lgaSlugToName[farmer.lga_of_origin]
      : "Nil",
    state_of_origin: stateSlugToName[farmer.state_of_origin]
      ? stateSlugToName[farmer.state_of_origin]
      : "Nil",
    createdBy: farmer.createdBy ? farmer.createdBy : "Nil",
    createdAt: moment(farmer.createdAt).format("DD-MM-YYYY"),
  };
};

const formatFarmerData = (data) => {
  return data.map((el) => {
    return {
      ...el,
      name: `${el.first_name || "Nil"} ${el.last_name || "Nil"}`,
      lga: el.lgaId
        ? el.lgaId.name
        : lgaSlugToName[el.LGA]
        ? lgaSlugToName[el.LGA]
        : "Nil",
      state: el.stateId
        ? el.stateId.name
        : stateSlugToName[el.state]
        ? stateSlugToName[el.state]
        : "Nil",
      createdAt: moment(el.createdAt).format("DD-MM-YYYY"),
      phone: el.phone ? el.phone : "Nil",
      avgTransSize: 0,
      avgP1Vol: 0,
    };
  });
};

const state = () => ({
  farmersList: [],
  farmerToBeUpdated: {},
  error: null,
  farmer: null,
  farmersCommitment: null,
  oneFarmerCommitment: null,
  updateFarmerCommitment: null,
  isFilter: false,
  isDateFilter: false,
  inputtedFarmer: {
    latLong: {
      lat: "",
      long: "",
    },
    last_name: "",
    first_name: "",
    other_name: "",
    title: "",
    phone: "",
    email_address: "",
    countryCode: "+234",
    state_of_origin: "",
    lga_of_origin: "",
    community_of_residence: "",
    sex: "",
    have_smart_phone: "",
    supplierType: "",
    channel: "",
    actioned_by: "",
    nationality: "",
    marital_status: "",
    bank: "",
    occupation: "",
    dob: "",
    levelOfEducation: "",
    noOfChildren: 0,
    bvn: "",
    field_agent: "",
    address: "",
    nin: "",
    accountName: "",
    communityId: "",
    communityName: "",
    lgaId: "",
    lgaName: "",
    stateId: "",
    stateName: "",
  },
  filterMetaData: {
    filteredFarmers: [],
    count: 0,
    query: "",
  },
});

const getters = {
  getFarmersList: (state) => {
    return state.farmersList;
  },
  getFarmer: (state) => {
    return state.farmer;
  },
  getFarmersCommitment: state => state.farmersCommitment,
  getOneFarmerCommitment: state => state.oneFarmerCommitment,
  getUpdatedFarmerCommitment: state => state.updateFarmerCommitment,

  getFilterStatus: (state) => {
    return state.isFilter;
  },

  getIsDateFilterStatus: (state) => {
    return state.isDateFilter;
  },

  getFarmerToBeUpdated: (state) => {
    return state.farmerToBeUpdated;
  },

  getInputtedFarmer: (state) => {
    return state.inputtedFarmer;
  },

  getFilterFarmerMetaData: (state) => {
    return state.filterMetaData;
  },

  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  saveFarmersCommitment(state, payload) {
    state.farmersCommitment = payload;
  },
  saveOneFarmerCommitment(state, payload) {
    state.oneFarmerCommitment = payload
  },
  saveUpdatedFarmerCommitment(state, payload) {
    state.updateFarmerCommitment = payload;
  },
  saveFarmers(state, farmers) {
    state.farmersList = farmers;
  },

  saveOneFarmer(state, farmer) {
    state.farmer = farmer;
  },

  setIsFilter(state, status) {
    state.isFilter = status;
  },

  setIsDateFilter(state, status) {
    state.isDateFilter = status;
  },

  setInputtedFarmer(state, farmer) {
    state.inputtedFarmer = farmer;
  },

  setValuesForFarmerUpdate(state, farmer) {
    state.farmerToBeUpdated = {
      ...state.farmerToBeUpdated,
      ...farmer,
    };
  },

  setFarmerToBeUodated(state, farmer) {
    state.farmerToBeUpdated = farmer;
  },

  resetFarmerForm(state, resetValue) {
    state.inputtedFarmer = resetValue;
  },

  setFilterFarmerMetaData(state, metaData) {
    state.filterMetaData = {
      ...state.filterMetaData,
      ...metaData,
    };
  },

  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  setIsFilter({ commit }, status) {
    commit("setIsFilter", status);
  },

  setInputtedFarmer({ commit }, farmer) {
    commit("setInputtedFarmer", farmer);
  },

  setValuesForFarmerUpdate({ commit }, farmer) {
    commit("setValuesForFarmerUpdate", farmer);
  },

  findFarmerToBeUpdated({ commit, state }, farmerId) {
    let farmerToUpdated = state.farmersList.find(
      (farmer) => farmer._id === farmerId
    );

    farmerToUpdated = {
      ...farmerToUpdated,
      stateName: farmerToUpdated?.state || farmerToUpdated?.stateId?.name,
      lgaName: farmerToUpdated?.lga || farmerToUpdated?.lgaId?.name,
      communityName: farmerToUpdated?.communityId?.name || "Nil",
      lga_of_origin: lgaSlugToName[farmerToUpdated["LGA"]],
      state_of_origin: stateSlugToName[farmerToUpdated["state_of_origin"]],
    };

    commit("setFarmerToBeUodated", farmerToUpdated);
  },

  resetInputtedFarmer({ commit }) {
    const resetFarmerForm = {
      latLong: {
        lat: "",
        long: "",
      },
      last_name: "",
      first_name: "",
      other_name: "",
      title: "",
      phone: "",
      email_address: "",
      countryCode: "+234",
      state_of_origin: "",
      lga_of_origin: "",
      lgaId: "",
      lgaName: "",
      state: "",
      community_of_residence: "",
      sex: "",
      have_smartphone: "",
      supplierType: "",
      channel: "",
      actioned_by: "",
      nationality: "",
      marital_status: "",
      bank: "",
      occupation: "",
      dob: "",
      levelOfEducation: "",
      noOfChildren: 0,
      nin: "",
      bvn: "",
      field_agent: "",
      accountName: "",
      communityId: "",
      communityName: "",
      stateId: "",
      stateName: "",
    };
    commit("resetFarmerForm", resetFarmerForm);
  },

  async addFarmer({ commit, dispatch }, payload) {
    try {
      const { data } = await APIConfig.post("/farmers", payload);
      dispatch("resetInputtedFarmer");
      return data;
    } catch (error) {
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async deleteFarmer({ commit }, farmerId) {
    try {
      const { data } = await APIConfig.delete(`/farmers/${farmerId}`);
      return data;
    } catch (error) {
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async deleteManyFarmers(_, ids) {
    try {
      const { data } = await APIConfig.delete(`/farmers?farmersIds=${ids}`);

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async updateFarmer({ commit }, payload) {
    try {
      const { data } = await APIConfig.patch(`/farmers/${payload.id}`, payload);
      return data;
    } catch (error) {
      commit("setError", error.response.data);
      return error.response.data;
    }
  },

  async fetchAllFarmers({ commit }, params) {
    const { page, limit, searchTerm } = params;
    try {
      const { data } = await APIConfig.get(
        `/farmers/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
      );

      const farmers = formatFarmerData(data?.data);

      commit("saveFarmers", farmers);

      return {
        count: data?.count,
        farmers,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async fetchAllFarmersWithoutParams({ commit }) {
    try {
      const { data } = await APIConfig.get(`/farmers`);

      const farmers = formatFarmerData(data?.data);

      commit("saveFarmers", farmers);

      return {
        count: data?.count,
        farmers,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async searchFarmers({ commit }, searchTerm) {
    try {
      const response = await APIConfig.get(
        `/farmers/?searchTerm=${searchTerm}`
      );

      const list = response.data?.data?.slice(0, 20);

      if (!response.data.data) {
        await commit("saveFarmers", []);
      }

      if (list?.length) await commit("saveFarmers", list);

      return list;
    } catch (error) {
      console.log(error);
    }
  },

  async approveManyFarmers(_, farmerIds) {
    try {
      const { data } = await APIConfig.patch(`/farmers/approvals`, {
        farmerIds,
      });

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async filterFarmers({ commit }, params) {
    const { page, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `/farmers/?page=${page}&${filterQuery}`
      );

      let response = {
        filteredFarmers: [],
        count: 0,
        query: filterQuery,
      };

      if (data?.count > 0) {
        response.filteredFarmers = formatFarmerData(data?.data);
        response.count = data?.count;
        commit("setIsFilter", true);
        commit("setFilterFarmerMetaData", response);
      } else {
        commit("setFilterFarmerMetaData", response);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async filterFarmersByDatePeriod({ commit }, params) {
    const { page, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `/farmers/date-range?page=${page}&${filterQuery}`
      );

      commit("setIsDateFilter", true);

      if (Object.keys(data).length > 0) {
        const farmers = formatFarmerData(data?.data);

        return {
          count: data?.count,
          farmers,
        };
      } else {
        return {
          count: 0,
          farmers: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async fetchOneFarmer({ commit }, farmerId) {
    try {
      let { data } = await APIConfig.get(`/farmers/${farmerId}`);

      commit("saveOneFarmer", data);

      data = formatSingleFarmer(data);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchFarmersCommitment({ commit },farmerId) {
    try {

      const {data} = await APIConfig.get(`/farmer-commitment/farmer/${farmerId}`);
      commit('saveFarmersCommitment', data);
      return data;
    } catch (error) {
      console.log(error);
      console.log('error in fetching the Aps');
    }
  },

  async fetchOneFarmerCommitment({commit}, commitmentId) {
    try {
      
      const {data} = await APIConfig.get(`/farmer-commitment/${commitmentId}`);  
      commit('saveOneFarmerCommitment', data);
      return data

    } catch (error) {
      console.log(error)
    }
  },

  async updateFarmersCommitment(_,updateEdit) {
    try {
      const {data} = await APIConfig.patch(`/farmer-commitment/${updateEdit._id}`, updateEdit);
      return data
    } catch (error) {
      console.log(error)
    }
  },
  async deleteFarmerCommitment(_,deleteCommitment) {
    try {
      const {data} = await APIConfig.delete(`/farmer-commitment/${deleteCommitment}`);
      return data

      
    } catch (error) {
      console.log(error)
    }
  },
  async addFarmerCommitment(_,addCommitment) {
    try {
      const {data} = await APIConfig.post(`/farmer-commitment`, addCommitment);
      return data
      
    } catch (error) {
      console.log(error)
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
