<template>
  <div class="dashboard-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <div class="dashboard-container">
      <section class="dashboard-metric">
        <div style="margin: 0 auto; width: 100%; padding: 30px">
          <router-link to="/dashboard">
            <div class="dashboard-back">
              <InvertedBlackButton
                label="Go Back"
                width="5rem"
                height="2.6rem"
                padding="0px 0px"
              ></InvertedBlackButton>
            </div>
          </router-link>

          <div class="metric-header">
            <p class="metric-title-text">
              <b>Total Purchases</b> | {{ title }}
            </p>
          </div>
          <div class="border"></div>
          <div class="metric-data-range">
            <div class="metric-data-time-range">
              <div class="metric-toolbar">
                <span class="metric-data-caption">Range:</span>
                <div class="input-select">
                  <select
                    v-model="dateRange"
                    @change="changeP1DateAction($event)"
                  >
                    <option value="this_week">This Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="this_month">This Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="this_year">This Year</option>
                    <option value="last_year">Last year</option>
                  </select>
                </div>
                <div class="input">
                  <input
                    type="text"
                    name=""
                    id="date"
                    placeholder="Select Start Date"
                    onfocus="(this.type = 'date')"
                    v-model="startDate"
                    @change="fetchbyDateRange"
                  />
                </div>

                <div class="input">
                  <input
                    type="text"
                    name=""
                    id="date"
                    placeholder="Select End Date"
                    onfocus="(this.type = 'date')"
                    v-model="endDate"
                    @change="fetchbyDateRange"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="metric-kpi-block">
            <div class="metric-kpi-core">
              <ul class="kpi-block">
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="isFinite(currentPurchase) ? currentPurchase : 0"
                    :kpiValue2="isFinite(targetPurchase) ? targetPurchase : 0"
                    kpiLabel="Current/Target in tonnes"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="pricePerTon"
                    kpiColor1="#8fc54d"
                    kpiLabel="Price/Ton"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="isFinite(tonnLeft) ? tonnLeft : 0"
                    kpiColor1="#8fc54d"
                    kpiLabel="Tonnage Left"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="isFinite(rateToHit) ? rateToHit : 0"
                    :kpiValue2="suffix"
                    kpiLabel="Rate to hit target"
                  >
                  </StyledMetricText>
                </li>
              </ul>
            </div>
            <div class="metric-kpi-collapsible" v-show="collapsibleVisible">
              <ul class="kpi-block">
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="
                      isFinite(completion) ? completion + `%` : 0 + `%`
                    "
                    kpiColor1="#8fc54d"
                    kpiLabel="% Completion"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="isFinite(rate) ? rate : 0"
                    kpiLabel="Current Rate"
                  >
                  </StyledMetricText>
                </li>
                <li class="metric-kpi">
                  <StyledMetricText
                    :kpiValue1="isFinite(daysLeft) ? daysLeft : 0"
                    :kpiLabel="suffix + `(s) left`"
                  >
                  </StyledMetricText>
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="collapsibleVisible"
            class="metric-collapse"
            @click="toggleCollasible"
          >
            See less &gt;
          </div>
          <div v-else class="metric-collapse" @click="toggleCollasible">
            See more &lt;
          </div>
          <div class="chart-wrapper">
            <div class="chart-toolbar">
              <div class="chart-view-selector">
                <span class="chart-selector-text">Type</span>
                <div class="input-select">
                  <select v-model="type" @change="checkType($event)">
                    <option value="">Team</option>
                    <option value="team">Team</option>
                    <option value="individual">Individual</option>
                  </select>
                </div>
              </div>
              <div class="chart-search" v-if="individualView">
                <v-select
                  :options="agents"
                  placeholder="select a user"
                  v-model="selectedUser"
                  label="name"
                >
                  <template #option="option">
                    <span>{{
                      option.first_name + " " + option.last_name
                    }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="chart-data">
              <LineCharts :series="purchSeriesData" />
            </div>
          </div>
          <router-view />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import StyledMetricText from "../../../components/metric-text/MetricText.vue";
import LineCharts from "../charts/LineCharts.vue";
import { convertToFilterQueryString } from "../../../utils";
import { formatNumber } from "../../../utils/numbers-formatter";

export default {
  name: "PurchaseDash",

  data() {
    return {
      collapsibleVisible: false,
      individualView: false,
      loading: false,
      type: "",
      dateRange: "this_week",
      customDateRange: "",
      currentPurchase: "",
      targetPurchase: "",
      tonnLeft: "",
      purchTrend: "",
      daysLeft: "",
      completion: "",
      rate: "",
      rateToHit: "",
      pricePerTon: 0,
      purchSeriesData: [],
      targetPurchaseChart: "",
      agents: [],
      suffix: "",
      startDate: "",
      endDate: "",
      selectedAgentId: "",
      title: "",
      selectedUser: "",
    };
  },

  components: {
    InvertedBlackButton,
    StyledMetricText,
    LineCharts,
    Loading,
  },

  mounted() {
    this.fetchPurchaseData();
    this.fetchP1Data();
    this.fetchUsers();
  },

  methods: {
    async fetchUsers(page = 1, searchTerm = "") {
      try {
        const { assets } = await this.$store.dispatch(
          "userManagement/fetchUsers",
          {
            page,
            searchTerm,
          }
        );

        this.agents = assets;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    handleSelect(obj) {
      this.selectedAgentId = obj.agent;
    },

    toggleCollasible() {
      this.collapsibleVisible = !this.collapsibleVisible;
    },

    checkType(event) {
      if (event.target.value === "individual") this.individualView = true;
      else this.individualView = false;
    },

    getTimePassed(template) {
      const now = new Date();

      switch (template) {
        case "this_week":
          return now.getDay();
        case "this_month":
          return Math.floor(now.getDate() / 7);
        case "this_year":
          return now.getMonth();
        case "last_week":
          return 6;
        case "last_month":
          return 4;
        case "last_year":
          return 12;
        default:
          throw new Error("Invalid template provided.");
      }
    },

    getTimeLeft(template) {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let end;
      let unit;

      switch (template) {
        case "this_week":
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + (6 - now.getDay())
          );
          unit = "day";
          break;
        case "last_week":
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - now.getDay() - 1
          );
          unit = "day";
          break;
        case "this_month":
          end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          unit = "week";
          break;
        case "last_month":
          end = new Date(now.getFullYear(), now.getMonth(), 0);
          unit = "week";
          break;
        case "last_year":
          end = new Date(now.getFullYear(), 0, 0);
          unit = "month";
          break;
        case "this_year":
          end = new Date(now.getFullYear() + 1, 0, 0);
          unit = "month";
          break;
        default:
          throw new Error("Invalid template provided.");
      }

      const diff = Math.ceil(
        (end.getTime() - today.getTime()) / (1000 * 3600 * 24)
      );

      if (unit === "day") {
        return Math.max(0, diff);
      } else if (unit === "week") {
        return Math.max(0, Math.floor(diff / 7));
      } else if (unit === "month") {
        const diffMonths =
          (end.getFullYear() - today.getFullYear()) * 12 +
          (end.getMonth() - today.getMonth());
        return Math.max(0, diffMonths);
      }
    },

    getDaysLeft(template) {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let end;

      switch (template) {
        case "this_week":
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + (6 - now.getDay())
          );
          break;
        case "last_week":
          end = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - now.getDay() - 1
          );
          break;
        case "this_month":
          end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          break;
        case "last_month":
          end = new Date(now.getFullYear(), now.getMonth(), 0);
          break;
        case "last_year":
          end = new Date(now.getFullYear(), 0, 0);
          break;
        case "this_year":
          end = new Date(now.getFullYear() + 1, 0, 0);
          break;
        default:
          throw new Error("Invalid template provided.");
      }

      const diff = Math.ceil(
        (end.getTime() - today.getTime()) / (1000 * 3600 * 24)
      );
      return Math.max(0, diff);
    },

    getTarget(template, divisor) {
      let targetPurchase, divider, suffix;
      if (template) {
        switch (template) {
          case "last_week":
            targetPurchase = 200;
            divider = 6;
            suffix = "day";
            break;
          case "this_week":
            targetPurchase = 200;
            divider = 6;
            suffix = "day";
            break;
          case "last_month":
            targetPurchase = 800;
            divider = 4;
            suffix = "week";
            break;
          case "this_month":
            targetPurchase = 800;
            divider = 4;
            suffix = "week";
            break;
          case "this_year":
            targetPurchase = 9600;
            divider = 12;
            suffix = "month";
            break;
          case "last_year":
            targetPurchase = 9600;
            divider = 12;
            suffix = "month";
            break;
          default:
            targetPurchase = 800 * 12;
        }

        if (divisor === "yes") {
          targetPurchase = targetPurchase / divider;
        }
        return {
          targetPurchase,
          divider,
          suffix,
        };
      }
    },

    changeP1DateAction(event) {
      this.dateRange = event.target.value;
      this.customDateRange = "";
      this.fetchPurchaseData(this.dateRange);
      this.fetchP1Data(this.dateRange);
    },

    addDataObject(dataArray, name, targetValue) {
      const newDataObject = {
        name: name,
        data: Array(dataArray[0].data.length).fill(targetValue),
      };

      dataArray.push(newDataObject);
    },

    calculateRate(tonleft, timeleft) {
      if (timeleft === 0) {
        return tonleft;
      } else if (timeleft > 0) {
        return (tonleft / timeleft).toFixed(0);
      } else return 0;
    },

    calculateDaysBetweenDates(date1, date2) {
      // const format = "YYYY-MM-DD";
      try {
        const datetime1 = new Date(date1);
        const datetime2 = new Date(date2);
        const difference = Math.abs(datetime2 - datetime1);
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const daysBetween = Math.floor(difference / millisecondsPerDay);
        return daysBetween;
      } catch (error) {
        return "Invalid date format. Please provide dates in the format 'YYYY-MM-DD'.";
      }
    },

    async fetchPurchaseData(query = "this_week") {
      this.loading = true;
      const data = await this.$store.dispatch(
        "overviews/getPurchaseData",
        query
      );
      const targetObj = this.getTarget(query);
      this.pricePerTon = `₦` + formatNumber(data?.pricePerTon);
      this.targetPurchase = targetObj.targetPurchase;
      this.currentPurchase = isNaN(data.p1qty) ? 0 : data.p1qty;
      this.completion = (
        (this.currentPurchase / this.targetPurchase) *
        100
      ).toFixed(0);
      this.title = data.period + " Data Report";

      this.tonnLeft = (this.targetPurchase - this.currentPurchase).toFixed(0);
      this.daysLeft = this.getTimeLeft(query).toString();
      this.rateToHit = this.calculateRate(
        this.tonnLeft,
        this.getTimeLeft(query)
      );
      this.suffix = targetObj.suffix;
      this.rate = (this.currentPurchase / this.getTimePassed(query)).toFixed(1);
      this.loading = false;
    },

    async fetchP1Data(query = "this_week") {
      this.loading = true;
      const data = await this.$store.dispatch(
        "overviews/getOverviewPurchData",
        query
      );
      this.targetPurchaseChart = this.getTarget(
        query,
        "yes"
      ).targetPurchase.toFixed(0);

      this.addDataObject(data, "Target", this.targetPurchaseChart);
      this.purchSeriesData = data;
      this.loading = false;
    },

    async fetchbyDateRange() {
      if (this.startDate === "" || this.endDate === "") return;
      else if (this.startDate !== "" && this.endDate !== "") {
        this.dateRange = "";
        const query = convertToFilterQueryString({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        this.customDateRange = query;
        try {
          this.loading = true;
          const data = await this.$store.dispatch(
            "overviews/getOverviewPurchaseDateRange",
            query
          );
          const graphData = await this.$store.dispatch(
            "overviews/getOverviewPurchDateRange",
            query
          );

          console.log("graph", graphData);

          this.loading = false;
          this.title = data.period;
          this.pricePerTon = `₦` + formatNumber(data.pricePerTon);

          this.daysLeft = this.calculateDaysBetweenDates(
            this.startDate,
            this.endDate
          );
          this.targetPurchase =
            this.daysLeft > 7
              ? (200 * Math.abs(this.daysLeft / 7)).toFixed(0)
              : this.daysLeft === 7
              ? 200
              : (33 * this.daysLeft).toFixed(0);
          this.currentPurchase = data.p1qty;
          this.completion = (
            (this.currentPurchase / this.targetPurchase) *
            100
          ).toFixed(0);
          this.tonnLeft = (this.targetPurchase - this.currentPurchase)
            .toFixed(0)
            .toString();
          this.rate = (this.currentPurchase / this.daysLeft).toFixed(0);
          this.rateToHit = (this.targetPurchase / this.daysLeft).toFixed(0);
          this.targetPurchaseChart = this.rateToHit;
          this.addDataObject(graphData, "Target", this.targetPurchaseChart);
          this.purchSeriesData = graphData;
          this.suffix = "day";
        } catch (error) {
          console.log(error);
        }
      }
    },

    async getAgentPurchasedTonnes(agentId, dateQuery) {
      this.loading = true;
      this.purchSeriesData = await this.$store.dispatch(
        "overviews/getAgentPurchasedTonnes",
        {
          agentId,
          dateQuery,
        }
      );

      this.loading = false;
    },
  },

  watch: {
    individualView(newValue) {
      if (!newValue) {
        this.fetchP1Data();
      }
    },

    selectedUser(newValue) {
      if (newValue !== "") {
        const query = this.dateRange || this.customDateRange;
        console.log(query);
        this.getAgentPurchasedTonnes(newValue._id, query);
      }
    },
  },
};
</script>

<style scoped>
.dashboard-parent {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  margin: 0 auto;
  background: #ffffff;
}

.dashboard-back {
  margin-bottom: 30px;
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-caption {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
}

.metric-title-text {
  font-size: 16px;
  font-weight: 300;
  color: #131313;
}

.metric-title-text b {
  font-weight: 800;
}

.image-metric {
  cursor: pointer;
}
.metric-data-range,
.metric-kpi-core,
.metric-kpi-collapsible,
.chart-wrapper {
  height: auto;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  padding: 2px;
  margin-bottom: 30px;
}

.metric-data-range {
  padding: 10px;
}

.metric-kpi-core {
  width: 70%;
  margin-right: 20px;
}

.metric-kpi-collapsible {
  width: 30%;
}

.metric-kpi-collapsible .metric-kpi {
  width: 33%;
}

/* .metric-kpi-collapsible {
  width: 40%;
} */

.metric-data-time-range {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
}

.metric-toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.metric-data-caption {
  color: #333;
  font-weight: 400;
  /* font-size: 14px; */
}

.metric-kpi-block {
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.kpi-block {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  width: 100%;
}

.metric-kpi {
  list-style: none;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  width: 25%;
  /* text-align: center; */
}

.metric-kpi:not(:last-child) {
  border-right: 1px solid #a7a7a726;
}

.metric-collapse {
  display: inline;
  float: right;
  font-size: 12px;
  font-weight: 400;
}

.input,
.input-select {
  height: 30px;
  width: 115px;
}

select {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

input {
  width: 100%;
  height: 100%;
}

.image-footer {
  width: 0px;
  height: 40px;
}

.month-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.month-caption-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.dashboard-chart {
  margin-top: 20px;
}

.timerange {
  display: flex;
  gap: 10px;
}

.location-range {
  display: flex;
  gap: 10px;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 40px 0;
  font-size: 0.8rem;
}

.chart-toolbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.chart-view-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chart-search {
  width: 350px;
  border-radius: 3px;
  padding: 0 9px;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}
.chart-data {
  width: 100%;
  height: 400px;
  border-radius: 4px;
}

.border {
  border: 1px solid #e7e7e7;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

/* reset default styles */
a:hover {
  text-decoration: none;
}
</style>
