<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Update PKO Settings</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-container">
      <form class="form-container-div">
        <TextInput
          placeholder="PKO_R/T P1"
          fieldName="pko_selling_price_per_ton"
          :inputValue="pkoForm?.pko_selling_price_per_ton"
          @onInputChange="handleInputChange"
          :disabledValue="true"
        />

        <TextInput
          placeholder="PKO_SP"
          fieldName="pko_selling_price"
          :inputValue="pkoForm?.pko_selling_price"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="P2_DRY to PKO"
          fieldName="p2_dry_to_pko_qty"
          :inputValue="pkoForm.p2_dry_to_pko_qty"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="P1 to P2 DRY"
          fieldName="p1_to_p2_dry_qty"
          :inputValue="pkoForm.p1_to_p2_dry_qty"
          @onInputChange="handleInputChange"
          :disabledValue="true"
        />

        <TextInput
          placeholder="P1 to P2 WET"
          fieldName="p1_to_p2_wet_qty"
          :inputValue="pkoForm.p1_to_p2_wet_qty"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="P2 WET to P2 DRY"
          fieldName="p2_wet_to_p2_dry_qty"
          :inputValue="pkoForm.p2_wet_to_p2_dry_qty"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="No of PKO/T P1 Cracked"
          fieldName="no_of_pko_per_ton_cracked"
          :inputValue="pkoForm.no_of_pko_per_ton_cracked"
          @onInputChange="handleInputChange"
          :disabledValue="true"
        />

        <TextInput
          placeholder="Tonnes of P1 in 1T of PKO"
          fieldName="tons_of_p1_in_1t_of_pko"
          :inputValue="pkoForm.tons_of_p1_in_1t_of_pko"
          @onInputChange="handleInputChange"
          :disabledValue="true"
        />
      </form>
    </section>

    <div class="border"></div>

    <div class="button-container">
      <GreenButton
        label="Update"
        width="10.5rem"
        height="2.9rem"
        @click="updatePkoForm"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";

export default {
  name: "EditPKOModal",
  components: {
    TextInput,
    GreenButton,
    Loading,
  },

  data() {
    return {
      pkoForm: {
        pko_selling_price_per_ton: this.pko_seling_price_per_ton || "",
        pko_selling_price: this.pko_seling_price || "",
        p2_dry_to_pko_qty: this.p2_dry_to_pko_qy || "",
        p1_to_p2_dry_qty: this.p1_to_p2_dry_qy || "",
        p1_to_p2_wet_qty: this.p1_to_p2_wet_qy || "",
        p2_wet_to_p2_dry_qty: this.p2_wet_to_p2_dry_qy || "",
        no_of_pko_per_ton_cracked: this.no_of_pko_pr_ton_cracked || "",
        tons_of_p1_in_1t_of_pko: this.tons_of_p1_in_ton_of_pko || "",
      },
      loading: false,
    };
  },

  props: {
    pko_seling_price_per_ton: {
      type: String,
      required: true,
    },
    pko_seling_price: {
      type: String,
      required: true,
    },
    p2_dry_to_pko_qy: {
      type: String,
      required: true,
    },
    p1_to_p2_dry_qy: {
      type: String,
      required: true,
    },

    p1_to_p2_wet_qy: {
      type: String,
      required: true,
    },

    p2_wet_to_p2_dry_qy: {
      type: String,
      required: true,
    },

    no_of_pko_pr_ton_cracked: {
      type: String,
      required: true,
    },
    tons_of_p1_in_ton_of_pko: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.pkoForm = {
        ...this.pkoForm,
        ...obj,
      };
    },

    async updatePkoForm() {
      this.loading = true;
      try {
        const {
          pko_selling_price_per_ton,
          pko_selling_price,
          p2_dry_to_pko_qty,
          p1_to_p2_dry_qty,
          p1_to_p2_wet_qty,
          p2_wet_to_p2_dry_qty,
          no_of_pko_per_ton_cracked,
          tons_of_p1_in_1t_of_pko,
        } = this.pkoForm;

        const payload = {
          pko_selling_price_per_ton: Number(pko_selling_price_per_ton),
          pko_selling_price: Number(pko_selling_price),
          p2_dry_to_pko_qty: Number(p2_dry_to_pko_qty),
          p1_to_p2_dry_qty: Number(p1_to_p2_dry_qty),
          p1_to_p2_wet_qty: Number(p1_to_p2_wet_qty),
          p2_wet_to_p2_dry_qty: Number(p2_wet_to_p2_dry_qty),
          no_of_pko_per_ton_cracked: Number(no_of_pko_per_ton_cracked),
          tons_of_p1_in_1t_of_pko: Number(tons_of_p1_in_1t_of_pko),
        };

        const response = await this.$store.dispatch(
          "profitInput/updatePriceAtomSettings",
          payload
        );

        await this.$store.dispatch(
          "profitInput/addP1toP2Dry",
          payload.p1_to_p2_dry_qty
        );

        if (response.acknowledged) {
          this.loading = false;
          this.$emit('updatePKOEvent', this.pkoForm)

          createToast("PKO Form Updated Successfully", {
            type: "success",
            position: "top-right",
            timeout: 3000,

            onClose: () => {
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;

      }
    },
  },

  watch: {
    "pkoForm.tons_of_p1_in_1t_of_pko": function (val) {
      this.pkoForm.pko_selling_price_per_ton =
        this.pkoForm.pko_selling_price * (1 / val);

      this.handleInputChange({
        pko_selling_price_per_ton: this.pkoForm.pko_selling_price_per_ton,
      });
    },

    "pkoForm.p2_wet_to_p2_dry_qty": function (val) {
      this.pkoForm.p1_to_p2_dry_qty = val * this.pkoForm.p1_to_p2_wet_qty;

      this.handleInputChange({
        p1_to_p2_dry_qty: this.pkoForm.p1_to_p2_dry_qty,
      });
    },

    "pkoForm.p1_to_p2_wet_qty": function (val) {
      this.pkoForm.p1_to_p2_dry_qty = val * this.pkoForm.p2_wet_to_p2_dry_qty;

      this.handleInputChange({
        p1_to_p2_dry_qty: this.pkoForm.p1_to_p2_dry_qty,
      });
    },

    "pkoForm.p2_dry_to_pko_qty": function (val) {
      this.pkoForm.no_of_pko_per_ton_cracked =
        val * this.pkoForm.p1_to_p2_dry_qty;

      this.pkoForm.tons_of_p1_in_1t_of_pko =
        1 / this.pkoForm.no_of_pko_per_ton_cracked;

      this.handleInputChange({
        no_of_pko_per_ton_cracked: this.pkoForm.no_of_pko_per_ton_cracked,
        tons_of_p1_in_1t_of_pko: this.pkoForm.tons_of_p1_in_1t_of_pko,
      });
    },

    "pkoForm.p1_to_p2_dry_qty": function (val) {
      this.pkoForm.no_of_pko_per_ton_cracked =
        val * this.pkoForm.p2_dry_to_pko_qty;

      this.pkoForm.tons_of_p1_in_1t_of_pko =
        1 / this.pkoForm.no_of_pko_per_ton_cracked;

      this.handleInputChange({
        no_of_pko_per_ton_cracked: this.pkoForm.no_of_pko_per_ton_cracked,
        tons_of_p1_in_1t_of_pko: this.pkoForm.tons_of_p1_in_1t_of_pko,
      });
    },
    "pkoForm.pko_selling_price": function (val) {
      this.pkoForm.pko_selling_price_per_ton = Math.round(
        val * (1 / this.pkoForm.tons_of_p1_in_1t_of_pko)
      );

      this.handleInputChange({
        pko_selling_price_per_ton: this.pkoForm.pko_selling_price_per_ton,
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  height: 100%;
  background: #fff;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.form-container {
  height: 230px;
  overflow-y: scroll;
}

.form-container-div {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
</style>
