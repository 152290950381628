<template>
  <div class="parent">
    <div class="parent-wrapper">
      <!-- Search Bar Section -->
      <section class="search-bar-container">
        <div class="search-form">
          <input
            type="text"
            class="search-input"
            :placeholder="
              activeLogisticsType === 'Dispatch'
                ? 'Search by factory or purchase id'
                : 'Search by driver, AP, factory and vehicle'
            "
            v-model="searchTerm"
            @keyup.enter="handleSearchText"
          />
          <button><img src="../../assets/search-icon.png" alt="" /></button>
        </div>
        <!-- <div class="">Add Farmer Button</div> -->

        <div class="create-buttons__container">
          <InvertedGreenButton
            label="
              Create Target
            "
            width="
             11rem
            "
            height="2.9rem"
            iconName="target-icon"
          />
          <GreenButton
            :label="
              activeLogisticsType === 'Aggregation'
                ? 'Start Aggregation'
                : 'Start Dispatch'
            "
            :width="
              activeLogisticsType === 'Aggregation'
                ? '13.5rem'
                : activeLogisticsType === 'Dispatch'
                ? '12.9rem'
                : '13.5rem'
            "
            height="2.9rem"
            iconName="add-icon"
            @click="openStartLogisticsModal"
          />
        </div>
      </section>

      <div class="logistics-type__container">
        <router-link to="/dashboard/logistics/dispatch">
          <div
            class="logistics-type"
            :class="{ active: activeLogisticsType === 'Dispatch' }"
            @click="setActiveLogisticsType(dispatch)"
          >
            {{ dispatch }}
          </div>
        </router-link>
        <router-link to="/dashboard/logistics/aggregation">
          <div
            class="logistics-type"
            :class="{ active: activeLogisticsType === 'Aggregation' }"
            @click="setActiveLogisticsType(aggregation)"
          >
            {{ aggregation }}
          </div>
        </router-link>
      </div>

      <routerView></routerView>

      <!-- Create Logistics Modal -->
      <div
        class="start-logistics__modal"
        v-if="isCreateLogisticsOpened && activeLogisticsType === aggregation"
      >
        <StartAggregationModal1
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 1"
        />
        <StartAggregationModal2
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 2"
        />
      </div>
      <div
        class="start-logistics__modal"
        v-if="isCreateLogisticsOpened && activeLogisticsType === dispatch"
      >
        <SelectDispatchTypeModal
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 1"
        />
        <MillToFactoryDispatch
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 2"
        />
        <APToFactoryDispatch
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 3"
        />
      </div>

      <div
        class="start-logistics__modal"
        v-if="isEditLogisticsModalOpened && activeLogisticsType === aggregation"
      >
        <EditAggregationModal1
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 1"
        />
        <EditAggregationModal2
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 2"
        />
      </div>

      <div
        class="start-logistics__modal"
        v-if="
          isVerifyLogisticsModalOpened && activeLogisticsType === aggregation
        "
      >
        <VerifyAggregation @onCloseModal="handleCloseModal" />
      </div>

      <div
        class="start-logistics__modal"
        v-if="isEditLogisticsModalOpened && activeLogisticsType === dispatch"
      >
        <EditDispatchModal1
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 1"
          @openEditModal="openEditModal"
        />
        <EditDispatchModal2
          @onCloseModal="handleCloseModal"
          @onSetAddModalPage="handleAddModalPage"
          v-if="modalPageNum === 2"
        />
      </div>

      <div
        class="start-logistics__modal"
        v-if="isVerifyLogisticsModalOpened && activeLogisticsType === dispatch"
      >
        <VerifyDispatch @onCloseModal="handleCloseModal" />
      </div>

      <!-- Details Modal -->
      <div class="details-modal" v-if="isDetailsModalOpened">
        <ScheduledDispatchDetails
          v-if="activeLogisticsType === scheduled"
          :dispatchId="itemClicked"
          @onCloseModal="handleCloseModal"
        />
        <AggregationDetailsModal
          v-if="activeLogisticsType === aggregation"
          :aggregationId="itemClicked"
          @onCloseModal="handleCloseModal"
        />
        <DispatchDetailsModal
          v-if="activeLogisticsType === dispatch"
          :dispatchId="itemClicked"
          @onCloseModal="handleCloseModal"
        />
      </div>
      <div class="filter-modal" v-if="isFilterModalOpened">
        <LogisticsFilterForm :activeLogisticsType="activeLogisticsType" />
      </div>

      <!-- Delete Modals -->
      <div class="delete-modal" v-if="isDeleteModalOpened">
        <DeleteModal
          :heading="activeLogisticsType"
          :itemId="itemId"
          @onCloseModal="handleCloseModal"
        />
      </div>

      <div class="delete-modal" v-if="multipleIds.length > 0">
        <DeleteManyModal
          :heading="activeLogisticsType"
          :ids="multipleIds"
          @onCloseModal="handleCloseModal"
        />
      </div>

      <!-- Approval -->
      <div class="delete-modal" v-if="isApprovalStatus">
        <ApproveManyModal
          :heading="activeLogisticsType"
          :ids="multipleIds"
          @onCloseModal="handleCloseModal"
        />
      </div>

      <div
        class="dark-overlay"
        v-if="
          isFilterModalOpened ||
          isDetailsModalOpened ||
          isCreateLogisticsOpened ||
          isEditLogisticsModalOpened ||
          isDeleteModalOpened ||
          isApprovalStatus ||
          isVerifyLogisticsModalOpened
        "
        @click="closeOverlay"
      ></div>
    </div>
  </div>
</template>

<script>

import LogisticsFilterForm from "./LogisticsFilterForm.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import InvertedGreenButton from "../../components/buttons/inverted-green-button/InvertedGreenButton.vue";
import StartAggregationModal1 from "../logistics/start-aggregation-modals/StartAggregationModal1.vue";
import StartAggregationModal2 from "../logistics/start-aggregation-modals/StartAggregationModal2.vue";
import APToFactoryDispatch from "../logistics/start-dispatch-modals/APToFactoryDispatch.vue";
import MillToFactoryDispatch from "../logistics/start-dispatch-modals/MillToFactoryDispatch.vue";
import SelectDispatchTypeModal from "../logistics/start-dispatch-modals/SelectDispatchTypeModal.vue";
import EditDispatchModal1 from "../logistics/edit-dispatch-modals/EditDispatchModal1.vue";
import EditDispatchModal2 from "../logistics/edit-dispatch-modals/EditDispatchModal2.vue";
import VerifyDispatch from "../logistics/edit-dispatch-modals/VerifyDispatch.vue";
import EditAggregationModal1 from "../logistics/edit-aggregation-modals/EditAggregationModal1.vue";
import EditAggregationModal2 from "../logistics/edit-aggregation-modals/EditAggregationModal2.vue";
import VerifyAggregation from "../logistics/edit-aggregation-modals/VerifyAggregation.vue";
import DispatchDetailsModal from "./DispatchDetailsModal.vue";
import AggregationDetailsModal from "./AggregationDetailsModal.vue";
import DeleteModal from "./DeleteModal.vue";
import DeleteManyModal from "../../components/modals/DeleteManyModal.vue";
import ApproveManyModal from "../../components/modals/ApproveManyModal.vue";

import {
  aggregationTableHeaders,
  dispatchTableHeaders,
} from "./table-headers/headers";

import { convertToFilterQueryString } from "../../utils";
import ScheduledDispatchDetails from "./ScheduledDispatchDetails.vue";

export default {
  name: "Logistics",

  components: {
    GreenButton,
    InvertedGreenButton,
    LogisticsFilterForm,
    DispatchDetailsModal,
    AggregationDetailsModal,
    StartAggregationModal1,
    StartAggregationModal2,
    APToFactoryDispatch,
    MillToFactoryDispatch,
    EditAggregationModal1,
    EditAggregationModal2,
    EditDispatchModal1,
    EditDispatchModal2,
    ScheduledDispatchDetails,
    DeleteModal,
    DeleteManyModal,
    ApproveManyModal,
    SelectDispatchTypeModal,
    VerifyAggregation,
    VerifyDispatch,
  },
  data() {
    return {
      modalPageNum: 1,
      aggregation: "Aggregation",
      dispatch: "Dispatch",
      scheduled: "Scheduled",
      startDate: "",
      endDate: "",
      activeLogisticsType: "Dispatch",
      searchTerm: "",
      loading: false,
      itemsSelected: [],
      paginationMetaData: {
        page: 1,
        rowsPerPage: 10,
      },
      aggregationTableHeaders: aggregationTableHeaders,
      dispatchTableHeaders: dispatchTableHeaders,
      aggregations: [],
      multipleIds: [],
      dispatches: [],
      scheduledDispatches: [],
      dataToExcel: [],
      globalSearchTerm: "",
      globalFilterQuery: "",
      itemClicked: "",
      itemId: "",
      isActionsOpened: false,
      isCreateLogisticsOpened: false,
      isEditLogisticsModalOpened: false,
      isVerifyLogisticsModalOpened: false,
      isDetailsModalOpened: false,
      isFilterModalOpened: false,
      isDeleteModalOpened: false,
      isApprovalStatus: false,
    };
  },

  mounted() {
    // this.fetchAggregations();
    // this.fetchDispatches();
  },

  methods: {
    handleSearchText(e) {
      const pageNum = 1;
      this.$store.dispatch("logistics/setLogisticsSearchTerm", e.target.value);
      if (this.activeLogisticsType === this.aggregation) {
        this.fetchAggregations(pageNum, e.target.value);
      } else if (this.activeLogisticsType === this.dispatch) {
        this.fetchDispatches(pageNum, e.target.value);
      }
    },

    closeOverlay() {
      this.isDetailsModalOpened = false;
      this.isFilterModalOpened = false;
      this.isCreateLogisticsOpened = false;
      this.isEditLogisticsModalOpened = false;
      this.$store.dispatch("aggregation/updateFilterModalStatus", false);
      this.$store.dispatch("dispatch/updateFilterModalStatus", false);
      this.$store.dispatch("aggregation/updateDeleteModalStatus", false);
      this.$store.dispatch("aggregation/setVerifyModalStatus", false);
      this.$store.dispatch("dispatch/setVerifyModalStatus", false);
    },

    handleCloseModal(status) {
      this.isDetailsModalOpened = status;
      this.isCreateLogisticsOpened = status;
      this.isEditLogisticsModalOpened = status;
      this.isDeleteModalOpened = status;
      this.isApprovalStatus = status;
      this.isVerifyLogisticsModalOpened = status;
      this.$store.dispatch("dispatch/setDetailsModalStatus", false);
      this.$store.dispatch("aggregation/setDetailsModalStatus", false);
      this.$store.dispatch("aggregation/updateDeleteModalStatus", false);
      this.$store.dispatch("aggregation/updateApprovalModalStatus", false);
      this.$store.dispatch("dispatch/updateApprovalModalStatus", false);
      this.$store.dispatch("aggregation/setVerifyModalStatus", false);
      this.$store.dispatch("dispatch/setVerifyModalStatus", false);
      this.$store.dispatch("aggregation/updateMultipleIds", []);
      this.$store.dispatch("dispatch/updateMultipleIds", []);
    },

    openEditModal() {
      this.isEditLogisticsModalOpened = !this.isEditLogisticsModalOpened;
    },

    openDetailsModal() {
      this.isDetailsModalOpened = !this.isDetailsModalOpened;
    },

    openFilterModal() {
      this.isFilterModalOpened = !this.isFilterModalOpened;
    },

    toggleActions(arg) {
      this.itemClicked = arg._id;
      this.isActionsOpened = !this.isActionsOpened;
    },

    handleEdit(id) {
      this.openEditModal();
      if (this.activeLogisticsType === this.aggregation) {
        this.$store.dispatch("aggregation/findAggregationToBeUpdated", id);
      } else {
        this.$store.dispatch("dispatch/findDispatchToBeUpdated", id);
      }
    },

    handleAddModalPage(pageNum) {
      this.modalPageNum = pageNum;
    },

    setActiveLogisticsType(type) {
      this.activeLogisticsType = type;
    },

    openStartLogisticsModal() {
      this.isCreateLogisticsOpened = !this.isCreateLogisticsOpened;
    },

    async fetchAggregations(page = 1, searchTerm = "") {
      try {
        this.loading = true;

        const { aggregations, count } = await this.$store.dispatch(
          "aggregation/fetchAllAggregations",
          {
            page,
            searchTerm,
          }
        );

        this.aggregations = aggregations;
        this.count = count;
        this.globalSearchTerm = searchTerm;
        this.loading = false;
        this.dataToExcel = aggregations;
      } catch (error) {
        this.loading = false;
      }
    },

    async fetchDispatches(page = 1, searchTerm = "") {
      try {
        this.loading = true;

        const { dispatches, count } = await this.$store.dispatch(
          "dispatch/fetchAllDispatches",
          {
            page,
            searchTerm,
          }
        );

        this.dispatches = dispatches;
        this.count = count;
        this.globalSearchTerm = searchTerm;
        this.loading = false;
        this.dataToExcel = dispatches;
      } catch (error) {
        this.loading = false;
      }
    },

    async fetchByDatePeriod(page = 1, filterQuery = "") {
      if (this.startDate === "" || this.endDate === "") return;
      let query = "";

      if (!filterQuery) {
        query = convertToFilterQueryString({
          startDate: this.startDate,
          endDate: this.endDate,
        });
      } else {
        query = filterQuery;
      }
      this.loading = true;
      try {
        if (this.activeLogisticsType === this.aggregation) {
          const { count, aggregations, filterQuery } =
            await this.$store.dispatch(
              "aggregation/filterAggregationsByDatePeriod",
              {
                page,
                filterQuery: query,
              }
            );
          this.aggregations = aggregations;
          this.count = count;
          this.globalFilterQuery = filterQuery;
        } else if (this.activeLogisticsType === this.dispatch) {
          const { count, dispatches, filterQuery } = await this.$store.dispatch(
            "dispatch/filterDispatchesByDatePeriod",
            {
              page,
              filterQuery: query,
            }
          );
          this.dispatches = dispatches;
          this.count = count;
          this.globalFilterQuery = filterQuery;
        } else {
          const { count, dispatches, filterQuery } = await this.$store.dispatch(
            "dispatch/filterScheduledDispatchesByDatePeriod",
            {
              page,
              filterQuery: query,
            }
          );
          this.scheduledDispatches = dispatches;
          this.count = count;
          this.globalFilterQuery = filterQuery;
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  computed: {
    dispatchFilterMetaData() {
      return this.$store.getters["dispatch/getFilterMetaData"];
    },

    scheduledDetailModalStatus() {
      return this.$store.getters["dispatch/getDetailsModalStatus"];
    },

    clickedDispatch() {
      return this.$store.getters["dispatch/getDispatchId"];
    },

    selectedAggregationIds() {
      return this.$store.getters["aggregation/getSelectedIds"];
    },

    selectedDispatchIds() {
      return this.$store.getters["dispatch/getSelectedIds"];
    },

    aggregationApprovalModalStatus() {
      return this.$store.getters["aggregation/getApprovalModalStatus"];
    },

    aggregationVerifyModalStatus() {
      return this.$store.getters["aggregation/getVerifyModalStatus"];
    },

    dispatchVerifyModalStatus() {
      return this.$store.getters["dispatch/getVerifyModalStatus"];
    },

    dispatchApprovalModalStatus() {
      return this.$store.getters["dispatch/getApprovalModalStatus"];
    },

    aggregationDetailModalStatus() {
      return this.$store.getters["aggregation/getDetailsModalStatus"];
    },

    aggregationEditModalStatus() {
      return this.$store.getters["aggregation/getEditModalStatus"];
    },

    dipstachEditModalStatus() {
      return this.$store.getters["dispatch/getEditModalStatus"];
    },

    aggregationFilterModalStatus() {
      return this.$store.getters["aggregation/getFilterModalStatus"];
    },

    dispatchFilterModalStatus() {
      return this.$store.getters["dispatch/getFilterModalStatus"];
    },

    aggregationActiveTab() {
      return this.$store.getters["aggregation/getActiveLogisticsTab"];
    },

    dipatchActiveTab() {
      return this.$store.getters["dispatch/getActiveLogisticsTab"];
    },

    aggregationDeleteModalStatus() {
      return this.$store.getters["aggregation/getDeleteModalStatus"];
    },

    dispatchDeleteModalStatus() {
      return this.$store.getters["dispatch/getDeleteModalStatus"];
    },

    aggregationId() {
      return this.$store.getters["aggregation/getAggregationId"];
    },

    dispatchId() {
      return this.$store.getters["dispatch/getDispatchId"];
    },

    clickedAggregation() {
      return this.$store.getters["aggregation/getAggregationId"];
    },
  },

  watch: {
    activeLogisticsType(newValue) {
      if (newValue === "Dispatch") {
        this.fetchDispatches();
      } else if (newValue === "Aggregation") {
        this.fetchAggregations();
      }
    },

    aggregationEditModalStatus(newValue) {
      this.isEditLogisticsModalOpened = newValue;
    },

    dipstachEditModalStatus(newValue) {
      this.isEditLogisticsModalOpened = newValue;
    },

    aggregationActiveTab(newValue) {
      this.activeLogisticsType = newValue;
    },

    dispatchActiveTab(newValue) {
      this.activeLogisticsType = newValue;
    },

    aggregationFilterModalStatus(newValue) {
      this.isFilterModalOpened = newValue;
    },

    aggregationVerifyModalStatus(newValue) {
      this.isVerifyLogisticsModalOpened = newValue;
    },

    dispatchVerifyModalStatus(newValue) {
      this.isVerifyLogisticsModalOpened = newValue;
    },

    dispatchFilterModalStatus(newValue) {
      this.isFilterModalOpened = newValue;
    },

    scheduledDetailModalStatus(newValue) {
      this.isDetailsModalOpened = newValue;
    },

    clickedDispatch(newValue) {
      this.itemClicked = newValue;
    },

    clickedAggregation(newValue) {
      this.itemClicked = newValue;
    },

    aggregationDetailModalStatus(newValue) {
      this.isDetailsModalOpened = newValue;
    },

    aggregationApprovalModalStatus(newValue) {
      this.isApprovalStatus = newValue;
    },

    dispatchApprovalModalStatus(newValue) {
      this.isApprovalStatus = newValue;
    },

    aggregationId(newValue) {
      this.itemId = newValue;
    },

    dispatchId(newValue) {
      this.itemId = newValue;
    },

    dispatchFilterMetaData(newValue) {
      const { filteredDispatches, count, query } = newValue;

      this.globalFilterQuery = query;
      this.dispatches = filteredDispatches;
      this.count = count;
    },

    aggregations(newValue) {
      this.dataToExcel = newValue;
    },

    aggregationDeleteModalStatus(newValue) {
      this.isDeleteModalOpened = newValue;
    },

    dispatchDeleteModalStatus(newValue) {
      this.isDeleteModalOpened = newValue;
    },

    dispatches(newValue) {
      this.dataToExcel = newValue;
    },

    selectedAggregationIds(newValue) {
      this.multipleIds = newValue;
    },

    selectedDispatchIds(newValue) {
      this.multipleIds = newValue;
    },

    paginationMetaData: {
      handler: function (newValue) {
        const isAggregationDateFilter =
          this.$store.getters["aggregation/getIsDateFilterStatus"];

        const isDispatchDateFilter =
          this.$store.getters["dispatch/getIsDateFilterStatus"];

        // if (this.activeLogisticsType === this.aggregation) {
        //   if (isDateFilter) {
        //     this.fetchByDatePeriod(newValue.page, this.globalFilterQuery);
        //   } else this.fetchAggregations(newValue.page, this.globalSearchTerm);
        // } else if (this.activeLogisticsType === this.dispatch) {
        //   this.fetchDispatches(newValue.page, this.globalSearchTerm);
        // }
        if (isAggregationDateFilter || isDispatchDateFilter) {
          this.fetchByDatePeriod(newValue.page, this.globalFilterQuery);
        }

        if (this.activeLogisticsType === this.aggregation) {
          this.fetchAggregations(newValue.page, this.globalSearchTerm);
        } else if (this.activeLogisticsType === this.dispatch) {
          this.fetchDispatches(newValue.page, this.globalSearchTerm);
        }
      },
    },
  },
};
</script>

<style scoped>
.parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
  overflow-y: scroll;
}

.parent-wrapper {
  width: 98%;
  /* border: 1px solid #000; */
  margin: 1% auto 0 auto;
  height: 100%;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.border {
  margin-top: 10px;
  border-bottom: 0.7px solid #fff1f1;
}

.logistics-type__container {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 0px;
  align-items: center;
  /* border-bottom: 1px solid #b3b3b3; */
}

.logistics-type {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #b3b3b3;
  font-size: 13px;
  cursor: pointer;
}

.active {
  color: #fff !important;
  background: #232a34;
  border-radius: 37px;
  padding: 8px 16px;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.date-filter-container {
  width: 100%;
  margin-top: 1.5%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 85%;
}

.wrapper p {
  margin-bottom: 0px;
}

.date-filter-container p {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 0.78rem;
  line-height: 24px;
  color: #333333;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.create-buttons__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 36%;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 27%;
  /* border: 1px solid #000; */
}

.data-table-container {
  width: 100%;
  /* border: 1px solid green; */
  height: 79.9%;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

/* Data table Styles */
.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 7px 10px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;

  width: 127px;
  height: 32px;

  border-radius: 41px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.approved {
  color: #669933;
  background: rgba(102, 153, 51, 0.15);
}

.rejected {
  color: #ed0a0a;
  background: rgba(237, 10, 10, 0.15);
}

.pending {
  color: #f19306 !important;
  background: rgba(241, 147, 6, 0.15);
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5px;

  position: absolute;
  width: 110%;
  height: auto;
  right: 60px;
  background: #ffffff;
  /* Drop down shadow */
  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
}

.item-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 5px;
}

.actions-item {
  display: flex;
  cursor: pointer;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.view-action,
.edit-action,
.delete-action {
  margin-left: 5px;
}

.view-action {
  color: #669933;
}

.edit-action {
  color: #ea9a00;
}

.delete-action {
  color: #ed0a0a;
}

.details-modal {
  max-width: 850px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.start-logistics__modal {
  max-width: 650px;
  height: auto;
}

.details-modal,
.start-logistics__modal {
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.filter-modal,
.close-filter-modal {
  position: absolute;
  top: 0px;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter-modal {
  animation: slideFromRightToLeft 1s ease-in-out;
  animation-delay: 10ms;
  left: 72%;
  width: 450px;
}

.close-filter-modal {
  animation: slideBackIn 1s ease-in-out;
  animation-delay: 10ms;
  left: 100%;
  display: hidden;
}

@keyframes slideFromRightToLeft {
  0% {
    left: 100%;
  }
  50% {
    left: 72%;
  }
  100% {
    left: 72%;
  }
}

.delete-modal {
  max-width: 500px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}
</style>
