import { APIConfig } from "../../config/apiConfig";
import moment from "moment";

const formatFieldAgentCosts = (data) => {
  return data.map((cost) => ({
    _id: cost._id,
    agent: cost.fieldAgent?.first_name + " " + cost.fieldAgent?.last_name,
    impressAmount: cost.impressAmount,
    createdBy: cost.agentDetails.first_name + " " + cost.agentDetails.last_name,
    date: moment(cost.date).format("MM/DD/yyyy"),
    dateAdded: moment(cost.createdAt).format("MM/DD/yyyy"),
  }));
};

const state = () => ({
  fieldAgentImprestCosts: [],
  costsToBeUpdated: {},
  error: null,
});

const getters = {
  getCostsToBeUpdated: (state) => state.costsToBeUpdated,
};

const mutations = {
  populatefieldAgentImprestCosts(state, fieldAgentImprestCosts) {
    state.fieldAgentImprestCosts = fieldAgentImprestCosts;
  },

  setCostsToBeUpdated(state, costs) {
    state.costsToBeUpdated = costs;
  },
};

const actions = {
  findCostToBeUpdated({ commit, state }, costId) {
    let costs = state.fieldAgentImprestCosts.find(
      (cost) => cost._id === costId
    );

    commit("setCostsToBeUpdated", costs);
  },

  async fetchFieldAgentImprestCosts({ commit }, params) {
    const { page, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/field-agent-impress/?page=${page}&limit=${limit}`
      );

      const fieldAgentImprestCosts = data?.data
        ? formatFieldAgentCosts(data?.data)
        : [];

      commit("populatefieldAgentImprestCosts", fieldAgentImprestCosts);

      return {
        count: data?.count,
        cost: fieldAgentImprestCosts,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async addNewCosts(_, payload) {
    try {
      const { data } = await APIConfig.post(`/field-agent-impress`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async createTarget(_, payload) {
    try {
      const { data } = await APIConfig.post(
        `/field-agent-impress/target`,
        payload
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async filterByDatePeriod({ commit }, params) {
    const { page, limit, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `/field-agent-impress/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      if (Object.keys(data).length > 0) {
        const fieldAgentImprestCosts = formatFieldAgentCosts(data?.data);

        commit("populatefieldAgentImprestCosts", fieldAgentImprestCosts);

        return {
          count: data?.count,
          cost: fieldAgentImprestCosts,
        };
      } else {
        return {
          count: 0,
          cost: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async updateCosts(_, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/field-agent-impress/${payload.id}`,
        payload
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteCosts(_, id) {
    try {
      const { data } = await APIConfig.delete(`/field-agent-impress/${id}`);

      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
