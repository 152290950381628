export const tableHeaders = [
  { text: "ID", value: "purchaseId" },
  { text: "Purchase Date", value: "purchaseDate" },
  { text: "Farmer name", value: "farmerName" },
  { text: "Mill", value: "millName" },
  { text: "Actual Tonnage", value: "actualTonnage" },
  { text: "Amount Paid", value: "price" },
  { text: "Created By", value: "createdBy" },
  { text: "Channel", value: "channel" },
  { text: "Actions", value: "actions" },
];
