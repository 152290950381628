export const tableHeaders = [
  { text: "ID", value: "farmer_id" },
  { text: "Date", value: "createdAt" },
  { text: "Farmer's name", value: "name" },
  { text: "Phone Number", value: "phone" },
  { text: "State", value: "state" },
  { text: "LGA", value: "lga" },
  { text: "Approval Status", value: "authorization_status" },
  { text: "Actions", value: "actions" },
];

export const commitmentHeaders = [
  {text: "Year", value: "year"},
  {text: "Commited", value: "commited"},
  {text: "Supplied", value: "supplied"},
  {text: "% of Filled Commitment", value: "percentage"},
  {text: "Farmer's Last Comment", value: "farmerLastComment"},
  { text: "Actions", value: "actions" },
]

// export const serverOptions = {
//   page: 1,
//   rowsPerPage: 10,
// };



