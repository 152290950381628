<template>
  <div class="parent">
    <div class="wrapper">
      <!-- Tabs section -->
      <!-- <div class="parent-tabs-container">
        <router-link to="/dashboard/inventory" class="link">
          <div
            class="parent-tab"
            :class="{
              active: activeParentTab === 'Inventory List',
            }"
            @click="updateActiveParentTab(inventoryList)"
          >
            {{ inventoryList }}
          </div>
        </router-link>

        <div
          class="parent-tab"
          :class="{
            active: activeParentTab === 'Target',
          }"
          @click="updateActiveParentTab(target)"
        >
          <router-link to="/dashboard/inventory/target">
            {{ target }}
          </router-link>
        </div>
      </div> -->

      <!-- children routes go here -->
      <router-view></router-view>

    </div>
  </div>
</template>

<script>
export default {
  name: "Inventory",

  components: {
  },

  data() {
    return {
      activeParentTab: "Inventory List",
      inventoryList: "Inventory List",
      target: "Target",
      activeChildTab: "Inventory",
      inventory: "Inventory",
      discrepancies: "Discrepancies",
    };
  },

  methods: {
    updateActiveParentTab(name) {
      this.activeParentTab = name;
    },

    updateActiveChildTab(name) {
      this.activeChildTab = name;
    },
  },
};
</script>

<style scoped>
.parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
  overflow-y: scroll;
}

.wrapper {
  width: 98%;
  margin: 1% auto 0 auto;
  height: 100%;
}

.parent-tabs-container {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 30px;
}

.link {
  text-decoration: none !important;
}

.parent-tab {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #b3b3b3;
  font-size: 13px;
  cursor: pointer;
  padding: 0px;
}

.active {
  color: #232a34 !important;
  border-bottom: 2px solid #669933;
  padding: 8px 16px;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.tabs-container {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 0px;
  align-items: center;
}

.tab {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #b3b3b3;
  font-size: 13px;
  cursor: pointer;
}

.active-child-tab {
  color: #fff !important;
  background: #232a34;
  border-radius: 37px;
  padding: 8px 16px;
}
</style>
