/* eslint-disable no-useless-catch */
import moment from "moment";
import { APIConfig } from "../../config/apiConfig";

const state = () => ({
  discoveryList: [],
  discovery: null,
  isFilter: false,
  estimate_category: "",
  isDateFilter: false,
  isDeleted: false,
  filterMetaData: {
    filteredDiscovery: [],
    count: 0,
    query: "",
  },
  discoveryToBeUpdated: {},
});

const getters = {
  getDiscoveryList: (state) => {
    return state.discoveryList;
  },

  getDiscovery: (state) => {
    return state.discovery;
  },

  getFilterStatus: (state) => {
    return state.isFilter;
  },

  getEstimateCategory: (state) => {
    return state.estimate_category;
  },

  getIsDateFilterStatus: (state) => {
    return state.isDateFilter;
  },

  getIsDeleted: (state) => {
    return state.isDeleted;
  },

  getFilterDiscoveryMetaData: (state) => {
    return state.filterMetaData;
  },

  getDiscoveryToBeUpdated: (state) => {
    return state.discoveryToBeUpdated;
  },
};

const mutations = {
  setDiscoveryList(state, payload) {
    state.discoveryList = payload;
  },

  setDiscovery(state, payload) {
    state.discovery = payload;
  },

  setFilterStatus(state, payload) {
    state.isFilter = payload;
  },

  setEstimateCategory(state, payload) {
    state.estimate_category = payload;
  },

  setIsDateFilterStatus: (state, payload) => {
    state.isDateFilter = payload;
  },

  setIsDeleted: (state, payload) => {
    state.isDeleted = payload;
  },

  setFilterDiscoveryMetaData: (state, metaData) => {
    state.filterMetaData = {
      ...state.filterMetaData,
      ...metaData,
    };
  },

  setValuesForDiscoveryUpdate(state, discovery) {
    state.discoveryToBeUpdated = {
      ...state.discoveryToBeUpdated,
      ...discovery,
    };
  },

  setDiscoveryToBeUpdated: (state, payload) => {
    state.discoveryToBeUpdated = payload;
  },
};

const actions = {
  addEstimateCategory({ commit }, payload) {
    commit("setEstimateCategory", payload);
  },

  setValuesForDiscoveryUpdate({ commit }, discovery) {
    commit("setValuesForDiscoveryUpdate", discovery);
  },

  findDiscoveryToBeUpdated({ commit, state }, discoveryId) {
    const discoveryToBeUpdated = state.discoveryList.find(
      (discovery) => discovery._id === discoveryId
    );

    commit("setDiscoveryToBeUpdated", discoveryToBeUpdated);
  },
  async fetchAllDiscovery({ commit }, params) {
    const { page, limit, searchTerm } = params;

    try {
      const { data } = await APIConfig.get(
        `/declines/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
      );

      if (data) {
        await commit("setDiscoveryList", data.data);
      }

      const discoveries = data?.data?.map((discovery) => {
        return {
          _id: discovery._id,
          decline_id: discovery.decline_id,
          farmer_name: `${discovery.farmerId?.first_name || "Nil"} ${
            discovery.farmerId?.last_name || "Nil"
          }`,
          farmer_id: discovery.farmerId?._id || "Nil",
          millId: discovery.millId?.id || "Nil",
          millRTM: discovery.millId?.mill_id || "Nil",
          mill_name: discovery.millId?.name || "Nil",
          latitude: discovery.millId?.location?.latitude || "Nil",
          longitude: discovery.millId?.location?.longitude || "Nil",
          estimated_tonnage: discovery.estimated_tonnage || "Nil",
          estimate_category: discovery.estimate_category || "Nil",
          discovery_status: discovery.discovery_status,
          isApproved: discovery.isApproved,
          community: discovery.millId?.community_id?.name || "Nil",
          channel: discovery.channel || "Nil",
          state: discovery.millId?.state_id?.name || "Nil",
          lga: discovery.millId?.lga_id?.name || "Nil",
          createdBy:
            discovery.createdBy?.first_name +
              " " +
              discovery.createdBy?.last_name || "Nil",
          agentId: discovery.createdBy?._id,
          discovery_date: moment(discovery.discoveryDate).format("MM/DD/yyyy"),
        };
      });

      return {
        count: data?.count || 0,
        discoveries: discoveries || [],
      };
    } catch (error) {
      console.log(error);
    }
  },

  async updateDiscovery({ commit }, payload) {
    console.log(commit);
    try {
      const { data } = await APIConfig.patch(
        `/declines/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  },

  async updateDiscoveryStatus(_, payload) {
    try {
      const { data } = await APIConfig.patch(
        `/declines/${payload.id}`,
        payload
      );

      return data;
    } catch (error) {
      return error.response.data;
    }
  },

  async filterDiscovery({ commit }, params) {
    const { page, filterQuery, limit } = params;

    try {
      const { data } = await APIConfig.get(
        `/declines/filter/?page=${page}&limit=${limit}&${filterQuery}`
      );

      let response = {
        filteredDiscovery: [],
        count: 0,
        query: filterQuery,
      };

      if (data?.count > 0) {
        response.filteredDiscovery = data?.data.map((discovery) => {
          return {
            _id: discovery._id,
            decline_id: discovery.decline_id,
            farmer_name: `${discovery.farmerId?.first_name || "Nil"} ${
              discovery.farmerId?.last_name || "Nil"
            }`,
            farmer_id: discovery.farmerId?._id || "Nil",
            millId: discovery.millId?.id || "Nil",
            millRTM: discovery.millId?.mill_id || "Nil",
            mill_name: discovery.millId?.name || "Nil",
            latitude: discovery.millId?.location?.latitude || "Nil",
            longitude: discovery.millId?.location?.longitude || "Nil",
            estimated_tonnage: discovery.estimated_tonnage || "Nil",
            estimate_category: discovery.estimate_category || "Nil",
            discovery_status: discovery.discovery_status,
            isApproved: discovery.isApproved,
            community: discovery.millId?.community_id?.name || "Nil",
            channel: discovery.channel || "Nil",
            state: discovery.millId?.state_id?.name || "Nil",
            lga: discovery.millId?.lga_id?.name || "Nil",
            createdBy:
              discovery.createdBy?.first_name +
                " " +
                discovery.createdBy?.last_name || "Nil",
            agentId: discovery.createdBy?._id,
            discovery_date: moment(discovery.discoveryDate).format(
              "MM/DD/yyyy"
            ),
          };
        });

        response.count = data?.count;

        await commit("setFilterStatus", true);
        await commit("setFilterDiscoveryMetaData", response);
      } else {
        await commit("setFilterDiscoveryMetaData", response);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteDiscovery({ commit }, id) {
    try {
      const { data } = await APIConfig.delete(`/declines/${id}`);

      if (data) {
        await commit("setDiscovery", data);
        await commit("setIsDeleted", true);
      }

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async deleteManyDiscoveries({ commit }, ids) {
    console.log(commit);
    try {
      const { data } = await APIConfig.delete(
        `/declines?discoveriesIds=${ids}`
      );

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async fetchOneDiscovery({ commit }, discoveryId) {
    try {
      let { data } = await APIConfig.get(`/declines/${discoveryId}`);

      if (data) {
        await commit("setDiscovery", data);

        data = {
          ...data,
          farmer_name: `${data.farmerId?.first_name || "Nil"} ${
            data.farmerId?.last_name || "Nil"
          }`,
          discovery_status: data.discovery_status,
          millId: data.millId?.id || "Nil",
          mill_name: data.millId?.name,
          latitude: data.millId?.location?.latitude.toString() || "Nil",
          longitude: data.millId?.location?.longitude.toString() || "Nil",
          community: data.millId?.community_id?.name || "Nil",
          estimated_tonnage: data.estimated_tonnage.toString(),
          pricePerTon: data.buy_price_per_ton
            ? Math.round(data.buy_price_per_ton).toString()
            : Math.round(
                data.amount_payable / data.estimated_tonnage
              ).toString(),
          state: data.millId?.state_id?.name || "Nil",
          lga: data.millId?.lga_id?.name || "Nil",
          discovery_date: moment(data.decline_date).format("DD-MM-YYYY"),
          createdBy:
            data.createdBy?.first_name + " " + data.createdBy?.last_name ||
            "Nil",
          channel: data?.channel || "Nil",
        };
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async addDiscovery({ commit }, payload) {
    try {
      const response = await APIConfig.post("/declines", payload);

      const discovery = response.data;

      if (discovery) {
        await commit("setDiscovery", discovery);
      }

      return discovery;
    } catch (error) {
      throw error;
    }
  },

  async approveManyDiscoveries({ commit }, discoveriesIds) {
    console.log(commit);
    try {
      const { data } = await APIConfig.patch(`/declines/approvals`, {
        discoveriesIds,
      });

      return data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  },

  async filterDiscoveryByDatePeriod({ commit }, params) {
    const { page, filterQuery, limit } = params;

    try {
      const { data } = await APIConfig.get(
        `/declines/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      let response = {
        filteredDiscovery: [],
        count: 0,
        query: filterQuery,
      };

      if (Object.keys(data).length > 0) {
        const discoveries = data?.data?.map((discovery) => {
          return {
            _id: discovery._id,
            decline_id: discovery.decline_id,
            farmer_name: `${discovery.farmerId?.first_name || "Nil"} ${
              discovery.farmerId?.last_name || "Nil"
            }`,
            farmer_id: discovery.farmerId?._id || "Nil",
            millId: discovery.millId?.id || "Nil",
            millRTM: discovery.millId?.mill_id || "Nil",
            mill_name: discovery.millId?.name || "Nil",
            latitude: discovery.millId?.location?.latitude || "Nil",
            longitude: discovery.millId?.location?.longitude || "Nil",
            estimated_tonnage: discovery.estimated_tonnage || "Nil",
            estimate_category: discovery.estimate_category || "Nil",
            discovery_status: discovery.discovery_status,
            isApproved: discovery.isApproved,
            community: discovery.millId?.community_id?.name || "Nil",
            channel: discovery.channel || "Nil",
            state: discovery.millId?.state_id?.name || "Nil",
            lga: discovery.millId?.lga_id?.name || "Nil",
            createdBy:
              discovery.createdBy?.first_name +
                " " +
                discovery.createdBy?.last_name || "Nil",
            agentId: discovery.createdBy?._id,
            discovery_date: moment(discovery.discoveryDate).format(
              "MM/DD/yyyy"
            ),
          };
        });

        commit("setIsDateFilterStatus", true);

        response.filteredDiscovery = discoveries;
        response.count = data?.count;

        await commit("setFilterDiscoveryMetaData", response);

        return response;
      } else {
        return {
          count: 0,
          discoveries: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
