<template>
  <div class="profit-input-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <div class="profit-wrapper">
      <div class="profit-type__container">
        <div
          v-if="ViewAtom === true || EditAtom === true"
          class="profit-type"
          :class="{
            active: activeProfitType === 'Profit Target',
          }"
          @click="setActiveProfitType(atom)"
        >
          {{ atom }}
        </div>

        <div
          v-if="ViewPKS === true || EditPKS === true"
          class="profit-type"
          :class="{
            active: activeProfitType === 'PKS',
          }"
          @click="setActiveProfitType(pks)"
        >
          {{ pks }}
        </div>

        <div
          v-if="ViewPKO === true || EditPKO === true"
          class="profit-type"
          :class="{
            active: activeProfitType === 'PKO',
          }"
          @click="setActiveProfitType(pko)"
        >
          {{ pko }}
        </div>

        <div
          v-if="ViewPKC === true || EditPKC === true"
          class="profit-type"
          :class="{
            active: activeProfitType === 'PKC',
          }"
          @click="setActiveProfitType(pkc)"
        >
          {{ pkc }}
        </div>

        <div
          v-if="ViewLogistics === true || EditLogistics === true"
          class="profit-type"
          :class="{
            active: activeProfitType === 'Logistics',
          }"
          @click="setActiveProfitType(logistics)"
        >
          {{ logistics }}
        </div>

        <div
          v-if="ViewPROC === true || EditPROC === true"
          class="profit-type"
          :class="{
            active: activeProfitType === 'Processing Cost',
          }"
          @click="setActiveProfitType(proc)"
        >
          {{ proc }}
        </div>

        <div
          v-if="ViewAtom === true || EditAtom === true"
          class="profit-type"
          :class="{
            active: activeProfitType === 'Maximum RM Price',
          }"
          @click="setActiveProfitType(optimumP1Cost)"
        >
          {{ optimumP1Cost }}
        </div>
      </div>

      <div class="" v-if="activeProfitType === 'Profit Target'">
        <div class="atom-settings-container">
          <div class="atom-settings">
            <div class="atom-caption-wrapper">
              <p class="atom-caption">Profit Target Settings</p>

              <div
                class="atom-caption-image"
                v-if="EditAtom === true"
                @click="openEditAtomModal"
              >
                <img
                  class="image-text"
                  src="../../assets/edit-pencil.png"
                  alt="edit pencil"
                />
              </div>
            </div>

            <div class="atom-box-container">
              <div class="atom-box">
                <p class="atom-box-caption">Atom</p>
                <p class="atom-box-text">01</p>
              </div>

              <div class="atom-box">
                <p class="atom-box-caption">Profit/Atom Target</p>
                <p class="atom-box-text">
                  {{ vueNumberFormat(profitInputUnits.profit_target, {}) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" v-if="activeProfitType === 'PKS'">
        <div class="atom-settings-container">
          <div class="pks-settings">
            <div class="pks-caption-wrapper">
              <p class="pks-caption">PKS Settings</p>

              <div
                class="pks-caption-image"
                v-if="EditPKS === true"
                @click="openEditPKSModal"
              >
                <img
                  class="image-text"
                  src="../../assets/edit-pencil.png"
                  alt="edit pencil"
                />
              </div>
            </div>

            <div class="pks-box-container">
              <div class="pks-box">
                <p class="pks-box-caption">PKS Raw Materials Per Atom</p>
                <p class="pks-box-text">
                  {{
                    vueNumberFormat(
                      profitInputUnits.pks_selling_price_per_ton,
                      {}
                    )
                  }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">PKS Selling Price</p>
                <p class="pks-box-text">
                  {{ vueNumberFormat(profitInputUnits.pks_selling_price, {}) }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">P1 ; PKS Conversion Rate</p>
                <p class="pks-box-text">
                  {{
                    vueNumberFormat(profitInputUnits.p1_to_pks_qty * 100, {
                      prefix: "",
                      suffix: "%",
                      decimal: ".",
                      thousand: ",",
                      precision: "0",
                    })
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" v-if="activeProfitType === 'PKO'">
        <div class="atom-settings-container">
          <div class="pko-settings">
            <div class="pks-caption-wrapper">
              <p class="pks-caption">PKO Settings</p>

              <div
                class="pks-caption-image"
                v-if="EditPKO === true"
                @click="openEditPKOModal"
              >
                <img
                  class="image-text"
                  src="../../assets/edit-pencil.png"
                  alt="edit pencil"
                />
              </div>
            </div>

            <div class="pks-box-container">
              <div class="pks-box">
                <p class="pks-box-caption">PKO Raw Materials Per Atom</p>
                <p class="pks-box-text">
                  {{
                    vueNumberFormat(
                      profitInputUnits.pko_selling_price_per_ton,
                      {}
                    )
                  }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">PKO Selling Price</p>
                <p class="pks-box-text">
                  {{ vueNumberFormat(profitInputUnits.pko_selling_price, {}) }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">DRY P2 ; PKO Conversion Ratio</p>
                <p class="pks-box-text">
                  {{
                    vueNumberFormat(
                      profitInputUnits.p2_dry_to_pko_qty.toFixed(3) * 100,
                      {
                        prefix: "",
                        suffix: "%",
                        decimal: ".",
                        thousand: ",",
                        precision: "0",
                      }
                    )
                  }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">P1 ; P2 DRY Conversion Ratio</p>
                <p class="pks-box-text">
                  {{
                    vueNumberFormat(
                      profitInputUnits.p1_to_p2_dry_qty.toFixed(3) * 100,
                      {
                        prefix: "",
                        suffix: "%",
                        decimal: ".",
                        thousand: ",",
                        precision: "0",
                      }
                    )
                  }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">P1 ; P2 WET Conversion Ratio</p>
                <p class="pks-box-text">
                  {{
                    vueNumberFormat(
                      profitInputUnits.p1_to_p2_wet_qty.toFixed(3) * 100,
                      {
                        prefix: "",
                        suffix: "%",
                        decimal: ".",
                        thousand: ",",
                        precision: "0",
                      }
                    )
                  }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">P2 WET ; P2 DRY Conversion Ratio</p>
                <p class="pks-box-text">
                  {{
                    vueNumberFormat(
                      profitInputUnits.p2_wet_to_p2_dry_qty.toFixed(3) * 100,
                      {
                        prefix: "",
                        suffix: "%",
                        decimal: ".",
                        thousand: ",",
                        precision: "0",
                      }
                    )
                  }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">No of PKO/T P1 Cracked</p>
                <p class="pks-box-text">
                  {{ profitInputUnits.no_of_pko_per_ton_cracked.toFixed(3) }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">Tonnes of P1 in IT of PKO</p>
                <p class="pks-box-text">
                  {{ profitInputUnits?.tons_of_p1_in_1t_of_pko?.toFixed(3) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" v-if="activeProfitType === 'PKC'">
        <div class="atom-settings-container">
          <div class="pko-settings">
            <div class="pks-caption-wrapper">
              <p class="pks-caption">PKC Settings</p>

              <div
                class="pks-caption-image"
                v-if="EditPKC === true"
                @click="openPKCModal"
              >
                <img
                  class="image-text"
                  src="../../assets/edit-pencil.png"
                  alt="edit pencil"
                />
              </div>
            </div>

            <div class="pks-box-container">
              <div class="pks-box">
                <p class="pks-box-caption">PKC Raw Materials Per Atom</p>
                <p class="pks-box-text">
                  {{
                    vueNumberFormat(
                      profitInputUnits.pkc_selling_price_per_ton.toFixed(0),
                      {}
                    )
                  }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">PKC Selling Price</p>
                <p class="pks-box-text">
                  {{ vueNumberFormat(profitInputUnits.pkc_selling_price, {}) }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">P2 DRY ; PKC Conversion Ratio</p>
                <p class="pks-box-text">
                  {{ profitInputUnits?.p2_dry_to_pkc_qty * 100 }}%
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">No of PKC/T P1 Cracked</p>
                <p class="pks-box-text">
                  {{ profitInputUnits.no_of_pkc_per_ton_cracked.toFixed(3) }}
                </p>
              </div>

              <div class="pks-box">
                <p class="pks-box-caption">Tonnes of P1 in 1T of PKC</p>
                <p class="pks-box-text">
                  {{ profitInputUnits.tons_of_p1_in_1t_of_pkc.toFixed(3) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" v-if="activeProfitType === 'Logistics'">
        <div class="logistics-container">
          <div class="p1_log-container">
            <div class="pko-settings">
              <div class="pks-caption-wrapper">
                <p class="pks-caption">P1_InLog</p>

                <div
                  class="pks-caption-image"
                  v-if="EditLogistics === true"
                  @click="openP1_LogisticsModal"
                >
                  <img
                    class="image-text"
                    src="../../assets/edit-pencil.png"
                    alt="edit pencil"
                  />
                </div>
              </div>

              <div class="pks-box-container">
                <div class="pks-box">
                  <p class="pks-box-caption">Fuel_Econ(L/KM)</p>
                  <p class="pks-box-text">
                    {{ profitInputUnits.inbound_fuel_econ }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Fuel Cost</p>
                  <p class="pks-box-text">
                    &#8358;{{ profitInputUnits.fuel_cost }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Route Quality</p>
                  <p class="pks-box-text">
                    {{ profitInputUnits.route_quality }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Inbound Vehicle Capacity(tons)</p>
                  <p class="pks-box-text">
                    {{ profitInputUnits?.inbound_vehicle_capacity }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Loading Cost Per Ton</p>
                  <p class="pks-box-text">
                    &#8358;{{
                      profitInputUnits.loading_cost_per_ton?.toFixed(0)
                    }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Inbound Loading Cost</p>
                  <p class="pks-box-text">
                    {{
                      vueNumberFormat(
                        profitInputUnits?.inbound_loading_cost,
                        {}
                      )
                    }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Comm_Tolls/Trip</p>
                  <p class="pks-box-text">
                    &#8358;{{
                      profitInputUnits.community_ton_per_trip?.toFixed(0)
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="p2_outlong">
            <div class="pko-settings">
              <div class="pks-caption-wrapper">
                <p class="pks-caption">P2_OutLog</p>

                <div
                  class="pks-caption-image"
                  v-if="EditLogistics === true"
                  @click="openP2_LogisticsModal"
                >
                  <img
                    class="image-text"
                    src="../../assets/edit-pencil.png"
                    alt="edit pencil"
                  />
                </div>
              </div>

              <div class="pks-box-container">
                <div class="pks-box">
                  <p class="pks-box-caption">Fuel_Econ(L/KM)</p>
                  <p class="pks-box-text">{{ profitInputUnits.fuel_econ }}</p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Fuel Cost</p>
                  <p class="pks-box-text">{{ profitInputUnits.fuel_cost }}</p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Outbound Loading Cost/Ton</p>
                  <p class="pks-box-text">
                    {{
                      vueNumberFormat(
                        profitInputUnits.outbound_loading_cost,
                        {}
                      )
                    }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Route Quality</p>
                  <p class="pks-box-text">
                    {{ profitInputUnits.route_quality }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Outbound Vehicle Capacity(tons)</p>
                  <p class="pks-box-text">
                    {{ profitInputUnits.outbound_vehicle_capacity }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Loading Cost Per Ton</p>
                  <p class="pks-box-text">
                    {{ profitInputUnits.loading_cost_per_ton?.toFixed(0) }}
                  </p>
                </div>

                <div class="pks-box">
                  <p class="pks-box-caption">Comm_Tolls/Trip</p>
                  <p class="pks-box-text">
                    &#8358;{{
                      profitInputUnits.community_ton_per_trip?.toFixed(0)
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" v-if="activeProfitType === 'Processing Cost'">
        <div class="logistics-container">
          <div class="p1_proc-settings">
            <div class="atom-settings">
              <div class="atom-caption-wrapper">
                <p class="atom-caption">P1_PROC Settings</p>

                <div
                  class="atom-caption-image"
                  v-if="EditPROC === true"
                  @click="openP1PROCModal"
                >
                  <img
                    class="image-text"
                    src="../../assets/edit-pencil.png"
                    alt="edit pencil"
                  />
                </div>
              </div>

              <div class="atom-box-container">
                <div class="atom-box">
                  <p class="atom-box-caption">P1_PROC</p>
                  <p class="atom-box-text">
                    {{ vueNumberFormat(profitInputUnits?.p1_proc, {}) }}
                  </p>
                </div>

                <div class="atom-box">
                  <p class="atom-box-caption">Cost to Produce 1T P2</p>
                  <p class="atom-box-text">
                    {{
                      vueNumberFormat(
                        profitInputUnits.cost_to_produce_1t_p2,
                        {}
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="p2_proc-settings">
            <div class="atom-settings">
              <div class="atom-caption-wrapper">
                <p class="atom-caption">P2_PROC Settings</p>

                <div
                  class="atom-caption-image"
                  v-if="EditPROC === true"
                  @click="openP2PROCModal"
                >
                  <img
                    class="image-text"
                    src="../../assets/edit-pencil.png"
                    alt="edit pencil"
                  />
                </div>
              </div>

              <div class="atom-box-container">
                <div class="atom-box">
                  <p class="atom-box-caption">P1_PROC</p>
                  <p class="atom-box-text">
                    {{ vueNumberFormat(profitInputUnits?.p2_proc, {}) }}
                  </p>
                </div>

                <div class="atom-box">
                  <p class="atom-box-caption">Cost to Produce 1T PKO</p>
                  <p class="atom-box-text">
                    {{
                      vueNumberFormat(
                        profitInputUnits.cost_to_produce_1t_pko,
                        {}
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" v-if="activeProfitType === 'Maximum RM Price'">
        <div class="">
          <p class="optimum-p1-caption">Maximum RM Price</p>
          <div class="optimum-p1-cost__wrapper">
            <div class="optimum-form-container">
              <SearchableMillsDropdown
                :options="mills"
                dropdownTitle="Mill"
                fieldName="mill"
                @onSelect="handleSelect"
                :inputValue="mill"
              />

              <TextInput
                placeholder="RM Price"
                fieldName="optimumRMPrice"
                :inputValue="optimumRMPrice"
              />

              <GreenButton
                label="Submit"
                @click="getOptimumP1Price"
                width="128px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="edit-atom-modal"
      v-if="isEditAtomModalOpened && activeProfitType === 'Profit Target'"
    >
      <EditAtomModal
        @updateProfitPerAtomTargetEvent="updateProfitPerAtomTarget"
        @onCloseModal="handleCloseModal"
        :target_profit="profitInputUnits.profit_target"
      />
    </div>

    <div
      class="edit-pks-modal"
      v-if="isEditPKSModalOpened && activeProfitType === 'PKS'"
    >
      <EditPKSModal
        @onCloseModal="handleCloseModal"
        @updatePKSEvent="updatePKS"
        :pks_selling_price="profitInputUnits.pks_selling_price"
        :pks_selling_price_per_ton="profitInputUnits.pks_selling_price_per_ton"
        :p1_to_pks_qty="profitInputUnits.p1_to_pks_qty"
      />
    </div>

    <div
      class="edit-pko-modal"
      v-if="isEditPKOModalOpened && activeProfitType === 'PKO'"
    >
      <EditPKOModal
        @onCloseModal="handleCloseModal"
        @updatePKOEvent="updatePKO"
        :pko_seling_price_per_ton="profitInputUnits.pko_selling_price_per_ton"
        :pko_seling_price="profitInputUnits.pko_selling_price"
        :p2_dry_to_pko_qy="profitInputUnits.p2_dry_to_pko_qty"
        :p1_to_p2_dry_qy="profitInputUnits.p1_to_p2_dry_qty"
        :p1_to_p2_wet_qy="profitInputUnits.p1_to_p2_wet_qty"
        :p2_wet_to_p2_dry_qy="profitInputUnits.p2_wet_to_p2_dry_qty"
        :no_of_pko_pr_ton_cracked="profitInputUnits.no_of_pko_per_ton_cracked"
        :tons_of_p1_in_ton_of_pko="profitInputUnits.tons_of_p1_in_1t_of_pko"
      />
    </div>

    <div
      class="edit-pkc-modal"
      v-if="isEditPKCModalOpened && activeProfitType === 'PKC'"
    >
      <EditPKCModal
        @onCloseModal="handleCloseModal"
        @updatePKCEvent="updatePKC"
        :p1_to_p2_dry_qty="profitInputUnits.p1_to_p2_dry_qty"
        :pkc_seling_price_per_ton="profitInputUnits.pkc_selling_price_per_ton"
        :pkc_seling_price="profitInputUnits.pkc_selling_price"
        :p2_dry_to_pkc_qy="profitInputUnits.p2_dry_to_pkc_qty"
        :no_of_pkc_pr_ton_cracked="profitInputUnits.no_of_pkc_per_ton_cracked"
        :tons_of_p1_in_ton_of_pkc="profitInputUnits.tons_of_p1_in_1t_of_pkc"
      />
    </div>

    <div
      class="edit-p1-logistics-modal"
      v-if="isEditP1_LogisticsModalOpened && activeProfitType === 'Logistics'"
    >
      <EditP1LogisticsModal
        @onCloseModal="handleCloseModal"
        @updateP1InLogEvent="updateInLog"
        :inbound_fuel_econ="profitInputUnits.inbound_fuel_econ"
        :ful_cost="profitInputUnits.fuel_cost"
        :rout_quality="profitInputUnits.route_quality"
        :vehi_capacity="profitInputUnits.inbound_vehicle_capacity"
        :load_t="profitInputUnits.loading_cost_per_ton"
        :comm_tols="profitInputUnits.community_ton_per_trip"
        :load_cost="profitInputUnits.inbound_loading_cost"
      />
    </div>

    <div
      class="edit-p2-logistic-modal"
      v-if="isEditP2_LogisticsModalOpened && activeProfitType === 'Logistics'"
    >
      <EditP2LogisticsModal
        @onCloseModal="handleCloseModal"
        @updateOutLogEvent="updateOutLog"
        :ful_econ="profitInputUnits.fuel_econ"
        :ful_cost="profitInputUnits.fuel_cost"
        :rout_quality="profitInputUnits.route_quality"
        :vehi_capacity="profitInputUnits.outbound_vehicle_capacity"
        :load_t="profitInputUnits.loading_cost_per_ton"
        :comm_tols="profitInputUnits.community_ton_per_trip"
        :load_cost="profitInputUnits.outbound_loading_cost"
      />
    </div>

    <div
      class="edit-p1-proc-modal"
      v-if="isEditP1PROCModalOpened && activeProfitType === 'Processing Cost'"
    >
      <EditP1PROCModal
        @onCloseModal="handleCloseModal"
        @updateP1ProcessingCostEvent="updateP1ProcessingCost"
        :p_proc="profitInputUnits.p1_proc"
        :cost_2_produce_1t_p2="profitInputUnits.cost_to_produce_1t_p2"
      />
    </div>

    <div
      class="edit-p2-proc-modal"
      v-if="isEditP2PROCModalOpened && activeProfitType === 'Processing Cost'"
    >
      <EditP2PROCModal
        @onCloseModal="handleCloseModal"
        @updateP2ProcessingCostEvent="updateP2ProcessingCost"
        :p_proc="profitInputUnits.p2_proc"
        :cost_2_produce_1t_pko="profitInputUnits.cost_to_produce_1t_pko"
      />
    </div>
  </div>
</template>

<script>
import EditAtomModal from "./modals/EditAtomModal.vue";
import EditPKSModal from "./modals/EditPKSModal.vue";
import EditPKOModal from "./modals/EditPKOModal.vue";
import EditPKCModal from "./modals/EditPKCModal.vue";
import EditP1LogisticsModal from "./modals/EditP1LogisticsModal.vue";
import EditP2LogisticsModal from "./modals/EditP2LogisticsModal.vue";
import EditP1PROCModal from "./modals/EditP1PROCModal.vue";
import EditP2PROCModal from "./modals/EditP2PROCModal.vue";
import Loading from "vue-loading-overlay";
import SearchableMillsDropdown from "../../components/form-inputs/select-dropdown/SearchableMillsDropdown.vue";
import TextInput from "../../components/form-inputs/text-input/TextInput.vue";

import GreenButton from "../../components/buttons/green-button/GreenButton.vue";

import { createToast } from "mosha-vue-toastify";
import { AuthUser } from "../../utils/authUser";

export default {
  name: "ProfitInput",
  components: {
    EditAtomModal,
    EditPKSModal,
    EditPKOModal,
    EditPKCModal,
    EditP1LogisticsModal,
    EditP2LogisticsModal,
    EditP1PROCModal,
    EditP2PROCModal,
    SearchableMillsDropdown,
    GreenButton,
    TextInput,
    Loading,
  },

  data() {
    return {
      atom: "Profit Target",
      pks: "PKS",
      pko: "PKO",
      pkc: "PKC",
      logistics: "Logistics",
      proc: "Processing Cost",
      optimumP1Cost: "Maximum RM Price",
      activeProfitType: "Profit Target",
      lmv_type: "<3T,<7T,<10T,<15T",
      lmv_type2: "<15T,<35T",
      isEditAtomModalOpened: false,
      isEditPKSModalOpened: false,
      isEditPKOModalOpened: false,
      isEditPKCModalOpened: false,
      isEditP1_LogisticsModalOpened: false,
      isEditP2_LogisticsModalOpened: false,
      isEditP1PROCModalOpened: false,
      isEditP2PROCModalOpened: false,
      profitInputUnits: {},
      loading: false,
      ViewAtom: false,
      ViewPKO: false,
      ViewPKS: false,
      ViewPKC: false,
      ViewLogistics: false,
      ViewPROC: false,
      EditAtom: false,
      EditPKO: false,
      EditPKC: false,
      EditLogistics: false,
      EditPROC: false,
      EditPKS: false,
      mills: [],
      mill: {},
      optimumRMPrice: "",
    };
  },

  mounted() {
    this.fetchProfitInputUnits();
    this.getUserFromLocalStorage();
    this.getMills();
  },

  methods: {
    setActiveProfitType(profitType) {
      this.activeProfitType = profitType;
    },

    handleSelect(obj) {
      const { mill } = obj;
      this.mill = {
        latitude: !isNaN(mill.latitude) ? Number(mill.latitude) : "",
        longitude: !isNaN(mill.longitude) ? Number(mill.longitude) : "",
      };
    },

    async getOptimumP1Price() {
      const { latitude, longitude } = this.mill;
      if (!latitude || !longitude) {
        createToast("Mill does not have coordinates!", {
          type: "danger",
          timeout: 3000,
          position: "top-right",
        });
        return;
      }
      const params = {
        millLat: latitude,
        millLng: longitude,
      };

      this.loading = true;

      const price = await this.$store.dispatch(
        "profitInput/getOptimumP1PriceForProfitTarget",
        params
      );

      this.loading = false;

      this.optimumRMPrice = price.toFixed(2);
    },

    openEditAtomModal() {
      this.isEditAtomModalOpened = !this.isEditAtomModalOpened;
    },

    openEditPKSModal() {
      this.isEditPKSModalOpened = !this.isEditPKSModalOpened;
    },

    openEditPKOModal() {
      this.isEditPKOModalOpened = !this.isEditPKOModalOpened;
    },

    openPKCModal() {
      this.isEditPKCModalOpened = !this.isEditPKCModalOpened;
    },

    openP1_LogisticsModal() {
      this.isEditP1_LogisticsModalOpened = !this.isEditP1_LogisticsModalOpened;
    },

    openP2_LogisticsModal() {
      this.isEditP2_LogisticsModalOpened = !this.isEditP2_LogisticsModalOpened;
    },

    openP1PROCModal() {
      this.isEditP1PROCModalOpened = !this.isEditP1PROCModalOpened;
    },

    openP2PROCModal() {
      this.isEditP2PROCModalOpened = !this.isEditP2PROCModalOpened;
    },

    handleCloseModal(status) {
      this.isEditAtomModalOpened = status;
      this.isEditPKSModalOpened = status;
      this.isEditPKOModalOpened = status;
      this.isEditPKCModalOpened = status;
      this.isEditP1_LogisticsModalOpened = status;
      this.isEditP2_LogisticsModalOpened = status;
      this.isEditP1PROCModalOpened = status;
      this.isEditP2PROCModalOpened = status;
    },

    async fetchProfitInputUnits() {
      this.loading = true;
      try {
        this.profitInputUnits = await this.$store.dispatch(
          "profitInput/profitInput"
        );

        console.log(this.profitInputUnits.inbound_fuel_econ);

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getMills() {
      const params = {
        page: 1,
        searchTerm: "",
      };
      try {
        const { mills } = await this.$store.dispatch("mills/getMills", params);

        if (mills.length) {
          this.mills = mills.map((mill) => {
            return {
              id: mill.id,
              name: mill.name,
              latitude: mill.latitude,
              longitude: mill.longitude,
            };
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    getUserFromLocalStorage() {
      this.loading = true;
      const authUser = new AuthUser();
      const user = authUser.getDetails();

      user?.permissions.forEach((el) => {
        if (el === "ViewPKO") {
          this.ViewPKO = true;
        }
        if (el === "ViewPKS") {
          this.ViewPKS = true;
        }
        if (el === "ViewPKC") {
          this.ViewPKC = true;
        }
        if (el === "ViewLogistics") {
          this.ViewLogistics = true;
        }
        if (el === "ViewPROC") {
          this.ViewPROC = true;
        }
        if (el === "EditPKO") {
          this.EditPKO = true;
          this.ViewPKO = true;
        }
        if (el === "EditPKS") {
          this.EditPKS = true;
          this.ViewPKS = true;
        }
        if (el === "EditPKC") {
          this.EditPKC = true;
          this.ViewPKC = true;
        }
        if (el === "EditLogistics") {
          this.EditLogistics = true;
          this.ViewLogistics = true;
        }
        if (el === "EditPROC") {
          this.EditPROC = true;
          this.ViewPROC = true;
        }

        if (el === "ViewAtom") {
          this.ViewAtom = true;
          this.ViewAtom = true;
        }

        if (el === "EditAtom") {
          this.EditAtom = true;
          this.ViewAtom = true;
        }
      });

      this.loading = false;
    },

    updateProfitPerAtomTarget(value) {
      this.profitInputUnits.profit_target = value;
      this.isEditAtomModalOpened = false;
    },
    updatePKS(value) {
      this.profitInputUnits.pks_selling_price = value.PKS_SP;
      this.profitInputUnits.p1_to_pks_qty = value.p1_to_pks;
      this.profitInputUnits.pks_selling_price_per_ton = value.pks_RTP1;

      this.isEditPKSModalOpened = false;
    },
    updatePKO(value) {
      this.profitInputUnits.pko_selling_price_per_ton =
        value.pko_selling_price_per_ton;
      this.profitInputUnits.pko_selling_price = value.pko_selling_price;
      this.profitInputUnits.p2_dry_to_pko_qty = value.p2_dry_to_pko_qty;
      this.profitInputUnits.p1_to_p2_dry_qty = value.p1_to_p2_dry_qty;
      this.profitInputUnits.p1_to_p2_wet_qty = value.p1_to_p2_wet_qty;
      this.profitInputUnits.p2_wet_to_p2_dry_qty = value.p2_wet_to_p2_dry_qty;
      this.profitInputUnits.no_of_pko_per_ton_cracked =
        value.no_of_pko_per_ton_cracked;
      this.profitInputUnits.tons_of_p1_in_1t_of_pko =
        value.tons_of_p1_in_1t_of_pko;
      this.isEditPKOModalOpened = false;
    },

    updatePKC(value) {
      this.profitInputUnits.pkc_selling_price_per_ton =
        value.pkc_selling_price_per_ton;
      this.profitInputUnits.pkc_selling_price = value.pkc_selling_price;
      this.profitInputUnits.p2_dry_to_pkc_qty = value.p2_dry_to_pkc_qty;
      this.profitInputUnits.no_of_pkc_per_ton_cracked =
        value.no_of_pkc_per_ton_cracked;
      this.profitInputUnits.tons_of_p1_in_1t_of_pkc =
        value.tons_of_p1_in_1t_of_pkc;

      this.isEditPKCModalOpened = false;
    },
    updateInLog(value) {
      this.profitInputUnits.fuel_econ = value.fuel_econ;
      this.profitInputUnits.fuel_cost = value.fuel_cost;
      this.profitInputUnits.route_quality = value.route_quality;
      this.profitInputUnits.inbound_vehicle_capacity =
        value.inbound_vehicle_capacity;
      this.profitInputUnits.loading_cost_per_ton = value.loading_t;
      this.profitInputUnits.inbound_loading_cost = value.inbound_loading_cost;
      this.profitInputUnits.community_ton_per_trip = value.comm_tolls;

      this.isEditP1_LogisticsModalOpened = false;
    },
    updateOutLog(value) {
      this.profitInputUnits.fuel_econ = value.fuel_econ;
      this.profitInputUnits.fuel_cost = value.fuel_cost;
      this.profitInputUnits.route_quality = value.route_quality;
      this.profitInputUnits.outbound_vehicle_capacity =
        value.outbound_vehicle_capacity;
      this.profitInputUnits.loading_cost_per_ton = value.loading_t;
      this.profitInputUnits.outbound_loading_cost = value.outbound_loading_cost;
      this.profitInputUnits.community_ton_per_trip = value.comm_tolls;

      this.isEditP2_LogisticsModalOpened = false;
    },
    updateP1ProcessingCost(value) {
      this.profitInputUnits.p1_proc = value.p1_proc;
      this.profitInputUnits.cost_to_produce_1t_p2 = value.cost_to_produce_1t_p2;

      this.handleCloseModal(false);
    },

    updateP2ProcessingCost(value) {
      this.profitInputUnits.p2_proc = value.p2_proc;
      this.profitInputUnits.cost_to_produce_1t_pko =
        value.cost_to_produce_1t_pko;

      this.isEditP2PROCModalOpened = false;
    },
  },
};
</script>

<style scoped>
.profit-input-parent {
  width: 100%;
  margin-left: auto;
  background: #f9fafe;
  padding: 1%;
  height: 100vh;
}

.profit-wrapper {
  width: 98%;
  margin: 1% auto 0 auto;
  height: 100%;
}

.profit-type__container {
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 30px;
}

.profit-type {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #b3b3b3;
  font-size: 13px;
  cursor: pointer;
}

.active {
  color: #232a34 !important;
  border-bottom: 2px solid #669933;
  padding: 8px 16px;
}

.atom-settings-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.atom-settings,
.pks-settings {
  height: 10rem;
}

.atom-caption-wrapper,
.pks-caption-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.atom-caption,
.pks-caption,
.optimum-p1-caption {
  font-size: 14px;
  font-weight: 700;
  color: #669933;
}

.atom-caption-image,
.pks-caption-image {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.image-text {
  width: 100%;
  height: 100%;
}

.atom-box-container,
.pks-box-container {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.optimum-p1-cost__wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.optimum-form-container {
  /* display: flex; */
  width: 100%;
  padding: 10px;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.p1_log-container,
.p2_outlong {
  width: 100%;
  height: 20rem;
}

.p1_proc-settings,
.p2_proc-settings {
  width: 100%;
  height: 10rem;
}

.logistics-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.atom-box-text,
.pks-box-text {
  color: #333;
  font-weight: 800;
  font-size: 12px;
}

.pko-settings {
  height: 30rem;
}

.atom-box-caption,
.pks-box-caption {
  color: #8c8c8c;
  font-weight: 400;
  font-size: 12px;
}

.edit-atom-modal {
  max-width: 600px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.edit-pks-modal,
.edit-pko-modal,
.edit-pkc-modal,
.edit-p1-logistics-modal,
.edit-p2-logistic-modal,
.edit-p1-proc-modal,
.edit-p2-proc-modal {
  max-width: 600px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}
</style>
