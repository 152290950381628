<template>
  <div class="discovery-parent-modal">
    <!-- <h4>Discovery Modal</h4> -->
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />

    <section class="modal-header">
      <div style="margin: 0 auto; width: 96%">
        <div class="modal-header-content">
          <h5 class="modal-header-caption">Vehicle Details</h5>

          <div class="logo-container">
            <img src="../../../../assets/releaf-logo.png" alt="releaf-logo" />
          </div>

          <div class="icons-container">
            <div style="cursor: pointer">
              <img
                src="../../../../assets/edit-icon.png"
                @click="openEditModal"
                alt="edit-icon"
              />
            </div>

            <div style="cursor: pointer" @click="openDeleteModal">
              <img src="../../../../assets/delete-icon.png" alt="delete-icon" />
            </div>

            <div class="x-container" @click="closeModal">
              <img src="../../../../assets/x-icon.png" alt="x-icon" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="discovery-details" style="padding: 1rem">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="details">
          <div class="details_section">
            <InfoBox header="Vehicle Name" :info="vehicle.name" class="div1" />

            <InfoBox
              header="Plate Number"
              :info="vehicle.vehicleNumber"
              class="div2"
            />

            <InfoBox
              header="Vehicle Model"
              :info="vehicle.vehicleModel"
              class="div3"
            />

            <InfoBox
              header="Fuel Economy"
              :info="vehicle.fuelEconomy + 'L/KM'"
              class="div4"
            />

            <InfoBox
              header="Loading Time"
              :info="vehicle.loadingTime"
              class="div5"
            />

            <InfoBox
              header="LMV Cost"
              :info="vueNumberFormat(vehicle.vehicleCost, {})"
              class="div6"
            />
            <InfoBox
              header="Last Repair Date"
              :info="vehicle.lastRepairDate"
              class="div7"
            />
            <InfoBox header="Driver" :info="vehicle.driver" class="div8" />
          </div>

          <div class="details_section">
            <InfoBox
              header="Minimum Capacity"
              :info="vehicle.minCapacity"
              class="div1"
            />

            <InfoBox
              header="Maximum Capacity"
              :info="vehicle.maxCapacity"
              class="div2"
            />

            <InfoBox
              header="Fuel Cost"
              :info="vueNumberFormat(vehicle.fuelCost, {})"
              class="div3"
            />

            <InfoBox
              header="Loading Cost/T"
              :info="vueNumberFormat(vehicle.loadingCostPerTon, {})"
              class="div4"
            />

            <InfoBox
              header="Date Of Purchased"
              :info="vehicle.datePurchased"
              class="div5"
            />

            <InfoBox
              header="Last Overhaul Date"
              :info="vehicle.lastOverhaulDate"
              class="div6"
            />

            <InfoBox
              header="Equipments"
              :info="vehicle.equipment"
              class="div7"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import InfoBox from "../../../../components/info-box/InfoBox.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "VehicleDetailsModal",
  components: {
    InfoBox,
    Loading,
  },
  data() {
    return {
      loading: false,
      modalStatus: "",
      vehicle: this.$store.getters["vehicleManagement/getAssetToBeUpdated"],
    };
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    openDeleteModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openDeleteModal", true);
      this.$store.dispatch("assetManagement/setRefreshStatus", false);
    },

    openEditModal() {
      this.$emit("onCloseModal", false);
      this.$emit("openEditModal", true);
      this.$store.dispatch(
        "vehicleManagement/findAssetToBeUpdated",
        this.vehicle["_id"]
      );
    },
  },
};
</script>

<style scoped>
.discovery-parent-modal {
  background-color: #fff;
  height: 450px;
}

.modal-header-content,
.details,
.contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details {
  padding: 5px;
}

.modal-header-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;

  /* margin: 5px auto; */
  width: 94%;
  /* border: 1px solid #fff; */
  margin: 0;
}
.content-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.logo-container img {
  width: 30px;
  height: auto;
}
.discovery-decision-details {
  background: #232a34;
  border-radius: 16px;
  height: 180px;
  margin: 20px;
  padding-top: 20px;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 19%;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.border,
.green-border {
  margin-top: 10px;
  background: #ffffff;
  width: 100%;
}

.decision-label {
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.8px;
}

.details_section {
  margin-top: 15px;
  width: 42%;
  height: auto;

  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 20px;
  padding: 0px;
}

.first {
  grid-area: 1 / 1 / 2 / 2;
  /* border: 1px solid #fff; */
  align-self: center;
}

.second {
  grid-area: 1 / 2 / 2 / 3;
  /* border: 1px solid #fff; */
}

.third {
  grid-area: 2 / 1 / 3 / 2;
  /* border: 1px solid #fff; */
}

.four {
  grid-area: 2 / 2 / 3 / 3;
  /* border: 1px solid #fff; */
}

.five {
  grid-area: 1 / 1 / 2 / 2;
}

.six {
  grid-area: 1 / 2 / 2 / 3;
}

.seven {
  grid-area: 2 / 1 / 3 / 2;
}

.eight {
  grid-area: 2 / 2 / 3 / 3;
}

.green-border {
  border-bottom: 2px dotted #669933;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 2 / 1 / 3 / 2;
}
.div4 {
  grid-area: 2 / 2 / 3 / 3;
}
.div5 {
  grid-area: 3 / 1 / 4 / 2;
}
.div6 {
  grid-area: 3 / 2 / 4 / 3;
}

.discovery-decision {
  margin: 50px 0px;
}

.actions-container {
  width: 100%;
  height: 5rem;
  background: #fff;
  border-top: 1px solid #b3b3b3;
  padding: 1rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions-img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.image-container {
  height: 2rem;
  width: 2rem;
}

.image-item {
  height: 100%;
  width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.discovery-decision-header {
  color: #669933;
  font-weight: 900;
  padding-left: 10px;
  font-size: 27px;
}
</style>
