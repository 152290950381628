<template>
  <div class="modal-parent">
    <Loading v-model:active="loading" :can-cancel="true" :is-full-page="true" />
    <section class="headers">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Add Commitment</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <div class="edit-body-commitment">
      <table id="data-table">
        <thead>
          <tr>
            <th>Year</th>
            <th>Commitment Amount</th>
            <th>Farmer Last Comment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in tableData" :key="index">
            <td>
              <select
                v-model="row.commitmentYear"
                @input="validateForm"
                id="year"
                name="year"
              >
                <option value="">Select year</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
              </select>
            </td>
            <td>
              <input
                v-model="row.commitmentAmount"
                @input="validateForm"
                type="number"
                placeholder="Enter amount in tonnes"
              />
            </td>
            <td>
              <input
                v-model="row.farmerComment"
                type="text"
                placeholder="Enter comment"
                @input="validateForm"
              />
            </td>
            <td>
              <img
                @click="deleteTableRow(index)"
                class="trash-can"
                src="../../../assets/trash-can-solid.svg"
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
      <p @click="addTableRows" id="add-row-button" class="mb-5">+ Add Row</p>
    </div>

    <!-- footer -->
    <div class="edit-footer-commitment">
      <div
        @click="addFarmerCommitment"
        :disabled="!isFormValid"
        class="btn-commitment"
      >
        Add commitment
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
export default {
  name: "SaltClientAddCommitmentModal",

  data() {
    return {
      tableData: [
        {
          commitmentYear: "",
          commitmentAmount: "",
          farmerComment: "",
        },
      ],
      isFormValid: false,
    };
  },

  props: {
    farmerId: {
      type: String,
      required: true,
    },
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
      window.location.reload();
    },
    addTableRows() {
      const newRow = {
        commitmentYear: "",
        commitmentAmount: "",
        farmerComment: "",
      };
      this.tableData.push(newRow);
    },
    deleteTableRow(index) {
      if (index >= 0 && index < this.tableData.length) {
        this.tableData.splice(index, 1);
      }
    },

    validateForm() {
      const tableData = this.tableData;
      tableData.map((result) => {
        const commitmentAmount = result.commitmentAmount;
        const farmerComment = result.farmerComment;
        const commitmentYear = result.commitmentYear;

        this.isFormValid =
          commitmentAmount !== "" &&
          farmerComment.trim() !== "" &&
          commitmentYear.trim() !== "";
      });
    },

    async addFarmerCommitment() {
      console.log(this.farmerId);
      this.loading = true;

      if (this.isFormValid) {
        const farmerId = this.farmerId;
        const tableData = this.tableData;
        const getYearData = this.$store.getters["farmers/getFarmersCommitment"];

        let storedYear = getYearData.map((data) => {
          const jsonDate = data.year;
          const dateObject = new Date(jsonDate);
          const yearJson = dateObject.getFullYear();
          const storedYear = yearJson;
          return storedYear;
        });

        tableData.map((data) => {
          const year = data.commitmentYear;
          const isValueInArray = storedYear.some((element) => element == year);

          if (isValueInArray) {
            createToast("Year Already Exists", {
              type: "danger",
              timeout: 3000,
              position: "top-right",
            });
            this.showAddCommitment = true;
          } else {
            this.loading = true;

            console.log("tableData", this.tableData);

            for (const data of tableData) {
              const addCommitment = {
                year: data.commitmentYear,
                amount: data.commitmentAmount,
                farmerLastComment: data.farmerComment,
                farmerId: farmerId,
              };

              this.$store.dispatch(
                "farmers/addFarmerCommitment",
                addCommitment
              );
            }
            this.loading = false;
            createToast("Farmer commitment added successfully", {
              type: "success",
              position: "top-right",
              timeout: 3000,
              onClose: () => {
                this.showAddCommitment = false;
                this.closeModal();
                window.location.reload();
              },
            });
          }
        });
      } else {
        createToast("Please add all fields", {
          type: "danger",
          position: "top-right",
          timeout: 2000,
        });
      }

      this.loading = false;
    },
  },

  computed: {},
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  width: 500px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.delete-action-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  padding: 20px 0px;
}

p {
  font-size: 1.2rem;
  font-weight: 500;
  color: #232a34;
}

h5 {
  font-size: 17px;
  margin: 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-bottom: 40px;
}

.edit-commitment {
  position: fixed;
  top: 130%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: blue;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.edit-input {
  display: block;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 0px 20px;
}

.edit-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  border: #ccc 1px solid;
  height: 60px;
  margin: 0px 20px;
}
.cards {
  text-align: center;
  padding: 5px 25px;
  height: 40px;
}
.cards p {
  margin: 0px;
  display: block;
}
.cards span {
  margin: 0px;
  display: block;
  font-weight: bold;
  color: #282828;
}
.all {
  border-left: #ccc 1px solid;
}
.both {
  border-left: #ccc 1px solid;
  border-right: #ccc 1px solid;
}

input[type="text"] {
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 100%;
}
input[type="number"] {
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 100%;
}
input[type="text"]:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  outline: none;
}
input[type="number"]:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  outline: none;
}

.edit-input input[type="text"] {
  padding: 5px;
  border: 1px solid #353535;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}
.edit-input input[type="text"]:focus {
  border-color: #669933;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  outline: none;
}

.btn-commitment {
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #669933;
  width: 200px;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
}

.edit-footer-commitment {
  padding: 20px 0px;
}

.closeX {
  cursor: pointer;
}

.no-data {
  margin-top: 40px;
  padding: 20px;
  display: grid;
  place-items: center;
}

.enrol-farmer-img {
  width: 32px;
}
.enrol-farmer-p {
  color: #121212;
  margin: 20px 0;
  font-size: 12px;
}
.enrol-farmer-btn {
  color: #669933;
  font-size: 12px;
  padding: 10px 25px;
  background: #fff;
  border: #669933 1px solid;
  border-radius: 30px;
  cursor: pointer;
}

#data-table {
  border-collapse: collapse;
  width: 80%;
  margin: 20px auto;
}

#data-table th {
  border: 1px solid #ccc;
  text-align: center;
  height: 40px;
}
#data-table td {
  border: 1px solid #ccc;
  text-align: center;
}

#data-table th {
  background-color: #f0f0f0;
}
.trash-can {
  cursor: pointer;
}

select {
  padding: 8px;
  border: 1px solid rgba(64, 14, 172, 0);
  border-radius: 5px;
  background-color: #fff;
  font-size: 10px;
}
select:focus {
  outline: none;
  border-color: #ccc;
  box-shadow: 0px 2px 4px rgba(0, 123, 255, 0);
}

#add-row-button {
  width: 80px;
  color: #669933;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  left: 390px;
}
</style>