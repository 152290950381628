<template>
  <div class="dashboard-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <div class="dashboard-container">
      <section class="dashboard-metric">
        <div style="margin: 0 auto; width: 96%">
          <router-link to="/dashboard">
            <div class="dashboard-back">
              <InvertedBlackButton
                label="Go Back"
                width="5rem"
                height="2.6rem"
                padding="0px 0px"
              ></InvertedBlackButton>
            </div>
          </router-link>
          <div class="metric-header">
            <p class="metric-title-text"><b>Inventory</b> | {{ title }}</p>
          </div>
          <div class="border"></div>
          <div class="metric-data-range">
            <div class="metric-data-time-range">
              <div class="metric-toolbar">
                <span class="metric-data-caption">Range:</span>
                <div class="input-select">
                  <select
                    v-model="dateRange"
                    @change="changeP1DateAction($event)"
                  >
                    <option value="this_week">This Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="this_month">This Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="this_year">This Year</option>
                    <option value="last_year">Last year</option>
                  </select>
                </div>

                <div class="input">
                  <input
                    type="text"
                    name=""
                    id="date"
                    placeholder="Select End Date"
                    onfocus="(this.type = 'date')"
                    v-model="startDate"
                    @change="dateRangeChange"
                  />
                </div>

                <div class="input">
                  <input
                    type="text"
                    name=""
                    id="date"
                    placeholder="Select End Date"
                    onfocus="(this.type = 'date')"
                    v-model="endDate"
                    @change="dateRangeChange"
                  />
                </div>
              </div>
              <div class="metric-view-selector">
                <div class="input-select">
                  <select v-model="view" @change="setDataView">
                    <option value="quantity">Quantity</option>
                    <option value="value">Value</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="metric-subsection-nav">
            <div
              class="metric-subsections"
              :class="{ active: activeNav === factory }"
              @click="setActiveNav(factory)"
            >
              {{ factory }}
            </div>
            <div
              class="metric-subsections"
              :class="{ active: activeNav === APs }"
              @click="setActiveNav(APs)"
            >
              {{ APs }}
            </div>
            <div
              class="metric-subsections"
              :class="{ active: activeNav === farmgate }"
              @click="setActiveNav(farmgate)"
            >
              {{ farmgate }}
            </div>
            <div
              class="metric-subsections"
              :class="{ active: activeNav === allInField }"
              @click="setActiveNav(allInField)"
            >
              {{ allInField }}
            </div>
          </div>
          <hr />

          <div v-if="activeNav === factory">
            <div class="metric-kpi-block">
              <div class="metric-kpi-core">
                <ul class="kpi-block">
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="view === 'value' ? tonDispC + `M` : tonDisp"
                      kpiLabel="Tonnage Dispatched"
                    >
                    </StyledMetricText>
                  </li>
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="view === 'value' ? tonVerC + `M` : tonVer"
                      kpiLabel="Tonnage Verified"
                    >
                    </StyledMetricText>
                  </li>
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="view === 'value' ? varianceC + `K` : variance"
                      :kpiValue2="varPercent + '%'"
                      kpiColor1="#8fc54d"
                      kpiColor2="#8fc54d"
                      kpiLabel="Variance (Tonnage/Percentage)"
                    >
                    </StyledMetricText>
                  </li>
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="landedCostPerTon"
                      kpiColor1="#8fc54d"
                      kpiLabel="Landed Cost/Ton"
                    >
                    </StyledMetricText>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="activeNav === APs">
            <div class="metric-kpi-block">
              <div class="metric-kpi-core">
                <ul class="kpi-block">
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="view === 'value' ? tonAtAPC + 'M' : tonAtAP"
                      kpiLabel="Tonnage Dispatched"
                    >
                    </StyledMetricText>
                  </li>
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="view === 'value' ? tonAtAPC + 'M' : tonAtAP"
                      kpiLabel="Tonnage Verified"
                    >
                    </StyledMetricText>
                  </li>
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="view === 'value' ? varianceC + `K` : variance"
                      :kpiValue2="varPercent + '%'"
                      kpiColor1="#8fc54d"
                      kpiColor2="#8fc54d"
                      kpiLabel="Variance (Tonnage/Percentage)"
                    >
                    </StyledMetricText>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="activeNav === farmgate">
            <div
              class="metric-kpi-block"
              style="width: 25%; align-content: center"
            >
              <div class="metric-kpi-core">
                <StyledMetricText
                  :kpiValue1="view === 'value' ? tonUnmovedC + 'M' : tonUnmoved"
                  kpiLabel="Tonnage Unmoved"
                >
                </StyledMetricText>
              </div>
            </div>
          </div>

          <div v-if="activeNav === allInField">
            <div class="metric-kpi-block">
              <div class="metric-kpi-core">
                <ul class="kpi-block">
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="view === 'value' ? tonDispC + 'M' : tonDisp"
                      kpiLabel="Tonnage @Factory"
                    >
                    </StyledMetricText>
                  </li>
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="view === 'value' ? tonAtAPC + 'M' : tonAtAP"
                      kpiLabel="Tonnage @APs"
                    >
                    </StyledMetricText>
                  </li>
                  <li class="metric-kpi">
                    <StyledMetricText
                      :kpiValue1="
                        view === 'value' ? tonUnmovedC + 'M' : tonUnmoved
                      "
                      kpiLabel="Tonnage @Farmgate"
                    >
                    </StyledMetricText>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="chart-section">
            <div class="chart-left">
              <div class="chart-titles">
                <p
                  class="chart-title-text"
                  v-if="activeNav === factory || activeNav === APs"
                >
                  <b>
                    P1 at
                    <span v-if="activeNav === factory">Factory</span>
                    <span v-if="activeNav === APs">AP</span>
                  </b>
                </p>
                <p class="chart-title-text" v-if="activeNav === farmgate">
                  Total Unmoved at Farmgate
                </p>
                <p class="chart-title-text" v-if="activeNav === allInField">
                  Total Inventory
                </p>
              </div>
              <div class="chart-wrapper">
                <div class="chart-data" v-show="view === 'quantity'">
                  <BarChart2
                    :series="
                      activeNav === factory
                        ? verifedP1Data
                        : activeNav === APs
                        ? verifedP1Data
                        : activeNav === farmgate
                        ? unmovedData
                        : totalTonsData
                    "
                  />
                </div>
                <div class="chart-data" v-show="view === 'value'">
                  <BarChart2
                    :series="
                      activeNav === factory
                        ? verifedP1DataC
                        : activeNav === farmgate
                        ? unmovedDataC
                        : totalTonsData
                    "
                  />
                </div>
              </div>
            </div>
            <div class="chart-right">
              <div class="chart-titles">
                <p
                  class="chart-title-text"
                  v-if="activeNav === factory || activeNav === APs"
                >
                  <b>
                    Trailing P1 Inventory at
                    <span v-if="activeNav === factory">Factory</span>
                    <span v-if="activeNav === APs">APs</span>
                  </b>
                </p>
                <p class="chart-title-text" v-if="activeNav === farmgate">
                  Trailing P1 Inventory at Farmgate
                </p>
                <p class="chart-title-text" v-if="activeNav === allInField">
                  Trailing P1 Inventory
                </p>
              </div>
              <div class="chart-wrapper">
                <div class="chart-data">
                  <BarChart3
                    :series="
                      activeNav === factory
                        ? dispatchedAndVerifiedTons
                        : activeNav === farmgate
                        ? farmgateData
                        : allTonnes
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <router-view />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import StyledMetricText from "../../../components/metric-text/MetricText.vue";
import BarChart3 from "../charts/Barchart3.vue";
import BarChart2 from "../charts/BarChart2.vue";
import { convertToFilterQueryString } from "../../../utils";
import { formatNumber, roundToTwoDP } from "../../../utils/numbers-formatter";

export default {
  name: "InventoryDash",

  data() {
    return {
      collapsibleVisible: true,
      individualView: false,
      type: "",
      activeNav: null,
      factory: "Factory",
      APs: "APs",
      farmgate: "Farmgate",
      allInField: "Summary",
      dateRange: "this_week",
      tonDisp: "",
      tonVer: "",
      variance: "",
      varPercent: "",
      tonUnmoved: "",
      tonAtAP: "",
      tonDispC: "",
      tonVerC: "",
      varianceC: "",
      varPercentC: "",
      tonUnmovedC: "",
      tonAtAPC: "",
      factData: [],
      dispatchedAndVerifiedTons: [],
      farmgateData: [],
      verifedP1Data: [],
      unmovedData: [],
      totalTonsData: [],
      allTonnes: [],
      apData: "",
      startDate: "",
      endDate: "",
      title: "",
      view: "quantity",
      multiplier: 0,
      landedCostPerTon: 0,
      verifedP1DataC: [],
      unmovedDataC: [],
      loading: false,
      totalTonnes: 0,
    };
  },

  components: {
    InvertedBlackButton,
    StyledMetricText,
    Loading,
    BarChart3,
    BarChart2,
  },

  mounted() {
    this.fetchInventoryData();
    this.fetchP1MoveChartData();
    this.fetchunmovedP1Data();
    this.fetchInventoryTrailingDataPoints();
  },

  methods: {
    toggleCollasible() {
      this.collapsibleVisible = !this.collapsibleVisible;
    },

    checkType(event) {
      if (event.target.value === "individual") this.individualView = true;
      else this.individualView = false;
    },

    setActiveNav(nav) {
      this.activeNav = nav;
    },

    setDataView(event) {
      this.view = event.target.value;
      this.returnCurrencyData();
    },

    returnCurrencyData() {
      this.tonDispC = this.tonDisp * this.multiplier;
      this.tonDispC = roundToTwoDP(this.tonDispC / 1000000);
      this.tonVerC = roundToTwoDP(this.tonVer * this.multiplier);
      this.tonVerC = roundToTwoDP(this.tonVerC / 1000000);
      this.varianceC = roundToTwoDP(this.variance * this.multiplier);
      this.varianceC = roundToTwoDP(this.varianceC / 1000);
      this.tonUnmovedC = roundToTwoDP(this.tonUnmoved * this.multiplier);
      this.tonUnmovedC = roundToTwoDP(this.tonUnmovedC / 1000000);
      this.tonAtAPC = roundToTwoDP(this.tonAtAP * this.multiplier);
      this.tonAtAPC = roundToTwoDP(this.tonAtAPC / 1000);
      this.verifedP1DataC = [
        {
          name: "",
          data: [this.tonDispC, this.tonVerC],
          xAxis: ["Tonnes Dispatched", "Verified Tonnes"],
        },
      ];
      this.unmovedDataC = [
        {
          name: "",
          data: [this.tonUnmovedC],
          xAxis: ["P1 Quantity"],
        },
      ];
    },

    changeP1DateAction(event) {
      this.dateRange = event.target.value;
      this.fetchInventoryData(this.dateRange);
      this.fetchP1MoveChartData(this.dateRange);
      this.fetchunmovedP1Data(this.dateRange);
      this.fetchP1MoveChartDataDateRange(this.dateRange);
      this.fetchInventoryTrailingDataPoints(this.dateRange);
    },

    dateRangeChange() {
      if (this.startDate === "" || this.endDate === "") return;
      else if (this.startDate !== "" && this.endDate !== "") {
        const query = convertToFilterQueryString({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        this.fetchbyDateRange(query);
        this.fetchP1MoveChartDataDateRange(query);
        this.fetchunmovedP1DataDateRange(query);
        this.fetchInventoryTrailingDataPoints(query);
      } else return;
    },

    async fetchP1MoveChartData(query = "this_week") {
      const data = await this.$store.dispatch(
        "overviews/getInventoryAtFactoryChart",
        query
      );
      this.dispatchedAndVerifiedTons = data;
    },

    async fetchP1MoveChartDataDateRange(query = "this_week") {
      const data = await this.$store.dispatch(
        "overviews/getInventoryAtFactoryChartDateRange",
        query
      );
      this.factData = data;
    },

    async fetchInventoryData(query = "this_week") {
      this.loading = true;
      const data = await this.$store.dispatch(
        "overviews/getInventorySummary",
        query
      );
      this.loading = false;
      this.totalTonnes = roundToTwoDP(data.totalTonnes);
      this.landedCostPerTon = `₦` + formatNumber(data.totalLandedCostPerTon);
      this.multiplier =
        data.tonnesAtFactory > 0 ? data.amountPaid / data.tonnesAtFactory : 0;
      this.tonDisp = roundToTwoDP(data.tonnesAtFactory);
      this.tonVer = roundToTwoDP(data.verifiedTonnes);
      this.variance = (this.tonDisp - this.tonVer).toFixed(1);
      this.varPercent = isFinite(data.variance / data.tonnesAtFactory)
        ? ((data.variance / data.tonnesAtFactory) * 100).toFixed(1)
        : 0;
      this.tonUnmoved = roundToTwoDP(data.tonnesAtFarmGate);
      this.tonAtAP = data.tonnesAtAP.toFixed(1);
      this.title = data.period + " Data Report";
      const movedTonnes = [
        {
          name: "",
          data: [this.tonDisp, this.tonVer],
          xAxis: ["Tonnes Dispatched", "Verified Tonnes"],
        },
      ];

      console.log(this.tonVer);

      this.unmovedData = [
        {
          name: "",
          data: [this.tonUnmoved],
          xAxis: ["P1 Quantity"],
        },
      ];

      this.totalTonsData = [
        {
          name: "",
          data: [this.totalTonnes],
          xAxis: ["P1 Quantity"],
        },
      ];

      this.verifedP1Data = movedTonnes;
      this.returnCurrencyData();
    },

    async fetchInventoryTrailingDataPoints(query = "this_week") {
      const data = await this.$store.dispatch(
        "overviews/getOverviewP1TrailingData",
        query
      );
      this.allTonnes = data;
    },

    async fetchunmovedP1Data(query = "this_week") {
      const data = await this.$store.dispatch(
        "overviews/getunmovedInventory",
        query
      );
      this.farmgateData = data;
      console.log(this.farmgateData);
    },

    async fetchunmovedP1DataDateRange(query = "this_week") {
      const data = await this.$store.dispatch(
        "overviews/getunmovedInventoryDateRange",
        query
      );
      this.farmgateData = data;
    },

    async fetchbyDateRange(query) {
      try {
        const data = await this.$store.dispatch(
          "overviews/getOverviewInventoryDateRange",
          query
        );

        console.log(data);
        this.multiplier =
          data.tonnesAtFactory > 0 ? data.amountPaid / data.tonnesAtFactory : 0;
        this.landedCostPerTon = `₦` + formatNumber(data.totalLandedCostPerTon);
        this.tonDisp = roundToTwoDP(data.tonnesAtFactory);
        this.tonVer = roundToTwoDP(data.verifiedTonnes);
        this.variance = Math.abs(this.tonDisp - this.tonVer);
        this.varPercent = isFinite(data.variance / data.tonnesAtFactory)
          ? ((data.variance / data.tonnesAtFactory) * 100).toFixed(1)
          : 0;
        this.tonUnmoved = roundToTwoDP(data.tonnesAtFarmGate);
        this.tonAtAP = data.tonnesAtAP.toFixed(0);

        const verArray = [
          {
            name: "",
            data: [this.tonDisp, this.tonVer],
            xAxis: ["Tonnes Dispatched", "Verified Tonnes"],
          },
        ];

        console.log(this.tonVer);

        this.unmovedData = [
          {
            name: "",
            data: [this.tonUnmoved],
            xAxis: ["P1 Quantity"],
          },
        ];

        this.verifedP1Data = verArray;
        this.returnCurrencyData();
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    this.activeNav = this.$route.params.activeNav;
  },
};
</script>

<style scoped>
.active {
  color: #232a34 !important;
  font-weight: 700;
  border-bottom: 2px solid #669933;
  /* padding: 8px 16px; */
}
.dashboard-parent {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  margin: 0 auto;
  background: #ffffff;
}

.dashboard-back {
  margin-top: 25px;
  margin-bottom: 30px;
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-caption {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
}

.metric-title-text {
  font-size: 16px;
  font-weight: 300;
  color: #131313;
}

.metric-title-text b {
  font-weight: 800;
}

.image-metric {
  cursor: pointer;
}
.metric-data-range,
.metric-kpi-core,
.metric-kpi-collapsible,
.chart-wrapper {
  height: auto;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;
}

.metric-data-range {
  padding: 15px;
}

.metric-kpi-core {
  width: 60%;
  /* padding-left: 0px; */
  margin-right: 20px;
}

.metric-kpi-collapsible {
  width: 40%;
}

.metric-kpi-collapsible .metric-kpi {
  width: 33%;
}

/* .metric-kpi-collapsible {
  width: 40%;
} */

.metric-data-time-range {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.metric-toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.metric-data-caption {
  color: #333;
  font-weight: 400;
  /* font-size: 14px; */
}

.metric-kpi-block {
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.kpi-block {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  width: 100%;
}

.metric-kpi {
  list-style: none;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  width: 33%;
  /* text-align: center; */
}

.metric-kpi:not(:last-child) {
  border-right: 1px solid #a7a7a726;
}

.metric-collapse {
  display: inline;
  float: right;
  font-size: 12px;
  font-weight: 400;
}

.metric-subsection-nav {
  display: flex;
  width: 30%;
  justify-content: space-between;
  font-size: 13.5px;
  cursor: pointer;
}

.metric-subsections {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

hr {
  margin-top: 0;
  max-width: 500px;
  margin-left: 0;
}

.input-select {
  height: 30px;
  width: 110px;
}

.input {
  width: 110px;
  height: 30px;
}

select {
  width: 100%;
  height: 100%;
}

input {
  width: 100%;
  height: 100%;
}

.image-footer {
  width: 0px;
  height: 40px;
}

.month-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.month-caption-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.dashboard-chart {
  margin-top: 20px;
}

.timerange {
  display: flex;
  gap: 10px;
}

.location-range {
  display: flex;
  gap: 10px;
}

.chart-section {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
}

.chart-left,
.chart-right {
  width: 48%;
  align-items: center;
}

.chart-title-text {
  font-size: 14px;
  font-weight: 900;
  color: #131010;
}
.chart-wrapper {
  display: flex;
  padding: 30px;
  margin: 20px 0;
  font-size: 0.8rem;
}

.chart-toolbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.chart-view-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chart-search {
  display: flex;
  width: 30%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}
.chart-data {
  width: 100%;
  /* height: 400px; */
  /* border: 1px solid #e7e7e7; */
  /* border-radius: 4px; */
}

.border {
  border: 1px solid #e7e7e7;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

/* reset default styles */
a:hover {
  text-decoration: none;
}
</style>
