import { APIConfig } from "../../config/apiConfig";
import moment from "moment";

const formatData = (data) => {
  return data.map((cost) => ({
    _id: cost._id,
    noOfWeeks: cost.noOfWeeks,
    tollAmount: cost.tollAmount,
    month: moment(cost.month).format("MMMM-yyyy"),
    date: moment(cost.date).format("MM/DD/yyyy"),
    dateAdded: moment(cost.createdAt).format("MM/DD/yyyy"),
    createdBy: cost.agentDetails.first_name + " " + cost.agentDetails.last_name,
  }));
};

const state = () => ({
  tollsCosts: [],
  costsToBeUpdated: {},
  error: null,
});

const getters = {
  getCostsToBeUpdated: (state) => state.costsToBeUpdated,
};

const mutations = {
  populateTollsCosts(state, tollsCosts) {
    state.tollsCosts = tollsCosts;
  },

  setCostsToBeUpdated(state, costs) {
    state.costsToBeUpdated = costs;
  },
};

const actions = {
  findCostToBeUpdated({ commit, state }, costId) {
    let costs = state.tollsCosts.find((cost) => cost._id === costId);

    commit("setCostsToBeUpdated", costs);
  },

  async fetchTollsCosts({ commit }, params) {
    const { page, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/tolls/?page=${page}&limit=${limit}`
      );

      const tollsCosts = data?.data ? formatData(data?.data) : [];

      commit("populateTollsCosts", tollsCosts);

      return {
        count: data?.count,
        cost: tollsCosts,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async addNewCosts(_, payload) {
    try {
      const { data } = await APIConfig.post(`/tolls`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async filterByDatePeriod({ commit }, params) {
    const { page, limit, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `/tolls/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      if (Object.keys(data).length > 0) {
        const tollsCosts = formatData(data?.data);

        commit("populateDieselCosts", tollsCosts);

        return {
          count: data?.count,
          cost: tollsCosts,
        };
      } else {
        return {
          count: 0,
          cost: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async createTarget(_, payload) {
    try {
      const { data } = await APIConfig.post(`/tolls/target`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateCosts(_, payload) {
    try {
      const { data } = await APIConfig.patch(`/tolls/${payload.id}`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteCosts(_, id) {
    try {
      const { data } = await APIConfig.delete(`/tolls/${id}`);

      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
