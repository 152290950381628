<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Create UserType</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="border"></div>

    <section class="category-container">
      <form class="form">
        <TextInput
          placeholder="Enter User Type"
          fieldName="name"
          :inputValue="userTypeForm.name"
          @onInputChange="handleInputChange"
        />
      </form>
    </section>

    <div class="border"></div>

    <div class="button-container">
      <GreenButton
        label="Continue"
        width="7.5rem"
        height="2.9rem"
        @click="createUserType"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";

export default {
  name: "CreateUserTypeModal",
  components: {
    TextInput,
    GreenButton,
    Loading,
  },

  data() {
    return {
      loading: false,
      userTypeForm: {
        name: "",
      },
    };
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.userTypeForm = {
        ...this.userTypeForm,
        ...obj,
      };
    },

    async createUserType() {
      this.loading = true;

      try {
        const { name } = this.userTypeForm;

        if (!name) {
          createToast("Please enter a name", {
            type: "danger",
            position: "top-right",
            timeout: 3000,
          });
          this.loading = false;
          return;
        }

        console.log(this.userTypeForm, "userTypeForm");

        console.log({ name });

        const response = await this.$store.dispatch(
          "rolepermissions/createRole",
          name
        );

        if (response.id) {
          createToast("User Type created successfully", {
            type: "success",
            position: "top-right",
            timeout: 3000,
          });
          this.loading = false;

          window.location.reload();
          this.$emit("onCloseModal", false);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.x-container:hover {
  background: #e8e8e8;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.category-container {
  height: 200px;
  padding-top: 30px;
}
.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}
.category-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

p {
  font-size: 14px;
}
</style>
