<template>
  <div class="modal-parent">
    <Loading v-model:active="loading" :can-cancel="true" :is-full-page="true" />
    <section class="headers">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Delete {{ formatHeading }}</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="border"></div>

    <section class="delete-action">
      <div style="margin: 0 auto; width: 96%">
        <div class="delete-action-content">
          <p class="modal-caption">
            Are you sure you want to delete this item?
          </p>
        </div>

        <div class="button-container">
          <InvertedGreenButton
            label="Go Back"
            width="8.5rem"
            height="2.9rem"
            @click="closeModal"
          />
          <GreenButton
            label="Yes, Delete"
            width="10.5rem"
            height="2.9rem"
            @click="deleteItem"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import InvertedGreenButton from "../../../components/buttons/inverted-green-button/InvertedGreenButton.vue";
import { NameConvention } from "../../../utils/nameConvention";
export default {
  name: "DeleteModal",
  components: {
    GreenButton,
    InvertedGreenButton,
    Loading,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    formatHeading() {
      return this.heading.split(" ")[0];
    },
  },

  props: {
    itemId: {
      type: String,
      required: true,
    },

    heading: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
      this.$store.dispatch("assetManagement/updateDeleteModalStatus", false);
    },

    async deleteItem() {
      const nameConvention = new NameConvention(this.heading);
      const headingInCamelCase = nameConvention.camelize();
      const id =
        this.itemId ||
        this.$store.getters[`${headingInCamelCase}/getAssetToBeUpdated`]["_id"];

      this.loading = true;

      const data = await this.$store.dispatch(
        `${headingInCamelCase}/delete${this.heading.split(" ")[0]}`,
        id
      );

      if (data) {
        this.loading = false;
        createToast(`${this.heading.split(" ")[0]} deleted successfully`, {
          type: "success",
          position: "top-right",
          timeout: 3000,

          onClose: () => {
            this.$store.dispatch("assetManagement/setRefreshStatus", true);
            this.$emit("onCloseModal");
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: #fff;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.delete-action-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
}

p {
  font-size: 1.2rem;
  font-weight: 500;
  color: #232a34;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
</style>
