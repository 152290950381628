<template>
  <div class="login">
    <div class="login-container">
      <div class="wrapper">
        <div class="wrapper-left">
          <div class="transparent-border">
            <img
              src="../../assets/countryside-woman-out-field.png"
              class="login-image"
              alt="country-side"
            />
          </div>
        </div>

        <div class="wrapper-right">
          <div class="wrapper-right-container">
            <h1 class="login-heading">Login</h1>

            <form class="login-form" @submit="logIn">
              <fieldset>
                <!-- <label>Email</label> -->
                <div class="inputs">
                  <i class="fa fa-envelope"></i>
                  <input
                    type="email"
                    class="forms-control"
                    v-model="email"
                    id="email"
                    placeholder="Enter your email address"
                  />
                </div>
              </fieldset>

              <fieldset>
                <!-- <label>Password</label> -->
                <div class="inputs">
                  <i class="fa fa-lock" aria-hidden="true"></i>
                  <input
                    id="password"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    class="forms-control"
                    placeholder="Password"
                  />

                  <i
                    :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                    @click="togglePasswordState"
                  ></i>
                </div>
              </fieldset>

              <div class="button-links">
                <p>Forget Password?</p>
              </div>

              <button type="submit" class="button-login" @click="logIn">
                Login
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import firebase, { catchFirebaseAuthError } from "../../config/firebase";
import { IdConfig2 } from "../../config/idConfig";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      loading: false,
      errors: [],
    };
  },

  computed: {
    isFormEmpty() {
      const isEmpty = Boolean(this.email) && Boolean(this.password);
      return !isEmpty;
    },

    error() {
      return this.$store.getters["auth/getError"];
    },
  },

  methods: {
    togglePasswordState() {
      this.showPassword = !this.showPassword;
    },

    async logIn(e) {
      e.preventDefault();

      if (this.isFormEmpty) {
        this.errors.push("Please fill in all fields");
        return;
      }

      try {
        this.loading = true;
        // const isLoggedIn = await this.$store.dispatch("auth/login", {
        //   email: this.email,
        //   password: this.password,
        // });

        // if (isLoggedIn) {
        //   this.loading = false;
        //   createToast("Login Successful", {
        //     type: "success",
        //     position: "top-right",
        //     timeout: 3000,

        //     onClose: () => {
        //       this.$router.push("/dashboard");
        //     },
        //   });
        // }

        const firebaseResp = await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);

        if (firebaseResp) {
          const token = await firebaseResp.user.getIdToken();

          localStorage.setItem("userToken", token);

          this.$store.dispatch("auth/setAuth", true);

          const { data } = await IdConfig2.get(`/auth/currentUser`);

          if (data) {
            localStorage.setItem("user", JSON.stringify(data));
          }

          // this.$store.dispatch("auth/setCurrentUser", data);

          this.loading = false;

          createToast("Login Successful", {
            type: "success",
            position: "top-right",
            timeout: 3000,

            onClose: () => {
              this.$router.push("/dashboard");
            },
          });
        }
      } catch (error) {
        catchFirebaseAuthError(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    error(newValue) {
      createToast(newValue, {
        type: "danger",
        position: "top-right",
        timeout: 3000,
      });
    },
  },
};
</script>

<style scoped>
.login {
  width: 100vw;
  height: 100vh;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper-left {
  width: 60%;
  height: 100vh;
  background: url(../../assets/login_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #669933;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.wrapper-right {
  width: 40%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transparent-border {
  width: 40%;
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  background-blend-mode: lighten;
  border: 1px solid #ffffff;
  border-radius: 24px;
  z-index: 100;
}

.login-image {
  width: 70%;
  height: 80vh;
  margin-bottom: 7rem;
}

.wrapper-right-container {
  width: 70%;
  height: 80%;
  margin-top: 6rem;
}

.login-form {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.4rem;
}

fieldset {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #545454;
}

.inputs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.forms-control {
  border: none;
  outline: none;
  width: 100%;
  outline-color: none;
  background-color: none;
}

h1 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #545454;
}

.button-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  background: #545454;
  border-radius: 50px;
  color: #fff;
}

.button-links {
  font-size: 0.9rem;
  color: black;
  margin-left: 9.6rem;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
}
</style>
