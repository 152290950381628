<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add New Driver</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <TextInput
          placeholder="Name"
          @onInputChange="handleInputChange"
          fieldName="issue"
          :inputValue="driverForm?.name"
        />

        <TextInput
          placeholder="Email"
          @onInputChange="handleInputChange"
          fieldName="issue"
          :inputValue="driverForm?.email"
        />

        <TextInput
          placeholder="Phone Number"
          @onInputChange="handleInputChange"
          fieldName="issue"
          :inputValue="driverForm?.phonenum"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton width="12rem" label="Submit" @click="addImprestRecord" />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import GreenButton from "../../../../components/buttons/green-button/GreenButton.vue";
import TextInput from "../../../../components/form-inputs/text-input/TextInput.vue";

export default {
  name: "AddDriverModal",

  components: {
    GreenButton,
    TextInput,
    Loading,
  },

  data() {
    return {
      driverForm: {
        name: "",
        email: "",
        phonenum: "",
      },
      loading: false,

      isRefresh: false,
    };
  },

  mounted() {
    this.fetchVehicles();
  },

  methods: {
    async fetchVehicles(page = 1) {
      const { assets } = await this.$store.dispatch(
        "vehicleManagement/fetchVehicles",
        {
          page,
        }
      );

      this.vehicles = assets;
    },

    getVehicleNames(vehicles) {
      return vehicles.name;
    },
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.driverForm = {
        ...this.driverForm,
        ...obj,
      };

      console.log(this.driverForm);
    },
    handleInputChange(obj) {
      this.driverForm = {
        ...this.driverForm,
        ...obj,
      };
    },

    async addImprestRecord() {
      const { date, agent, amountPaid } = this.driverForm;
      if (!date || !agent || !amountPaid) {
        return createToast("All fields are required!", {
          type: "danger",
          timeout: 2000,
          position: "top-left",
        });
      }

      try {
        this.loading = true;
        const record = await this.$store.dispatch(
          "imprest/addNewRecord",
          this.driverForm
        );

        if (record) {
          this.loading = false;
          createToast("Imprest record added successfully!", {
            type: "success",
            timeout: 3000,
            position: "top-right",
            onClose: () => {
              this.$store.dispatch("users/setRefresh", true);
              this.closeModal();
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}
</style>
