<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { IdConfig2 } from "./config/idConfig";

export default {
  name: "App",
  data() {
    return {
      isAuth: this.$store.getters["auth/getAuth"],
      currentUser: this.$store.getters["auth/getCurrentUser"],
    };
  },

  mounted() {},

  methods: {
    async getCurrentUser() {
      const { data } = await IdConfig2.get(`/auth/currentUser`);
      localStorage.setItem("user", JSON.stringify(data));
    },
  },

  computed: {
    layout() {
      return "dashboardLayout";
    },
    getAuth() {
      return this.$store.getters["auth/getAuth"];
    },
  },
  watch: {
    getCurrentUser(newValue) {
      this.currentUser = newValue;
    },
  },
};
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: "Poppins", Arial, Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  width: 100%;
  height: 100vh;
}
</style>
