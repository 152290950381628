<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Add Repair Record</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <SelectDropdown
          :options="[
            'Preventive',
            'Corrective',
            'Part Purchase',
            'Equipment Purchase',
          ]"
          class="select"
          dropdownTitle="Maintenance Type"
          fieldName="maintenanceType"
          @onSelect="handleSelect"
          :inputValue="maintenanceForm?.maintenanceType"
        />

        <DateField
          placeholder="Date of Maintenance Action"
          @onInputChange="handleInputChange"
          fieldName="dateOfMaintenance"
          :inputValue="maintenanceForm?.dateOfMaintenance"
        />

        <Dropdown
          :options="vehicles"
          class="select"
          dropdownTitle="Vehicle"
          fieldName="vehicle"
          fieldValue="name"
          @onSelect="handleSelect"
          :inputValue="maintenanceForm?.name"
          v-if="!isLMVFieldHidden"
        />

        <DateField
          placeholder="Date Issue Occurred"
          @onInputChange="handleInputChange"
          fieldName="dateOfIssue"
          :inputValue="maintenanceForm?.dateOfIssue"
        />

        <TextAreaInput
          placeholder="Issue Description"
          @onInputChange="handleInputChange"
          fieldName="issueDescription"
          :inputValue="maintenanceForm?.issueDescription"
          :isSnakeCase="false"
        />
      </form>
    </section>

    <div class="button-container">
      <GreenButton
        width="12.8rem"
        label="Save and Continue"
        @click="routeToNextPage"
      />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";
import DateField from "../../../../components/form-inputs/date-input/DateField.vue";
import GreenButton from "../../../../components/buttons/green-button/GreenButton.vue";
import Dropdown from "../../../../components/form-inputs/select-dropdown/Dropdown.vue";
import SelectDropdown from "../../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import TextAreaInput from "../../../../components/form-inputs/text-area/TextAreaInput.vue";

export default {
  name: "AddMaintenanceModal",

  components: {
    GreenButton,
    Dropdown,
    DateField,
    SelectDropdown,
    TextAreaInput,
    Loading,
  },

  data() {
    return {
      maintenanceForm: this.$store.getters[
        "lMVRepairsAndMaintenanceCosts/getInputtedCosts"
      ] || {
        dateOfMaintenance: "",
        vehicle: "",
        dateOfIssue: "",
        issueDescription: "",
        maintenanceType: "",
      },
      globalInputtedDispatch: {},
      loading: false,
      vehicles: [],
      isRefresh: false,
      isLMVFieldHidden: false,
    };
  },

  mounted() {
    this.fetchVehicles();
  },

  methods: {
    async fetchVehicles(page = 1) {
      const { assets } = await this.$store.dispatch(
        "vehicleManagement/fetchVehicles",
        {
          page,
        }
      );

      this.vehicles = assets;
    },

    getVehicleNames(vehicles) {
      return vehicles.name;
    },
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleSelect(obj) {
      this.maintenanceForm = {
        ...this.maintenanceForm,
        ...obj,
      };
    },
    handleInputChange(obj) {
      this.maintenanceForm = {
        ...this.maintenanceForm,
        ...obj,
      };
    },

    routeToNextPage() {
      const {
        dateOfMaintenance,
        dateOfIssue,
        issueDescription,
        maintenanceType,
        vehicle,
      } = this.maintenanceForm;
      if (
        !dateOfMaintenance ||
        !dateOfIssue ||
        !issueDescription ||
        !maintenanceType ||
        !vehicle
      ) {
        return createToast("All fields are required!", {
          type: "danger",
          timeout: 2000,
          position: "top-left",
        });
      }
      this.$emit("onSetAddModalPage", 2);

      this.globalInputtedDispatch =
        this.$store.getters["lMVRepairsAndMaintenanceCosts/getInputtedCosts"];
      this.globalInputtedDispatch = {
        ...this.globalInputtedDispatch,
        ...this.maintenanceForm,
      };

      this.$store.dispatch(
        "lMVRepairsAndMaintenanceCosts/setInputtedCosts",
        this.globalInputtedDispatch
      );
    },
  },

  watch: {
    maintenanceForm: {
      handler: function (newValue) {
        if (newValue.maintenanceType === "Equipment Purchase") {
          this.isLMVFieldHidden = true;
        } else {
          this.isLMVFieldHidden = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% auto 2% auto;
}
</style>
