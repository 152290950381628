import { APIConfig } from "../../config/apiConfig";
import moment from "moment";

const formatDieselCosts = (data) => {
  return data.map((cost) => ({
    _id: cost._id,
    vehicle: cost.vehicle?._id,
    vehicleName: cost.vehicle?.name,
    fuelType: cost.fuelType,
    costPerLitre: cost.costPerLitre,
    noOfLitres: cost.noOfLitres,
    amountPaid: cost.amountPaid,
    date: moment(cost.date).format("MM/DD/yyyy"),
    dateAdded: moment(cost.createdAt).format("MM/DD/yyyy"),
    createdBy: cost.agentDetails.first_name + " " + cost.agentDetails.last_name,
  }));
};

const state = () => ({
  dieselCosts: [],
  dieselCostsToBeUpdated: {},
  error: null,
});

const getters = {
  getCostsToBeUpdated: (state) => state.dieselCostsToBeUpdated,
};

const mutations = {
  populateDieselCosts(state, dieselCosts) {
    state.dieselCosts = dieselCosts;
  },

  setDieselCostsToBeUpdated(state, dieselCosts) {
    state.dieselCostsToBeUpdated = dieselCosts;
  },
};

const actions = {
  findCostToBeUpdated({ commit, state }, dieselCostId) {
    let dieselCosts = state.dieselCosts.find(
      (cost) => cost._id === dieselCostId
    );

    commit("setDieselCostsToBeUpdated", dieselCosts);
  },

  async fetchDieselCosts({ commit }, params) {
    const { page, limit } = params;
    try {
      const { data } = await APIConfig.get(
        `/diesel/?page=${page}&limit=${limit}`
      );

      const dieselCosts = data?.data ? formatDieselCosts(data?.data) : [];

      commit("populateDieselCosts", dieselCosts);

      return {
        count: data?.count || 0,
        cost: dieselCosts || [],
      };
    } catch (error) {
      console.log(error);
    }
  },

  async addDieselCosts(_, payload) {
    try {
      const { data } = await APIConfig.post(`/diesel`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async createTarget(_, payload) {
    try {
      const { data } = await APIConfig.post(`/diesel/target`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async filterByDatePeriod({ commit }, params) {
    const { page, limit, filterQuery } = params;
    try {
      const { data } = await APIConfig.get(
        `/diesel/date-range?page=${page}&limit=${limit}&${filterQuery}`
      );

      let response = {
        cost: [],
        count: 0,
        query: filterQuery,
      };

      if (Object.keys(data).length > 0) {
        const dieselCosts = formatDieselCosts(data?.data);

        commit("populateDieselCosts", dieselCosts);

        response.cost = dieselCosts;
        response.count = data?.count;

        return response;
      } else {
        return {
          count: 0,
          cost: [],
        };
      }
    } catch (error) {
      console.log(error);
    }
  },

  async updateDieselCosts(_, payload) {
    try {
      const { data } = await APIConfig.patch(`/diesel/${payload.id}`, payload);

      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteCosts(_, id) {
    try {
      const { data } = await APIConfig.delete(`/diesel/${id}`);

      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
