<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Procurement details</h5>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <TextInput
          fieldName="palmKernelPricePerTonne"
          :isSnakeCase="false"
          placeholder="Current Palm Kernel Price/T Buying Price"
          :inputValue="procurementDetailsForm.palmKernelPricePerTonne"
          @onInputChange="handleInputChange"
        />
        <TextInput
          fieldName="monthlyPalmKernelTarget"
          :isSnakeCase="false"
          placeholder="Monthly Palm Kernel Procurement Target (T)"
          :inputValue="procurementDetailsForm.monthlyPalmKernelTarget"
          @onInputChange="handleInputChange"
        />
        <TextInput
          fieldName="palmKernelImpurityThreshold"
          :isSnakeCase="false"
          placeholder="Impurity Threshold Of Palm Kernel Purchased"
          :inputValue="procurementDetailsForm.palmKernelImpurityThreshold"
          @onInputChange="handleInputChange"
        />
        <SelectDropdown
          :options="['V1']"
          class="select"
          fieldName="processingLocation"
          dropdownTitle="Processing Location"
          @onSelect="handleSelect"
          :inputValue="procurementDetailsForm?.processingLocation"
        />
      </form>
    </section>

    <!-- border -->
    <div class="border"></div>

    <div class="button-container">
      <GreenButton label="Save" @click="saveProcurementDetails" />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";

export default {
  name: "ProcurementDetailsForm",

  components: {
    TextInput,
    SelectDropdown,
    GreenButton,
    Loading,
  },

  data() {
    return {
      procurementDetailsForm: this.$store.getters[
        "procurementDetails/getProcurementDetails"
      ] || {
        palmKernelPricePerTonne: 0,
        monthlyPalmKernelTarget: 0,
        palmKernelImpurityThreshold: 0,
        processingLocation: "",
      },
      loading: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.procurementDetailsForm = {
        ...this.procurementDetailsForm,
        ...obj,
      };
    },

    handleSelect(obj) {
      this.procurementDetailsForm = {
        ...this.procurementDetailsForm,
        ...obj,
      };
    },

    async saveProcurementDetails() {
      const payload = {
        ...this.procurementDetailsForm,
        palmKernelPricePerTonne: Number(
          this.procurementDetailsForm["palmKernelPricePerTonne"]
        ),
        monthlyPalmKernelTarget: Number(
          this.procurementDetailsForm["monthlyPalmKernelTarget"]
        ),
        palmKernelImpurityThreshold: Number(
          this.procurementDetailsForm["palmKernelImpurityThreshold"]
        ),
      };

      this.loading = true;
      let resp = {};

      try {
        if (payload._id) {
          resp = await this.$store.dispatch(
            "procurementDetails/updateCustomerProcurementDetails",
            payload
          );

          if (resp._id) {
            this.loading = false;
            createToast("Procurement Details saved successfully!", {
              type: "success",
              timeout: 3000,
              position: "top-right",
              onClose: () => {
                this.$store.dispatch(
                  "procurementDetails/getCustomerProcurementDetails"
                );
                this.closeModal();
              },
            });
          }

          return;
        }

        resp = await this.$store.dispatch(
          "procurementDetails/createCustomerProcurementDetails",
          payload
        );

        if (resp._id) {
          this.loading = false;
          createToast("Procurement Details saved successfully!", {
            type: "success",
            timeout: 3000,
            position: "top-right",
            onClose: () => {
              this.$store.dispatch(
                "procurementDetails/getCustomerProcurementDetails"
              );
              this.closeModal();
            },
          });
        }

        // console.log(resp);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: auto;
}

.form {
  margin: 5% auto;
  width: 80%;
  height: 70%;
}

.button-container {
  width: 50%;
  margin: 1% auto 4% auto;
}
</style>
