<template>
  <div class="mills-parent">
    <div class="mills-wrapper">
      <div class="tabss">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              >Analytics</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              >Table</a
            >
          </li>
        </ul>
      </div>
      <div class="tab-content" id="myTabContent">
        <!-- Analytics -->
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div class="container mt-5">
            <div class="column-1">
              <div class="card ctop">
                <h2 class="ml-3">34567</h2>
                <p class="ml-3 desc">All - Time</p>
                <div class="card-sub">
                  <p class="ml-3 pdesc">Total number of unique presser</p>
                </div>
              </div>
              <div class="card cmiddle mt-3">
                <h2 class="ml-3">3.5%</h2>
                <p class="ml-3 desc">% Sell Now/Session</p>
                <div class="card-sub">
                  <p class="ml-3 pdesc">Total No: 1344</p>
                </div>
              </div>
              <div class="card cbottom mt-3">
                <h2 class="ml-3">10.4%</h2>
                <p class="ml-3 desc">% Cracking/Session</p>
                <div class="card-sub">
                  <p class="ml-3 pdesc">Total No: 5644</p>
                </div>
              </div>
            </div>
            <div class="column-2">
              <!-- Content for column 2 goes here -->
              <div class="analytics">
                <!-- Top Nav area -->
                <div class="mt-5 topNav">
                  <nav class="navbar">
                    <!-- left nav -->
                    <div class="navbar-left">
                      <select id="dropdown" class="table-select">
                        <option value="option1">Percentage</option>
                        <option value="option2">Number</option>
                      </select>
                    </div>
                    <!-- right nav -->
                    <div class="navbar-right">
                      <label class="line" for="dropdown">Range:</label>
                      <select id="dropdown" class="analytics-select">
                        <option value="option1">Yearly</option>
                        <option value="option2">Month</option>
                      </select>
                    </div>
                  </nav>
                </div>
                <!-- <ApexCharts /> -->
                <apexchart
                  width="100%"
                  height="300px"
                  :options="options"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <!-- End Analytics -->

        <div
          class="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div class="table mt-4">
            <!-- TAB SELECTOR -->
            <Tabs :tabs="tabs">
              <template #tab1>
                <!-- USSD TAB SECTION -->
                <div>
                  <h2>USSD Dailed Session</h2>
                  <section class="date-filter-container">
                    <div class="wrapper">
                      <p>Search by Period</p>

                      <div class="controls-container">
                        <div class="inputs-container">
                          <div class="input">
                            <input
                              type="text"
                              name=""
                              id="date"
                              placeholder="Select Start Date"
                              onfocus="(this.type = 'date')"
                              v-model="startDate"
                            />
                          </div>

                          <div class="input">
                            <input
                              type="text"
                              name=""
                              id="date"
                              placeholder="Select End Date"
                              onfocus="(this.type = 'date')"
                              v-model="endDate"
                            />
                          </div>

                          <GreenButton
                            label="Fetch"
                            width="5rem"
                            height="2rem"
                            @click="fetchByDatePeriod(page)"
                          />
                        </div>

                        <div
                          v-if="itemsSelected.length > 0"
                          class="multi-actions"
                        >
                          <i
                            class="fa fa-trash"
                            style="font-size: 24px; color: red; cursor: pointer"
                            @click="openDeleteModal"
                          ></i>

                          <GreenButton
                            label="Approve"
                            width="5rem"
                            height="2rem"
                            @click="openApprovalModal"
                          />
                        </div>

                        <div class="buttons-container">
                          <div class="">
                            <download-excel :data="millsJSON">
                              <InvertedBlackButton
                                label="Export"
                                width="8rem"
                                height="2.6rem"
                                iconName="excel-icon"
                              >
                              </InvertedBlackButton>
                            </download-excel>
                          </div>

                          <div class="">
                            <BlackButton
                              label="Open Filter"
                              width="10rem"
                              height="2.6rem"
                              iconName="filter-icon"
                              @click="openFilterModal"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section class="data-table-container">
                    <EasyDataTable
                      v-model:server-options="paginationMetaData"
                      v-model:items-selected="itemsSelected"
                      :server-items-length="count"
                      :headers="headerUSSD"
                      :items="dataUSSD"
                      alternating
                      table-class-name="customize-table"
                      class="data-table"
                      :loading="loading"
                      buttons-pagination
                    >
                      <template #item-status="item">
                        <div class="status-wrapper">
                          <div
                            class="status"
                            :class="[
                              item.status === 'Completed'
                                ? 'completed'
                                : item.status === 'Pending'
                                ? 'pending'
                                : 'rejected',
                            ]"
                          >
                            {{ item.status }}
                          </div>
                        </div>
                      </template>
                      <template #item-actions="item">
                        <div class="actions-wrapper">
                          <div class="" @click="toggleActionsUSSD(item)">
                            <div
                              class="action-options"
                              v-if="
                                isActionsOpened &&
                                item.supplyID === itemClickedUSSD
                              "
                            >
                              <div class="item-wrapper">
                                <!-- Call farmer  -->
                                <div class="actions-item">
                                  <div class="image">
                                    <img
                                      src="../../assets/phone-solid.svg"
                                      alt=""
                                      class="action-icon green-eye"
                                    />
                                  </div>
                                  <p class="view-action">Call farmer</p>
                                </div>
                                <hr />
                                <!-- convert to supply request  -->
                                <div
                                  @click="openEditCommentModal"
                                  class="actions-item"
                                >
                                  <div class="image">
                                    <img
                                      src="../../assets/arrow-right-arrow-left-solid.svg"
                                      alt=""
                                      class="action-icon green-eye"
                                    />
                                  </div>
                                  <p class="view-action">
                                    convert to supply request
                                  </p>
                                </div>
                                <hr />
                                <!-- Decline Request  -->
                                <div class="actions-item">
                                  <div class="image">
                                    <img
                                      src="../../assets/circle-stop-solid.svg"
                                      alt=""
                                      class="action-icon green-eye"
                                    />
                                  </div>
                                  <p class="view-action">Decline Request</p>
                                </div>
                              </div>
                            </div>
                            <span>&#8942;</span>
                          </div>
                        </div>
                      </template>
                    </EasyDataTable>
                  </section>
                </div>
              </template>

              <!-- SUPPLY DETAIL TAB -->
              <template #tab2>
                <div>
                  <h2>Supply Request List</h2>
                  <section class="date-filter-container">
                    <div class="wrapper">
                      <p>Search by Period</p>

                      <div class="controls-container">
                        <div class="inputs-container">
                          <div class="input">
                            <input
                              type="text"
                              name=""
                              id="date"
                              placeholder="Select Start Date"
                              onfocus="(this.type = 'date')"
                              v-model="startDate"
                            />
                          </div>

                          <div class="input">
                            <input
                              type="text"
                              name=""
                              id="date"
                              placeholder="Select End Date"
                              onfocus="(this.type = 'date')"
                              v-model="endDate"
                            />
                          </div>

                          <GreenButton
                            label="Fetch"
                            width="5rem"
                            height="2rem"
                            @click="fetchByDatePeriod(page)"
                          />
                        </div>

                        <div
                          v-if="itemsSelected.length > 0"
                          class="multi-actions"
                        >
                          <i
                            class="fa fa-trash"
                            style="font-size: 24px; color: red; cursor: pointer"
                            @click="openDeleteModal"
                          ></i>

                          <GreenButton
                            label="Approve"
                            width="5rem"
                            height="2rem"
                            @click="openApprovalModal"
                          />
                        </div>

                        <div class="buttons-container">
                          <div class="">
                            <download-excel :data="millsJSON">
                              <InvertedBlackButton
                                label="Export"
                                width="8rem"
                                height="2.6rem"
                                iconName="excel-icon"
                              >
                              </InvertedBlackButton>
                            </download-excel>
                          </div>

                          <div class="">
                            <BlackButton
                              label="Open Filter"
                              width="10rem"
                              height="2.6rem"
                              iconName="filter-icon"
                              @click="openFilterModal"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section class="data-table-container">
                    <EasyDataTable
                      v-model:server-options="paginationMetaData"
                      v-model:items-selected="itemsSelected"
                      :server-items-length="count"
                      :headers="header"
                      :items="data"
                      alternating
                      table-class-name="customize-table"
                      class="data-table"
                      :loading="loading"
                      buttons-pagination
                    >
                      <template #item-status="item">
                        <div class="status-wrapper">
                          <div
                            class="status"
                            :class="[
                              item.status === 'Sell Now'
                                ? 'completed'
                                : item.status === 'Cracking'
                                ? 'pending'
                                : 'rejected',
                            ]"
                          >
                            {{ item.status }}
                          </div>
                        </div>
                      </template>
                      <template #item-actions="item">
                        <div class="actions-wrapper">
                          <div class="">
                            <span
                              style="font-size: 14px"
                              @click="openSupplyDetailsSession(item)"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 576 512"
                              >
                                <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                  d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                />
                              </svg>
                              view</span
                            >
                          </div>
                        </div>
                      </template>
                    </EasyDataTable>
                  </section>
                </div>
              </template>
            </Tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- USSD Request Supply Modal Section -->
    <div class="farmer-modal" v-if="isAddFarmerModalOpened">
      <ConvertSupplyModal1
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 1"
      />
      <ConvertSupplyModal2
        @onCloseModal="handleCloseModal"
        @onSetAddModalPage="handleAddModalPage"
        v-if="modalPageNum === 2"
      />
    </div>

    <!-- Request supply modal Section -->
    <div class="addSupplyDetails" v-if="showSupplyModal">
      <AddSupplyRequestModal @onCloseModal="handleCloseModal" />
    </div>

    <!-- Overlay -->
    <div
      class="dark-overlay"
      v-if="showSupplyModal || isAddFarmerModalOpened"
    ></div>
  </div>
</template>

<script>
import BlackButton from "../../components/buttons/black-button/BlackButton.vue";
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import { convertToFilterQueryString } from "../../utils";
import {
  supplyHeaders,
  dummyData,
  ussdHeaders,
  dummyDataUSSD,
} from "./header/headers";
import VueApexCharts from "vue-apexcharts";
import AddSupplyRequestModal from "./modals/SupplyDetailsModal.vue";
import Tabs from "../supplyValidation/modals/Tabs.vue";
import ConvertSupplyModal1 from "./modals/convert-supply-request-modals/ConvertSupplyModal1.vue";
import ConvertSupplyModal2 from "./modals/convert-supply-request-modals/ConvertSupplyModal2.vue";

export default {
  name: "SupplyValidation",
  components: {
    GreenButton,
    BlackButton,
    InvertedBlackButton,
    AddSupplyRequestModal,
    Tabs,
    ConvertSupplyModal1,
    ConvertSupplyModal2,
  },

  data() {
    return {
      tabs: [
        { label: "USSD Dailed Session", slotName: "tab1" },
        { label: "Request Supply", slotName: "tab2" },
      ],
      VueApexCharts,
      options: {
        chart: {
          id: "vuechart-example",
          type: "line",
          background: "#fff",
          foreColor: "#333",
        },
        xaxis: {
          categories: [2017, 2018, 2019, 2020, 2021, 2022, 2023],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 58, 60],
        },
        {
          name: "series-2",
          data: [30, 80, 45, 20, 49, 55, 70],
        },
      ],
      header: supplyHeaders,
      data: dummyData,
      headerUSSD: ussdHeaders,
      dataUSSD: dummyDataUSSD,
      millsList: [],
      itemsSelected: [],
      selectedMillsIds: [],
      count: 0,
      searchTerm: "",
      startDate: "",
      endDate: "",
      paginationMetaData: {
        page: 1,
        rowsPerPage: 10,
      },
      isActionOpened: false,
      farmer: {},
      itemClickedUSSD: "",
      itemClicked: "",
      millsJSON: [],
      loading: false,
      isActionsOpened: false,
      isMillsDetailsModalOpened: false,
      isAddMillOpened1: false,
      isAddMillOpened2: false,
      isEditMillsModalOpened: false,
      isFilterMillsModalOpened: false,
      isDeleteMillsModalOpened: false,
      isApprovalStatus: false,
      modalPageNum: 1,
      globalFilterQUery: "",
      showSupplyModal: false,
      showTextArea: false,
      selectedOption: "",
      isAddFarmerModalOpened: false,
    };
  },

  mounted() {
    this.fetchAllMills();
  },

  methods: {
    async fetchFarmer() {
      const farmerId = this.farmerId;
      this.loading = true;

      try {
        const farmer = await this.$store.dispatch(
          "farmers/fetchOneFarmer",
          farmerId
        );
        this.loading = false;

        this.farmer = {
          ...this.farmer,
          ...farmer,
        };
        console.log(farmer);
      } catch (error) {
        console.log(error.message);
      }
    },

    handleOptionChange() {
      console.log("Selected option:", this.selectedOption);
      if (this.selectedOption == "yes") {
        this.showTextArea = true;
      } else {
        this.showTextArea = false;
      }
    },

    handleAddModalPage(pageNum) {
      this.modalPageNum = pageNum;
    },
    openEditCommentModal() {
      this.isAddFarmerModalOpened = !this.isAddFarmerModalOpened;
    },
    openSupplyDetailsSession(arg) {
      this.showSupplyModal = !this.showSupplyModal;
      console.log(arg);
      console.log("im clicked at supply");
    },

    openDetailsModal() {
      this.isMillsDetailsModalOpened = !this.isMillsDetailsModalOpened;
    },

    handleCloseModal(status) {
      this.isAddFarmerModalOpened = status;
      this.showSupplyModal = status;
      this.isMillsDetailsModalOpened = status;
      this.isAddMillOpened1 = status;
      this.isAddMillOpened2 = status;
      this.isEditMillsModalOpened = status;
      this.isFilterMillsModalOpened = status;
      this.isDeleteMillsModalOpened = status;
      this.isApprovalStatus = status;
    },

    openAddMillModal() {
      this.isAddMillOpened1 = !this.isAddMillOpened1;
    },

    handleAddMillsModal(pageNum) {
      this.modalPageNum = pageNum;
      this.isAddMillOpened2 = !this.isAddMillOpened2;
    },

    openAddMillModal2() {
      this.isAddMillOpened2 = !this.isAddMillOpened2;
    },

    handleEdit(id) {
      this.isEditMillsModalOpened = !this.isEditMillsModalOpened;
      this.$store.dispatch("mills/findMillToBeUpdate", id);
    },

    openEditModal() {
      this.isEditMillsModalOpened = !this.isEditMillsModalOpened;
    },

    openFilterModal() {
      this.isFilterMillsModalOpened = !this.isFilterMillsModalOpened;
    },

    openDeleteModal() {
      this.isDeleteMillsModalOpened = !this.isDeleteMillsModalOpened;
    },

    openApprovalModal() {
      this.isApprovalStatus = !this.isApprovalStatus;
    },

    toggleActionsUSSD(arg) {
      this.itemClickedUSSD = arg.supplyID;
      console.log(arg);
      this.isActionsOpened = !this.isActionsOpened;
    },

    toggleActionsData(arg) {
      console.log(arg);
    },

    async fetchAllMills(page = 1, searchTerm = "") {
      this.loading = true;
      try {
        const { mills, count } = await this.$store.dispatch("mills/getMills", {
          page,
          searchTerm,
        });

        console.log({ mills });

        this.millsList = mills;

        this.millsJSON = mills;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async handleSearchText(e) {
      const pageNum = 1;
      await this.fetchAllMills(pageNum, e.target.value);
    },

    async fetchByDatePeriod(page = 1) {
      if (!this.startDate || !this.endDate) {
        return;
      }

      const query = convertToFilterQueryString({
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.loading = true;

      try {
        const { mills, count } = await this.$store.dispatch(
          "mills/filterMillsDatePeriod",
          {
            page,
            filterQuery: query,
          }
        );

        this.millsList = mills;
        this.millsJSON = mills;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async filterMills(page, filterQuery) {
      try {
        this.loading = true;

        const { filteredMills, count } = this.$store.dispatch(
          "mills/filterMills",
          {
            page,
            query: filterQuery,
          }
        );

        this.millsList = filteredMills;
        this.millsJSON = filteredMills;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  computed: {
    filterMetaData() {
      return this.$store.getters["mills/getFilteredMillMetaData"];
    },
  },

  watch: {
    paginationMetaData: {
      handler: function (newValue) {
        // this.fetchAllMills(newValue.page);
        const isFilter = this.$store.getters["mills/getFilterStatus"];

        const isDateFilter = this.$store.getters["mills/getIsDateFilterStatus"];

        if (isFilter) {
          this.filterMills(newValue.page, this.globalFilterQUery);
        } else if (isDateFilter) {
          this.fetchByDatePeriod(newValue.page);
        } else {
          this.fetchAllMills(newValue.page);
        }
      },
    },

    millsList(newValue) {
      this.millsJSON = newValue;
      this.millsList = newValue;
    },

    filterMetaData(newValue) {
      const { filteredMills, count, query } = newValue;

      this.globalFilterQuery = query;
      this.millsList = filteredMills;
      this.millsJSON = filteredMills;
      this.count = count;
    },

    itemsSelected: {
      handler: function (newValue) {
        this.selectedMillsIds = newValue.map((mill) => mill.id);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.mills-parent {
  width: 100%;
  margin: 0 auto;
  background: #f9fafe;
  height: 100vh;
  overflow-y: scroll;
}

.mills-wrapper {
  width: 95%;
  margin: 1% auto 0 auto;
  height: 100%;
}

.mills-details-modal {
  max-width: 800px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.mills-add-modal-1,
.mills-edit-modal {
  max-width: 550px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.filter-modal {
  position: absolute;
  top: 0px;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  animation: slideFromRightToLeft 1s ease-in-out;
  animation-delay: 10ms;
  left: 72%;
  width: 450px;
}

.mills-delete-modal {
  max-width: 500px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.mills-search-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}
.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.multi-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
}

.date-filter-container {
  width: 100%;
  margin-top: 3%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 87%;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.data-table-container {
  width: 100%;
  height: 79.9%;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;
  width: 127px;
  height: 32px;
  border-radius: 41px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.completed {
  color: #669933;
  background: rgba(102, 153, 51, 0.15);
}

.rejected {
  color: #ed0a0a;
  background: rgba(237, 10, 10, 0.15);
}

.pending {
  color: #f19306;
  background: rgba(241, 147, 6, 0.15);
}

.actions-item {
  display: flex;
  cursor: pointer;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.view-action {
  color: #669933;
}

.edit-action {
  color: #ea9a00;
}

.delete-action {
  color: #ed0a0a;
}

.item-wrapper {
  width: 70%;
  margin: auto auto;
}

.actions-item {
  display: flex;
  cursor: pointer;
}

.actions-item p {
  margin: 0px;
  color: black;
}

.actions-item p:hover {
  color: #777777;
}

.action-icon {
  margin-right: 5px;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  position: absolute;
  width: 150%;
  right: 135px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}

.customize-table {
  --easy-table-header-item-padding: 10px 15px;
  --easy-table-header-background-color: #cfd0d5;
  --easy-table-body-item-padding: 15px 15px;
  --easy-table-body-even-row-background-color: #fff;
  --easy-table-body-row-background-color: #fff;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: #222a34;
}
div.nav-link {
  cursor: pointer;
}

div.tab-pane h2 {
  color: #222a34;
}

.container {
  padding: 0px;
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-gap: 20px;
}

.column-1 {
  box-sizing: border-box;
}
div.card {
  margin-bottom: 20px;
  border: #fff 1px solid;
  padding-top: 15px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: left;
}
.desc {
  font-size: 13px;
  color: #222a34;
  padding-top: 5px;
  padding-bottom: 15px;
}
.pdesc {
  color: #222a34;
}
div.ctop {
  border-left: #222a34 8px solid;
}
div.cmiddle {
  border-left: #8fc54e 8px solid;
}
div.cbottom {
  border-left: #ea9a01 8px solid;
}
div.card-sub {
  border-top: #abb5c1 solid 1px;
  width: 210px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 40px;
}
div.card h2,
div.card p {
  margin: 0px;
}

.column-2 {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left label {
  margin: 0px;
  margin-right: 10px;
  width: 150px;
}

.navbar-left label.line {
  margin: 0px;
  margin-right: 10px;
  width: 50px;
}

.navbar-left select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styles for the right side of the navbar */
.navbar-right {
  display: flex;
  align-items: center;
}

select.analytics-select {
  background: none;
  border: 1px solid #ccc;
  padding: 10px 5px;
  border-radius: 4px;
}

select.table-select {
  background: none;
  border: 1px solid #ccc;
  padding: 10px 5px;
  border-radius: 4px;
}

select::-ms-expand {
  display: none;
}

select::after {
  content: "\25BC";
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

hr {
  margin: 0px;
}
.actions-item p {
  font-size: 10px;
}
.green-eye {
  width: 14px;
}

section.edit-commitment {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: blue;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 999;

  /* max-height: 350px; */
  overflow: auto;
}

.edit-header-commitment {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #232a34;
  border-bottom: #d7d7d7 1px solid;
}

.edit-header-commitment h4 {
  font-size: 15px;
  margin-left: 10px;
  margin-bottom: 0px;
}
.edit-header-commitment img {
  width: 32px;
  margin-right: 300px;
  margin-left: 190px;
}

.edit-header-commitment p {
  font-size: 12px;
  margin: 0px;
  margin-left: 20px;
}

.edit-footer-commitment {
  border-top: #d7d7d7 1px solid;
  padding: 10px 30px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.btn-commitment {
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #669933;
  width: 150px;
  margin-left: 20px;
  /* margin: 0 auto; */
  border-radius: 25px;
}

.edit-body-commitment {
  margin: 20px 0px;
  z-index: 2;
}
.edit-input {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0px 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.dropdown {
  padding: 0px 20px;
  width: 100%;
}

.dropdown select {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  font-size: 12px;
  color: #999;
  border-color: #3c3c3c;
}

.radio-section {
  margin: 0px 20px;
}
.radio-section p {
  margin: 0px;
}
.radio-section label {
  margin-top: 10px;
  margin-right: 10px;
}

.textarea-section {
  margin: 0px 20px;
  margin-bottom: 20px;
  width: 100%;
}

.textarea-section textarea {
  width: 92%;
  border: 1px solid #333333;
  border-radius: 5px;
  resize: vertical;
  font-family: Arial, sans-serif;
}

.textarea-container textarea:focus {
  border-color: #007bff;
}

.edit-input label {
  margin-bottom: 5px;
}

input[type="text"] {
  padding: 5px;
  border: 1px solid #353535;
  border-radius: 5px;
  font-size: 12px;
  width: 100%;
}

input[type="text"]:focus {
  border-color: #669933;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  outline: none;
}

.edit-input input::placeholder {
  color: #999;
  font-size: 12px;
}

.modal-content {
  padding: 0 30px;
  border: none;
}

.supply-decision-section {
  margin-top: 20px;
}
.supply-decision-header {
  color: #669933;
}
.supply-decision-header p {
  margin: 0px;
  font-size: 12px;
}
.supply-decision-header hr {
  height: 2px;
  border-top: #669933 2px solid;
}
.supply-decision-container {
  margin-top: 10px;
  background: #222a34;
  border-radius: 15px;
  padding: 20px;
  color: #fff;
}
.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details_section {
  margin-top: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.supply-decision-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  color: #fff;
}
.supply-decision {
  color: #fff !important;
}
.supply-decision p {
  margin: 0px;
}

.addSupplyDetails {
  max-width: 800px;
  height: auto;
  position: absolute;
  top: 400px;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.farmer-modal {
  max-width: 720px;
  height: auto;
}

.details-modal,
.farmer-modal {
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}
</style>
