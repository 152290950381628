<template>
  <div class="modal-parent">
    <loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#669933"
      loader="spinner"
    />
    <section class="header">
      <div class="" style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="section-1">
            <h5 class="modal-caption">Edit Farmer</h5>

            <p>Step 4 of 4</p>
          </div>

          <div class="section-2">
            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="" />
            </div>

            <div class="icon-container">
              <div
                class="close-container"
                style="cursor: pointer"
                @click="closeModal"
              >
                <img src="../../../assets/x-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- border -->
    <div class="border"></div>

    <!-- Form section -->
    <section class="form-section">
      <form class="form">
        <SelectDropdown
          :options="['Male', 'Female', 'Do not disclose']"
          class="select"
          dropdownTitle="Gender"
          fieldName="sex"
          @onSelect="handleSelect"
          :inputValue="farmerForm?.sex"
          :reset="reset"
          @onReset="handleReset"
        />

        <SelectDropdown
          :options="['Farmer']"
          class="select"
          dropdownTitle="Occupation"
          fieldName="occupation"
          @onSelect="handleSelect"
          :inputValue="farmerForm?.occupation"
          :reset="reset"
          @onReset="handleReset"
        />

        <SelectDropdown
          :options="['Yes', 'No']"
          class="select"
          dropdownTitle="Have Samrtphone?"
          fieldName="have_smartphone"
          @onSelect="handleSelect"
          :inputValue="farmerForm?.have_smartphone"
          :reset="reset"
          @onReset="handleReset"
        />

        <TextInput
          placeholder="Account Number"
          @onInputChange="handleInputChange"
          fieldName="bank_acct_no"
          :inputValue="farmerForm?.bank_acct_no"
        />

        <TextInput
          placeholder="BVN"
          @onInputChange="handleInputChange"
          fieldName="bvn"
          :inputValue="farmerForm?.bvn"
        />
      </form>
    </section>

    <div class="text-indicator">
      <p class=""><span>Step 4</span> of 4</p>
    </div>

    <!-- Progress Bar -->
    <div class="progress-bar-container">
      <div class="indicator"></div>
    </div>

    <div class="button-container">
      <InvertedBlackButton
        label="Back"
        width="8rem"
        class="back-btn"
        @click="routeToPreviousPage"
      />
      <GreenButton label="Update Farmer" @click="updateFarmer" />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createToast } from "mosha-vue-toastify";

import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import InvertedBlackButton from "../../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import SelectDropdown from "../../../components/form-inputs/select-dropdown/SelectDropdown.vue";
import { stateHash } from "../../../data/location/statesHash";
import { lgasNameToSlug } from "../../../data/location/lgasHash";

export default {
  name: "EditFarmerModal4",

  data() {
    return {
      farmerForm: this.$store.getters["farmers/getFarmerToBeUpdated"] || {
        sex: "",
        occupation: "",
        have_smartphone: "",
        bank_acct_no: "",
        bvn: "",
      },
      farmerId: this.$store.getters["farmers/getFarmerToBeUpdated"]["_id"],
      globalInputtedFarmer: {},
      reset: false,
      loading: false,
    };
  },

  components: {
    TextInput,
    GreenButton,
    InvertedBlackButton,
    SelectDropdown,
    Loading,
  },

  computed: {
    isRequiredFieldsEmpty() {
      const requiredFields = {
        sex: this.farmerForm["sex"],
        occupation: this.farmerForm["occupation"],
        have_smartphone: this.farmerForm["have_smartphone"],
      };
      const isEmpty = Object.values(requiredFields).some((el) => !el?.length);
      return isEmpty;
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal", false);
    },

    handleInputChange(obj) {
      this.farmerForm = {
        ...this.farmerForm,
        ...obj,
      };
    },

    handleSelect(obj) {
      this.farmerForm = {
        ...this.farmerForm,
        ...obj,
      };
    },

    clearForm() {
      this.farmerForm = {
        sex: "",
        occupation: "",
        have_smartphone: "",
        bank_acct_no: "",
        bvn: "",
      };
    },

    clearDropdown() {
      this.reset = true;
    },

    routeToPreviousPage() {
      this.$emit("onSetAddModalPage", 3);
      this.globalInputtedFarmer =
        this.$store.getters["farmers/getFarmerToBeUpdated"];
      this.globalInputtedFarmer = {
        ...this.globalInputtedFarmer,
        ...this.farmerForm,
      };

      this.$store.dispatch(
        "farmers/setValuesForFarmerUpdate",
        this.globalInputtedFarmer
      );
    },

    async updateFarmer() {
      this.farmerForm = {
        ...this.farmerForm,
        id: this.farmerId,
        lga_of_origin: lgasNameToSlug[this.farmerForm["LGA"]],
        state_of_origin: stateHash[this.farmerForm["state"]],
      };

      this.loading = true;

      const data = await this.$store.dispatch(
        "farmers/updateFarmer",
        this.farmerForm
      );

      if (data._id) {
        this.loading = false;
        this.clearForm();
        this.clearDropdown();
        createToast("Farmer updated successfully!", {
          type: "success",
          timeout: 3000,
          position: "top-right",
          onClose: () => {
            return window.location.reload();
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-parent {
  background-color: white;
  height: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  /* border: 1px solid #000; */
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 40%;
}

p {
  color: #232a34;
  font-weight: 900;
  align-self: center;
  font-size: 0.77rem;
  margin-top: 7px;
}

.section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 55%;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.logo-container img {
  width: 30px;
  height: auto;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.close-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.close-container:hover {
  background: #e8e8e8;
}

.border {
  margin-top: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.form-section {
  height: auto;
}

.form {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.text-indicator {
  margin: 0% auto;
  width: 65%;
  /* border: 1px solid red; */
  display: flex;
}

.text-indicator p {
  margin-left: auto;
  font-size: 11px;
  font-weight: 400;
}

p span {
  color: #669933;
}

.progress-bar-container {
  margin: 0% auto 3% auto;
  width: 65%;
  background: #b3b3b3;
  border-radius: 9px;
  padding: 1px;
}

.indicator {
  height: 65%;
  width: 100%;
  background: #669933;
  border-radius: 9px;
  border: 1px solid #669933;
  padding: 3px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 7% auto 2% auto;
}

.back-btn {
  width: 33%;
}
</style>
