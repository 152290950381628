<template>
  <div>
    <!-- Search Bar Section -->
    <section class="search-bar-container">
      <section class="search-bar-container">
        <div class="search-form">
          <input
            type="text"
            class="search-input"
            placeholder="Search by atom id, purchase id"
            v-model="searchTerm"
            @keyup.enter="handleSearchText"
          />
          <button><img src="../../assets/search-icon.png" alt="" /></button>
        </div>
      </section>
    </section>

    <!-- Date Filter Section -->
    <section class="date-filter-container">
      <div class="wrapper">
        <p class="">Search by Period</p>
        <div class="controls-container">
          <div class="inputs-container">
            <div class="input">
              <input
                type="text"
                name=""
                id="date"
                placeholder="Select Start Date"
                onfocus="(this.type = 'date')"
                v-model="startDate"
              />
            </div>

            <div class="input">
              <input
                type="text"
                name=""
                id="date"
                placeholder="Select End Date"
                onfocus="(this.type = 'date')"
                v-model="endDate"
              />
            </div>

            <GreenButton
              label="Fetch"
              width="5rem"
              height="2rem"
              @click="fetchByDatePeriod(page)"
            />
          </div>

          <div v-if="itemsSelected.length > 0" class="multi-actions">
            <i
              class="fa fa-trash"
              style="font-size: 24px; color: red; cursor: pointer"
              @click="openDeleteModal"
            ></i>

            <GreenButton
              label="Approve"
              width="5rem"
              height="2rem"
              @click="openApprovalModal"
            />
          </div>

          <div class="buttons-container">
            <div class="">
              <download-excel :data="farmerJSON">
                <InvertedBlackButton
                  label="Export"
                  width="8rem"
                  height="2.6rem"
                  iconName="excel-icon"
                ></InvertedBlackButton>
              </download-excel>
            </div>

            <div class="">
              <BlackButton
                label="Open Filter"
                width="10rem"
                height="2.6rem"
                iconName="filter-icon"
                @click="openFilterModal"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Data table section -->
    <section class="data-table-container">
      <EasyDataTable
        v-model:server-options="paginationMetaData"
        v-model:items-selected="itemsSelected"
        :server-items-length="count"
        :headers="headers"
        :items="inventories"
        alternating
        table-class-name="customize-table"
        class="data-table"
        :loading="loading"
        buttons-pagination
      >
        <template #item-price="item">
          <div>{{ vueNumberFormat(item.price, {}) }}</div>
        </template>
        <template #item-logisticCost="item">
          <div>{{ vueNumberFormat(item.logisticCost, {}) }}</div>
        </template>
        <template #item-status="item">
          <div class="status-wrapper">
            <div
              class="status"
              :class="[
                item.status === 'Completely Moved'
                  ? 'moved'
                  : item.status === 'Unmoved'
                  ? 'unmoved'
                  : 'partial',
              ]"
            >
              {{ item.status }}
            </div>
          </div>
        </template>

        <template #item-processingStatus="item">
          <div class="status-wrapper">
            <div
              class="status"
              :class="[
                item.processingStatus === 'Available'
                  ? 'available'
                  : item.processingStatus === 'Consumed'
                  ? 'consumed'
                  : 'partial',
              ]"
            >
              {{ item.processingStatus }}
            </div>
          </div>
        </template>

        <template #item-actions="item">
          <div class="actions-wrapper">
            <div class="" @click="toggleActions(item)">
              <div
                class="action-options"
                v-if="isActionsOpened && item._id === itemClicked"
              >
                <div class="item-wrapper">
                  <div class="actions-item" @click="openDetailsModal">
                    <div class="image">
                      <img
                        src="../../assets/green-eye.png"
                        alt=""
                        class="action-icon green-eye"
                      />
                    </div>
                    <p class="view-action">View details</p>
                  </div>
                  <div class="actions-item" @click="openDeleteModal(item._id)">
                    <div class="">
                      <img
                        src="../../assets/delete-icon.png"
                        alt=""
                        class="action-icon delete-icon"
                      />
                    </div>
                    <p class="delete-action">Delete</p>
                  </div>
                </div>
              </div>
              <span>&#8942;</span>
            </div>
          </div>
        </template>
      </EasyDataTable>
    </section>

    <!-- Details Modal -->
    <div class="details-modal" v-if="isDetailsModalOpened">
      <InventoryDetailsModal
        @onCloseModal="handleCloseModal"
        :inventoryId="itemClicked"
      />
    </div>

    <!-- Filter Modal -->
    <div class="filter-modal" v-if="isFilterModalOpened">
      <InventoryFilterForm />
    </div>

    <!-- Delete Modal -->
    <div class="delete-modal" v-if="isDeleteModalOpened">
      <DeleteModal
        heading="Inventory"
        :itemId="itemClicked"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <div
      class="delete-modal"
      v-if="isDeleteModalOpened && selectedInventoryIds.length"
    >
      <DeleteManyModal
        heading="Inventories"
        :ids="selectedInventoryIds"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <!-- Approval -->
    <div
      class="delete-modal"
      v-if="isApprovalStatus && selectedInventoryIds.length"
    >
      <ApproveManyModal
        heading="Inventories"
        :ids="selectedInventoryIds"
        @onCloseModal="handleCloseModal"
      />
    </div>

    <!-- Overlay -->
    <div
      class="dark-overlay"
      v-if="
        isDetailsModalOpened ||
        isFilterModalOpened ||
        isDeleteModalOpened ||
        isApprovalStatus
      "
      @click="closeOverlay"
    ></div>
  </div>
</template>

<script>
import GreenButton from "../../components/buttons/green-button/GreenButton.vue";
import BlackButton from "../../components/buttons/black-button/BlackButton.vue";
import InvertedBlackButton from "../../components/buttons/inverted-black-button/InvertedBlackButton.vue";
import InventoryFilterForm from "./InventoryFilterForm.vue";
import DeleteModal from "./DeleteModal.vue";
import DeleteManyModal from "../../components/modals/DeleteManyModal.vue";
import ApproveManyModal from "../../components/modals/ApproveManyModal.vue";

import InventoryDetailsModal from "./InventoryDetailsModal.vue";
import { tableHeaders } from "./table-headers/headers";
import { convertToFilterQueryString } from "../../utils";

export default {
  name: "InventoryList",

  components: {
    GreenButton,
    BlackButton,
    InvertedBlackButton,
    InventoryFilterForm,
    InventoryDetailsModal,
    DeleteModal,
    DeleteManyModal,
    ApproveManyModal,
  },

  data() {
    return {
      activeChildTab: "Inventory",
      inventory: "Inventory",
      discrepancies: "Discrepancies",
      headers: tableHeaders,
      itemsSelected: [],
      selectedInventoryIds: [],
      isFilterModalOpened: false,
      paginationMetaData: {
        page: 1,
        rowsPerPage: 100,
      },
      inventories: [],
      isActionsOpened: false,
      isDetailsModalOpened: false,
      isDeleteModalOpened: false,
      isApprovalStatus: false,
      itemClicked: "",
      loading: false,
      searchTerm: "",
      globalFilterQuery: "",
    };
  },

  mounted() {
    this.fetchInventories();
  },

  computed: {
    filterMetaData() {
      return this.$store.getters["inventories/getFilterMetaData"];
    },
  },

  methods: {
    async fetchInventories(page = 1, limit = 100, searchTerm = "") {
      this.loading = true;

      const { inventories, count } = await this.$store.dispatch(
        "inventories/fetchAllInventories",
        {
          page,
          limit,
          searchTerm,
        }
      );

      this.inventories = inventories;
      this.count = count;
      this.loading = false;
    },

    async handleSearchText(e) {
      const pageNum = 1;
      this.fetchInventories(pageNum, 100, e.target.value);
    },

    openApprovalModal() {
      this.isApprovalStatus = !this.isApprovalStatus;
    },

    async fetchByDatePeriod(page = 1, limit = 100) {
      if (this.startDate === "" || this.endDate === "") return;
      const query = convertToFilterQueryString({
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.loading = true;
      try {
        const { count, filteredInventories } = await this.$store.dispatch(
          "inventories/filterInventoriesByDatePeriod",
          {
            page,
            filterQuery: query,
            limit,
          }
        );

        this.inventories = filteredInventories;
        this.count = count;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    updateActiveChildTab(name) {
      this.activeChildTab = name;
    },

    openFilterModal() {
      this.isFilterModalOpened = !this.isFilterModalOpened;
    },

    closeOverlay() {
      this.isFilterModalOpened = false;
      this.isDetailsModalOpened = false;
      this.isDeleteModalOpened = false;
    },

    openDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },

    toggleActions(arg) {
      this.itemClicked = arg._id;
      this.isActionsOpened = !this.isActionsOpened;
    },

    openDetailsModal() {
      this.isDetailsModalOpened = !this.isDetailsModalOpened;
    },

    handleCloseModal(status) {
      this.isDetailsModalOpened = status;
      this.isFilterModalOpened = status;
      this.isDeleteModalOpened = status;
      this.isApprovalStatus = status;
    },
  },

  watch: {
    paginationMetaData: {
      handler: function (newValue) {
        const isDateFilter =
          this.$store.getters["inventories/getDateFilterStatus"];

        if (isDateFilter) {
          this.fetchByDatePeriod(
            newValue.page,
            newValue.rowsPerPage,
            this.globalFilterQuery
          );
        } else
          this.fetchInventories(
            newValue.page,
            newValue.rowsPerPage,
            this.searchTerm
          );
      },
    },

    filterMetaData(newValue) {
      const { filteredInventories, count, query } = newValue;

      this.globalFilterQuery = query;
      this.inventories = filteredInventories;
      this.count = count;
    },

    itemsSelected: {
      handler: function (newValue) {
        this.selectedInventoryIds = newValue.map((inventory) => inventory._id);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.search-form {
  display: flex;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  height: 2.2rem;
  padding: 0 9px;
  background: #f9fafe;
}

.search-input {
  border: 0;
  outline: none;
  flex: 1;
  background: #f9fafe;
  color: #726e6e;
  font-size: 0.8rem;
}

.search-input::placeholder {
  color: #b3b3b3;
  line-height: 24px;
  font-size: 0.8rem;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

button img {
  width: 13px;
  height: 13px;
}

.tabs-container {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 0px;
  align-items: center;
}

.tab {
  margin: 0px 15px;
  margin-bottom: 0px;
  color: #b3b3b3;
  font-size: 13px;
  cursor: pointer;
}

.active-child-tab {
  color: #fff !important;
  background: #232a34;
  border-radius: 37px;
  padding: 8px 16px;
}

.date-filter-container {
  width: 100%;
  margin-top: 1.1%;
  height: 6rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
}

.multi-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
}

.wrapper {
  width: 97%;
  margin: 1.1% auto 0% auto;
  height: 85%;
}

.wrapper p {
  margin-bottom: 0px;
}

.date-filter-container p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.78rem;
  line-height: 24px;
  color: #333333;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.input {
  width: 35%;
  margin-right: 5px;
  height: 2rem;
  display: flex;
}

#date {
  width: 100%;
  outline: 0;
  padding: 0 8px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  transition: 300ms border-color ease;
}

#date:focus {
  border-color: #000;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 27%;
}

.data-table-container {
  width: 100%;
  height: 79.9%;
  overflow-x: hidden;
  overflow-y: auto;
}

.actions-wrapper .actions-icon {
  width: 20px;
  cursor: pointer;
  display: flex;
}

.actions-wrapper span {
  color: #232a34;
  font-size: 20px;
  cursor: pointer;
}

/* Data table Styles */
.customize-table {
  --easy-table-header-background-color: #ced0d5;
  --easy-table-body-item-padding: 9px 11px;
  --easy-table-body-even-row-background-color: #f9fafe;
  --easy-table-body-row-background-color: #f9fafe;
  --easy-table-body-row-font-size: 12.4px;
  --easy-table-body-even-row-font-color: #333333;
  --easy-table-body-row-font-color: #333333;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 24px;
  gap: 8px;
  width: 135px;
  height: 32px;
  border-radius: 41px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.consumed {
  font-size: 10px;
  background: #e8e9eb;
  color: #545454;
}

.moved,
.available {
  color: #669933;
  font-size: 10px;
  background: rgba(102, 153, 51, 0.15);
}

.unmoved {
  color: #ed0a0a;
  font-size: 10px;
  background: rgba(237, 10, 10, 0.15);
}

.partial,
.aggregated {
  color: #f19306;
  font-size: 10px;
  background: rgba(241, 147, 6, 0.15);
}

.action-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 0px;
  position: absolute;
  width: 160%;
  height: 65px;
  right: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(179, 179, 179, 0.32);
  border-radius: 4px;
  z-index: 5;
  gap: 10px;
}

.item-wrapper {
  width: 90%;
  margin: 0 auto;
  height: 100%;
}

.actions-item {
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
}

.action-icon {
  margin-right: 5px;
}

.green-eye,
.edit-icon,
.delete-icon {
  width: 14px;
  height: auto;
}

.view-action {
  color: #669933;
}

.edit-action {
  color: #ea9a00;
}

.delete-action {
  color: #ed0a0a;
}

.details-modal {
  max-width: 800px;
  height: 455px;
  /* overflow-x: hidden;
  overflow-y: auto; */
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.delete-modal {
  max-width: 500px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  margin: 30px 0;
}

.filter-modal,
.close-filter-modal {
  position: absolute;
  top: 0px;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 15;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter-modal {
  animation: slideFromRightToLeft 1s ease-in-out;
  animation-delay: 10ms;
  left: 72%;
  width: 450px;
}

.close-filter-modal {
  animation: slideBackIn 1s ease-in-out;
  animation-delay: 10ms;
  left: 100%;
  display: hidden;
}

@keyframes slideFromRightToLeft {
  0% {
    left: 100%;
  }
  50% {
    left: 72%;
  }
  100% {
    left: 72%;
  }
}

@keyframes slideBackIn {
  0% {
    left: 72%;
  }
  50% {
    left: 72%;
  }
  100% {
    left: 100%;
  }
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 9;
}
</style>
