<template>
  <div class="modal-parent">
    <Loading
      v-model:active="loading"
      :can-cancel="true"
      :is-full-page="true"
      color="#669933"
      loader="spinner"
    />

    <section class="header">
      <div style="margin: 0 auto; width: 96%">
        <div class="header-content">
          <div class="header-content-right">
            <h5 class="modal-caption">Update P2_OutLog Settings</h5>

            <div class="logo-container">
              <img src="../../../assets/releaf-logo.png" alt="releaf-logo" />
            </div>
          </div>

          <div class="icons-container" @click="closeModal">
            <div
              class="x-container"
              style="cursor: pointer"
              @click="closeModal"
            >
              <img src="../../../assets/x-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="border"></div>

    <section class="form-container">
      <form class="form-container-div">
        <TextInput
          placeholder="Fuel_Econ(L/KM)"
          fieldName="fuel_econ"
          :inputValue="logisticForm.fuel_econ"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="Fuel Cost"
          fieldName="fuel_cost"
          :inputValue="logisticForm.fuel_cost"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="Route Quality"
          fieldName="route_quality"
          :inputValue="logisticForm.route_quality"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="Outbound Vehicle Capacity"
          fieldName="outbound_vehicle_capacity"
          :inputValue="logisticForm.outbound_vehicle_capacity"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="Outbound Loading Cost"
          fieldName="outbound_loading_cost"
          :inputValue="logisticForm.outbound_loading_cost"
          @onInputChange="handleInputChange"
        />

        <TextInput
          placeholder="Loading /T"
          fieldName="loading_t"
          :inputValue="logisticForm.loading_t"
          @onInputChange="handleInputChange"
          :disabledValue="true"
        />

        <TextInput
          placeholder="Comm_Tolls/Trip"
          fieldName="comm_tolls"
          :inputValue="logisticForm.comm_tolls"
          @onInputChange="handleInputChange"
        />
      </form>
    </section>

    <div class="border"></div>

    <div class="button-container">
      <GreenButton
        label="Update"
        width="10.5rem"
        height="2.9rem"
        @click="updateP2Logistics"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../../components/form-inputs/text-input/TextInput.vue";
import GreenButton from "../../../components/buttons/green-button/GreenButton.vue";
import { createToast } from "mosha-vue-toastify";
import Loading from "vue-loading-overlay";

export default {
  name: "EditP2LogisticsModal",
  components: {
    TextInput,
    GreenButton,
    Loading,
  },

  data() {
    return {
      logisticForm: {
        fuel_econ: this.ful_econ || "",
        fuel_cost: this.ful_cost || "",
        route_quality: this.rout_quality || "",
        outbound_vehicle_capacity: this.vehi_capacity || "",
        loading_t: this.load_t || "",
        comm_tolls: this.comm_tols || "",
        outbound_loading_cost: this.load_cost || "",
      },

      loading: false,
    };
  },

  props: {
    ful_econ: {
      type: String,
      required: true,
    },

    rout_quality: {
      type: String,
      required: true,
    },

    vehi_capacity: {
      type: String,
      required: true,
    },

    load_t: {
      type: String,
      required: true,
    },

    comm_tols: {
      type: String,
      required: true,
    },

    load_cost: {
      type: String,
      required: true,
    },

    ful_cost: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleInputChange(obj) {
      this.logisticForm = {
        ...this.logisticForm,
        ...obj,
      };
    },

    closeModal() {
      this.$emit("onCloseModal", false);
    },

    async updateP2Logistics() {
      this.loading = true;

      try {
        const {
          fuel_econ,
          fuel_cost,
          route_quality,
          outbound_vehicle_capacity,
          loading_t,
          comm_tolls,
          outbound_loading_cost,
        } = this.logisticForm;

        const payload = {
          fuel_econ: Number(fuel_econ),
          fuel_cost: Number(fuel_cost),
          route_quality: Number(route_quality),
          outbound_vehicle_capacity: Number(outbound_vehicle_capacity),
          outbound_loading_cost: Number(outbound_loading_cost),
          loading_cost_per_ton: Number(loading_t),
          community_ton_per_trip: Number(comm_tolls),
        };

        const response = await this.$store.dispatch(
          "profitInput/updatePriceAtomSettings",
          payload
        );

        if (response.acknowledged) {
          this.loading = false;
          this.$emit('updateOutLogEvent', this.logisticForm)

          createToast("P2_OutLog Settings Updated Successfully", {
            type: "success",
            position: "top-right",
            timeout: 3000,

            onClose: () => {
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },

  watch: {
    "logisticForm.outbound_loading_cost": function (val) {
      this.logisticForm.loading_t =
        val * (1 / this.logisticForm.outbound_vehicle_capacity);

      this.handleInputChange({
        loading_t: this.logisticForm.loading_t,
      });
    },

    "logisticForm.outbound_vehicle_capacity": function (val) {
      this.logisticForm.loading_t =
        this.logisticForm.outbound_loading_cost * (1 / val);

      this.handleInputChange({
        loading_t: this.logisticForm.loading_t,
      });
    },
  },
};
</script>

<style scoped>
.modal-parent {
  height: 100%;
  background: #fff;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.modal-caption {
  color: #232a34;
  font-weight: 700;
}

.logo-container {
  background-color: #232a34;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.x-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dcdbdb;
}

.border {
  margin-top: 10px;
  background: #fff;
  border-bottom: 1px solid #b3b3b3;
}

.form-container {
  height: 230px;
  overflow-y: scroll;
}

.form-container-div {
  margin: 5% auto;
  width: 65%;
  height: 70%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
</style>
