export const statesDoc = [
  { id: "60c358bf2079e2001e77dfa7", name: "Ebonyi", slug: "EBO" },
  { id: "60c358bf2079e2001e77dfad", name: "Imo", slug: "IMO" },
  { id: "60c358bf2079e2001e77dfbb", name: "Oyo", slug: "OYO" },
  { id: "60c358bf2079e2001e77dfa9", name: "Ekiti", slug: "EKI" },
  { id: "60c358bf2079e2001e77df9d", name: "Abia", slug: "ABI" },
  { id: "60c358bf2079e2001e77dfb5", name: "Lagos", slug: "LAG" },
  { id: "60c358bf2079e2001e77dfc0", name: "Yobe", slug: "YOB" },
  { id: "60c358bf2079e2001e77dfa2", name: "Bayelsa", slug: "BAY" },
  { id: "60c358bf2079e2001e77dfaa", name: "Enugu", slug: "ENU" },
  { id: "60c358bf2079e2001e77dfb1", name: "Katsina", slug: "KAT" },
  { id: "60c358bf2079e2001e77dfba", name: "Osun", slug: "OSU" },
  { id: "60c358bf2079e2001e77dfbe", name: "Sokoto", slug: "SKO" },
  { id: "60c358bf2079e2001e77dfb8", name: "Ogun", slug: "OGU" },
  { id: "60c358bf2079e2001e77dfb9", name: "Ondo", slug: "OND" },
  { id: "60c358bf2079e2001e77dfa5", name: "Cross River", slug: "CRO" },
  { id: "60c358bf2079e2001e77df9e", name: "Adamawa", slug: "ADA" },
  { id: "60c358bf2079e2001e77dfa4", name: "Borno", slug: "BOR" },
  { id: "60c358bf2079e2001e77dfa0", name: "Anambra", slug: "ANA" },
  { id: "60c358bf2079e2001e77dfa6", name: "Delta", slug: "DEL" },
  { id: "60c358bf2079e2001e77dfb2", name: "Kebbi", slug: "KEB" },
  { id: "60c358bf2079e2001e77dfbc", name: "Plateau", slug: "PLA" },
  { id: "60c358bf2079e2001e77dfac", name: "Gombe", slug: "GOM" },
  { id: "60c358bf2079e2001e77dfb3", name: "Kogi", slug: "KOG" },
  { id: "60c358bf2079e2001e77dfbd", name: "Rivers", slug: "RIV" },
  { id: "60c358bf2079e2001e77dfbf", name: "Taraba", slug: "TAR" },
  { id: "60c358bf2079e2001e77dfaf", name: "Kaduna", slug: "KAD" },
  { id: "60c358bf2079e2001e77dfb0", name: "Kano", slug: "KAN" },
  { id: "60c358bf2079e2001e77dfa8", name: "Edo", slug: "EDO" },
  { id: "60c358bf2079e2001e77dfc1", name: "Zamfara", slug: "ZAM" },
  { id: "60c358bf2079e2001e77df9f", name: "Akwa Ibom", slug: "AKW" },
  { id: "60c358bf2079e2001e77dfa1", name: "Bauchi", slug: "BAU" },
  { id: "60c358bf2079e2001e77dfb7", name: "Niger", slug: "NIG" },
  { id: "60c358bf2079e2001e77dfab", name: "FCT", slug: "FCT" },
  { id: "60c358bf2079e2001e77dfb6", name: "Nasarawa", slug: "NAS" },
  { id: "60c358bf2079e2001e77dfa3", name: "Benue", slug: "BEN" },
  { id: "60c358bf2079e2001e77dfae", name: "Jigawa", slug: "JIG" },
  { id: "60c358bf2079e2001e77dfb4", name: "Kwara", slug: "KWA" },
];
