export const convertToFilterQueryString = (obj) => {
  let filterQuery = {};
  let result = [];

  //get query object with non empty values
  for (let prop in obj) {
    if (obj[prop]) {
      filterQuery = {
        ...filterQuery,
        [prop]: obj[prop],
      };
    }
  }

  for (let prop in filterQuery) {
    result.push(`${prop}=${filterQuery[prop]}`);
  }
  return result.join("&");
};
